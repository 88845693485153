import Duck from 'extensible-duck'
import { AUTH_UI_STATE } from 'components/auth/const'

const duck = new Duck({
  namespace: 'user',
  store: 'auth',
  types: [
    'SET_EMAIL',
    'SET_GUEST_MODE',
    'SET_PROVIDER_INFO',
    'SET_STAGE',
    'SET_SUCCESSFUL_LOGIN_REDIRECT_URL',
    'SET_TOKEN',
    'SET_USER',
    'SET_CUSTOMER_DATA',
    'SHOW_LOGIN_AREA',
    'SET_OPTION',
  ],
  initialState: {
    email: '',
    isGuestMode: true,
    customerData: null,
    options: {},
    phoneNumber: null,
    providerInfo: null,
    showLogin: false,
    stage: AUTH_UI_STATE.logIn,
    successfulLoginRedirectUrl: '',
    token: '',
    user: null,
  },
  reducer: (state, action, rDuck) => {
    switch (action.type) {
      case rDuck.types.SET_STAGE:
        return { ...state, stage: action.stage }
      case rDuck.types.SET_EMAIL:
        return { ...state, email: action.email }
      case rDuck.types.SET_USER:
        return { ...state, user: action.user, isGuestMode: false }
      case rDuck.types.SET_TOKEN:
        return { ...state, token: action.token }
      case rDuck.types.SET_PROVIDER_INFO:
        return { ...state, providerInfo: action.providerInfo }
      case rDuck.types.SHOW_LOGIN_AREA:
        return {
          ...state,
          showLogin: action.showLogin,
          options: { ...state.options, ...action.options },
        }
      case rDuck.types.SET_OPTION:
        return {
          ...state,
          options: { ...state.options, ...action.options },
        }
      case rDuck.types.SET_SUCCESSFUL_LOGIN_REDIRECT_URL:
        return {
          ...state,
          successfulLoginRedirectUrl: action.successfulLoginRedirectUrl,
        }
      case rDuck.types.SET_GUEST_MODE:
        return { ...state, isGuestMode: true }
      case rDuck.types.SET_CUSTOMER_DATA:
        return { ...state, customerData: action.customerData }
      default:
        return state
    }
  },
  creators: (cDuck) => ({
    setStage: (stage) => ({ type: cDuck.types.SET_STAGE, stage }),
    setEmail: (email) => ({ type: cDuck.types.SET_EMAIL, email }),
    setUser: (user) => ({ type: cDuck.types.SET_USER, user }),
    setToken: (token) => ({ type: cDuck.types.SET_TOKEN, token }),
    setProviderInfo: (providerInfo) => ({
      type: cDuck.types.SET_PROVIDER_INFO,
      providerInfo,
    }),
    updateShowLogin: (showLogin, options) => ({
      type: cDuck.types.SHOW_LOGIN_AREA,
      showLogin,
      options,
    }),
    // TODO: Implement this back in.
    setSuccessfulLoginRedirectUrl: (url) => ({
      type: cDuck.types.SET_SUCCESSFUL_LOGIN_REDIRECT_URL,
      successfulLoginRedirectUrl: url,
    }),
    setGuestMode: () => ({ type: cDuck.types.SET_GUEST_MODE }),
    setCustomerData: (customerData) => ({
      type: cDuck.types.SET_CUSTOMER_DATA,
      customerData,
    }),
    setOption: (options) => ({
      type: cDuck.types.SET_OPTION,
      options,
    }),
  }),
})

export default duck
