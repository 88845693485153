import { useState, useEffect } from 'react'

const getTimeRemaining = (seconds) => {
  const hours = Math.floor(seconds / 3600)
  const minutes = Math.floor((seconds % 3600) / 60)
  const secs = seconds % 60

  const secsDes = secs < 10 ? `0${secs}` : secs
  const minsDes = minutes < 10 ? `0${minutes}` : minutes
  const hoursDes = hours < 10 ? `0${hours}` : hours

  if (hours === 0) return `${minsDes}m:${secsDes}s`

  return `${hoursDes}h:${minsDes}m:${secsDes}s`
}

const useProductFlashSale = (is_flash_sale, flash_sale_seconds_left) => {
  const [secondsLeft, setSecondsLeft] = useState(flash_sale_seconds_left)
  const [isShowFlashSaleEnded, setIsShowFlashSaleEnded] = useState(false)

  useEffect(() => {
    if (!is_flash_sale) return undefined

    const myInterval = setInterval(async () => {
      if (secondsLeft > 0) {
        setSecondsLeft((prevSecondsLeft) => prevSecondsLeft - 1)
      }

      if (secondsLeft === 0) {
        setIsShowFlashSaleEnded(true)
      }
    }, 1000)

    return () => {
      clearInterval(myInterval)
    }
  }, [is_flash_sale, secondsLeft])

  const flashSaleTimeRemaining = getTimeRemaining(secondsLeft)

  return {
    getTimeRemaining,
    flashSaleTimeRemaining,
    isShowFlashSaleEnded,
  }
}

export default useProductFlashSale
