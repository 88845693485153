import { createHash } from 'crypto'
import TagManager from 'react-gtm-module'
import env from 'config/env'
import StateStorage from 'lib/state-storage'

// https://jestjs.io/docs/environment-variables
const isJest = !!process.env.JEST_WORKER_ID

export const isTestEnv = env.NODE_ENV === 'test' || isJest
const isDevelopment = env.NODE_ENV === 'development'

class Tracking {
  static API_HOST = env.API_HOST

  static EVENT_NAME_PAGE_VIEW = 'page-view'

  static EVENT_NAME_VIEW_CONTENT_CATEGORY = 'view-content-category'

  static EVENT_NAME_VIEW_CONTENT_PRODUCT = 'view-content-product'

  static EVENT_NAME_VIEW_CART = 'view-cart'

  static EVENT_NAME_ADD_TO_CART = 'add-to-cart'

  static EVENT_NAME_PURCHASE = 'purchase'

  static EVENT_NAME_SEARCH = 'search'

  static EVENT_NAME_START_CHECKOUT = 'start-checkout'

  static EVENT_NAME_FINISH_SHIPPING_METHOD = 'finish-shipping-method'

  static EVENT_NAME_FINISH_PAYMENT_METHOD = 'finish-payment-method'

  static trackPageView(url = window.location.href, data = {}) {
    const user = StateStorage.getAuthUser()
    const shop = StateStorage.getShop()

    const dataLayer = {
      ...data,
      event: Tracking.EVENT_NAME_PAGE_VIEW,
      'Page URL': url,
      'criteo-email-hash':
        user && user.email
          ? createHash('sha256').update(user.email).digest('hex')
          : null,
      'criteo-account-number': Tracking.getShopTrackingId(data.country || shop),
    }

    Tracking.log(`📈 ${Tracking.EVENT_NAME_PAGE_VIEW}`, dataLayer)

    if (!isTestEnv) {
      TagManager.dataLayer({ dataLayer })
    }
  }

  static getShopTrackingId = (country) => {
    switch (country) {
      case 'th':
        return 19957
      case 'sg':
        return 24846
      case 'id':
        return 29147
      case 'my':
        return 56071
      case 'global':
        return 56072
      case 'ph':
        return 90300
      default:
        return null
    }
  }

  static enableGoogleEmailTracking = () => {
    const user = StateStorage.getAuthUser()
    const bannerPDPACookieConsent = JSON.parse(
      StateStorage.getCookie('bannerPDPACookieConsent'),
    )
    const isEnableGoogleEmailTracking =
      user && bannerPDPACookieConsent?.is_marketing_allowed

    return isEnableGoogleEmailTracking && user
  }

  static trackEvent(event, data) {
    let dataLayerData = { ...data, event }

    if (
      [Tracking.EVENT_NAME_ADD_TO_CART, Tracking.EVENT_NAME_PURCHASE].includes(
        event,
      )
    ) {
      const enableGoogleEmailTrackingObj = Tracking.enableGoogleEmailTracking()

      dataLayerData = {
        ...data,
        ...(!!enableGoogleEmailTrackingObj && {
          email: enableGoogleEmailTrackingObj.email,
        }),
        event,
      }
    }

    Tracking.log(`📈 ${event}`, dataLayerData)

    if (!isTestEnv) {
      TagManager.dataLayer({ dataLayer: dataLayerData })
    }
  }

  static log(...args) {
    if (window && window.console && isDevelopment && !isTestEnv) {
      console.info(...args) // eslint-disable-line no-console
    }
  }
}

export default Tracking
