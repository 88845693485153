// eslint-disable-next-line import/no-cycle
import { extractTabsID } from './util'

export const CATEGORY_BANNER_WITH_LINK = [
  {
    as: '/purpose',
    id: 1039,
    href: '/purpose',
  },
  {
    as: '/beet',
    id: 1214,
    href: '/beet',
  },
  {
    as: '/purpose',
    id: 1484,
    href: '/purpose',
  },
  {
    as: '/purpose',
    id: 1570,
    href: '/purpose',
  },
]

export const CATEGORY_BANNER_WITH_TNC = [1497]

// TODO: Remove this key, it's for hacky way to fix category cache + pagination.
export const STORAGE_KEY_LAST_START_PAGE = 'category_last_start_page_no'

export const CATEGORY_TAB_DATA = [
  // 4 nov (11/11 campaign (TH/SG/MY/PH/ID))
  {
    buttonText: {
      en: '11.11 Sale',
    },
    categoryId: 5207,
    showOnCategoryID: [5207, 5203, 5204, 5205],
    link: 'features/all-sale-11-11',
    elligibleShops: ['th', 'sg', 'my', 'ph', 'id'],
  },
  {
    buttonText: {
      en: 'Up to 30%',
    },
    categoryId: 5203,
    showOnCategoryID: [5207, 5203, 5204, 5205],
    link: 'features/sale-up-to-30',
    elligibleShops: ['th', 'sg', 'my', 'ph', 'id'],
  },
  {
    buttonText: {
      en: '40 - 60%',
    },
    categoryId: 5204,
    showOnCategoryID: [5207, 5203, 5204, 5205],
    link: 'features/sale-40-60',
    elligibleShops: ['th', 'sg', 'my', 'ph', 'id'],
  },
  {
    buttonText: {
      en: '70 - 90%',
    },
    categoryId: 5205,
    showOnCategoryID: [5207, 5203, 5204, 5205],
    link: 'features/sale-70-90',
    elligibleShops: ['th', 'sg', 'my', 'ph', 'id'],
  },
]

export const CATEGORY_TAB_DATA_2 = [
  {
    buttonText: {
      en: 'All Sale',
      th: 'Sale ทั้งหมด',
    },
    categoryId: 4129,
    link: 'features/mega-brand-deals',
    elligibleShops: ['th'],
  },
  {
    buttonText: {
      en: 'Top Brand Deals',
      th: 'ดีล Top Brands',
    },
    categoryId: 4130,
    link: 'features/twelve-twelve-top-brands',
    elligibleShops: ['th'],
  },
  {
    buttonText: {
      en: '50% Off',
      th: 'ลด 50%',
    },
    categoryId: 4131,
    link: 'features/twelve-twelve-brands-50off',
    elligibleShops: ['th'],
  },
  {
    buttonText: {
      en: '30% Off',
      th: 'ลด 30%',
    },
    categoryId: 4132,
    link: 'features/twelve-twelve-brands-30off',
    elligibleShops: ['th'],
  },
  {
    buttonText: {
      en: '20% Off',
      th: 'ลด 20%',
    },
    categoryId: 4133,
    link: 'features/twelve-twelve-brands-20off',
    elligibleShops: ['th'],
  },
]

export const CATEGORY_TAB_IDS = extractTabsID(CATEGORY_TAB_DATA)
export const CATEGORY_TAB_IDS_2 = extractTabsID(CATEGORY_TAB_DATA_2)

export const PRODUCTS_PER_PAGE = 18

export const FETCH_PRODUCTS_DELAY = 500
