import Duck from 'extensible-duck'

export const ORDER_STATUS_ENUM = {
  ALL: 'ALL',
  UNPAID: 'UNPAID',
  PROCESSING: 'PROCESSING',
  SHIPPED: 'SHIPPED',
  PENDING_REVIEW: 'PENDING_REVIEW',
  RETURN: 'RETURN',
  OTHER: 'OTHER',
}

const initialState = {
  filter: ORDER_STATUS_ENUM.ALL,
}

const types = ['UPDATE_ORDER_STATUSES_FILTER', 'RESET']

export default new Duck({
  namespace: 'content',
  store: 'orderStatusesFilter',
  types,
  initialState,
  reducer: (state, action, duck) => {
    switch (action.type) {
      case duck.types.UPDATE_ORDER_STATUSES_FILTER:
        return { filter: action.orderStatusesFilter }
      case duck.types.RESET:
        return { filter: ORDER_STATUS_ENUM.ALL }
      default:
        return state
    }
  },
  creators: (duck) => ({
    reset: () => ({ type: duck.types.RESET }),
    updateOrderStatusesFilter: (orderStatusesFilter) => ({
      type: duck.types.UPDATE_ORDER_STATUSES_FILTER,
      orderStatusesFilter,
    }),
  }),
})
