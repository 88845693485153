import Duck from 'extensible-duck'

const initialState = {
  bankProofErrorMsg: null,
  errorMessage: null,
  extendSuccess: false,
  hasProof: false,
  image: null,
  isExtendButtonClicked: false,
  isExtended: false,
  isExtendInfoClosed: false,
  isExtendOptionHidden: false,
  isPast: false,
  isModalOpen: false,
  orderID: null,
  readyToUpload: false,
  uploadingImage: false,
}

const types = [
  'CLOSE_MODAL',
  'RESET',
  'UPDATE_EXTEND_DATA',
  'UPDATE_EXTEND_INFO_BOX',
  'UPDATE_EXTEND_MODAL',
  'UPDATE_EXTEND_SUCCESS',
  'UPDATE_HAS_PROOF',
  'UPDATE_IS_PAST',
  'UPDATE_MULTI_STATE',
  'UPDATE_ORDER_ERROR_MSG',
  'UPDATE_ORDER_ID',
  'UPDATE_UPLOADED_IMAGE',
  'UPLOADING_PROOF',
]

export default new Duck({
  namespace: 'content',
  store: 'orderPage',
  types,
  initialState,
  reducer: (state, action, rDuck) => {
    switch (action.type) {
      case rDuck.types.CLOSE_MODAL:
        return { ...state, isModalOpen: false }
      case rDuck.types.RESET:
        return initialState
      case rDuck.types.UPDATE_EXTEND_DATA:
        return {
          ...state,
          isExtended: action.isExtended,
          isExtendInfoClosed: action.extendInfoStatus,
          isExtendOptionHidden: action.isExtendOptionHidden,
        }
      case rDuck.types.UPDATE_EXTEND_INFO_BOX:
        return { ...state, isExtendInfoClosed: action.status }
      case rDuck.types.UPDATE_EXTEND_MODAL:
        return {
          ...state,
          isExtendButtonClicked: action.extendClicked,
          isModalOpen: action.modalStatus,
        }
      case rDuck.types.UPDATE_EXTEND_SUCCESS:
        return { ...state, extendSuccess: action.status }
      case rDuck.types.UPDATE_HAS_PROOF:
        return { ...state, hasProof: action.status }
      case rDuck.types.UPDATE_IS_PAST:
        return { ...state, isPast: true }
      case rDuck.types.UPDATE_MULTI_STATE:
        return { ...state, ...action.obj }
      case rDuck.types.UPDATE_ORDER_ERROR_MSG:
        return { ...state, errorMessage: action.msg }
      case rDuck.types.UPDATE_ORDER_ID:
        return { ...state, orderID: action.id }
      case rDuck.types.UPDATE_UPLOADED_IMAGE:
        return {
          ...state,
          bankProofErrorMsg: action.bankErrorMsg,
          image: action.img,
          readyToUpload: action.isReady,
        }
      case rDuck.types.UPLOADING_PROOF:
        return {
          ...state,
          ...(![undefined, null].includes(action.gotProof) && {
            hasProof: action.gotProof,
          }),
          uploadingImage: action.uploadingStatus,
        }
      default:
        return state
    }
  },
  creators: (cDuck) => ({
    closeModal: () => ({ type: cDuck.types.CLOSE_MODAL }),
    reset: () => ({ type: cDuck.types.RESET }),
    updateExtendData: (isExtended, extendInfoStatus, isExtendOptionHidden) => ({
      type: cDuck.types.UPDATE_EXTEND_DATA,
      isExtended,
      extendInfoStatus,
      isExtendOptionHidden,
    }),
    updateExtendInfoBox: (status) => ({
      type: cDuck.types.UPDATE_EXTEND_INFO_BOX,
      status,
    }),
    updateExtendModal: (extendClicked = false, modalStatus = false) => ({
      type: cDuck.types.UPDATE_EXTEND_MODAL,
      extendClicked,
      modalStatus,
    }),
    updateExtendSuccess: (status) => ({
      type: cDuck.types.UPDATE_EXTEND_SUCCESS,
      status,
    }),
    updateHasProof: (status) => ({
      type: cDuck.types.UPDATE_HAS_PROOF,
      status,
    }),
    updateIsPast: () => ({ type: cDuck.types.UPDATE_IS_PAST }),
    updateMultiState: (obj) => ({ type: cDuck.types.UPDATE_MULTI_STATE, obj }),
    updateOrderErrorMsg: (msg) => ({
      type: cDuck.types.UPDATE_ORDER_ERROR_MSG,
      msg,
    }),
    updateOrderID: (id) => ({ type: cDuck.types.UPDATE_ORDER_ID, id }),
    updateUploadedImage: (bankErrorMsg, img, isReady) => ({
      type: cDuck.types.UPDATE_UPLOADED_IMAGE,
      bankErrorMsg,
      img,
      isReady,
    }),
    uploadingProof: (uploadingStatus, gotProof) => ({
      type: cDuck.types.UPLOADING_PROOF,
      uploadingStatus,
      gotProof,
    }),
  }),
})

export { initialState as orderPageInitState }
