import clsx from 'clsx'
import PropTypes from 'prop-types'
import { segmentVoucherCopied, segmentVoucherSection } from 'lib/segment'
import { copyToClipboard, throttleCallback } from 'lib/utils/common/commonUtils'
import { useCallback, useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { hasLocizeTranslation } from 'lib/utils/locize'
import Icon from 'components/common/icon'
import ICONS, { ICON_SIZE } from 'components/common/icon/const'
import SnackBar from 'components/common/snack-bar'
import Typo from 'constants/typography'
import { withI18next } from 'lib/i18n/withI18next'
import {
  getIsCoachMarkClosed,
  saveClosedCoachMarkToStorage,
} from 'pages/product/utils'
import { VOUCHER_CHECKOUT_LOCIZE } from 'components/pdp/carousel-images/voucher/voucher-checkout/const'
import { setElementsWidth } from 'components/pdp/carousel-images/voucher/utils'
import styles from './style.scss'
import VoucherList from './voucher-list/VoucherList'

const VOUCHER_COACH_MARK = 'pdp_voucher_coach_mark'

const VoucherListModal = ({
  isLoading,
  isPhone,
  isCheckout,
  productId,
  appliedVoucher,
  voucherList,
  t,
  onVoucherSelected,
  onClose,
}) => {
  // states
  const [isSnackBarOpen, setIsSnackBarOpen] = useState(false)
  const [isOpenCoachMark, setIsOpenCoachMark] = useState(true)
  const [voucherListItemWidth, setVoucherListItemWidth] = useState()
  const modalRef = useRef()
  const voucherPieceRef = useRef(null)
  const localizeHeader = isCheckout
    ? hasLocizeTranslation(
        t,
        VOUCHER_CHECKOUT_LOCIZE.VOUCHER_CHECKOUT_HEADER.key,
        VOUCHER_CHECKOUT_LOCIZE.VOUCHER_CHECKOUT_HEADER.text,
      )
    : hasLocizeTranslation(t, 'VOUCHER_LIST_HEADER', 'New User Vouchers')

  const isPmloVoucherAllUserFlagValue = true // 'EC-VoucherPDP-20220610'
  const isFlagForAllUserValid = isPmloVoucherAllUserFlagValue === true
  const isCoachMarkAlreadyClosed = getIsCoachMarkClosed(
    VOUCHER_COACH_MARK,
    true,
  )
  const isCoachMarkPresent =
    isFlagForAllUserValid && isOpenCoachMark && !isCoachMarkAlreadyClosed

  // events
  const handleCopyVoucherCode = (code) => {
    copyToClipboard(code)
    setIsSnackBarOpen(true)
    segmentVoucherCopied(productId, code)
  }

  const handleSaveCoachMarkClosed = () => {
    setIsOpenCoachMark(false)
    saveClosedCoachMarkToStorage(VOUCHER_COACH_MARK, true)
  }

  const handleHideContent = useCallback(
    (e) => {
      if (modalRef.current && !modalRef.current.contains(e.target)) {
        onClose(e)
      }
    },
    [modalRef, onClose],
  )

  const handleUpdateCoachMarkWidth = useCallback(() => {
    const currentVoucherElemWidth =
      voucherPieceRef?.current?.getBoundingClientRect()?.width

    if (
      currentVoucherElemWidth &&
      currentVoucherElemWidth !== voucherListItemWidth
    ) {
      const roundUpWidth = Math.ceil(currentVoucherElemWidth)

      setVoucherListItemWidth(roundUpWidth)
      setElementsWidth(roundUpWidth)
    }
  }, [voucherListItemWidth])

  const handleScrollToCloseCoachMark = useCallback(() => {
    if (isCoachMarkPresent) {
      handleSaveCoachMarkClosed()
    }
  }, [isCoachMarkPresent])

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (!isPhone && !isCheckout) {
      document.addEventListener('click', handleHideContent, true)

      return () => {
        document.removeEventListener('click', handleHideContent, true)
      }
    }
  }, [handleHideContent, isPhone, isCheckout])

  useEffect(() => {
    if (voucherList?.length && !isCheckout) {
      const options = { productId, voucherList, isVoucherViewed: false }
      segmentVoucherSection(options)
    }
  }, [productId, voucherList, isCheckout])

  useEffect(() => {
    // delay to wait for the first voucher to be rendered, then set the coach-mark width
    const coachMarkTimeOut = setTimeout(() => {
      handleUpdateCoachMarkWidth()
    }, 100)

    const modalElem = modalRef.current

    const setupListeners = () => {
      modalElem.addEventListener('scroll', handleScrollToCloseCoachMark)
      window.addEventListener(
        'resize',
        throttleCallback(handleUpdateCoachMarkWidth, 100),
        true,
      )
    }

    const cleanupListeners = () => {
      modalElem.removeEventListener('scroll', handleScrollToCloseCoachMark)
      window.removeEventListener('resize', handleUpdateCoachMarkWidth)
      clearTimeout(coachMarkTimeOut)
    }

    setupListeners()

    return cleanupListeners
  }, [modalRef, handleScrollToCloseCoachMark, handleUpdateCoachMarkWidth])

  return (
    <div className="pdp__voucher-modal__container">
      <style jsx>{styles}</style>
      <div
        className={clsx('pdp__voucher-modal__header', {
          'with-bottom-border': isFlagForAllUserValid,
        })}
      >
        <Icon
          className="pdp__voucher-modal__close"
          alt="close-icon"
          src={ICONS.close}
          size={ICON_SIZE.medium}
          onClick={onClose}
        />
        <span className={Typo.price1}>{localizeHeader}</span>
        {!isFlagForAllUserValid && (
          <div className="pdp__voucher-modal__info">
            <span className={Typo.body2}>
              {hasLocizeTranslation(
                t,
                'VOUCHER_LIST_TITLE',
                'You can copy the following vouchers and paste in your shopping bag before place order',
              )}
            </span>
          </div>
        )}
      </div>
      <div
        className={clsx('pdp__voucher-modal__info', {
          'for-all-user': isFlagForAllUserValid,
          'for-checkout': isCheckout,
        })}
        data-cy="pdp-voucher-modal-info"
        ref={modalRef}
      >
        <VoucherList
          isLoading={isLoading}
          isCheckout={isCheckout}
          isCoachMarkPresent={isCoachMarkPresent}
          isFlagForAllUserValid={isFlagForAllUserValid}
          isOpenCoachMark={isOpenCoachMark}
          voucherPieceRef={voucherPieceRef}
          voucherList={voucherList}
          appliedVoucher={appliedVoucher}
          onVoucherSelected={onVoucherSelected}
          onCopyVoucherCode={handleCopyVoucherCode}
          onSaveCoachMarkClosed={handleSaveCoachMarkClosed}
        />
      </div>
      <div className="pdp__voucher-modal__snack-bar">
        <SnackBar
          open={isSnackBarOpen}
          duration={10000}
          onClose={() => setIsSnackBarOpen(false)}
        >
          <span className={clsx('text', Typo.caption)}>
            {hasLocizeTranslation(
              t,
              'VOUCHER_COPIED',
              'Code copied, please apply in Shopping Bag!',
            )}
          </span>
        </SnackBar>
      </div>
    </div>
  )
}

VoucherListModal.defaultProps = {
  isLoading: false,
  appliedVoucher: '',
  productId: '',
  t: undefined,
  onVoucherSelected: undefined,
}

VoucherListModal.propTypes = {
  isLoading: PropTypes.bool,
  isPhone: PropTypes.bool.isRequired,
  isCheckout: PropTypes.bool.isRequired,
  appliedVoucher: PropTypes.string,
  voucherList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  productId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onClose: PropTypes.func.isRequired,
  t: PropTypes.func,
  onVoucherSelected: PropTypes.func,
}

export default compose(
  connect((state) => ({
    isPhone: state.device.isPhone,
  })),
  withI18next(),
)(VoucherListModal)
