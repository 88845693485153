import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import Link from 'next/link'
import Icon from 'components/common/icon'
import { ICON_SIZE } from 'components/common/icon/const'
import { rearrangeFooterIconByShop } from 'components/footer/FooterHook'

const FooterIconList = ({ internationalization: { shop }, IconLists }) => {
  const iconRearranged = rearrangeFooterIconByShop(IconLists, shop)

  return (
    <ul className="list-row list-icons">
      {iconRearranged.map(({ href, key, src, alt }) => (
        <li key={key} data-cy={`footer_${key.replace('SVG', '')}`}>
          <Link href={href}>
            <a
              target="_blank"
              rel="nofollow noopener noreferrer"
              aria-label={alt}
            >
              <Icon
                size={ICON_SIZE.medium}
                src={src}
                alt={key}
                lazyThreshold={0.9}
              />
            </a>
          </Link>
        </li>
      ))}
    </ul>
  )
}

FooterIconList.propTypes = {
  internationalization: PropTypes.shape({
    shop: PropTypes.number,
  }).isRequired,
  IconLists: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
}

export default compose(
  connect((state) => ({
    internationalization: state.internationalization,
  })),
)(FooterIconList)
