import clsx from 'clsx'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import Button from 'components/common/button'
import checkoutDuck from 'components/checkout/duck'
import { customFlagValue, isFlagEligible } from 'lib/utils/handle-flag/const'
import { withI18next } from 'lib/i18n/withI18next'
import {
  segmentCouponApplied,
  segmentCouponFailed,
  segmentVoucherCheckoutClicked,
} from 'lib/segment'
import {
  CHECKOUT_SUB_STEPS,
  isCartTotalZero,
  getCartParams,
} from 'lib/utils/checkout/checkoutUtils'
import Typo from 'constants/typography'
import { BUTTON_TYPES } from 'constants/button'
import useModal from 'components/portal/useModal'
import BackdropModal from 'components/portal/backdrop-modal'
import Icon from 'components/common/icon'
import ICONS, { ICON_SIZE } from 'components/common/icon/const'
import TextField from 'components/common/textfield'
import { hasLocizeTranslation } from 'lib/utils/locize'
import VoucherCheckout from 'components/pdp/carousel-images/voucher/voucher-checkout'
import { VOUCHER_CHECKOUT_LOCIZE } from 'components/pdp/carousel-images/voucher/voucher-checkout/const'
import cartDuck from 'components/cart/duck'
import cartItemDuck from 'components/cart/cart-items/duck'
import voucherDuck from 'components/cart/cart-voucher-code/duck'
import { SNACKBAR_TYPE } from 'components/cart/cart-snackbar'
import CartCredits from './cart-credits'
import styles from './styles.scss'

const UNKNOWN_ERROR = 'An unknown error has occurred.'
const CartDiscount = ({
  applyStoreCredit,
  cart,
  checkoutModel,
  cartProducts,
  fromSummary,
  getCart,
  isPhone,
  resetCart,
  loadCart,
  unloadCart,
  resetVoucher,
  setError,
  t,
  updateCartItem,
  updateSnackbar,
  updateVoucherErrorMessage,
  updatePayment,
  getVoucher,
  voucherErrorMessage,
}) => {
  const getCallCartOptions = (isStoreCreditUsed) =>
    fromSummary
      ? getCartParams(checkoutModel, isStoreCreditUsed)
      : {
          type: 'GET',
          body: {
            is_store_credits_applied: isStoreCreditUsed,
          },
        }

  const [cartVoucher, setCartVoucher] = useState('')
  const [discountedAmount, setDiscountedAmount] = useState('')
  const [isVoucherModalOpen, setIsVoucherModalOpen] = useModal(false)
  const voucherCheckoutFlag = customFlagValue({
    flagName: 'voucherCheckout',
  }) // TODO: force value to handle with flagsmith later (VoucherCheckout-20220711)
  const shoppingBagRedesignFlagValue = customFlagValue({
    flagName: 'shoppingBagRedesign',
  }) // TODO: force value to handle with flagsmith later (ShoppingBagRedesign-20230101)
  const isShowAddVoucherButton = isFlagEligible(voucherCheckoutFlag)
  const isShoppingBagNewDesign = isFlagEligible(shoppingBagRedesignFlagValue)
  const cashbackNotice = cart?.summary?.notice?.cashback
  const isShowCashbackNotice = Boolean(cashbackNotice)

  useEffect(() => {
    const applied_voucher = cartProducts?.summary?.applied_voucher
    updateSnackbar(null)
    if (applied_voucher) {
      setDiscountedAmount(applied_voucher.discount_formatted)
      setCartVoucher(applied_voucher.code)
      updateSnackbar('Voucher applied', SNACKBAR_TYPE.info)
    }
  }, [cartProducts?.summary?.applied_voucher, updateSnackbar])

  const updateVoucherState = (e) => setCartVoucher(e.target.value)

  const trackCouponFailed = (voucherCode, id_cart, response) => {
    const { error, status } = response || {}
    let reason = error || response || t('UNKNOWN_ERROR')
    reason =
      typeof reason === 'string' || reason instanceof String
        ? reason
        : `${t('UNKNOWN_ERROR')} ${JSON.stringify(response, null, 2)}`
    segmentCouponFailed(
      voucherCode,
      id_cart,
      status || 0,
      reason,
      window.location.href,
    )
  }

  const clearVoucherCode = () => {
    setCartVoucher('')
    setDiscountedAmount('')
  }

  const setVoucherMessage = (message) => {
    if (fromSummary) {
      setError(message)
    } else {
      updateVoucherErrorMessage(message)
    }
  }

  const applyVoucher = async ({ type, voucherCode, apply_method }) => {
    setVoucherMessage(null)
    resetVoucher()
    loadCart()
    const voucherResponse = await getVoucher({
      type,
      voucherCode,
    })
    unloadCart()

    if (!voucherResponse) {
      trackCouponFailed(
        voucherCode,
        cartProducts.metadata.id_cart,
        voucherResponse,
      )
      setVoucherMessage(t(UNKNOWN_ERROR))
      return
    }

    if (voucherResponse?.status === 1) {
      segmentCouponApplied(
        voucherCode,
        cartProducts.metadata.id_cart,
        apply_method,
      )
      resetCart()
      getCart(getCallCartOptions(applyStoreCredit))
    } else {
      trackCouponFailed(
        voucherCode,
        cartProducts.metadata.id_cart,
        voucherResponse,
      )
      const { error } = voucherResponse
      // Error from 3rd party voucher will response as object
      setVoucherMessage(error || voucherResponse || t(UNKNOWN_ERROR))
    }
    clearVoucherCode()
  }

  const handleVoucherCode = () => {
    if (cartVoucher) {
      applyVoucher({
        type: 'USE_VOUCHER',
        voucherCode: cartVoucher,
        apply_method: 'organic',
      })
    }
  }

  const resetVoucherCode = () => {
    applyVoucher({
      type: 'REMOVE_VOUCHER',
      voucherCode: '',
      apply_method: 'organic',
    })
  }

  const onApplyStoreCreditsSuccessfuly = (res) => {
    updateCartItem({
      cartOpen: true,
      couponUsed: null,
      freeGiftOpen: false,
      isStoreCreditUsed: true,
    })

    if (fromSummary && isCartTotalZero(res)) {
      updatePayment({
        paymentMethod: {
          method: CHECKOUT_SUB_STEPS.STORE_CREDITS.id,
          type: CHECKOUT_SUB_STEPS.STORE_CREDITS.id,
          name: CHECKOUT_SUB_STEPS.STORE_CREDITS.title,
        },
      })
    }
  }

  const handleStoreCredit = () => {
    resetCart()
    updateCartItem({
      cartOpen: true,
      couponUsed: null,
      freeGiftOpen: false,
      isStoreCreditUsed: true,
    })
    getCart(getCallCartOptions(true)).then((res) =>
      onApplyStoreCreditsSuccessfuly(res),
    )
  }

  const resetStoreCredit = () => {
    if (fromSummary && isCartTotalZero(cart)) {
      updatePayment({
        paymentMethod: {},
      })
    }

    resetCart()
    updateCartItem({
      cartOpen: true,
      couponUsed: null,
      freeGiftOpen: false,
      isStoreCreditUsed: false,
    })
    getCart(getCallCartOptions(false))
  }

  const hasVoucherWarningMsg = () => {
    if (!cartProducts) {
      return undefined
    }

    if (!cartProducts.summary.applied_voucher?.is_applied) {
      return cartProducts.summary.notice.voucher?.message
    }

    return null
  }

  const onVoucherKeyPress = (e) => {
    if (
      cartVoucher &&
      e &&
      (e.key === 'Enter' || e.keyCode === 13 || e.charCode === 13)
    ) {
      handleVoucherCode()
    }
  }

  const voucherWarningMsg = hasVoucherWarningMsg()

  const handleVoucherCheckoutButton = () => {
    setIsVoucherModalOpen(true)
    segmentVoucherCheckoutClicked()
  }

  // TODO: new design not separate view between promo code and voucher
  const renderCartPromoSection = () => {
    const icon =
      fromSummary && voucherWarningMsg ? ICONS.error : ICONS.promoCodeVerified

    if (discountedAmount) {
      return (
        <div className="cart-discount__main is-applied">
          <div className="cart-discount__card">
            <div className="cart-discount__usable">
              <span className={Typo.price1}>{cartVoucher}</span>
              <Icon
                src={icon}
                size={ICON_SIZE.small}
                className="cart-discount__icon"
              />
            </div>
            <div className="cart-discount__usable">
              <span className={clsx(Typo.price2, 'cart-discount__price')}>
                {discountedAmount}
              </span>
              <Icon
                src={ICONS.close}
                size={ICON_SIZE.small}
                onClick={resetVoucherCode}
                className="cart-discount__remove"
              />
            </div>
          </div>
        </div>
      )
    }
    return (
      <div className="cart-discount__main">
        <TextField
          fullWidth
          onChange={updateVoucherState}
          onKeyPress={onVoucherKeyPress}
          placeholder={t('Enter Promo Code')}
          value={cartVoucher}
        />
        <Button isDisabled={!cartVoucher} onClick={handleVoucherCode}>
          {t('Apply')}
        </Button>
      </div>
    )
  }

  const voucherMsg = voucherWarningMsg || voucherErrorMessage
  const voucherMsgJsx = !!voucherMsg && (
    <div className="cart-discount__main">
      <span className={clsx(Typo.caption, 'cart-discount__voucher-messages')}>
        {voucherMsg}
      </span>
    </div>
  )
  const cartCreditsJsx = !!cartProducts?.summary?.store_credits?.available && (
    <CartCredits
      availableCredits={cartProducts.summary.store_credits}
      isFromCheckoutSummary={fromSummary}
      handleStoreCredit={handleStoreCredit}
      resetStoreCredit={resetStoreCredit}
      isPhone={isPhone}
    />
  )

  const addVoucherButton = isShowAddVoucherButton && (
    <Button
      className="cart-discount__voucher-cta"
      type={BUTTON_TYPES.link}
      onClick={handleVoucherCheckoutButton}
    >
      <Icon src={ICONS.voucherPurple} size={ICON_SIZE.medium} />
      {hasLocizeTranslation(
        t,
        VOUCHER_CHECKOUT_LOCIZE.VOUCHER_CHECKOUT_ADD.key,
        VOUCHER_CHECKOUT_LOCIZE.VOUCHER_CHECKOUT_ADD.text,
      )}
    </Button>
  )

  return (
    <div
      className={clsx('cart-discount', {
        'new-design': isShoppingBagNewDesign && !fromSummary,
      })}
    >
      <style jsx>{styles}</style>
      <div className={clsx(Typo.subtitle1, 'cart-discount__title')}>
        {hasLocizeTranslation(
          t,
          'CART_DISCOUNT_TITLE',
          'Promotions & Discounts',
        )}
      </div>
      {isShowCashbackNotice && (
        <div className="cart-discount__cashback-notice">
          <span className={Typo.body2}>{cashbackNotice}</span>
        </div>
      )}
      {cartCreditsJsx}
      {renderCartPromoSection()}
      {voucherMsgJsx}
      {addVoucherButton}
      {isShowAddVoucherButton && (
        <BackdropModal isOpen={isVoucherModalOpen} zIndex={1001}>
          <VoucherCheckout
            onClose={() => setIsVoucherModalOpen(false)}
            applyVoucher={applyVoucher}
          />
        </BackdropModal>
      )}
    </div>
  )
}

CartDiscount.defaultProps = {
  applyStoreCredit: undefined,
  cart: undefined,
  checkoutModel: {},
  cartProducts: undefined,
  fromSummary: undefined,
  getCart: undefined,
  isPhone: undefined,
  resetCart: undefined,
  loadCart: undefined,
  unloadCart: undefined,
  resetVoucher: undefined,
  setError: undefined,
  t: undefined,
  updateCartItem: undefined,
  updateSnackbar: undefined,
  updateVoucherErrorMessage: undefined,
  updatePayment: undefined,
  getVoucher: undefined,
  voucherErrorMessage: undefined,
}
CartDiscount.propTypes = {
  applyStoreCredit: PropTypes.bool,
  cart: PropTypes.shape({
    summary: PropTypes.shape({
      notice: PropTypes.shape({ cashback: PropTypes.string }),
    }),
  }),
  checkoutModel: PropTypes.shape({ isTTBDepositFlagEnable: PropTypes.bool }),
  cartProducts: PropTypes.shape({
    metadata: PropTypes.shape({
      id_cart: PropTypes.number,
    }),
    summary: PropTypes.shape({
      store_credits: PropTypes.shape({
        available: PropTypes.number,
      }),
      notice: PropTypes.shape({
        voucher: PropTypes.shape({
          message: PropTypes.string,
        }),
      }),
      applied_voucher: PropTypes.shape({
        discount_formatted: PropTypes.string,
        is_applied: PropTypes.bool,
        code: PropTypes.string,
      }),
    }),
  }),
  fromSummary: PropTypes.bool,
  getCart: PropTypes.func,
  isPhone: PropTypes.bool,
  resetCart: PropTypes.func,
  loadCart: PropTypes.func,
  unloadCart: PropTypes.func,
  resetVoucher: PropTypes.func,
  setError: PropTypes.func,
  t: PropTypes.func,
  updateCartItem: PropTypes.func,
  updateSnackbar: PropTypes.func,
  updateVoucherErrorMessage: PropTypes.func,
  updatePayment: PropTypes.func,
  getVoucher: PropTypes.func,
  voucherErrorMessage: PropTypes.shape({}),
}

const Extended = withI18next()(CartDiscount)

export default connect(
  (state) => ({
    applyStoreCredit: state.cartData.isStoreCreditUsed,
    cart: state.cart.payload,
    checkoutModel: state.checkout,
    cartOpen: state.cartData.cartOpen,
    couponUsed: state.cartData.couponUsed,
    freeGiftOpen: state.cartData.freeGiftOpen,
    isPhone: state.device.isPhone,
  }),
  (dispatch) =>
    bindActionCreators(
      {
        getCart: cartDuck.creators.get,
        resetCart: cartDuck.creators.invalidate,
        loadCart: cartDuck.creators.request,
        unloadCart: cartDuck.creators.done,
        resetVoucher: voucherDuck.creators.invalidate,
        updateCartItem: cartItemDuck.creators.update,
        updateSnackbar: cartItemDuck.creators.updateSnackbar,
        updateVoucherErrorMessage:
          cartItemDuck.creators.updateVoucherErrorMessage,
        updatePayment: checkoutDuck.creators.updatePayment,
        getVoucher: voucherDuck.creators.get,
      },
      dispatch,
    ),
)(Extended)
