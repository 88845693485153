import PropTypes from 'prop-types'
import React, { useMemo } from 'react'
import clsx from 'clsx'
import Typo from 'constants/typography'
import styles from './style.scss'

/**
 * @param {function} param0.onChange event callback when value inside input was changed
 * @param {element} param0.prefix the adornment that will be show at start of input
 * @param {element} param0.postfix the adornment that will be show at end of input
 * @param {object} param0.inputRef the ref of input element
 * @param {boolean} param0.fullWidth flag to set the component display with full width.
 * @param {boolean} param0.disabled flag to disable input
 * @param {boolean} param0.error flag to set error appearance
 * @param {boolean} param0.hideUnderline flag to show/hide underline
 * @param {number} param0.adornmentWidth set width for prefix area
 * @param {string} param0.typo set typo for input
 */
const InputBase = ({
  onChange,
  inputRef,
  fullWidth,
  prefix,
  postfix,
  error,
  adornmentWidth,
  typo,
  hideUnderline,
  fieldId,
  ...props
}) => {
  const adornmentStyles = useMemo(
    () => ({
      width: adornmentWidth,
    }),
    [adornmentWidth],
  )

  return (
    <div
      data-cy="pml-input-wrapper"
      className={clsx('pml-input__wrapper', {
        'full-width': fullWidth,
        error,
        'hide-underline': hideUnderline,
      })}
    >
      <style jsx>{styles}</style>
      {prefix && (
        <div
          data-cy="pml-input__prefix"
          style={adornmentStyles}
          className="pml-input__adornment start"
        >
          {prefix}
        </div>
      )}
      <input
        className={clsx(typo, 'pml-input__input')}
        onChange={onChange}
        data-cy={fieldId || 'pml-input'}
        ref={inputRef}
        {...props}
      />
      {postfix && (
        <div
          data-cy="pml-input__postfix"
          style={adornmentStyles}
          className="pml-input__adornment end"
        >
          {postfix}
        </div>
      )}
    </div>
  )
}

InputBase.defaultProps = {
  onChange: undefined,
  prefix: undefined,
  postfix: undefined,
  inputRef: undefined,
  fieldId: undefined,
  fullWidth: false,
  error: false,
  hideUnderline: false,
  adornmentWidth: undefined,
  typo: Typo.body1,
}

InputBase.propTypes = {
  inputRef: PropTypes.oneOfType([
    PropTypes.shape({
      current: PropTypes.shape({}),
    }),
    PropTypes.func,
  ]),
  fieldId: PropTypes.string,
  fullWidth: PropTypes.bool,
  hideUnderline: PropTypes.bool,
  prefix: PropTypes.element,
  postfix: PropTypes.element,
  onChange: PropTypes.func,
  error: PropTypes.bool,
  adornmentWidth: PropTypes.number,
  typo: PropTypes.oneOf(Object.values(Typo)),
}

export default React.memo(InputBase)
