import clsx from 'clsx'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Countries from 'components/addresses/countries'
import CartBody from 'components/cart'
import { trackCartViewed } from 'components/cart/utils'
import cartItemsDuck from 'components/cart/cart-items/duck'
import { CART_FREE_GIFT_MODAL_ID } from 'components/cart/free-gift/const'
import BackdropModal from 'components/portal/backdrop-modal'
import FallBackModal, { FALLBACK_TYPE } from 'components/portal/fall-back-modal'
import SearchSection from 'components/search/search-section'
import { EVENT } from 'lib/segment/const'
import { toggleBodyFreezing } from 'lib/utils/common/commonUtils'
import { DynamicFreeGift } from '../utils'

const HeaderModals = ({
  activeModal,
  applyStoreCredit,
  cartProducts,
  customerData,
  internationalization: { country, shop },
  isCartOpen,
  isFreeGiftOpen,
  setSearchWord,
  setShowCountrySelectorModal,
  showCountrySelectorModal,
  showSearchModal,
  toggleSearch,
  updateCart,
}) => {
  const [apiResError, setApiResError] = useState(false)

  // Use istanbul ignore for now
  // because this function cannot be tested due to cannot render the dialog component under the backdrop modal
  /* istanbul ignore next */
  const closeCountrySelectorModal = (e) => {
    e?.preventDefault()
    setShowCountrySelectorModal(false)
  }

  const onUpdateCart = () => {
    trackCartViewed(cartProducts, country, shop, EVENT.leftCart)

    updateCart({
      cartOpen: false,
      couponUsed: null,
      freeGiftOpen: false,
      isStoreCreditUsed: applyStoreCredit,
    })
  }

  const showFallbackModal = async () => {
    await updateCart({
      cartOpen: false,
      couponUsed: null,
      freeGiftOpen: false,
      isStoreCreditUsed: applyStoreCredit,
    })

    setApiResError(true)
  }

  useEffect(() => {
    if (showSearchModal) {
      toggleBodyFreezing(true)
    }

    return () => {
      toggleBodyFreezing()
    }
  }, [showSearchModal])

  return (
    <React.Fragment>
      {!showSearchModal && (
        <div
          className={clsx('header-search-form', {
            active: showSearchModal,
          })}
          onClick={toggleSearch}
          role="presentation"
        />
      )}
      {showSearchModal && (
        <SearchSection onClose={toggleSearch} setSearchWord={setSearchWord} />
      )}
      <div
        className={clsx('shopping-bag-container-modal', {
          active: isCartOpen,
        })}
        onKeyDown={onUpdateCart}
        onClick={onUpdateCart}
        role="presentation"
      />
      <div
        className={clsx('shopping-bag-container', {
          active: isCartOpen,
        })}
      >
        {customerData?.isFetched && (
          <CartBody showFallbackModal={showFallbackModal} />
        )}
      </div>
      <BackdropModal
        isOpen={isFreeGiftOpen && activeModal.includes(CART_FREE_GIFT_MODAL_ID)}
        // Override auth drawer/modal
        zIndex="100004"
      >
        <DynamicFreeGift />
      </BackdropModal>
      {apiResError && <FallBackModal modalType={FALLBACK_TYPE.reload} />}
      <Countries
        isOpen={showCountrySelectorModal}
        onClose={closeCountrySelectorModal}
      />
    </React.Fragment>
  )
}

HeaderModals.defaultProps = {
  applyStoreCredit: undefined,
  cartProducts: {},
  customerData: undefined,
  isCartOpen: false,
  isFreeGiftOpen: false,
  showCountrySelectorModal: false,
  showSearchModal: false,
}

HeaderModals.propTypes = {
  activeModal: PropTypes.arrayOf(PropTypes.string).isRequired,
  applyStoreCredit: PropTypes.bool,
  cartProducts: PropTypes.shape({
    items: PropTypes.arrayOf(
      PropTypes.shape({
        id_product: PropTypes.number,
      }),
    ),
    summary: PropTypes.shape({
      subtotal: PropTypes.number,
      total: PropTypes.number,
    }),
  }),
  customerData: PropTypes.shape({ isFetched: PropTypes.bool }),
  internationalization: PropTypes.shape({
    country: PropTypes.string,
    shop: PropTypes.number,
  }).isRequired,
  isCartOpen: PropTypes.bool,
  isFreeGiftOpen: PropTypes.bool,
  setSearchWord: PropTypes.func.isRequired,
  setShowCountrySelectorModal: PropTypes.func.isRequired,
  showCountrySelectorModal: PropTypes.bool,
  showSearchModal: PropTypes.bool,
  toggleSearch: PropTypes.func.isRequired,
  updateCart: PropTypes.func.isRequired,
}

export default connect(
  (state) => ({
    activeModal: state.modal.active,
    applyStoreCredit: state.cartData.isStoreCreditUsed,
    cartProducts: state.cart.payload,
    customerData: state.auth.customerData,
    internationalization: state.internationalization,
    isCartOpen: state.cartData.cartOpen,
    isFreeGiftOpen: state.cartData.freeGiftOpen,
    showSearchModal: state.searchModal.showSearchModal,
  }),
  (dispatch) =>
    bindActionCreators(
      {
        updateCart: cartItemsDuck.creators.update,
      },
      dispatch,
    ),
)(HeaderModals)
