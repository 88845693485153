import PropTypes from 'prop-types'
import { useState, useEffect, useCallback, useMemo } from 'react'
import Router from 'next/router'
import clsx from 'clsx'
import CountryApiHandler from 'lib/api/country'
import StateStorage from 'lib/state-storage'
import Button from 'components/common/button'
import Typo from 'constants/typography'
import { BUTTON_TYPES } from 'constants/button'
import Dialog from 'components/common/dialog'
import BasePage from 'pages/base'
import { ENABLE_SHOP } from 'constants/shop-language'
import { getShopCountryID } from 'lib/utils/shop-lang'
import { hasLocizeTranslation } from 'lib/utils/locize'
import { withI18next } from 'lib/i18n/withI18next'
import BackdropModal from 'components/portal/backdrop-modal'
import styles from './style.scss'
import {
  COUNTRIES_MODAL,
  minOrderFreeDelivery,
  FREE_SHIPPING_OVER_100_USD_COUNTRIES,
} from './const'

const Countries = ({ isOpen, onClose, t }) => {
  const [countries, setCoutries] = useState([])
  const [selectedCountryID, setSelectedCountryID] = useState('')
  const isNoneCountryId = selectedCountryID === 'none'
  const isFreeShippingOver100USDCountries =
    FREE_SHIPPING_OVER_100_USD_COUNTRIES.includes(selectedCountryID)
  const freeShippingInfoText = isFreeShippingOver100USDCountries
    ? 'Free shipping over $100 USD.'
    : hasLocizeTranslation(
        t,
        minOrderFreeDelivery.key,
        minOrderFreeDelivery.text,
      )

  useEffect(() => {
    CountryApiHandler.getCountry()
      .then((data) => {
        setCoutries(data.countries)
      })
      .catch(() => {
        setCoutries([])
      })
  }, [])

  const otherCountries = useMemo(() => {
    const mainCountriesIDList = getShopCountryID(ENABLE_SHOP)
    return countries.filter(
      (country) => !mainCountriesIDList.includes(country.id_country),
    )
  }, [countries])

  const save = useCallback(
    (e) => {
      e.preventDefault()

      const country = COUNTRIES_MODAL[selectedCountryID]
      const shop = StateStorage.getShop()
      const lang = StateStorage.getLanguage()
      const path = BasePage.getCleanedPath(Router.asPath, { zone: shop, lang })

      onClose()

      if (selectedCountryID) {
        StateStorage.saveCountry(selectedCountryID)
      }
      if (country) {
        Router.push(`${country.prefix}${path}`)
      }
    },
    [onClose, selectedCountryID],
  )

  const renderOtherCountries = useCallback(
    () =>
      otherCountries.map(({ id_country, name }) => (
        <option key={id_country} value={id_country}>
          {name || ''}
        </option>
      )),
    [otherCountries],
  )

  return (
    <BackdropModal isOpen={isOpen}>
      <Dialog className="country-list-map-container" onClose={onClose} closable>
        <style jsx>{styles}</style>
        <h3 className={clsx('global__title', Typo.h3)}>{t('Hello, World!')}</h3>
        <div className={clsx('global__content', Typo.body1)}>
          {t('Pomelo ships to over 43 countries.')}
          <br />
          {t("See if you're in one of them.")}
        </div>
        <div className="country-list-map__selector-wrapper">
          <select
            className={clsx('country-list-map__selector', Typo.body2)}
            value={selectedCountryID}
            onChange={(e) => setSelectedCountryID(e.target.value)}
          >
            <option value="">Select your shipping country</option>
            {renderOtherCountries()}
            <option value="none">{t('None of the above')}</option>
          </select>
        </div>
        {selectedCountryID && !isNoneCountryId && (
          <div className={clsx('shipping-fee', Typo.body1)}>
            {freeShippingInfoText}
          </div>
        )}
        {isNoneCountryId && (
          <div className={clsx('none', Typo.body1)}>
            {t(
              "Oh no! Pomelo currently doesn't ship to your country, but keep checking back - we're always expanding to new places.",
            )}
          </div>
        )}
        <Button
          type={BUTTON_TYPES.primary}
          className="country-list-map__cta"
          onClick={save}
        >
          {t('Continue')}
        </Button>
      </Dialog>
    </BackdropModal>
  )
}

Countries.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  // Injected Props
  t: PropTypes.func.isRequired,
}

export default withI18next()(Countries)
