/**
 * An object that contains the reference for the typography classnames.
 *
 * The classnames (value) here should match the what is declared in _fonts.scss.
 */
const Typo = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  h6: 'h6',
  subtitle1: 'subtitle1',
  subtitle2: 'subtitle2',
  body1: 'body1',
  body2: 'body2',
  button: 'button',
  caption: 'caption',
  overline: 'overline',
  price1: 'price1',
  price2: 'price2',
  title1: 'h3',
  title2: 'title2',
  title3: 'title3',
  h1SEO: 'h1SEO',
}

export default Typo
