import env from 'config/env'

export const SEGMENT_SCRIPT = `
!function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on"];analytics.factory=function(t){return function(){var e=Array.prototype.slice.call(arguments);e.unshift(t);analytics.push(e);return analytics}};for(var t=0;t<analytics.methods.length;t++){var e=analytics.methods[t];analytics[e]=analytics.factory(e)}analytics.load=function(t,e){var n=document.createElement("script");n.type="text/javascript";n.async=!0;n.src="https://cdn.segment.com/analytics.js/v1/"+t+"/analytics.min.js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(n,a);analytics._loadOptions=e};analytics.SNIPPET_VERSION="4.1.0";analytics.load("${env.SEGMENT_KEY}");analytics.page();}}();
`

export const SOCIAL_PROFILE = `
{"@context":"http://schema.org","@type":"Organization","name":"Pomelo Fashion","url":"https://www.pomelofashion.com/","logo":"${env.IMG_HOST}img/pomelo-logo-square.jpg","sameAs":["https://www.facebook.com/pomelofashion","https://www.instagram.com/pomelofashion/","https://twitter.com/pomelofashion","https://www.linkedin.com/company/pomelo-fashion"]}
`
export const PAGE_NAME = {
  about: 'about',
  bankTransfer: 'bank_transfer',
  brand: 'brand',
  category: 'category',
  error: 'error',
  faq: 'faq',
  feedback: 'feedback',
  home: 'homefeed',
  justForYou: 'just-for-you',
  lookbook: 'lookbook',
  lookbooks: 'lookbooks',
  loyalty: 'loyalty',
  offlineStore: 'offline_store',
  pdp: 'product',
  pickupDetail: 'pickup_detail',
  popStation: 'pop_station_return',
  press: 'press',
  purpose: 'purpose',
  recentlyView: 'recently_viewed',
  referral: 'referral',
  referralWelcome: 'referral_welcome',
  return: 'return',
  review: 'review',
  search: 'search',
  similar: 'similar',
  ttbHome: 'ttb_landing_page',
  unsub: 'unsubscribe',
  upSell: 'people-also-bought',
  user: 'user',
  whyPmlo: 'why_pomelo',
  wishlist: 'wishlist',
}
