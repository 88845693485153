import React from 'react'
import ContentLoader from 'react-content-loader'
import styles from './styles.scss'

const RecentlyViewSkeleton = () => (
  <div className="recently-view__skeleton">
    <style jsx>{styles}</style>
    <ContentLoader
      uniquekey="recently-view__content-loader"
      width={100}
      height={85.5}
      speed={2}
      primaryColor="#f3f3f3"
      secondaryColor="#ecebeb"
    >
      <rect x="0" y="1" width={40} height={3} />
      <rect x="0" y="9" width={37} height={50} />
      <rect x="0" y="62" width={20} height={2} />
      <rect x="0" y="67" width={37} height={3} />
      <rect x="0" y="74" width={12} height={3} />
      <rect x="0" y="79" width={24} height={10} />
      <rect x="40" y="9" width={37} height={50} />
      <rect x="40" y="62" width={20} height={2} />
      <rect x="40" y="67" width={37} height={3} />
      <rect x="40" y="74" width={12} height={3} />
      <rect x="40" y="79" width={24} height={10} />
      <rect x="80" y="9" width={37} height={50} />
      <rect x="80" y="62" width={20} height={2} />
      <rect x="80" y="67" width={37} height={3} />
      <rect x="80" y="74" width={12} height={3} />
      <rect x="80" y="79" width={24} height={10} />
    </ContentLoader>
  </div>
)

export default RecentlyViewSkeleton
