import clsx from 'clsx'
import PropTypes from 'prop-types'
import Typo from 'constants/typography'
import { withI18next } from 'lib/i18n/withI18next'
import { getPriceClass, isLowInStock, freeGiftPriceClass } from '../utils'
import styles from './styles.scss'

const CartProductPrice = ({
  product,
  selectedAttribute,
  isFreeGift,
  isSoldOut,
  isShoppingBagNewDesign,
  fromSummary,
  t,
}) => {
  if (isFreeGift) {
    return (
      <div
        className={clsx('cart-item-info__product-price', {
          'new-design': isShoppingBagNewDesign,
        })}
      >
        <style jsx>{styles}</style>
        <span className={freeGiftPriceClass(isShoppingBagNewDesign)}>
          {t('Free')}
        </span>
      </div>
    )
  }

  const isDiscounted = product.is_sale || product.prices.discounted_price

  // we hide product stock when it is sold out, in checkout summary page and new shopping design
  const isLowStockDisplay =
    isLowInStock(isSoldOut, product, selectedAttribute) &&
    !fromSummary &&
    !isShoppingBagNewDesign

  const {
    original_price_formatted: priceValue,
    discounted_price_formatted: discountValue,
  } = product.prices

  return (
    product && (
      <div
        className={clsx('cart-item-info__product-price', {
          'new-design': isShoppingBagNewDesign,
        })}
      >
        <style jsx>{styles}</style>
        <span className={getPriceClass(discountValue, isSoldOut)}>
          {priceValue}
        </span>
        {isDiscounted && (
          <span
            className={clsx(Typo.price1, 'cart-item-info__price-discounted')}
          >
            {discountValue}
          </span>
        )}
        {isLowStockDisplay && (
          <span className={clsx(Typo.caption, 'cart-item-info__stock')}>
            {`${selectedAttribute.stock.quantity} ${t('Left')}`}
          </span>
        )}
      </div>
    )
  )
}

CartProductPrice.defaultProps = {
  fromSummary: false,
  isShoppingBagNewDesign: false,
}

CartProductPrice.propTypes = {
  product: PropTypes.shape({
    id_product: PropTypes.number,
    type: PropTypes.string,
    prices: PropTypes.shape({
      original_price: PropTypes.number,
      original_price_formatted: PropTypes.string,
      discounted_price_formatted: PropTypes.string,
      discounted_price: PropTypes.number,
    }),
    is_available: PropTypes.bool,
    is_sale: PropTypes.bool,
    quantity: PropTypes.number,
  }).isRequired,
  selectedAttribute: PropTypes.shape({
    stock: PropTypes.shape({
      quantity: PropTypes.number,
      is_low_quantity: PropTypes.bool.isRequired,
      is_sold_out: PropTypes.bool.isRequired,
    }),
  }).isRequired,
  isFreeGift: PropTypes.bool.isRequired,
  isSoldOut: PropTypes.bool.isRequired,
  isShoppingBagNewDesign: PropTypes.bool,
  fromSummary: PropTypes.bool,
  t: PropTypes.func.isRequired,
}

export default withI18next()(CartProductPrice)
