import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import clsx from 'clsx'
import { withI18next } from 'lib/i18n/withI18next'
import Icon from 'components/common/icon'
import ICONS, { ICON_SIZE } from 'components/common/icon/const'
import Typo from 'constants/typography'
import Button from 'components/common/button'
import { BUTTON_TYPES } from 'constants/button'
import { hasLocizeTranslation } from 'lib/utils/locize'
import { useAuth } from '../context'
import { AUTH_PROVIDER, AUTH_UI_STATE } from '../const'
import style from './style.scss'

const AuthFooterBase = ({ t }) => {
  const [authState, updateAuthState] = useAuth()
  const { uiState } = authState
  const isLogInState = uiState === AUTH_UI_STATE.logIn
  const toggleAuthState = useCallback(() => {
    updateAuthState({
      isSelectSignUpMethod: false,
      uiState: isLogInState ? AUTH_UI_STATE.signUp : AUTH_UI_STATE.logIn,
    })
  }, [updateAuthState, isLogInState])

  const onSocialMediaClicked = async (provider) => {
    updateAuthState({
      source: AUTH_PROVIDER[provider],
      uiState: AUTH_UI_STATE.thirdPartyVerify,
    })
  }

  const getSocialMediaButton = (provider) => {
    const loginIcon =
      provider === 'facebook' ? ICONS.facebookLogin : ICONS.google
    return (
      <button
        className="auth__social__cta"
        data-cy={`auth__login__${provider}`}
        onClick={() => onSocialMediaClicked(provider)}
        type="button"
      >
        <Icon src={loginIcon} size={ICON_SIZE.extraLarge} />
        <span className={clsx(Typo.body2, 'auth__social__label')}>
          {provider}
        </span>
      </button>
    )
  }

  return (
    <div className="auth__footer">
      <style jsx>{style}</style>
      <span className={clsx(Typo.body2, 'auth__footer-title')}>
        {t('or just')}
      </span>
      <div className="auth__social__wrapper">
        {getSocialMediaButton('facebook')}
        {getSocialMediaButton('google')}
      </div>
      <span className={Typo.subtitle2}>
        {t(
          isLogInState ? "Don't have an account?" : 'Already have an account?',
        )}
        <Button
          cy={`auth__go__${isLogInState ? 'sign_up' : 'log_in'}`}
          className="auth__bottom__acc__cta"
          type={BUTTON_TYPES.link}
          onClick={toggleAuthState}
        >
          {isLogInState
            ? hasLocizeTranslation(t, 'AUTH_GO_SIGN_UP', 'Sign Up')
            : hasLocizeTranslation(t, 'AUTH_GO_LOG_IN', 'Log In')}
        </Button>
      </span>
    </div>
  )
}

AuthFooterBase.propTypes = {
  t: PropTypes.func.isRequired,
}

const AuthFooter = connect((state) => ({ isPhone: state.device.isPhone }))(
  AuthFooterBase,
)

export default withI18next()(AuthFooter)
