import React, { useEffect, useState } from 'react'
import StateStorage from 'lib/state-storage'
import Icon from 'components/common/icon'
import ICON, { ICON_SIZE } from 'components/common/icon/const'
import AlertAdsButton from './alert-ads-button'
import styles from './styles.scss'

const AlertAdsBanner = () => {
  const [isExpanded, setIsExpanded] = useState(false)
  const [showAdsBanner, setShowAdsBanner] = useState(false)
  const isCloseAdsBannerBefore = StateStorage.getCookie('bannerAdsWebToApp')
  const closeBanner = () => {
    StateStorage.saveBannerStatus('AdsWebToApp', true)
    setShowAdsBanner(false)
  }

  useEffect(() => {
    const closeIcon = document.querySelector('.ads-banner__close-icon')
    const textBanner = document.querySelector('.ads-banner__description')
    const bannerWrapper = document.querySelector('.ads-banner__wrapper')
    let isScrolled = false

    /**
     * A function that handles the expanded state of the Ads banner.
     * @param {bool} clickToShow - This parameter is used to determine whether the banner is expanded or not.
     * @constant startExpanding - Means to let Ads banner calculating for expanded at the beginning of the editorial section.
     * @constant endExpanding - Means to let Ads banner calculating for collapsed at the last section of editorial
     */
    const handleExpanded = (clickToShow) => (e) => {
      const window = e.currentTarget
      const startExpanding = window.innerWidth * 0.6 + 15
      const endExpanding = window.innerWidth * 1.6 - 93
      const isInMasonry =
        window.scrollY >= startExpanding && window.scrollY <= endExpanding
      const showFullAds = isInMasonry && !isScrolled && !isCloseAdsBannerBefore

      if (window.scrollY > endExpanding) {
        isScrolled = true
      }

      if (showFullAds || clickToShow) {
        bannerWrapper?.classList.add('expanded')
        closeIcon?.classList.add('expanded')
        textBanner?.classList.add('expanded')
        setIsExpanded(true)
        setShowAdsBanner(true)
      } else {
        bannerWrapper?.classList.remove('expanded')
        closeIcon?.classList.remove('expanded')
        textBanner?.classList.remove('expanded')
        setIsExpanded(false)
      }
    }

    if (showAdsBanner) {
      textBanner?.addEventListener('click', handleExpanded(true))
    }
    window.addEventListener('scroll', handleExpanded(false))

    return () => {
      window.removeEventListener('scroll', handleExpanded)
    }
  }, [isCloseAdsBannerBefore, showAdsBanner])

  if (!showAdsBanner) {
    return null
  }

  return (
    <React.Fragment>
      <style jsx>{styles}</style>
      <div className="ads-banner__wrapper">
        <Icon
          alt="close-banner"
          className="ads-banner__close-icon"
          cy="ads-banner__close-icon"
          src={ICON.close}
          size={ICON_SIZE.medium}
          onClick={closeBanner}
        />
        <div
          className="ads-banner__description"
          data-cy="ads-banner__description"
        >
          <AlertAdsButton isExpanded={isExpanded} />
        </div>
      </div>
    </React.Fragment>
  )
}

export default AlertAdsBanner
