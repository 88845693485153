import clsx from 'clsx'
import {
  useCallback,
  useEffect,
  useRef,
  useMemo,
  useState,
  forwardRef,
} from 'react'
import PropTypes from 'prop-types'
import Placeholder from 'components/images/placeholder'
import useIntersection from 'components/custom-hooks/useIntersection'
import { getImageSize } from './utils'

/** Note: `imgRatio` is the value of height / width. e.g. 1280px / 720px, `imgRatio` = 1.77777778 */
const ResponsiveImage = ({
  alt,
  children,
  className,
  cy,
  imageRef,
  imgRatio,
  imgStyle,
  lazy,
  lazyThreshold,
  onClick,
  src,
  style,
  wrapperProps,
  isLocalImage,
}) => {
  const ref = useRef()
  const [isError, setIsError] = useState(false)
  const [isLoaded, setIsLoaded] = useState(false)
  const [showImage, setShowImage] = useState(false)
  const [imageSize, setImageSize] = useState(null)
  let isIntersecting = useIntersection(ref, { threshold: lazyThreshold })
  if (lazy) {
    isIntersecting = true
  }

  useEffect(() => {
    if (ref.current) {
      setImageSize({
        width: ref.current.clientWidth,
        height: ref.current.clientHeight,
      })
    }
    return () => {
      setImageSize(null)
    }
  }, [])

  const imageSrc = useMemo(() => {
    if (imageSize) {
      if (isLocalImage) return src

      const pixelRatio = global.devicePixelRatio || 1.0
      const size = getImageSize(imageSize)
      const imageWidth = size.width * pixelRatio
      const imageHeight = size.height * pixelRatio
      return `${src}?auto=compress,format&fm=webp,jpg,png&w=${imageWidth}&h=${imageHeight}`
    }
    return ''
  }, [imageSize, src, isLocalImage])

  useEffect(() => {
    setShowImage(isIntersecting)
  }, [isIntersecting])

  const onLoad = useCallback(() => {
    setIsLoaded(true)
  }, [])

  const onError = useCallback(() => {
    setIsError(true)
  }, [])

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      ref={ref}
      className={clsx('picture', className)}
      style={{
        ...style,
        ...(showImage && !isError && !imgStyle
          ? {
              width: getImageSize(imageSize).width,
              height: getImageSize(imageSize).height,
            }
          : {}),
      }}
      onClick={onClick}
      onKeyDown={onClick}
      data-cy={cy}
      {...wrapperProps}
    >
      {!isLoaded && <Placeholder isStatic={isError} imgRatio={imgRatio} />}
      {showImage && !isError && (
        <img
          ref={imageRef}
          src={imageSrc}
          width={getImageSize(imageSize).width}
          height={getImageSize(imageSize).height}
          alt={alt}
          className={clsx('image', { 'is-loading': !isLoaded })}
          style={imgStyle}
          onError={onError}
          onLoad={onLoad}
        />
      )}
      {children}
    </div>
  )
}

ResponsiveImage.defaultProps = {
  alt: '',
  children: null,
  className: '',
  cy: undefined,
  imgRatio: 1,
  imageRef: null,
  imgStyle: undefined,
  lazy: true,
  lazyThreshold: 0.1,
  onClick: undefined,
  style: undefined,
  wrapperProps: undefined,
  isLocalImage: false,
}

ResponsiveImage.propTypes = {
  alt: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
    PropTypes.number,
  ]),
  className: PropTypes.string,
  imgRatio: PropTypes.number,
  cy: PropTypes.string,
  imgStyle: PropTypes.shape({}),
  lazy: PropTypes.bool,
  lazyThreshold: PropTypes.number,
  onClick: PropTypes.func,
  src: PropTypes.string.isRequired,
  style: PropTypes.shape({}),
  imageRef: PropTypes.func,
  wrapperProps: PropTypes.shape({}),
  isLocalImage: PropTypes.bool,
}

export default forwardRef((props, ref) => (
  <ResponsiveImage {...props} imageRef={ref} />
))
