import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import authDuck from 'components/auth/duck'
import Loader from 'components/loader'
import { segmentSignInViewed } from 'lib/segment'
import { SEGMENT_STORAGE_KEY_PROMPT_LOCATION } from 'lib/segment/const'
import StateStorage from 'lib/state-storage'
import Icon from 'components/common/icon'
import ICONS, { ICON_SIZE } from 'components/common/icon/const'
import { deleteWishlist, updateWishlistItem } from './utils'

async function toggleWishlisting({
  isWishlisted,
  localWishlists,
  product,
  productId,
  promptLocation,
  refType,
  setIsDeleteWishlistProductTimeOut,
  setIsWishlisting,
  setLocalWishlists,
  setCancelingID,
  setIsSnackBarOpen,
  setUndoClicked,
  showLoginModal,
}) {
  if (StateStorage.isGuestMode()) {
    segmentSignInViewed(promptLocation)
    sessionStorage.setItem(SEGMENT_STORAGE_KEY_PROMPT_LOCATION, promptLocation)
    StateStorage.saveLocalState('TemporaryWishlist', product, false)
    showLoginModal(true)
    return
  }

  setIsWishlisting(true)
  const response = await updateWishlistItem({
    product,
    productId,
    isWishlisted,
    refType,
    setCancelingID,
    setIsDeleteWishlistProductTimeOut,
    setIsSnackBarOpen,
    setUndoClicked,
  })

  if (response) {
    setLocalWishlists({ ...localWishlists, [productId]: !isWishlisted })
  }
  setIsWishlisting(false)
}

const ProductItemWishlistIcon = ({
  cy,
  isDeleteWishlistProductTimeOut,
  isOriginalWishlisted,
  product,
  productId,
  promptLocation,
  refType,
  selectedSwatch,
  setIsDeleteWishlistProductTimeOut,
  setIsSnackBarOpen,
  setUndoClicked,
  showLoginModal,
  undoClicked,
}) => {
  const [localWishlists, setLocalWishlists] = useState({
    [productId]: isOriginalWishlisted,
  })
  const [isWishlisting, setIsWishlisting] = useState(false)
  const [cancelingID, setCancelingID] = useState(null)

  useEffect(() => {
    const deleteWishlistWhenTimeOut = async () => {
      await deleteWishlist(cancelingID)
      setLocalWishlists({ ...localWishlists, [cancelingID]: false })
    }

    if (isDeleteWishlistProductTimeOut && cancelingID && !undoClicked) {
      setCancelingID(null)
      deleteWishlistWhenTimeOut()
    }

    if (cancelingID && undoClicked) {
      setCancelingID(null)
    }
  }, [
    cancelingID,
    isDeleteWishlistProductTimeOut,
    localWishlists,
    setUndoClicked,
    undoClicked,
  ])

  useEffect(() => {
    const tempWishlist = StateStorage.getLocalState('TemporaryWishlist')
    const updateWishlistFromGuestTemporaryAdded = async () => {
      setIsWishlisting(false)
      setLocalWishlists({ ...localWishlists, [productId]: true })
      await updateWishlistItem({
        product,
        productId,
        isWishlisted: false,
        refType,
        setIsSnackBarOpen,
      })
    }

    if (tempWishlist?.id_product === productId) {
      updateWishlistFromGuestTemporaryAdded()
      StateStorage.removeLocalState('TemporaryWishlist')
    }
  }, [localWishlists, product, productId, refType, setIsSnackBarOpen])

  if (isWishlisting) {
    return (
      <div className="product_wishlist__wrapper">
        <Loader small />
      </div>
    )
  }

  const isWishlisted =
    productId in localWishlists
      ? localWishlists[productId]
      : selectedSwatch?.is_on_wishlist

  return (
    <button
      className="product_wishlist__wrapper"
      onClick={() =>
        toggleWishlisting({
          isWishlisted,
          localWishlists,
          product,
          productId,
          promptLocation,
          refType,
          setCancelingID,
          setIsDeleteWishlistProductTimeOut,
          setIsWishlisting,
          setIsSnackBarOpen,
          setLocalWishlists,
          setUndoClicked,
          showLoginModal,
        })
      }
      type="button"
      data-cy={cy}
    >
      <Icon
        alt="wishlist icon"
        size={ICON_SIZE.medium}
        src={isWishlisted ? ICONS.productWishlistFilled : ICONS.productWishlist}
      />
    </button>
  )
}

ProductItemWishlistIcon.defaultProps = {
  cy: undefined,
  isOriginalWishlisted: false,
  productId: 0,
  promptLocation: undefined,
  selectedSwatch: null,
}

ProductItemWishlistIcon.propTypes = {
  cy: PropTypes.string,
  isDeleteWishlistProductTimeOut: PropTypes.bool.isRequired,
  isOriginalWishlisted: PropTypes.bool,
  productId: PropTypes.number,
  product: PropTypes.shape({}).isRequired,
  promptLocation: PropTypes.string,
  refType: PropTypes.string.isRequired,
  selectedSwatch: PropTypes.shape({
    is_on_wishlist: PropTypes.bool,
  }),
  setIsDeleteWishlistProductTimeOut: PropTypes.func.isRequired,
  setIsSnackBarOpen: PropTypes.func.isRequired,
  setUndoClicked: PropTypes.func.isRequired,
  showLoginModal: PropTypes.func.isRequired,
  undoClicked: PropTypes.bool.isRequired,
}

export default connect(null, (dispatch) =>
  bindActionCreators(
    {
      showLoginModal: authDuck.creators.updateShowLogin,
    },
    dispatch,
  ),
)(ProductItemWishlistIcon)
