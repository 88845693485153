import env from 'config/env'
import { request } from 'lib/api'
import { getFlagValue } from 'lib/flagsmith'
import { HTTP_METHODS } from './const'

class Wishlist {
  static get(option) {
    switch (option.type) {
      case 'ADD':
        return Wishlist.addWishlist(option)
      case 'DELETE':
        return Wishlist.removeFromWishlist(option)
      case 'UPDATE':
        return Wishlist.updateWishlist(option)
      default:
        return Wishlist.getAllWishlist(option)
    }
  }

  static getSaveForLaterFlagValue = async () => {
    const flagValue = await getFlagValue('SaveForLater_20230704', false)
      .then((flag) => flag)
      .catch(() => false)
    return flagValue
  }

  static getAllWishlist = async ({ userId } = {}) => {
    const isSaveForLaterFlagValue = await Wishlist.getSaveForLaterFlagValue()

    if (isSaveForLaterFlagValue) {
      return request({
        url: 'v8/wishlists',
        method: HTTP_METHODS.get,
        baseURL: env.NEXT_API_HOST,
        params: {
          customer_id: userId,
        },
      })
    }

    return request({
      url: 'v6/wishlists/',
      method: HTTP_METHODS.get,
      params: {
        customer_id: userId,
      },
    })
  }

  static addWishlistWithSizeData({ id_product, id_product_attribute }) {
    return request({
      url: 'v8/wishlists',
      method: HTTP_METHODS.post,
      baseURL: env.NEXT_API_HOST,
      body: {
        id_product,
        id_product_attribute,
        quantity: 1,
      },
    })
  }

  static addWishlist = async ({ product_id, id_product_attribute }) => {
    const isSaveForLaterFlagValue = await Wishlist.getSaveForLaterFlagValue()

    if (isSaveForLaterFlagValue) {
      return Wishlist.addWishlistWithSizeData({
        id_product: product_id,
        id_product_attribute,
      })
    }

    return request({
      url: 'v6/wishlists/',
      method: HTTP_METHODS.post,
      body: {
        id_product: product_id,
      },
    })
  }

  static deleteWishlistWithNewVersion({
    productId,
    wishlistId,
    ref_type = 'product',
  }) {
    const isWishlistPage = ref_type === 'wishlist'
    const ref_id = isWishlistPage ? wishlistId : productId

    return request({
      url: `v8/wishlists/${ref_id}`,
      method: HTTP_METHODS.delete,
      baseURL: env.NEXT_API_HOST,
      params: {
        type: ref_type,
      },
    }).then((res) => (isWishlistPage ? Wishlist.getAllWishlist() : res))
  }

  static removeFromWishlist = async ({ productId, wishlistId }) => {
    const isSaveForLaterFlagValue = await Wishlist.getSaveForLaterFlagValue()

    if (isSaveForLaterFlagValue) {
      return Wishlist.deleteWishlistWithNewVersion({
        wishlistId,
        ref_type: 'wishlist',
      })
    }

    return request({
      url: `v6/wishlists/${productId}`,
      method: HTTP_METHODS.delete,
      params: {
        type: 'product',
      },
    }).then(() => Wishlist.getAllWishlist())
  }

  static deleteWishlist = async ({ productId }) => {
    const isSaveForLaterFlagValue = await Wishlist.getSaveForLaterFlagValue()

    if (isSaveForLaterFlagValue) {
      return Wishlist.deleteWishlistWithNewVersion({
        productId,
        ref_type: 'product',
      })
    }

    return request({
      url: `v6/wishlists/${productId}`,
      method: HTTP_METHODS.delete,
      params: {
        type: 'product',
      },
    })
  }

  static updateWishlist({
    id_product,
    id_product_attribute,
    id_wishlist_item,
  }) {
    return request({
      url: 'v8/wishlists',
      method: HTTP_METHODS.put,
      baseURL: env.NEXT_API_HOST,
      body: {
        id_wishlist_item,
        id_product,
        id_product_attribute,
        quantity: 1,
      },
    }).then(() => Wishlist.getAllWishlist())
  }
}

export default Wishlist
