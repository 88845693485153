import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import clsx from 'clsx'
import { TRAILING } from 'components/recently-view/const'
import ProductItem from 'components/product/product-item'
import HoolahBanner from '../hoolah-banner'
import PageTrackWrapper from '../page-track-wrapper'
import getHoolahBannerEligibilityStatus from './utils'

const MapProductItem = ({
  className,
  hoolahDesktopRule,
  hoolahMobileRule,
  idx,
  isHoolahWithSingleProduct,
  isPhone,
  onProductPageChanged,
  product: { id_product },
  productProps,
  productsPerPage,
  totalProducts,
}) => {
  if (onProductPageChanged && (idx + 1) % productsPerPage === 1) {
    return (
      <React.Fragment>
        <PageTrackWrapper
          additionalPage={idx / productsPerPage}
          className={clsx('product-item', className)}
          key={id_product}
          onProductPageChanged={onProductPageChanged}
        >
          <ProductItem {...productProps} />
        </PageTrackWrapper>
        {isHoolahWithSingleProduct && (
          <HoolahBanner
            isAtBottomMobile={isHoolahWithSingleProduct && isPhone}
          />
        )}
      </React.Fragment>
    )
  }

  const { isAtBottomMobile, withHoolahBanner } =
    getHoolahBannerEligibilityStatus(
      idx,
      totalProducts,
      hoolahDesktopRule,
      hoolahMobileRule,
    )

  if (withHoolahBanner) {
    return (
      <React.Fragment>
        <ProductItem {...productProps} />
        <HoolahBanner isAtBottomMobile={isAtBottomMobile} />
      </React.Fragment>
    )
  }

  return (
    !(id_product === TRAILING) && (
      <ProductItem {...productProps} className={className} />
    )
  )
}

MapProductItem.defaultProps = {
  className: '',
  productsPerPage: 18,
  onProductPageChanged: null,
}

MapProductItem.propTypes = {
  className: PropTypes.string,
  hoolahDesktopRule: PropTypes.bool.isRequired,
  hoolahMobileRule: PropTypes.bool.isRequired,
  idx: PropTypes.number.isRequired,
  isHoolahWithSingleProduct: PropTypes.bool.isRequired,
  isPhone: PropTypes.bool.isRequired,
  onProductPageChanged: PropTypes.func,
  product: PropTypes.shape({
    id_product: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
  productProps: PropTypes.shape({}).isRequired,
  productsPerPage: PropTypes.number,
  totalProducts: PropTypes.number.isRequired,
}

export default connect((state) => ({
  isPhone: state.device.isPhone,
}))(MapProductItem)
