export default class Time {
  /**
   *
   * @param {number} secs
   * @returns {Time}
   */
  static getTimeFromSeconds(secs) {
    const total = Math.ceil(secs)
    const seconds = Math.trunc(total % 60)
    return {
      seconds,
    }
  }

  /**
   *
   * @param {number} timestamp
   * @returns {boolean}
   */
  static isValidExpiryTimeStamp(timestamp) {
    const isValid = new Date(timestamp).getTime() > 0

    return isValid
  }

  /**
   *
   * @param {number} expiry timestamp
   * @param {boolean} shouldRound
   * @returns {number}
   */
  static getSecondsFromExpiry(expiry, shouldRound) {
    const now = new Date().getTime()
    const milliSecondsDistance = expiry - now
    if (milliSecondsDistance > 0) {
      const val = milliSecondsDistance / 1000
      return shouldRound ? Math.round(val) : val
    }
    return 0
  }
}

/**
 * @typedef {object} Time
 * @property {number} seconds
 * @property {number} minutes
 * @property {number} hours
 * @property {number} days
 */
