const filterKeys = {
  size: 'filter[size]',
  price: 'filter[price]',
  color: 'filter[color]',
  categories: 'filter[categories]',
  brand: 'filter[brands]',
  sustainability: 'filter[feature]',
}

const shouldHasParamPrefix = (id) => ['size', 'color', 'price'].includes(id)

const joinParamValues = (id, values) =>
  id === 'price' ? values.join('-') : values.join(',')

export const getFilterParams = (filter, isProductFilter = false) => {
  const paramObj = {}

  filter
    .filter(({ values }) => Boolean(values?.length))
    .forEach(({ id, values }) => {
      const replaceId = id.replace(' ', '_')

      const paramKey =
        isProductFilter && shouldHasParamPrefix(replaceId)
          ? `filter[filter_${replaceId}]`
          : filterKeys[replaceId] || `filter[${replaceId}]`

      paramObj[paramKey] = joinParamValues(replaceId, values)
    })

  return paramObj
}

export default { getFilterParams }
