// eslint-disable-next-line import/no-cycle
import ProductsApiHandler from 'lib/api/products'
import StateStorage from 'lib/state-storage'
import { SEE_ALL, TRAILING } from './const'

const checkShowBackToTop = (setShowBackToTop, showBackToTop) => {
  const newVal =
    document.body.scrollTop > 400 || document.documentElement.scrollTop > 400

  if (showBackToTop !== newVal) {
    setShowBackToTop(newVal)
  }
}

const getRecentViewProducts = () =>
  StateStorage.getLocalState('recentlyViewProductList')

const setRecentViewProducts = (productCollection) => {
  StateStorage.saveLocalState(
    'recentlyViewProductList',
    productCollection,
    false,
  )
}

const clearGuestRecentlyViewProducts = () => {
  const productCollection = getRecentViewProducts() || {}
  const userIdKeys = Object.keys(productCollection)

  if (userIdKeys.includes('guest')) {
    delete productCollection.guest
    setRecentViewProducts(productCollection)
  }
}

const clearRecentlyViewProducts = (newShop) => {
  const prevShop = StateStorage.getShop()

  if (prevShop !== newShop) {
    StateStorage.removeLocalState('recentlyViewProductList')
  }
}

const fillClassNamePrefix = (classname) => `recently-view-product__${classname}`

const getProductWithCoverImages = async (productId) => {
  // wait for full data, if not we'll get {} (empty object)
  const productWithCoverImgList = await ProductsApiHandler.getProducts([
    productId,
  ]).catch(() => undefined)
  return productWithCoverImgList?.products[0]
}

const isProductPresent = (productId, productList) => {
  let productIdx

  const foundProduct = productList.find((prod, idx) => {
    let exactProduct
    if (prod.id_product === productId) {
      productIdx = idx
      exactProduct = prod
    }
    return exactProduct
  })

  return [foundProduct, productIdx]
}

const removeDuplicateProduct = (productId, productList) => {
  const [foundProduct, productIdx] = isProductPresent(productId, productList)

  if (foundProduct) {
    productList.splice(productIdx, 1)
  }
}

const removeConsecutiveDuplicateProduct = (productId, productList) => {
  if (productList[0]?.id_product === productId) {
    productList.splice(productList[0], 1)
  }
}

const insertUserRecentProductList = (userId, userProductList) => {
  const productCollection = getRecentViewProducts() || {}
  productCollection[userId] = userProductList

  return productCollection
}

const getProductListByUserId = (userId) => {
  const productCollection = getRecentViewProducts() || {}
  const userIdKeys = Object.keys(productCollection)

  if (!userIdKeys.includes(userId)) {
    return []
  }

  return productCollection[userId]
}

const getStringIDList = (list) => {
  if (!list) {
    return null
  }

  // since the array isn't contains only id_product in the object
  // to gather the field into the list by iteration then following by `.join` isn't the appropriate way
  // so the concatination of the string is the right path
  let productIDs = ''
  list.map((product) => {
    if (typeof product.id_product === 'number') {
      productIDs += `${String(product.id_product)},`
    }
    return null
  })
  return productIDs
}

const getProductList = async (userId) => {
  const userProductList = getProductListByUserId(userId)

  if (!userProductList.length) {
    return Promise.resolve({ products: [] })
  }

  const productIDs = getStringIDList(userProductList)
  const productList = await ProductsApiHandler.getProductListByIDs(productIDs)
  return productList
}

const pushSpecialElement = (initialProductLists, newProductList) => {
  const isMainListEmpty = initialProductLists.length === 0
  const isMainListQuantityExceed = initialProductLists.length > 7
  const lastInitialListIdx = initialProductLists.length - 1

  // when user've viewed more than 7 product, insert id_product = 'see-all' after that (index = 7)
  // the list will show max 7 products + see all card component
  // with the trailing space after it to indicate end spacing since it has been removed by `removeLastItem()`
  if (
    isMainListQuantityExceed &&
    ![TRAILING, SEE_ALL].includes(initialProductLists[7].id_product)
  ) {
    newProductList.push({ id_product: SEE_ALL })
    newProductList.push({ id_product: TRAILING }) // insert trailing space after it
  }

  // actual product is 7 but the trailing space make them 8 (initialProductLists)
  // so after slice (newProductList), we have to push the trailing space in again
  // since the product is still 7 so the product list has no `see all` card yet
  if (
    isMainListQuantityExceed &&
    initialProductLists[7].id_product === TRAILING
  ) {
    newProductList.push({ id_product: TRAILING }) // insert trailing space after it
  }

  if (
    !isMainListEmpty &&
    !isMainListQuantityExceed &&
    initialProductLists[lastInitialListIdx]?.id_product !== TRAILING
  ) {
    newProductList.push({ id_product: TRAILING })
  }
}

const isTrailingInvalid = (productList, len) =>
  productList.length === len && productList[len - 1].id_product === TRAILING

const removeLastItem = (productList) => {
  const containOnlyTrailing = isTrailingInvalid(productList, 1)
  const isExceedWithTrailingSpace = isTrailingInvalid(productList, 9)
  const exceedAllowedQuantity = productList.length > 16

  if (
    containOnlyTrailing ||
    isExceedWithTrailingSpace ||
    exceedAllowedQuantity
  ) {
    productList.pop()
  }
}

const storeRecentViewProduct = async (productId, userId, productAttributes) => {
  const userProductList = getProductListByUserId(userId)

  removeConsecutiveDuplicateProduct(productId, userProductList)

  const productWithCoverImg = await getProductWithCoverImages(productId)

  // include only valid product with cover images
  if (productWithCoverImg) {
    productWithCoverImg.sizes = productAttributes // include product attributes (sizes) for add to bag hover CTA
    userProductList.unshift(productWithCoverImg)
  }

  removeLastItem(userProductList)

  const productCollection = insertUserRecentProductList(userId, userProductList)
  setRecentViewProducts(productCollection)
}

export {
  checkShowBackToTop,
  clearGuestRecentlyViewProducts,
  clearRecentlyViewProducts,
  fillClassNamePrefix,
  getProductList,
  getProductListByUserId,
  getRecentViewProducts,
  getStringIDList,
  pushSpecialElement,
  setRecentViewProducts,
  removeConsecutiveDuplicateProduct,
  removeDuplicateProduct,
  removeLastItem,
  storeRecentViewProduct,
}
