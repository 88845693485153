import styles from './styles.scss'

const Loading = () => (
  <div>
    <style jsx>{styles}</style>
    <div className="skinny-banner-loader" data-cy="skinny-loader" />
  </div>
)

export default Loading
