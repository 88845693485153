import clsx from 'clsx'
import Link from 'next/link'
import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import cartItemsDuck from 'components/cart/cart-items/duck'
import Button from 'components/common/button'
import Icon from 'components/common/icon'
import ICONS, { ICON_SIZE } from 'components/common/icon/const'
import SearchForm from 'components/search/search-form'
import searchModalDuck from 'components/search/search-section/duck'
import { BUTTON_TYPES } from 'constants/button'
import StateStorage from 'lib/state-storage'
import {
  getSessionStorageOnInit,
  isAppWebView,
} from 'lib/utils/common/commonUtils'
import HeaderModals from './modal-section'
import NavigationMenu from './navigation-menu'
import PomeloLogo from './pomelo-logo'
import styles from './style.scss'
import UserMenu from './user-menu'
import {
  focusSearchBox,
  injectAppAlert,
  segmentSearchIconClick,
  trackViewCartEvent,
  updateHeaderDesktopStyle,
} from './utils'

const Header = ({
  applyStoreCredit,
  cart,
  internationalization: { country, language },
  isPhone,
  isSticky,
  noCountrySelector,
  pageName,
  showSearch,
  showSearchModal,
  toggleSearchModal,
  updateCart,
}) => {
  const headerRef = useRef(null)
  const initialSearchWord = getSessionStorageOnInit('recent-search-word')
  const [searchWord, setSearchWord] = useState(initialSearchWord)
  const [showCountrySelectorModal, setShowCountrySelectorModal] =
    useState(false)
  const isGlobalCountry = country === 'global'

  const openCart = useCallback(() => {
    updateCart({
      cartOpen: true,
      couponUsed: null,
      freeGiftOpen: false,
      isStoreCreditUsed: applyStoreCredit,
    })
    trackViewCartEvent(cart)
  }, [applyStoreCredit, cart, updateCart])

  // update header style after scrolling up or down while track for search bar AB tets segment event
  useEffect(() => {
    window.addEventListener('scroll', () => updateHeaderDesktopStyle(isPhone))

    return () => {
      window.removeEventListener('scroll', updateHeaderDesktopStyle)
    }
  }, [isPhone])

  // open cart again after clicking checkout as a guest and then successfully login
  useEffect(() => {
    const checkoutText = `/${country}/${language}/checkout`
    const successfulLogin = StateStorage.getLocalState('successfullogincart')

    if (successfulLogin === checkoutText) {
      openCart()
      StateStorage.removeLocalState('successfullogincart')
    }
  }, [country, language, openCart])

  // inject app alert class while showing country selector when applicable
  useEffect(() => {
    injectAppAlert()

    if (
      !isAppWebView() &&
      !noCountrySelector &&
      isGlobalCountry &&
      !StateStorage.getCountry()
    ) {
      setShowCountrySelectorModal(true)
    }
  }, [isGlobalCountry, noCountrySelector])

  const classes = clsx('top-nav header', {
    large: !isPhone,
    'search-bar-active': showSearchModal,
    sticky: isSticky,
  })

  const toggleSearch = () => {
    if (headerRef?.current) {
      toggleSearchModal()
      segmentSearchIconClick(pageName)

      // wait for the search box to be rendered then focus on it
      setTimeout(() => focusSearchBox(), 1)
    }
  }

  return (
    <React.Fragment>
      <style jsx>{styles}</style>
      <header className={classes} ref={headerRef}>
        <div className="header-section small-logo">
          <Link href="/home" as={`/${country}/${language}/`}>
            <Button link type={BUTTON_TYPES.link} className="small-logo__btn">
              <Icon src={ICONS.pmlSmallLogo} size={ICON_SIZE.medium} />
            </Button>
          </Link>
        </div>
        <NavigationMenu isSearchBarActive={showSearchModal} />
        <div className="header-section header-logo">
          <PomeloLogo />
        </div>
        <UserMenu
          openCart={openCart}
          showSearch={showSearch}
          toggleSearch={toggleSearch}
        />
        <div
          className={clsx('header-search__input', {
            active: showSearchModal,
          })}
        >
          <SearchForm
            alwaysShowClose
            searchWord={searchWord}
            setSearchWord={setSearchWord}
          />
        </div>
      </header>
      <HeaderModals
        setSearchWord={setSearchWord}
        setShowCountrySelectorModal={setShowCountrySelectorModal}
        showCountrySelectorModal={showCountrySelectorModal}
        toggleSearch={toggleSearch}
      />
    </React.Fragment>
  )
}

Header.defaultProps = {
  applyStoreCredit: undefined,
  cart: {},
  isPhone: false,
  isSticky: true,
  noCountrySelector: false,
  pageName: '',
  showSearch: true,
  showSearchModal: false,
}

Header.propTypes = {
  applyStoreCredit: PropTypes.bool,
  cart: PropTypes.shape({
    items: PropTypes.shape({
      id_product: PropTypes.number,
      prices: PropTypes.shape({
        discounted_price: PropTypes.number,
        original_price: PropTypes.number,
      }),
      quantity: PropTypes.number,
    }),
  }),
  internationalization: PropTypes.shape({
    country: PropTypes.string,
    language: PropTypes.string,
  }).isRequired,
  isPhone: PropTypes.bool,
  isSticky: PropTypes.bool,
  noCountrySelector: PropTypes.bool,
  pageName: PropTypes.string,
  showSearch: PropTypes.bool,
  showSearchModal: PropTypes.bool,
  toggleSearchModal: PropTypes.func.isRequired,
  updateCart: PropTypes.func.isRequired,
}

export default connect(
  (state) => ({
    applyStoreCredit: state.cartData.isStoreCreditUsed,
    cart: state.cart.payload,
    internationalization: state.internationalization,
    isPhone: state.device.isPhone,
    showSearchModal: state.searchModal.showSearchModal,
  }),
  (dispatch) =>
    bindActionCreators(
      {
        toggleSearchModal: searchModalDuck.creators.toggleSearchModal,
        updateCart: cartItemsDuck.creators.update,
      },
      dispatch,
    ),
)(Header)
