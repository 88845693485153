import Color from './color'

export default class {
  constructor(target) {
    this.target = target
    this.targetHSL = target.hsl()
    this.reusedColor = new Color(0, 0, 0)
  }

  solve() {
    const result = this.solveNarrow(this.solveWide())
    return {
      values: result.values,
      loss: result.loss,
      filter: this.css(result.values),
    }
  }

  solveWide() {
    const A = 5
    const c = 15
    const a = [60, 180, 18000, 600, 1.2, 1.2]

    let best = { loss: Infinity }
    for (let i = 0; best.loss > 25 && i < 3; i += 1) {
      const initial = [50, 20, 3750, 50, 100, 100]
      const result = this.spsa(A, a, c, initial, 1000)
      if (result.loss < best.loss) {
        best = result
      }
    }
    return best
  }

  solveNarrow(wide) {
    const A = wide.loss
    const c = 2
    const A1 = A + 1
    const a = [0.25 * A1, 0.25 * A1, A1, 0.25 * A1, 0.2 * A1, 0.2 * A1]
    return this.spsa(A, a, c, wide.values, 500)
  }

  spsa(A, a, c, values, iters) {
    const alpha = 1
    const gamma = 0.16666666666666666

    const fix = (value, idx) => {
      let max = 100
      let result = value
      switch (idx) {
        case 2: // saturate
          max = 7500
          break

        case 3: // hue-rotate
          result = value < 0 ? max + (value % max) : value % max
          break

        case 4: // brightness
        case 5: // contrast
          max = 200
          break

        default:
          break
      }
      return Math.min(Math.max(0, result), max)
    }

    let best = null
    let bestLoss = Infinity
    const deltas = new Array(6)
    const highArgs = new Array(6)
    const lowArgs = new Array(6)
    const resultValues = values

    for (let k = 0; k < iters; k += 1) {
      const ck = c / (k + 1) ** gamma
      for (let i = 0; i < 6; i += 1) {
        deltas[i] = Math.random() > 0.5 ? 1 : -1
        highArgs[i] = values[i] + ck * deltas[i]
        lowArgs[i] = values[i] - ck * deltas[i]
      }

      const lossDiff = this.loss(highArgs) - this.loss(lowArgs)
      for (let i = 0; i < 6; i += 1) {
        const g = (lossDiff / (2 * ck)) * deltas[i]
        const ak = a[i] / (A + k + 1) ** alpha
        resultValues[i] = fix(values[i] - ak * g, i)
      }

      const loss = this.loss(resultValues)
      if (loss < bestLoss) {
        best = resultValues.slice(0)
        bestLoss = loss
      }
    }
    return { values: best, loss: bestLoss }
  }

  loss(filters) {
    // Argument is array of percentages.
    const color = this.reusedColor
    color.set(0, 0, 0)

    color.invert(filters[0] / 100)
    color.sepia(filters[1] / 100)
    color.saturate(filters[2] / 100)
    color.hueRotate(filters[3] * 3.6)
    color.brightness(filters[4] / 100)
    color.contrast(filters[5] / 100)

    const colorHSL = color.hsl()
    return (
      Math.abs(color.r - this.target.r) +
      Math.abs(color.g - this.target.g) +
      Math.abs(color.b - this.target.b) +
      Math.abs(colorHSL.h - this.targetHSL.h) +
      Math.abs(colorHSL.s - this.targetHSL.s) +
      Math.abs(colorHSL.l - this.targetHSL.l)
    )
  }

  css(filters) {
    function fmt(idx, multiplier = 1) {
      return Math.round(filters[idx] * multiplier)
    }
    return `invert(${fmt(0)}%) sepia(${fmt(1)}%) saturate(${fmt(
      2,
    )}%) hue-rotate(${fmt(3, 3.6)}deg) brightness(${fmt(4)}%) contrast(${fmt(
      5,
    )}%)`
  }
}
