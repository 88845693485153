const searchSidebarTitle = {
  popular: {
    key: 'SEARCH_SIDEBAR_TITLE_POPULAR',
    value: 'Popular Search',
  },
  recent: {
    key: 'SEARCH_SIDEBAR_TITLE_RECENT',
    value: 'Recent Search',
  },
}

const clearCta = {
  key: 'SEARCH_SIDEBAR_CLEAR_CTA',
  value: 'CLEAR',
}

export { clearCta, searchSidebarTitle }
