import PropTypes from 'prop-types'
import React, { useState, useMemo } from 'react'
import { connect } from 'react-redux'
import { getShopInfo } from 'lib/utils/shop-lang'
import usePomeloCashbackEnable from 'pages/user/pomelo-credit/credit-cashback/usePomeloCashbackEnable'
import { useFlagsmithGetFlagValue } from 'lib/flagsmith'
import HeaderUspMobile from '../header-usp-mobile'
import HeaderUspDesktop from '../header-usp-desktop'
import { getArrayOfUspType, checkProbability } from './const'

const HeaderUsp = ({ className, internationalization, isPhone }) => {
  const isPomeloCashbackEnable = usePomeloCashbackEnable(
    internationalization.shop,
  )
  const isTTBEnable = useFlagsmithGetFlagValue('TTBVisibility_20230228', false)
  const [activeType, setActiveType] = useState('')
  const { shop } = internationalization
  const currentShop = getShopInfo({
    key: 'id_shop',
    value: shop,
  })

  const uspTypeListOnCurrentShop = useMemo(
    () => getArrayOfUspType(currentShop, isPomeloCashbackEnable, isTTBEnable),
    [currentShop, isPomeloCashbackEnable, isTTBEnable],
  )

  const hideUspTooltip = () => {
    setActiveType('')
  }

  const toggleUspTooltip = (id) => {
    if (id === 'new') {
      return
    }

    const activeStateToSet = checkProbability(activeType === id, '', id)
    setActiveType(activeStateToSet)

    if (isPhone) {
      document.querySelector('body').style.overflowX = checkProbability(
        activeStateToSet,
        'hidden',
        '',
      )
    }
  }

  const commonProps = {
    classNameFromLayout: className,
    activeType,
    uspList: uspTypeListOnCurrentShop,
    toggleUspTooltip,
  }
  return isPhone ? (
    <HeaderUspMobile {...commonProps} />
  ) : (
    <HeaderUspDesktop {...commonProps} hideUspTooltip={hideUspTooltip} />
  )
}

HeaderUsp.defaultProps = { className: '' }

HeaderUsp.propTypes = {
  className: PropTypes.string,
  internationalization: PropTypes.shape({
    country: PropTypes.string,
    language: PropTypes.string,
    shop: PropTypes.number,
  }).isRequired,
  isPhone: PropTypes.bool.isRequired,
}

export default connect((state) => ({
  internationalization: state.internationalization,
  isPhone: state.device.isPhone,
}))(HeaderUsp)
