const CART_SUMMARY = Object.freeze({
  subTotal: 'CART_SUMMARY_SUBTOTAL',
  shipping: 'CART_SUMMARY_SHIPPING',
  discount: 'CART_SUMMARY_DISCOUNT',
  pmlCredit: 'CART_SUMMARY_POMELO_CREDIT',
  total: 'CART_SUMMARY_TOTAL',
  loyalty: 'CART_SUMMARY_LOYALTY',
  hoolah: 'CART_SUMMARY_HOOLAH',
})

export default CART_SUMMARY
