import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { withI18next } from 'lib/i18n/withI18next'
import PropTypes from 'prop-types'
import React from 'react'
import Typo from 'constants/typography'
import clsx from 'clsx'
import { hasLocizeTranslation } from 'lib/utils/locize'
import getProductPremiumType, {
  getPremiumTags,
} from 'components/shared/product/utils/ProductPremiumUtils'
import useProductFlashSale from 'components/shared/product/hooks/useProductFlashSale/useProductFlashSale'
import PRODUCT_PREMIUM_TYPE_ENUM from 'constants/product/product'
import styles from './product-premium-tags.scss'

const ProductPremiumTags = ({
  t,
  cy,
  isComingSoon,
  comingSoonText,
  isSoldOut,
  isFlashSale,
  flashSaleSecondsLeft,
  displayFlashSaleTitle,
  isLowStock,
  stockQuantity,
  isBackInStock,
}) => {
  const productBannerType = getProductPremiumType({
    isComingSoon,
    isSoldOut,
    isFlashSale,
    isLowStock,
    isBackInStock,
  })
  const { flashSaleTimeRemaining, isShowFlashSaleEnded } = useProductFlashSale(
    isFlashSale,
    flashSaleSecondsLeft,
  )
  const premiumTags = getPremiumTags({
    t,
    productBannerType,
    comingSoonText,
    stockQuantity,
  })

  const renderProductPremiumTags = () => {
    switch (productBannerType) {
      case PRODUCT_PREMIUM_TYPE_ENUM.SOLD_OUT:
      case PRODUCT_PREMIUM_TYPE_ENUM.LOW_STOCK:
      case PRODUCT_PREMIUM_TYPE_ENUM.BACK_IN_STOCK:
        return (
          <div
            className="product-premium-tags__label"
            data-cy={cy ? `${cy}__premium_label` : undefined}
          >
            <span className={Typo.overline}>{premiumTags}</span>
          </div>
        )
      case PRODUCT_PREMIUM_TYPE_ENUM.COMING_SOON:
        return (
          <div
            className="product-premium-tags__coming-soon"
            data-cy={cy ? `${cy}__premium_label` : undefined}
          >
            <span className={Typo.overline}>{premiumTags}</span>
          </div>
        )
      case PRODUCT_PREMIUM_TYPE_ENUM.FLASH_SALE:
        return (
          <div
            className="product-premium-tags__flash-sale"
            data-cy={cy ? `${cy}__premium_label` : undefined}
          >
            {displayFlashSaleTitle ? (
              <div className="product-premium-tags__flash-sale__with-title">
                <span className={clsx(Typo.overline)}>
                  {hasLocizeTranslation(t, 'FLASH_SALE', 'FLASH SALE')}
                </span>
                {isShowFlashSaleEnded ? (
                  <span className={clsx(Typo.caption)}>
                    {hasLocizeTranslation(t, 'ENDED', 'ENDED')}
                  </span>
                ) : (
                  <span className={Typo.caption}>{flashSaleTimeRemaining}</span>
                )}
              </div>
            ) : (
              <div>
                {isShowFlashSaleEnded ? (
                  <span className={clsx(Typo.caption)}>
                    {hasLocizeTranslation(t, 'ENDED', 'ENDED')}
                  </span>
                ) : (
                  <span className={Typo.caption}>{flashSaleTimeRemaining}</span>
                )}
              </div>
            )}
          </div>
        )
      default:
        return null
    }
  }

  return (
    <div className="product-premium-tags">
      <style jsx>{styles}</style>
      {renderProductPremiumTags()}
    </div>
  )
}

ProductPremiumTags.defaultProps = {
  cy: undefined,
  isComingSoon: false,
  comingSoonText: '',
  isSoldOut: false,
  isFlashSale: false,
  flashSaleSecondsLeft: 0,
  displayFlashSaleTitle: true,
  isLowStock: false,
  stockQuantity: 0,
  isBackInStock: false,
}

ProductPremiumTags.propTypes = {
  // component props
  t: PropTypes.func.isRequired,
  cy: PropTypes.string,
  isComingSoon: PropTypes.bool,
  comingSoonText: PropTypes.string,
  isSoldOut: PropTypes.bool,
  isFlashSale: PropTypes.bool,
  flashSaleSecondsLeft: PropTypes.number,
  displayFlashSaleTitle: PropTypes.bool,
  isLowStock: PropTypes.bool,
  stockQuantity: PropTypes.number,
  isBackInStock: PropTypes.bool,

  // redux props
  internationalization: PropTypes.shape({
    country: PropTypes.string,
    language: PropTypes.string,
  }).isRequired,
}

export default compose(
  connect((state) => ({
    internationalization: state.internationalization,
  })),
  withI18next(),
)(ProductPremiumTags)
