import { useCallback, useState, useRef, useEffect } from 'react'
import clsx from 'clsx'

const setAutoHideTimer = (hideTimeoutRef, duration, onClose) => {
  clearTimeout(hideTimeoutRef.current)
  // eslint-disable-next-line no-param-reassign
  hideTimeoutRef.current = setTimeout(() => {
    if (onClose) {
      onClose()
    }
  }, duration)
}

// Pause timer when user interact with component
const handlePause = (hideTimeoutRef) => {
  clearTimeout(hideTimeoutRef.current)
}

const useSnackbarElement = ({
  children,
  className,
  duration,
  onClose,
  onEnter,
  onLeave,
  open,
}) => {
  const hideTimeoutRef = useRef()
  const [isClose, setIsClose] = useState(true)
  const [active, setActive] = useState()
  const classes = clsx('pomelo-snack-bar', className, {
    active,
  })

  // Restart timer when user stop interact with component
  const handleResume = useCallback(() => {
    if (duration > 0) {
      setAutoHideTimer(hideTimeoutRef, duration, onClose)
    }
  }, [duration, onClose])

  useEffect(() => {
    if (children && open) {
      setIsClose(false)
      const interval = setImmediate(() => setActive(true))
      handleResume()
      return () => {
        clearImmediate(interval)
      }
    }

    setActive(false)
    const timeout = setTimeout(() => {
      setIsClose(true)
    }, 300)

    return () => {
      clearTimeout(timeout)
    }
  }, [children, open, handleResume])

  // If the user is see the component then start the timer
  useEffect(() => {
    if (open) {
      window.addEventListener('focus', handleResume)
      window.addEventListener('blur', () => handlePause(hideTimeoutRef))

      return () => {
        window.removeEventListener('focus', handleResume)
        window.removeEventListener('blur', handlePause)
      }
    }

    return undefined
  }, [open, handleResume])

  return {
    classes,
    isClose,
    handleMouseEnter: (e) => {
      if (onEnter) {
        onEnter(e)
      }
      handlePause(hideTimeoutRef)
    },
    handleMouseLeave: (e) => {
      if (onLeave) {
        onLeave(e)
      }
      handleResume()
    },
  }
}

export default useSnackbarElement
