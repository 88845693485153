import Duck from 'extensible-duck'

export default new Duck({
  namespace: 'content',
  store: 'visibility',
  types: ['SHOW', 'HIDE', 'CLEAN'],
  initialState: {
    visible: [],
    hidden: [],
  },
  reducer: (state, action, duck) => {
    const hidden = state.hidden.filter(
      (hiddenElem) => hiddenElem !== action.elem,
    )
    const visible = state.visible.filter(
      (hiddenElem) => hiddenElem !== action.elem,
    )

    switch (action.type) {
      case duck.types.SHOW:
        return {
          visible: [action.elem].concat(visible),
          hidden,
        }
      case duck.types.HIDE:
        return {
          visible,
          hidden: [action.elem].concat(hidden),
        }
      case duck.types.CLEAN:
        return {
          visible: [],
          hidden: [],
        }
      default:
        return state
    }
  },
  creators: (duck) => ({
    show: (elem) => (dispatch) => dispatch({ type: duck.types.SHOW, elem }),
    hide: (elem) => (dispatch) => dispatch({ type: duck.types.HIDE, elem }),
    clean: () => (dispatch) => dispatch({ type: duck.types.CLEAN }),
  }),
})
