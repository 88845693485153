import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import Typo from 'constants/typography'
import styles from './styles.scss'

const ProductPrice = ({ price, discountedPrice }) => (
  <div className="product__prices">
    <style jsx>{styles}</style>
    <span
      className={`${Typo.price1} ${
        discountedPrice ? 'product__prices__original-with-dc' : undefined
      }`}
    >
      {discountedPrice || price}
    </span>
    {discountedPrice && (
      <span className={clsx(Typo.price2, 'product__prices__discount')}>
        {price}
      </span>
    )}
  </div>
)

ProductPrice.defaultProps = {
  discountedPrice: null,
}

ProductPrice.propTypes = {
  price: PropTypes.string.isRequired,
  discountedPrice: PropTypes.string,
}

export default ProductPrice
