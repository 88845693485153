import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import useSWR from 'swr'
import { translate } from 'react-i18next'
import clsx from 'clsx'
import { ENDPOINTS } from 'lib/api/const'
import { requestWithCache } from 'lib/api'
import Loader from 'components/loader'
import ResponsiveImage from 'components/images/responsive-image'
import { hasLocizeTranslation } from 'lib/utils/locize'
import env from 'config/env'
import Typo from 'constants/typography'
import Dialog from 'components/common/dialog'
import Icon from 'components/common/icon'
import { ICON_SIZE } from 'components/common/icon/const'
import styles from './styles.scss'

const CreditInformation = ({
  onClose,
  isCheckoutFlowText,
  showMoreDetails,
  t,
}) => {
  const { data, error } = useSWR(
    ENDPOINTS.creditConditions.link,
    requestWithCache,
    { dedupingInterval: ENDPOINTS.creditConditions.cache },
  )
  const hasOneCondition = data?.conditions?.length === 1

  let bodyJsx = null

  if (error) {
    bodyJsx = (
      <div className="loader-container">
        {hasLocizeTranslation(
          t,
          'POMELO_CREDIT_MODAL_ERROR',
          'Oops. Something went wrong, please try again later.',
        )}
      </div>
    )
  } else if (!data) {
    bodyJsx = (
      <div className="loader-container">
        <Loader />
      </div>
    )
  } else if (data?.conditions?.length) {
    const { conditions } = data
    let titleLocize = {
      key: 'POMELO_CREDIT_TITLE',
      val: 'pomelo credit',
    }
    let subtitleLocize = null

    if (showMoreDetails) {
      if (isCheckoutFlowText) {
        titleLocize = {
          key: 'POMELO_CREDIT_CHECKOUT_TITLE',
          val: 'pomelo credit',
        }
        subtitleLocize = {
          key: 'POMELO_CREDIT_CHECKOUT_SUBTITLE',
          val: 'Pomelo Credits will be deducted from your balance.',
        }
      } else {
        titleLocize = {
          key: 'POMELO_CREDIT_ALTERNATE_TITLE',
          val: 'what is pomelo credit',
        }
        subtitleLocize = {
          key: 'POMELO_CREDIT_ALTERNATE_SUBTITLE',
          val: 'The Sale itme(s) will be refunded as Pomelo credit. Just as long as you manually enter the amount at checkout.',
        }
      }
    }

    if (hasOneCondition) {
      bodyJsx = (
        <Fragment>
          <div className="credit-info__header-icon">
            <Icon
              src={`${env.IMG_HOST}${conditions[0].icon}`}
              size={ICON_SIZE.extraLarge}
            />
          </div>
          <div
            className={`credit-info__title-gap ${
              subtitleLocize ? Typo.subtitle2 : Typo.h6
            }`}
          >
            {hasLocizeTranslation(t, titleLocize.key, titleLocize.val)}
          </div>
          {!!subtitleLocize && (
            <div className={clsx('credit-info__subtitle', Typo.caption)}>
              {hasLocizeTranslation(t, subtitleLocize.key, subtitleLocize.val)}
            </div>
          )}
          <div className={clsx('credit-info__single-desc', Typo.body2)}>
            {conditions[0].description}
          </div>
        </Fragment>
      )
    } else {
      bodyJsx = (
        <Fragment>
          <div
            className={`${
              subtitleLocize ? Typo.subtitle2 : Typo.h6
            } credit-info___title`}
          >
            {hasLocizeTranslation(t, titleLocize.key, titleLocize.val)}
          </div>
          {!!subtitleLocize && (
            <div className={clsx('credit-info__subtitle', Typo.caption)}>
              {hasLocizeTranslation(t, subtitleLocize.key, subtitleLocize.val)}
            </div>
          )}
          {conditions.map((condition) => (
            <div className="credit-info__block" key={condition.icon}>
              <ResponsiveImage
                className="credit-info__block-icon"
                src={`${env.IMG_HOST}${condition.icon}`}
                phone="20vw"
                laptopAndUp="10vw"
                lazy={false}
              />
              <span className={clsx(Typo.body2, 'credit-info__block-desc')}>
                {condition.description}
              </span>
            </div>
          ))}
        </Fragment>
      )
    }
  }

  return (
    <Dialog
      closable={!hasOneCondition}
      confirmable={hasOneCondition}
      onClose={onClose}
      onConfirm={onClose}
    >
      <style jsx>{styles}</style>
      <div
        className={clsx(
          !hasOneCondition
            ? 'credit-info__wrapper-left'
            : 'credit-info__wrapper',
        )}
      >
        {bodyJsx}
      </div>
    </Dialog>
  )
}

CreditInformation.defaultProps = {
  isCheckoutFlowText: false,
  showMoreDetails: false,
}

CreditInformation.propTypes = {
  isCheckoutFlowText: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  showMoreDetails: PropTypes.bool,
  t: PropTypes.func.isRequired,
}

export default translate('global')(CreditInformation)
