import React, { useEffect, useState, useCallback, useRef } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withI18next } from 'lib/i18n/withI18next'
import { segmentCookieNoticeResponded } from 'lib/segment'
import { calculateBottomGapOfAdsBanner } from 'lib/utils/common/commonUtils'
import StateStorage from 'lib/state-storage'
import AlertAdsBanner from 'components/alert/alert-ads'
import CookieSetting from 'components/alert/alert-cookie/cookie-setting'
import styles from './style.scss'
import CookieBanner from './cookie-banner'

const AlertCookie = ({ showLogin, user, showAdsBanner, isPhone }) => {
  const [isCookieBannerNotActive, setIsCookieBannerNotActive] = useState(true)
  const [isShowSetting, setIsShowSetting] = useState(false)
  const bannerPDPACookieConsent = StateStorage.getCookie(
    'bannerPDPACookieConsent',
  )
  const alertAdsBannerRef = useRef(null)
  const displayAdsBanner = showAdsBanner && !showLogin && !isPhone
  const showCookieBanner =
    !isCookieBannerNotActive &&
    !bannerPDPACookieConsent &&
    !isShowSetting &&
    !showLogin

  const showCookieBannerRef = useRef(showCookieBanner)

  useEffect(() => {
    showCookieBannerRef.current = showCookieBanner
  }, [showCookieBanner])

  useEffect(() => {
    setIsCookieBannerNotActive(!!user)
  }, [user])

  const updateAlertAdsBanner = React.useCallback(() => {
    if (!alertAdsBannerRef?.current) {
      return
    }

    const root = document.documentElement
    const percentageHorizontalScroll =
      (root.scrollTop / (root.scrollHeight - root.clientHeight)) * 100

    alertAdsBannerRef.current.style.marginBottom = `${calculateBottomGapOfAdsBanner(
      percentageHorizontalScroll,
    )}px`
  }, [])

  useEffect(() => {
    if (!showCookieBanner) {
      window.addEventListener('scroll', updateAlertAdsBanner)
    }

    return () => window.removeEventListener('scroll', updateAlertAdsBanner)
  }, [showCookieBanner, updateAlertAdsBanner])

  const disableCookieBanner = useCallback((type) => {
    const options = {
      is_performance_allowed: true,
      is_marketing_allowed: true,
    }

    setIsCookieBannerNotActive(true)
    switch (type) {
      case 'customize':
        setIsShowSetting(true)
        break
      case 'accept all':
        segmentCookieNoticeResponded(options)
        StateStorage.saveBannerStatus('PDPACookieConsent', options)
        window.location.reload()
        break
      default:
    }
  }, [])

  return (
    <div className="alert-cookie">
      <style jsx>{styles}</style>
      {displayAdsBanner && (
        <div className="alert-cookie__ads-banner" ref={alertAdsBannerRef}>
          <AlertAdsBanner />
        </div>
      )}
      {showCookieBanner && (
        <CookieBanner
          disableBanner={(type) => {
            disableCookieBanner(type)
            updateAlertAdsBanner()
          }}
        />
      )}
      {isShowSetting && <CookieSetting />}
    </div>
  )
}

AlertCookie.defaultProps = {
  user: null,
}

AlertCookie.propTypes = {
  showLogin: PropTypes.bool.isRequired,
  user: PropTypes.shape({}),
  showAdsBanner: PropTypes.bool.isRequired,
  isPhone: PropTypes.bool.isRequired,
}

const Extended = withI18next()(AlertCookie)
export default connect((state) => ({
  showLogin: state.auth.showLogin,
  user: state.auth.user,
}))(Extended)
