import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import Typo from 'constants/typography'
import styles from './style.scss'

/**
 *
 * @param {string} param0.className classname of the top most wrapper element.
 * @param {boolean} param0.error flag to display error apearrance
 * @param {Element} param0.helperText the text that show below input
 * @param {string} param0.label textfield's label
 * @param {boolean} param0.shrink flag to set label to be alway on the top of input
 * @param {boolean} param0.fullWidth flag to set the component display with full width.
 * @param {Element} param0.children the component that to be wrap with this layout
 * @param {boolean} param0.shiftLabelLeft add padding to leftside of layout
 */
const InputLayout = ({
  children,
  className,
  error,
  fieldId,
  fullWidth,
  helperText,
  htmlFor,
  label,
  shiftLabelLeft,
  shiftWidth,
  shrink,
}) => (
  <div
    className={clsx(
      'pml-input-layout__container',
      { 'full-width': fullWidth },
      className,
    )}
  >
    <style jsx>{styles}</style>
    <div className="pml-input-layout__input-wrapper">
      {children}
      {!!label && (
        <label
          htmlFor={htmlFor || label}
          className={clsx(
            'pml-input-layout__label',
            shrink ? Typo.caption : Typo.body1,
            {
              float: shrink,
            },
          )}
          style={shiftLabelLeft && !shrink ? { left: shiftWidth } : undefined}
        >
          {label}
        </label>
      )}
    </div>
    {/* Add the optional to reserve space for the default error message. */}
    {helperText && (
      <div
        data-cy={`${fieldId}__helper__text__${error ? 'error' : ''}`}
        className={clsx('pml-input-layout__helper-text', { error })}
      >
        <span className={Typo.caption}>{helperText}</span>
      </div>
    )}
  </div>
)

InputLayout.defaultProps = {
  className: undefined,
  error: false,
  fieldId: undefined,
  fullWidth: false,
  helperText: undefined,
  htmlFor: undefined,
  label: '',
  shiftLabelLeft: false,
  shiftWidth: 24,
  shrink: false,
}

InputLayout.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  error: PropTypes.bool,
  fieldId: PropTypes.string,
  fullWidth: PropTypes.bool,
  helperText: PropTypes.node,
  htmlFor: PropTypes.string,
  label: PropTypes.string,
  shiftLabelLeft: PropTypes.bool,
  shiftWidth: PropTypes.number,
  shrink: PropTypes.bool,
}

export default InputLayout
