const sortButtonLabel = Object.freeze({
  RECENTLY_ADDED: 'recentlyAdded',
  HIGH_TO_LOW: 'highToLow',
  LOW_TO_HIGH: 'lowToHigh',
})

const sortButtonValue = Object.freeze({
  RECENTLY_ADDED_TEXT: 'Recently Added',
  HIGH_TO_LOW_TEXT: 'Price: High to Low',
  LOW_TO_HIGH_TEXT: 'Price: Low to High',
})

const sortOptions = [
  {
    label: sortButtonValue.RECENTLY_ADDED_TEXT,
    value: sortButtonLabel.RECENTLY_ADDED,
  },
  {
    label: sortButtonValue.HIGH_TO_LOW_TEXT,
    value: sortButtonLabel.HIGH_TO_LOW,
  },
  {
    label: sortButtonValue.LOW_TO_HIGH_TEXT,
    value: sortButtonLabel.LOW_TO_HIGH,
  },
]

const wishListProductTag = Object.freeze({
  SOLD_OUT: 'soldOut',
  ALL_PRODUCTS: 'allProducts',
  IN_STOCK: 'inStock',
  COMING_SOON: 'comingSoon',
})

export { sortButtonLabel, sortButtonValue, sortOptions, wishListProductTag }
