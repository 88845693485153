import { hasLocizeTranslation } from 'lib/utils/locize/index'
import {
  FREE_GIFT_CART_CONDITION_LOCIZE,
  FREE_GIFT_CONDITION_LOCIZE,
} from 'components/cart/free-gift/const'

const getAddToCartCartParam = (cartData, product) => ({
  body: {
    is_store_credits_applied: cartData.isStoreCreditUsed,
  },
  productAttributeID: product.attributes[0].id_product_attribute,
  productID: product.id_product,
  quantity: 1,
  type: 'AddToCart',
})

const getAddToCartWithSizeCartParam = (cartData, product, size) => ({
  body: {
    is_store_credits_applied: cartData.isStoreCreditUsed,
  },
  productAttributeID: size.id_product_attribute,
  productID: product.id_product,
  quantity: 1,
  type: 'AddToCart',
})

const handleResponse = (res, cartData, dependentFunctions) => {
  const { callCloseModal, hasError, updateCart, updateIsFreeGiftAdded } =
    dependentFunctions
  if (res.error) {
    hasError(res.error)
  } else {
    updateCart({
      cartOpen: true,
      couponUsed: cartData.couponUsed,
      freeGiftOpen: false,
      isStoreCreditUsed: cartData.isStoreCreditUsed,
    })
    updateIsFreeGiftAdded(true)
    setTimeout(callCloseModal, 300)
  }
}

const handleAddToCart = (
  resetCart,
  handleCart,
  cartData,
  cartParam,
  callCloseModal,
  hasError,
  updateCart,
  updateIsFreeGiftAdded,
) => {
  resetCart()
  handleCart(cartParam)
    .then((res) => {
      const dependentFunctions = {
        callCloseModal,
        hasError,
        updateCart,
        updateIsFreeGiftAdded,
      }
      handleResponse(res, cartData, dependentFunctions)
    })
    .catch(
      // cannot intercept as the error on the test
      /* istanbul ignore next */
      (err) => {
        hasError(err || {})
      },
    )
}

const getSelectedSize = (event, cartData) => {
  const target = event.currentTarget.options[event.currentTarget.selectedIndex]
  const productAttribute = target.getAttribute('data-attr-id')
  const metadata = target.getAttribute('data-meta-data')
  const size = target.getAttribute('value')
  const lowstock = target.getAttribute('data-stock-is_low_stock')
  const soldout = target.getAttribute('data-stock-is_sold_out')

  // Fixed max quantity to 1 for free gift at this time
  const quantity = 1
  return {
    body: {
      is_store_credits_applied: cartData.isStoreCreditUsed,
    },
    id_product_attribute: productAttribute,
    metadata: {
      reference: metadata,
    },
    size,
    stock: {
      quantity,
      is_low_stock: lowstock,
      is_sold_out: soldout,
    },
  }
}

const handleChangeSize = (
  event,
  cartData,
  resetCart,
  handleCart,
  product,
  callCloseModal,
  hasError,
  updateCart,
  updateIsFreeGiftAdded,
) => {
  const selectedSize = getSelectedSize(event, cartData)
  const cartParam = getAddToCartWithSizeCartParam(
    cartData,
    product,
    selectedSize,
  )
  handleAddToCart(
    resetCart,
    handleCart,
    cartData,
    cartParam,
    callCloseModal,
    hasError,
    updateCart,
    updateIsFreeGiftAdded,
  )
}

const freeGiftPriceCondition = (
  hasQuantity,
  hasCategoryPrice,
  FREE_GIFT_LOCIZE,
) => {
  if (hasQuantity && hasCategoryPrice) {
    return FREE_GIFT_LOCIZE.CONDITION_PRICE_QUANTITY_CATEGORY
  }
  if (hasQuantity) {
    return FREE_GIFT_LOCIZE.CONDITION_PRICE_QUANTITY
  }
  if (hasCategoryPrice) {
    return FREE_GIFT_LOCIZE.CONDITION_PRICE_CATEGORY
  }

  return FREE_GIFT_LOCIZE.CONDITION_PRICE
}

const freeGiftQuantityCondition = (
  hasQuantity,
  hasCategoryPrice,
  hasCategoryQuantity,
  FREE_GIFT_LOCIZE,
) => {
  if ((hasCategoryQuantity || hasCategoryPrice) && hasQuantity) {
    return FREE_GIFT_LOCIZE.CONDITION_QUANTITY_CATEGORY
  }
  if (hasQuantity) {
    return FREE_GIFT_LOCIZE.CONDITION_QUANTITY
  }
  return ''
}

const isHasPrice = (condition, isFreeGiftModal) => {
  const { minimum_amount, remaining_amount } = condition?.minimum_amount || {}
  return isFreeGiftModal
    ? remaining_amount && remaining_amount !== 0
    : minimum_amount && minimum_amount !== 0
}

const isHasQuantity = (condition, isFreeGiftModal) => {
  const { minimum_amount, remaining_amount } = condition?.minimum_item || {}
  return isFreeGiftModal
    ? remaining_amount && remaining_amount !== 0
    : minimum_amount && minimum_amount !== 0
}

const getPrice = (condition, isFreeGiftModal) => {
  const { remaining_amount_formatted, minimum_amount_formatted } =
    condition?.minimum_amount || {}
  return isFreeGiftModal ? remaining_amount_formatted : minimum_amount_formatted
}

const getQuantity = (condition, isFreeGiftModal) => {
  const { remaining_amount, minimum_amount } = condition?.minimum_item || {}
  return isFreeGiftModal ? remaining_amount : minimum_amount
}

const getFreeGiftCondition = (condition, t, isFreeGiftModal) => {
  const hasPrice = isHasPrice(condition, isFreeGiftModal)
  const hasQuantity = isHasQuantity(condition, isFreeGiftModal)
  const hasCategoryPrice = condition?.minimum_amount?.category
  const hasCategoryQuantity = condition?.minimum_item?.category
  const price = getPrice(condition, isFreeGiftModal)
  const quantity = getQuantity(condition, isFreeGiftModal)
  const getCategoryPrice = hasCategoryPrice?.name
  const getCategoryQuantity = hasCategoryQuantity?.name
  const FREE_GIFT_LOCIZE = isFreeGiftModal
    ? FREE_GIFT_CONDITION_LOCIZE
    : FREE_GIFT_CART_CONDITION_LOCIZE

  const locizeText = hasPrice
    ? freeGiftPriceCondition(hasQuantity, hasCategoryPrice, FREE_GIFT_LOCIZE)
    : freeGiftQuantityCondition(
        hasQuantity,
        hasCategoryPrice,
        hasCategoryQuantity,
        FREE_GIFT_LOCIZE,
      )
  return locizeText
    ? hasLocizeTranslation(t, locizeText.key, locizeText.text)
        .replace('%d', price)
        .replace('%q', quantity)
        .replace('%c', getCategoryQuantity || getCategoryPrice)
    : ''
}

export {
  getAddToCartCartParam,
  getAddToCartWithSizeCartParam,
  getFreeGiftCondition,
  handleResponse,
  handleChangeSize,
  handleAddToCart,
}
