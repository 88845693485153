import { getWishlistProductMultipleSizes } from 'components/wishlist/utils'
import WishlistApiHandler from 'lib/api/wishlist'
import { segmentTrackProductAddedToWishlist } from 'lib/segment'
import StateStorage from 'lib/state-storage'

/**
 * @param {string} productId
 * @returns {Promise}
 */
export const deleteWishlist = async (productId) => {
  const res = await WishlistApiHandler.deleteWishlist({
    productId,
  })

  return res
}

/**
 * @param {string} productId
 * @param {Function} setCancelingID
 * @param {Function} setIsSnackBarOpen
 * @returns {Promise|null}
 */
export const checkWishlistProductMultipleSizes = async (
  productId,
  setCancelingID,
  setIsSnackBarOpen,
) => {
  const user = StateStorage.getAuthUser()
  const hasWishlistProductMultipleSizes = await getWishlistProductMultipleSizes(
    productId,
    user,
  )

  if (hasWishlistProductMultipleSizes) {
    setCancelingID(productId)
    setIsSnackBarOpen(true)
    return null
  }

  const res = await deleteWishlist(productId)

  return res
}

/**
 * @param {Object} params
 * @param {boolean} params.isWishlisted
 * @param {string} params.productId
 * @param {Object} params.product
 * @param {string} params.refType
 * @param {Function} params.setCancelingID
 * @param {Function} params.setIsDeleteWishlistProductTimeOut
 * @param {Function} params.setIsSnackBarOpen
 * @param {Function} params.setUndoClicked
 * @returns {Promise} A promise that resolves with the API response
 * @throws {Error}
 */
export const updateWishlistItem = async ({
  isWishlisted,
  productId,
  product,
  refType,
  setCancelingID,
  setIsDeleteWishlistProductTimeOut,
  setIsSnackBarOpen,
  setUndoClicked,
}) => {
  try {
    let res

    if (isWishlisted) {
      setIsDeleteWishlistProductTimeOut(false)
      setUndoClicked(false)
      res = await checkWishlistProductMultipleSizes(
        productId,
        setCancelingID,
        setIsSnackBarOpen,
      )
    } else {
      segmentTrackProductAddedToWishlist(product, refType)
      res = await WishlistApiHandler.addWishlist({ product_id: productId })
    }

    return res
  } catch (err) {
    throw new Error(err)
  }
}
