import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { translate } from 'react-i18next'
import Loader from 'components/loader'
import ProductList from 'components/product/product-list'
import { segmentTrackProductListViewed } from 'lib/segment'
import Typo from 'constants/typography'
import styles from './style.scss'

class CategoryProductList extends React.Component {
  state = {
    name: null,
  }

  static getDerivedStateFromProps(props) {
    const { category, title } = props

    if (title) {
      return {}
    }

    if (category) {
      const { id_category, name } = category

      // Custom category title some categories
      switch (id_category) {
        case 1194:
          return { name: '30% off' }
        case 1195:
          return { name: '40-50% off' }
        case 1196:
          return { name: '60-70% off' }
        default:
          return { name }
      }
    }

    return { name: null }
  }

  componentDidMount() {
    const { refType, refId, list, category } = this.props
    if (list?.length > 0 && refType !== 'category') {
      segmentTrackProductListViewed(
        {
          id_site_category: category?.id_category,
          page_number: 1,
          product_list_ids: list.map((item) => item.id_product),
          site_category_name: category?.name || '',
        },
        { ref_type: refType, ref_id: refId },
      )
    }
  }

  renderTitle() {
    const { t, title } = this.props
    const { name } = this.state

    if (title) {
      return title
    }

    if (name) {
      return `${t('ALL')} ${t(name)}`
    }

    return null
  }

  render() {
    const {
      category,
      displayHoverInteractions,
      displayQuickAddToBag,
      displayWishlistIcon,
      idx,
      isFetchingProducts,
      isSearchPage,
      isSubCategory,
      list,
      onProductPageChanged,
      pageName,
      refId,
      refType,
      showTeaser,
    } = this.props

    return (
      <div
        className={`category-list__wrapper${
          isSubCategory ? ' is-sub-category' : ''
        }`}
      >
        <style jsx>{styles}</style>
        {isSubCategory && (
          <span
            className={`${Typo.subtitle2} category-list__title${
              idx === 0 ? ' is-first' : ''
            }`}
          >
            {this.renderTitle()}
          </span>
        )}
        <ProductList
          category={category}
          displayDescription
          displayHoverInteractions={displayHoverInteractions}
          displayQuickAddToBag={displayQuickAddToBag}
          displayPrice
          displaySwatches
          displayWishlistIcon={displayWishlistIcon}
          isFilterAvailable
          isSearchPage={isSearchPage}
          list={list}
          onProductPageChanged={onProductPageChanged}
          pageName={pageName}
          productsPerPage={18}
          promptLocation="wishlist_category"
          refId={refId}
          refType={refType}
          showTeaser={showTeaser}
        />
        {isFetchingProducts && (
          <div className="category-list__load-section">
            <Loader />
          </div>
        )}
      </div>
    )
  }
}

CategoryProductList.defaultProps = {
  category: null,
  displayHoverInteractions: false,
  displayQuickAddToBag: false,
  displayWishlistIcon: true,
  idx: null,
  isFetchingProducts: false,
  isSearchPage: false,
  isSubCategory: false,
  list: null,
  onProductPageChanged: null,
  pageName: '',
  refId: null,
  refType: 'category',
  showTeaser: false,
  title: null,
}

CategoryProductList.propTypes = {
  category: PropTypes.shape({
    id_category: PropTypes.number,
    name: PropTypes.string,
  }),
  displayHoverInteractions: PropTypes.bool,
  displayQuickAddToBag: PropTypes.bool,
  displayWishlistIcon: PropTypes.bool,
  idx: PropTypes.number,
  isFetchingProducts: PropTypes.bool,
  isSearchPage: PropTypes.bool,
  isSubCategory: PropTypes.bool,
  list: PropTypes.arrayOf(PropTypes.shape({})),
  onProductPageChanged: PropTypes.func,
  pageName: PropTypes.string,
  refId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  refType: PropTypes.string,
  showTeaser: PropTypes.bool,
  t: PropTypes.func.isRequired,
  title: PropTypes.string,
}

const Extended = translate('global')(CategoryProductList)

export { CategoryProductList }

export default connect(
  /* istanbul ignore next */
  (state) => ({
    isFetchingProducts: state.products.isFetching,
  }),
)(Extended)
