import clsx from 'clsx'
import PropTypes from 'prop-types'
import React, { useState, useCallback } from 'react'
import {
  clearRecentSearch,
  getKeywordList,
  getRecentSearchItems,
} from 'components/search/utils'
import Typo from 'constants/typography'
import { withI18next } from 'lib/i18n/withI18next'
import { hasLocizeTranslation } from 'lib/utils/locize'
import { clearCta, searchSidebarTitle } from '../const'
import SearchSidebarItem from '../sidebar-item'

const SearchSidebar = ({ onClose, t, setSearchWord }) => {
  const [isCleared, setIsCleared] = useState(false)

  // this is for trigger component re-render
  const updateClearStatus = useCallback(() => {
    setIsCleared(!isCleared)
  }, [isCleared])

  const updateKeyword = () => {
    clearRecentSearch()
    updateClearStatus()
    sessionStorage.removeItem('recent-search-word')
    setSearchWord('')
  }

  const recentSearchList = getRecentSearchItems()
  const isRecentSearchListValid = recentSearchList?.length > 0
  const keywordList = getKeywordList(isRecentSearchListValid, recentSearchList)

  const sidebarTitle = isRecentSearchListValid
    ? hasLocizeTranslation(
        t,
        searchSidebarTitle.recent.key,
        searchSidebarTitle.recent.value,
      )
    : hasLocizeTranslation(
        t,
        searchSidebarTitle.popular.key,
        searchSidebarTitle.popular.value,
      )

  const clearCtaText = hasLocizeTranslation(t, clearCta.key, clearCta.value)

  const clearCtaTitleClass = clsx('clear-btn', Typo.overline, {
    active: isRecentSearchListValid,
  })

  return (
    <div className="search-sidebar__wrapper">
      <div className="search-sidebar__title__container">
        <div className={clsx('search-sidebar__title', Typo.subtitle2)}>
          {sidebarTitle}
        </div>
        {isRecentSearchListValid && (
          <div className="clear-btn__container">
            <button className={clearCtaTitleClass} onClick={updateKeyword}>
              {clearCtaText}
            </button>
          </div>
        )}
      </div>
      <SearchSidebarItem
        keywordList={keywordList}
        onClose={onClose}
        isRecentSearchValid={isRecentSearchListValid}
        setSearchWord={setSearchWord}
      />
    </div>
  )
}

SearchSidebar.propTypes = {
  onClose: PropTypes.func.isRequired,
  setSearchWord: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
}

export default withI18next()(SearchSidebar)
