// The desktop layout has 3 products per row
//   No filter rule:
//       - the product quantity must be at least 10 (>= 4 product rows)
//       - Hoolah banner is located in the 4th row. So it needs to follow the 9th (index = 8) product
//   With filter rule:
//       - the product quantity is lower than 10 (<= 3 product rows)
//       - Hoolah banner is at the bottom of the product list.
//         So it need to follow the last product item (index = totalProducts - 1)
// The mobile layout has 2 products per row
//   No filter rule:
//       - the product quantity must be at least 11 (>= 6 product rows)
//       - Hoolah banner is located in the 6th row. So it needs to follow the 10th (index = 9) product
//   With filter rule:
//       - the product quantity is lower than 11 (<= 5 product rows)
//       - Hoolah banner is at the bottom of the product list sticking with the footer.
//         So it needs to follow the last product item (index = totalProducts - 1)
// Note: Sometimes, the product quantity (without filter) can be lower than the criteria (10 or 11),
//       but the rule still applies. The name portrays most cases. Don't be confuse by it.
const getHoolahBannerEligibilityStatus = (
  idx,
  totalProducts,
  hoolahDesktopRule,
  hoolahMobileRule,
) => {
  const hoolahDesktopNoFilterRule =
    hoolahDesktopRule && totalProducts >= 10 && idx === 8
  const hoolahMobileNoFilterRule =
    hoolahMobileRule && totalProducts >= 11 && idx === 9
  const hoolahDesktopWithFilterRule =
    hoolahDesktopRule && totalProducts < 10 && idx === totalProducts - 1
  const hoolahMobileWithFilterRule =
    hoolahMobileRule && totalProducts < 11 && idx === totalProducts - 1

  return {
    isAtBottomMobile: hoolahMobileWithFilterRule,
    withHoolahBanner:
      hoolahDesktopNoFilterRule ||
      hoolahMobileNoFilterRule ||
      hoolahDesktopWithFilterRule ||
      hoolahMobileWithFilterRule,
  }
}

export default getHoolahBannerEligibilityStatus
