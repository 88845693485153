import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import { withI18next } from 'lib/i18n/withI18next'
import clsx from 'clsx'
import Icon from 'components/common/icon'
import ICONS, { ICON_SIZE } from 'components/common/icon/const'
import Typo from 'constants/typography'
import styles from './drawer.scss'

const Drawer = ({
  title,
  children,
  onClickBack,
  isOpenDrawer,
  onCloseDrawer,
  drawerTrigger,
  drawerPosition,
  isShowCloseDrawer,
}) => {
  const isShowBackBtn = Boolean(onClickBack)

  useEffect(() => {
    if (isOpenDrawer) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = ''
    }

    return () => {
      document.body.style.overflow = ''
    }
  }, [isOpenDrawer])

  return (
    <React.Fragment>
      <style jsx>{styles}</style>
      <div className="drawer-container">
        <div>{drawerTrigger}</div>
        <div
          role="presentation"
          onClick={() => onCloseDrawer?.()}
          onKeyDown={() => onCloseDrawer?.()}
          className={clsx('drawer-container__modal', {
            active: isOpenDrawer,
          })}
        />
        <div
          className={clsx('drawer-container__inner', {
            active: isOpenDrawer,
            right: drawerPosition === 'right',
            left: drawerPosition === 'left',
          })}
        >
          <div className="drawer-container__top-header">
            {isShowBackBtn && (
              <Icon
                src={ICONS.arrowHeadLeft}
                size={ICON_SIZE.small}
                onClick={() => onClickBack()}
                className="drawer-container__top-header__back-btn"
              />
            )}
            <span
              className={clsx(
                Typo.subtitle2,
                'drawer-container__top-header__title',
              )}
            >
              {title}
            </span>
            {isShowCloseDrawer && (
              <Icon
                alt="close-icon"
                src={ICONS.close}
                size={ICON_SIZE.small}
                onClick={() => onCloseDrawer?.()}
                className="drawer-container__top-header__close-btn"
              />
            )}
          </div>
          <div className="drawer-container__body">{children}</div>
        </div>
      </div>
    </React.Fragment>
  )
}

Drawer.defaultProps = {
  title: '',
  children: null,
  onClickBack: null,
  isOpenDrawer: false,
  onCloseDrawer: null,
  drawerTrigger: null,
  drawerPosition: 'right',
  isShowCloseDrawer: true,
}

Drawer.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  onClickBack: PropTypes.func,
  isOpenDrawer: PropTypes.bool,
  onCloseDrawer: PropTypes.func,
  drawerTrigger: PropTypes.node,
  drawerPosition: PropTypes.string,
  isShowCloseDrawer: PropTypes.bool,
}

export default compose(
  connect((state) => ({
    isPhone: state.device.isPhone,
  })),
  withI18next(),
)(Drawer)
