import React from 'react'
import PropTypes from 'prop-types'
import { withI18next } from 'lib/i18n/withI18next'
import { updateLagacyShoppingTrack } from 'lib/auth'
import { BASE_PRODUCT_LIMIT } from 'components/cart/const'
import SizeAndQuantitySelection from 'components/size-quantity-selection'
import CartChangeFreeGiftButton from '../cart-change-free-gift-button/index'

const CartProductSizeQuantity = ({
  addToCart,
  allowProductUpdate,
  applyStoreCredit,
  cartProducts,
  isFreeGift,
  isSoldOut,
  openFreeGift,
  product,
  selectedAttribute,
  setError,
  updateCart,
  isShoppingBagNewDesign,
}) => {
  const hasMultipleFreeGifts =
    cartProducts?.eligibility?.free_gift?.has_multiple
  const isFreeGiftButtonDisplay =
    allowProductUpdate &&
    hasMultipleFreeGifts &&
    isFreeGift &&
    !isShoppingBagNewDesign

  const handleUpdateCart = async (
    productID,
    productToDeleteAttributeID,
    productToAddAttributeID,
    quantity,
  ) => {
    const updateCartRes = await updateCart({
      body: {
        is_store_credits_applied: applyStoreCredit,
      },
      productID,
      productToDeleteAttributeID,
      productToAddAttributeID,
      quantity,
      keepPayload: true,
      ignoreCache: true,
    })

    if (updateCartRes?.error) {
      setError(updateCartRes.error.message)
      // Revert deleted product when selected size isn't available
      const addToCartRes = await addToCart({
        productID,
        productAttributeID: productToDeleteAttributeID,
        quantity: product.quantity,
      })

      // Existing Product isn't available anymore
      if (addToCartRes?.error) {
        setError(addToCartRes.error.message)
      }
    }

    await updateLagacyShoppingTrack(updateCartRes)
  }

  const handleChangeQuantity = async (prodId, attrId, quantity = 1) => {
    const limit = product.max_quantity || BASE_PRODUCT_LIMIT
    await handleUpdateCart(prodId, attrId, attrId, Math.min(limit, quantity))
  }

  const handleChangeSize = async (val, attrIdOld, prodId) => {
    await handleUpdateCart(prodId, attrIdOld, val, 1)
  }

  return (
    <React.Fragment>
      <SizeAndQuantitySelection
        allowProductUpdate={allowProductUpdate}
        handleChangeQuantity={handleChangeQuantity}
        handleChangeSize={handleChangeSize}
        isShoppingBagNewDesign={isShoppingBagNewDesign}
        isSoldOut={isSoldOut}
        product={product}
        selectedAttribute={selectedAttribute}
      />
      {isFreeGiftButtonDisplay && (
        <CartChangeFreeGiftButton onClick={openFreeGift} />
      )}
    </React.Fragment>
  )
}

CartProductSizeQuantity.defaultProps = {
  allowProductUpdate: undefined,
  applyStoreCredit: undefined,
  cartProducts: undefined,
  setError: undefined,
  updateCart: undefined,
  isShoppingBagNewDesign: false,
}

CartProductSizeQuantity.propTypes = {
  addToCart: PropTypes.func.isRequired,
  allowProductUpdate: PropTypes.bool,
  applyStoreCredit: PropTypes.bool,
  cartProducts: PropTypes.shape({
    eligibility: PropTypes.shape({
      free_gift: PropTypes.shape({ has_multiple: PropTypes.bool }),
    }),
    items: PropTypes.arrayOf(PropTypes.shape({})),
    summary: PropTypes.shape({ store_credits: PropTypes.shape({}) }),
  }),
  isFreeGift: PropTypes.bool.isRequired,
  isSoldOut: PropTypes.bool.isRequired,
  openFreeGift: PropTypes.func.isRequired,
  product: PropTypes.shape({
    id_product: PropTypes.number,
    attributes: PropTypes.arrayOf(PropTypes.shape({})),
    name: PropTypes.string,
    max_quantity: PropTypes.number,
    type: PropTypes.string,
    quantity: PropTypes.number,
  }).isRequired,
  selectedAttribute: PropTypes.shape({
    id_product_attribute: PropTypes.number,
    size: PropTypes.string,
    selected: PropTypes.bool,
    stock: PropTypes.shape({
      quantity: PropTypes.number,
      is_low_quantity: PropTypes.bool.isRequired,
      is_sold_out: PropTypes.bool.isRequired,
    }),
  }).isRequired,
  setError: PropTypes.func,
  updateCart: PropTypes.func,
  isShoppingBagNewDesign: PropTypes.bool,
}

export default withI18next()(CartProductSizeQuantity)
