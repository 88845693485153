export const CART_FREE_GIFT_MODAL_ID = 'cart-free-gift'

export const FREE_GIFT_CONDITION_LOCIZE = {
  CONDITION_PRICE_QUANTITY_CATEGORY: {
    key: 'FREE_GIFT_CONDTION_QUANTITY_PRICE_CATEGORY',
    text: 'Spend %d more and purchase %q items on %c to receive a free gift!',
  },
  CONDITION_PRICE_QUANTITY: {
    key: 'FREE_GIFT_CONDTION_PRICE_QUANTITY',
    text: 'Spend %d more and purchase %q items more to receive a free gift!',
  },
  CONDITION_PRICE_CATEGORY: {
    key: 'FREE_GIFT_CONDTION_PRICE_CATEGORY',
    text: 'Spend %d more on %c to receive a free gift!',
  },
  CONDITION_PRICE: {
    key: 'FREE_GIFT_CONDTION_PRICE',
    text: 'Spend %d more to receive a free gift!',
  },
  CONDITION_QUANTITY_CATEGORY: {
    key: 'FREE_GIFT_CONDTION_QUANTITY_CATEGORY',
    text: 'Purchase %q items more on %c to receive a free gift!',
  },
  CONDITION_QUANTITY: {
    key: 'FREE_GIFT_CONDTION_QUANTITY',
    text: 'Purchase %q more to receive a free gift!',
  },
}

export const FREE_GIFT_CART_CONDITION_LOCIZE = {
  CONDITION_PRICE_QUANTITY_CATEGORY: {
    key: 'FREE_GIFT_CART_CONDITION_PRICE_QUANTITY_CATEGORY',
    text: 'You have unlocked this free gift from spending %d and buying %q items on %c products',
  },
  CONDITION_PRICE_QUANTITY: {
    key: 'FREE_GIFT_CART_CONDITION_PRICE_QUANTITY',
    text: 'You have unlocked this free gift from spending %d and buying %q items',
  },
  CONDITION_PRICE_CATEGORY: {
    key: 'FREE_GIFT_CART_CONDITION_PRICE_CATEGORY',
    text: 'You have unlocked this free gift from spending %d on %c products',
  },
  CONDITION_PRICE: {
    key: 'FREE_GIFT_CART_CONDITION_PRICE',
    text: 'You have unlocked this free gift from spending %d',
  },
  CONDITION_QUANTITY_CATEGORY: {
    key: 'FREE_GIFT_CART_CONDITION_QUANTITY_CATEGORY',
    text: 'You have unlocked this free gift from buying %q items on %c products',
  },
  CONDITION_QUANTITY: {
    key: 'FREE_GIFT_CART_CONDITION_QUANTITY',
    text: 'You have unlocked this free gift from buying %q items',
  },
}
