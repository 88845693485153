import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import Typo from 'constants/typography'
import Icon, { ICONS, ICON_SIZE } from 'components/common/icon'

const ProductItemPriceSection = ({
  cy,
  isPhone,
  onClick,
  productId,
  productUrl,
  displayPrice,
  discountBadge,
  priceFormatted,
  isShowFlashSaleIcon,
  discountedPriceFormatted,
}) => {
  const isShowDiscountedPrice =
    discountedPriceFormatted && discountedPriceFormatted !== priceFormatted

  const renderPriceContent = () => {
    if (!isShowDiscountedPrice) {
      return (
        <span
          className={clsx(
            'product_price__wrapper',
            'without-discount',
            Typo.price2,
          )}
          data-cy={`product_item_price_${productId}`}
        >
          {priceFormatted}
        </span>
      )
    }

    if (isPhone) {
      return (
        <React.Fragment>
          <div className="product_price__wrapper with-discount">
            <span className={clsx('discounted__price', Typo.price2)}>
              {discountedPriceFormatted}
            </span>
            {!!discountBadge && (
              <span
                className="reduction-label"
                data-cy={cy ? `${cy}__reduction` : undefined}
              >
                <span className={Typo.price2}>{`-${discountBadge}`}</span>
                {isShowFlashSaleIcon && (
                  <Icon src={ICONS.flashSale} size={ICON_SIZE.extraSmall} />
                )}
              </span>
            )}
          </div>
          <span
            className={clsx(Typo.price2, 'with-discount-original-price')}
            data-cy={`product_item_price_${productId}__discount`}
          >
            {priceFormatted}
          </span>
        </React.Fragment>
      )
    }

    return (
      <div className="product_price__wrapper with-discount">
        <span className={clsx('discounted__price', Typo.price2)}>
          {discountedPriceFormatted}
        </span>
        <span
          className={clsx(Typo.price2, 'with-discount-original-price')}
          data-cy={`product_item_price_${productId}__discount`}
        >
          {priceFormatted}
        </span>
        {!!discountBadge && (
          <span
            className="reduction-label"
            data-cy={cy ? `${cy}__reduction` : undefined}
          >
            <span className={Typo.price2}>{`-${discountBadge}`}</span>
            {isShowFlashSaleIcon && (
              <Icon src={ICONS.flashSale} size={ICON_SIZE.extraSmall} />
            )}
          </span>
        )}
      </div>
    )
  }

  return (
    <div className="product_price_wishlist__container">
      {displayPrice && (
        <a href={productUrl} className="product_price__link" onClick={onClick}>
          {renderPriceContent()}
        </a>
      )}
    </div>
  )
}

ProductItemPriceSection.defaultProps = {
  cy: '',
  discountedPriceFormatted: '',
  displayPrice: false,
  onClick: () => null,
  priceFormatted: '',
  productId: 0,
  productUrl: '',
  isShowFlashSaleIcon: false,
  discountBadge: '',
  isPhone: false,
}

ProductItemPriceSection.propTypes = {
  cy: PropTypes.string,
  onClick: PropTypes.func,
  isPhone: PropTypes.bool,
  productId: PropTypes.number,
  productUrl: PropTypes.string,
  displayPrice: PropTypes.bool,
  discountBadge: PropTypes.string,
  priceFormatted: PropTypes.string,
  isShowFlashSaleIcon: PropTypes.bool,
  discountedPriceFormatted: PropTypes.string,
}

export default ProductItemPriceSection
