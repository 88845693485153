import { useEffect, useRef } from 'react'

export default function useInterval(callback, delay) {
  const callbackRef = useRef()

  useEffect(() => {
    callbackRef.current = callback
  })

  useEffect(() => {
    if (!delay) {
      return () => {}
    }

    const interval = setInterval(() => {
      if (callbackRef.current) {
        callbackRef.current()
      }
    }, delay)
    return () => clearInterval(interval)
  }, [delay])
}
