import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useTransition, animated } from 'react-spring'
import clsx from 'clsx'
import Portal from 'components/portal'
import { disableBodyScroll, clearAllBodyScrollLocks } from 'lib/utils/scroll'
import { toggleBodyFreezing } from 'lib/utils/common/commonUtils'
import Icon from 'components/common/icon'
import ICONS, { ICON_SIZE } from 'components/common/icon/const'
import styles from './styles.scss'

export const FullModal = ({ isOpen, children, onClose, isCloseRight }) => {
  const transitions = useTransition(isOpen, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  })
  const headerClass = clsx('full-modal__header', {
    ' right': isCloseRight,
  })

  useEffect(() => {
    const elem = document.querySelector('.full-modal')

    // disable body scroll when modal is open
    if (isOpen) {
      disableBodyScroll(elem, {
        allowTouchMove: (el) => {
          /* istanbul ignore next */
          while (el && el !== document.body) {
            if (el.className === 'full-modal') {
              return true
            }
            el = el.parentNode // eslint-disable-line no-param-reassign
          }
          return undefined
        },
      })
    }

    return () => {
      clearAllBodyScrollLocks()
    }
  }, [isOpen])

  return (
    <Portal>
      {transitions.map(
        ({ item, key, props }) =>
          item && (
            <animated.div style={props} className="full-modal" key={key}>
              <style jsx>{styles}</style>
              <div className={headerClass}>
                <Icon
                  className="close-icon"
                  src={ICONS.close}
                  size={ICON_SIZE.medium}
                  onClick={onClose}
                  alt="close-icon-fullmodal"
                />
              </div>
              <div className="full-modal__content">{children}</div>
            </animated.div>
          ),
      )}
    </Portal>
  )
}

export const CenterModal = ({ isOpen, children }) => {
  const transitions = useTransition(isOpen, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  })

  useEffect(() => {
    if (isOpen) {
      toggleBodyFreezing(true)
    }

    return () => {
      toggleBodyFreezing()
    }
  }, [isOpen])

  return (
    <Portal>
      {transitions.map(
        ({ item, key, props }) =>
          item && (
            <animated.div style={props} className="center-modal" key={key}>
              <style jsx>{styles}</style>
              <div className="center__content">{children}</div>
            </animated.div>
          ),
      )}
    </Portal>
  )
}

export const Modal = ({ isOpen, children, disabledPointerEvents }) => {
  const transitions = useTransition(isOpen, null, {
    from: { opacity: 0, transform: 'translate3d(100%,0,0)' },
    enter: { opacity: 1, transform: 'translate3d(0%,0,0)' },
    leave: { opacity: 0, transform: 'translate3d(30%,0,0)' },
  })

  return (
    <Portal>
      {transitions.map(
        ({ item, key, props }) =>
          item && (
            <animated.div
              style={props}
              className={`modal${
                disabledPointerEvents ? ' disabled-events' : ''
              }`}
              key={key}
            >
              <style jsx>{styles}</style>
              {children}
            </animated.div>
          ),
      )}
    </Portal>
  )
}

export const BackdropCenterModal = ({ isOpen, children, onClose }) => {
  const transitions = useTransition(isOpen, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  })

  useEffect(() => {
    if (isOpen) {
      toggleBodyFreezing(true)
    }

    return () => {
      toggleBodyFreezing()
    }
  }, [isOpen])

  return (
    <Portal>
      {transitions.map(
        ({ item, key, props }) =>
          item && (
            <animated.div
              style={props}
              className="backdrop-center__modal"
              key={key}
            >
              <style jsx>{styles}</style>
              <div
                className="backdrop-center__backdrop"
                onClick={onClose}
                aria-hidden="true"
                data-cy="pml-modal-backdrop"
              />
              <div className="backdrop-center__content">{children}</div>
            </animated.div>
          ),
      )}
    </Portal>
  )
}

FullModal.defaultProps = {
  isOpen: false,
  onClose: () => null,
  isCloseRight: false,
}

FullModal.propTypes = {
  isOpen: PropTypes.bool,
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func,
  isCloseRight: PropTypes.bool,
}

CenterModal.defaultProps = {
  isOpen: false,
}

CenterModal.propTypes = {
  isOpen: PropTypes.bool,
  children: PropTypes.node.isRequired,
}

Modal.defaultProps = {
  isOpen: false,
  disabledPointerEvents: false,
}

Modal.propTypes = {
  isOpen: PropTypes.bool,
  children: PropTypes.node.isRequired,
  disabledPointerEvents: PropTypes.bool,
}

BackdropCenterModal.defaultProps = {
  isOpen: false,
  onClose: null,
}

BackdropCenterModal.propTypes = {
  isOpen: PropTypes.bool,
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func,
}
