import clsx from 'clsx'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import checkoutPaymentMethodDuck from 'components/checkout/payment-options/duck'
import HoolahInfoModal from 'components/pdp/interactions/product-usp/hoolah-info-modal'
import ASSETS from 'components/pdp/interactions/product-usp/hoolah-info-modal/const'
import { Modal } from 'components/portal/modal'
import useModal from 'components/portal/useModal'
import Typo from 'constants/typography'
import { withI18next } from 'lib/i18n/withI18next'
import { hasLocizeTranslation } from 'lib/utils/locize'
import { PAYMENT_FETCH_TYPES } from 'lib/utils/payments/paymentMethodUtils'
import Loader from 'components/loader'
import styles from './styles.scss'

const getBannerClassName = (classname) => `hoolah-banner__${classname}`

const isHoolahAvailable = (paymentMethodList) => {
  let hoolahStatus
  if (paymentMethodList?.payment_methods) {
    const [hoolahPayment] = paymentMethodList.payment_methods.filter(
      (method) => method.type === 'hoolah',
    )
    hoolahStatus = !!hoolahPayment
  }
  return hoolahStatus
}

const HoolahBanner = ({
  fetchPaymentOptions,
  internationalization: { shop },
  isAtBottomMobile,
  paymentOptions,
  resetPaymentOptions,
  t,
}) => {
  const [isHoolahOpen, setIsHoolahOpen] = useModal(false)
  const hoolahDescTitle = hasLocizeTranslation(
    t,
    'HOOLAH_BANNER_DESC_TITLE',
    'Try 3 months installment with',
  )

  const closeHoolahModal = () => {
    setIsHoolahOpen(false)
  }

  const openHoolahModal = () => {
    setIsHoolahOpen(true)
  }

  useEffect(() => {
    resetPaymentOptions()
    fetchPaymentOptions({ type: PAYMENT_FETCH_TYPES.GET_PAYMENT_METHODS })
  }, [fetchPaymentOptions, resetPaymentOptions])

  if ([1, 2, 11].includes(shop) && !paymentOptions) {
    return (
      <div className={getBannerClassName('loader')}>
        <style jsx>{styles}</style>
        <Loader />
      </div>
    )
  }

  if (!isHoolahAvailable(paymentOptions)) {
    return null
  }

  return (
    <React.Fragment>
      <div
        className={clsx(getBannerClassName('wrapper'), {
          'disable-click-event': isHoolahOpen,
          'at-bottom': isAtBottomMobile,
        })}
        role="button"
        tabIndex={0}
        onClick={openHoolahModal}
        onKeyDown={openHoolahModal}
      >
        <style jsx>{styles}</style>
        <div className={getBannerClassName('desc-container')}>
          <div className={clsx('title', Typo.subtitle1)}>{hoolahDescTitle}</div>
          <img
            alt="hoolah logo"
            className="hoolah-logo"
            src={ASSETS.HOOLAH_LOGO}
          />
        </div>
      </div>
      <Modal isOpen={isHoolahOpen} disabledPointerEvents>
        <HoolahInfoModal onClose={closeHoolahModal} />
      </Modal>
    </React.Fragment>
  )
}

HoolahBanner.defaultProps = {
  paymentOptions: undefined,
}

HoolahBanner.propTypes = {
  fetchPaymentOptions: PropTypes.func.isRequired,
  internationalization: PropTypes.shape({
    shop: PropTypes.number.isRequired,
  }).isRequired,
  isAtBottomMobile: PropTypes.bool.isRequired,
  paymentOptions: PropTypes.shape({}),
  resetPaymentOptions: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
}

const Extended = withI18next()(HoolahBanner)

export default connect(
  (state) => ({
    internationalization: state.internationalization,
    paymentOptions: state?.checkoutPaymentMethod?.payload,
  }),
  (dispatch) =>
    bindActionCreators(
      {
        fetchPaymentOptions: checkoutPaymentMethodDuck.creators.get,
        resetPaymentOptions: checkoutPaymentMethodDuck.creators.invalidate,
      },
      dispatch,
    ),
)(Extended)
