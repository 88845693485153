import dynamic from 'next/dynamic'
import { HEADER_SEARCH_FORM_ID } from 'components/search/search-form'
import Exchange from 'lib/api/exchange'
import { segmentTrackAction } from 'lib/segment'
import { EVENT } from 'lib/segment/const'
import StateStorage from 'lib/state-storage'
import Tracking from 'lib/tracking'
import {
  getIsBodyFreezed,
  getISOCurrencyCodeWithShopID,
} from 'lib/utils/common/commonUtils'
import NoopLoader from '../noop-loader'

const DynamicConfig = { ssr: false, loading: NoopLoader }

const DesktopMenu = dynamic(
  () => import('components/navigation/desktop-menu'),
  DynamicConfig,
)
const MobileHamburgerMenu = dynamic(
  () => import('components/navigation/mobile-hamburger-menu'),
  DynamicConfig,
)
const DynamicFreeGift = dynamic(
  () => import('components/cart/free-gift'),
  DynamicConfig,
)
const DynamicCartNoticeModal = dynamic(
  // eslint-disable-next-line import/no-cycle
  () => import('components/cart/cart-notice'),
  DynamicConfig,
)

const DynamicHeaderUser = dynamic(
  () => import('components/user/header-user'),
  DynamicConfig,
)

const updateHeaderDesktopStyle = (isPhone) => {
  const elem = document.querySelector('header.header')
  const isBodyFreeze = getIsBodyFreezed()

  if (elem && !isBodyFreeze && !isPhone) {
    if (
      document.body.scrollTop > 100 ||
      document.documentElement.scrollTop > 100
    ) {
      elem.classList.add('small')
      elem.classList.remove('large')
    } else {
      elem.classList.remove('small')
      elem.classList.add('large')
    }
  }
}

const getProductList = (cart) => {
  const isCartHasProduct = cart?.items?.length
  let productList = []

  if (isCartHasProduct) {
    productList = cart.items.map((product) => {
      const {
        id_product: productID,
        prices: { discounted_price, original_price },
        quantity,
      } = product
      const tmpPrice = Exchange.syncConvertTo(
        discounted_price || original_price,
      )

      return {
        id: productID ? productID.toString() : null,
        price: tmpPrice ? tmpPrice.toString() : null,
        quantity: quantity ? quantity.toString() : null,
      }
    })
  }

  return productList
}

const getProductIds = (cart) => {
  const isCartHasProduct = cart?.items?.length
  const productIDList = isCartHasProduct
    ? cart.items.map((prod) => prod.id_product)
    : []

  return productIDList
}

const trackViewCartEvent = (cart) => {
  const productList = getProductList(cart)
  const productIds = getProductIds(cart)

  const currency = getISOCurrencyCodeWithShopID(
    StateStorage.getShop(),
  ).toUpperCase()

  Tracking.trackEvent(Tracking.EVENT_NAME_VIEW_CART, {
    'cart-product-ids': productIds,
    'criteo-view-cart-or-purchase-usd': productList.map((product) => ({
      ...product,
      currency,
    })),
  })
}

const injectAppAlert = () => {
  if (typeof document !== 'undefined') {
    const banners = document.querySelectorAll('.banner-header-top')

    if (banners?.length) {
      document.querySelector('header').classList.add('with-app-alert')
    } else {
      document.querySelector('header').classList.remove('with-app-alert')
    }
  }
}

const focusSearchBox = () => {
  const searchForm = document.getElementById(HEADER_SEARCH_FORM_ID)
  const searchInput = searchForm?.querySelector('.pml-input__input')

  if (searchInput) {
    searchInput.focus()
  }
}

const segmentSearchIconClick = (pageName) => {
  if (pageName) {
    segmentTrackAction(EVENT.searchIconClicked, { ref_type: pageName })
  }
}

export {
  DesktopMenu,
  DynamicFreeGift,
  DynamicCartNoticeModal,
  DynamicHeaderUser,
  focusSearchBox,
  injectAppAlert,
  MobileHamburgerMenu,
  segmentSearchIconClick,
  trackViewCartEvent,
  updateHeaderDesktopStyle,
}
