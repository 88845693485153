import { getAssetPath } from 'lib/utils/common/commonUtils'
import is3DSSuccess from 'components/checkout/omise-3ds/const'

const ICON_PREFIX = `${getAssetPath()}/static/img/icons/`

const paymentPendingIcon = `${ICON_PREFIX}checkout/paymentPending.svg`

export const paymentPendingSubtitle = {
  key: 'PROCESSING_PAYMENT_DESC',
  value: 'The payment is being processed, please wait... %timecount',
}

export const paymentPendingWarning = {
  key: 'PAYMENT_PENDING_WARNING',
  value: 'It took longer than we expected, please check in order history',
}

export const getNextStepCheckoutStatus = (data) =>
  is3DSSuccess(data)
    ? { step: 'CONFIRM', subStep: 'CONFIRMATION' }
    : { step: 'PAYMENT_RETRY', subStep: 'PAYMENT_RETRY' }

export default paymentPendingIcon
