import clsx from 'clsx'
import PropTypes from 'prop-types'
import React from 'react'
import ProductDiscountBadge from 'components/products/product-discount-badge'
import ProductImage from 'components/products/product-image'
import ProductLabel from 'components/products/product-label'
import ProductPrice from 'components/products/product-price'
import Typo from 'constants/typography'
import { withI18next } from 'lib/i18n/withI18next'

// TODO: refactor to product tile
const CartRecommendedProduct = ({
  idx,
  onClickAddToBag,
  onClickProductImg,
  product,
  t,
}) => {
  const {
    id_product,
    name,
    stock: { quantity, is_low_stock: isLowStock, is_sold_out: isSoldOut },
    is_back_in_stock: isBackInStock,
    prices,
    images,
  } = product
  const { reduction } = prices

  return (
    <div
      key={id_product}
      className={clsx('cart__recomm__product-item', {
        'first-item': idx === 0,
      })}
      id={`cart__recomm__product-item-${idx}`}
    >
      <div
        className="cart__recomm__product-img"
        onClick={onClickProductImg}
        onKeyUp={onClickProductImg}
        role="button"
        tabIndex={0}
      >
        <ProductImage
          coverImg={images.full_cover_image_path}
          hoverImg={
            images.full_hover_image_path ? images.full_hover_image_path : null
          }
          isFade={isSoldOut}
          phone="40vw"
          tablet="15vw"
          laptopAndUp="15vw"
        />
        <ProductDiscountBadge percentage={reduction?.value_formatted} />
        <ProductLabel
          isSoldOut={isSoldOut}
          isBackInStock={isBackInStock}
          isLowStock={isLowStock}
          quantity={quantity}
        />
      </div>
      <span className={clsx('cart__recomm__product-name', Typo.caption)}>
        {name}
      </span>
      <div className="cart__recomm__product-price">
        <ProductPrice
          price={prices?.original_price_formatted}
          discountedPrice={prices?.discounted_price_formatted}
        />
      </div>
      <button
        className={clsx('cart__recomm__add-to-bag', Typo.button)}
        onClick={onClickAddToBag}
      >
        {t('Add to Bag')}
      </button>
    </div>
  )
}

CartRecommendedProduct.propTypes = {
  idx: PropTypes.number.isRequired,
  onClickAddToBag: PropTypes.func.isRequired,
  onClickProductImg: PropTypes.func.isRequired,
  product: PropTypes.shape({
    id_product: PropTypes.number,
    images: PropTypes.shape({
      full_cover_image_path: PropTypes.string.isRequired,
      full_hover_image_path: PropTypes.string,
    }).isRequired,
    is_back_in_stock: PropTypes.bool.isRequired,
    name: PropTypes.string.isRequired,
    prices: PropTypes.shape({
      discounted_price_formatted: PropTypes.string,
      original_price_formatted: PropTypes.string.isRequired,
      reduction: PropTypes.shape({
        value_formatted: PropTypes.string.isRequired,
      }),
    }).isRequired,
    stock: PropTypes.shape({
      is_low_stock: PropTypes.bool.isRequired,
      is_sold_out: PropTypes.bool.isRequired,
      quantity: PropTypes.number,
    }).isRequired,
  }).isRequired,
  t: PropTypes.func.isRequired,
}

export default withI18next()(CartRecommendedProduct)
