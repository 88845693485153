import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import Typo from 'constants/typography'
import { withI18next } from 'lib/i18n/withI18next'
import FreshChatCTA from 'components/pdp/interactions/fresh-chat-cta'
import HeaderUspItem from '../header-usp-item'
import styles from './style.scss'

const HeaderUspDesktop = ({
  activeType,
  classNameFromLayout,
  uspList,
  toggleUspTooltip,
  hideUspTooltip,
  t,
}) => {
  const classNameDesktop = clsx(
    'usp-container',
    'usp-container-desktop',
    classNameFromLayout,
  )

  return (
    <div className={classNameDesktop}>
      <style jsx>{styles}</style>
      <ul>
        {uspList.map((type) => {
          const classNamePerType = clsx('relative', 'mobile-slide-item', {
            active: activeType === type ? 'active' : '',
          })
          return (
            <li
              key={`desktop-${type}`}
              className={classNamePerType}
              onMouseMove={(e) => e.stopPropagation()}
              onMouseEnter={() => toggleUspTooltip(type)}
              onMouseLeave={() => hideUspTooltip()}
            >
              <HeaderUspItem type={type} activeType={activeType} />
            </li>
          )
        })}
        <li key="desktop-message-us">
          <FreshChatCTA />
        </li>
      </ul>
      <span className={clsx(Typo.caption, 'usp-header-text')}>
        {t('Fashion born in Asia. On trend. Online. On the go.')}
      </span>
    </div>
  )
}

HeaderUspDesktop.defaultProps = { classNameFromLayout: '' }

HeaderUspDesktop.propTypes = {
  classNameFromLayout: PropTypes.string,
  uspList: PropTypes.arrayOf(PropTypes.string).isRequired,
  toggleUspTooltip: PropTypes.func.isRequired,
  hideUspTooltip: PropTypes.func.isRequired,
  activeType: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
}

export default withI18next()(HeaderUspDesktop)
