import React from 'react'
import PropTypes from 'prop-types'
import useBadgeElement from './BadgeHook'
import styles from './style.scss'
import { BADGE_HORIZONTAL, BADGE_VERTICAL, BADGE_COLOR } from './const'

/**
 * Small numerical value or status descriptor for UI elements.
 * @param {Node} param0.children the badge will be added relative to this node
 * @param {Node} param0.content the content rendered within the badge
 * @param {string} param0.className customize style of component
 * @param {string} param0.contentClassName customize style of content's component
 * @param {number} param0.max max count to show
 * @param {boolean} param0.showZero whether to show badge when `content` is zero
 * @param {object} param0.alignX use `BADGE_HORIZONTAL` to set position as `left` `right`
 * @param {object} param0.alignY use `BADGE_VERTICAL` to set position as `top` `bottom`
 * @param {string} param0.color use `BADGE_COLOR` to set as `primary` `secondary`
 * @param {boolean} param0.dot whether to display as a dot instead of text
 * @param {string} param0.cy for Cypress
 */
const Badge = ({
  children,
  content,
  className,
  contentClassName,
  max,
  showZero,
  alignX,
  alignY,
  color,
  dot,
  cy,
  smallCircle,
  hideAndShowAnimation,
}) => {
  const { classes, badgeClass, displayContent } = useBadgeElement({
    children,
    className,
    contentClassName,
    content,
    max,
    showZero,
    alignX,
    alignY,
    color,
    dot,
    smallCircle,
    hideAndShowAnimation,
  })

  return (
    <div className={classes} data-cy={cy}>
      <style jsx>{styles}</style>
      {children}
      <span className={badgeClass}>{displayContent}</span>
    </div>
  )
}

Badge.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  contentClassName: PropTypes.string,
  max: PropTypes.number,
  showZero: PropTypes.bool,
  alignX: PropTypes.oneOf(Object.values(BADGE_HORIZONTAL)),
  alignY: PropTypes.oneOf(Object.values(BADGE_VERTICAL)),
  color: PropTypes.oneOf(Object.values(BADGE_COLOR)),
  dot: PropTypes.bool,
  cy: PropTypes.string,
  smallCircle: PropTypes.bool,
  hideAndShowAnimation: PropTypes.bool,
}

Badge.defaultProps = {
  children: undefined,
  className: undefined,
  content: undefined,
  contentClassName: undefined,
  max: undefined,
  showZero: false,
  alignX: BADGE_HORIZONTAL.right,
  alignY: BADGE_VERTICAL.top,
  color: BADGE_COLOR.primary,
  dot: false,
  cy: undefined,
  smallCircle: false,
  hideAndShowAnimation: false,
}

export default Badge
