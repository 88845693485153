import PropTypes from 'prop-types'
import styles from './styles.scss'
import CartProductPrice from '../cart-product-price/index'
import CartProductName from '../cart-product-name/index'

const CartProductInfo = ({
  fromSummary,
  isFreeGift,
  isSoldOut,
  openFreeGift,
  onLinkClicked,
  product,
  selectedAttribute,
}) => (
  <div className="cart-item-info__wrapper">
    <style jsx>{styles}</style>
    <CartProductName
      product={product}
      isFreeGift={isFreeGift}
      isSoldOut={isSoldOut}
      openFreeGift={openFreeGift}
      onLinkClicked={onLinkClicked}
    />
    <CartProductPrice
      product={product}
      selectedAttribute={selectedAttribute}
      isFreeGift={isFreeGift}
      isSoldOut={isSoldOut}
      fromSummary={fromSummary}
    />
  </div>
)

CartProductInfo.defaultProps = {
  fromSummary: false,
}

CartProductInfo.propTypes = {
  fromSummary: PropTypes.bool,
  isFreeGift: PropTypes.bool.isRequired,
  isSoldOut: PropTypes.bool.isRequired,
  onLinkClicked: PropTypes.func.isRequired,
  openFreeGift: PropTypes.func.isRequired,
  product: PropTypes.shape({
    id_product: PropTypes.number,
    name: PropTypes.string,
    type: PropTypes.string,
    prices: PropTypes.shape({
      original_price: PropTypes.number,
      original_price_formatted: PropTypes.string,
      discounted_price_formatted: PropTypes.string,
      discounted_price: PropTypes.number,
    }),
    is_available: PropTypes.bool,
    is_sale: PropTypes.bool,
    quantity: PropTypes.number,
  }).isRequired,
  selectedAttribute: PropTypes.shape({
    stock: PropTypes.shape({
      quantity: PropTypes.number,
      is_low_quantity: PropTypes.bool.isRequired,
      is_sold_out: PropTypes.bool.isRequired,
    }),
  }).isRequired,
}

export default CartProductInfo
