import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import {
  EXPAND_BUTTON_TYPES,
  EXPAND_BUTTON_TYPE_CLASSES,
} from 'constants/button'
import Button from '../index'
import styles from './style.scss'

/**
 *
 * @param {string} param0.type use `EXPAND_BUTTON_TYPES` can be set to `floatingText` `floatingIcon` `floatingAction`
 * @param {string} param0.className customize style of component
 * @param {boolean} param0.disabled disabled state of button
 * @param {string} param0.cy for Cypress
 * @param {string} param0.htmlType set the original html type see https://developer.mozilla.org/en-US/docs/Web/HTML/Element/button#attr-type
 * @param {object} param0.style customize inline style of component
 * @param {function} param0.onClick Set the handler to handle `click` event
 * @param {boolean} param0.link can be set to link button
 */
const ExpandButton = ({
  children,
  type,
  className,
  disabled,
  cy,
  htmlType,
  style,
  onClick,
  isAutoExpanded,
  isExpanded,
  link,
}) => {
  const expandedButton = clsx(
    'pomelo-expand-btn',
    EXPAND_BUTTON_TYPE_CLASSES[type],
    className,
    {
      expanded: isExpanded,
    },
  )
  const classes = isAutoExpanded ? 'pomelo-auto-expand-btn' : expandedButton

  return (
    !!children && (
      <Button
        className={classes}
        disabled={disabled}
        data-cy={cy}
        htmlType={htmlType}
        style={style}
        link={link}
        onClick={onClick}
      >
        <style jsx>{styles}</style>
        {children}
      </Button>
    )
  )
}

ExpandButton.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  type: PropTypes.oneOf(Object.values(EXPAND_BUTTON_TYPES)),
  className: PropTypes.string,
  disabled: PropTypes.bool,
  cy: PropTypes.string,
  htmlType: PropTypes.string,
  style: PropTypes.shape({}),
  onClick: PropTypes.func,
  isAutoExpanded: PropTypes.bool,
  isExpanded: PropTypes.bool,
  link: PropTypes.bool,
}

ExpandButton.defaultProps = {
  className: '',
  type: undefined,
  disabled: false,
  cy: undefined,
  htmlType: '',
  style: {},
  onClick: undefined,
  isAutoExpanded: false,
  isExpanded: false,
  link: false,
}

export default ExpandButton
