import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { withI18next } from 'lib/i18n/withI18next'
import { hasLocizeTranslation } from 'lib/utils/locize'
import StateStorage from 'lib/state-storage'
import Button from 'components/common/button'
import { BUTTON_TYPES } from 'constants/button'
import BackdropModal from 'components/portal/backdrop-modal'
import Typo from 'constants/typography'
import { segmentCookieNoticeResponded } from 'lib/segment'
import {
  arrangeContent,
  COOKIE_ACCEPT_ALL_BUTTON,
  COOKIE_CUSTOMIZE_BUTTON,
  PRIVACY_SETTING,
  PRIVACY_SETTING_CONTENT,
  PRIVACY_SETTING_LIST,
} from 'components/alert/alert-cookie/const'
import CookieSettingList from './setting-list'
import styles from './styles.scss'

const trackSegmentCookieResponded = (
  isToggle,
  setIsShowSetting,
  oldCookieConsent,
) => {
  const options = {
    is_performance_allowed: isToggle[1],
    is_marketing_allowed: isToggle[2],
  }
  StateStorage.saveBannerStatus('PDPACookieConsent', options)
  segmentCookieNoticeResponded(options, oldCookieConsent)
  setIsShowSetting(false)
  window.location.reload()
}

const CookieSetting = ({ t, oldCookieConsent }) => {
  const [isShowSetting, setIsShowSetting] = useState(true)
  const [isToggle, setIsToggle] = useState([true, false, false])
  const PrivacyContent = arrangeContent(t, PRIVACY_SETTING_CONTENT, 'cta-link')

  useEffect(() => {
    const oldCookiesSetting = JSON.parse(oldCookieConsent)

    if (oldCookiesSetting) {
      const { is_marketing_allowed, is_performance_allowed } = oldCookiesSetting

      setIsToggle([true, is_performance_allowed, is_marketing_allowed])
    }
  }, [oldCookieConsent])

  const toggleAllSetting = () => {
    setIsToggle([true, true, true])
  }

  const toggleSetting = useCallback(
    (idx) => {
      const newState = [...isToggle]
      newState[idx] = !isToggle[idx]
      setIsToggle([true, newState[1], newState[2]])
    },
    [isToggle],
  )

  return (
    <BackdropModal isOpen={isShowSetting}>
      <style jsx>{styles}</style>
      <div className="setting-dialog__container">
        <span className={clsx('setting-dialog__header', Typo.title3)}>
          {hasLocizeTranslation(t, PRIVACY_SETTING.key, PRIVACY_SETTING.text)}
        </span>
        <div className="setting-dialog__content">
          <div className={Typo.body2}>
            <div
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: PrivacyContent,
              }}
            />
          </div>
          <Button
            className="accept-all-button-wrapper"
            type={BUTTON_TYPES.primary}
            onClick={toggleAllSetting}
          >
            {hasLocizeTranslation(
              t,
              COOKIE_ACCEPT_ALL_BUTTON.key,
              COOKIE_ACCEPT_ALL_BUTTON.text,
            )}
          </Button>
          <div className={clsx('setting-dialog__sub-header', Typo.title3)}>
            {hasLocizeTranslation(
              t,
              COOKIE_CUSTOMIZE_BUTTON.key,
              COOKIE_CUSTOMIZE_BUTTON.text,
            )}
          </div>
          {PRIVACY_SETTING_LIST.map((list, idx) => (
            <CookieSettingList
              key={list.header.text}
              list={list}
              idx={idx}
              checked={isToggle[idx]}
              toggleSetting={() => toggleSetting(idx)}
            />
          ))}
          <div className="save-button-wrapper">
            <Button
              className="save-button"
              type={BUTTON_TYPES.primary}
              onClick={() =>
                trackSegmentCookieResponded(
                  isToggle,
                  setIsShowSetting,
                  oldCookieConsent,
                )
              }
            >
              {t('Save')}
            </Button>
          </div>
        </div>
      </div>
    </BackdropModal>
  )
}

CookieSetting.defaultProps = {
  oldCookieConsent: null,
}

CookieSetting.propTypes = {
  t: PropTypes.func.isRequired,
  oldCookieConsent: PropTypes.string,
}

export default withI18next()(CookieSetting)
