import PropTypes from 'prop-types'
import { getCartProductUrl } from 'components/cart/utils'
import Button from 'components/common/button'
import ResponsiveImage from 'components/images/responsive-image'
import { BUTTON_TYPES } from 'constants/button'

const CartProductImage = ({
  product,
  isSoldOut,
  isFreeGift,
  openFreeGift,
  onLinkClicked,
  imageLinkClass,
}) => {
  const imageJsx = (
    <ResponsiveImage
      alt={product.name}
      imgRatio={1.33}
      src={product.image.full_path}
    />
  )

  if (isSoldOut) {
    return <div className={imageLinkClass}>{imageJsx}</div>
  }

  if (isFreeGift) {
    return (
      <Button
        type={BUTTON_TYPES.text}
        className={imageLinkClass}
        onClick={openFreeGift}
      >
        {imageJsx}
      </Button>
    )
  }

  return (
    <a
      className={imageLinkClass}
      href={getCartProductUrl(product)}
      onClick={onLinkClicked}
    >
      {imageJsx}
    </a>
  )
}

CartProductImage.propTypes = {
  product: PropTypes.shape({
    name: PropTypes.string,
    is_available: PropTypes.bool,
    image: PropTypes.shape({
      path: PropTypes.string,
      full_path: PropTypes.string,
    }),
  }).isRequired,
  isSoldOut: PropTypes.bool.isRequired,
  isFreeGift: PropTypes.bool.isRequired,
  openFreeGift: PropTypes.func.isRequired,
  onLinkClicked: PropTypes.func.isRequired,
  imageLinkClass: PropTypes.string.isRequired,
}

export default CartProductImage
