import { SHOP_LANG_CURRENCY } from 'constants/shop-language'

class ShopLanguageMap {
  static SHOP_MAP = [
    {
      shopSlug: 'global',
      shopId: 4,
      languageId: 4,
      currencyId: 5,
      countryId: 21,
    },
    {
      shopSlug: 'us',
      shopId: 4,
      languageId: 1,
      currencyId: 5,
      countryId: 21,
    },
    {
      shopSlug: 'au',
      shopId: 10,
      languageId: 1,
      currencyId: 6,
      countryId: 24,
    },
    {
      shopSlug: 'sg',
      shopId: 2,
      languageId: 1,
      currencyId: 3,
      countryId: 25,
    },
    {
      shopSlug: 'th',
      shopId: 1,
      languageId: 2,
      currencyId: 1,
      countryId: 206,
    },
    {
      shopSlug: 'id',
      shopId: 5,
      languageId: 5,
      currencyId: 7,
      countryId: 111,
    },
    {
      shopSlug: 'my',
      shopId: 11,
      languageId: 1,
      currencyId: 10,
      countryId: 136,
    },
    {
      shopSlug: 'hk',
      shopId: 12,
      languageId: 12,
      currencyId: 9,
      countryId: 22,
    },
    {
      shopSlug: 'mo',
      shopId: 12,
      languageId: 12,
      currencyId: 9,
      countryId: 132,
    },
    {
      shopSlug: 'ph',
      shopId: 14,
      languageId: 13,
      currencyId: 11,
      countryId: 172,
    },
    {
      shopSlug: 'kh',
      shopId: 15,
      languageId: 15,
      currencyId: 5,
      countryId: 63,
    },
  ]

  static DEFAULT_STORE = this.SHOP_MAP[0]

  static LOCALE_MAP = [
    {
      slug: 'en',
      id: 1,
    },
    {
      slug: 'th',
      id: 2,
    },
    {
      slug: 'sg',
      id: 3,
    },
    {
      slug: 'id',
      id: 5,
    },
    {
      slug: 'au',
      id: 10,
    },
    {
      slug: 'my',
      id: 11,
    },
    {
      slug: 'hk',
      id: 12,
    },
    {
      slug: 'ph',
      id: 13,
    },
    {
      slug: 'kh',
      id: 15,
    },
  ]

  static DEFAULT_LOCALE = this.LOCALE_MAP[0]

  static getLocaleById = (id) => {
    const locale = ShopLanguageMap.LOCALE_MAP.find(
      (localeItem) => localeItem.id === id,
    )

    return locale || ShopLanguageMap.DEFAULT_LOCALE
  }

  static getLocaleBySlug = (slug, zone = null) => {
    const target = zone && (zone === 'id' || zone === 'my') ? zone : slug
    const locale = ShopLanguageMap.LOCALE_MAP.find(
      (localeItem) => localeItem.slug === target,
    )

    return locale || ShopLanguageMap.DEFAULT_LOCALE
  }

  static getShopById = (id) => {
    const shop = ShopLanguageMap.SHOP_MAP.find(({ shopId }) => shopId === id)

    if (shop) {
      return shop
    }

    return ShopLanguageMap.DEFAULT_STORE
  }

  static getShopBySlug = (slug) => {
    const shop = ShopLanguageMap.SHOP_MAP.find(
      ({ shopSlug }) => shopSlug === slug,
    )

    if (shop) {
      return shop
    }

    return ShopLanguageMap.DEFAULT_STORE
  }

  /**
   * shop, lang, currency, country ID
   * https://pomelofashion.atlassian.net/wiki/spaces/API/pages/741769714/Country+Shop+Language+Currency+Validation
   */
  static getIdLangBySlugAndLang = (slug, lang) => {
    const shop = SHOP_LANG_CURRENCY[slug]
    if (shop) {
      return shop.id_lang[lang]
    }

    // return default global shop / id_lang: 4
    return SHOP_LANG_CURRENCY.global.id_lang.en
  }

  static getIdShopBySlug = (slug) => {
    const shop = SHOP_LANG_CURRENCY[slug]
    if (shop) {
      return shop.id_shop
    }

    // return default global shop / id_shop: 4
    return SHOP_LANG_CURRENCY.global.id_shop
  }

  static getDefaultLangByShopSlug = (shopSlug) => {
    const shop = SHOP_LANG_CURRENCY[shopSlug]
    if (shop) {
      return shop.default_lang
    }

    // return default global shop / { id_lang: 4, lang_slug: 'en' }
    return SHOP_LANG_CURRENCY.global.default_lang
  }

  static getISOCodeBySlugAndLang = (slug, lang) => {
    const shop = SHOP_LANG_CURRENCY[slug]
    if (shop) {
      return shop.iso_code[lang]
    }

    // return default global shop / iso_code: 4
    return SHOP_LANG_CURRENCY.global.iso_code.en
  }

  static getCurrencyISOCodeByShopSlug = (shopSlug) => {
    const shop = SHOP_LANG_CURRENCY[shopSlug]
    if (shop) {
      return shop.currency_iso_code
    }

    // return default global shop / currency_iso_code: USD
    return SHOP_LANG_CURRENCY.global.currency_iso_code
  }

  static getSlugFromLangId(langId) {
    switch (langId) {
      case 2:
        return 'th'
      case 5:
        return 'id'
      default:
        return 'en'
    }
  }
}

export default ShopLanguageMap
