import { Fragment } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import TextField from 'components/common/textfield'
import { withI18next } from 'lib/i18n/withI18next'
import { BUTTON_TYPES } from 'constants/button'
import Typo from 'constants/typography'
import Icon from 'components/common/icon'
import ICONS, { ICON_SIZE } from 'components/common/icon/const'
import Button from '../button'
import { useTextFieldPassword } from './useTextFieldPassword'
import styles from './style.scss'

const TextFieldPassword = ({
  id,
  fieldId,
  error,
  helperText,
  inputRef,
  passwordFormat,
  name,
  onChange,
  onFocus,
  applyPasswordPolicy,
  label,
  t,
}) => {
  const {
    isShowPassword,
    isShowPasswordToggler,
    type,
    handleClick,
    handlePasswordRef,
    handleChange,
    getProgress,
    passwordPolicyLabel,
  } = useTextFieldPassword({
    inputRef,
    onChange,
    error,
    passwordFormat,
    t,
  })

  const passwordPolicy = [
    {
      item: [
        {
          text: passwordPolicyLabel?.validLength,
          condition: passwordFormat?.isValidLength,
          key: 'validLength',
        },
        {
          text: passwordPolicyLabel?.number,
          condition: passwordFormat?.isNumber,
          key: 'number',
        },
      ],
      key: 'col1',
    },
    {
      item: [
        {
          text: passwordPolicyLabel?.upperCase,
          condition: passwordFormat?.isUpperCase,
          key: 'upperCase',
        },
        {
          text: passwordPolicyLabel?.lowerCase,
          condition: passwordFormat?.isLowerCase,
          key: 'lowerCase',
        },
      ],
      key: 'col2',
    },
  ]

  const getCheckIcon = (item) =>
    item.map(({ condition, key }) => {
      let icon = <div className="uncheck" />
      if (condition)
        icon = (
          <Icon
            src={ICONS.check}
            size={ICON_SIZE.small}
            key={key}
            data-cy={key}
          />
        )
      return (
        <span className="icon-wrapper" key={key}>
          {icon}
        </span>
      )
    })

  const setToFocus = () => {
    if (onFocus) {
      onFocus()
    }
  }

  const getLabelClass = (condition) => {
    const isError = error && !condition
    let labelClass = ''
    if (condition) {
      labelClass = ' _success'
    } else if (isError) {
      labelClass = ' _error'
    }
    return labelClass
  }

  const getPolicyLabel = (item) =>
    item.map(({ condition, text }) => (
      <span className={clsx(Typo.caption, getLabelClass(condition))} key={text}>
        {text}
      </span>
    ))

  const getPasswordPolicy = () =>
    passwordPolicy.map(({ item, key }) => (
      <div className="password-column" key={key}>
        <div className="password-row">{getCheckIcon(item)}</div>
        <div className="password-row">{getPolicyLabel(item)}</div>
      </div>
    ))

  return (
    <Fragment>
      <style jsx>{styles}</style>
      <TextField
        error={error}
        fullWidth
        fieldId={fieldId}
        helperText={helperText}
        inputRef={handlePasswordRef}
        label={label || t('Password')}
        name={name}
        onFocus={setToFocus}
        onChange={handleChange}
        postfix={
          isShowPasswordToggler ? (
            <Button
              onClick={handleClick}
              type={BUTTON_TYPES.link}
              cy={`${fieldId}__${isShowPassword ? 'hide_btn' : 'show_btn'}`}
            >
              <span className={Typo.overline}>
                {t(isShowPassword ? 'HIDE' : 'SHOW')}
              </span>
            </Button>
          ) : undefined
        }
        type={type}
        htmlFor={name}
        inputProps={{
          id,
          maxLength: 26,
        }}
      />
      {applyPasswordPolicy ? (
        <section className={clsx('pml-form-row', 'password-policy', { error })}>
          <div
            className="password-strength"
            data-cy={`${fieldId}__pass_strength`}
          >
            <div
              data-cy="strength-bar"
              className={clsx('strength-bar', { error })}
              style={{ transform: `translateX(-${getProgress()}%)` }}
            />
          </div>
          <div
            className="password-condition"
            data-cy={`${fieldId}__pass_condition`}
          >
            {getPasswordPolicy()}
          </div>
        </section>
      ) : (
        <div className="password-policy hide" />
      )}
    </Fragment>
  )
}

TextFieldPassword.defaultProps = {
  id: 'password',
  fieldId: undefined,
  error: undefined,
  helperText: undefined,
  inputRef: undefined,
  passwordFormat: undefined,
  passwordPolicyLabel: undefined,
  name: undefined,
  onChange: undefined,
  onFocus: undefined,
  applyPasswordPolicy: undefined,
  label: undefined,
  t: undefined,
}

TextFieldPassword.propTypes = {
  id: PropTypes.string,
  fieldId: PropTypes.string,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  inputRef: PropTypes.oneOfType([
    PropTypes.shape({
      current: PropTypes.shape({}),
    }),
    PropTypes.func,
  ]),
  passwordFormat: PropTypes.shape({
    isValidLength: PropTypes.bool,
    isNumber: PropTypes.bool,
    isUpperCase: PropTypes.bool,
    isLowerCase: PropTypes.bool,
  }),
  passwordPolicyLabel: PropTypes.shape({
    validLength: PropTypes.string,
    number: PropTypes.string,
    upperCase: PropTypes.string,
    lowerCase: PropTypes.string,
  }),
  name: PropTypes.string,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  applyPasswordPolicy: PropTypes.bool,
  label: PropTypes.string,
  t: PropTypes.func,
}

export default withI18next()(TextFieldPassword)
