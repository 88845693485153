import env from 'config/env'

export const DEF_HEAD_META_DATA = {
  title:
    'POMELO Fashion | Get all the latest fashion, updated twice every week! - Pomelo',
  description:
    'Shop the latest fashion at POMELO fashion. Designed and manufactured in house for quality and style. Dresses, tops, knits, jackets, denim, accessories and more!',
  image: `${env.IMG_HOST}img/pomelo-logo-square.jpg`,
  deeplink: 'pomelofashion://deeplink',
  page: 'home',
  id: '0',
}

export default { DEF_HEAD_META_DATA }
