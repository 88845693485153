import Color from './color'
import Solver from './solver'

export const hexToRgb = (hex) => {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i
  const sanitizeHex = hex.replace(
    shorthandRegex,
    (_m, r, g, b) => r + r + g + g + b + b,
  )

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(sanitizeHex)
  return result
    ? [
        parseInt(result[1], 16),
        parseInt(result[2], 16),
        parseInt(result[3], 16),
      ]
    : null
}

export const getColorFilter = (rgb) => {
  if (!rgb) {
    return undefined
  }

  const [r, g, b] = hexToRgb(rgb)
  const color = new Color(r, g, b)
  const solver = new Solver(color)

  return solver.solve()
}

export default { hexToRgb, getColorFilter }
