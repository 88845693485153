function addExternalScript(src, id = '', onloadFn = undefined) {
  const addScript = document.createElement('script')

  if (onloadFn) {
    addScript.onload = onloadFn
  }

  addScript.id = id
  addScript.setAttribute('src', src)
  addScript.setAttribute('async', true)
  document.body.appendChild(addScript)
  return addScript
}

export default addExternalScript
