import React, { useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import useModal from 'components/portal/useModal'
import BackdropModal from 'components/portal/backdrop-modal'
import CreditInformation from 'components/shared/credit-information'

// Wrapper for class based components
const CreditInformationWrapper = ({
  isCheckoutFlowText,
  isPhone,
  isShow,
  setIsShow,
  showMoreDetails,
  zIndex,
}) => {
  const [isModalOpen, setIsModalOpen] = useModal(false)

  useEffect(() => {
    setIsModalOpen(isShow)
  }, [isShow, setIsModalOpen])

  const closeModal = useCallback(() => setIsShow(false), [setIsShow])

  return (
    <BackdropModal
      onBackdropClick={closeModal}
      freezeBody={isPhone}
      isOpen={isModalOpen}
      zIndex={zIndex}
    >
      <CreditInformation
        isCheckoutFlowText={isCheckoutFlowText}
        onClose={closeModal}
        showMoreDetails={showMoreDetails}
      />
    </BackdropModal>
  )
}

CreditInformationWrapper.defaultProps = {
  isCheckoutFlowText: false,
  isPhone: false,
  isShow: false,
  showMoreDetails: false,
  zIndex: 10,
}

CreditInformationWrapper.propTypes = {
  isCheckoutFlowText: PropTypes.bool,
  isPhone: PropTypes.bool,
  isShow: PropTypes.bool,
  setIsShow: PropTypes.func.isRequired,
  showMoreDetails: PropTypes.bool,
  zIndex: PropTypes.number,
}

export default CreditInformationWrapper
