import PropTypes from 'prop-types'
import React from 'react'
import initReactFastclick from 'react-fastclick'
import dayjs from 'dayjs'
import { Offline } from 'react-detect-offline'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import dynamic from 'next/dynamic'
import { withI18next } from 'lib/i18n/withI18next'
import SkinnyBanners from 'components/skinny-banner'
import Connecting from 'components/network/offline'
import CustomHead from 'components/custom-head'
import Footer from 'components/footer'
import Header from 'components/header'
import HeaderUsp from 'components/usp/header-usp'
import StateStorage from 'lib/state-storage'
import authDuck from 'components/auth/duck'
import cartDuck from 'components/cart/duck'
import { segmentAuthentication } from 'lib/segment'
import FallBackModal, { FALLBACK_TYPE } from 'components/portal/fall-back-modal'
import {
  identifyLoggedInUser,
  finalizeAuthFireBase,
  getCurrentUserFromFirebase,
  getCustomerData,
  getRedirectResult,
  initializeGuestMode,
  renewToken,
} from 'lib/auth'
import {
  checkResetAuth,
  getIsPageScrollable,
} from 'lib/utils/common/commonUtils'
import AlertCookie from 'components/alert/alert-cookie'

const AlertCredits = dynamic(() => import('components/alert/alert-credits'), {
  ssr: false,
})

initReactFastclick()

class CommonLayout extends React.Component {
  state = {
    isError: false,
  }

  async componentDidMount() {
    await this.initializeAuthentication()
  }

  async initializeAuthentication() {
    const { isPhone, setGuestMode, setCustomerData } = this.props

    if (checkResetAuth()) {
      return
    }

    try {
      let redirectResult
      const auth = StateStorage.getAuthCookies()

      if (isPhone) {
        redirectResult = await getRedirectResult()
      }

      if (redirectResult?.user) {
        const { additionalUserInfo: info } = redirectResult

        // get First Name and Last Name from Facebook and Google respectively
        const data = {
          firstName:
            info?.profile?.first_name ||
            info?.profile?.given_name ||
            'No Firstname',
          lastName:
            info?.profile?.last_name ||
            info?.profile?.family_name ||
            'No Lastname',
        }

        const authData = await finalizeAuthFireBase(redirectResult, data, true)

        if (authData.customer) {
          setCustomerData({ ...authData.customer, isFetched: true })
        }

        await this.updateAuthStateFireBase(
          redirectResult.user,
          authData.access_token,
        )
        segmentAuthentication(info.providerId, info.isNewUser)
        return
      }

      const userFirebase = await getCurrentUserFromFirebase()

      if (userFirebase && !userFirebase.isAnonymous && auth?.token) {
        await renewToken()
        await this.updateAuthStateFireBase(userFirebase)
      } else {
        await initializeGuestMode()
        setGuestMode()
        setCustomerData({ isFetched: true })
      }
    } catch (err) {
      this.setState({ isError: true })
    }
  }

  async updateAuthStateFireBase(userProvider, redirectedToken) {
    if (!userProvider) return

    const {
      customerData,
      setCustomerData,
      setUser,
      setToken,
      setProviderInfo,
    } = this.props

    let data = {}
    let lastSignInTime

    if (!customerData?.isFetched) {
      try {
        data = await getCustomerData()
      } catch (e) {
        // Continue
      }
    } else {
      data = customerData
    }

    const { id_customer, firstname, lastname, email, customer_groups } = data

    if (userProvider?.metadata) {
      lastSignInTime = dayjs(userProvider.metadata.lastSignInTime).format(
        'YYYY-MM-DD HH:mm:ss',
      )
    } else {
      lastSignInTime = dayjs().format('YYYY-MM-DD HH:mm:ss')
    }

    const token = redirectedToken || StateStorage.getAuthToken()
    const authState = {
      lastSignInTime,
      token,
      user: {
        customer_groups,
        id_customer,
        firstname,
        lastname,
        email: email || '',
      },
      refreshToken: userProvider?.refreshToken,
    }
    StateStorage.saveAuthState(authState)

    setUser(data)
    setToken(token)
    setProviderInfo(userProvider)

    if (redirectedToken) {
      identifyLoggedInUser()
    }

    setCustomerData({ ...data, isFetched: true })
  }

  getAvailableStoreCredits() {
    const { cart } = this.props

    return cart?.summary?.store_credits?.available || 0
  }

  getPageName() {
    const { pageName: initialPageName } = this.props
    return typeof document === 'undefined' ? undefined : initialPageName || ''
  }

  getPageId() {
    const { pageId } = this.props
    if (pageId) {
      return pageId
    }

    if (typeof document === 'undefined') {
      return undefined
    }

    return document.querySelector('body').getAttribute('rel') || 0
  }

  isStickyHeader = () => {
    const { isStickyHeader } = this.props
    if (isStickyHeader === undefined) {
      return getIsPageScrollable()
    }

    return isStickyHeader
  }

  render() {
    const {
      children,
      cleanedPath,
      customCleanedPath,
      customOriginPath,
      isCreditsAlertActive,
      isPhone,
      meta,
      noCountrySelector,
      noSidePadding,
      noFooterTopMargin,
      originPath,
      pageName,
      pathPrefix,
      showAdsBanner,
      showAlertCookiesBanner,
      showCreditsBanner,
      showFooter,
      showHeader,
      showSearch,
      showSkinnyBanner,
      showUsp,
      style,
      schema,
    } = this.props
    const { isError } = this.state
    const availableStoreCredits = this.getAvailableStoreCredits()
    const validPageName = this.getPageName()

    return (
      <React.Fragment>
        <CustomHead
          {...meta}
          cleanedPath={customCleanedPath || cleanedPath}
          originPath={customOriginPath || originPath}
          pathPrefix={pathPrefix}
          pageName={validPageName}
          pageId={this.getPageId()}
          schema={schema}
        />
        {showSkinnyBanner && <SkinnyBanners pageName={pageName} />}
        {showHeader && (
          <Header
            noCountrySelector={noCountrySelector}
            pageName={validPageName}
            showSearch={showSearch}
            isSticky={this.isStickyHeader()}
          />
        )}

        <main
          id="page-wrap"
          className={noSidePadding ? '' : 'container'}
          style={style}
        >
          {showUsp && (
            <HeaderUsp className={noSidePadding ? ' container' : ''} />
          )}
          {children}
        </main>
        {showFooter && (
          <Footer pageName={pageName} noFooterTopMargin={noFooterTopMargin} />
        )}
        {showAlertCookiesBanner && (
          <AlertCookie showAdsBanner={showAdsBanner} isPhone={isPhone} />
        )}
        {!isPhone &&
          showCreditsBanner &&
          isCreditsAlertActive &&
          availableStoreCredits > 0 && <AlertCredits />}
        <Offline>
          <Connecting errorCode="noInternet" />
        </Offline>
        {isError && <FallBackModal modalType={FALLBACK_TYPE.backOrReload} />}
      </React.Fragment>
    )
  }
}

CommonLayout.defaultProps = {
  applyStoreCredit: undefined,
  cart: null,
  children: {},
  customerData: null,
  customCleanedPath: '',
  customOriginPath: '',
  isAppAlertActive: false,
  isCreditsAlertActive: false,
  isStickyHeader: undefined,
  meta: {},
  noCountrySelector: false,
  noSidePadding: false,
  noFooterTopMargin: false,
  pageId: undefined,
  pageName: '',
  schema: '',
  showAdsBanner: false,
  showAlertCookiesBanner: true,
  showCountrySelector: true,
  showCreditsBanner: false,
  showFooter: true,
  showHeader: true,
  showSearch: true,
  showSignupBanner: false,
  showSkinnyBanner: true,
  showUsp: true,
  style: null,
  usedCredit: false,
  user: null,
}

CommonLayout.propTypes = {
  applyStoreCredit: PropTypes.bool,
  cart: PropTypes.shape({
    summary: PropTypes.shape({
      store_credits: PropTypes.shape({
        available: PropTypes.number,
      }),
    }),
  }),
  children: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.array]),
  cleanedPath: PropTypes.string.isRequired,
  customerData: PropTypes.shape({
    isFetched: PropTypes.bool.isRequired,
  }),
  customCleanedPath: PropTypes.string,
  customOriginPath: PropTypes.string,
  getCart: PropTypes.func.isRequired,
  internationalization: PropTypes.shape({
    shop: PropTypes.number,
  }).isRequired,
  isAppAlertActive: PropTypes.bool,
  isCreditsAlertActive: PropTypes.bool,
  isPhone: PropTypes.bool.isRequired,
  isStickyHeader: PropTypes.bool,
  meta: PropTypes.shape({
    deeplink: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.string,
    title: PropTypes.string,
  }),
  noCountrySelector: PropTypes.bool,
  noSidePadding: PropTypes.bool,
  noFooterTopMargin: PropTypes.bool,
  originPath: PropTypes.string.isRequired,
  pageId: PropTypes.number,
  pageName: PropTypes.string,
  pathPrefix: PropTypes.string.isRequired,
  setCustomerData: PropTypes.func.isRequired,
  setGuestMode: PropTypes.func.isRequired,
  setProviderInfo: PropTypes.func.isRequired,
  setToken: PropTypes.func.isRequired,
  setUser: PropTypes.func.isRequired,
  showAdsBanner: PropTypes.bool,
  showAlertCookiesBanner: PropTypes.bool,
  showCountrySelector: PropTypes.bool,
  showCreditsBanner: PropTypes.bool,
  showFooter: PropTypes.bool,
  showHeader: PropTypes.bool,
  showSearch: PropTypes.bool,
  showSignupBanner: PropTypes.bool,
  showSkinnyBanner: PropTypes.bool,
  showUsp: PropTypes.bool,
  style: PropTypes.shape({}),
  t: PropTypes.func.isRequired,
  user: PropTypes.shape({}),
  usedCredit: PropTypes.bool,
  schema: PropTypes.string,
}

const Extended = withI18next()(CommonLayout)

export { CommonLayout }

export default compose(
  connect(
    (state) => ({
      applyStoreCredit: state.cartData.isStoreCreditUsed,
      cart: state.cart.payload,
      cleanedPath: state.device.cleanedPath,
      customerData: state.auth.customerData,
      internationalization: state.internationalization,
      isAppAlertActive: state.alertApp.active,
      isCreditsAlertActive: state.alertCredits.active,
      isPhone: state.device.isPhone,
      originPath: state.device.originPath,
      pathPrefix: state.device.pathPrefix,
      user: state.auth.user,
      usedCredit: state.cartData.isStoreCreditUsed,
    }),
    (dispatch) =>
      bindActionCreators(
        {
          setCustomerData: authDuck.creators.setCustomerData,
          getCart: cartDuck.creators.get,
          setGuestMode: authDuck.creators.setGuestMode,
          setProviderInfo: authDuck.creators.setProviderInfo,
          setToken: authDuck.creators.setToken,
          setUser: authDuck.creators.setUser,
        },
        dispatch,
      ),
  ),
)(Extended)
