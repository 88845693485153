/**
 * @typedef {{
 *  is_default: boolean;
 *  lang_slug: string;
 *  id_lang: number;
 *  iso_code: string;
 *  locale: string;
 *  menu_name: string;
 *  menu_ordering: number;
 * }} Languages
 *
 * @typedef {{
 *  slug: string;
 *  id_shop: number;
 *  languages: Language[];
 *  id_currency: number;
 *  id_country: number;
 *  country_code: number;
 *  currency_iso_code: string;
 *  currency_symbol?: string;
 *  offline_available?: boolean;
 *  is_global_migrated?: boolean;
 *  is_enable_cash_on_delivery?: boolean;
 *  is_enable_pick_up?: boolean;
 *  is_free_return_able?: boolean;
 *  is_allow_loyalty?: boolean;
 *  is_cod_refunding_via_credit_only?: boolean;
 *  is_eligible_for_sign_up_promo?: boolean;
 *  is_allow_welcome_modal?: boolean;
 * }} ShopConfig
 *
 * @typedef {{
 *  [shopCode: string]: string
 * }} ShopCountryISOCode
 *
 * @typedef {{
 *  id_shop: number;
 *  country_code: string;
 *  id_country: number;
 * }} ShopCountryIDAndCode
 *
 * @typedef {{
 *  name: string;
 *  shop: string;
 *  lang: string;
 *  order: number;
 *  isUnderline: boolean;
 * }} ShopMenuConfig
 *
 * @typedef {{
 *  [shopKey: string]: {
 *    slug: string;
 *    id_shop: number;
 *    is_global_migrated?: boolean;
 *    id_lang: {
 *      [lang_slug: string]: number;
 *    };
 *    locales: {
 *     [lang_slug: string]: string;
 *    };
 *    default_lang: {
 *      id_lang: number;
 *      lang_slug: string;
 *    };
 *    id_currency: number;
 *    id_country: number;
 *    iso_code: {
 *      [lang.lang_slug]: string;
 *    };
 *    currency_iso_code: string;
 *  }
 * }} ShopLang
 *
 */

const { configs: shopConfigs } = require('../../../config/shop-language.json')

/**
 * @description Get available shop.
 * @param {ShopConfig[]} shops
 * @return {ShopConfig[]}
 */
function getAvailableShop(shops) {
  // Change the ENV file at key DISABLED_SHOPS when there're some shop need to disable
  // E.g. DISABLED_SHOPS=th,us
  const disableShopsRawString = process.env.DISABLED_SHOPS
  if (!disableShopsRawString) {
    return shops
  }
  const disabledShopList = disableShopsRawString.split(',')
  return shops.filter((shop) => !disabledShopList.includes(shop))
}

/**
 * @description Get the shop that has multi langauages.
 * @param {ShopConfig[]} shops
 * @return {ShopConfig[]}
 */
function getMultiLanguageShop(shops) {
  return shops.filter((config) => config.languages.length > 1)
}

/**
 * @description Get language's iso_code from each shop.
 * @param {ShopConfig[]} shops
 * @return {string[]}
 */
function getShopLanguageISOCode(shops) {
  const shopLanguageISOCode = shops.reduce((langs, shop) => {
    const { languages } = shop
    const shopLangSlugs = languages.map((language) => language.lang_slug)
    return [...langs, ...shopLangSlugs]
  }, [])
  return Array.from(new Set(shopLanguageISOCode))
}

/**
 * @description Get the shop that have offline store.
 * @param {ShopConfig[]} shops
 * @return {ShopConfig[]}
 */
function filterOfflineAvailableShop(shops) {
  return shops.filter((shop) => shop.offline_available)
}

/**
 * @description Check the shop that is offline available shop or not.
 * @param {ShopConfig[]} shops
 * @param {string} checkingShop
 * @return {boolean}
 */
function isOfflineAvailableShop(shops, checkingShop) {
  return shops.map((offlineShop) => offlineShop.slug).includes(checkingShop)
}

/**
 * @description Get Shop info
 * @param {object} parameters
 * @param {ShopConfig[]} parameters.config
 * @param {string} parameters.key
 * @param {any} parameters.value
 * @return {ShopConfig}
 */
function getShopInfo({ config = shopConfigs, key, value }) {
  return config.find((shop) => shop[key] === value) || {}
}

/**
 * @description Mapping shop config to ShopCountryIDAndCode format.
 * @param {ShopConfig[]} shops
 * @return {ShopCountryIDAndCode[]}
 */
function getShopCountryIDAndCode(shops) {
  return shops.map((config) => ({
    id_shop: config.id_shop,
    country_code: config.country_code,
    id_country: config.id_country,
  }))
}

/**
 * @description Get shop's country iso code.
 * @param {ShopConfig[]} shops
 * @return {ShopCountryISOCode}
 */
function getShopCountryISOCode(shops) {
  return shops.reduce(
    (countryCodes, config) => ({
      ...countryCodes,
      [config.slug.toUpperCase()]: config.slug,
    }),
    {},
  )
}

/**
 * @description Generate menu config from shop configs
 * @param {ShopConfig[]} shops
 * @return {ShopMenuConfig}
 */
function getMenuFromShops(shops) {
  return shops
    .reduce((menus, current) => {
      const { languages, slug } = current
      const currentLanguageMenu = languages.reduce(
        (currentMenus, lang) => [
          ...currentMenus,
          {
            name: lang.menu_name,
            shop: slug,
            lang: lang.lang_slug,
            order: lang.menu_ordering,
          },
        ],
        [],
      )
      return [...menus, ...currentLanguageMenu]
    }, [])
    .sort((a, b) => a.order - b.order)
}

/**
 * @description Get shop's locale list
 * @param {ShopConfig[]} shops
 * @return {string[]}
 */
function getShopLocales(shops) {
  const localeList = shops.reduce((prev, cur) => {
    if (cur.disabled) {
      return prev
    }
    const countryLocales = cur.languages.map((idLang) => idLang.locale)
    return [...prev, ...countryLocales]
  }, [])
  return Array.from(new Set(localeList))
}

/**
 * @deprecated Since 25th Feb 2021 -- Should use config directly instead of this.
 * @description Mapping shop config to ShopLang format
 * @param {ShopConfig[]} shops
 * @return {ShopLang}
 */
function getShopLangCurrency(shops) {
  return shops.reduce((prev, cur) => {
    const {
      slug,
      id_shop,
      languages,
      id_currency,
      id_country,
      currency_iso_code,
      is_global_migrated,
    } = cur
    const defaultLang =
      languages.find((lang) => lang.is_default) || languages[0]
    if (!defaultLang) {
      return prev
    }

    return {
      ...prev,
      [slug]: {
        slug,
        id_shop,
        is_global_migrated,
        id_lang: languages.reduce(
          (idLangs, lang) => ({
            ...idLangs,
            [lang.lang_slug]: lang.id_lang,
          }),
          {},
        ),
        locales: languages.reduce(
          (idLangs, lang) => ({
            ...idLangs,
            [lang.lang_slug]: lang.locale,
          }),
          {},
        ),
        default_lang: {
          id_lang: defaultLang.id_lang,
          lang_slug: defaultLang.lang_slug,
        },
        id_currency,
        id_country,
        iso_code: languages.reduce(
          (isoLangs, lang) => ({
            ...isoLangs,
            [lang.lang_slug]: lang.iso_code,
          }),
          {},
        ),
        currency_iso_code,
      },
    }
  }, {})
}

/**
 * @description Get shop's id_country list
 * @param {ShopConfig[]} shops
 * @return {number[]}
 */
function getShopCountryID(shops) {
  return Array.from(new Set(shops.map((shop) => shop.id_country)))
}

/**
 * @description Currency formatter
 * @param {object} params
 * @param {string} params.shop
 * @param {string} params.value
 * @return {string}
 */
function formatShopCurrency({ shop, value }) {
  const currentShop = getShopInfo({ key: 'slug', value: shop })
  return !currentShop
    ? `${value} ฿`
    : currentShop.currency_symbol.replace('%VALUE%', value)
}

/**
 * @typedef {{
 *   hrefLang: string,
 *   href: string,
 * }} ShopMetaLink
 */
/**
 * Tell Google about localized versions of your page
 * @param {string} prefix domain
 * @param {string} path url path
 * @returns {ShopMetaLink[]}
 */
function getShopsMetaLinks(prefix = '', path = '') {
  const [glShop, ...shops] = shopConfigs
  return [
    {
      hrefLang: 'en',
      href: [prefix, glShop.slug, glShop.languages[0].lang_slug, path].join(
        '/',
      ),
    },
  ].concat(
    ...shops.map(({ slug, languages }) =>
      languages.map(({ lang_slug }) => ({
        hrefLang: `${lang_slug}-${slug.toUpperCase()}`,
        href: [prefix, slug, lang_slug, path].join('/'),
      })),
    ),
  )
}

module.exports = {
  isOfflineAvailableShop,
  filterOfflineAvailableShop,
  getShopLanguageISOCode,
  getMultiLanguageShop,
  getAvailableShop,
  getShopInfo,
  getShopCountryIDAndCode,
  getShopCountryISOCode,
  getMenuFromShops,
  getShopLocales,
  getShopLangCurrency,
  getShopCountryID,
  formatShopCurrency,
  getShopsMetaLinks,
}
