const blockedUserLocizes = {
  AUTH_BLOCKED_USER_INFO_1: 'Your account %account% has been locked',
  AUTH_BLOCKED_USER_INFO_2: 'due to multiple failed login attempts.',
  AUTH_BLOCKED_USER_INFO_3: 'Please wait %d mins from your last attempt.',
  AUTH_BLOCKED_RESET_PASSWORD:
    'Your account %account% has been locked temporarily for your safety as we detected suspicious resetting of your password multiple times.',
  AUTH_BLOCKED_TRY_AGAIN: 'Please try again later!',
}

export default blockedUserLocizes
