import { segmentTrackAction } from 'lib/segment'
import { EVENT } from 'lib/segment/const'
import StateStorage from 'lib/state-storage'
import ShopLanguageMap from 'lib/i18n/shop-language-map'

const shop = StateStorage.getShop()
const user = StateStorage.getAuthUser()
const id_shop = ShopLanguageMap.getIdShopBySlug(shop)
const id_customer = user?.id_customer

const segmentQuickAddToBagClicked = ({
  id_product,
  product_name,
  size,
  id_site_category,
}) => {
  const data = {
    id_shop,
    id_customer,
    id_product,
    product_name,
    size,
    quantity: 1,
    id_site_category,
  }
  segmentTrackAction(EVENT.quickAddToCart, data)
}

export default segmentQuickAddToBagClicked
