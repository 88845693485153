import Duck from 'extensible-duck'

const initialState = {
  showSearchModal: false,
}

export default new Duck({
  namespace: 'content',
  store: 'searchModal',
  types: ['TOGGLE', 'CLOSE'],
  initialState,
  reducer: (state, action, duck) => {
    switch (action.type) {
      case duck.types.TOGGLE:
        return { showSearchModal: !state.showSearchModal }
      case duck.types.CLOSE:
        return { showSearchModal: false }
      default:
        return state
    }
  },
  creators: (duck) => ({
    toggleSearchModal: (showSearchModal) => ({
      type: duck.types.TOGGLE,
      showSearchModal,
    }),
    closeSearchModal: () => ({ type: duck.types.CLOSE }),
  }),
})
