import React from 'react'
import PropTypes from 'prop-types'
import Select from 'components/common/select'
import Typo from 'constants/typography'
import { SELECT_TYPE } from 'components/common/select/const'
import { withI18next } from 'lib/i18n/withI18next'

const MultipleSizeSelection = ({
  handleChangeSize,
  product,
  selectedAttribute,
  t,
}) => (
  <Select
    fullWidth
    type={SELECT_TYPE.float}
    label={t('Size')}
    value={selectedAttribute?.id_product_attribute}
    className="item-info__select"
    rootClassName="item-info__size"
    selectClassName={Typo.subtitle2}
    onChange={(event, val) =>
      handleChangeSize &&
      handleChangeSize(
        val,
        selectedAttribute.id_product_attribute,
        product.id_product,
        event,
      )
    }
  >
    {product?.attributes?.map((item) => (
      <option
        key={item.id_product_attribute}
        value={item.id_product_attribute}
        className={Typo.subtitle2}
      >
        {item.size}
      </option>
    ))}
  </Select>
)

MultipleSizeSelection.defaultProps = {
  handleChangeSize: null,
  product: null,
  selectedAttribute: null,
}

MultipleSizeSelection.propTypes = {
  handleChangeSize: PropTypes.func,
  product: PropTypes.shape({
    id_product: PropTypes.number,
    attributes: PropTypes.arrayOf(PropTypes.shape({})),
    name: PropTypes.string,
    max_quantity: PropTypes.number,
    type: PropTypes.string,
    quantity: PropTypes.number,
  }),
  selectedAttribute: PropTypes.shape({
    id_product_attribute: PropTypes.number,
    size: PropTypes.string,
  }),
  t: PropTypes.func.isRequired,
}

export default withI18next()(MultipleSizeSelection)
