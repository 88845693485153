import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import clsx from 'clsx'
import useAnonymousId from 'components/custom-hooks/useAnonymousId'
import ResponsiveImage from 'components/images/responsive-image'
import { hasLocizeTranslation } from 'lib/utils/locize'
import Typo from 'constants/typography'
import { BUTTON_TYPES } from 'constants/button'
import Button from 'components/common/button'
import env from 'config/env'
import usePomeloCashbackEnable from 'pages/user/pomelo-credit/credit-cashback/usePomeloCashbackEnable'
import { withI18next } from 'lib/i18n/withI18next'
import styles from './styles.scss'

const DATA = {
  cta: 'install',
  label: 'Welcome',
  title: 'get 20% off first order in-app',
  ctaKey: 'AUTH_SIGNUP_WELCOME_CTA',
  labelKey: 'AUTH_SIGNUP_WELCOME_LABEL',
  titleKey: 'AUTH_SIGNUP_WELCOME_TITLE',
  desc: [
    {
      descKey: 'AUTH_SIGNUP_WELCOME_DESC_MOBILE',
      descVal: 'Make your first purchase on App and apply code:',
    },
    {
      descKey: 'AUTH_SIGNUP_WELCOME_DESC_DESKTOP',
      descVal: 'Scan QR Code to download Pomelo Fashion App',
    },
  ],
  appCode: 'APPY20',
  appCodeKey: 'AUTH_SIGNUP_WELCOME_CODE',
  appStore: {
    imgRatio: 0.296,
    link: 'https://pomelo.app.link/gISpQRe4oX',
    src: `${env.IMG_HOST}img/logos/app-store.svg`,
  },
  playStore: {
    imgRatio: 0.296,
    link: 'https://pomelo.app.link/gISpQRe4oX',
    src: `${env.IMG_HOST}img/logos/play-store.svg`,
  },
  webToApp: {
    imgRatio: 1,
    src: `${env.IMG_HOST}img/logos/web-to-app.jpg`,
  },
  installLink: 'https://pomelo.app.link/gISpQRe4oX',
}

const AuthWelcome = ({ isPhone, t, user, shopId }) => {
  const isCashbackEnable = usePomeloCashbackEnable(shopId)
  const { descVal, descKey } = DATA.desc[isPhone ? 0 : 1]
  const { anonymousIdQueryString } = useAnonymousId()
  const welcomeMsg = `${hasLocizeTranslation(t, DATA.labelKey, DATA.label)} ${
    user?.firstname || 'User'
  }!`

  return (
    <React.Fragment>
      <style jsx>{styles}</style>
      <span className={clsx(Typo.h5, 'signup-welcome__label')}>
        {welcomeMsg}
      </span>
      {isCashbackEnable && (
        <span className={Typo.h4}>
          {hasLocizeTranslation(t, DATA.titleKey, DATA.title)}
        </span>
      )}
      <span className={clsx(Typo.body2, 'signup-welcome__desc')}>
        {hasLocizeTranslation(t, descKey, descVal)}
        {isPhone && (
          <span className={clsx(Typo.body2, 'signup-welcome__code')}>
            {hasLocizeTranslation(t, DATA.appCodeKey, DATA.appCode)}
          </span>
        )}
      </span>
      {!isPhone && (
        <ResponsiveImage
          className="signup-welcome__qr"
          imgRatio={DATA.webToApp.imgRatio}
          src={DATA.webToApp.src}
          tablet="50vw"
          laptopAndUp="40vw"
        />
      )}
      <div className="signup-welcome__download">
        <a
          className="signup-welcome__link"
          href={`${DATA.playStore.link}${anonymousIdQueryString}`}
          rel="nofollow"
        >
          <ResponsiveImage
            imgRatio={DATA.playStore.imgRatio}
            src={DATA.playStore.src}
            phone="45vw"
            tablet="20vw"
            laptopAndUp="15vw"
          />
        </a>
        <a
          className="signup-welcome__link"
          href={`${DATA.appStore.link}${anonymousIdQueryString}`}
          rel="nofollow"
        >
          <ResponsiveImage
            imgRatio={DATA.appStore.imgRatio}
            src={DATA.appStore.src}
            phone="45vw"
            tablet="20vw"
            laptopAndUp="15vw"
          />
        </a>
      </div>
      {isPhone && (
        <a
          className="signup-welcome__link download-cta"
          href={`${DATA.installLink}${anonymousIdQueryString}`}
          rel="nofollow"
        >
          <Button
            type={BUTTON_TYPES.primary}
            className="signup-welcome__cta button"
          >
            {hasLocizeTranslation(t, DATA.ctaKey, DATA.cta)}
          </Button>
        </a>
      )}
    </React.Fragment>
  )
}

AuthWelcome.defaultProps = {
  user: null,
}

AuthWelcome.propTypes = {
  isPhone: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
  user: PropTypes.shape({ firstname: PropTypes.string }),
  shopId: PropTypes.number.isRequired,
}

export { AuthWelcome as SignupWelcome }

export default compose(
  connect((state) => ({
    isPhone: state.device.isPhone,
    user: state.auth.user,
    shopId: state.internationalization.shop,
  })),
  withI18next(),
)(AuthWelcome)
