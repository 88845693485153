import { request } from './index'
import { HTTP_METHODS } from './const'

class Cart {
  static ADD_CART = 'AddToCart'

  static UPDATE_CART = 'UpdateCart'

  static DELETE_FROM_CART = 'DeleteFromCart'

  static GET_CART = 'GET'

  static USE_VOUCHER = 'USE_VOUCHER'

  static REMOVE_VOUCHER = 'REMOVE_VOUCHER'

  static ADD_TO_CART_FROM_WISHLIST = 'ADD_TO_CART_FROM_WISHLIST'

  static get(options) {
    switch (options.type) {
      case Cart.GET_CART:
        return Cart.getCart(options)
      case Cart.ADD_CART:
        return Cart.addToCart(options)
      case Cart.UPDATE_CART:
        return Cart.updateCart(options)
      case Cart.DELETE_FROM_CART:
        return Cart.deleteFromCart(options)
      case Cart.USE_VOUCHER:
      case Cart.REMOVE_VOUCHER:
        return Cart.handleVoucherCode(options)
      case Cart.ADD_TO_CART_FROM_WISHLIST:
        return Cart.addToCartFromWishlist(options)
      default:
        return Cart.getCart(options)
    }
  }

  static getCart(options) {
    const params = {
      'include[id_cart]': true,
    }

    const config = {
      method: HTTP_METHODS.post,
      url: `v6/carts/current/view`,
      body: options.body,
      params,
    }

    return request(config)
  }

  static addToCart(options) {
    const body = {
      id_product_attribute: parseInt(options.productAttributeID, 10),
      quantity: options.quantity,
    }

    const config = {
      method: HTTP_METHODS.post,
      url: `v6/products/${parseInt(options.productID, 10)}/add-to-cart`,
      body,
    }

    return request(config)
      .then(() => Cart.getCart(options))
      .catch((err) => ({ ...err, ok: false }))
  }

  static updateCart(options) {
    const deleteOptions = {
      ...options,
      productAttributeID: options.productToDeleteAttributeID,
      skipCartRender: true,
    }
    const addOptions = {
      ...options,
      productAttributeID: options.productToAddAttributeID,
      skipCartRender: options.skipCartRender,
    }

    return Cart.deleteFromCart(deleteOptions)
      .then(() => Cart.addToCart(addOptions))
      .catch((err) => ({ ...err, ok: false }))
  }

  static deleteFromCart(options) {
    const body = {
      actions: [
        {
          type: 'remove-item',
          id_product: parseInt(options.productID, 10),
          id_product_attribute: parseInt(options.productAttributeID, 10),
        },
      ],
    }

    const config = {
      method: HTTP_METHODS.patch,
      url: 'v6/carts/current',
      body,
    }

    if (options.skipCartRender) {
      return request(config)
    }

    return request(config)
      .then(() => Cart.getCart(options))
      .catch((err) => ({ ...err, ok: false }))
  }

  static handleVoucherCode(options) {
    const config = {
      method: HTTP_METHODS.post,
      url: 'v6/carts/current/apply',
      body: {
        voucher_code: options.voucherCode,
      },
    }

    return request(config).catch(
      (err) => err.error?.message || err.response?.data?.error?.message,
    )
  }

  static addToCartFromWishlist(options) {
    const { body, wishlistID } = options
    const config = {
      method: HTTP_METHODS.post,
      url: `v6/wishlists/${wishlistID}/add-to-cart`,
      body,
    }

    return request(config).catch((err) => err)
  }
}

export default Cart
