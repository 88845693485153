import { covertPropertiesToCamelCase } from 'lib/utils/common/object'

const USP_KEY_LIST = ['pickup', 'loyalty', 'new', 'delivery', 'cash', 'return']

const checkRemoveType = (propertiesToCamelCase, item) => {
  const {
    isEnableCashOnDelivery: isCash,
    isEnablePickUp: isPickup,
    isFreeReturnAble: isReturn,
    isAllowLoyalty: isLoyalty,
  } = propertiesToCamelCase

  switch (item) {
    case 'cash':
      return isCash
    case 'pickup':
      return isPickup
    case 'return':
      return isReturn
    case 'loyalty':
      return isLoyalty
    default:
      return true
  }
}

const getArrayOfUspType = (
  currentShop,
  isPomeloCashbackEnable,
  isTTBEnable,
) => {
  const propertiesToCamelCase = covertPropertiesToCamelCase(currentShop)
  return USP_KEY_LIST.slice()
    .filter((item) => checkRemoveType(propertiesToCamelCase, item))
    .filter((item) => {
      switch (item) {
        case 'loyalty':
          return isPomeloCashbackEnable
        case 'pickup':
          return isTTBEnable

        default:
          return true
      }
    })
}

const checkProbability = (isAllow, solved, reject = null) =>
  isAllow ? solved : reject

export { checkProbability, getArrayOfUspType }
