import env from 'config/env'
import { getAssetPath } from 'lib/utils/common/commonUtils'

export const ASSET_PREFIX = `${getAssetPath()}/static/img/pages/no-result`

export const CATEGORORY_DATA = [
  {
    src: `${env.IMG_HOST}img/error/error_new_arrivals.jpg`,
    label: 'New Arrivals',
    labelKey: 'ERROR_DEFAULT_LABEL_1',
    link: {
      as: '/clothes/new-arrivals',
      href: '/category?id=88',
    },
  },
  {
    src: `${env.IMG_HOST}img/error/error_dresses.jpg`,
    label: 'Dresses',
    labelKey: 'ERROR_DEFAULT_LABEL_2',
    link: {
      as: '/clothes/dresses',
      href: '/category?id=36',
    },
  },
  {
    src: `${env.IMG_HOST}img/error/error_tops.jpg`,
    label: 'Tops',
    labelKey: 'ERROR_DEFAULT_LABEL_3',
    link: {
      as: '/clothes/tops',
      href: '/category?id=6',
    },
  },
  {
    src: `${env.IMG_HOST}img/error/error_bottoms.jpg`,
    label: 'Bottoms',
    labelKey: 'ERROR_DEFAULT_LABEL_4',
    link: {
      as: '/clothes/bottoms',
      href: '/category?id=7',
    },
  },
]

export const PDP_ERROR_MESSAGE = {
  title: { key: 'PDP_ERROR_MESSAGE_TITLE', message: 'Product Unavailable' },
  description: {
    key: 'PDP_ERROR_MESSAGE_DESCRIPTION',
    message:
      'The product you requested is not available in the selected country',
  },
  placeholder: {
    key: 'PDP_ERROR_MESSAGE_PLACEHOLDER',
    message: 'Search a city or postal code…',
  },
}
