import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { withI18next } from 'lib/i18n/withI18next'
import { hasLocizeTranslation } from 'lib/utils/locize'
import Typo from 'constants/typography'
import Icon from 'components/common/icon'
import { ICON_SIZE } from 'components/common/icon/const'
import { FOOTER_LOCIZE, FOOTER_ICONS, FOOTER_PAYMENT_ICONS } from '../const'
import FooterIconList from '../icon-list'
import styles from '../style.scss'

const ContactSection = ({ isHoolahVisible, t }) => (
  <div className="footer-section">
    <style jsx>{styles}</style>
    <span
      className={clsx('footer-title', Typo.subtitle2)}
      data-cy={FOOTER_LOCIZE.contact_key.toLowerCase()}
    >
      {hasLocizeTranslation(
        t,
        FOOTER_LOCIZE.contact_key,
        FOOTER_LOCIZE.contact_desc,
      )}
    </span>
    <div className="footer-section-contact">
      <span className={clsx('footer-hashtag', Typo.body2)}>
        {hasLocizeTranslation(
          t,
          FOOTER_LOCIZE.hashtag_key,
          FOOTER_LOCIZE.hashtag_desc,
        )}
      </span>
      <FooterIconList IconLists={FOOTER_ICONS} />
    </div>
    {isHoolahVisible && (
      <div>
        <div
          className={clsx('footer-payment-title', Typo.subtitle2)}
          data-cy={FOOTER_LOCIZE.payment_key.toLowerCase()}
        >
          {hasLocizeTranslation(
            t,
            FOOTER_LOCIZE.payment_key,
            FOOTER_LOCIZE.payment_desc,
          )}
        </div>

        <div className="footer-section__payment-icons">
          {FOOTER_PAYMENT_ICONS.map(({ key, src, customSize }) => (
            <Icon
              key={key}
              {...(!customSize
                ? {
                    size: ICON_SIZE.medium,
                  }
                : customSize)}
              src={src}
              alt={key}
              lazyThreshold={0.9}
            />
          ))}
        </div>
      </div>
    )}
  </div>
)

ContactSection.propTypes = {
  isHoolahVisible: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
}

export default withI18next()(ContactSection)
