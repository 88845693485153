import React from 'react'
import PropTypes from 'prop-types'
import { hasLocizeTranslation } from 'lib/utils/locize'
import Icon from 'components/common/icon'
import ICONS, { ICON_SIZE } from 'components/common/icon/const'
import Typo from 'constants/typography'
import { withI18next } from 'lib/i18n/withI18next'
import { VOUCHER_CHECKOUT_LOCIZE } from 'components/pdp/carousel-images/voucher/voucher-checkout/const'

const VoucherEmptyBody = ({ t }) => (
  <div className="pdp__voucher-modal__info-empty">
    <Icon src={ICONS.voucherEmpty} size={ICON_SIZE.extraLarge} />
    <span className={Typo.subtitle1}>
      {hasLocizeTranslation(
        t,
        VOUCHER_CHECKOUT_LOCIZE.VOUCHER_CHECKOUT_EMPTY.key,
        VOUCHER_CHECKOUT_LOCIZE.VOUCHER_CHECKOUT_EMPTY.text,
      )}
    </span>
  </div>
)

VoucherEmptyBody.propTypes = {
  t: PropTypes.func.isRequired,
}

export default withI18next()(VoucherEmptyBody)
