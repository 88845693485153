const BADGE_HORIZONTAL = {
  left: 'left',
  right: 'right',
}

const BADGE_VERTICAL = {
  top: 'top',
  bottom: 'bottom',
}

const BADGE_COLOR = {
  primary: 'pri',
  secondary: 'sec',
  error: 'error',
  accentLight: 'accent-light',
  errorLight: 'error-light',
  desktopBanner: 'desktop-banner',
}

export { BADGE_HORIZONTAL, BADGE_VERTICAL, BADGE_COLOR }
