import clsx from 'clsx'
import PropTypes from 'prop-types'
import Typo from 'constants/typography'
import { withI18next } from 'lib/i18n/withI18next'
import { hasLocizeTranslation } from 'lib/utils/locize'
import { PDP_ERROR_MESSAGE } from '../const'
import styles from './style.scss'

const ErrorTitle = ({ showPDPError, showDefaultError, t }) => {
  if (showPDPError) {
    return (
      <div className="text-container">
        <style jsx>{styles}</style>
        <p className={clsx('title', Typo.title3)}>
          {hasLocizeTranslation(
            t,
            PDP_ERROR_MESSAGE.title.key,
            PDP_ERROR_MESSAGE.title.message,
          )}
        </p>
        <p className={clsx('sub-title', Typo.price2)}>
          {hasLocizeTranslation(
            t,
            PDP_ERROR_MESSAGE.description.key,
            PDP_ERROR_MESSAGE.description.message,
          )}
        </p>
      </div>
    )
  }

  if (showDefaultError) {
    return (
      <div className="text-container">
        <style jsx>{styles}</style>
        <p className={clsx('title', Typo.h4)}>{t('Oops!')}</p>
        <p className={clsx('sub-title', Typo.h6)}>
          {t('We could not find the page you requested')}
        </p>
      </div>
    )
  }

  return null
}

ErrorTitle.defaultProps = {
  showDefaultError: true,
  showPDPError: undefined,
}

ErrorTitle.propTypes = {
  showDefaultError: PropTypes.bool,
  showPDPError: PropTypes.bool,
  t: PropTypes.func.isRequired,
}

export default withI18next()(ErrorTitle)
