import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { segmentHomeFeedImpression } from 'lib/segment'
import Components from '../const'

const DynamicSkinnyBanner = ({
  banner,
  isPhone,
  link,
  onLinkClicked,
  type,
}) => {
  const CustomComponent = Components[type]
  useEffect(() => {
    segmentHomeFeedImpression(banner?.id_feed)
  }, [banner.id_feed])

  if (!type || !CustomComponent) {
    return null
  }

  return (
    <CustomComponent
      banner={banner}
      link={link}
      onLinkClicked={onLinkClicked}
      isPhone={isPhone}
    />
  )
}

DynamicSkinnyBanner.propTypes = {
  banner: PropTypes.shape({
    id_feed: PropTypes.number,
  }).isRequired,
  isPhone: PropTypes.bool.isRequired,
  link: PropTypes.shape({
    href: PropTypes.string,
    as: PropTypes.string,
  }).isRequired,
  onLinkClicked: PropTypes.func.isRequired,
  type: PropTypes.oneOf(Object.keys(Components)).isRequired,
}

export default connect((state) => ({
  isPhone: state.device.isPhone,
}))(DynamicSkinnyBanner)
