import { useState, useEffect } from 'react'

function useAnonymousId() {
  const [anonymousId, setAnonymousId] = useState(null)
  const [anonymousIdQueryString, setanonymousIdQueryString] = useState('')

  useEffect(() => {
    const analytics = window && window.analytics
    if (analytics && typeof analytics.user === 'function') {
      setAnonymousId(window.analytics.user().anonymousId())

      if (anonymousId) {
        setanonymousIdQueryString(`?anon_id=${anonymousId}`)
      }
    }
  }, [anonymousId])

  return { anonymousId, anonymousIdQueryString }
}

export default useAnonymousId
