import clsx from 'clsx'
import PropTypes from 'prop-types'
import React, { useCallback, useEffect } from 'react'
import { connect } from 'react-redux'
import Button from 'components/common/button'
import Icon from 'components/common/icon'
import ICONS, { ICON_SIZE } from 'components/common/icon/const'
import { BUTTON_TYPES } from 'constants/button'
import Typo from 'constants/typography'
import { withI18next } from 'lib/i18n/withI18next'
import { toggleBodyFreezing } from 'lib/utils/common/commonUtils'
import { hasLocizeTranslation } from 'lib/utils/locize'
import HoolahInfo from '../hoolah-info'
import ASSETS, { hoolahInfoList, hoolahModalText, hoolahUrl } from './const'
import styles from './styles.scss'

const getHoolahClassName = (classname) => `pdp__hoolah-info-modal__${classname}`

const redirectToHoolah = (shop) => {
  switch (shop) {
    // commented for now as the TH link will be ready later
    // case 1:
    //   window.open(hoolahUrl.th)
    //   break
    case 2:
      window.open(hoolahUrl.sg)
      break
    case 11:
      window.open(hoolahUrl.my)
      break
    default:
      break
  }
}

const HoolahInfoModal = ({
  internationalization: { shop },
  isPhone,
  onClose,
  t,
}) => {
  const linksEl = React.createRef()

  const handleHideContent = useCallback(
    (e) => {
      if (linksEl.current && !linksEl.current.contains(e.target)) {
        onClose(e)
      }
    },
    [linksEl, onClose],
  )

  useEffect(() => {
    if (!isPhone) {
      document.addEventListener('click', handleHideContent, true)

      return () => {
        document.removeEventListener('click', handleHideContent, true)
      }
    }

    return () => {}
  }, [handleHideContent, isPhone, linksEl])

  useEffect(() => {
    if (isPhone) {
      toggleBodyFreezing(true)

      return () => {
        toggleBodyFreezing(false)
      }
    }

    return () => {}
  }, [isPhone])

  return (
    <div className={getHoolahClassName('container')} ref={linksEl}>
      <style jsx>{styles}</style>
      <Icon
        alt="close hoolah drawer"
        className={getHoolahClassName('close-btn')}
        src={ICONS.close}
        size={ICON_SIZE.medium}
        onClick={onClose}
      />
      <div className={getHoolahClassName('header')}>
        <span className={clsx('header-title', Typo.subtitle1)}>
          {hasLocizeTranslation(
            t,
            hoolahModalText.headerTitle.key,
            hoolahModalText.headerTitle.value,
          )}
        </span>
      </div>
      <div className={getHoolahClassName('description')}>
        <img
          alt="hoolah logo"
          className="hoolah-logo"
          src={ASSETS.HOOLAH_LOGO}
        />
        <span className={clsx('title', Typo.title3)}>
          {hasLocizeTranslation(
            t,
            hoolahModalText.descriptionTitle.key,
            hoolahModalText.descriptionTitle.value,
          )}
        </span>
        <span className={clsx('sub-title', Typo.body2)}>
          {hasLocizeTranslation(
            t,
            hoolahModalText.descriptionSubTitle.key,
            hoolahModalText.descriptionSubTitle.value,
          )}
        </span>
      </div>
      <div className={getHoolahClassName('info-section')}>
        {hoolahInfoList.map((step) => (
          <HoolahInfo
            getClass={getHoolahClassName}
            key={step.description.key}
            step={step}
          />
        ))}
      </div>
      <div className={getHoolahClassName('cta-container')}>
        <Button
          className="learn-more-cta"
          type={BUTTON_TYPES.link}
          onClick={() => redirectToHoolah(shop)}
        >
          {hasLocizeTranslation(
            t,
            hoolahModalText.learnMoreCta.key,
            hoolahModalText.learnMoreCta.value,
          )}
        </Button>
      </div>
    </div>
  )
}

HoolahInfoModal.propTypes = {
  internationalization: PropTypes.shape({
    shop: PropTypes.number,
  }).isRequired,
  isPhone: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
}

const Extended = withI18next()(HoolahInfoModal)

export default connect((state) => ({
  internationalization: state.internationalization,
  isPhone: state.device.isPhone,
}))(Extended)
