import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { useForm } from 'react-hook-form'
import { parsePhoneNumber } from 'libphonenumber-js'
import Button from 'components/common/button'
import { BUTTON_HTML_TYPE, BUTTON_TYPES } from 'constants/button'
import { getCountryCodeWithShopID } from 'lib/utils/common/commonUtils'
import { hasLocizeTranslation } from 'lib/utils/locize'
import { useAuth } from 'components/auth/context'
import {
  AUTH_PROVIDER,
  AUTH_SIGNUP_METHODS,
  AUTH_UI_STATE,
  AUTH_MESSAGE,
} from 'components/auth/const'
import {
  logInWithPhoneGetSMSFireBase,
  verifyIsPhoneRegisteredFireBase,
} from 'lib/auth'
import { withI18next } from 'lib/i18n/withI18next'
import TextFieldPhone from 'components/molecules/textfield-phone'
import { formConfig } from 'components/auth/signup-form/const'
import FormItem from 'components/form-item'
import compose from 'recompose/compose'
import style from './style.scss'

const AuthPhoneBase = ({
  countryState,
  internationalization,
  isRegister,
  t,
}) => {
  // states
  const [isLoading, setIsLoading] = useState(false)
  const [isVerified, setIsVerified] = useState(false)
  const [prefix, setPrefix] = useState(
    getCountryCodeWithShopID(internationalization),
  )

  // auth hook
  const [authState, updateAuthState] = useAuth()

  // form hook
  const { errors, formState, handleSubmit, register, setError } = useForm({
    mode: 'onChange',
  })
  const { isValid, dirty } = formState
  const isVerifiedAndNotTouched = isVerified && !dirty

  const INPUT_NAME_PHONE = 'phone'
  const invalidPhoneMsg = hasLocizeTranslation(
    t,
    'FORM_INVALID_PHONE_FORMAT',
    'Invalid phone number format.',
  )

  const phoneRegister = register(
    formConfig.phone({
      isRequired: true,
      requiredMessage: t('Required'),
      lengthErrorMessage: invalidPhoneMsg,
      patternErrorMessage: invalidPhoneMsg,
    }),
  )

  // handlers
  const handleFormSubmit = async (values, e) => {
    e?.preventDefault()

    const fullPhone = parsePhoneNumber(`+${prefix}${values.phone}`).number

    updateAuthState({
      dataPhone: fullPhone,
      source: AUTH_PROVIDER.phone,
      overlayLoading: true,
    })
    setIsLoading(true)

    try {
      const res = await verifyIsPhoneRegisteredFireBase(fullPhone)
      if (res.number_phone_exist) {
        await logInWithPhoneGetSMSFireBase(fullPhone)
        updateAuthState({
          isPhoneSignup: false,
          uiState: AUTH_UI_STATE.verifyPhone,
        })
      } else {
        updateAuthState({
          isSelectSignUpMethod: true,
          isPhoneSignup: true,
          signUpMethod: AUTH_SIGNUP_METHODS.PHONE,
          uiState: AUTH_UI_STATE.signUp,
        })
      }
      updateAuthState({ overlayLoading: false })
    } catch (err) {
      if (err.message === AUTH_MESSAGE.invalidUsername) {
        setError(
          INPUT_NAME_PHONE,
          'manual',
          hasLocizeTranslation(
            t,
            'INVALID_USERNAME_PHONE',
            'Please provide a valid phone number',
          ),
        )
      } else {
        updateAuthState({ snackBar: err })
      }
      updateAuthState({ overlayLoading: false })
      setIsLoading(false)
    }
  }

  // effect
  useEffect(() => {
    if (authState.dataPhone) {
      setIsVerified(true)
    }
    // disabled this rule because want to run only when mounted
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <form
      className="auth__phone-login-wrapper"
      onSubmit={handleSubmit(handleFormSubmit)}
      noValidate
    >
      <style jsx>{style}</style>
      <FormItem>
        <TextFieldPhone
          selectedPhonePrefix={String(prefix)}
          handleChangePhonePrefix={(e) => setPrefix(+e.target.value)}
          countryList={countryState?.countries}
          phonePrefixRegister={register({ required: true })}
          phoneNumberRegister={phoneRegister}
          inputProps={{
            cy: 'auth__phone__input',
            required: true,
            type: 'tel',
            id: 'phone',
          }}
          label={t('Phone Number')}
          name={INPUT_NAME_PHONE}
          htmlFor="phone"
          error={errors[INPUT_NAME_PHONE]?.message}
          userPhoneNumber={authState.dataPhone?.replace(`+${prefix}`, '')}
        />
      </FormItem>
      <FormItem>
        <Button
          className="auth__phone-login-cta"
          cy="auth__login__phone__button"
          disabled={(!isValid && !isVerifiedAndNotTouched) || isLoading}
          htmlType={BUTTON_HTML_TYPE.submit}
          type={BUTTON_TYPES.primary}
        >
          {t(isRegister ? 'Sign Up' : 'Log In')}
        </Button>
      </FormItem>
    </form>
  )
}

AuthPhoneBase.propTypes = {
  countryState: PropTypes.shape({
    countries: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  internationalization: PropTypes.shape({}).isRequired,
  isRegister: PropTypes.bool,
  t: PropTypes.func.isRequired,
}

AuthPhoneBase.defaultProps = {
  countryState: undefined,
  isRegister: false,
}

export default compose(
  connect((state) => ({
    countryState: state.country.payload,
    internationalization: state.internationalization,
  })),
  withI18next(),
)(AuthPhoneBase)
