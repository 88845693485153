const {
  filterOfflineAvailableShop,
  getShopLanguageISOCode,
  getAvailableShop,
  getShopCountryIDAndCode,
  getMultiLanguageShop,
  getShopCountryISOCode,
  getMenuFromShops,
  getShopLocales,
  getShopLangCurrency,
  getShopCountryID,
} = require('../../lib/utils/shop-lang')
const { configs } = require('../../config/shop-language.json')

const ENABLE_SHOP = getAvailableShop(configs)
const OFFLINE_AVAILABLE_SHOP = filterOfflineAvailableShop(ENABLE_SHOP)
const SHOP_COUNTRY_ID_AND_CODE = getShopCountryIDAndCode(ENABLE_SHOP)
const AVAILABLE_SHOP_LANG_ISO_CODE = getShopLanguageISOCode(ENABLE_SHOP)
const SHOP_WITH_MULTI_LANG = getMultiLanguageShop(ENABLE_SHOP)
const SHOP_COUNTRY_ISO_CODES = getShopCountryISOCode(ENABLE_SHOP)
const SHOP_LANG_CURRENCY = getShopLangCurrency(ENABLE_SHOP)
const SHOP_LOCALES = getShopLocales(ENABLE_SHOP)
const SHOP_MENUS = getMenuFromShops(ENABLE_SHOP)
const SHOP_COUNTRIES_ID = getShopCountryID(ENABLE_SHOP)

module.exports = {
  AVAILABLE_SHOP_LANG_ISO_CODE,
  ENABLE_SHOP,
  OFFLINE_AVAILABLE_SHOP,
  SHOP_COUNTRIES_ID,
  SHOP_COUNTRY_ID_AND_CODE,
  SHOP_COUNTRY_ISO_CODES,
  SHOP_LANG_CURRENCY,
  SHOP_LOCALES,
  SHOP_MENUS,
  SHOP_WITH_MULTI_LANG,
}
