import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { withI18next } from 'lib/i18n/withI18next'
import Typo from 'constants/typography'
import PropTypes from 'prop-types'
import React from 'react'
import { hasLocizeTranslation } from 'lib/utils/locize'
import clsx from 'clsx'
import Icon from 'components/common/icon'
import ICONS, { ICON_SIZE } from 'components/common/icon/const'
import { useFlagsmithGetFlagValue } from 'lib/flagsmith'
import styles from './cart-loyalty-progress.scss'
import CartLoyaltyBenefitModal from './cart-loyalty-benefit-modal/CartLoyaltyBenefitModal'

const CartLoyaltyProgress = ({ t, loyalty_info }) => {
  const isLoyaltyTwoPointO = useFlagsmithGetFlagValue(
    'Loyalty2.0_20240808',
    false,
  )
  const isShowLoyaltyProgress =
    Boolean(loyalty_info?.current_tier_name_key) &&
    loyalty_info?.current_tier_name_key !== 'LOYALTY_TIER_NAME_BLACK' &&
    isLoyaltyTwoPointO
  const isReachTargetForUpgrade =
    Number(loyalty_info?.percentage_with_cart) === 100

  const loyaltyIcon =
    loyalty_info?.next_tier_name_key === 'LOYALTY_TIER_NAME_BLACK'
      ? ICONS.loyaltyBlackStar
      : ICONS.loyaltyGoldStar

  const loyaltyStatusUpgradeText = hasLocizeTranslation(
    t,
    'LOYALTY_STATUS_UPGRADE_TEXT',
    'Add %s+ for %t status upgrade!',
  ).replace('%s', loyalty_info?.to_meet_target_with_cart)
  const loyaltyStatusUpgradeOrderText = hasLocizeTranslation(
    t,
    'LOYALTY_STATUS_UPGRADE_ORDER_TEXT',
    'Order now for %t status upgrade!',
  )
  const textParts = isReachTargetForUpgrade
    ? loyaltyStatusUpgradeOrderText.split('%t')
    : loyaltyStatusUpgradeText.split('%t')

  if (!isShowLoyaltyProgress) return null

  return (
    <div
      data-cy="cart-loyalty-progress-container"
      className="cart-loyalty-progress-container"
    >
      <style jsx>{styles}</style>
      <div className="cart-loyalty-progress-container__status">
        <div>
          <span className={Typo.caption}>{textParts[0]}</span>
          <span
            style={{ color: loyalty_info?.next_tier_color }}
            className={clsx('status-text')}
          >
            {hasLocizeTranslation(t, loyalty_info?.next_tier_name_key, 'Gold')}
          </span>
          <span className={Typo.caption}>{textParts[1]}</span>
        </div>
        <CartLoyaltyBenefitModal
          next_tier_color={loyalty_info?.next_tier_color}
          next_tier_name_key={loyalty_info?.next_tier_name_key}
        />
      </div>
      <div
        className={clsx('status-bar', {
          'status-bar__background': !isReachTargetForUpgrade,
        })}
      >
        <div
          className="current"
          style={{
            maxWidth: `calc(100% - 21px)`,
            width: `${loyalty_info?.percentage_with_cart}%`,
            backgroundColor: loyalty_info?.next_tier_color,
          }}
        />
        {isReachTargetForUpgrade && (
          <div data-cy="loyalty-star-icon" className="icon-wrapper">
            <Icon src={loyaltyIcon} size={ICON_SIZE.medium} />
          </div>
        )}
      </div>
    </div>
  )
}

CartLoyaltyProgress.defaultProps = {
  loyalty_info: {},
}

CartLoyaltyProgress.propTypes = {
  // component props
  loyalty_info: PropTypes.shape({
    current_tier_name_key: PropTypes.string,
    next_tier_name_key: PropTypes.string,
    next_tier_color: PropTypes.string,
    curernt_with_cart: PropTypes.string,
    current: PropTypes.string,
    percentage: PropTypes.string,
    percentage_with_cart: PropTypes.string,
    target: PropTypes.string,
    to_meet_target: PropTypes.string,
    to_meet_target_with_cart: PropTypes.string,
  }),

  // redux props
  t: PropTypes.func.isRequired,
  internationalization: PropTypes.shape({
    country: PropTypes.string,
    language: PropTypes.string,
  }).isRequired,
}

export default compose(
  connect((state) => ({
    isPhone: state.device.isPhone,
    internationalization: state.internationalization,
  })),
  withI18next(),
)(CartLoyaltyProgress)
