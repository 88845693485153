import React from 'react'
import PropTypes, { string } from 'prop-types'
import Placeholder from 'components/images/placeholder'
import style from './style.scss'
import useIconElement from './IconHook'
import ICONS, { ICON_SIZE } from './const'

/**
 * **Tips**
 * When adding new icon, should use
 * https://jakearchibald.github.io/svgomg/ to minify them first.
 */
/**
 * @param {string} param0.alt icon text
 * @param {string|string[]} param0.className custom style for this component\
 * @param {string} param0.color customize icon color with hex or rgb
 * @param {boolean} param0.disabled flag to disable icon
 * @param {number} param0.lazyThreshold config for lazy loading between 0.0 to 1.0 (0 = not use lazy load)
 * @param {function} param0.onClick Set the handler to handle `click` event
 * @param {function} param0.onLoadCallback action after image loaded
 * @param {string} param0.size can be set to `small`(16x16px) `medium`(24x24px) `large`(32x32px) `extraLarge`(48x48px)
 * @param {string} param0.src svg path (local/CDN)
 */
const Icon = ({
  alt,
  lazyThreshold,
  size,
  width,
  height,
  className,
  color,
  cy,
  disabled,
  onClick,
  onLoadCallback,
  src,
}) => {
  const {
    classes,
    iconStyle,
    isShowImage,
    sizeAttribute,
    onLoad,
    refImg,
    refImgWrapper,
  } = useIconElement({
    className,
    lazyThreshold,
    size,
    width,
    height,
    color,
    disabled,
    onClick,
    onLoadCallback,
  })

  return (
    <span
      className={classes}
      onClick={onClick}
      ref={refImgWrapper}
      role="presentation"
      style={iconStyle}
      data-cy={cy}
    >
      <style jsx>{style}</style>
      {isShowImage ? (
        <img
          className="icon-image"
          ref={refImg}
          src={src}
          alt={alt}
          onLoad={onLoad}
          {...sizeAttribute}
        />
      ) : (
        <Placeholder imgRatio={1.2} />
      )}
    </span>
  )
}

Icon.propTypes = {
  alt: PropTypes.string,
  lazyThreshold: PropTypes.number,
  size: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(string)]),
  color: PropTypes.string,
  cy: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  onLoadCallback: PropTypes.func,
  src: PropTypes.string.isRequired,
}

Icon.defaultProps = {
  alt: '',
  className: '',
  size: undefined,
  width: undefined,
  height: undefined,
  color: undefined,
  cy: undefined,
  disabled: undefined,
  lazyThreshold: 0,
  onClick: undefined,
  onLoadCallback: undefined,
}

export { ICONS, ICON_SIZE }
export default Icon
