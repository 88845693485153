import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import Icon from 'components/common/icon'
import ICONS, { ICON_SIZE } from 'components/common/icon/const'

/**
 *
 * @param {string} param0.className - component classname
 * @param {boolean} param0.checked - checked state of component
 * @param {string} param0.cy - for cypress identifier
 * @param {boolean} param0.disabled - disable state of component, this one have more priority than `checked`
 * @param {string} param0.size - component size can be set to `small`(16x16px) `medium`(24x24px) `large`(32x32px) `extraLarge`(48x48px)
 * @param {function} param0.onClick - click event callback function
 */
const Radio = ({ className, checked, cy, disabled, size, onClick }) => {
  const classes = clsx('pomelo-radio', className)
  const icon = checked ? ICONS.radioActive : ICONS.radioInactive

  return (
    <Icon
      className={classes}
      src={icon}
      cy={cy}
      disabled={disabled}
      size={size}
      onClick={!disabled ? onClick : undefined}
    />
  )
}

Radio.propTypes = {
  className: PropTypes.string,
  cy: PropTypes.string,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  size: PropTypes.oneOf(Object.values(ICON_SIZE)),
  onClick: PropTypes.func,
}

Radio.defaultProps = {
  className: '',
  cy: undefined,
  checked: false,
  disabled: undefined,
  size: ICON_SIZE.medium,
  onClick: undefined,
}

export default Radio
