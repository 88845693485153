import StateStorage from 'lib/state-storage'
import ShopLanguageMap from 'lib/i18n/shop-language-map'

const isAuthenticated = () => {
  const user = StateStorage.getAuthUser()
  return !!user
}

const getCustomerId = () => {
  const user = StateStorage.getAuthUser()
  return user?.id_customer
}

const getStateKey = (customerId, isAuth) => (isAuth ? customerId : 'guest')

const getIsCoachMarkClosed = (
  coachMarkStateName,
  allowGuestToLocalSave = false,
) => {
  let isCoachMarkClosed = false
  const isAuth = isAuthenticated()
  const customerId = getCustomerId()
  try {
    const coachMarkSessionState = sessionStorage.getItem(coachMarkStateName)
    const coachMarkSessionData =
      typeof coachMarkSessionState === 'string'
        ? JSON.parse(coachMarkSessionState)
        : coachMarkSessionState
    const getData =
      isAuth || allowGuestToLocalSave
        ? StateStorage.getLocalState(coachMarkStateName)[
            `${getStateKey(customerId, isAuth)}`
          ]
        : coachMarkSessionData?.isClosed
    isCoachMarkClosed = !!getData
  } catch (err) {
    // Ignore write errors
  }

  return isCoachMarkClosed
}

const saveClosedCoachMarkToStorage = (
  coachMarkStateName,
  allowGuestToLocalSave = false,
) => {
  const isAuth = isAuthenticated()
  const customerId = getCustomerId()

  if (isAuth || allowGuestToLocalSave) {
    let coachMarkData = {
      ...(customerId && { [customerId]: 'true' }),
      ...(!isAuth && allowGuestToLocalSave && { guest: 'true' }),
    }

    const coachMarkLocalState = StateStorage.getLocalState(coachMarkStateName)
    if (coachMarkLocalState) {
      coachMarkData = {
        ...coachMarkLocalState,
        ...coachMarkData,
      }
    }

    return StateStorage.saveLocalState(coachMarkStateName, coachMarkData, false)
  }

  const coachMarkGuest = {
    isClosed: 'true',
  }
  return sessionStorage.setItem(
    coachMarkStateName,
    JSON.stringify(coachMarkGuest),
  )
}

const getInitialSchema = (type) => ({
  '@context': 'https://schema.org/',
  '@type': type,
})

const getSchema = (product, normalizedLink) => {
  const colors = product.variations?.map((variant) => variant.display_color)
  const images = product.images.map((img) => img.full_path)

  const productSchema = getInitialSchema('Product')
  productSchema.brand = {
    '@type': 'Brand',
    name: product.manufacturer.name,
  }
  productSchema.category = product.category.link_rewrite
  productSchema.color = colors.length > 0 ? colors[0] : undefined
  productSchema.height = product?.model?.height || null
  productSchema.image = images.length > 0 ? images[0] : undefined
  productSchema.inProductGroupWithID = product.category.id_category
  productSchema.manufacturer = {
    '@type': 'Organization',
    name: `${product.manufacturer.name}`,
  }
  productSchema.name = product.name
  productSchema.productID = product.id_product
  productSchema.size = product?.model?.size
  productSchema.sku = product.sku
  productSchema.url = `https://www.pomelofashion.com${normalizedLink.linkWithShop}`
  productSchema.offers = {
    '@type': 'Offer',
    url: `https://www.pomelofashion.com${normalizedLink.linkWithShop}`,
    priceCurrency: `${ShopLanguageMap.getCurrencyISOCodeByShopSlug(
      StateStorage.getShop(),
    )}`,
    price: `${
      product.prices.discounted_price
        ? product.prices.discounted_price
        : product.prices.original_price
    }`,
    itemCondition: 'https://schema.org/NewCondition',
    availability: 'https://schema.org/InStock',
  }

  const breadcrumbsSchema = getInitialSchema('BreadcrumbList')
  breadcrumbsSchema.itemListElement = [
    {
      '@type': 'ListItem',
      position: 1,
      name: `${product.manufacturer.name}`,
      item: {
        id: `https://www.pomelofashion.com${normalizedLink.link}`,
        type: 'WebPage',
      },
    },
  ]

  const schema = [productSchema, breadcrumbsSchema]

  return JSON.stringify(schema)
}

const getLocalizedLink = (productName) => {
  // exclude trademark, copyright, registered, /, |, &, +, and hyphen with space around symbols
  const pattern = /[\u2122\u00A9\u00AE/|&+]|(\u0020\u002D\u0020)/gu
  const localizedName = productName
    .toLowerCase()
    .replace(pattern, ' ')
    .split(' ')
    .filter((elem) => elem !== '')
    .join('-')

  return localizedName
}

export {
  getIsCoachMarkClosed,
  saveClosedCoachMarkToStorage,
  getSchema,
  getLocalizedLink,
}
