import React from 'react'
import PropTypes from 'prop-types'
import Swiper from 'react-id-swiper'
import clsx from 'clsx'
import { withI18next } from 'lib/i18n/withI18next'
import HeaderUspItem from '../header-usp-item'
import styles from './style.scss'

const HeaderUspMobile = ({
  activeType,
  classNameFromLayout,
  uspList,
  toggleUspTooltip,
}) => {
  // parameter of swiper
  const paramsSwiper = {
    slidesPerView: 'auto',
    spaceBetween: 0,
    freeMode: true,
    activeSlideKey: activeType,
  }

  const classNameMobile = clsx(
    'usp-container',
    'usp-container-mobile',
    classNameFromLayout,
    {
      active: activeType ? 'active' : '',
    },
  )

  return (
    <div className={classNameMobile}>
      <style jsx>{styles}</style>
      <Swiper {...paramsSwiper}>
        {uspList.map((type) => {
          const classNamePerType = clsx('mobile-slide-item', {
            active: activeType === type ? 'active' : '',
          })
          return (
            <div
              key={type}
              className={classNamePerType}
              onClick={() => toggleUspTooltip(type)}
              onKeyDown={() => toggleUspTooltip(type)}
              role="button"
              tabIndex="0"
            >
              <HeaderUspItem type={type} activeType={activeType} />
            </div>
          )
        })}
      </Swiper>
    </div>
  )
}

HeaderUspMobile.defaultProps = { classNameFromLayout: '' }

HeaderUspMobile.propTypes = {
  classNameFromLayout: PropTypes.string,
  uspList: PropTypes.arrayOf(PropTypes.string).isRequired,
  toggleUspTooltip: PropTypes.func.isRequired,
  activeType: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
}

export default withI18next()(HeaderUspMobile)
