import Duck from 'extensible-duck'

const checkoutDuck = new Duck({
  namespace: 'checkout',
  store: 'checkout',
  types: [
    'UPDATE_SHIPPING_ADDRESS',
    'UPDATE_SHIPPING_METHOD',
    'UPDATE_PAYMENT_METHOD',
    'UPDATE_ORDER_ID',
    'UPDATE_ORDER_SUMMARY',
    'UPDATE_IS_RETRY_PAYMENT',
    'UPDATE_IS_TTB_DEPOSIT_FLAG_ENABLE',
    'UPDATE_IS_TTB_FEE_FLAG_ENABLE',
    'UPDATE_TIMER_BANNER',
    'UPDATE_IS_RETRY_PAYMENT_FROM_ORDER',
    'RESET_DUCK',
    'UPDATE_AB_TEST_VARIATION',
  ],
  initialState: {
    shippingAddress: '',
    shippingMethod: '',
    paymentMethod: '',
    orderID: '',
    orderSummary: {},
    isRetryPayment: false,
    isTTBDepositFlagEnable: false,
    showTimerBanner: false,
    isRetryPaymentFromOrders: false,
    abTestVariation: 'a',
  },
  reducer: (state, action, duck) => {
    switch (action.type) {
      case duck.types.UPDATE_SHIPPING_ADDRESS:
        return { ...state, shippingAddress: action.shippingAddress }
      case duck.types.UPDATE_PAYMENT_METHOD:
        return { ...state, paymentMethod: action.paymentMethod }
      case duck.types.UPDATE_SHIPPING_METHOD:
        return { ...state, shippingMethod: action.shippingMethod }
      case duck.types.UPDATE_ORDER_ID:
        return { ...state, orderID: action.orderID }
      case duck.types.UPDATE_ORDER_SUMMARY:
        return { ...state, orderSummary: action.orderSummary }
      case duck.types.UPDATE_IS_RETRY_PAYMENT:
        return { ...state, isRetryPayment: action.isRetryPayment }
      case duck.types.UPDATE_IS_TTB_DEPOSIT_FLAG_ENABLE:
        return {
          ...state,
          isTTBDepositFlagEnable: action.isTTBDepositFlagEnable,
        }
      case duck.types.UPDATE_IS_TTB_FEE_FLAG_ENABLE:
        return {
          ...state,
          isTTBFeeFlagEnable: action.isTTBFeeFlagEnable,
        }
      case duck.types.UPDATE_TIMER_BANNER:
        return { ...state, showTimerBanner: action.showTimerBanner }
      case duck.types.UPDATE_IS_RETRY_PAYMENT_FROM_ORDER:
        return {
          ...state,
          isRetryPaymentFromOrders: action.isRetryPaymentFromOrders,
        }
      case duck.types.RESET_DUCK:
        return {
          shippingAddress: '',
          shippingMethod: '',
          paymentMethod: '',
          orderID: '',
          orderSummary: {},
          isRetryPayment: false,
          isTTBDepositFlagEnable: false,
          showTimerBanner: false,
          isRetryPaymentFromOrders: false,
          abTestVariation: 'a',
        }
      case duck.types.UPDATE_AB_TEST_VARIATION:
        return { ...state, abTestVariation: action.abTestVariation }
      default:
        return state
    }
  },
  creators: (duck) => ({
    updateShippingAddress: (options) => (dispatch) =>
      dispatch({
        type: duck.types.UPDATE_SHIPPING_ADDRESS,
        shippingAddress: options.shippingAddress,
      }),
    updatePayment: (options) => (dispatch) =>
      dispatch({
        type: duck.types.UPDATE_PAYMENT_METHOD,
        paymentMethod: options.paymentMethod,
      }),
    updateShippingMethod: (options) => (dispatch) =>
      dispatch({
        type: duck.types.UPDATE_SHIPPING_METHOD,
        shippingMethod: options.shippingMethod,
      }),
    updateOrderID: (options) => (dispatch) =>
      dispatch({
        type: duck.types.UPDATE_ORDER_ID,
        orderID: options.orderID,
      }),
    updateOrderSummary: (options) => (dispatch) =>
      dispatch({
        type: duck.types.UPDATE_ORDER_SUMMARY,
        orderSummary: options.orderSummary,
      }),
    updateRetryPayment: (options) => (dispatch) =>
      dispatch({
        type: duck.types.UPDATE_IS_RETRY_PAYMENT,
        isRetryPayment: options.isRetryPayment,
      }),
    updateIsTTBDepositFlagEnable: (options) => (dispatch) => {
      dispatch({
        type: duck.types.UPDATE_IS_TTB_DEPOSIT_FLAG_ENABLE,
        isTTBDepositFlagEnable: options.isTTBDepositFlagEnable,
      })
    },
    updateIsTTBFeeFlagEnable: (options) => (dispatch) => {
      dispatch({
        type: duck.types.UPDATE_IS_TTB_FEE_FLAG_ENABLE,
        isTTBFeeFlagEnable: options.isTTBFeeFlagEnable,
      })
    },
    updateTimerBanner: (options) => (dispatch) =>
      dispatch({
        type: duck.types.UPDATE_TIMER_BANNER,
        showTimerBanner: options.showTimerBanner,
      }),
    updateRetryPaymentFromOrder: (options) => (dispatch) =>
      dispatch({
        type: duck.types.UPDATE_IS_RETRY_PAYMENT_FROM_ORDER,
        isRetryPaymentFromOrders: options.isRetryPaymentFromOrders,
      }),
    resetCheckoutDuckData: () => (dispatch) =>
      dispatch({
        type: duck.types.RESET_DUCK,
      }),
    updateABTestVariation: (options) => (dispatch) =>
      dispatch({
        type: duck.types.UPDATE_AB_TEST_VARIATION,
        abTestVariation: options?.abTestVariation || 'a',
      }),
  }),
})

export default checkoutDuck
