import StateStorage from 'lib/state-storage'
import env from 'config/env'

/**
 * A function to reflected the cookie based on users setting.
 * If the user has declined all cookies, disable all cookies (including when user has not response as well)
 * If the user has allowed all cookies, allow all cookies.
 * If the user has allowed some cookies, disable some cookies
 * @function window[`ga-disable-GOOGLE_ANALYTICS_ID`] - A Function to disable google analytics tracking.
 * @function window.branch.disableTracking() - A function to disable branch.io tracking.
 * @function window.fbq - A function to disable or enable an advertising on Facebook.
 * @function window.ga() - A function to disable Google advertising
 * @constant {object} cookieConsent - The cookie consent object that is stored in the browser.
 */
const update3rdPartyConsent = () => {
  let cookieConsent = StateStorage.getCookie('bannerPDPACookieConsent')
  cookieConsent =
    typeof cookieConsent === 'string'
      ? JSON.parse(cookieConsent)
      : cookieConsent
  const facebookOptions =
    cookieConsent?.is_marketing_allowed === true ? 'grant' : 'revoke'

  window[`ga-disable-${env.GOOGLE_ANALYTICS_ID}`] =
    !cookieConsent?.is_performance_allowed
  window.branch.disableTracking(!cookieConsent?.is_performance_allowed)
  window.fbq('consent', facebookOptions)
  window.ga(
    'set',
    'allowAdFeatures',
    cookieConsent?.is_marketing_allowed || false,
  )
}

const get3rdPartyToUpdatedConsent = () => {
  // Needed to setTimeout for waiting third party was successfully initialize on Google Tag Manager
  // eslint-disable-next-line consistent-return
  setTimeout(() => {
    const { branch, fbq, ga } = window
    const isFunctionAvailable = branch && fbq && ga

    if (isFunctionAvailable) {
      return update3rdPartyConsent()
    }

    // Retry to get 3rd-party function
    if (!isFunctionAvailable) {
      setTimeout(() => get3rdPartyToUpdatedConsent(), 500)
    }
  }, 1000)

  return null
}

export default get3rdPartyToUpdatedConsent
