import Router from 'next/router'
import { segmentSignInViewed, segmentTrackCartViewed } from 'lib/segment'
import {
  SEGMENT_STORAGE_KEY_NAVIGATION,
  SEGMENT_STORAGE_KEY_PROMPT_LOCATION,
} from 'lib/segment/const'
import StateStorage from 'lib/state-storage'
import { getISOCurrencyCodeWithShopID } from 'lib/utils/common/commonUtils'
import { hasLocizeTranslation } from 'lib/utils/locize'
import { segmentTrackCartProductMovedToWishlist } from './segment'
import { SNACKBAR_TYPE } from './cart-snackbar'
import { SNACKBAR_MOVE_TO_WISHLIST_FROM_CART } from './const'

const getSoldOutProducts = (cartProducts) => {
  const data = cartProducts?.items?.reduce((acc, itemProduct) => {
    const item = itemProduct.attributes?.find(
      (attribute) => attribute.selected && attribute.stock?.is_sold_out,
    )
    if (item) {
      acc.push(item)
    }
    return acc
  }, [])
  return data
}

const trackCartViewed = (cartProducts, country, shop, eventType) => {
  const subTotal = cartProducts?.summary?.subtotal || 0
  segmentTrackCartViewed({
    cart_subtotal: subTotal,
    currency: getISOCurrencyCodeWithShopID(country),
    products: cartProducts?.items || [],
    id_shop: shop,
    eventType,
  })
}

const getCartProductUrl = (product) => {
  const { id_product, category, name } = product
  const shop = StateStorage.getShop()
  const language = StateStorage.getLanguage()
  const productHyphenName = name.split(' ').join('-')

  return `/${shop}/${language}/${category.link_rewrite}/${id_product}-${productHyphenName}.html`
}

/**
 * @param {function} closeCart - Function to close the cart.
 * @param {function} updateShowLogin - Function to update the login modal state.
 */
const redirectToWishlistPage = (closeCart, updateShowLogin) => {
  const shop = StateStorage.getShop()
  const language = StateStorage.getLanguage()

  if (StateStorage.isGuestMode()) {
    segmentSignInViewed('bag')
    sessionStorage.setItem(SEGMENT_STORAGE_KEY_PROMPT_LOCATION, 'bag')
    updateShowLogin(true)
  } else {
    sessionStorage.setItem(SEGMENT_STORAGE_KEY_NAVIGATION.wishlist.type, 'bag')
    sessionStorage.removeItem(SEGMENT_STORAGE_KEY_NAVIGATION.wishlist.position)
    closeCart()
    Router.push('/wishlist', `/${shop}/${language}/wishlist`)
  }
}

/**
 * @param {Object} params - The parameters object
 * @param {Function} params.addWishlist - Function to add product to wishlist
 * @param {Function} params.updateWishlist - Function to update wishlist
 * @param {Function} params.invalidateWishlist - Function to invalidate wishlist cache
 * @param {Object} params.product - The product object
 * @param {Function} params.updateSnackbar - Function to update snackbar notification
 * @param {Function} params.t - Translation function
 *
 * @returns {Promise} - Promise resolving to the add wishlist API response
 */
const moveProductToWishlist = async ({
  addWishlist,
  updateWishlist,
  invalidateWishlist,
  product,
  updateSnackbar,
  t,
}) => {
  invalidateWishlist()
  const res = await addWishlist({
    product_id: product.id_product,
    id_product_attribute: product.selectedAttributes.id_product_attribute,
  })

  if (res) {
    invalidateWishlist()
    updateWishlist({ type: 'GET' })
    updateSnackbar(
      hasLocizeTranslation(
        t,
        SNACKBAR_MOVE_TO_WISHLIST_FROM_CART.key,
        SNACKBAR_MOVE_TO_WISHLIST_FROM_CART.text,
      ),
      4000,
      SNACKBAR_TYPE.info,
    )
    segmentTrackCartProductMovedToWishlist(product)
  }
}

export {
  getSoldOutProducts,
  trackCartViewed,
  getCartProductUrl,
  moveProductToWishlist,
  redirectToWishlistPage,
}
