import clsx from 'clsx'
import PropTypes from 'prop-types'
import React, { useEffect, useCallback } from 'react'
import { connect } from 'react-redux'
import Icon from 'components/common/icon'
import ICONS, { ICON_SIZE } from 'components/common/icon/const'
import { Modal } from 'components/portal/modal'
import useModal from 'components/portal/useModal'
import { getEachLineTnc } from 'components/skinny-banner/tnc'
import Typo from 'constants/typography'
import { withI18next } from 'lib/i18n/withI18next'
import { hasLocizeTranslation } from 'lib/utils/locize'
import styles from './style.scss'

const VoucherTermsAndConditions = ({
  isShow,
  isPhone,
  setIsShow,
  t,
  terms,
}) => {
  const [isModalOpen, setIsModalOpen] = useModal(false)
  const contents = getEachLineTnc(terms)

  useEffect(() => {
    setIsModalOpen(isShow)
  }, [isShow, setIsModalOpen])

  const closeModal = useCallback(() => setIsShow(false), [setIsShow])

  return (
    <Modal isOpen={isModalOpen}>
      <style jsx>{styles}</style>
      <div className="voucher-tnc">
        {!isPhone && (
          <div className="voucher-tnc__top-header">
            <Icon
              className="voucher-tnc__close-icon"
              alt="voucher-tnc-close-icon"
              src={ICONS.close}
              size={ICON_SIZE.medium}
              onClick={closeModal}
            />
          </div>
        )}
        <div className="voucher-tnc__wrapper">
          <div className="voucher-tnc__container">
            <div
              className={clsx('voucher-tnc__header', {
                'with-bottom-border': isPhone,
              })}
            >
              {isPhone && (
                <Icon
                  className="voucher-tnc__close-icon"
                  alt="voucher-tnc-close-icon"
                  src={ICONS.close}
                  size={ICON_SIZE.medium}
                  onClick={closeModal}
                />
              )}
              <span
                className={clsx('voucher-tnc__header-title', {
                  [Typo.price1]: isPhone,
                  [Typo.title3]: !isPhone,
                })}
              >
                {hasLocizeTranslation(
                  t,
                  'VOUCHER_TNC_HEADER',
                  'Terms & Conditions',
                )}
              </span>
            </div>
            <div className="voucher-tnc__content">
              <ul className="voucher-tnc__list">
                {contents.map((content, idx) => (
                  <li
                    key={idx}
                    className={clsx(Typo.price2, 'voucher-tnc__list-item')}
                  >
                    {content}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <div className="voucher-tnc__footer" />
      </div>
    </Modal>
  )
}

VoucherTermsAndConditions.propTypes = {
  isShow: PropTypes.bool.isRequired,
  isPhone: PropTypes.bool.isRequired,
  setIsShow: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  terms: PropTypes.string.isRequired,
}

const Extended = withI18next()(VoucherTermsAndConditions)

export default connect((state) => ({
  isPhone: state.device.isPhone,
}))(Extended)
