import clsx from 'clsx'
import PropTypes from 'prop-types'
import React from 'react'
import ContentLoader from 'react-content-loader'
import styles from '../styles.scss'

const CartRecommendationSkeleton = ({ justForYou, isShoppingBagNewDesign }) => (
  <div
    className={clsx('cart__recomm__skeleton', {
      'just-for-you': justForYou,
      'new-design': isShoppingBagNewDesign,
    })}
  >
    <style jsx>{styles}</style>
    {!justForYou && <hr className="cart__recomm__divider" />}
    <ContentLoader
      uniquekey="cart__recomm__content-loader"
      width={100}
      height={90}
      speed={2}
      primaryColor="#f3f3f3"
      secondaryColor="#ecebeb"
    >
      <rect x="25" y="0" width={50} height={5} />
      <rect x="15" y="7.5" width={70} height={5} />
      <rect x="0" y="20" width={40} height={54} />
      <rect x="0" y="76.5" width={20} height={5} />
      <rect x="0" y="84" width={40} height={5} />
      <rect x="42.5" y="20" width={40} height={54} />
      <rect x="42.5" y="76.5" width={20} height={5} />
      <rect x="42.5" y="84" width={40} height={5} />
      <rect x="85" y="20" width={15} height={54} />
      <rect x="85" y="76.5" width={15} height={5} />
      <rect x="85" y="84" width={15} height={5} />
    </ContentLoader>
  </div>
)

CartRecommendationSkeleton.defaultProps = {
  justForYou: false,
  isShoppingBagNewDesign: false,
}

CartRecommendationSkeleton.propTypes = {
  justForYou: PropTypes.bool,
  isShoppingBagNewDesign: PropTypes.bool,
}

export default CartRecommendationSkeleton
