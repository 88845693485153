import { EMAIL_REGEX } from 'components/common/textfield'

export const INPUT_NAME_EMAIL = 'email'
export const INPUT_NAME_PASSWORD = 'password'
export const INPUT_NAME_FIRST = 'firstName'
export const INPUT_NAME_LAST = 'lastName'
export const INPUT_NAME_PHONE = 'phoneNO'

const signupFormLocizes = {
  labels: {
    FORM_PHONE_NO_HELPER: 'We will use phone to authenticate your account',
    FORM_PASSWORD_HELPER: 'Min. 8 char. including letters and numbers',
  },
  errors: {
    FORM_INVALID_EMAIL_FORMAT: 'Invalid email format.',
    FORM_FIRST_NAME_LENGTH_ERROR: 'Maximum 25 Characters Allowed',
    FORM_FIRST_NAME_PATTERN_ERROR: 'Numbers or Special Characters Not Allowed',
    FORM_LAST_NAME_LENGTH_ERROR: 'Maximum 25 Characters Allowed',
    FORM_LAST_NAME_PATTERN_ERROR: 'Numbers or Special Characters Not Allowed',
    FORM_EMAIL_DUPLICATE_ERROR: 'This email already exist',
    FORM_PHONE_DUPLICATE_ERROR: 'This phone already exist',
    FORM_PHONE_NO_PATTERN_ERROR: 'Please use only number',
    FORM_PHONE_NO_LENGTH_ERROR:
      'Your phone number should be within 7 - 15 characters',
    PASSWORD_LENGTH_ERROR: 'Maximum 25 Characters Allowed',
  },
}

export const specialCharAndNumberRegex =
  /[\d!@#$%^&*()_+\-=\\[\]{};':"\\|,.<>\\/?]/

export const noWhiteSpacePrefixSuffix = /^[^\s]+(\s+[^\s]+)*$/

export const formConfig = {
  email: ({ isRequired, requiredMessage, patternErrorMessage }) => ({
    ...(isRequired && { required: requiredMessage }),
    pattern: {
      value: EMAIL_REGEX,
      message: patternErrorMessage,
    },
  }),
  phone: ({
    isRequired,
    requiredMessage,
    patternErrorMessage,
    lengthErrorMessage,
  }) => ({
    ...(isRequired && { required: requiredMessage }),
    pattern: {
      value: /^\d+$/,
      message: patternErrorMessage,
    },
    minLength: {
      value: 7,
      message: lengthErrorMessage,
    },
    maxLength: {
      value: 15,
      message: lengthErrorMessage,
    },
  }),
}

export default signupFormLocizes
