import React from 'react'
import PropTypes from 'prop-types'
import { withI18next } from 'lib/i18n/withI18next'
import styles from './style.scss'

import offlineMessage from './const'

const Offline = ({ t, errorCode }) => {
  const message = offlineMessage[errorCode] || ''
  return (
    <React.Fragment>
      <style jsx>{styles}</style>
      <div className="offline-wrapper">
        <div className="offline-message">
          {t(message)}
          {errorCode === 'noAPI' && (
            <button onClick={window.location.reload}>{t('Reload')}</button>
          )}
        </div>
      </div>
    </React.Fragment>
  )
}

Offline.propTypes = {
  t: PropTypes.func.isRequired,
  errorCode: PropTypes.string,
}

Offline.defaultProps = {
  errorCode: undefined,
}

export default withI18next()(Offline)
