import clsx from 'clsx'
import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import Icon from 'components/common/icon'
import ICONS, { ICON_SIZE } from 'components/common/icon/const'
import { BUTTON_HTML_TYPE } from 'constants/button'
import Typo from 'constants/typography'
import { COACHMARK_POSITION } from './const'
import styles from './style.scss'

function getCoachmarkTopPosition(
  offsetTop,
  offsetHeight,
  coachmarkArrowOffset,
  coachmarkHeight,
  childPosition,
) {
  const { TOP, BOTTOM, LEFT, RIGHT } = COACHMARK_POSITION

  if (childPosition.includes(TOP)) {
    return offsetTop + (offsetHeight + coachmarkArrowOffset)
  }
  if (childPosition.includes(BOTTOM)) {
    return offsetTop - coachmarkHeight - coachmarkArrowOffset
  }
  if ([LEFT, RIGHT].includes(childPosition)) {
    return offsetHeight / 2 - coachmarkHeight / 2 + coachmarkArrowOffset
  }
  return 0
}

function getCoachmarkHorizontalPosition(
  offsetLeft,
  coachmarkWidth,
  offsetWidth,
  coachmarkArrowOffset,
  childPosition,
) {
  const {
    TOP,
    TOP_LEFT,
    TOP_RIGHT,
    BOTTOM,
    BOTTOM_RIGHT,
    BOTTOM_LEFT,
    LEFT,
    RIGHT,
  } = COACHMARK_POSITION

  if ([TOP, BOTTOM].includes(childPosition)) {
    return {
      left: offsetLeft - coachmarkWidth / 2 + offsetWidth / 2,
    }
  }
  if ([TOP_LEFT, BOTTOM_LEFT].includes(childPosition)) {
    return {
      left: offsetLeft - coachmarkArrowOffset * 3 + offsetWidth / 2,
    }
  }
  if ([TOP_RIGHT, BOTTOM_RIGHT].includes(childPosition)) {
    return {
      right: offsetLeft - coachmarkArrowOffset * 3 + offsetWidth / 2,
    }
  }
  if ([LEFT, RIGHT].includes(childPosition)) {
    return {
      [childPosition]: offsetLeft + offsetWidth + coachmarkArrowOffset,
    }
  }

  return {}
}

/**
 *
 * @param {element}  children             - the element that coachmark relate to. It will calculate position from any element sent via children prop
 * @param {string}   className            - the className of coachmark element
 * @param {node}     closeBtn             - the close button element
 * @param {string}   customChildrenRef    - the custom ref for children that coach mark will be attatched to (required if you aren't passing children prop)
 * @param {string}   customContainerProps - the container properties you want to customize your coach mark container element include className and style
 * @param {bool}     disableEnterListener - flag to disable child's mouse enter event.
 * @param {bool}     disableLeaveListener - flag to disable child's mouse leave event.
 * @param {bool}     isOpen               - flag to control coachmark to be open or not.
 * @param {function} onClose              - callback event when coachmark is close.
 * @param {string}   position             - position of coachmark ( using COACHMARK_POSITION constant as value )
 * @param {string}   text                 - text that display inside coachmark.
 * @param {string}   wrapperClassName     - the className of children wrapper element
 */
const Coachmark = ({
  children,
  className,
  closeBtn,
  customChildrenRef,
  customContainerProps,
  disableEnterListener,
  disableLeaveListener,
  isOpen,
  onClose,
  position,
  text,
  wrapperClassName,
}) => {
  const [childrenRef, setChildrenRef] = useState(null)
  const [isInit, setIsInit] = useState(false)
  const [show, setShow] = useState(isOpen)
  const [coachmarkRef, setCoachmarkRef] = useState(null)

  const handleMouseEnter = useCallback(() => {
    if (!disableEnterListener) {
      setShow(true)
    }
  }, [disableEnterListener])

  const handleClose = useCallback(() => {
    setShow(false)
    if (onClose) {
      onClose()
    }
  }, [onClose])

  const handleMouseLeave = useCallback(() => {
    if (!disableLeaveListener) {
      handleClose()
    }
  }, [disableLeaveListener, handleClose])

  const coachmarkPosition = useMemo(() => {
    if (isInit && childrenRef) {
      const coachmarkWidth = coachmarkRef?.offsetWidth || 0
      const coachmarkHeight = coachmarkRef?.offsetHeight || 0
      const coachmarkArrowOffset = 8
      const { offsetTop, offsetHeight, offsetLeft, offsetWidth } = childrenRef

      const topPosition = getCoachmarkTopPosition(
        offsetTop,
        offsetHeight,
        coachmarkArrowOffset,
        coachmarkHeight,
        position,
      )

      const horizontalPosition = getCoachmarkHorizontalPosition(
        offsetLeft,
        coachmarkWidth,
        offsetWidth,
        coachmarkArrowOffset,
        position,
      )

      return {
        top: topPosition,
        ...horizontalPosition,
      }
    }
    return null
  }, [position, isInit, coachmarkRef, childrenRef])

  useEffect(() => {
    let timeout
    if (childrenRef) {
      // ?Note: work after all element is ready and really rendered.
      timeout = setTimeout(() => {
        setIsInit(true)
      })
    }
    return () => {
      clearTimeout(timeout)
    }
  }, [childrenRef])

  useEffect(() => {
    setShow(isOpen)

    if (!children) {
      setChildrenRef(customChildrenRef)
    }
  }, [children, customChildrenRef, isOpen])

  return (
    <div
      className={clsx('coachmark__wrapper', className)}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className={wrapperClassName} ref={setChildrenRef}>
        {children}
      </div>
      {show && isInit && (
        <div
          ref={setCoachmarkRef}
          className={clsx(
            'coachmark__container',
            position,
            customContainerProps?.className,
          )}
          style={{
            ...coachmarkPosition,
            ...customContainerProps?.style,
          }}
        >
          <style jsx>{styles}</style>

          <div className="coachmark__text__wrapper">
            <span className={Typo.caption}>{text}</span>
          </div>
          <button
            onClick={handleClose}
            className="coachmark__close-btn webview-cta"
            type={BUTTON_HTML_TYPE.button}
            data-cy="coachmark__close-btn"
          >
            {closeBtn || (
              <Icon
                alt="close-coachmark"
                src={ICONS.close}
                size={ICON_SIZE.small}
              />
            )}
          </button>
        </div>
      )}
    </div>
  )
}

Coachmark.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.bool]),
  className: PropTypes.string,
  closeBtn: PropTypes.node,
  customChildrenRef: PropTypes.shape({
    current: PropTypes.shape({}),
  }),
  customContainerProps: PropTypes.shape({
    className: PropTypes.string,
    style: PropTypes.shape({}),
  }),
  disableEnterListener: PropTypes.bool,
  disableLeaveListener: PropTypes.bool,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  position: PropTypes.oneOfType([
    PropTypes.oneOf(Object.values(COACHMARK_POSITION)),
    PropTypes.string,
  ]),
  text: PropTypes.string,
  wrapperClassName: PropTypes.string,
}

Coachmark.defaultProps = {
  children: false,
  className: '',
  closeBtn: undefined,
  customContainerProps: {},
  customChildrenRef: null,
  disableEnterListener: false,
  disableLeaveListener: false,
  isOpen: false,
  onClose: undefined,
  position: COACHMARK_POSITION.TOP,
  text: '',
  wrapperClassName: '',
}

export default Coachmark
