import clsx from 'clsx'
import PropTypes from 'prop-types'
import React, { useCallback } from 'react'
import useModal from 'components/portal/useModal'
import CreditInformationWrapper from 'components/shared/credit-information/credit-information-wrapper'

import compose from 'recompose/compose'
import { withI18next } from 'lib/i18n/withI18next'
import { connect } from 'react-redux'
import CartCreditBody from './cart-credit-body'

const CartCredits = ({
  // component props
  isPhone,
  isFromCheckoutSummary,
  availableCredits,
  handleStoreCredit,
  resetStoreCredit,
}) => {
  const [isModalOpen, setIsModalOpen] = useModal(false)
  const openModal = useCallback(() => setIsModalOpen(true), [setIsModalOpen])
  const closeModal = useCallback(() => setIsModalOpen(false), [setIsModalOpen])

  if (!availableCredits) {
    return null
  }

  const {
    applied,
    available_formatted,
    remaining_formatted,
    to_apply_formatted,
  } = availableCredits

  return (
    <React.Fragment>
      <div
        className={clsx('cart-discount__main', 'is-credit', {
          'is-applied': applied,
          'is-checkout-summary': isFromCheckoutSummary,
        })}
      >
        <CartCreditBody
          isApplied={applied}
          isFromCheckoutSummary={isFromCheckoutSummary}
          available_formatted={available_formatted}
          to_apply_formatted={to_apply_formatted}
          remaining_formatted={remaining_formatted}
          openModal={openModal}
          handleStoreCredit={handleStoreCredit}
          resetStoreCredit={resetStoreCredit}
        />
      </div>
      <CreditInformationWrapper
        isPhone={isPhone}
        isShow={isModalOpen}
        setIsShow={closeModal}
        zIndex={100003}
      />
    </React.Fragment>
  )
}

CartCredits.defaultProps = {
  isPhone: false,
  isFromCheckoutSummary: false,
  availableCredits: null,
}

CartCredits.propTypes = {
  // component props
  isPhone: PropTypes.bool,
  isFromCheckoutSummary: PropTypes.bool,
  availableCredits: PropTypes.shape({
    applied: PropTypes.number,
    applied_formatted: PropTypes.string,
    available: PropTypes.number,
    available_formatted: PropTypes.string,
    loyalty: PropTypes.number,
    loyalty_formatted: PropTypes.string,
    remaining: PropTypes.number,
    remaining_formatted: PropTypes.string,
    to_apply: PropTypes.number,
    to_apply_formatted: PropTypes.string,
  }),
  handleStoreCredit: PropTypes.func.isRequired,
  resetStoreCredit: PropTypes.func.isRequired,

  // redux props
  checkoutModel: PropTypes.shape({
    isTTBDepositFlagEnable: PropTypes.bool,
    paymentMethod: PropTypes.shape({
      isTTBFeePayment: PropTypes.bool,
    }),
    shippingAddress: PropTypes.shape({
      type: PropTypes.string,
    }),
  }).isRequired,
}

export default compose(
  connect((state) => ({
    checkoutModel: state.checkout,
  })),
  withI18next(),
)(CartCredits)
