import { request } from 'lib/api'

async function get() {
  try {
    const res = await request({
      baseURL: '/',
      url: 'healthcheck',
    })
    return res
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('err', err)
    return null
  }
}

export default {
  get,
}
