import clsx from 'clsx'
import PropTypes from 'prop-types'
import React, { useState, useCallback } from 'react'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { bindActionCreators } from 'redux'
import cartDuck from 'components/cart/duck'
import Icon, { ICON_SIZE } from 'components/common/icon'
import ICONS from 'components/common/icon/const'
import Loader from 'components/loader'
import useAddToBag from 'components/shared/useRedux/useAddToBag'
import Typo from 'constants/typography'
import ProductAPiHandler from 'lib/api/product'
import { withI18next } from 'lib/i18n/withI18next'
import StateStorage from 'lib/state-storage'
import { isSoldOut } from 'lib/utils/product'
import Button from 'components/common/button'
import { BUTTON_TYPES } from 'constants/button'
import Radio from 'components/common/radio'
import BackdropModal from 'components/portal/backdrop-modal'
import { hasLocizeTranslation } from 'lib/utils/locize'

const addToBag = async ({
  productId,
  setIsLoading,
  sizeData,
  addToBagAction,
  refData,
  setIsModalOpen,
}) => {
  try {
    setIsLoading(true)

    // NOTE: This product details section is copied from ListView add to bag, maybe can create as a reuseable function
    const res = await ProductAPiHandler.getProduct({
      product_id: productId,
      isGuestMode: StateStorage.isGuestMode(),
    })

    if (res) {
      // Do nothing if sold out at this time, interstitial will catch this.
      // const isSoldOut = res.stock?.is_sold_out || res.stock?.quantity === 0

      addToBagAction({
        enableInterstitial: false,
        onError: () => setIsLoading(false),
        onSuccess: () => setIsLoading(false),
        product: res,
        sizeData,
        refData,
      })
    } else {
      setIsLoading(false)
    }
  } catch (err) {
    setIsLoading(false)
  } finally {
    setIsModalOpen(false)
  }
}

const getArrow = (onClick, iconName, pageNum) => (
  <Icon
    className="option-item"
    onClick={(e) => onClick(e, pageNum)}
    src={ICONS[iconName]}
    size={ICON_SIZE.small}
  />
)

const HoverInteractionsAddToBag = ({
  isPhone,
  addToBagAction,
  options,
  productId,
  refData,
  t,
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [pageNo, setPageNo] = useState(1)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const openModal = useCallback(() => setIsModalOpen(true), [setIsModalOpen])
  const closeModal = useCallback(() => setIsModalOpen(false), [setIsModalOpen])

  const callSetPageNo = (e, no) => {
    if (e?.preventDefault && e?.stopPropagation) {
      e.preventDefault()
      e.stopPropagation()
    }

    setPageNo(no)
  }

  const callAddToBag = (e, sizeData, soldOut) => {
    if (e?.preventDefault && e?.stopPropagation) {
      e.preventDefault()
      e.stopPropagation()
    }

    const isOneSize = options?.length === 1

    if (isLoading || soldOut || (!sizeData && !isOneSize)) {
      return
    }

    addToBag({
      productId,
      setIsLoading,
      sizeData: isOneSize ? options[0] : sizeData,
      addToBagAction,
      refData,
      setIsModalOpen,
    })
  }

  const ctaKey = 'Add To Bag'
  // NOTE: Came up with this logic because couldn't find products with many options + long text
  const hasLargeWidthOption = options?.length > 0 && options[0].size?.length > 3
  const showNextThreshold = hasLargeWidthOption ? 2 : 4

  const isTablet =
    typeof window !== 'undefined' &&
    window.innerWidth >= 300 &&
    window.innerWidth <= 1024

  if (isPhone || isTablet) {
    return (
      <React.Fragment>
        <Button
          onClick={openModal}
          type={BUTTON_TYPES.text}
          cy="product-item-quick-add-to-bag-modal__button"
          className="product-item-quick-add-to-bag-modal__button"
        >
          {hasLocizeTranslation(t, 'ADD_TO_BAG', 'ADD TO BAG')}
        </Button>
        <BackdropModal
          zIndex={2000}
          freezeBody={isPhone}
          isOpen={isModalOpen}
          onBackdropClick={closeModal}
        >
          <div className="product-item-quick-add-to-bag-modal__wrapper">
            <span
              className={clsx(
                Typo.subtitle1,
                'product-item-quick-add-to-bag-modal__title',
              )}
            >
              {t('Choose your size')}
            </span>
            <hr className="product-item-quick-add-to-bag-modal__line" />
            {isLoading ? (
              <div className="loader-container">
                <Loader />
              </div>
            ) : (
              options?.map((sizeData) => (
                <div
                  key={sizeData?.id_product_attribute}
                  className="product-item-quick-add-to-bag-modal__content"
                >
                  <button
                    data-cy={`product-item-quick-add-to-bag-modal__attr-${sizeData?.size}`}
                    className={clsx(
                      'product-item-quick-add-to-bag-modal__attr',
                      {
                        'out-of-stock': sizeData?.stock?.is_sold_out,
                      },
                    )}
                    type="button"
                    disabled={sizeData?.stock?.is_sold_out}
                    onClick={(e) =>
                      callAddToBag(e, sizeData, sizeData?.stock?.is_sold_out)
                    }
                  >
                    <Radio className="product-item-quick-add-to-bag-modal__circle" />
                    <span
                      className={clsx(
                        Typo.body2,
                        'product-item-quick-add-to-bag-modal__label-body',
                      )}
                    >
                      {sizeData?.size}
                    </span>
                  </button>
                </div>
              ))
            )}
          </div>
        </BackdropModal>
      </React.Fragment>
    )
  }

  return (
    <div
      className="product-hover-interactions__add-to-bag"
      onClick={callAddToBag}
      onKeyUp={callAddToBag}
      role="button"
      tabIndex={0}
    >
      {isLoading ? (
        <Loader small />
      ) : (
        <React.Fragment>
          {options?.length > 1 && (
            <div className="option-items-wrapper">
              {pageNo === 2 && getArrow(callSetPageNo, 'arrowHeadLeft', 1)}
              {options.map((option, idx) => {
                if (
                  (pageNo === 1 && idx > showNextThreshold - 1) ||
                  (pageNo === 2 && idx < showNextThreshold)
                ) {
                  return null
                }

                const soldOut = isSoldOut(option)

                return (
                  <button
                    className={clsx('option-item', Typo.button, {
                      'sold-out': soldOut,
                    })}
                    key={option.size}
                    onClick={(e) => callAddToBag(e, option, soldOut)}
                    style={{ width: hasLargeWidthOption ? '40%' : '20%' }}
                  >
                    {option.size}
                  </button>
                )
              })}
              {pageNo === 1 &&
                options.length > showNextThreshold &&
                getArrow(callSetPageNo, 'arrowHeadRight', 2)}
            </div>
          )}
          <span className={Typo.button}>{t(ctaKey)}</span>
        </React.Fragment>
      )}
    </div>
  )
}

HoverInteractionsAddToBag.defaultProps = {
  options: null,
  refData: null,
}

HoverInteractionsAddToBag.propTypes = {
  isPhone: PropTypes.bool.isRequired,
  addToBagAction: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      size: PropTypes.string,
    }),
  ),
  productId: PropTypes.number.isRequired,
  refData: PropTypes.shape({}),
  t: PropTypes.func.isRequired,
}

export default compose(
  connect(
    (state) => ({
      isPhone: state.device.isPhone,
    }),
    (dispatch) =>
      bindActionCreators(
        {
          addToBagAction: useAddToBag,
          getCart: cartDuck.creators.get,
        },
        dispatch,
      ),
  ),
  withI18next(),
)(HoverInteractionsAddToBag)
