import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import ProductsAPiHandler from 'lib/api/products'
import ICONS, { ICON_SIZE } from 'components/common/icon/const'
import Icon from 'components/common/icon'
import ProductItemSwatch from './swatch'

const handleClick = (e, wrapperEl, setShowFullSwatches, selfRef) => {
  if (!wrapperEl?.current?.contains(e?.target)) {
    setShowFullSwatches(false)

    document.removeEventListener('click', selfRef, true)
  }
}

const triggerFullSwatch = async ({
  e,
  fromPlusIcon,
  fullSwatches,
  handleClickRef,
  isPhone,
  parentId,
  productId,
  setFullSwatches,
  setSelectedSwatch,
  setShowFullSwatches,
  triggerSwatchModal,
}) => {
  if (isPhone && fromPlusIcon) {
    triggerSwatchModal()

    return
  }

  if (e?.preventDefault && e?.stopPropagation) {
    e.preventDefault()
    e.stopPropagation()
  }

  const selectedSwatch = fullSwatches?.find(
    (swatch) => swatch.id_product === productId,
  )
  const isDefaultSwatch =
    selectedSwatch && parentId === selectedSwatch.id_product

  if (isPhone || (!isPhone && !fromPlusIcon)) {
    setSelectedSwatch(isDefaultSwatch ? null : selectedSwatch)
  }

  setShowFullSwatches(true)

  document.addEventListener('click', handleClickRef, true)

  if (!fullSwatches) {
    const res = await ProductsAPiHandler.getProductAlternatives(parentId, true)

    if (res) {
      setFullSwatches(res.alternatives)
      setSelectedSwatch(
        res.alternatives?.find((swatch) => swatch.id_product === productId),
      )
    }
  }
}

const ProductItemSwatches = ({
  fullSwatches,
  isPhone,
  parentId,
  productId,
  selectedSwatchId,
  setFullSwatches,
  setSelectedSwatch,
  swatches,
  total,
  triggerSwatchModal,
  wrapperEl,
}) => {
  const [showFullSwatches, setShowFullSwatches] = useState(false)
  const emptyJsx = <div className="product_color-variation" />

  const handleClickRef = (e) => {
    handleClick(e, wrapperEl, setShowFullSwatches, handleClickRef)
  }

  const callTriggerFullSwatch = (e, triggerSwatchProductId, fromPlusIcon) => {
    triggerFullSwatch({
      e,
      fromPlusIcon,
      fullSwatches,
      handleClickRef,
      isPhone,
      parentId,
      productId: triggerSwatchProductId,
      setFullSwatches,
      setSelectedSwatch,
      setShowFullSwatches,
      triggerSwatchModal,
    })
  }

  useEffect(
    () => () => {
      document.removeEventListener('click', handleClickRef, true)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  if (swatches?.length) {
    const swatchesToRender =
      showFullSwatches && fullSwatches?.map ? fullSwatches : swatches
    const showPlusIcon = showFullSwatches ? isPhone && total > 4 : total > 4
    return (
      <div className="product_color-variation">
        {swatchesToRender.map((data, idx) => (
          <ProductItemSwatch
            callTriggerFullSwatch={callTriggerFullSwatch}
            data={data}
            fullSwatches={fullSwatches}
            idx={idx}
            isPhone={isPhone}
            key={data.id_product}
            parentId={parentId}
            productId={productId}
            selectedSwatchId={selectedSwatchId}
            setSelectedSwatch={setSelectedSwatch}
            showFullSwatches={showFullSwatches}
          />
        ))}
        {showPlusIcon && (
          <div className="product_color-variation__plus__wrapper">
            <Icon
              onClick={(e) => callTriggerFullSwatch(e, productId, true)}
              src={ICONS.plus}
              size={ICON_SIZE.small}
              alt="expand swatch"
            />
          </div>
        )}
      </div>
    )
  }

  return emptyJsx
}

ProductItemSwatches.defaultProps = {
  fullSwatches: null,
  productId: undefined,
  selectedSwatchId: 0,
  swatches: null,
}

ProductItemSwatches.propTypes = {
  isPhone: PropTypes.bool.isRequired,
  fullSwatches: PropTypes.arrayOf(PropTypes.shape({})),
  parentId: PropTypes.number.isRequired,
  productId: PropTypes.number,
  selectedSwatchId: PropTypes.number,
  setFullSwatches: PropTypes.func.isRequired,
  setSelectedSwatch: PropTypes.func.isRequired,
  swatches: PropTypes.arrayOf(PropTypes.shape({})),
  total: PropTypes.number.isRequired,
  triggerSwatchModal: PropTypes.func.isRequired,
  wrapperEl: PropTypes.shape({}).isRequired,
}

export { ProductItemSwatches }

export default connect((state) => ({ isPhone: state.device.isPhone }))(
  ProductItemSwatches,
)
