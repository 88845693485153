import { useState, useEffect, useRef } from 'react'

const useGoogleRecaptcha = () => {
  const [isVerifiedRecaptcha, setIsVerifiedRecaptcha] = useState(false)
  const timeoutRef = useRef(null)

  const handleVerifyRecaptcha = async (token) => {
    if (!token) return

    const response = await fetch('/server/verify-recaptcha', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ captcha: token }),
    })

    const result = await response.json()

    if (result.success) {
      setIsVerifiedRecaptcha(true)

      if (timeoutRef.current) clearTimeout(timeoutRef.current)

      timeoutRef.current = setTimeout(() => {
        setIsVerifiedRecaptcha(false)
      }, 60000)
    } else {
      setIsVerifiedRecaptcha(false)
    }
  }

  useEffect(
    () => () => {
      if (timeoutRef.current) clearTimeout(timeoutRef.current)
    },
    [],
  )

  return {
    isVerifiedRecaptcha,
    handleVerifyRecaptcha,
  }
}

export default useGoogleRecaptcha
