const REDIRECT_STATUS_CODE = 302

export const redirectToHomePage = (res, customCode) => {
  if (res?.writeHead) {
    res.writeHead(customCode || REDIRECT_STATUS_CODE, { Location: '/' })
  }
}

export const isAuthCookiesAvailable = (auth) => auth && auth.user

export const getProductIDs = (products = [], isCriteo = false) => {
  let passedProducts = products
  let productIds

  if (isCriteo) {
    passedProducts = passedProducts.slice(0, 5)

    productIds = passedProducts.map((product) => {
      const tmpId = product.id_product || product.id_product_2

      return tmpId ? tmpId.toString() : tmpId
    })
  } else {
    productIds = passedProducts.map((product) => {
      const tmpId = product.id_product || product.id_product_2
      return tmpId
    })
  }
  return productIds
}
