import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { bindActionCreators } from 'redux'
import TextField from 'components/common/textfield'
import Icon from 'components/common/icon'
import ICONS, { ICON_SIZE } from 'components/common/icon/const'
import searchModalDuck from 'components/search/search-section/duck'
import Typo from 'constants/typography'
import { withI18next } from 'lib/i18n/withI18next'
import { goToSearchPage, storeTheSearchWord } from '../utils'
import styles from './style.scss'

export const HEADER_SEARCH_FORM_ID = 'header-search-form'

const SearchForm = ({
  alwaysShowClose,
  placeholder,
  searchWord,
  setSearchWord,
  t,
  toggleSearchModal,
}) => {
  const searchBoxPlaceholder =
    placeholder || t('What are you looking for today?...')
  const shouldShowCloseCTA = searchWord || alwaysShowClose

  const search = (e) => {
    e?.preventDefault()

    // Do searching for a non-empty field.
    if (searchWord) {
      storeTheSearchWord(searchWord, setSearchWord)
      goToSearchPage(searchWord, toggleSearchModal)
    }
  }

  return (
    <form
      className="search-form-container flex-form"
      id={HEADER_SEARCH_FORM_ID}
      onSubmit={search}
    >
      <style jsx>{styles}</style>
      <TextField
        fullWidth
        onChange={(e) => e && setSearchWord(e.target.value)}
        value={searchWord}
        placeholder={searchBoxPlaceholder}
        prefix={<Icon src={ICONS.search} size={ICON_SIZE.medium} />}
        postfix={
          shouldShowCloseCTA ? (
            <Icon
              src={ICONS.close}
              size={ICON_SIZE.small}
              onClick={toggleSearchModal}
            />
          ) : undefined
        }
        typo={Typo.body2}
      />
    </form>
  )
}

SearchForm.propTypes = {
  alwaysShowClose: PropTypes.bool,
  placeholder: PropTypes.string,
  searchWord: PropTypes.string,
  setSearchWord: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  toggleSearchModal: PropTypes.func.isRequired,
}

SearchForm.defaultProps = {
  alwaysShowClose: false,
  placeholder: '',
  searchWord: '',
}

export default compose(
  connect(
    () => ({}),
    (dispatch) =>
      bindActionCreators(
        {
          toggleSearchModal: searchModalDuck.creators.toggleSearchModal,
        },
        dispatch,
      ),
  ),
  withI18next(),
)(SearchForm)
