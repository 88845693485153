import axios from 'axios'
import env from 'config/env'
import { PHONE } from 'constants/devices'
import { renewToken } from 'lib/auth' // eslint-disable-line import/no-cycle
import {
  HTTP_STATUS_CODES,
  syncShopAndLangWithUrl,
  isStringValueTruthy,
} from 'lib/utils/common/commonUtils'
import { isObject } from 'lib/utils/common/object'
import shopLanguages from 'config/shop-language.json'
import StateStorage from '../state-storage'
import {
  API_HEADER_ATTRIBUTE_KEY,
  ERROR_CODES,
  HTTP_METHODS,
  STATUS_CODES,
} from './const'

// default 60 mins lifespan
const setApiHeaderData = (key, data, lifeSpan = 3600) => {
  const apiCacheCtrl = StateStorage.getLocalState(key)

  if (!apiCacheCtrl) {
    StateStorage.saveLocalState(key, data, lifeSpan)
  }
}

/**
 * Use this function to get the Accept-Language header for API requests
 *
 * For client side the params are optional, fallback is the data in the StateStorage
 *
 * TODO: Add validation for **shop** and **lang**.
 * @param {*} shop mandatory for ssr. eg. 'th', 'id', 'my'
 * @param {*} lang mandatory for ssr. eg. 'th', 'en', 'id'
 */
export function getAcceptLanguage(shop, lang) {
  try {
    syncShopAndLangWithUrl('sync')

    let foundShop
    if (typeof shop === 'number') {
      const filteredShopById = shopLanguages.configs.filter(
        (shopLang) => shopLang.id_shop === shop,
      )
      if (filteredShopById.length > 0) {
        foundShop = filteredShopById[0].slug
      }
    }

    let parsedShop = foundShop || shop || StateStorage.getShop() || 'th'
    const parsedLang = lang || StateStorage.getLanguage() || 'en'

    if (parsedShop === 'mo') {
      parsedShop = 'hk'
    }

    return parsedShop === parsedLang
      ? parsedShop
      : `${parsedLang}-${parsedShop}`
  } catch (e) {
    return shop === lang ? shop : `${lang}-${shop}`
  }
}

export function generateAuthorizationHeader({ identityId, parsedToken }) {
  // check identityId first
  if (isStringValueTruthy(identityId))
    return {
      Authorization: `identity ${identityId}`,
    }

  // check bearer token after as default
  if (isStringValueTruthy(parsedToken))
    return {
      Authorization: `bearer ${parsedToken}`,
    }

  return {}
}

export function generateContentType({
  multipart = null,
  pdf = null,
  awz = null,
}) {
  if (multipart) return 'x-www-form-urlencoded; charset=utf-8'
  if (pdf) return 'pdf'
  if (awz) return 'x-amz-json-1.1'
  return 'json' // default as json
}

/**
 * Use this function to get the header in the format that aligned with the API
 *
 * for **client side**, most these data are filled by default by StateStorage
 *
 * for **server side**, all data must be passed since the StateStorage data is not available
 * @param {*} param0
 */
export function getHeaders({
  awz,
  apiKey,
  device,
  shop,
  lang,
  multipart,
  pdf,
  referer,
  segmentAnonymousID,
  token,
  upload,
  userUId,
  withAuthorization,
  xHeaders,
  withCacheControl = true,
}) {
  const xPlatform = device || StateStorage.getDevice()
  const xUUID = userUId || StateStorage.getUserUID()
  const xAnonymousID =
    segmentAnonymousID || StateStorage.getSegmentAnonymousId()
  const parsedToken = token || StateStorage.getAuthToken()
  const identityId = StateStorage.getIdentityId()
  const contentType = generateContentType({ multipart, pdf, awz })

  const authorizationHeader = {
    ...generateAuthorizationHeader({ identityId, parsedToken }),
  }
  const apiCacheCtrl = StateStorage.getLocalState(
    API_HEADER_ATTRIBUTE_KEY['cache-control'],
  )

  return {
    Accept: 'application/json',
    'Accept-Language': getAcceptLanguage(shop, lang),
    ...(!upload && { 'Content-Type': `application/${contentType}` }),
    ...(!apiKey && withAuthorization && authorizationHeader),
    ...(Boolean(xUUID) && { 'x-uuid': xUUID }),
    ...(Boolean(xPlatform) && {
      'x-platform': `web-${xPlatform === PHONE ? 'mobile' : 'desktop'} ${
        env.VERSION
      }`,
    }),
    ...(Boolean(xAnonymousID) && { 'x-anonymous-id': xAnonymousID }),
    ...(referer && { referer }),
    ...(Boolean(apiKey) && { 'x-api-key': apiKey }),
    ...(Boolean(xHeaders) && { ...xHeaders }),
    ...(withCacheControl &&
      Boolean(apiCacheCtrl) && { 'Cache-Control': apiCacheCtrl }),
  }
}

export async function request({
  apiKey,
  awz,
  baseURL = env.API_HOST,
  body = null, // eg. for POST
  device = '',
  forceRenewToken,
  includeServerTime = false,
  isGuestMode = StateStorage.isGuestMode(),
  lang = '',
  method = HTTP_METHODS.get,
  multipart = false,
  params = undefined, // eg. for GET
  pdf = false,
  referer,
  responseType = 'json',
  segmentAnonymousID = '',
  shop = '',
  stringify = false,
  timeout = env.API_REQUEST_TIMEOUT,
  token = '',
  upload = false,
  url = '',
  userUID = '',
  withAuthorization = true,
  xHeaders,
  header,
  signal,
  withCacheControl = true,
}) {
  // TODO: find all component test cases that not handle promise then next line could be removed
  const newToken =
    withAuthorization && forceRenewToken ? await renewToken() : token
  const headers =
    header ||
    getHeaders({
      awz,
      apiKey,
      device,
      isGuestMode,
      lang,
      multipart,
      pdf,
      referer,
      segmentAnonymousID,
      shop,
      token: newToken,
      upload,
      userUID,
      withAuthorization,
      xHeaders,
      withCacheControl,
    })

  return axios({
    baseURL,
    method,
    url,
    responseType,
    headers,
    signal,
    timeout,
    ...(Boolean(body) && { data: stringify ? JSON.stringify(body) : body }),
    ...(Boolean(params) && { params }),
  })
    .then((res) => {
      const { data, headers: resHeader } = res

      // set response api header cache-control option
      if (resHeader?.['cache-control']) {
        setApiHeaderData(
          API_HEADER_ATTRIBUTE_KEY['cache-control'],
          resHeader['cache-control'],
        )
      }

      if (!isObject(data)) {
        return data
      }
      if (data.statusCode && data.statusCode !== HTTP_STATUS_CODES.OK) {
        throw Error(data.message)
      }
      if (includeServerTime) {
        return {
          ...data,
          server_time: res.headers.date,
        }
      }

      return data
    })
    .catch(async (err) => {
      if (!err.response || err.code === ERROR_CODES.timeout) {
        throw err
      }

      // NOTE: Logic from the old request's saveExpiredToken(), it has no effect on SSR.
      if (err.response?.status === STATUS_CODES.unauthorized) {
        if (!forceRenewToken) {
          return request({
            ...err.config,
            ...(method === HTTP_METHODS.post && { body }),
            forceRenewToken: true,
            // token: await renewToken(), // enable after test fixed
          })
        }

        StateStorage.setCookie('isTokenExpired', true)
        // TODO: should update redux to handle 401 and display to user due to
        // all request didn't handle this type of error format
        throw Error('Unauthorized Access')
      }

      throw err.response.data
    })
}

export function requestWithCache(url, options) {
  let parsedOptions = {}

  if (options) {
    try {
      parsedOptions = JSON.parse(options)
    } catch (e) {
      // Silently failed
    }
  }

  return request({ url, ...parsedOptions })
}

/**
 * @typedef {object} RequestConfig
 * @property {string} baseURL
 * @property {string} url
 * @property {string} method
 * @property {object} body
 * @property {bool} withAuthorization
 */
