import clsx from 'clsx'
import PropTypes from 'prop-types'
import Typo from 'constants/typography'
import { withI18next } from 'lib/i18n/withI18next'
import { hasLocizeTranslation } from 'lib/utils/locize'
import { VOUCHER_CHECKOUT_LOCIZE } from 'components/pdp/carousel-images/voucher/voucher-checkout/const'
import styles from './styles.scss'

const VoucherBadge = ({
  isHotDeal,
  isLogoShow,
  logoUrl,
  text,
  isNotApplicable,
  t,
}) => {
  const badgeClassName = isHotDeal ? 'hot-deal' : 'custom-badge'
  const badgeText = isHotDeal
    ? hasLocizeTranslation(
        t,
        VOUCHER_CHECKOUT_LOCIZE.VOUCHER_BADGE_HOT_DEAL.key,
        VOUCHER_CHECKOUT_LOCIZE.VOUCHER_BADGE_HOT_DEAL.text,
      )
    : text
  return (
    <div
      className={clsx('voucher-badge', {
        [badgeClassName]: !isLogoShow,
        'logo-badge': isLogoShow,
      })}
    >
      <style jsx>{styles}</style>
      {isLogoShow ? (
        <img
          className={clsx({ 'non-applicable': isNotApplicable })}
          alt="Partner Logo Badge"
          src={logoUrl}
          height={18}
        />
      ) : (
        <span className={Typo.overline}>{badgeText}</span>
      )}
    </div>
  )
}

VoucherBadge.defaultProps = {
  logoUrl: '',
}

VoucherBadge.propTypes = {
  isHotDeal: PropTypes.bool.isRequired,
  isLogoShow: PropTypes.bool.isRequired,
  logoUrl: PropTypes.string,
  text: PropTypes.string.isRequired,
  isNotApplicable: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
}

export default withI18next()(VoucherBadge)
