import Duck from 'extensible-duck'

const duck = new Duck({
  namespace: 'detection',
  store: 'device',
  types: ['SET'],
  initialState: {
    cleanedPath: '',
    isDesktop: false,
    isPhone: false,
    originPath: '',
    pathPrefix: '',
  },
  reducer: (state, action, rDuck) => {
    const { cleanedPath, isDesktop, isPhone, originPath, pathPrefix } = action

    switch (action.type) {
      case rDuck.types.SET:
        return {
          ...state,
          cleanedPath,
          isDesktop,
          isPhone,
          originPath,
          pathPrefix,
        }
      default:
        return state
    }
  },
  creators: (cDuck) => ({
    set: ({ cleanedPath, isDesktop, isPhone, originPath, pathPrefix }) => ({
      type: cDuck.types.SET,
      cleanedPath,
      isDesktop,
      isPhone,
      originPath,
      pathPrefix,
    }),
  }),
})

export default duck
