import ICONS from 'components/common/icon/const'
import { SHOP_COUNTRY_ISO_CODES } from 'constants/shop-language'

export const maskCardNumber = (numberToMask) =>
  numberToMask && numberToMask.padStart(8, '****')

// Syncing is a problem. Should be fetched from api
const countryCurrencyMap = [
  {
    countryCode: SHOP_COUNTRY_ISO_CODES.TH,
    currencyId: 1,
    shopId: 1,
  },
  {
    countryCode: SHOP_COUNTRY_ISO_CODES.SG,
    currencyId: 3,
    shopId: 2,
  },
  {
    countryCode: SHOP_COUNTRY_ISO_CODES.ID,
    currencyId: 7,
    shopId: 5,
  },
  {
    countryCode: SHOP_COUNTRY_ISO_CODES.US,
    currencyId: 5,
    shopId: 4,
  },
  {
    countryCode: SHOP_COUNTRY_ISO_CODES.GLOBAL,
    currencyId: 5,
    shopId: 4,
  },
  {
    countryCode: SHOP_COUNTRY_ISO_CODES.MY,
    currencyId: 10,
    shopId: 11,
  },
  {
    countryCode: SHOP_COUNTRY_ISO_CODES.AU,
    currencyId: 6,
    shopId: 10,
  },
  {
    countryCode: SHOP_COUNTRY_ISO_CODES.KH,
    currencyId: 5,
    shopId: 15,
  },
]

const COUNTRY_CODE_CURRENCY_SYMBOL = {
  th: '฿',
  sg: 'SGD',
  id: 'IDR',
  us: '$',
  au: '$',
  my: 'RM',
  ph: 'PHP',
  hk: 'HKD',
  mo: 'HKD',
  kh: '$',
  global: '$',
}

export const getCurrencyIdByCountry = (countryCode) =>
  countryCurrencyMap.find((item) => item.countryCode === countryCode).currencyId

export const getCurrencySymbolByCountry = (countryCode) =>
  COUNTRY_CODE_CURRENCY_SYMBOL[countryCode]

export const CARD_TYPE = {
  AMEX: 'amex',
  CUP: 'cup',
  MASTERCARD: 'mastercard',
  JCB: 'jcb',
  VISA: 'visa',
}

export const CARD_ICON = {
  amex: ICONS.amex,
  cup: ICONS.cup,
  master: ICONS.mastercard,
  mastercard: ICONS.mastercard,
  jcb: ICONS.jcb,
  visa: ICONS.visa,
  cash_on_delivery: ICONS.checkoutCOD,
}

export const getIconClassForCardType = (cardType) => {
  switch (cardType) {
    case 'Visa':
    case CARD_TYPE.VISA:
      return 'visa'
    case 'MasterCard':
    case CARD_TYPE.MASTERCARD:
      return 'master'
    case 'American Express':
      return 'amex'
    case 'JCB':
    case CARD_TYPE.JCB:
      return 'jcb'
    case 'Discover':
      return 'discover'
    default:
      return 'visa'
  }
}

export const PAYMENT_FETCH_TYPES = {
  GET_CREDIT_CARDS: 'GET_CREDIT_CARDS',
  GET_TTB_CREDIT_CARDS: 'GET_TTB_CREDIT_CARDS',
  DELETE_CREDIT_CARD: 'DELETE_CREDIT_CARD',
  GET_PAYMENT_METHODS: 'GET_PAYMENT_METHODS',
  GET_BANK_TRANSFER_DETAIL: 'GET_BANK_TRANSFER_DETAIL',
}

export const PAYMENT_GATEWAY = {
  ADYEN: 'adyen',
  OMISE: 'omise',
  VERITRANS: 'veritrans',
  ABA: 'aba',
}

export function getPaymentGateway(country) {
  switch (country) {
    case SHOP_COUNTRY_ISO_CODES.TH:
      return PAYMENT_GATEWAY.OMISE
    case SHOP_COUNTRY_ISO_CODES.ID:
      return PAYMENT_GATEWAY.VERITRANS
    case SHOP_COUNTRY_ISO_CODES.KH:
      return PAYMENT_GATEWAY.ABA
    default:
      return PAYMENT_GATEWAY.ADYEN
  }
}

export const shouldFetchCreditCards = (country) =>
  country !== SHOP_COUNTRY_ISO_CODES.ID

export const PAYMENT_RETRY_STATUS = {
  FAILURE: 0,
  PASS: 1,
}

export const PAYMENT_RETRY_MESSAGES = {
  FAILURE: 'PAYMENT_FAILED',
  PASS: 'ORDER_PAYMENT_SUCCESS',
  THREEDS: 'ORDER_PAYMENT_PENDING',
}

/**
 *
 * @param {string[]} cardList
 * @returns {boolean}
 */
export const isCreditCardExpired = (cardList) =>
  cardList?.some((card) => card.is_expired === true)
