import clsx from 'clsx'
import { useState } from 'react'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import PropTypes from 'prop-types'
import Typo from 'constants/typography'
import CreditInformationWrapper from 'components/shared/credit-information/credit-information-wrapper'
import { withI18next } from 'lib/i18n/withI18next'
import { hasLocizeTranslation } from 'lib/utils/locize'

import styles from './styles.scss'

const StoreCreditNoted = ({ isPhone, t }) => {
  const [showCreditInfo, setShowCreditInfo] = useState()

  const handleClick = () => setShowCreditInfo((prevState) => !prevState)

  return (
    <div
      className={clsx(Typo.caption, 'store-credit-noted')}
      data-cy="store-credit-noted"
    >
      <style jsx>{styles}</style>
      <span>{t('*Eligible for')}</span>
      <span
        onClick={handleClick}
        onKeyDown={handleClick}
        className="store-credit-noted__cta"
        role="button"
        tabIndex={0}
      >
        {t('Store Credit Refund')}
      </span>
      <span>{hasLocizeTranslation(t, 'STORE_CREDIT_ONLY_KEY', 'only')}</span>
      <CreditInformationWrapper
        isPhone={isPhone}
        isShow={showCreditInfo}
        setIsShow={handleClick}
        zIndex={100003}
      />
    </div>
  )
}

StoreCreditNoted.defaultProps = {
  isPhone: undefined,
  t: undefined,
}

StoreCreditNoted.propTypes = {
  isPhone: PropTypes.bool,
  t: PropTypes.func,
}

export default compose(
  connect((state) => ({
    isPhone: state.device.isPhone,
  })),
  withI18next(),
)(StoreCreditNoted)
