import Duck from 'extensible-duck'
import { insertArrayInsideObject } from 'lib/utils/common/commonUtils'

export default function createDuck({
  namespace,
  store,
  fetchCallback,
  selectors,
}) {
  return new Duck({
    namespace,
    store,
    types: ['ERROR', 'REQUEST', 'RECEIVE', 'ADD', 'INVALIDATE', 'END', 'DONE'],
    initialState: {
      isFetching: false,
      didInvalidate: true,
      payload: null,
      end: false,
      error: null,
    },
    reducer: (state, action, duck) => {
      switch (action.type) {
        case duck.types.INVALIDATE:
          return { ...state, didInvalidate: true, payload: null }
        case duck.types.END:
          return { ...state, end: true }
        case duck.types.ERROR:
          return {
            ...state,
            isFetching: false,
            didInvalidate: true,
            error: action.error,
            ...(!action.error.keepPayload && { payload: null }),
          }
        case duck.types.REQUEST:
          return { ...state, isFetching: true, didInvalidate: false }
        case duck.types.RECEIVE:
          return {
            ...state,
            isFetching: false,
            didInvalidate: false,
            payload: action.payload,
            lastUpdated: action.receivedAt,
            end: action.payload ? action.payload.length === 0 : true,
            error: null,
          }
        case duck.types.ADD:
          return {
            ...state,
            isFetching: false,
            didInvalidate: false,
            payload: insertArrayInsideObject(
              state.payload,
              action.payload,
              action.field,
            ),
            lastUpdated: action.receivedAt,
            end: action.payload.length === 0,
          }
        case duck.types.DONE:
          return { ...state, isFetching: false }
        default:
          return state
      }
    },

    selectors: selectors || null,

    creators: (duck) => ({
      invalidate: () => ({ type: duck.types.INVALIDATE }),
      end: () => ({ type: duck.types.END }),
      request: () => ({ type: duck.types.REQUEST }),
      receive: (json) => ({
        type: duck.types.RECEIVE,
        payload: json,
        receivedAt: Date.now(),
      }),
      concat: (json, field) => ({
        type: duck.types.ADD,
        payload: json,
        receivedAt: Date.now(),
        field,
      }),
      error: (error, keepPayload = false) => ({
        type: duck.types.ERROR,
        error: { ...error, keepPayload },
      }),
      shouldFetch: (state) => {
        if (state[store].isFetching) {
          return false
        }

        return state[store].didInvalidate
      },
      get: (params) => (dispatch, getState) => {
        if (duck.creators.shouldFetch(getState()) || params?.ignoreCache) {
          return dispatch(duck.creators.fetch(params))
        }

        return Promise.resolve()
      },
      add:
        (params, field = null) =>
        (dispatch) => {
          dispatch(duck.creators.request())

          return fetchCallback(params)
            .then((data) => {
              if (data) {
                return dispatch(duck.creators.concat(data, field))
              }
              return null
            })
            .catch((error) => dispatch(duck.creators.error(error)))
        },
      fetch: (params) => (dispatch) => {
        dispatch(duck.creators.request())

        return fetchCallback(params)
          .then((data) => {
            if (typeof data.ok !== 'undefined' && !data.ok) {
              dispatch(duck.creators.error(data, params.keepPayload))
            } else {
              dispatch(duck.creators.receive(data))
            }
            return data
          })
          .catch((error) =>
            dispatch(duck.creators.error(error, params?.keepPayload)),
          )
      },
      done: () => ({ type: duck.types.DONE }),
    }),
  })
}
