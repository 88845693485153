import { request } from 'lib/api'
import { SHOP_COUNTRY_ISO_CODES } from 'constants/shop-language'
import { PAYMENT_FETCH_TYPES } from '../utils/payments/paymentMethodUtils'
import compressImageWithMaxSizeMB, {
  getFileSizeMB,
} from '../utils/images/compression'
import { HTTP_METHODS } from './const'

class Payment {
  static handleFetch(options) {
    switch (options.type) {
      case PAYMENT_FETCH_TYPES.DELETE_CREDIT_CARD:
        return Payment.deleteCreditCard(options)
      case PAYMENT_FETCH_TYPES.GET_PAYMENT_METHODS:
        return Payment.getPaymentMethods(options)
      case PAYMENT_FETCH_TYPES.GET_BANK_TRANSFER_DETAIL:
        return Payment.getBankTransferDetails(options)
      case PAYMENT_FETCH_TYPES.GET_TTB_CREDIT_CARDS:
        return Payment.getTTBCreditCards()
      case PAYMENT_FETCH_TYPES.GET_CREDIT_CARDS:
      default:
        return Payment.getCreditCards()
    }
  }

  static addAbaCreditCards(body) {
    return request({
      method: HTTP_METHODS.post,
      url: 'v6/credit-cards/aba',
      body,
    })
  }

  static getCreditCards() {
    return request({
      url: 'v6/credit-cards',
    })
  }

  static async getTTBCreditCards() {
    const params = {
      check_additional_expiration: true,
    }

    const config = {
      method: HTTP_METHODS.get,
      url: 'v6/credit-cards',
      params,
    }
    try {
      return await request(config)
    } catch (error) {
      return error
    }
  }

  static async deleteCreditCard({ token }) {
    try {
      return await request({
        method: HTTP_METHODS.delete,
        url: `v6/credit-cards/${token}`,
      })
    } catch (error) {
      return error
    }
  }

  static async setDefaultCreditCard(token) {
    try {
      return await request({
        method: HTTP_METHODS.post,
        url: `v6/credit-cards/${token}/mark-default`,
      })
    } catch (error) {
      return error
    }
  }

  static getPaymentMethods(options) {
    const filter = {
      cart_total: options?.cart_total,
      id_order: options?.id_order,
      shipping_type: options?.shipping_type || 'normal',
      shipping_id: options?.shipping_id,
    }

    return request({
      method: HTTP_METHODS.get,
      url: `v6/payments/methods`,
      params: filter,
    })
  }

  static getBankTransferDetails({ payment_type }) {
    return request({
      url: 'v6/payments/bank-account',
      params: {
        payment_type,
      },
    })
  }

  static async uploadPaymentProof(orderID, data = {}) {
    const strippedFormData = new FormData()
    const MaxSizeMB = 5

    try {
      const compressedImage = await compressImageWithMaxSizeMB(
        data.file,
        MaxSizeMB,
      )

      strippedFormData.append('order_id', orderID)
      strippedFormData.append('proof_file', compressedImage, data.file.name)
      const fileSizeMB = getFileSizeMB(compressedImage)

      if (fileSizeMB > MaxSizeMB) {
        const err = {
          name: 'Payload Too Large',
          status: 413,
          message: `File size exceeds the maximum size of ${MaxSizeMB} MB`,
        }
        return err
      }

      await request({
        method: HTTP_METHODS.post,
        url: 'v6/payments/proofs',
        body: strippedFormData,
      })

      return { status: 204 }
    } catch (err) {
      throw new Error(err.message || err)
    }
  }

  static addCustomerCreditCards(options) {
    switch (options.country) {
      case SHOP_COUNTRY_ISO_CODES.TH:
        return Payment.addOmiseMethod(options)
      default:
        return Payment.addAdyenMethod(options)
    }
  }

  static addAdyenMethod(body) {
    return request({
      method: HTTP_METHODS.post,
      url: 'v6/credit-cards',
      body,
    })
  }

  static addOmiseMethod(options) {
    const payload = {
      number: parseInt(options.cardNumber.split(' ').join(''), 10),
      name: options.holder,
      expiration_month: parseInt(options.expiry.split('/')[0].trim(), 10),
      expiration_year: parseInt(
        options.expiry.split('/')[1].trim().padStart(4, 20),
        10,
      ),
      security_code: options?.cvv,
    }

    return new Promise((resolve, reject) => {
      Omise.createToken('card', payload, (statusCode, response) => {
        if (parseInt(statusCode, 10) === 200) {
          return request({
            method: HTTP_METHODS.post,
            url: 'v6/credit-cards',
            body: {
              token: response.id,
              check_additional_expiration: options.check_additional_expiration,
            },
          })
            .then(resolve)
            .catch(reject)
        }

        const errObj = {
          code: response.code,
          message: response.message,
        }

        return reject(errObj)
      })
    })
  }
}

export default Payment
