import Duck from 'extensible-duck'

const duck = new Duck({
  namespace: 'ui',
  store: 'modal',
  types: ['SHOW', 'HIDE', 'TOGGLE_CART_NOTICE'],
  initialState: {
    active: [],
    args: null,
    callback: null,
    firstCallback: null,
    secondCallBack: null,
  },
  reducer: (state, action, rDuck) => {
    switch (action.type) {
      case rDuck.types.SHOW:
        return {
          ...state,
          active: [...state.active, action.id],
          // In the future, gonna make this args append the old ones, to handle many modals at the same time.
          args: action.args,
        }
      case rDuck.types.HIDE:
        return {
          ...state,
          active: state.active.filter((id) => id !== action.id),
        }
      case rDuck.types.TOGGLE_CART_NOTICE:
        return {
          ...state,
          showCartNotice: action.show,
          args: action.args,
        }
      default:
        return state
    }
  },
  creators: (cDuck) => ({
    show: (id, args = null) => ({
      type: cDuck.types.SHOW,
      id,
      args,
    }),
    hide: (id) => ({
      type: cDuck.types.HIDE,
      id,
    }),
    toggleCartNotice: (show, args = null) => ({
      type: cDuck.types.TOGGLE_CART_NOTICE,
      show,
      args,
    }),
  }),
})

export default duck
