import PropTypes from 'prop-types'
import Button from 'components/common/button'
import { BUTTON_TYPES } from 'constants/button'
import { withI18next } from 'lib/i18n/withI18next'
import styles from './styles.scss'

const CartChangeFreeGiftButton = ({ onClick, t }) => (
  <Button
    className="cart-item-info__free-gift-change"
    type={BUTTON_TYPES.text}
    onClick={onClick}
  >
    <style jsx>{styles}</style>
    {t('Change')}
  </Button>
)

CartChangeFreeGiftButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
}

export default withI18next()(CartChangeFreeGiftButton)
