import createDuck from 'lib/ducks/remoteDuck'
import { request } from '.' // eslint-disable-line import/no-cycle
import { API_ALIASES, HTTP_METHODS } from './const'
import { getAPIRequestURL } from './utils'

// TODO: change data structure of redux store in createDuck to support
// resource with multiple data type
export class CartBadge {
  static get(options) {
    switch (options.type) {
      case 'GET_CART_QUANTITY':
      default:
        return this.getCartQuantity(options)
    }
  }

  static async getCartQuantity() {
    const { baseURL, url } = await getAPIRequestURL(API_ALIASES.CART_BADGE)
    return request({
      baseURL,
      url,
      method: HTTP_METHODS.get,
    })
  }
}

export default createDuck({
  namespace: 'account',
  store: 'cartBadge',
  fetchCallback: (options) => CartBadge.getCartQuantity(options),
})
