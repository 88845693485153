import React from 'react'
import PropTypes from 'prop-types'
import { withI18next } from 'lib/i18n/withI18next'
import OneSizeSelection from './one-size'
import MultipleSizeSelection from './multiple-size'
import { isMultipleSizeAvailable, isOneSizeSelection } from '../utils'

const SizeSelection = ({
  allowProductUpdate,
  handleChangeSize,
  isShoppingBagNewDesign,
  isSoldOut,
  product,
  selectedAttribute,
}) => (
  <div>
    {isOneSizeSelection(allowProductUpdate, isSoldOut, product) ? (
      <OneSizeSelection
        isSoldOut={isSoldOut}
        selectedAttribute={selectedAttribute}
      />
    ) : (
      isMultipleSizeAvailable(
        allowProductUpdate,
        isShoppingBagNewDesign,
        isSoldOut,
        product,
      ) && (
        <MultipleSizeSelection
          handleChangeSize={handleChangeSize}
          product={product}
          selectedAttribute={selectedAttribute}
        />
      )
    )}
  </div>
)

SizeSelection.defaultProps = {
  allowProductUpdate: false,
  handleChangeSize: null,
  isShoppingBagNewDesign: false,
  isSoldOut: false,
  product: null,
  selectedAttribute: null,
}

SizeSelection.propTypes = {
  allowProductUpdate: PropTypes.bool,
  handleChangeSize: PropTypes.func,
  isShoppingBagNewDesign: PropTypes.bool,
  isSoldOut: PropTypes.bool,
  product: PropTypes.shape({}),
  selectedAttribute: PropTypes.shape({ size: PropTypes.string }),
}

export default withI18next()(SizeSelection)
