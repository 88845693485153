import PropTypes from 'prop-types'
import { Fragment } from 'react'
import clsx from 'clsx'
import ICONS, { ICON_SIZE } from 'components/common/icon/const'
import Icon from 'components/common/icon'

const getArrowComponent = (iconName, onClick, side) => (
  <Icon
    className={clsx(`cart__recomm__arrow-${side}`)}
    onClick={onClick}
    size={ICON_SIZE.small}
    src={ICONS[iconName]}
  />
)

const CartRecommendationArrows = ({ curImg, onClick, totalProducts }) => {
  const diff = 2

  return (
    <Fragment>
      {curImg > 0 &&
        getArrowComponent('arrowHeadLeft', () => onClick('decrement'), 'left')}
      {curImg < totalProducts - diff &&
        getArrowComponent(
          'arrowHeadRight',
          () => onClick('increment'),
          'right',
        )}
    </Fragment>
  )
}

CartRecommendationArrows.propTypes = {
  curImg: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
  totalProducts: PropTypes.number.isRequired,
}

export default CartRecommendationArrows
