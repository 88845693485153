import clsx from 'clsx'
import PropTypes from 'prop-types'
import React from 'react'
import { FEATURE_TOGGLER } from 'constants/global'
import Typo from 'constants/typography'
import { customFlagValue, isFlagEligible } from 'lib/utils/handle-flag/const'
import { hasLocizeTranslation } from 'lib/utils/locize'
import CART_SUMMARY from './const'
import HoolahSuggestion from './hoolah-suggestion'
import styles from './styles.scss'

// TODO: refactor check out price summary to use this component
const CartSummary = ({
  priceSummary,
  showLoyaltyBenefit,
  showHoolahSuggestion,
  showTitle,
  t,
}) => {
  const shoppingBagRedesignFlagValue = customFlagValue({
    flagName: 'shoppingBagRedesign',
  }) // TODO: force value to handle with flagsmith later (ShoppingBagRedesign-20230101)
  const isShoppingBagNewDesign = isFlagEligible(shoppingBagRedesignFlagValue)

  if (!priceSummary) {
    return null
  }

  const showCartLoyalty =
    FEATURE_TOGGLER.loyalty &&
    showLoyaltyBenefit &&
    !!priceSummary.store_credits?.loyalty

  const cartSummary = [
    {
      key: CART_SUMMARY.subTotal,
      label: t('Sub - Total'),
      format: priceSummary.subtotal_formatted,
      show: true,
    },
    {
      key: CART_SUMMARY.shipping,
      label: t('Shipping'),
      format: priceSummary.shipping_fee
        ? priceSummary.shipping_fee_formatted
        : t('Free'),
      show: 'shipping_fee' in priceSummary,
    },
    {
      key: CART_SUMMARY.discount,
      label: hasLocizeTranslation(t, 'CART_SUMMARY_DISCOUNT', 'Discount'),
      format: `-${priceSummary.applied_voucher?.discount_formatted}`,
      show: priceSummary.applied_voucher?.is_applied,
    },
    {
      key: CART_SUMMARY.pmlCredit,
      label: hasLocizeTranslation(
        t,
        'CART_SUMMARY_STORE_CREDITS',
        'Pomelo Credits',
      ),
      format: `-${priceSummary.store_credits?.applied_formatted}`,
      show: !!priceSummary.store_credits?.applied,
    },
    {
      key: CART_SUMMARY.total,
      label: hasLocizeTranslation(t, 'CART_SUMMARY_TOTAL', 'total'),
      format: priceSummary.total_formatted,
      show: true,
    },
    {
      key: CART_SUMMARY.hoolah,
      label: (
        <HoolahSuggestion
          totalPrice={priceSummary.total}
          formattedTotalPrice={priceSummary.total_formatted}
          allowBottomGap={showCartLoyalty}
        />
      ),
      format: '',
      show: showHoolahSuggestion,
      bottomDivider: showCartLoyalty,
    },
    {
      key: CART_SUMMARY.loyalty,
      label: hasLocizeTranslation(
        t,
        'CART_CHECKOUT_LOYALTY_BENEFIT',
        'Pomelo credit to be earn',
      ),
      format: priceSummary.store_credits?.loyalty_formatted,
      show: showCartLoyalty,
    },
  ]

  return (
    <div
      className={clsx('cart-payment', {
        'new-design': isShoppingBagNewDesign,
      })}
    >
      <style jsx>{styles}</style>
      {showTitle && (
        <div className={clsx(Typo.subtitle1, 'cart-payment__title')}>
          {hasLocizeTranslation(t, 'CART_PAYMENT_TITLE', 'Payment Summary')}
        </div>
      )}
      {cartSummary
        .filter(({ show }) => show)
        .map(({ key, label, format, bottomDivider }) => (
          <div
            key={key}
            className={clsx(
              'cart-payment__break',
              bottomDivider && 'bottom-divider',
            )}
          >
            <span
              className={
                key === CART_SUMMARY.total ? Typo.subtitle2 : Typo.caption
              }
            >
              {label}
            </span>
            <span
              className={key === CART_SUMMARY.total ? Typo.price1 : Typo.price2}
            >
              {format}
            </span>
          </div>
        ))}
    </div>
  )
}

CartSummary.propTypes = {
  priceSummary: PropTypes.shape({
    applied_voucher: PropTypes.shape({
      discount: PropTypes.number,
      discount_formatted: PropTypes.string,
      is_applied: PropTypes.bool,
    }),
    store_credits: PropTypes.shape({
      remaining: PropTypes.number,
      remaining_formatted: PropTypes.string,
      loyalty: PropTypes.number,
      loyalty_formatted: PropTypes.string,
      applied_formatted: PropTypes.string,
      applied: PropTypes.number,
    }),
    subtotal: PropTypes.number,
    subtotal_formatted: PropTypes.string,
    total: PropTypes.number,
    total_formatted: PropTypes.string,
    shipping_fee: PropTypes.number,
    shipping_fee_formatted: PropTypes.string,
  }),
  showLoyaltyBenefit: PropTypes.bool,
  showHoolahSuggestion: PropTypes.bool.isRequired,
  showTitle: PropTypes.bool,
  t: PropTypes.func.isRequired,
}

CartSummary.defaultProps = {
  priceSummary: undefined,
  showLoyaltyBenefit: undefined,
  showTitle: undefined,
}

export default CartSummary
