import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { BUTTON_HTML_TYPE, BUTTON_TYPES } from 'constants/button'
import Typo from 'constants/typography'
import Button from '../button'
import styles from './style.scss'
import Icon from '../icon'
import ICONS, { ICON_SIZE } from '../icon/const'

/**
 * @param {string} props.className customize style of component
 * @param {boolean} props.closable flag to show close button
 * @param {boolean} props.cancelable flag to show cancel button
 * @param {boolean} props.confirmable flag to show confirm button
 * @param {func} props.onConfirm event callback when confirm button is clicks
 * @param {func} props.onCancel event callback when cancel button is click
 * @param {func} props.onClose event callback when close button is click
 * @param {string} props.confirmText confirm button's text
 * @param {string} props.cancelText cancel button's text
 * @param {node} props.icon the icon that show at the top of dialog
 * @param {string} props.title dialog's title
 * @param {node|string} props.children dialog content
 */
const Dialog = ({
  className,
  closable,
  cancelable,
  confirmable,
  onConfirm,
  onCancel,
  confirmText,
  cancelText,
  onClose,
  icon,
  title,
  children,
}) => {
  const classes = clsx('pml-dialog__container', className)
  return (
    <div className={classes}>
      <style jsx>{styles}</style>
      {closable && (
        <button
          type={BUTTON_HTML_TYPE.button}
          className="pml-dialog__close-btn"
          data-cy="pml-dialog-close-btn"
          onClick={onClose}
        >
          <Icon alt="close dialog" src={ICONS.close} size={ICON_SIZE.medium} />
        </button>
      )}
      {icon && <div className="pml-dialog__icon-wrapper">{icon}</div>}

      {title &&
        (typeof title === 'string' ? (
          <div className="pml-dialog__header">
            <span className={Typo.h6}>{title}</span>
          </div>
        ) : (
          title
        ))}
      <div className="pml-dialog__content">
        {typeof children === 'string' ? (
          <span className={clsx('content-text', Typo.body2)}>{children}</span>
        ) : (
          children
        )}
      </div>
      {(confirmable || cancelable) && (
        <div className="pml-dialog__footer">
          {cancelable && (
            <Button
              onClick={onCancel}
              className="dialog-button__cancel"
              type={BUTTON_TYPES.outlinedLight}
            >
              {cancelText}
            </Button>
          )}
          {confirmable && (
            <Button
              onClick={onConfirm}
              className="dialog-button__confirm"
              type={BUTTON_TYPES.primary}
            >
              {confirmText}
            </Button>
          )}
        </div>
      )}
    </div>
  )
}

Dialog.defaultProps = {
  className: undefined,
  closable: false,
  cancelable: false,
  confirmable: false,
  onConfirm: undefined,
  onCancel: undefined,
  confirmText: 'Confirm',
  cancelText: 'Cancel',
  onClose: undefined,
  icon: undefined,
  title: undefined,
  children: undefined,
}

Dialog.propTypes = {
  className: PropTypes.string,
  closable: PropTypes.bool,
  cancelable: PropTypes.bool,
  confirmable: PropTypes.bool,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  confirmText: PropTypes.string,
  cancelText: PropTypes.string,
  onClose: PropTypes.func,
  icon: PropTypes.node,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
}

export default Dialog
