import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import cartItemsDuck from 'components/cart/cart-items/duck'
import Typo from 'constants/typography'
import Icon from 'components/common/icon'
import ICONS, { ICON_SIZE } from 'components/common/icon/const'

export const SNACKBAR_TYPE = {
  info: 0,
  error: 1,
}

const CartSnackbar = ({ updateSnackbar, snackbarMessage }) => {
  const timerRef = useRef(null)
  const [isMounted, setIsMounted] = useState(false)

  useEffect(() => {
    setIsMounted(true)
  }, [])

  useEffect(() => {
    if (snackbarMessage.text) {
      clearTimeout(timerRef.current)

      timerRef.current = setTimeout(
        () => {
          updateSnackbar('')
        },
        snackbarMessage.time ? snackbarMessage.time : 3000,
      )
    }
  }, [snackbarMessage.text, snackbarMessage.time, updateSnackbar])

  function hideSnackbar() {
    clearTimeout(timerRef.current)
    updateSnackbar('')
  }

  return (
    <div
      className={`cart-snackbar${
        isMounted && snackbarMessage.text ? ' active' : ''
      }`}
      onClick={hideSnackbar}
      onKeyUp={hideSnackbar}
      role="presentation"
      style={
        snackbarMessage.type === SNACKBAR_TYPE.error
          ? {
              backgroundColor: 'var(--accent-light)',
            }
          : null
      }
    >
      <span className={Typo.caption}>{snackbarMessage.text}</span>
      <Icon src={ICONS.close} size={ICON_SIZE.medium} />
    </div>
  )
}

CartSnackbar.propTypes = {
  snackbarMessage: PropTypes.shape({
    text: PropTypes.string,
    time: PropTypes.number,
    type: PropTypes.oneOf(Object.values(SNACKBAR_TYPE)),
  }).isRequired,
  updateSnackbar: PropTypes.func.isRequired,
}

export default connect(
  /* istanbul ignore next */
  (state) => ({
    snackbarMessage: state.cartData.snackbarMessage,
  }),
  /* istanbul ignore next */
  (dispatch) =>
    bindActionCreators(
      {
        updateSnackbar: cartItemsDuck.creators.updateSnackbar,
      },
      dispatch,
    ),
)(CartSnackbar)
