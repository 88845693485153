import clsx from 'clsx'
import React from 'react'
import PropTypes from 'prop-types'
import { withI18next } from 'lib/i18n/withI18next'
import { hasLocizeTranslation } from 'lib/utils/locize'
import Icon from 'components/common/icon'
import ICONS, { ICON_SIZE } from 'components/common/icon/const'
import Typo from 'constants/typography'
import Banner from 'components/banner'
import {
  arrangeContent,
  NEW_COOKIE_ALERT_TITLE,
  NEW_COOKIE_ALERT_CONTENT,
} from 'components/alert/alert-cookie/const'
import CookieBannerButton from '../cookie-button'
import styles from './style.scss'

const CookieBanner = ({ disableBanner, t }) => {
  const cookieBannerContent = arrangeContent(
    t,
    NEW_COOKIE_ALERT_CONTENT,
    'cookie-banner__banner__anchor',
  )

  return (
    <div className="cookie-banner" id="cookie-banner">
      <style jsx>{styles}</style>
      <Banner className="cookie-banner__banner">
        <div className="cookie-banner__banner__container">
          <Icon src={ICONS.cookies} size={ICON_SIZE.jumbo} />
          <div className="cookie-banner__banner__title">
            <div className={Typo.title3}>
              {hasLocizeTranslation(
                t,
                NEW_COOKIE_ALERT_TITLE.key,
                NEW_COOKIE_ALERT_TITLE.text,
              )}
            </div>
            <div className={clsx('cookie-banner__banner__content', Typo.body2)}>
              <div
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: cookieBannerContent,
                }}
              />
            </div>
          </div>
          <CookieBannerButton disableBanner={disableBanner} />
        </div>
      </Banner>
    </div>
  )
}

CookieBanner.propTypes = {
  disableBanner: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
}

export default withI18next()(CookieBanner)
