import clsx from 'clsx'
import PropTypes from 'prop-types'
import React from 'react'
import Icon from 'components/common/icon'
import ICONS from 'components/common/icon/const'
import { withI18next } from 'lib/i18n/withI18next'
import Typo from 'constants/typography'

const Badge3D = ({ isPhone, is3dImage }) => {
  if (isPhone) {
    return (
      <div className="pdp__3d-badge-mobile">
        <div
          className={clsx('pdp__3d-badge-mobile__wrapper', {
            'pdp__3d-badge-mobile__wrapper__3DModel': is3dImage,
          })}
        >
          <span style={{ marginRight: '3px' }}>
            <Icon size={17} src={ICONS.product3d} />
          </span>
          <span className={clsx(Typo.body2)}>
            {is3dImage ? 'preview - ' : 'preview available'}
          </span>
          {is3dImage && (
            <span className={clsx(Typo.caption)} style={{ color: 'gray' }}>
              pinch and zoom me!
            </span>
          )}
        </div>
      </div>
    )
  }

  return (
    <div className="pdp__3d-badge-desktop">
      <div className="pdp__3d-badge-desktop__wrapper">
        <span style={{ marginRight: '5px' }}>
          <Icon size={20} src={ICONS.product3d} />
        </span>
        <span className={clsx(Typo.body2)}>
          {is3dImage ? 'preview - ' : 'preview available'}
        </span>
        {is3dImage && (
          <span className={clsx(Typo.caption)} style={{ color: 'gray' }}>
            click and zoom me!
          </span>
        )}
      </div>
    </div>
  )
}

Badge3D.defaultProps = {
  isPhone: false,
  is3dImage: false,
}

Badge3D.propTypes = {
  isPhone: PropTypes.bool,
  is3dImage: PropTypes.bool,
}

export default withI18next()(Badge3D)
