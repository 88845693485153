import React from 'react'
import { v4 as uuid } from 'uuid'
import PropTypes from 'prop-types'
import Typo from 'constants/typography'
import InputLayout from 'components/common/input-layout'
import Select from 'components/common/select'
import { SELECT_TYPE } from 'components/common/select/const'
import { withI18next } from 'lib/i18n/withI18next'
import { getClassNameWithOpacity } from 'components/cart/cart-product/utils'

const QuantitySelection = ({
  allowProductUpdate,
  handleChangeQuantity,
  isSoldOut,
  product,
  selectedAttribute,
  t,
}) => {
  const isUnAvailableQuantity = !allowProductUpdate || isSoldOut
  const quantityClass = getClassNameWithOpacity(
    `${Typo.subtitle2} default-amount`,
    isSoldOut,
  )
  const limit = selectedAttribute?.stock?.quantity || product?.max_quantity || 1
  const amount = Array.from({ length: limit }, (_, i) => i + 1)

  return (
    <div className="item-info__quantity">
      {isUnAvailableQuantity ? (
        <InputLayout label={t('Quantity')} fullWidth shrink>
          <div className={quantityClass}>{product?.quantity}</div>
        </InputLayout>
      ) : (
        <Select
          fullWidth
          label={t('Quantity')}
          value={product?.quantity}
          className="item-info__select"
          selectClassName={Typo.subtitle2}
          type={SELECT_TYPE.float}
          onChange={(event, val) =>
            handleChangeQuantity(
              product?.id_product,
              selectedAttribute?.id_product_attribute,
              val,
              event,
            )
          }
        >
          {amount?.map((quantity) => (
            <option
              key={`${uuid()}-${quantity}`}
              value={quantity}
              className={Typo.subtitle2}
            >
              {quantity}
            </option>
          ))}
        </Select>
      )}
    </div>
  )
}

QuantitySelection.defaultProps = {
  allowProductUpdate: false,
  isSoldOut: false,
  product: null,
  selectedAttribute: null,
}

QuantitySelection.propTypes = {
  allowProductUpdate: PropTypes.bool,
  handleChangeQuantity: PropTypes.func.isRequired,
  isSoldOut: PropTypes.bool,
  product: PropTypes.shape({
    id_product: PropTypes.number,
    max_quantity: PropTypes.number,
    quantity: PropTypes.number,
  }),
  selectedAttribute: PropTypes.shape({
    id_product_attribute: PropTypes.number,
    stock: PropTypes.shape({
      quantity: PropTypes.number,
      is_low_quantity: PropTypes.bool.isRequired,
      is_sold_out: PropTypes.bool.isRequired,
    }),
  }),
  t: PropTypes.func.isRequired,
}

export default withI18next()(QuantitySelection)
