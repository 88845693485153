export const VOUCHER_LOCIZE = {
  VOUCHER_DISCOUNT_TITLE: {
    key: 'VOUCHER_DISCOUNT_TITLE',
    text: '%d OFF | Code: %s',
  },
  VOUCHER_COF_TITLE: {
    key: 'VOUCHER_COF_TITLE',
    text: 'BUY %d, 1 FREE | Code: %s',
  },
  VOUCHER_MIN_SPEND: {
    key: 'VOUCHER_MIN_SPEND',
    text: 'Min Spend %d',
  },
  VOUCHER_MIN_SPEND_UP_TO: {
    key: 'VOUCHER_MIN_SPEND_UP_TO',
    text: `Min Spend %d Up to %s THB off`,
  },
  VOUCHER_MIN_PURCHASE: {
    key: 'VOUCHER_MIN_PURCHASE',
    text: `Min purchase of %d items`,
  },
  VOUCHER_MIN_PURCHASE_UP_TO: {
    key: 'VOUCHER_MIN_PURCHASE_UP_TO',
    text: `Min purchase of %d Up to %s THB off`,
  },
  VOUCHER_COACH_MARK_TEXT: {
    key: 'VOUCHER_COACH_MARK_TEXT',
    text: 'Copy and paste voucher code in the shopping bag',
  },
}

export const VOUCHER_TYPE_LIST = [
  'Specific Brand',
  'Specific Time',
  'Specific Cohort',
  'Campaign Voucher',
  'Other Voucher',
  null,
]

export const VOUCHER_DISCOUNT_TYPE = {
  GET_COF: 'Get_COF',
  PERCENTAGE: 'percentage',
  AMOUNT: 'amount',
}

export const VOUCHER_DISCOUNT_TYPE_LIST = [
  VOUCHER_DISCOUNT_TYPE.GET_COF,
  VOUCHER_DISCOUNT_TYPE.PERCENTAGE,
  VOUCHER_DISCOUNT_TYPE.AMOUNT,
]
