import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import RecentProductList from 'components/recently-view/recent-product-list'
import { getSearchBarMargin } from 'components/search/utils'
import { withI18next } from 'lib/i18n/withI18next'
import { hasLocizeTranslation } from 'lib/utils/locize'
import SearchSidebar from './search-sidebar'
import styles from './style.scss'

const SearchSection = ({ onClose, setSearchWord, t }) => {
  const baseSearchBarMargin = getSearchBarMargin()
  const [searchBarMargin, setSearchBarMargin] = useState(baseSearchBarMargin)

  const sectionTitle = hasLocizeTranslation(
    t,
    'SEARCH_SECTION_RECENTLY_VIEW_TITLE',
    'Recently Viewed Items',
  )

  const updateSearchBarMargin = useCallback(() => {
    setSearchBarMargin(getSearchBarMargin())
  }, [])

  useEffect(() => {
    const newMargin = getSearchBarMargin()
    if (searchBarMargin !== newMargin) {
      setSearchBarMargin(newMargin)
    }
    window.addEventListener('resize', updateSearchBarMargin)

    return () => {
      window.removeEventListener('resize', updateSearchBarMargin)
    }
  }, [searchBarMargin, updateSearchBarMargin])

  const headerSideMargin = searchBarMargin?.split(' ')?.[1]
  const parsedSideMargin = headerSideMargin && parseFloat(headerSideMargin)

  return (
    <div className="search-section__wrapper">
      <style jsx>{styles}</style>
      <div
        className="search-section__container"
        style={{ paddingLeft: parsedSideMargin }}
      >
        <SearchSidebar onClose={onClose} setSearchWord={setSearchWord} />
        <RecentProductList
          onSearchSection
          refType="searchbox"
          rightMargin={parsedSideMargin}
          title={sectionTitle}
        />
      </div>
    </div>
  )
}

SearchSection.propTypes = {
  onClose: PropTypes.func.isRequired,
  setSearchWord: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
}

export default compose(
  connect((state) => ({
    auth: state.auth,
  })),
  withI18next(),
)(SearchSection)
