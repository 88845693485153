import Duck from 'extensible-duck'

const CartItemDuck = new Duck({
  namespace: 'content',
  store: 'cartData',
  types: [
    'UPDATE',
    'SELECT_SIZE',
    'IS_SOLD_OUT',
    'UPDATE_SNACKBAR',
    'UPDATE_VOUCHER_ERROR',
    'UPDATE_FREE_GIFT',
  ],
  initialState: {
    cartOpen: false,
    couponUsed: null,
    freeGiftOpen: false,
    sizeSelected: false,
    isStoreCreditUsed: false,
    hasSoldOutItem: false,
    snackbarMessage: {
      text: '',
      time: 0,
      type: null,
    },
    voucherErrorMessage: '',
    isFreeGiftAdded: false,
  },
  reducer: (state, action, duck) => {
    switch (action.type) {
      case duck.types.SELECT_SIZE:
        return { ...state, sizeSelected: !state.sizeSelected }
      case duck.types.UPDATE:
        return {
          ...state,
          cartOpen: action.cartOpen,
          couponUsed: action.couponUsed,
          freeGiftOpen: action.freeGiftOpen,
          isStoreCreditUsed: action.isStoreCreditUsed,
        }
      case duck.types.IS_SOLD_OUT:
        return { ...state, hasSoldOutItem: action.hasSoldOutItem }
      case duck.types.UPDATE_SNACKBAR:
        return { ...state, snackbarMessage: action.snackbarMessage }
      case duck.types.UPDATE_VOUCHER_ERROR:
        return { ...state, voucherErrorMessage: action.voucherErrorMessage }
      case duck.types.UPDATE_FREE_GIFT:
        return { ...state, isFreeGiftAdded: action.isFreeGiftAdded }
      default:
        return state
    }
  },
  creators: (duck) => ({
    update: (options) => ({
      type: duck.types.UPDATE,
      cartOpen: options?.cartOpen,
      couponUsed: options?.couponUsed,
      freeGiftOpen: options?.freeGiftOpen,
      isStoreCreditUsed: options?.isStoreCreditUsed,
    }),
    toggleSelectSize: () => ({ type: duck.types.SELECT_SIZE }),
    checkSoldOutItem: (options) => ({
      type: duck.types.IS_SOLD_OUT,
      hasSoldOutItem: options?.hasSoldOutItem,
    }),
    updateSnackbar: (text, type) => ({
      type: duck.types.UPDATE_SNACKBAR,
      snackbarMessage: {
        text,
        time: Date.now(),
        type,
      },
    }),
    updateVoucherErrorMessage: (text) => ({
      type: duck.types.UPDATE_VOUCHER_ERROR,
      voucherErrorMessage: text,
    }),
    updateIsFreeGiftAdded: (isFreeGiftAdded) => ({
      type: duck.types.UPDATE_FREE_GIFT,
      isFreeGiftAdded,
    }),
  }),
})

export default CartItemDuck
