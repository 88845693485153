import { useState, useEffect } from 'react'
import { remoteConfig } from 'lib/firebase/index'

// Noted: Tempory hook before moving to `FlagSmith`
export default function usePomeloCashbackEnable(currentShopId) {
  const [isCashbackEnabled, setIsCachkbackEnabled] = useState(true)

  useEffect(() => {
    remoteConfig
      .fetchAndActivate()
      .then(() => {
        const enabledStoreIDs = JSON.parse(
          // eslint-disable-next-line no-underscore-dangle
          remoteConfig.getValue('loyalty_enabled')._value,
        )
        setIsCachkbackEnabled(enabledStoreIDs.includes(currentShopId))
      })
      .catch(() => {
        setIsCachkbackEnabled(true)
      })
  }, [currentShopId])

  return isCashbackEnabled
}
