import clsx from 'clsx'
import PropTypes from 'prop-types'
import React from 'react'
import { FullModal } from 'components/portal/modal'
import useModal from 'components/portal/useModal'
import Typo from 'constants/typography'
import { withI18next } from 'lib/i18n/withI18next'

export const getEachLineTnc = (terms) =>
  typeof terms === 'string' ? terms.split('\r\n') : ''

const TermsAndConditions = ({ t, terms, tnc, withCode }) => {
  const [isOpen, setIsOpen] = useModal(false)
  const content = getEachLineTnc(terms)
  const classes = clsx(Typo.overline, 'skinny-banner__tnc', {
    'skinny-banner__tnc-withCode': withCode,
  })

  if (!tnc || !terms) {
    return null
  }

  return (
    <React.Fragment>
      <button className={classes} onClick={() => setIsOpen(true)}>
        {tnc}
      </button>
      <FullModal isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <div className="skinny-banner__tnc-content">
          <div className="skinny-banner__tnc-content-wrapper">
            <span className={Typo.h6}>{t('TERMS & CONDITIONS')}</span>
            <ul className="skinny-banner__tnc-list">
              {content.map((data) => (
                <li
                  key={data}
                  className={clsx('skinny-banner__tnc-list__item', Typo.price2)}
                >
                  {data}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </FullModal>
    </React.Fragment>
  )
}

TermsAndConditions.defaultProps = {
  terms: '',
  tnc: '',
  withCode: false,
}

TermsAndConditions.propTypes = {
  t: PropTypes.func.isRequired,
  terms: PropTypes.string,
  tnc: PropTypes.string,
  withCode: PropTypes.bool,
}

export default withI18next()(TermsAndConditions)
