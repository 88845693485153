import { useState, useEffect } from 'react'
import getSkinnyBanners from 'lib/api/skinny-banners'
import { isAppWebView } from 'lib/utils/common/commonUtils'
import { getCountdownVoucherSkinnyBannerForUser } from './countdownVoucher'

async function getFetchSkinnyBanner(
  ignore,
  { setIsFetched, setBanners },
  customer_groups,
  translation,
) {
  try {
    const res = await getSkinnyBanners()
    setIsFetched(true)

    const countdownVoucher = await getCountdownVoucherSkinnyBannerForUser(
      customer_groups,
      translation,
    )

    const banners =
      res['skinny-banners'].length > 0 ? [...res['skinny-banners']] : []
    if (countdownVoucher) {
      banners.push(countdownVoucher)
    }
    if (!ignore) {
      setBanners(banners)
    }
  } catch (error) {
    // continue regardless of error
  }
}

function useSkinnyBanners(isGuestMode, translation, customer_groups) {
  const [banners, setBanners] = useState([])
  const [isFetched, setIsFetched] = useState(false)

  useEffect(() => {
    let ignore = false

    async function fetchSkinnyBanners() {
      if (isAppWebView()) {
        setIsFetched(true)
        return
      }

      const stateUpdate = { setIsFetched, setBanners }
      await getFetchSkinnyBanner(
        ignore,
        stateUpdate,
        customer_groups,
        translation,
      )
    }

    fetchSkinnyBanners()

    return () => {
      ignore = true
    }
  }, [customer_groups, isGuestMode, translation])

  return [banners, isFetched]
}

export default useSkinnyBanners
