import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { BUTTON_TYPES, BUTTON_SHAPES, BUTTON_HTML_TYPE } from 'constants/button'
import useButtonElement from './ButtonHook'
import styles from './style.scss'

/**
 *
 * @param {AnchorHTMLAttributes} param0.aProps set attribute for <a> tag
 * @param {object} param0.children content of button cen be string, number, or node (eg. <span>foobar</span>)
 * @param {string} param0.className customize style of component
 * @param {string} param0.cy for Cypress
 * @param {boolean} param0.disabled disabled state of button
 * @param {string} param0.htmlType set the original html type see https://developer.mozilla.org/en-US/docs/Web/HTML/Element/button#attr-type
 * @param {boolean} param0.link set to true to return <a> wrapper instead of <button>
 * @param {function} param0.onClick Set the handler to handle `click` event
 * @param {function} param0.onMouseEnter Set the handler to handle `hover` event
 * @param {function} param0.onMouseLeave Set the handler to handle `leave` event
 * @param {string} param0.shape can be set to `circle` `round`
 * @param {object} param0.style customize inline style of component
 * @param {string} param0.target set where to open the linked document
 * @param {string} param0.type can be set to `primary` `secondary` `link`
 */
const Button = ({
  aProps,
  children,
  className,
  cy,
  disabled,
  htmlType,
  link,
  onClick,
  onMouseEnter,
  onMouseLeave,
  shape,
  style,
  target,
  type,
  form,
}) => {
  const { kids, classes, btnHtmlType, handleClicked } = useButtonElement({
    children,
    type,
    shape,
    disabled,
    className,
    htmlType,
    link,
    onClick,
    onMouseEnter,
    onMouseLeave,
    form,
  })

  return (
    !!children && (
      <Fragment>
        <style jsx>{styles}</style>
        {link ? (
          <a
            {...aProps}
            className={classes}
            style={style}
            data-cy={cy}
            onClick={handleClicked}
            onKeyDown={handleClicked}
            target={target}
            role="button"
            tabIndex={0}
          >
            {kids}
          </a>
        ) : (
          <button
            className={classes}
            onClick={handleClicked}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            type={btnHtmlType}
            style={style}
            form={form}
            disabled={disabled}
            data-cy={cy}
          >
            {kids}
          </button>
        )}
      </Fragment>
    )
  )
}

Button.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  type: PropTypes.oneOf(Object.values(BUTTON_TYPES)),
  shape: PropTypes.oneOf(Object.values(BUTTON_SHAPES)),
  disabled: PropTypes.bool,
  className: PropTypes.string,
  cy: PropTypes.string,
  htmlType: PropTypes.oneOf(Object.values(BUTTON_HTML_TYPE)),
  style: PropTypes.shape({}),
  link: PropTypes.bool,
  target: PropTypes.string,
  aProps: PropTypes.shape({}),
  onClick: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  form: PropTypes.string,
}

Button.defaultProps = {
  type: '',
  shape: '',
  disabled: false,
  className: '',
  cy: undefined,
  htmlType: '',
  style: {},
  link: false,
  target: undefined,
  aProps: undefined,
  onClick: undefined,
  onMouseEnter: undefined,
  onMouseLeave: undefined,
  form: undefined,
}

export default Button
