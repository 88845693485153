import { segmentTrackAction } from 'lib/segment'
import { EVENT } from 'lib/segment/const'

/**
 * @param {object} product
 * @param {number} product.id_product
 * @param {object} product.selectedAttributes
 * @param {string} product.selectedAttributes.size
 * @param {number} product.quantity
 * @param {object} product.category
 * @param {number} product.category.id_category
 */
export const segmentTrackCartProductMovedToWishlist = (product) => {
  const data = {
    id_product: product.id_product,
    size: product.selectedAttributes.size,
    quantity: product.quantity,
    id_category: product.category?.id_category,
  }

  segmentTrackAction(EVENT.cartProductMoveToWishlist, data)
}

export default { segmentTrackCartProductMovedToWishlist }
