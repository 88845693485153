export const ORDER_CURRENT_STATUS_CODE = {
  AWAITING_CHEQUE_PAYMENT: 1,
  PAYMENT_ACCEPTED: 2,
  PREPARATION_IN_PROGRESS: 3,
  SHIPPED: 4,
  DELIVERED: 5,
  CANCELED: 6,
  REFUND: 7,
  PAYMENT_ERROR: 8,
  ON_BACKORDER: 9,
  AWAITING_BANK_WIRE_PAYMENT: 10,
  AWAITING_PAYPAL_PAYMENT: 11,
  PAYMENT_REMOTELY_ACCEPTED: 12,
  PENDING: 13,
  TEST_ORDER: 14,
  PACKED: 15,
  BACKORDER_CONFIRMED: 16,
  WH_PROCESSING: 17,
  WMS_CANCELED: 18,
  WMS_NOT_SYNCED: 19,
  WMS_SYNCED: 20,
  DELIVERY_FAILED: 21,
  BANK_WIRE_ACCEPTED: 22,
  BANK_WIRE_FAILED: 23,
  AWAITING_PAYMENT: 24,
  OUT_FOR_DELIVERY: 25,
  AWAITING_FAILED_DELIVERY: 26,
  SUCCESSFUL_PICK_UP: 27,
  SHIPMENT_LOST: 28,
  MP_CANCELED: 29,
}

export const SHIPPING_TYPE_ENUM = {
  NORMAL: 'normal',
  PICKUP: 'pickup',
  PARTNER: 'partner',
}

export const ORDER_SUMMARY_FEE_TYPE = {
  SUB_TOTAL: 'sub_total',
  TTB_FEE: 'ttb_fee',
  TTB_ALL_ACCEPT_DISCOUNT: 'ttb_all_accept_discount',
  SHIPPING: 'shipping',
  TOTAL: 'total',
  TOTAL_TTB_CHARGE: 'total_ttb_charge',
}

export const ORDER_REFUND_SUMMARY_FEE_TYPE = {
  REFUND_STATUS: 'refund_status',
  REFUND_METHOD: 'refund_method',
  REFUND_TOTAL: 'refund_total',
}

export const ORDER_REFUND_STATUS_TYPE = {
  IN_PROGRESS: 'In Progress',
  COMPLETE: 'Complete',
}
