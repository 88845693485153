import Router from 'next/router'
import StateStorage from 'lib/state-storage'
import { getFilterParams } from 'lib/utils/category'
import {
  clearArrayDuplicate,
  getElementStyleProperty,
} from 'lib/utils/common/commonUtils'
import { ITEMS_LIMIT } from './const'

const getSearchBarMargin = () =>
  getElementStyleProperty({
    className: 'search-bar-active',
    key: 'margin',
    pos: 0,
  })

const getSearchResultParams = (filter, page, query, sortData) => {
  const sortAndFilterOptions = {
    'filter[order_by]': sortData,
    ...getFilterParams(filter),
  }

  return JSON.stringify({
    params: {
      query,
      'filter[skip]': page * ITEMS_LIMIT,
      'filter[limit]': ITEMS_LIMIT,
      ...sortAndFilterOptions,
    },
  })
}

const goToSearchPage = (searchWord, callback) => {
  const shop = StateStorage.getShop()
  const language = StateStorage.getLanguage()

  Router.push(
    `/search?term=${encodeURI(searchWord)}`,
    `/${shop}/${language}/search/${encodeURI(searchWord)}`,
  ).then(() => {
    callback()
  })
}

const clearRecentSearch = () => StateStorage.removeCookie('recentSearchList')

const setRecentSearchItems = (recentSearchList) => {
  const searchItemList = recentSearchList.toString()
  StateStorage.setCookie('recentSearchList', searchItemList)
}

const getRecentSearchItems = () => {
  const serializedState = StateStorage.getCookie('recentSearchList')

  if (!serializedState) {
    return undefined
  }

  const recentSearchList = serializedState.split(',')
  if (recentSearchList.length > 10) {
    recentSearchList.pop()
    setRecentSearchItems(recentSearchList)
  }

  return recentSearchList
}

const storeRecentSearchTerm = (term) => {
  let searchItem = getRecentSearchItems() || []
  searchItem.unshift(term)

  if (searchItem.includes(term)) {
    searchItem = clearArrayDuplicate(searchItem)
  }

  if (searchItem.length > 10) {
    searchItem.pop()
  }

  setRecentSearchItems(searchItem)
}

const storeTheSearchWord = (searchWord, setSearchWord) => {
  storeRecentSearchTerm(searchWord)
  sessionStorage.setItem('recent-search-word', searchWord)
  setSearchWord(searchWord)
}

const generateKeywordList = (length) =>
  Array.from({ length }, (_, i) => `Keyword ${i + 1}`)

const getKeywordList = (isRecentSearchListValid, recentSearchList) => {
  const generatedKeywords = generateKeywordList(5)
  const keywordList = isRecentSearchListValid
    ? recentSearchList
    : generatedKeywords

  return keywordList
}

const checkHasFilterQuickSize = (filter, isPhone, quickSizes) => {
  const isFilterValid = Object.values(filter).some(
    ({ values }) => values.length > 0,
  )

  return isPhone ? isFilterValid && quickSizes : isFilterValid
}

export {
  checkHasFilterQuickSize,
  clearRecentSearch,
  getKeywordList,
  getRecentSearchItems,
  getSearchBarMargin,
  getSearchResultParams,
  goToSearchPage,
  storeTheSearchWord,
}
