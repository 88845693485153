import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import ResponsiveImage from 'components/images/responsive-image'
import styles from './styles.scss'

const ProductImage = ({ coverImg, hoverImg, isFade }) => (
  <div
    className={clsx('product__img__wrapper', { product__img__fade: isFade })}
  >
    <style jsx>{styles}</style>
    <ResponsiveImage
      className="product__img__cover"
      src={coverImg}
      imgRatio={1.33}
    />
    {/* TODO: Lazy Load Hover Image display: none with animation */}
    {hoverImg && (
      <ResponsiveImage
        className="product__img__hover"
        src={hoverImg}
        imgRatio={1.33}
      />
    )}
  </div>
)

ProductImage.defaultProps = {
  hoverImg: null,
  isFade: false,
}

ProductImage.propTypes = {
  hoverImg: PropTypes.string,
  coverImg: PropTypes.string.isRequired,
  isFade: PropTypes.bool,
}

export default ProductImage
