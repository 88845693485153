import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import CommonLayout from 'components/layout/common'
import ErrorDefault from 'components/error/error-default'
import ErrorNotAvailable from 'components/error/error-not-available'
import { ERROR_PAGE_TYPE } from 'constants/error'
import { withI18next } from 'lib/i18n/withI18next'
import BasePage from './base'
import { PAGE_NAME } from './const'

class Error extends BasePage {
  static getInitialProps({ store, req, res, isServer }) {
    if (isServer) {
      BasePage.loadInitialContentFromServer({ req, res, store })
    } else {
      BasePage.loadInitialContentFromClient(req, res, store)
    }

    return {}
  }

  render() {
    const { error } = this.props
    let errorHeader

    switch (error) {
      case ERROR_PAGE_TYPE.beetNotAvailable:
        errorHeader = <ErrorNotAvailable />
        break
      case ERROR_PAGE_TYPE.defaultError:
      default:
        errorHeader = ''
        break
    }

    return (
      <CommonLayout showUsp={!!errorHeader} pageName={PAGE_NAME.error}>
        {errorHeader}
        <ErrorDefault showDefaultError={!errorHeader} />
      </CommonLayout>
    )
  }
}

Error.defaultProps = {
  error: ERROR_PAGE_TYPE.defaultError,
  internationalization: {},
  t: (s) => s,
}

Error.propTypes = {
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  internationalization: PropTypes.shape({}),
  t: PropTypes.func,
}

const Extended = withI18next(['global'])(Error)

export { Error }

export default connect((state) => ({
  internationalization: state.internationalization,
}))(Extended)
