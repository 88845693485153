import dayjs from 'dayjs'
import env from 'config/env'
import StateStorage from 'lib/state-storage' // eslint-disable-line import/no-cycle
import {
  SHOP_COUNTRY_ISO_CODES,
  SHOP_COUNTRY_ID_AND_CODE,
  SHOP_COUNTRIES_ID,
} from 'constants/shop-language'
import { getShopInfo } from 'lib/utils/shop-lang'

export const COUNTRY_CODES = SHOP_COUNTRY_ISO_CODES
export const HTTP_STATUS_CODES = { OK: 200 }

export const getAssetPath = () =>
  env.NODE_ENV === 'development' || env.USE_LOCAL_ASSETS
    ? ``
    : `${env.CDN_HOST}www/${env.VERSION}`

export function extractSelectedCountry(res, selectedCountry) {
  let extractedCountry
  if (res) {
    extractedCountry = res.countries && [
      res.countries.find((item) => item.id_country === selectedCountry),
    ]
  }
  return extractedCountry
}

export function removeShopCountriesFromGlobal(res) {
  return res?.countries?.filter(
    (item) => !SHOP_COUNTRIES_ID.includes(item.id_country),
  )
}

export const getCountryCodeWithShopID = (i18n) => {
  const shopID = i18n && i18n.shop ? i18n.shop : '1'
  const chosenCountry = SHOP_COUNTRY_ID_AND_CODE.find((country) => {
    if (shopID === 12) {
      return country.id_country === i18n.id_country
    }

    return country.id_shop === shopID
  })

  return chosenCountry ? chosenCountry.country_code : 1
}

export const getOrderIDFromReference = (reference, prefixNo = '79') =>
  parseInt(
    reference
      ?.toString()
      .replace(`PML${prefixNo}000`, '')
      .replace(`PML${prefixNo}00`, '')
      .replace(`PML${prefixNo}0`, ''),
    10,
  )

export const returnOrderPrefix = (id, prefixNo = '79') => {
  const parsedId = parseInt(id, 10)

  if (parsedId >= 1000000) {
    return `PML${prefixNo}0`
  }
  if (parsedId >= 100000) {
    return `PML${prefixNo}00`
  }
  return `PML${prefixNo}000`
}

export const returnReturnPrefix = (returnID) =>
  returnOrderPrefix(returnID, '81')

export const getISOCurrencyCodeWithShopID = (shop) => {
  const shopInfo = getShopInfo({ key: 'slug', value: shop })
  return shopInfo?.currency_iso_code || ''
}

export const replaceWithDate = (text, replaceWith, replaceText) =>
  text.replace(replaceText, replaceWith)

export const splitTextWith = (text = '', splitWith = '') => {
  if (!text || !splitWith) {
    return null
  }

  return text.split(splitWith)
}

export function insertArrayInsideObject(objA, objB, field) {
  if (field) {
    return { ...objA, ...objB, [field]: [...objA[field], ...objB[field]] }
  }

  return { ...objA, ...objB }
}

export function replaceStringWith(string, replace, replaceWith) {
  if (!string) {
    return null
  }

  return string.split(replace).join(replaceWith)
}

export function getIsBodyFreezed() {
  if (typeof document === 'undefined' || !document.body) {
    return false
  }

  return document.body.classList.contains('freezed')
}

let lastScrollPos = 0

export function toggleBodyFreezing(
  freeze = false,
  onCloseScrollPos = undefined,
) {
  if (typeof document === 'undefined' || !document.body) {
    return
  }

  const { body } = document
  const isBodyFreezed = getIsBodyFreezed()

  if (freeze && !isBodyFreezed) {
    lastScrollPos = document.documentElement.scrollTop || window.pageYOffset
    body.classList.add('freezed')
    body.style.setProperty('top', `-${lastScrollPos}px`, 'important')
  } else if (!freeze && isBodyFreezed) {
    body.classList.remove('freezed')
    body.style.removeProperty('top')
    window.scroll(
      0,
      onCloseScrollPos || onCloseScrollPos === 0
        ? onCloseScrollPos
        : lastScrollPos,
    )
  }
}

export function capitalizeEachWord(string) {
  const capitalizePattern = /^[A-Z]([a-z]+|[A-Z]+)$/g
  if (typeof string === 'string') {
    return string.replace(/\w+/g, (str) =>
      capitalizePattern.test(str) // only convert when it isn't capitalized or isn't uppercase as a whole
        ? str
        : str.charAt(0).toUpperCase() + str.slice(1).toLowerCase(),
    )
  }
  return string
}

export function capitalize(string) {
  if (typeof string === 'string') {
    return string.charAt(0).toUpperCase() + string.slice(1)
  }
  return string
}

export function appendObjToSessionObj(sessionKey = '', newObj = {}) {
  try {
    const curObj = JSON.parse(sessionStorage.getItem(sessionKey)) || {}

    sessionStorage.setItem(sessionKey, JSON.stringify({ ...curObj, ...newObj }))
  } catch (e) {
    throw new Error(e)
  }
}

export function getSessionStringAsObj(sessionKey = '') {
  try {
    return JSON.parse(sessionStorage.getItem(sessionKey))
  } catch (e) {
    throw new Error(e)
  }
}

export function separateTimeDay(hours) {
  if (!hours) {
    return null
  }

  const [day, ...time] = hours.split(':')
  let parsedTime = '-'

  if (time) {
    parsedTime = time.toString().split(',').join(':')
  }

  const data = {
    day: day || '-',
    time: parsedTime,
  }

  return data
}

export function getCountryIdFromCountryCookie(cookieCountry) {
  const isValid =
    cookieCountry && cookieCountry !== 'null' && cookieCountry !== 'undefined'
  let countryId = isValid ? JSON.parse(cookieCountry) : 0

  // Handle for the old format.
  if (countryId && countryId.id_country) {
    countryId = countryId.id_country
  }

  return countryId
}

const mapFilterToPropName = (id, valueList) => {
  switch (id) {
    case 'categories':
      return { category_filter: valueList }
    case 'sustainability':
      return { sustainability: valueList }
    case 'price':
      return {}
    default:
      return id && { [`${id?.replace(' ', '_')}_filter`]: valueList }
  }
}

export function getActiveFilters(filterOptions) {
  if (!filterOptions) {
    return {}
  }

  return filterOptions.reduce((activeFilters, filter) => {
    if (filter?.id && filter?.values?.length) {
      const { activeKeys, id, values } = filter
      const filterId = id.toLowerCase()

      return {
        ...activeFilters,
        ...mapFilterToPropName(filterId, values),
        ...(filterId === 'sustainability' && {
          sustainability: activeKeys,
        }),
        ...(filterId === 'price' &&
          values.length > 1 && {
            price_filter_min: parseInt(values[0], 10),
            price_filter_max: parseInt(values[1], 10),
          }),
      }
    }

    return activeFilters
  }, {})
}

export function adjustMobileSideNavPosition(onlyPadding = false) {
  const topNavEl = document.getElementsByClassName('top-nav')
  const menuEl = document.getElementsByClassName('bm-menu')

  if (menuEl?.length && topNavEl?.length) {
    menuEl[0].style.padding = `0 0 ${topNavEl[0].getBoundingClientRect().top}px`
  }

  if (onlyPadding) {
    return
  }

  let heightCap = 0

  const overlayEl = document.getElementsByClassName('bm-overlay')
  const menuWrapEl = document.getElementsByClassName('bm-menu-wrap')

  if (topNavEl?.length && topNavEl[0].getBoundingClientRect().top <= 0) {
    heightCap = '0px'
  } else {
    heightCap = 'auto'
  }

  if (overlayEl?.length) {
    overlayEl[0].style.top = heightCap
  }

  if (menuWrapEl?.length) {
    menuWrapEl[0].style.top = heightCap
  }
}

/** @param {string | number} productId */
export function getParsedProductId(productId) {
  if (!productId || productId === '0') {
    return false
  }

  const parsedProductId = parseInt(productId, 10)

  if (Number.isNaN(parsedProductId) || parsedProductId === 0) {
    return false
  }

  return parsedProductId
}

export function getAuthStatus(auth) {
  if (!auth?.customerData?.isFetched) {
    return { isFetched: false, isGuest: false, isLoyaltyMember: false }
  }

  return {
    isFetched: true,
    isGuest: !auth.user,
    isLoyaltyMember: !!(auth.user && auth.customerData.loyalty_signup),
  }
}

// Return true if the auth is reset and the page will be reloaded.
export function checkResetAuth(autoReload = true) {
  const isTokenExpired = StateStorage.getCookie('isTokenExpired')
  const token = StateStorage.getAuthToken()
  const newAuthKey = 'isNewAuth'
  const isNewAuthReset = localStorage.getItem(newAuthKey)
  localStorage.setItem(newAuthKey, true)

  if (isTokenExpired === 'true' || (!isNewAuthReset && token)) {
    StateStorage.removeCookie('isTokenExpired')
    StateStorage.removeAuthUser()

    if (autoReload) {
      window.location.reload()
    }

    return true
  }

  return false
}

/**
 *
 * @returns {string}
 */
export function generatePassword() {
  const pwdChars =
    '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'

  return Array(10)
    .fill()
    .map(() => pwdChars[Math.trunc(Math.random() * pwdChars.length)])
    .join('')
}

/**
 * Use this function to get the Accept-Language header for API requests
 * @param {string} mode default is 'sync' can be config to 'check' for checking sync status only
 */
export const syncShopAndLangWithUrl = (mode = 'sync') => {
  // To evade the test failure
  const isPracticalEnv =
    env.ENVIRONMENT in ['staging', 'pre-production', 'production']
  if (!isPracticalEnv && window?.location?.href === 'http://localhost/') {
    return null
  }

  const currentHref = window?.location?.href?.match(/[^/]+/g)
  const currentShop = currentHref[2]
  const currentLang = currentHref[3]

  const cachedShop = StateStorage.getShop()
  const cachedLang = StateStorage.getLanguage()

  const isShopSync = cachedShop !== currentShop
  const isLangSync = cachedLang !== currentLang

  if (mode === 'sync' && isShopSync) {
    StateStorage.saveShop(currentShop)
  }

  if (mode === 'sync' && isLangSync) {
    StateStorage.saveLanguage(currentLang)
  }

  let returnData = [currentShop, currentLang]
  if (mode === 'check') {
    returnData = [isShopSync, isLangSync]
  }

  return returnData
}

/**
 * Use this function to check that value is not undefined or null
 * It returns true if the value is truthy, false otherwise
 * @param value - The value to check.
 */
export const isStringValueTruthy = (value) =>
  !!value && value !== 'undefined' && value !== 'null'

/**
 * Function to copy things to clipboard
 * @param {string} code - The code that would like to copy to the clipboard.
 */
export const copyToClipboard = (code) => {
  navigator.clipboard.writeText(code)
}

/**
 * Function to save session state data iteratively
 * @param {array} stateObj       - The object contains the session state data
 * @param {any} stateObj.payload - The data payload you want to save
 */
export const iterativeSaveSessionState = (stateObj) => {
  Object.keys(stateObj).forEach((key) => {
    const data = stateObj[key]
    sessionStorage.setItem(key, JSON.stringify(data.payload))
  })
}

/**
 * Function to get session state data iteratively
 * @param {array} stateKeyList - The array contains the session state keys
 */
export const iterativeGetSessionState = (stateKeyList) => {
  const sessionStorageDataList = []
  stateKeyList.forEach((key) => {
    const parsedData = JSON.parse(sessionStorage.getItem(key))
    sessionStorageDataList.push(parsedData)
  })
  return sessionStorageDataList
}

/**
 * Function to remove session state data iteratively
 * @param {array} stateKeyList - The array contains the session state keys
 */
export const iterativeRemoveSessionState = (stateKeyList) => {
  stateKeyList.forEach(
    (key) => !!sessionStorage.getItem(key) && sessionStorage.removeItem(key),
  )
}

/**
 * Function to replace all the numbers in the formatted price and replace with provided price.
 * @param {string} formattedPrice - The formatted price contains a currency symbol
 * @param {number} replacePrice   - The price to replace in the formatted price number
 */
export const replaceFormattedPriceWithUpdatedPrice = (
  formattedPrice,
  replacePrice,
) => formattedPrice.replace(',', '').replace(/\d+\.*\d*/g, replacePrice)

/**
 *
 * Function to detect the page scrollability
 */
export const getIsPageScrollable = () => {
  if (!global?.document) {
    return false
  }

  return (
    global.document.documentElement.scrollHeight >
    global.document.documentElement.clientHeight
  )
}

/**
 * Function to find the particular element by its class name
 * @param {string} className - The class name of the element you desire to find
 * @param {number} pos       - The element position in HTMLCollection
 */
export const getElementByClassName = (className, pos) => {
  let element
  if (typeof document !== 'undefined') {
    element = document.getElementsByClassName(className)
  }
  return element && element[pos]
}

/**
 * Function to get the style property value of the particular element
 * @param {string} className - The class name of the element you desire to find
 * @param {string} key       - The key of css property you want to find
 * @param {number} pos       - The element position in HTMLCollection
 * @param {object} htmlElem  - The HTML element
 */
export const getElementStyleProperty = ({
  className,
  key,
  pos,
  htmlElem = undefined,
}) => {
  let value
  const element = !htmlElem ? getElementByClassName(className, pos) : htmlElem

  if (element && typeof window !== 'undefined') {
    value = window.getComputedStyle(element)[key]
  }

  return value
}

/**
 * Function to set the style property value of the particular element
 * @param {string} className              - The class name of the element you desire to set its style
 * @param {object} propToSet              - The css property object contains key and value related to the particular propery
 * @param {string} propToSet.key          - The css property name
 * @param {string|number} propToSet.value - The css property value
 * @param {number} pos                    - The element position in HTMLCollection
 */
export const setElementStyleProperty = (className, propToSet, pos) => {
  const element = document.getElementsByClassName(className)
  if (element) {
    document
      .getElementsByClassName(className)
      [pos]?.style?.setProperty(propToSet.key, propToSet.value)
  }
}

// https://stackoverflow.com/a/55164486
/**
 * Function to throttle (delay) your callback function inside useEffect
 * @param {function} callback - The callback function that you want to throttle in addEventListener
 * @param {number} limit      - The timeout time in milisecond
 */
export const throttleCallback = (callback, limit) => {
  let wait = false
  return (...args) => {
    if (!wait) {
      callback(...args)
      wait = true
      setTimeout(() => {
        wait = false
      }, limit)
    }
  }
}

/**
 * Function to check if the flag is in the AB test experiment or not
 * @param {string|boolean} flagValue - A LaunchDarkly flag value
 */
export const isABTestVariation = (flagValue) => ['A', 'B'].includes(flagValue)

/**
 * Function to check if the flag is not deserve to allow particular content to be displayed or not
 * @param {string|boolean} flagValue - A LaunchDarkly flag value
 */
export const isFlagEligible = (flagValue) => !['OFF', 'A'].includes(flagValue)

/**
 * This function will provide bottom space for `<AdsBanner />` base on linear equation `[y=m(x)+b]` and the value will between `MINIMUM_BOTTOM_GAP` to `MAXIMUM_BOTTOM_GAP`.
 * @param {number} percentageHorizontalScroll
 */
export const calculateBottomGapOfAdsBanner = (percentageHorizontalScroll) => {
  const MINIMUM_BOTTOM_GAP = 16
  const MAXIMUM_BOTTOM_GAP = 70
  const MINIMUM_PERCENTAGE = 98
  const MAXIMUM_PERCENTAGE = 100
  const SLOPE =
    (MAXIMUM_BOTTOM_GAP - MINIMUM_BOTTOM_GAP) /
    (MAXIMUM_PERCENTAGE - MINIMUM_PERCENTAGE)
  const Y_INTERCEPT = SLOPE * MINIMUM_PERCENTAGE - MINIMUM_BOTTOM_GAP

  // NOTE: Scrollbar is not available.
  if (Number.isNaN(percentageHorizontalScroll)) {
    return MINIMUM_BOTTOM_GAP * 2
  }

  return Math.min(
    Math.max(
      SLOPE * percentageHorizontalScroll - Y_INTERCEPT,
      MINIMUM_BOTTOM_GAP,
    ),
    MAXIMUM_BOTTOM_GAP,
  )
}

/**
 * Function to check if the flag is not deserve to allow particular content to be displayed or not
 * @param {string} key - The session storage key you want to pull the data from
 */
export const getSessionStorageOnInit = (key) => {
  let value = ''

  if (global?.window?.sessionStorage) {
    value = sessionStorage.getItem(key)
  }

  return value
}

/**
 * Function to clear duplicate items in array
 * @param {array} array - The array you want to remove the duplicate elements
 */
export const clearArrayDuplicate = (array) => [...new Set(array)]

/**
 * Function to return a user id if valid or return id = 'guest' otherwise
 * @param {object} auth - The auth object contains user data
 */
export const getUserId = (auth) => {
  if (auth && !auth.isGuestMode) {
    return auth.user?.id_customer
  }
  return 'guest'
}

/**
 * Function to return a boolean if both object is the same or not
 * @param {object} obj1 - The first object to compare
 * @param {object} obj2 - The second object to compare
 */
export const isObjectStrictlyIdentical = (obj1, obj2) => {
  const firstObjKeys = Object.keys(obj1)
  const secondObjKeys = Object.keys(obj2)

  if (
    typeof obj1 !== typeof obj2 ||
    firstObjKeys.length !== secondObjKeys.length
  ) {
    return false
  }

  return firstObjKeys.every((key) => obj1[key] === obj2[key])
}

/**
 * Function to return a boolean if the web was render on the app web-view
 */
export const isAppWebView = () =>
  global.document && document.body.classList.contains('app-webview')

/**
 * Function to return formatted date from shop and lang.
 * @param {string} date - string of date object to be fomatted.
 * @param {number} shop - Number of shop.
 * @param {number} locale - Number of lang.
 * @param {string} dateFormat - string for date format.
 */
export const formatDate = (date, shop, locale, dateFormat) => {
  if (shop === 1 && locale === 2) {
    return dayjs(date).locale('th').format(dateFormat)
  }

  return dayjs(date).format(dateFormat)
}

/**
 * Function to return product url.
 * @param {object} product - product object to get url
 */
export const getProductUrl = (product) => {
  const shop = StateStorage.getShop()
  const language = StateStorage.getLanguage()

  return `/${shop}/${language}/${product.category_link_rewrite}/${product.id_product}-${product.link_rewrite}.html`
}

export const detectUserBrowser = () => {
  const userAgent = window?.navigator?.userAgent
  const browserRegex = {
    chrome: /chrome|chromium|crios/i,
    firefox: /firefox|fxios/i,
    safari: /safari/i,
    edge: /edg/i,
  }

  const keys = Object.keys(browserRegex)
  const values = Object.values(browserRegex)
  let browserName

  browserName = keys.find((key, i) => userAgent?.match(values[i]))

  if (!browserName) {
    browserName = 'Unknown'
  }

  return browserName
}

export const detectUserOperatingSystem = () => {
  if (/android/i.test(window?.navigator?.userAgent)) {
    return 'Android'
  }

  if (/iP(ad|hone|od)/.test(window?.navigator?.platform)) {
    return 'iOS'
  }

  return 'Unknown'
}
