import PropTypes from 'prop-types'
import React from 'react'
import clsx from 'clsx'
import { goToSearchPage } from 'components/search/utils'
import { withI18next } from 'lib/i18n/withI18next'
import { segmentSearchItemClicked } from 'lib/segment'
import { hasLocizeTranslation } from 'lib/utils/locize'
import Typo from 'constants/typography'

const getKeywordLocizeKey = (index) => `POPULAR_SEARCH_KEYWORD_${index + 1}`

const SearchSidebarItem = ({
  keywordList,
  onClose,
  isRecentSearchValid,
  setSearchWord,
  t,
}) => {
  const searchItem = (e) => {
    const innerText = e.target.innerHTML

    const searchClickedData = {
      clicked_keyword: innerText,
      isRecentSearchValid,
    }
    segmentSearchItemClicked(searchClickedData)

    sessionStorage.setItem('recent-search-word', innerText)
    setSearchWord(innerText)

    goToSearchPage(innerText, onClose)
  }

  return (
    <div className="search-sidebar__item__container">
      {keywordList?.map((item, index) => (
        <button
          className={clsx('search-sidebar__item', Typo.body2)}
          key={item}
          onClick={(e) => searchItem(e)}
        >
          {isRecentSearchValid
            ? item
            : hasLocizeTranslation(t, getKeywordLocizeKey(index), item)}
        </button>
      ))}
    </div>
  )
}

SearchSidebarItem.propTypes = {
  isRecentSearchValid: PropTypes.bool,
  keywordList: PropTypes.arrayOf(PropTypes.string),
  onClose: PropTypes.func.isRequired,
  setSearchWord: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
}

SearchSidebarItem.defaultProps = {
  isRecentSearchValid: false,
  keywordList: [],
}

export default withI18next()(SearchSidebarItem)
