// 1  = web ecom
// 5  = at PMLO store
// 58 = MY shop TTB
const allowedOrderTypeId = [1, 5, 58]

const DEBOUNCE_TIME = 5000
const BASE_PRODUCT_LIMIT = 5

// bca, mandiri, lainnya = virtual bank transfer
// bni                   = manual bank transfer
// cod                   = cash on delivery
// credit                = credit card
// free                  = free order
// installment           = Shopback (Hoolah) installments
// pick up, store        = pick up at PMLO store
const paymentTypeArray = [
  'bca',
  'bni',
  'cod',
  'credit',
  'free',
  'installment',
  'mandiri',
  'pick up',
  'store',
]

const SNACKBAR_MOVE_TO_WISHLIST_FROM_CART = {
  key: 'SNACKBAR_MOVE_TO_WISHLIST_FROM_CART',
  text: 'Item was removed from your shopping bag and added to the wishlist',
}

export {
  allowedOrderTypeId,
  DEBOUNCE_TIME,
  paymentTypeArray,
  BASE_PRODUCT_LIMIT,
  SNACKBAR_MOVE_TO_WISHLIST_FROM_CART,
}
