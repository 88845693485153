import { useMemo } from 'react'
import Link from 'next/link'
import { translate } from 'react-i18next'
import PropTypes from 'prop-types'
import Button from 'components/common/button'
import Dialog from 'components/common/dialog'
import Icon from 'components/common/icon'
import ICONS, { ICON_SIZE } from 'components/common/icon/const'
import { BUTTON_TYPES } from 'constants/button'
import { hasLocizeTranslation } from 'lib/utils/locize'
import Typo from 'constants/typography'
import BackdropModal from 'components/portal/backdrop-modal'
import styles from './styles.scss'

/**
 *
 * @param {boolean} param0.showCta set to display action button
 * @param {boolean} param0.open set to open modal
 * @param {function} param0.onBackdropClick set the handler of `click` back drop event
 * @param {function} param0.t
 */
const ModalNoReturn = ({ showCta, open, onBackdropClick, t }) => {
  const [title, subTitle, content, cta] = useMemo(
    () => [
      hasLocizeTranslation(
        t,
        'NO_RETURN_POLICY_TITLE',
        'What is your Returns Policy?',
      ),
      hasLocizeTranslation(
        t,
        'NO_RETURN_POLICY_SUB_TITLE',
        'Returns and Refunds',
      ),
      hasLocizeTranslation(t, 'NO_RETURN_POLICY_CONTENT', 'Not returnable'),
      hasLocizeTranslation(t, 'NO_RETURN_POLICY_CTA', 'LEARN MORE'),
    ],
    [t],
  )

  return (
    <BackdropModal
      freezeBody={false}
      isOpen={open}
      onBackdropClick={onBackdropClick}
      zIndex={1003}
    >
      <style jsx>{styles}</style>
      <Dialog
        closable
        onClose={onBackdropClick}
        title={
          <div className="no-return-modal__header">
            <div className={Typo.h6}>{title}</div>
            <div className={Typo.caption}>{subTitle}</div>
          </div>
        }
      >
        <div className="no-return-modal__content">
          <div>
            <Icon src={ICONS.noReturn} size={ICON_SIZE.large} />
          </div>
          <div className="no-return-modal__info">{content}</div>
        </div>
        {showCta && (
          <Link href="/info/returns">
            <Button link type={BUTTON_TYPES.text}>
              {cta}
            </Button>
          </Link>
        )}
      </Dialog>
    </BackdropModal>
  )
}

ModalNoReturn.defaultProps = {
  showCta: undefined,
  open: undefined,
  onBackdropClick: undefined,
  t: undefined,
}

ModalNoReturn.propTypes = {
  showCta: PropTypes.bool,
  open: PropTypes.bool,
  onBackdropClick: PropTypes.func,
  t: PropTypes.func,
}

export default translate('global')(ModalNoReturn)
