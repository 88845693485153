import env from 'config/env'
import { getParsedProductId } from 'lib/utils/common/commonUtils'
import { request } from 'lib/api'
import { HTTP_METHODS } from 'lib/api/const'

export const API_INVALID_PRODUCT_RES = {
  status: 404,
  body: {
    error: {
      status: 404,
      message: 'Invalid product',
      code: 'INVALID_PRODUCT',
    },
  },
}

class Product {
  static API_HOST = env.API_HOST

  static async getProduct(options) {
    let userUID = null
    if (options.userUId) {
      userUID = options.userUId
    }

    let segmentAnonymousID = null
    if (options.segmentAnonymousID) {
      segmentAnonymousID = options.segmentAnonymousID
    }

    let device = null
    if (options.device) {
      device = options.device
    }

    const productId = getParsedProductId(options.product_id)

    if (!productId) {
      throw API_INVALID_PRODUCT_RES
    }

    const requestUrl = `v6/products/${productId}`

    const requestOptions = {
      token: options.token,
      method: HTTP_METHODS.get,
      url: requestUrl,
      userUID,
      segmentAnonymousID,
      device,
    }

    requestOptions.shop = options.shop || ''
    requestOptions.lang = options.language || options.lang || ''

    const requestResult = await request(requestOptions)
    return requestResult
  }

  static addToWaitList({
    product_attribute_id: productAttributeId,
    product_id: productId,
  }) {
    return request({
      url: `v6/products/${productId}/notify-me`,
      method: HTTP_METHODS.post,
      body: {
        id_product_attribute: productAttributeId,
      },
    })
  }
}

export default Product
