import clsx from 'clsx'
import PropTypes from 'prop-types'
import React from 'react'
import Icon from 'components/common/icon'
import ICONS, { ICON_SIZE } from 'components/common/icon/const'
import Typo from 'constants/typography'
import { withI18next } from 'lib/i18n/withI18next'
import styles from '../style.scss'

const EmptyProductSection = ({ isFilterAvailable, isSearchPage, t }) => {
  if (isSearchPage) {
    return null
  }

  return (
    <div
      className={clsx('empty-results', Typo.body2, {
        'extend-bottom-margin': !isSearchPage,
      })}
    >
      <style jsx>{styles}</style>
      <Icon
        src={ICONS.emptySearch}
        size={ICON_SIZE.extraLarge}
        className="empty-results-icon"
      />
      <div className={clsx(Typo.h6, 'empty-results-title')}>
        {t('No products found')}
      </div>
      {isFilterAvailable && (
        <div className={clsx(Typo.caption, 'empty-results-sub-title')}>
          {t('Please remove a few filters')}
        </div>
      )}
    </div>
  )
}

EmptyProductSection.defaultProps = {
  isFilterAvailable: false,
  isSearchPage: false,
}

EmptyProductSection.propTypes = {
  isFilterAvailable: PropTypes.bool,
  isSearchPage: PropTypes.bool,
  t: PropTypes.func.isRequired,
}

export default withI18next()(EmptyProductSection)
