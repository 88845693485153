import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import BackdropModal from 'components/portal/backdrop-modal'
import { hasLocizeTranslation } from 'lib/utils/locize'
import { withI18next } from 'lib/i18n/withI18next'
import compose from 'recompose/compose'
import Typo from 'constants/typography'
import Button from 'components/common/button'
import { BUTTON_TYPES } from 'constants/button'
import Radio from 'components/common/radio'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import CartDuck from 'components/cart/duck'
import styles from './quick-add-to-bag-modal.scss'
import segmentQuickAddToBagClicked from './quick-add-to-bag-segment/quickAddToBagSegment'

const QuickAddToBagModal = ({
  t,
  isPhone,
  sizes,
  id_product,
  id_site_category,
  product_name,
  addToCart,
  usedCredit,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const openModal = useCallback(() => setIsModalOpen(true), [setIsModalOpen])
  const closeModal = useCallback(() => setIsModalOpen(false), [setIsModalOpen])

  const handleClickSize = async (id_product_attribute, size) => {
    try {
      segmentQuickAddToBagClicked({
        id_product,
        size,
        product_name,
        id_site_category,
      })
      await addToCart({
        quantity: 1,
        type: 'AddToCart',
        ignoreCache: true,
        productID: id_product,
        productAttributeID: id_product_attribute,
        body: {
          is_store_credits_applied: usedCredit,
        },
      })
    } catch (error) {
      // eslint-disable-next-line
      console.error(error)
    }
  }

  return (
    <React.Fragment>
      <style jsx>{styles}</style>
      <Button
        onClick={openModal}
        type={BUTTON_TYPES.text}
        cy="quick-add-to-bag-modal__button"
        className="quick-add-to-bag-modal__button"
      >
        {hasLocizeTranslation(t, 'ADD_TO_BAG', 'ADD TO BAG')}
      </Button>
      <BackdropModal
        zIndex={2000}
        freezeBody={isPhone}
        isOpen={isModalOpen}
        onBackdropClick={closeModal}
      >
        <div className="quick-add-to-bag-modal__wrapper">
          <span
            className={clsx(Typo.subtitle1, 'quick-add-to-bag-modal__title')}
          >
            {t('Choose your size')}
          </span>
          <hr className="quick-add-to-bag-modal__line" />
          {sizes?.map((sizeData) => (
            <div
              key={sizeData?.id_product_attribute}
              className="quick-add-to-bag-modal__content"
            >
              <button
                data-cy={`quick-add-to-bag-modal__attr-${sizeData?.size}`}
                className={clsx('quick-add-to-bag-modal__attr', {
                  'out-of-stock': sizeData?.stock?.is_sold_out,
                })}
                type="button"
                disabled={sizeData?.stock?.is_sold_out}
                onClick={() =>
                  handleClickSize(
                    sizeData?.id_product_attribute,
                    sizeData?.size,
                  )
                }
              >
                <Radio className="quick-add-to-bag-modal__circle" />
                <span
                  className={clsx(
                    Typo.body2,
                    'quick-add-to-bag-modal__label-body',
                  )}
                >
                  {sizeData?.size}
                </span>
              </button>
            </div>
          ))}
        </div>
      </BackdropModal>
    </React.Fragment>
  )
}

QuickAddToBagModal.defaultProps = {
  // component props
  id_product: null,
  product_name: '',
  id_site_category: null,
  sizes: [],
}

QuickAddToBagModal.propTypes = {
  // component props
  t: PropTypes.func.isRequired,
  id_product: PropTypes.number,
  id_site_category: PropTypes.number,
  product_name: PropTypes.string,
  sizes: PropTypes.arrayOf(
    PropTypes.shape({
      size: PropTypes.string,
      id_product_attribute: PropTypes.number,
      stock: PropTypes.shape({
        quantity: PropTypes.number,
        is_low_stock: PropTypes.bool,
        is_sold_out: PropTypes.bool,
      }),
    }),
  ),

  // redux props
  isPhone: PropTypes.bool.isRequired,
  addToCart: PropTypes.func.isRequired,
  usedCredit: PropTypes.bool.isRequired,
}

export default compose(
  connect(
    (state) => ({
      isPhone: state.device.isPhone,
      usedCredit: state.cartData.isStoreCreditUsed,
    }),
    (dispatch) =>
      bindActionCreators(
        {
          addToCart: CartDuck.creators.get,
        },
        dispatch,
      ),
  ),
  withI18next(),
)(QuickAddToBagModal)
