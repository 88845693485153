import { useState, useCallback } from 'react'
import { getCountryCodeWithShopID } from 'lib/utils/common/commonUtils'
import { locizeTranslation } from 'lib/utils/locize'
import signupFormLocizes, {
  formConfig,
  INPUT_NAME_EMAIL,
  INPUT_NAME_FIRST,
  INPUT_NAME_LAST,
  INPUT_NAME_PASSWORD,
  noWhiteSpacePrefixSuffix,
  specialCharAndNumberRegex,
} from './const'

function extractFieldHelperMessages({ errors, phoneHelperLabel }) {
  return {
    email: {
      error: !!errors[INPUT_NAME_EMAIL],
      helperText: errors[INPUT_NAME_EMAIL]?.message,
    },
    firstName: {
      error: !!errors[INPUT_NAME_FIRST],
      helperText: errors[INPUT_NAME_FIRST]?.message,
    },
    lastName: {
      error: !!errors[INPUT_NAME_LAST],
      helperText: errors[INPUT_NAME_LAST]?.message,
    },
    phoneNo: {
      error: errors.phoneNO?.message,
      helperText: phoneHelperLabel,
    },
    password: {
      error: !!errors[INPUT_NAME_PASSWORD],
      helperText: errors[INPUT_NAME_PASSWORD]?.message,
    },
  }
}

function createFormConfig({
  firstNamePatternError,
  firstNameMaxError,
  invalidEmailFormat,
  lastNamePatternError,
  lastNameMaxError,
  phoneLengthError,
  phonePatternError,
  validatePassword,
  t,
  register,
}) {
  const phoneConfig = {
    'data-cy': 'signup__phone_input',
    required: true,
    type: 'tel',
    id: 'phone',
  }
  const emailRegister = register(
    formConfig.email({
      isRequired: true,
      requiredMessage: t('Required'),
      patternErrorMessage: invalidEmailFormat,
    }),
  )
  const firstNameRegister = register({
    required: t('Required'),
    validate: (value) =>
      (!specialCharAndNumberRegex.test(value) &&
        noWhiteSpacePrefixSuffix.test(value)) ||
      firstNamePatternError,
    maxLength: {
      value: 25,
      message: firstNameMaxError,
    },
  })
  const lastNameRegister = register({
    required: t('Required'),
    validate: (value) =>
      (!specialCharAndNumberRegex.test(value) &&
        noWhiteSpacePrefixSuffix.test(value)) ||
      lastNamePatternError,
    maxLength: {
      value: 25,
      message: lastNameMaxError,
    },
  })
  const phonePrefixRegister = register({ required: true })
  const phoneRegister = register(
    formConfig.phone({
      isRequired: true,
      requiredMessage: t('Required'),
      lengthErrorMessage: phoneLengthError,
      patternErrorMessage: phonePatternError,
    }),
  )
  const passwordRegister = register({
    required: true,
    validate: validatePassword,
  })
  return {
    phoneConfig,
    emailRegister,
    firstNameRegister,
    lastNameRegister,
    phonePrefixRegister,
    phoneRegister,
    passwordRegister,
  }
}

const useSignupForm = ({
  formState,
  internationalization,
  passwordHelperLabel,
  t,
  register,
  isPhoneSignup,
  applyPasswordPolicy,
}) => {
  const [
    invalidEmailFormat,
    firstNameMaxError,
    firstNamePatternError,
    lastNameMaxError,
    lastNamePatternError,
    emailDupError,
    phoneDupError,
    phonePatternError,
    phoneLengthError,
    passwordLengthError,
  ] = locizeTranslation(t, signupFormLocizes.errors)

  const [selectedPhonePrefix, setSelectedPhonePrefix] = useState(
    getCountryCodeWithShopID(internationalization).toString(),
  )
  const [isLoading, setIsLoading] = useState(false)
  const [passwordFormat, setPasswordFormat] = useState({
    isNumber: false,
    isUpperCase: false,
    isLowerCase: false,
    isValidLength: false,
  })
  const { isValid } = formState
  const isValidFormat = applyPasswordPolicy
    ? Object.keys(passwordFormat).every((item) => passwordFormat[item] === true)
    : true
  const isDisabledSubmit =
    !isValid || (!isPhoneSignup && !isValidFormat) || isLoading

  const validatePassword = useCallback(
    (value) => {
      setPasswordFormat({
        isNumber: /\d/.test(value),
        isUpperCase: /[A-Z]/.test(value),
        isLowerCase: /[a-z]/.test(value),
        isValidLength: value.length >= 8 && value.length <= 25,
      })
      if (value.length > 25) return passwordLengthError
      return null
    },
    [passwordLengthError],
  )

  return {
    ...createFormConfig({
      firstNamePatternError,
      firstNameMaxError,
      invalidEmailFormat,
      lastNamePatternError,
      lastNameMaxError,
      passwordHelperLabel,
      phoneLengthError,
      phonePatternError,
      passwordLengthError,
      validatePassword,
      t,
      register,
    }),
    isDisabledSubmit,
    emailDupError,
    phoneDupError,
    selectedPhonePrefix,
    setIsLoading,
    setSelectedPhonePrefix,
    passwordFormat,
  }
}

export { extractFieldHelperMessages, useSignupForm }
