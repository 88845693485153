import React from 'react'
import PropTypes from 'prop-types'
import { withI18next } from 'lib/i18n/withI18next'
import { hasLocizeTranslation } from 'lib/utils/locize'
import Typo from 'constants/typography'
import styles from './styles.scss'

const ProductLabel = ({
  t,
  isSoldOut,
  isBackInStock,
  isLowStock,
  quantity,
}) => {
  let label

  if (isSoldOut) {
    label = hasLocizeTranslation(
      t,
      'SOLD_OUT_ITEM',
      'Sold Out - Notify Me or Find Item at Store',
    )
  } else if (isLowStock) {
    label = hasLocizeTranslation(t, 'ONLY_ITEM_LEFT', 'Only %s left').replace(
      /%s/g,
      quantity,
    )
  } else if (isBackInStock) {
    label = t('Back In Stock')
  }

  if (!label) {
    return null
  }

  return (
    <div className="product__label">
      <style jsx>{styles}</style>
      <span className={Typo.overline}>{label}</span>
    </div>
  )
}

ProductLabel.defaultProps = {
  isSoldOut: false,
  isBackInStock: false,
  isLowStock: false,
  quantity: null,
}

ProductLabel.propTypes = {
  isSoldOut: PropTypes.bool,
  isBackInStock: PropTypes.bool,
  isLowStock: PropTypes.bool,
  quantity: PropTypes.number,
  t: PropTypes.func.isRequired,
}

export default withI18next()(ProductLabel)
