import clsx from 'clsx'
import PropTypes from 'prop-types'
import Typo from 'constants/typography'
import Icon, { ICONS, ICON_SIZE } from 'components/common/icon'
import NoReturnNoted from 'components/no-return-noted'
import StoreCreditNoted from 'components/store-credit-noted'
import { useFlagsmithGetFlagValue } from 'lib/flagsmith'
import RemoveItemNoted from './remove'
import styles from './styles.scss'

const CartProductNoted = ({
  product,
  selectedAttribute,
  showCta,
  isShoppingBagNewDesign,
  fromSummary,
  isShowNoReturnNoted,
}) => {
  const isTTBDepositEnable = useFlagsmithGetFlagValue(
    'TTBDeposit_20231229',
    false,
  )
  const isGift = product.type === 'gift'
  const isSoldOut = selectedAttribute.stock.is_sold_out
  const isSale = product.is_sale && !isGift
  const isUnavailable = !product.is_available
  const returnable = product.is_returnable
  const isItemNeedRemove = isSoldOut || isUnavailable
  const storeCreditOnly = !isGift && isSale && !isItemNeedRemove
  const noNotedDisplay = !isItemNeedRemove && !isSale && returnable
  const isDisplayOnlySummary =
    isShoppingBagNewDesign &&
    (!returnable || storeCreditOnly) &&
    !isItemNeedRemove
  let child

  if (noNotedDisplay || (!fromSummary && isDisplayOnlySummary)) {
    return null
  }

  const iconSrc = isItemNeedRemove ? ICONS.promoError : ICONS.returnsUSP

  if (isItemNeedRemove) {
    child = <RemoveItemNoted />
  } else if (!returnable) {
    if (isTTBDepositEnable) {
      child = isShowNoReturnNoted && <NoReturnNoted showCta={showCta} />
    } else {
      child = <NoReturnNoted showCta={showCta} />
    }
  } else if (storeCreditOnly) {
    child = <StoreCreditNoted />
  }

  return (
    <div
      className={clsx(Typo.caption, 'cart-product-noted', {
        'new-design': isShoppingBagNewDesign,
      })}
    >
      <style jsx>{styles}</style>
      {isShoppingBagNewDesign && (
        <Icon size={ICON_SIZE.small} src={iconSrc} alt="cart noted icon" />
      )}
      {child}
    </div>
  )
}

CartProductNoted.defaultProps = {
  showCta: undefined,
  isShoppingBagNewDesign: false,
  isShowNoReturnNoted: false,
  fromSummary: false,
}

CartProductNoted.propTypes = {
  product: PropTypes.shape({
    is_sale: PropTypes.bool,
    is_available: PropTypes.bool,
    is_returnable: PropTypes.bool,
    type: PropTypes.string,
  }).isRequired,
  selectedAttribute: PropTypes.shape({
    stock: PropTypes.shape({
      is_sold_out: PropTypes.bool,
    }),
  }).isRequired,
  isShowNoReturnNoted: PropTypes.bool,
  showCta: PropTypes.bool,
  isShoppingBagNewDesign: PropTypes.bool,
  fromSummary: PropTypes.bool,
}

export default CartProductNoted
