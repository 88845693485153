export default class {
  constructor(r, g, b) {
    this.set(r, g, b)
  }

  toString() {
    return `rgb(${Math.round(this.r)}, ${Math.round(this.g)}, ${Math.round(
      this.b,
    )})`
  }

  set(r, g, b) {
    this.r = this.clamp(r)
    this.g = this.clamp(g)
    this.b = this.clamp(b)
  }

  hueRotate(angle = 0) {
    const sanitizeAngle = (angle / 180) * Math.PI
    const sin = Math.sin(sanitizeAngle)
    const cos = Math.cos(sanitizeAngle)

    this.multiply([
      0.213 + cos * 0.787 - sin * 0.213,
      0.715 - cos * 0.715 - sin * 0.715,
      0.072 - cos * 0.072 + sin * 0.928,
      0.213 - cos * 0.213 + sin * 0.143,
      0.715 + cos * 0.285 + sin * 0.14,
      0.072 - cos * 0.072 - sin * 0.283,
      0.213 - cos * 0.213 - sin * 0.787,
      0.715 - cos * 0.715 + sin * 0.715,
      0.072 + cos * 0.928 + sin * 0.072,
    ])
  }

  grayscale(value = 1) {
    const multiplier = 1 - value
    this.multiply([
      0.2126 + 0.7874 * multiplier,
      0.7152 - 0.7152 * multiplier,
      0.0722 - 0.0722 * multiplier,
      0.2126 - 0.2126 * multiplier,
      0.7152 + 0.2848 * multiplier,
      0.0722 - 0.0722 * multiplier,
      0.2126 - 0.2126 * multiplier,
      0.7152 - 0.7152 * multiplier,
      0.0722 + 0.9278 * multiplier,
    ])
  }

  sepia(value = 1) {
    const multiplier = 1 - value
    this.multiply([
      0.393 + 0.607 * multiplier,
      0.769 - 0.769 * multiplier,
      0.189 - 0.189 * multiplier,
      0.349 - 0.349 * multiplier,
      0.686 + 0.314 * multiplier,
      0.168 - 0.168 * multiplier,
      0.272 - 0.272 * multiplier,
      0.534 - 0.534 * multiplier,
      0.131 + 0.869 * multiplier,
    ])
  }

  saturate(value = 1) {
    this.multiply([
      0.213 + 0.787 * value,
      0.715 - 0.715 * value,
      0.072 - 0.072 * value,
      0.213 - 0.213 * value,
      0.715 + 0.285 * value,
      0.072 - 0.072 * value,
      0.213 - 0.213 * value,
      0.715 - 0.715 * value,
      0.072 + 0.928 * value,
    ])
  }

  multiply(matrix) {
    const newR = this.getNewColorFromMetrics(matrix[0], matrix[1], matrix[2])
    const newG = this.getNewColorFromMetrics(matrix[3], matrix[4], matrix[5])
    const newB = this.getNewColorFromMetrics(matrix[6], matrix[7], matrix[8])
    this.r = newR
    this.g = newG
    this.b = newB
  }

  getNewColorFromMetrics(m1, m2, m3) {
    return this.clamp(this.r * m1 + this.g * m2 + this.b * m3)
  }

  brightness(value = 1) {
    this.linear(value)
  }

  contrast(value = 1) {
    this.linear(value, -(0.5 * value) + 0.5)
  }

  linear(slope = 1, intercept = 0) {
    this.r = this.getLinearChannelValue(slope, intercept, this.r)
    this.g = this.getLinearChannelValue(slope, intercept, this.g)
    this.b = this.getLinearChannelValue(slope, intercept, this.b)
  }

  invert(value = 1) {
    this.r = this.getInvertChannelValue(value, this.r)
    this.g = this.getInvertChannelValue(value, this.g)
    this.b = this.getInvertChannelValue(value, this.b)
  }

  getInvertChannelValue(value, channelValue) {
    return this.clamp((value + (channelValue / 255) * (1 - 2 * value)) * 255)
  }

  getLinearChannelValue(slope, intercept, channelValue) {
    return this.clamp(channelValue * slope + intercept * 255)
  }

  // Code taken from https://stackoverflow.com/a/9493060/2688027, licensed under CC BY-SA.
  hsl() {
    const [r, g, b] = [this.r / 255, this.g / 255, this.b / 255]
    const max = Math.max(r, g, b)
    const min = Math.min(r, g, b)
    let h
    let s
    const l = (max + min) / 2

    if (max === min) {
      h = 0
      s = 0
    } else {
      const d = max - min
      s = l > 0.5 ? d / (2 - max - min) : d / (max + min)
      switch (max) {
        case r:
          h = (g - b) / d + (g < b ? 6 : 0)
          break

        case g:
          h = (b - r) / d + 2
          break

        case b:
          h = (r - g) / d + 4
          break

        default:
          break
      }
      h /= 6
    }

    return {
      h: h * 100,
      s: s * 100,
      l: l * 100,
    }
  }

  clamp(value) {
    return Math.max(Math.min(255, value), 0)
  }
}
