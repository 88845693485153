import React from 'react'
import PropTypes from 'prop-types'
import { hasLocizeTranslation } from 'lib/utils/locize'
import Button from 'components/common/button'
import Typo from 'constants/typography'
import Icon from 'components/common/icon'
import ICONS, { ICON_SIZE } from 'components/common/icon/const'
import { BUTTON_TYPES } from 'constants/button'

const CartGenericError = ({ tryToGetCart, t }) => (
  <div className="empty-cart stretch">
    <Icon
      size={ICON_SIZE.extraLarge}
      src={ICONS.emptyShoppingBag}
      className="empty-cart-icon"
    />
    <div className="empty-cart__text">
      <span className={Typo.h6}>
        {hasLocizeTranslation(
          t,
          'SHOPPING_BAG_ERROR_SOMETHING_WENT_WRONG',
          'Oops! Something Went Wrong',
        )}
      </span>
    </div>
    <div className="cart-bottom-button-wrapper">
      <Button
        className="cart__start-shopping"
        type={BUTTON_TYPES.primary}
        onClick={tryToGetCart}
      >
        {hasLocizeTranslation(t, 'SHOPPING_BAG_ERROR_CTA', 'Try Again')}
      </Button>
    </div>
  </div>
)

CartGenericError.propTypes = {
  tryToGetCart: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
}

export default CartGenericError
