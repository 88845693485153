import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { withI18next } from 'lib/i18n/withI18next'
import SnackBar from 'components/common/snack-bar'
import Button from 'components/common/button'
import { BUTTON_TYPES } from 'constants/button'
import Typo from 'constants/typography'
import { hasLocizeTranslation } from 'lib/utils/locize'
import styles from './styles.scss'

const WishlistSnackBar = ({
  isProductList,
  isSnackBarOpen,
  onCloseSnackBar,
  t,
  undoDeleteWishlist,
}) => (
  <div className="wishlist-snackbar">
    <style jsx>{styles}</style>
    <SnackBar
      className={clsx('wishlist-snackbar__box', {
        'no-bottom-space': isProductList,
      })}
      duration={4000}
      open={isSnackBarOpen}
      onClose={onCloseSnackBar}
    >
      <span className={Typo.caption}>
        {hasLocizeTranslation(
          t,
          'WISHLIST_REMOVE_MULTI_PRODUCT',
          'All sizes variations of this product were removed from your wishlist.',
        )}
      </span>
      <Button type={BUTTON_TYPES.text} onClick={undoDeleteWishlist}>
        {hasLocizeTranslation(t, 'WISHLIST_UNDO_REMOVE_MULTI_PRODUCT', 'UNDO')}
      </Button>
    </SnackBar>
  </div>
)

WishlistSnackBar.defaultProps = {
  isProductList: false,
  isSnackBarOpen: false,
}

WishlistSnackBar.propTypes = {
  isProductList: PropTypes.bool,
  isSnackBarOpen: PropTypes.bool,
  onCloseSnackBar: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  undoDeleteWishlist: PropTypes.func.isRequired,
}

export default withI18next()(WishlistSnackBar)
