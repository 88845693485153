import clsx from 'clsx'
import Link from 'next/link'
import PropTypes from 'prop-types'
import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import ResponsiveImage from 'components/images/responsive-image'
import Typo from 'constants/typography'
import { withI18next } from 'lib/i18n/withI18next'
import { hasLocizeTranslation } from 'lib/utils/locize'
import { setSegmentCategoryKeyData } from '../utils'
import styles from './style.scss'

const EmptyResultSquare = ({
  square,
  internationalization: { country, language },
  isNewUI,
  isPhone,
  refType,
  showSearch,
  t,
}) => {
  const { src, label, labelKey, link } = square
  const titleFont = isPhone ? Typo.caption : Typo.subtitle2

  // Use istanbul ignore for now
  // reason: can't figure out how to trigger onClickCapture event
  /* istanbul ignore next */
  return (
    <Fragment>
      <style jsx>{styles}</style>
      <Link
        key={label}
        href={link.href}
        as={`/${country}/${language}${link.as}`}
      >
        <a
          className="empty-result-grid-item"
          onClickCapture={() => setSegmentCategoryKeyData(showSearch, refType)}
        >
          <ResponsiveImage alt={label} imgRatio={1.33} src={src}>
            {!isNewUI && (
              <span className={clsx('title', Typo.subtitle2)}>
                {hasLocizeTranslation(t, labelKey, label)}
              </span>
            )}
          </ResponsiveImage>
          {isNewUI && (
            <span className={clsx('title', 'grid-title', titleFont)}>
              {hasLocizeTranslation(t, labelKey, label)}
            </span>
          )}
        </a>
      </Link>
    </Fragment>
  )
}

EmptyResultSquare.defaultProps = {
  isNewUI: false,
  refType: null,
  showSearch: true,
}

EmptyResultSquare.propTypes = {
  internationalization: PropTypes.shape({
    country: PropTypes.string,
    language: PropTypes.string,
  }).isRequired,
  isNewUI: PropTypes.bool,
  isPhone: PropTypes.bool.isRequired,
  refType: PropTypes.string,
  square: PropTypes.shape({
    src: PropTypes.string,
    label: PropTypes.string,
    labelKey: PropTypes.string,
    link: PropTypes.string,
  }).isRequired,
  showSearch: PropTypes.bool,
  t: PropTypes.func.isRequired,
}

const Extended = withI18next()(EmptyResultSquare)

export default connect((state) => ({
  internationalization: state.internationalization,
  isPhone: state.device.isPhone,
}))(Extended)
