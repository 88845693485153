import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { withI18next } from 'lib/i18n/withI18next'
import Typo from 'constants/typography'
import { hasLocizeTranslation } from 'lib/utils/locize'
import {
  replaceStringWith,
  replaceFormattedPriceWithUpdatedPrice,
} from 'lib/utils/common/commonUtils'
import styles from './styles.scss'

const HOOLAH_INSTALLMENT_MONTH_PERIOD = 3

const HoolahSuggestion = ({
  t,
  formattedTotalPrice,
  totalPrice,
  allowBottomGap,
}) => {
  const formattedHoolahPrice = replaceFormattedPriceWithUpdatedPrice(
    formattedTotalPrice,
    Math.ceil(totalPrice / HOOLAH_INSTALLMENT_MONTH_PERIOD),
  )

  const hoolahSuggestionTitle = replaceStringWith(
    hasLocizeTranslation(
      t,
      'HOOLAH_SUGGESTION_TITLE',
      `Or pay %s today with Shopback Paylater`,
    ),
    '%s',
    formattedHoolahPrice,
  )

  const hoolahSuggestionSubtitle = hasLocizeTranslation(
    t,
    'HOOLAH_SUGGESTION_SUB_TITLE',
    'Enjoy interest free 3 months installments plan now.',
  )

  return (
    <div className={clsx('hoolah-suggestion', allowBottomGap && 'bottom-gap')}>
      <style jsx>{styles}</style>
      <div className="hoolah-suggestion__title">
        <span className={Typo.caption}>{hoolahSuggestionTitle}</span>
      </div>
      <span className={clsx(Typo.caption, 'hoolah-suggestion__sub_title')}>
        {hoolahSuggestionSubtitle}
      </span>
    </div>
  )
}

HoolahSuggestion.defaultProps = {
  allowBottomGap: false,
}

HoolahSuggestion.propTypes = {
  totalPrice: PropTypes.number.isRequired,
  formattedTotalPrice: PropTypes.string.isRequired,
  allowBottomGap: PropTypes.bool,
  t: PropTypes.func.isRequired,
}

export default React.memo(withI18next()(HoolahSuggestion))
