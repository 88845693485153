import React from 'react'
import PropTypes from 'prop-types'
import { withI18next } from 'lib/i18n/withI18next'
import { hasLocizeTranslation } from 'lib/utils/locize'
import Button from 'components/common/button'
import { BUTTON_TYPES } from 'constants/button'
import { COOKIE_CUSTOMIZE_BUTTON, COOKIE_ACCEPT_ALL_BUTTON } from '../const'
import styles from './style.scss'

const CookieBannerButton = ({ disableBanner, t }) => (
  <div className="cookie-banner-button">
    <style jsx>{styles}</style>
    <Button
      className="cookie-banner-button__custom-button"
      onClick={() => disableBanner('customize')}
    >
      {hasLocizeTranslation(
        t,
        COOKIE_CUSTOMIZE_BUTTON.key,
        COOKIE_CUSTOMIZE_BUTTON.text,
      )}
    </Button>
    <Button
      className="cookie-banner-button__accept-all-button"
      type={BUTTON_TYPES.primary}
      onClick={() => disableBanner('accept all')}
    >
      {hasLocizeTranslation(
        t,
        COOKIE_ACCEPT_ALL_BUTTON.key,
        COOKIE_ACCEPT_ALL_BUTTON.text,
      )}
    </Button>
  </div>
)

CookieBannerButton.propTypes = {
  disableBanner: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
}

export default withI18next()(CookieBannerButton)
