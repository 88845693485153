import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { withI18next } from 'lib/i18n/withI18next'
import DynamicSkinnyBanner from './dynamic-skinny-banner'
import Loading from './loading'
import useSkinnyBanners from './useSkinnyBanner'
import { getBannerLink, onBannerClicked } from './utils'

const SkinnyBanners = ({
  categoryData,
  isGuestMode,
  isPhone,
  pageName,
  t,
  user,
}) => {
  const [banners, isFetched] = useSkinnyBanners(
    isGuestMode,
    t,
    user?.customer_groups,
  )
  const isCategoryPage = pageName === 'category'
  const showCategoryBanner = isCategoryPage && categoryData?.skinny_banner

  if (!isFetched) {
    return <Loading />
  }

  if (!banners.length && !showCategoryBanner) {
    return null
  }

  const banner = showCategoryBanner
    ? categoryData.skinny_banner
    : banners[banners.length - 1] // get latest banner

  const { type, id_category, id_feed, radio_type, destination_url } = banner
  const link = getBannerLink(destination_url, id_category, radio_type)

  return (
    <DynamicSkinnyBanner
      banner={banner}
      link={link}
      onLinkClicked={(e) => onBannerClicked(e, link, radio_type, id_feed)}
      isPhone={isPhone}
      type={type}
    />
  )
}

SkinnyBanners.defaultProps = {
  categoryData: null,
  isGuestMode: false,
  pageName: '',
  user: null,
}

SkinnyBanners.propTypes = {
  categoryData: PropTypes.shape({
    skinny_banner: PropTypes.shape({}),
  }),
  isGuestMode: PropTypes.bool,
  isPhone: PropTypes.bool.isRequired,
  pageName: PropTypes.string,
  t: PropTypes.func.isRequired,
  user: PropTypes.shape({
    id_customer: PropTypes.number,
    customer_groups: PropTypes.arrayOf(PropTypes.number),
  }),
}

const Extended = withI18next()(SkinnyBanners)

export default connect((state) => ({
  categoryData: state.category.payload,
  isGuestMode: state.auth.isGuestMode,
  isPhone: state.device.isPhone,
  user: state.auth.user,
}))(Extended)
