/* eslint-disable no-use-before-define */
// TODO: Clean up the auth-service.js and migrate it here.

import MD5 from 'md5.js'
import { v4 as uuid } from 'uuid'
import dayjs from 'dayjs'
import {
  AUTH_ERROR,
  AUTH_PROVIDER,
  RECAPTCHA_CONTAINER_ID,
} from 'components/auth/const'
import { request } from 'lib/api' // eslint-disable-line import/no-cycle
import { HTTP_METHODS } from 'lib/api/const'
import StateStorage from 'lib/state-storage'
import { segmentAuthentication, segmentIdentifyUser } from 'lib/segment'
import { SEGMENT_AUTH_PHONE } from 'lib/segment/const'
import ShopLanguageMap from 'lib/i18n/shop-language-map'
import env from 'config/env'
import PDPAConsent from 'lib/api/pdpa-consent' // eslint-disable-line import/no-cycle
import { CartBadge } from 'lib/api/cart-badge' // eslint-disable-line import/no-cycle
import firebase from '../firebase'
import { PASSWORD_SALT } from './const'

export async function finalizeAuthFireBase(provider, extras, register) {
  const token = await getFirebaseToken(true)

  let body = { token, params: provider, extras, register }

  if (StateStorage.isGuestMode()) {
    body = { ...body, guestToken: StateStorage.getAuthToken() }
  }

  await postOAuth(body)

  const customer = (await getCustomerData({ token })) || {}
  return {
    access_token: token,
    customer,
    provider,
    refreshToken: firebase.auth().currentUser.refreshToken,
  }
}

export async function getPictureFirebase() {
  try {
    const user = await firebase.auth().currentUser
    return user.photoURL
  } catch (_) {
    return null
  }
}

export function getEmailProviderListFireBase(email) {
  return firebase.auth().fetchSignInMethodsForEmail(email)
}

export async function logInWithEmailFireBase(email, password) {
  const saltedPassword = `${PASSWORD_SALT}${password}`
  const providerList = await getEmailProviderListFireBase(email)
  const emailProviderExist = providerList.some((provider) =>
    [AUTH_PROVIDER.password, AUTH_PROVIDER.email].includes(provider),
  )

  if (emailProviderExist) {
    return firebase
      .auth()
      .signInWithEmailAndPassword(email, saltedPassword)
      .then((res) => finalizeAuthFireBase(res))
  }

  // eslint-disable-next-line no-throw-literal
  throw { code: AUTH_ERROR.userNotFound, message: '' }
}

export async function signUpWithEmailFireBase(
  email,
  password,
  firstName,
  lastName,
  fullPhoneNo,
) {
  const saltedPassword = `${PASSWORD_SALT}${password}`
  const providerList = await getEmailProviderListFireBase(email)
  const emailProviderExist = providerList.some((provider) =>
    [AUTH_PROVIDER.password, AUTH_PROVIDER.email].includes(provider),
  )

  if (!emailProviderExist) {
    return firebase
      .auth()
      .createUserWithEmailAndPassword(email, saltedPassword)
      .then((res) =>
        finalizeAuthFireBase(res, { firstName, lastName, fullPhoneNo }, true),
      )
  }

  // eslint-disable-next-line no-throw-literal
  throw { code: AUTH_ERROR.emailExist, message: '' }
}

export function verifyIsPhoneRegisteredFireBase(fullPhone) {
  return request({
    body: { number_phone: fullPhone },
    method: HTTP_METHODS.post,
    url: 'v6/firebase/verify-phone',
  })
}

export function logInWithPhoneGetSMSFireBase(fullPhone) {
  if (!window.recaptchaVerifier) {
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      RECAPTCHA_CONTAINER_ID,
      { size: 'invisible' },
    )
  }

  return firebase
    .auth()
    .signInWithPhoneNumber(fullPhone, window.recaptchaVerifier)
    .then((confirmationResult) => {
      window.confirmationResult = confirmationResult
    })
}

export async function logInOrSignUpWithPhoneCodeFireBase(code, newUserData) {
  await window.confirmationResult.confirm(code)

  if (newUserData) {
    const { email, firstName, lastName } = newUserData

    if (email && firstName && lastName) {
      const password = new MD5()
        .update(`${PASSWORD_SALT}${uuid()}`)
        .digest('hex')
      const credential = firebase.auth.EmailAuthProvider.credential(
        email,
        password,
      )

      await firebase.auth().currentUser.linkWithCredential(credential)

      return finalizeAuthFireBase({ email }, { firstName, lastName }, true)
    }
  }

  return finalizeAuthFireBase()
}

export async function mergeWithEmailFirebase(email) {
  const password = new MD5().update(`${PASSWORD_SALT}${uuid()}`).digest('hex')
  const credential = firebase.auth.EmailAuthProvider.credential(email, password)
  await firebase.auth().currentUser.linkWithCredential(credential)
}

export async function mergeWithPhoneCodeFireBase(code) {
  const credenitial = firebase.auth.PhoneAuthProvider.credential(
    window.confirmationResult.verificationId,
    code,
  )

  const res = await firebase.auth().currentUser.linkWithCredential(credenitial)

  return finalizeAuthFireBase(res)
}

export function segmentAuthTracking(provider, customer, isPhoneSignUp) {
  if (provider?.additionalUserInfo) {
    const {
      additionalUserInfo: { isNewUser, providerId },
    } = provider
    segmentAuthentication(providerId, isNewUser, customer?.id_customer)
  } else if (customer?.phone_signup) {
    segmentAuthentication(
      SEGMENT_AUTH_PHONE,
      isPhoneSignUp,
      customer?.id_customer,
    )
  }
}

/**
 * Function to saves the PDPA consent value to the database
 */
export async function savePDPAConsent() {
  const bannerPDPACookieConsent = StateStorage.getCookie(
    'bannerPDPACookieConsent',
  )
  const pdpaOptions = {
    cookie_consent: bannerPDPACookieConsent
      ? JSON.parse(bannerPDPACookieConsent)
      : null,
  }

  if (!bannerPDPACookieConsent) {
    StateStorage.saveBannerStatus(
      'PDPACookieConsent',
      pdpaOptions.cookie_consent,
    )
  }

  await PDPAConsent.SavePDPAConsent(pdpaOptions)
}

export async function successfulLogInFireBase(res, isPhoneSignUp, redirectUrl) {
  const { access_token, customer, provider, refreshToken } = res

  const authState = {
    lastSignInTime: dayjs(
      provider?.user?.metadata?.lastSignInTime || undefined,
    ).format('YYYY-MM-DD HH:mm:ss'),
    token: access_token,
    user: {
      id_customer: customer?.id_customer,
      email: customer?.email,
      firstname: customer?.firstname,
      lastname: customer?.lastname,
    },
    refreshToken,
  }

  StateStorage.setCookie('guest', '0')
  StateStorage.saveAuthState(authState)

  segmentAuthTracking(provider, customer, isPhoneSignUp)
  await savePDPAConsent()

  if (redirectUrl) {
    window.location = redirectUrl
  } else {
    window.location.reload()
  }

  sessionStorage.removeItem('pdp_product_id')
}

/* istanbul ignore next */
function detectPopUpClose(stopLoader) {
  const popUp = {
    interval: undefined,
    lastOpenedWindow: undefined,
  }

  window.open = ((open) => (url, name, features) => {
    const parsedName = name || 'default_window_name'
    popUp.lastOpenedWindow = open.call(window, url, parsedName, features)
    return popUp.lastOpenedWindow
  })(window.open)

  popUp.interval = setInterval(() => {
    if (popUp.lastOpenedWindow && popUp.lastOpenedWindow.closed) {
      if (stopLoader) {
        stopLoader()
      }

      window.clearInterval(popUp.interval)
    }
  }, 500)
}

export async function signInWithGoogle(withRedirect, stopLoader, skipAuth) {
  const provider = new firebase.auth.GoogleAuthProvider()

  provider.setCustomParameters({ prompt: 'select_account' })
  provider.addScope('https://www.googleapis.com/auth/userinfo.profile')
  provider.addScope('https://www.googleapis.com/auth/userinfo.email')

  if (withRedirect) {
    return firebase
      .auth()
      .signInWithRedirect(provider)
      .then((res) => {
        if (skipAuth) {
          return res
        }

        return signInGoogleOrFacebookSuccess(res)
      })
  }

  detectPopUpClose(stopLoader)
  return firebase
    .auth()
    .signInWithPopup(provider)
    .then((res) => {
      if (skipAuth) {
        return res
      }

      return signInGoogleOrFacebookSuccess(res)
    })
}

// @TODO: Improve doc. | skipAuth is use for merging from phone which have verify phone step.
export async function signInWithFacebook(withRedirect, stopLoader, skipAuth) {
  const provider = new firebase.auth.FacebookAuthProvider()

  provider.setCustomParameters({ prompt: 'select_account' })
  provider.addScope('public_profile')
  provider.addScope('email')

  if (withRedirect) {
    return firebase
      .auth()
      .signInWithRedirect(provider)
      .then((res) => {
        if (skipAuth) {
          return res
        }

        return signInGoogleOrFacebookSuccess(res)
      })
  }

  detectPopUpClose(stopLoader)
  return firebase
    .auth()
    .signInWithPopup(provider)
    .then((res) => {
      if (skipAuth) {
        return res
      }

      return signInGoogleOrFacebookSuccess(res)
    })
}

function signInGoogleOrFacebookSuccess(res) {
  const { additionalUserInfo: info } = res

  return finalizeAuthFireBase(
    res,
    {
      firstName:
        info.given_name ||
        info.profile.first_name ||
        info.profile.given_name ||
        'No Firstname',
      lastName:
        info.family_name ||
        info.profile.last_name ||
        info.profile.family_name ||
        'No Lastname',
    },
    true,
  )
}

/* istanbul ignore next */
function getSourceAuthProvider(source) {
  switch (source) {
    case AUTH_PROVIDER.facebook[0]:
    case AUTH_PROVIDER.facebook[1]:
      return new firebase.auth.FacebookAuthProvider()
    case AUTH_PROVIDER.google[0]:
    case AUTH_PROVIDER.google[1]:
      return new firebase.auth.GoogleAuthProvider()
    case AUTH_PROVIDER.phone:
      return new firebase.auth.PhoneAuthProvider()
    default:
      return new firebase.auth.EmailAuthProvider()
  }
}

export async function logInOrSignUpWithMerge(source, email, password) {
  if (source === AUTH_PROVIDER.email || source === AUTH_PROVIDER.password) {
    const saltedPassword = `${PASSWORD_SALT}${password}`
    const credential = firebase.auth.EmailAuthProvider.credential(
      email,
      saltedPassword,
    )

    return firebase
      .auth()
      .currentUser.linkWithCredential(credential)
      .then((res) => finalizeAuthFireBase(res))
  }

  // Facebook / Google

  const sourceAuth = getSourceAuthProvider(
    Array.isArray(source) ? source[0] : source,
  )

  return firebase
    .auth()
    .currentUser.linkWithPopup(sourceAuth)
    .then((res) => finalizeAuthFireBase(res))
}

export function sendPasswordResetEmailFireBase(email) {
  const actionCodeSettings = {
    url: window.location.href || document.documentURI,
  }

  return firebase.auth().sendPasswordResetEmail(email, actionCodeSettings)
}

export async function successfulLogIn(res, redirectUrl) {
  const {
    idToken: { payload, jwtToken },
    refreshToken: { token },
  } = res?.signInUserSession || {}
  const { attributes, authenticationFlowType } = res

  const authState = {
    lastSignInTime: dayjs().format('YYYY-MM-DD HH:mm:ss'),
    token: jwtToken,
    user: {
      id_customer: payload.id_customer || payload['custom:customer_id'],
      email: payload.email,
      firstname: payload.given_name,
      lastname: payload.family_name,
    },
    refreshToken: token,
  }

  StateStorage.setCookie('guest', '0')
  StateStorage.saveAuthState(authState)

  if (attributes && attributes['custom:is_new_user']) {
    segmentAuthentication(
      authenticationFlowType,
      true,
      authState.user.id_customer,
    )
  }

  await savePDPAConsent()

  if (redirectUrl) {
    window.location = redirectUrl
  } else {
    window.location.reload()
  }

  sessionStorage.removeItem('pdp_product_id')
}

export function successfulMerge(res) {
  const { customer, provider } = res

  if (provider?.additionalUserInfo) {
    const {
      additionalUserInfo: { isNewUser, providerId },
    } = provider
    segmentAuthentication(providerId, isNewUser, customer?.id_customer)
  }

  window.location.reload()
}

/** Use this function to check when Firebase is ready */
export function getCurrentUserFromFirebase() {
  return new Promise((resolve, reject) => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      // in test unsubscribe will be undefined
      unsubscribe()
      resolve(user)
    }, reject)
  })
}

export async function getCurrentUser() {
  const currentUser = await getCurrentUserFromFirebase()
  return currentUser
}

/* istanbul ignore next */
export function onAuthStateChanged(callback) {
  return firebase.auth().onAuthStateChanged(callback)
}

/* istanbul ignore next */
export function getRedirectResult() {
  return firebase.auth().getRedirectResult()
}

export async function initializeGuestModeForFirebase() {
  await firebase.auth().signInAnonymously()
  const oldToken = StateStorage.getAuthToken()
  const token = await getFirebaseToken()
  const { refreshToken } = firebase.auth().currentUser
  if (oldToken !== token) {
    await postOAuth({ token })
  }
  StateStorage.saveAuthState({ token, user: null, refreshToken })
  StateStorage.removeIdentityId()
  await updateLagacyShoppingTrack()
}

export async function updateLagacyShoppingTrack(res) {
  let totalItems = 0

  if (res) {
    const { isShopByLegacy } = StateStorage.getLegacyShoppingTrack()
    if (!isShopByLegacy) {
      return // do nothing
    }
    totalItems = res?.items?.reduce((sum, current) => sum + current.quantity, 0)
  } else {
    totalItems = (await CartBadge.getCartQuantity())?.total_items
  }

  const payload = {
    totalItems: totalItems || 0,
    isShopByLegacy: true,
  }

  StateStorage.saveLegacyShoppingTrack(payload)
}

export async function initializeGuestMode() {
  await initializeGuestModeForFirebase()
  StateStorage.setCookie('guest', '1')
}

/** NOTE: A promise instance to prevent multiple api calls from the interceptor. */
const renewTokenInstance = {
  promise: null,
}

/* istanbul ignore next */
export async function renewToken() {
  // renewToken firebase
  if (renewTokenInstance.promise) {
    return renewTokenInstance.promise
  }

  // eslint-disable-next-line no-async-promise-executor
  renewTokenInstance.promise = new Promise(async (resolve, reject) => {
    try {
      // Get new token at SSR
      if (typeof window === 'undefined') {
        const refreshToken = StateStorage.getServerRefreshToken()
        if (refreshToken) {
          const { id_token } = await request({
            baseURL: 'https://securetoken.googleapis.com/',
            url: 'v1/token',
            params: {
              key: env.FIREBASE_API_KEY,
            },
            method: HTTP_METHODS.post,
            body: {
              grant_type: 'refresh_token',
              refresh_token: refreshToken,
            },
            referer: StateStorage.getServerLastUrl(),
            forceRenewToken: true,
            withAuthorization: false,
          })
          StateStorage.setAuthToken(id_token)

          resolve(id_token)
        }
        resolve()
      } else {
        // Get new token at client
        await getCurrentUserFromFirebase()
        const token = await getFirebaseToken(true)
        const auth = StateStorage.getAuthCookies()
        const refreshToken = StateStorage.getServerRefreshToken()

        StateStorage.saveAuthState({
          ...auth,
          token,
          ...(refreshToken && {
            refreshToken: firebase.auth().currentUser?.refreshToken,
          }),
        })

        resolve(token)
      }
    } catch (e) {
      reject(e)
    }
    renewTokenInstance.promise = null
  })

  return renewTokenInstance.promise
}

export function getFirebaseToken(forceRefresh = false) {
  return firebase.auth().currentUser?.getIdToken(forceRefresh)
}

export function postOAuth({ token, params, extras, register, guestToken }) {
  let body = { token }

  if (guestToken) {
    body = {
      ...body,
      guest_token: guestToken,
    }
  }

  if (register && extras && extras.firstName && extras.lastName) {
    const shopSlug = StateStorage.getShop()
    const shop = ShopLanguageMap.getShopBySlug(shopSlug)

    const getWorldWideCountryId = () => {
      if (shop) {
        const country = StateStorage.getCountry() ?? '21'
        const isGlobal = shop.shopSlug === 'global'

        return isGlobal ? Number(country) : shop.countryId
      }

      return 21
    }

    body = {
      ...body,
      registration_data: {
        email: params.email ? params.email : params.user?.email,
        first_name: extras.firstName,
        last_name: extras.lastName,
        id_country_worldwide: getWorldWideCountryId(),
        register_country: shopSlug.toUpperCase(),
      },
    }
  }

  return request({
    body,
    method: HTTP_METHODS.post,
    url: '/v6/oauth/token',
  })
}

export function getCustomerData({ token } = {}) {
  return request({
    token,
    url: '/v6/customers/current',
  })
}

export async function signInWithCustomToken(validatedToken) {
  const res = await firebase.auth().signInWithCustomToken(validatedToken)

  return finalizeAuthFireBase(res)
}

export function updateUserPassword(password) {
  const saltedPassword = `${PASSWORD_SALT}${password}`

  return firebase.auth().currentUser.updatePassword(saltedPassword)
}

export function getUpdatedPassword(password) {
  return new MD5().update(`${PASSWORD_SALT}${password}`).digest('hex')
}

export function identifyLoggedInUser() {
  const user = StateStorage.getAuthUserAndLastSignInTime()
  const shop = StateStorage.getShop()
  const lang = StateStorage.getLanguage()
  const idLang = ShopLanguageMap.getIdLangBySlugAndLang(shop, lang)

  if (user) {
    segmentIdentifyUser(user, idLang)
  }
}

/**
 * @typedef {Object} AuthApiResponse
 * @property {string} status
 * @property {number} statusCode
 * @property {string} message
 * @property {string} action
 */
