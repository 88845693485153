import { useEffect, useState } from 'react'
import { getProductList } from 'components/recently-view/utils'
import { TRAILING } from 'components/recently-view/const'
import { getUserId } from 'lib/utils/common/commonUtils'

const useRecentViewedProductList = (auth) => {
  const [userProductList, setUserProductList] = useState([])
  const [loading, setLoading] = useState(true)
  const userId = getUserId(auth)?.toString()

  useEffect(() => {
    getProductList(userId)
      .then((res) => {
        const productList = res?.products || []

        if (productList.length > 0) {
          productList.push({ id_product: TRAILING })
        }

        setUserProductList(productList)
      })
      .catch(() => setUserProductList([]))
      .finally(() => {
        setTimeout(() => setLoading(false), 350) // making it switching loading to the actual component not so fast
      })
  }, [userId])

  return {
    loading,
    userProductList,
  }
}

export default useRecentViewedProductList
