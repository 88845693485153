import clsx from 'clsx'
import { useRef, useState, useEffect, useMemo } from 'react'
import useIntersection from 'components/custom-hooks/useIntersection'
import { getColorFilter } from 'lib/utils/common/color/index'
import { ICON_SIZE } from './const'

const useIconElement = ({
  className,
  lazyThreshold,
  size,
  width,
  height,
  color,
  disabled,
  onClick,
  onLoadCallback,
}) => {
  // TODO, validate file extension to be *.svg
  const refImgWrapper = useRef()
  const refImg = useRef()
  const [isShowImage, setIsShowImage] = useState(lazyThreshold === 0)
  const isIntersecting = useIntersection(refImgWrapper, {
    threshold: lazyThreshold,
  })
  const classes = clsx('pomelo-icon', className, {
    'pomelo-icon-clickable': onClick,
    disabled,
  })
  const { filter } = getColorFilter(color) || {}
  const iconStyle = filter
    ? {
        filter,
      }
    : {}

  const onLoad = () => {
    if (onLoadCallback) {
      onLoadCallback()
    }
  }

  const sizeAttribute = useMemo(() => {
    if (width && height) {
      return {
        width,
        height,
      }
    }

    const iconSize = size || ICON_SIZE.default
    return {
      width: iconSize,
      height: iconSize,
    }
  }, [size, width, height])

  useEffect(() => {
    if (lazyThreshold > 0) {
      setIsShowImage(isIntersecting)
    }
  }, [isIntersecting, lazyThreshold])

  return {
    classes,
    iconStyle,
    isShowImage,
    onLoad,
    sizeAttribute,
    refImg,
    refImgWrapper,
  }
}

export default useIconElement
