// use CommonJS syntax to be able to use it on server.js
const envalid = require('envalid')
const getConfig = require('next/config').default
const pkg = require('../package.json')

// https://github.com/af/envalid#validator-types
const { str, num, bool, url } = envalid

const config = getConfig()

const opts = {
  ADYEN_ENV: str({ default: 'test' }),
  ADYEN_ORIGIN_KEY: str({ default: 'ADYEN_ORIGIN_KEY' }),
  ADYEN_SCRIPT: str({ default: 'ADYEN_SCRIPT' }),
  ANDROID_NAMESPACE: str({ default: 'ANDROID_NAMESPACE' }),
  ANDROID_PACKAGE_NAME: str({ default: 'ANDROID_PACKAGE_NAME' }),
  ANDROID_SHA256_CERTS: str({ default: 'ANDROID_SHA256_CERTS' }),
  API_HOST: url({ default: 'https://fake-api.com/' }),
  API_REQUEST_TIMEOUT: num({ default: 6000 }),
  APP_DOMAIN: url({ default: 'https://localhost:3000' }),
  APPLE_APP_ID: str({ default: 'APPLE_APP_ID' }),
  AUTH_API_HOST: url({ default: 'https://fake-auth-api.com/' }),
  AUTH_API_KEY: str({ default: 'auth-api-key' }),
  BRANCH_KEY: str({ default: 'ฺBRANCH_KEY' }),
  CATE_TOC_THIRD_SECTION: str({ default: '3' }),
  CDN_HOST: str({ default: '/' }),
  COGNITO_REGION: str({ default: 'COGNITO_REGION' }),
  ENVIRONMENT: str({
    choices: ['development', 'staging', 'pre-production', 'production'],
    devDefault: 'development',
  }),
  FACEBOOK_ADS_KEY: str({ default: 'FACEBOOK_ADS_KEY' }),
  FIREBASE_APP_ID: str({ default: 'FIREBASE_APP_ID' }),
  FIREBASE_API_KEY: str({ default: 'FIREBASE_API_KEY' }),
  FIREBASE_AUTH_DOMAIN: str({ default: 'FIREBASE_AUTH_DOMAIN' }),
  FIREBASE_PROJECT_ID: str({ default: 'FIREBASE_PROJECT_ID' }),
  FLAGSMITH_CLIENT_ID: str({ default: 'FLAGSMITH_CLIENT_ID' }),
  FLAGSMITH_API_KEY: url({
    default: 'https://fake-api.com/mock.flagsmith.com/api/v1/',
  }),
  FLAGSMITH_EDGE_PROXY_API_KEY: url({
    default: 'https://fake-api.com/edge-proxy.mock.flagsmith.com/api/v1/',
  }),
  FRESHCHAT_TOKEN: str({ default: 'FRESHCHAT_TOKEN' }),
  GOOGLE_ANALYTICS_ID: str({ default: 'GOOGLE_ANALYTICS_ID' }),
  GOOGLE_API_KEY: str({ default: 'GOOGLE_API_KEY' }),
  GOOGLE_SITE_VERIFICATION: str({ default: 'GOOGLE_SITE_VERIFICATION' }),
  GOOGLE_TAG_MANAGER_ID: str({ default: '' }),
  GOOGLE_RECAPTCHA_SITE_KEY: str({ default: 'GOOGLE_RECAPTCHA_SITE_KEY' }),
  GOOGLE_RECAPTCHA_SECRET_KEY: str({ default: 'GOOGLE_RECAPTCHA_SECRET_KEY' }),
  GOOGLE_RECAPTCHA_SITE_KEY_V3: str({
    default: 'GOOGLE_RECAPTCHA_SITE_KEY_V3',
  }),
  HUAWEI_CONTENT_KEY: str({ default: 'HUAWEI_CONTENT_KEY' }),
  IMG_HOST: url({ default: 'https://cdn.pomelofashion.com/' }),
  LD_CLIENT_SIDE_ID: str({ default: 'LD_CLIENT_SIDE_ID' }),
  LD_STREAM_URL: str({ default: '/' }),
  LD_EVENTS_URL: str({ default: '/' }),
  LD_BASE_URL: str({ default: '/' }),
  LD_SDK_KEY: str({ default: 'LD_SDK_KEY' }),
  LOOKBOOK_INFLUENCER_CATEGORY_ID: str({
    default: 'LOOKBOOK_INFLUENCER_CATEGORY_ID',
  }),
  MEDIA_CLOUDFRONT_URL: str({ default: 'https://fake-cdn.com/' }),
  NEXT_API_HOST: url({ default: 'https://fake-api.com/' }),
  NODE_ENV: str({
    choices: ['test', 'development', 'production'],
    devDefault: 'development',
  }),
  OAUTH_DOMAIN: str({ default: 'auth.staging.pmlo.co' }),
  OAUTH_REDIRECT_SIGNIN: str({ default: 'http://localhost:3000' }),
  OAUTH_REDIRECT_SIGNOUT: str({ default: 'http://localhost:3000' }),
  OMISE_PUBLIC_KEY: str({ default: 'OMISE_PUBLIC_KEY' }),
  PHONE_MIGRATION_CODE: str({ default: 'PHONE_MIGRATION_CODE' }),
  PURPOSE_BY_POMELO_HREF: str({ default: 'PURPOSE_BY_POMELO_HREF' }),
  PURPOSE_BY_POMELO_SHOW_URL: str({ default: 'PURPOSE_BY_POMELO_SHOW_URL' }),
  REFERRAL_LINK_TO_APP: str({ default: 'REFERRAL_LINK_TO_APP' }),
  SEGMENT_KEY: str({ default: 'SEGMENT_KEY' }),
  SMARTECH_CREATE_ID: str({ default: 'SMARTECH_CREATE_ID' }),
  SMARTECH_REGISTER_ID: str({ default: 'SMARTECH_REGISTER_ID' }),
  SMARTECH_USER_KEY: str({ default: 'SMARTECH_USER_KEY' }),
  SMARTECH_SITE_ID: str({ default: 'SMARTECH_SITE_ID' }),
  STAGE: str({
    choices: ['staging', 'release', 'pre-release'],
    devDefault: 'pre-release',
  }),
  S3_CREDENTIALS_ACCESS_KEY: str({ default: 'S3_CREDENTIALS_ACCESS_KEY' }),
  S3_CREDENTIALS_SECRET_KEY: str({ default: 'S3_CREDENTIALS_SECRET_KEY' }),
  S3_MEDIA_BUCKET: str({ default: 'S3_MEDIA_BUCKET' }),
  TTB_CATEGORY_ID: str({ default: 'TTB_CATEGORY_ID' }),
  VERSION: str({ default: pkg.version }),
  VT_CLIENT_KEY: str({ default: 'VT_CLIENT_KEY' }),
  VT_HOST: str({ default: 'VT_HOST' }),
  USE_LOCAL_ASSETS: bool({ default: false }),
}

// fallback to process.env for server.js
module.exports = envalid.cleanEnv(
  config ? config.publicRuntimeConfig : process.env,
  opts,
  { dotEnvPath: null },
)
