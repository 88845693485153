/**
 * @param {Object} product
 * @returns {boolean}
 */
export const isFreeGiftProduct = (product) => product?.type === 'gift'

/**
 * @param {Object} product
 * @returns {boolean}
 */
export const isSingleSize = (product) => product?.attributes?.length === 1

/**
 * @param {boolean} allowProductUpdate
 * @param {boolean} isSoldOut
 * @param {object} product
 * @returns {boolean}
 */
export const isOneSizeSelection = (allowProductUpdate, isSoldOut, product) =>
  !allowProductUpdate || isSingleSize(product) || isSoldOut

/**

 * @param {boolean} allowProductUpdate 
 * @param {boolean} isShoppingBagNewDesign
 * @param {boolean} isSoldOut 
 * @param {Object} product 
 * @returns {boolean} 
 */
export const isMultipleSizeAvailable = (
  allowProductUpdate,
  isShoppingBagNewDesign,
  isSoldOut,
  product,
) =>
  !isSingleSize(product) &&
  !isSoldOut &&
  (allowProductUpdate || !isShoppingBagNewDesign)
