/**
 * @typedef FooterConfigs
 * @type {Object}
 * @property {number[]} info_about_us
 * @property {number[]} info_contact
 * @property {number[]} info_career
 * @property {number[]} info_press
 * @property {number[]} info_why_pomelo
 * @property {number[]} info_purpose
 * @property {number[]} info_offline_store
 * @property {number[]} info_sell_on_pomelo
 * @property {number[]} info_invite_friends
 */
const {
  configs: footerInfoLinkConfigs,
} = require('./config/footer-info-link.json')

/**
 * @typedef FooterIconConfigs
 * @type {Object}
 * @property {Object.<number[], Object.string>} facebookSVG
 * @property {Object.<number[], Object.string>} instagramSVG
 * @property {Object.<number[], Object.string>} lineSVG
 * @property {Object.<number[], Object.string>} whatsappSVG
 * @property {Object.<number[], Object.string>} messengerSVG
 * @property {Object.<number[], Object.string>} youtubeSVG
 */
const {
  configs: footerIconConfigs,
} = require('./config/footer-social-icon.json')

/**
 *
 * @param {any[]} footerConfig
 * @param {number} shop
 * @returns {any[]}
 */
export const getAllowedFooterInfoByShop = (footerConfig, shop) => {
  const enabledFooterConfig = footerConfig.filter((list) => {
    const idShopEnabled = footerInfoLinkConfigs[list.key]?.includes(shop)
    return idShopEnabled
  })

  return enabledFooterConfig
}

/**
 *
 * @param {number} id_shop
 * @param {Object.string} href
 * @returns {string}
 */
const getHrefIconByIDShop = (id_shop, href) => {
  switch (id_shop) {
    case 15:
      return href.kh
    case 5:
      return href.id
    default:
      return href.default
  }
}

/**
 *
 * @param {string[]} iconList
 * @param {number} shop
 * @returns {string[]}
 */
export const rearrangeFooterIconByShop = (iconList, shop) =>
  iconList.filter((list) => {
    const icon = list
    const matchedIcon = footerIconConfigs[list.key]

    const isEnabled = matchedIcon?.id_shop_enabled.includes(shop)
    if (isEnabled) {
      const { shop_href } = matchedIcon
      icon.href = getHrefIconByIDShop(shop, shop_href)
    }
    return isEnabled
  })

export default { getAllowedFooterInfoByShop, rearrangeFooterIconByShop }
