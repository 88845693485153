const MAX_FILE_SIZE = 5

const MANUAL_BANK_STATUS = Object.freeze({
  awaitPayment: 'AWAIT_PAYMENT',
  gotProof: 'GOT_PROOF',
  pastDue: 'PAST_DUE',
})

const MANUAL_BANK_PAY_INFO = Object.freeze({
  awaitPayment: {
    name: MANUAL_BANK_STATUS.awaitPayment,
    key: 'MANUAL_BANK_COMPLETE',
    value: 'Complete your order within',
  },
  gotProof: {
    name: MANUAL_BANK_STATUS.gotProof,
    key: 'MANUAL_BANK_VERIFY',
    value: 'Verifying your payment',
  },
  pastDue: {
    name: MANUAL_BANK_STATUS.pastDue,
    key: 'MANUAL_BANK_EXPIRED',
    value: 'Payment time expired',
  },
})

const EXTENSION_INFO = {
  withCC: {
    key: 'ORDER_DETAIL_EXTENSION_DAY_CC',
    value:
      'No worries, you can extend the Pick Up day for %d days to avoid no show fee.',
  },
  noCC: {
    key: 'ORDER_DETAIL_EXTENSION_DAY',
    value: 'No worries, you can extend the Pick Up day for %d days.',
  },
}

export {
  EXTENSION_INFO,
  MANUAL_BANK_PAY_INFO,
  MANUAL_BANK_STATUS,
  MAX_FILE_SIZE,
}
