import React from 'react'
import PropTypes from 'prop-types'
import { translate } from 'react-i18next'
import { connect } from 'react-redux'
import Link from 'next/link'
import clsx from 'clsx'
import { hasLocizeTranslation } from 'lib/utils/locize'
import Button from 'components/common/button'
import Typo from 'constants/typography'
import Icon from 'components/common/icon'
import ICONS, { ICON_SIZE } from 'components/common/icon/const'
import { BUTTON_TYPES } from 'constants/button'
import StateStorage from 'lib/state-storage'
import styles from './style.scss'

const CartEmpty = ({ t }) => {
  const shop = StateStorage.getShop()
  const language = StateStorage.getLanguage()

  return (
    <div className="empty-cart">
      <style jsx>{styles}</style>
      <Icon
        size={ICON_SIZE.extraLarge}
        src={ICONS.emptyShoppingBag}
        className="empty-cart-icon"
      />
      <span className={clsx(Typo.h6, 'empty-cart__title')}>
        {hasLocizeTranslation(t, 'CART_EMPTY_TITLE', 'Your Bag is Empty')}
      </span>
      <span className={clsx(Typo.price2, 'empty-cart__subtitle')}>
        {hasLocizeTranslation(
          t,
          'CART_EMPTY_DESCRIPTION',
          'Start browsing the latest trends now!',
        )}
      </span>
      <Link href="/" as={`/${shop}/${language}/`}>
        <Button
          link
          className="empty-cart__cta"
          type={BUTTON_TYPES.containedLight}
        >
          {t('SHOP NOW')}
        </Button>
      </Link>
    </div>
  )
}

CartEmpty.propTypes = {
  t: PropTypes.func.isRequired,
}
const Extended = translate('global')(CartEmpty)

export default connect((state) => ({
  internationalization: state.internationalization,
}))(Extended)
