import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import clsx from 'clsx'
import countryDuck from 'components/addresses/countries/duck'
import Icon from 'components/common/icon'
import ICONS, { ICON_SIZE } from 'components/common/icon/const'
import SnackBar from 'components/common/snack-bar'
import Loader from 'components/loader'
import Typo from 'constants/typography'
import { withI18next } from 'lib/i18n/withI18next'
import Dialog from 'components/common/dialog'
import { locizeTranslation } from 'lib/utils/locize'

import { AUTH_MESSAGE, AUTH_UI_STATE, AUTH_DIALOG } from './const'
import { useAuth } from './context'
import AuthForgot from './forgot'
import AuthLogIn from './log-in'
import AuthMerge from './merge'
import AuthSignUp from './sign-up'
import AuthLoyaltySignUp from './loyalty-sign-up/AuthLoyaltySignUp'
import style from './style.scss'
import AuthUserInfo from './user-info'
import AuthVerify from './verify/AuthVerify'
import AuthWelcome from './welcome'
import AuthBlockedUser from './blocked-user'
import AuthThirdPartyVerify from './third-party'
import useRecaptcha from './hooks/useRecaptcha'

const AuthBase = ({ authOptions, closeLoginModal, getCountries, t }) => {
  const [snackBarMsg, setSnackBarMsg] = useState()
  const [isMigrate, setIsMigrate] = useState(false)
  const [showOnLeaveModal, setShowOnLeaveModal] = useState(false)
  const [authState, updateAuthState] = useAuth()
  const { overlayLoading, snackBar, uiState } = authState
  const [title, info, cta1, cta2] = locizeTranslation(t, AUTH_DIALOG)

  useRecaptcha()

  const migrateLoading = isMigrate || authState.isMigrate

  useEffect(() => {
    if (typeof getCountries === 'function') {
      getCountries()
    }
  }, [getCountries])

  const handleCloseModal = useCallback(
    (e) => {
      if (e?.preventDefault) {
        e.preventDefault()
      }
      closeLoginModal()
    },
    [closeLoginModal],
  )

  const resetSnackBar = useCallback((e) => {
    if (e?.preventDefault) {
      e.preventDefault()
    }

    setSnackBarMsg('')
  }, [])

  useEffect(() => {
    if (snackBar) {
      if (snackBarMsg) {
        resetSnackBar(undefined)
      } else {
        updateAuthState({ snackBar: '' })
        setSnackBarMsg(
          t(typeof snackBar === 'string' ? snackBar : t(AUTH_MESSAGE.unknown)),
        )
      }
    }
  }, [resetSnackBar, snackBar, snackBarMsg, t, updateAuthState])

  useEffect(() => {
    setSnackBarMsg(authOptions?.snackBarMessage)
  }, [authOptions?.snackBarMessage])

  useEffect(() => {
    const authSection = document.querySelector('.auth__wrapper')

    const handleScroll = () => {
      const closeIcon = document.querySelector('.auth__close')

      if (authSection.scrollTop > 0) {
        closeIcon?.classList.add('scrolled')
      }

      if (authSection.scrollTop === 0) {
        closeIcon?.classList.remove('scrolled')
      }
    }

    authSection.addEventListener('scroll', handleScroll)

    return () => {
      authSection.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const renderBody = useCallback(() => {
    // Can't control via context API yet, due to modal open state control via redux
    // and modal will show login by default. This step is for intercept modal state
    // before context kick in
    if (authOptions?.showSignUpPromo) {
      return <AuthWelcome />
    }

    switch (uiState) {
      // Merge Social account with existing user account
      case AUTH_UI_STATE.merge:
        return <AuthMerge />
      case AUTH_UI_STATE.verifyPhone:
        return <AuthVerify setIsMigrate={setIsMigrate} />
      case AUTH_UI_STATE.blockedUser:
        return <AuthBlockedUser />
      // Fill user profile for Social account
      case AUTH_UI_STATE.userInfoPhone:
        return <AuthUserInfo />
      case AUTH_UI_STATE.forgotPassword:
        return <AuthForgot />
      case AUTH_UI_STATE.signUp:
        return <AuthSignUp />
      case AUTH_UI_STATE.loyaltySignUp:
        return <AuthLoyaltySignUp />
      case AUTH_UI_STATE.thirdPartyVerify:
        return <AuthThirdPartyVerify />
      case AUTH_UI_STATE.logIn:
      default:
        return <AuthLogIn closeModal={handleCloseModal} />
    }
  }, [authOptions?.showSignUpPromo, handleCloseModal, uiState])

  // Use istanbul ignore for now
  // reason: can't figure out how to trigger click event
  /* istanbul ignore next */
  const onLeaveClicked = () => {
    setIsMigrate(false)
    updateAuthState({ overlayLoading: false, isMigrate: false })
    closeLoginModal()
  }

  return (
    <section className="auth__wrapper">
      <style jsx>{style}</style>
      <Icon
        cy="auth__close"
        className="auth__close"
        onClick={handleCloseModal}
        src={ICONS.close}
        size={ICON_SIZE.medium}
      />
      <div className={clsx('auth__body-wrapper')}>{renderBody()}</div>
      <SnackBar
        className={clsx('auth__snack-bar')}
        duration={5000}
        onClose={resetSnackBar}
        open={!!snackBarMsg}
      >
        <span className={Typo.caption}>{snackBarMsg}</span>
      </SnackBar>
      {overlayLoading && (
        <div
          className={migrateLoading ? 'auth__loader__migrate' : 'auth__loader'}
        >
          {migrateLoading && (
            // Use istanbul ignore for now
            // reason: can't figure out how to trigger click event
            /* istanbul ignore next */
            <Icon
              cy="auth__loader__close"
              className="auth__loader__close"
              src={ICONS.closeWhite}
              size={ICON_SIZE.small}
              onClick={() => setShowOnLeaveModal(true)}
            />
          )}
          {showOnLeaveModal ? (
            // Use istanbul ignore for now
            // reason: can't figure out how to trigger click event
            /* istanbul ignore next */
            <Dialog
              className="abort"
              title={title}
              cancelText={cta1}
              confirmText={cta2}
              onConfirm={() => setShowOnLeaveModal(false)}
              onCancel={onLeaveClicked}
              confirmable
              cancelable
            >
              {info}
            </Dialog>
          ) : (
            <Loader isMigrate={migrateLoading} />
          )}
        </div>
      )}
    </section>
  )
}

AuthBase.propTypes = {
  authOptions: PropTypes.shape({
    justSignUp: PropTypes.bool,
    showSignUpPromo: PropTypes.bool,
    snackBarMessage: PropTypes.string,
  }),
  closeLoginModal: PropTypes.func.isRequired,
  getCountries: PropTypes.func,
  t: PropTypes.func.isRequired,
}

AuthBase.defaultProps = {
  authOptions: undefined,
  getCountries: undefined,
}

const Auth = connect(
  (state) => ({
    authData: state.auth,
    authOptions: state.auth.options,
    isPhone: state.device.isPhone,
  }),
  (dispatch) =>
    bindActionCreators(
      {
        getCountries: countryDuck.creators.fetch,
      },
      dispatch,
    ),
)(withI18next()(AuthBase))

export default Auth
