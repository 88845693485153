import Router from 'next/router'
import StateStorage from 'lib/state-storage'
import { AUTH_ACTION } from 'lib/auth/const'

const ontextFieldFocus = (name) => {
  const textField = document.getElementsByName(name)[0]
  textField?.scrollIntoView({ behavior: 'smooth', block: 'center' })
}

const handlePasswordFlow = async (email, action, closeModal) => {
  const shop = StateStorage.getShop() || 'global'
  const lang = StateStorage.getLanguage() || 'en'

  const page =
    action === AUTH_ACTION.SET_PASSWORD ? 'set-password' : 'forgotpassword'

  const href = `/${shop}/${lang}/${page}?user=${email}`
  const link = {
    as: href,
    href,
  }

  await Router.push(link.href, link.as, { shallow: true })

  if (closeModal) {
    closeModal()
  }
}

const handleVerifyEmailFlow = async (email, username) => {
  const shop = StateStorage.getShop() || 'global'
  const lang = StateStorage.getLanguage() || 'en'
  const href = `/${shop}/${lang}/verify-email?user=${username}&e=${email}`

  await Router.push(href, href, { shallow: true })
}

/**
 *
 * @param {boolean} event - an boolean to tell should user click or automatically to redirect
 * @returns {null}
 */
const goToMyLoyaltyPage = (event) => {
  const shop = StateStorage.getShop()
  const language = StateStorage.getLanguage()

  if (event) {
    Router.push('/user/my-loyalty', `/${shop}/${language}/loyalty-program`)
  } else {
    const pathPrefix = window.location.origin
    window.location.replace(`${pathPrefix}/${shop}/${language}/user/my-loyalty`)
  }
}

export {
  ontextFieldFocus,
  handlePasswordFlow,
  handleVerifyEmailFlow,
  goToMyLoyaltyPage,
}
