// eslint-disable-next-line import/no-cycle
import {
  getProductListByUserId,
  getStringIDList,
} from 'components/recently-view/utils'
import StateStorage from 'lib/state-storage'
import env from 'config/env'
// eslint-disable-next-line import/no-cycle
import { getFlagValue } from 'lib/flagsmith'
import { API_ALIAS_CONFIGS } from './const'

const getRecentlyProductIdsParam = () => {
  const auth = StateStorage.getAuthUser()
  const userId = auth?.id_customer?.toString() || 'guest'

  const productLists = getProductListByUserId(userId)
  const recentlyParam = getStringIDList(productLists.reverse())
  return recentlyParam
}

export default getRecentlyProductIdsParam

/**
 * @param {keyof API_ALIAS_CONFIGS} alias
 * @param {string|string[]} [params='']
 *
 * @returns {Promise<{baseURL:string, url:string}>}
 */
export const getAPIRequestURL = async (alias, params = '') => {
  if (!API_ALIAS_CONFIGS[alias]) {
    throw new Error(`API alias ${alias} is not defined`)
  }

  const PARAM_REPLACER = /::PARAM::/
  const sanitizedParams = Array.isArray(params) ? params : [params]

  const defaultFlagValue = { endpoints: { enabled: [], disabled: [] } }
  const apiNextEndpointConfigs = await getFlagValue(
    'ApiNextEndpoints_20230621',
    false,
  ).then((variant) => {
    try {
      return JSON.parse(variant)
    } catch (e) {
      return defaultFlagValue
    }
  })
  const isCanary =
    apiNextEndpointConfigs.endpoints?.enabled?.some(
      (endpoint) => endpoint.alias === alias,
    ) || false

  const BASE_URL = isCanary
    ? env.NEXT_API_HOST.replace(/\/$/, '')
    : env.API_HOST.replace(/\/$/, '')

  const { original_path, canary_path } = API_ALIAS_CONFIGS[alias]

  const focusPath = isCanary ? canary_path : original_path
  const resultPath = sanitizedParams.reduce(
    (acc, param) => acc.replace(PARAM_REPLACER, param),
    focusPath,
  )

  if (PARAM_REPLACER.test(resultPath)) {
    throw new Error(`API path ${resultPath} still has params`)
  }

  return { baseURL: BASE_URL, url: resultPath }
}
