import { HTTP_METHODS } from './const'
import { request } from '.'

class Carrier {
  static get(options) {
    const {
      productsTotal,
      zoneId,
      customerId,
      stateId,
      pickupLocId,
      shippingType,
    } = options

    return request({
      url: 'v6/carriers/shipping-methods',
      params: {
        total_price: productsTotal,
        zone_id: zoneId,
        customer_id: customerId,
        state_id: stateId,
        shipping_id: pickupLocId,
        shipping_type: shippingType,
      },
      method: HTTP_METHODS.get,
    })
  }
}

export default Carrier
