import {
  getStringIDList,
  getProductListByUserId,
  removeConsecutiveDuplicateProduct,
  removeLastItem,
} from 'components/recently-view/utils'
import ProductsApiHandler from 'lib/api/products'
import getShoppingBagRecommendation from 'lib/api/shopping-bag'
import { SEGMENT_JUST_FOR_YOU_QUERY_ID } from 'lib/segment/const'
import { getUserId } from 'lib/utils/common/commonUtils'

export const getRecommendation = (
  auth,
  productId,
  isShoppingBag,
  { limit, skip },
) => {
  const userId = getUserId(auth)?.toString()
  const productList = getProductListByUserId(userId)

  removeConsecutiveDuplicateProduct(productId, productList)
  productList.unshift({ id_product: productId })
  removeLastItem(productList)

  const productIdList = getStringIDList(productList.reverse())
  const recommendation = isShoppingBag
    ? getShoppingBagRecommendation(productIdList)
    : ProductsApiHandler.getPersonalizedRecommendation({
        productId,
        productIdList,
        limit: limit || 8,
        skip: skip || 0,
      })
  return recommendation
}

export const fetchPersonalizedRecommendedProducts = async (
  auth,
  productId,
  isShoppingBag,
  setList,
  setIsFetched,
  ignore,
  { limit, skip },
) => {
  try {
    const recommendation = await getRecommendation(
      auth,
      productId,
      isShoppingBag,
      { limit, skip },
    )
    if (!ignore) {
      setList(recommendation)
      setIsFetched(true)
      sessionStorage.setItem(
        SEGMENT_JUST_FOR_YOU_QUERY_ID,
        recommendation.id_recommendation,
      )
    }
  } catch (e) {
    setList({})
  }
}

export default { fetchPersonalizedRecommendedProducts, getRecommendation }
