import React from 'react'
import PropTypes from 'prop-types'
import { useAuth } from 'components/auth/context'
import { AUTH_UI_STATE } from 'components/auth/const'
import { hasLocizeTranslation } from 'lib/utils/locize'
import { withI18next } from 'lib/i18n/withI18next'
import style from './style.scss'

const AuthHelperSignUp = ({ t, isSignUp }) => {
  const [, updateAuthState] = useAuth()

  const onCtaClicked = React.useCallback(() => {
    updateAuthState({
      uiState: isSignUp ? AUTH_UI_STATE.logIn : AUTH_UI_STATE.signUp,
    })
  }, [updateAuthState, isSignUp])

  return (
    <React.Fragment>
      <style jsx>{style}</style>
      {isSignUp
        ? hasLocizeTranslation(
            t,
            'SIGN_UP_HELPER_TEXT_1',
            'That email address is already in use.',
          )
        : hasLocizeTranslation(
            t,
            'You have not registered yet, please sign up first.',
            'You have not registered yet, please',
          )}
      &nbsp;
      <button className="underline-cta" onClick={onCtaClicked} type="button">
        {isSignUp ? t('Log In') : t('Sign Up')}
      </button>
      &nbsp;
      {isSignUp
        ? hasLocizeTranslation(t, 'SIGN_UP_HELPER_TEXT_2', 'instead')
        : t('first.')}
    </React.Fragment>
  )
}

AuthHelperSignUp.defaultProps = {
  isSignUp: false,
}

AuthHelperSignUp.propTypes = {
  t: PropTypes.func.isRequired,
  isSignUp: PropTypes.bool,
}

export default withI18next()(AuthHelperSignUp)
