import React, { useState, useRef, useEffect } from 'react'
import clsx from 'clsx'
import {
  BUTTON_TYPE_CLASSES,
  BUTTON_SHAPE_CLASSES,
  BUTTON_HTML_TYPE,
} from 'constants/button'
import Typo from 'constants/typography'

// Insert one space between node automatically.
function insertSpace(child) {
  if (['number', 'string'].includes(typeof child)) {
    return <span className={clsx('pml-btn-label', Typo.button)}>{child}</span>
  }
  return child
}

const handleClicked = (e, setClicked, onClick) => {
  setClicked(true)
  if (onClick) {
    onClick(e)
  }
}

const useButtonElement = ({
  children,
  type,
  shape,
  disabled,
  className,
  htmlType,
  link,
  onClick,
}) => {
  const timeout = useRef()
  const [clicked, setClicked] = useState(false)
  const btnType = BUTTON_TYPE_CLASSES[type]
  const btnShape = BUTTON_SHAPE_CLASSES[shape]
  const classes = clsx('pml-btn', btnType, btnShape, className, {
    'pml-btn-clicked': clicked,
    disabled: link && disabled,
  })
  const kids = React.Children.map(children, insertSpace)
  const btnHtmlType = BUTTON_HTML_TYPE[htmlType] || BUTTON_HTML_TYPE.button

  useEffect(
    () => () => {
      clearTimeout(timeout.current)
    },
    [],
  )

  return {
    kids,
    classes,
    btnHtmlType,
    handleClicked: (e) => {
      clearTimeout(timeout.current)
      handleClicked(e, setClicked, onClick)
      timeout.current = setTimeout(() => {
        setClicked(false)
      }, 500)
    },
  }
}

export default useButtonElement
