import queryString from 'query-string'
import dayjs from 'dayjs'
import { saveAs } from 'file-saver'
import Router from 'next/router'
import { ORDER_CURRENT_STATUS_CODE } from 'constants/order-constants/order'
import OrderApi from 'lib/api/order'
import PaymentApi from 'lib/api/payment'
import {
  getOrderIDFromReference,
  returnOrderPrefix,
} from 'lib/utils/common/commonUtils'
import { segmentConfirmExtendClicked } from 'lib/segment'
import StateStorage from 'lib/state-storage'
import { getFileSizeMB } from 'lib/utils/images/compression'
import { MOBILE_BANKING_TYPE } from 'components/checkout/mobile-banking-list/const'
import { MANUAL_BANK_PAY_INFO, MAX_FILE_SIZE } from './const'

const ORDER_STATES = {
  PAYMENT_ACCEPTED: 2,
  PAYMENT_ERROR: 8,
  AWAITING_BANK_WIRE_PAYMENT: 10,
  PENDING: 13,
  BANK_WIRE_ACCEPTED: 22,
  BANK_WIRE_FAILED: 23,
  OUT_FOR_DELIVERY: 25,
  PAYMENT_PENDING: 37,
}

const downloadInvoice = (e, order) => {
  e?.preventDefault()
  OrderApi.getInvoice(order.invoice_number).then((blob) =>
    saveAs(blob, `${order.reference}.pdf`),
  )
}

const getBankPaymentInfo = (hasProof, isPast) => {
  const awaitPayment = !isPast && !hasProof && MANUAL_BANK_PAY_INFO.awaitPayment
  const gotProof = hasProof && MANUAL_BANK_PAY_INFO.gotProof
  const pastDue = isPast && MANUAL_BANK_PAY_INFO.pastDue

  return awaitPayment || gotProof || pastDue
}

const getEndDate = (order) =>
  order?.payment?.expiration_proof_date &&
  !order?.payment?.account?.proof_upload
    ? order.payment.expiration_proof_date
    : undefined

const getExtendablityStatus = (isExtendOptionHidden, isExtended, order) => {
  const isExtendableShippingType = ['store', 'pickup'].includes(
    order?.shipping_type,
  )
  const isOutForDelivery =
    order?.current_status_code === ORDER_CURRENT_STATUS_CODE.OUT_FOR_DELIVERY
  const isPomeloEmployeeOrder = order?.pickup_location_type !== 'OFFICE'

  return (
    !isExtendOptionHidden &&
    !isExtended &&
    isExtendableShippingType &&
    isOutForDelivery &&
    isPomeloEmployeeOrder
  )
}

const getFormattedDate = (order) => {
  const newExpiryDate = dayjs(order.expiry_date, 'YYYY-MM-DD').add(
    order.available_extension_days,
    'd',
  )
  return newExpiryDate.format('DD MMM YYYY')
}

const replaceOrderIdWithReference = (id_order) => {
  const orderReference = returnOrderPrefix(id_order) + id_order
  const pathPrefix = window.location.pathname

  window.location.replace(`${pathPrefix}${orderReference}`)
}

const getOrderId = (id, reference, updateId) => {
  const urlParams = queryString.parse(window.location.search)

  if (urlParams?.id_order) {
    replaceOrderIdWithReference(urlParams?.id_order)
  }

  if (id) {
    return id
  }

  const orderId = getOrderIDFromReference(reference)

  if (id !== orderId) {
    updateId(orderId)
  }

  return orderId
}

const getOrderIDReference = (reference) =>
  parseInt(reference.replace('PML79', ''), 10)

const handleExtendClickAPI = async (
  id,
  updateExtendSuccess,
  updateExtendErrorData,
  user,
) => {
  segmentConfirmExtendClicked({
    id_customer: user?.id_customer,
    ref_type: 'order_details',
  })

  try {
    await OrderApi.extendPickupDate({
      orderId: id,
    })

    updateExtendSuccess(true)
  } catch (err) {
    updateExtendErrorData(err.error.message)
  }
}

const isManualBankPayment = (order) =>
  typeof order?.payment?.type === 'string' &&
  order.payment.type.startsWith('Manual Bank Transfer')

const isPickUpCompleted = (order) =>
  [
    ORDER_CURRENT_STATUS_CODE.SUCCESSFUL_PICK_UP,
    ORDER_CURRENT_STATUS_CODE.DELIVERED,
  ].includes(order.current_status_code)

const isVoucherWithTTBS = (order) => {
  const isTTBS = order?.pickup_location_type?.toLowerCase() === 'store'
  const isVoucherApplied = order?.voucher
  return isTTBS && isVoucherApplied
}

const onDrop = (files, updateImageUploadData) => {
  if (files?.length) {
    const fileSizeMB = getFileSizeMB(files[0])

    if (fileSizeMB > MAX_FILE_SIZE) {
      updateImageUploadData(
        `File size exceeds the maximum size of ${MAX_FILE_SIZE} MB`,
        null,
        false,
      )
    } else {
      updateImageUploadData(null, files[0], true)
    }
  }
}

const proceedToPay = (e, orderId) => {
  e?.preventDefault()
  const shop = StateStorage.getShop()
  const language = StateStorage.getLanguage()

  const URL = {
    as: `/${shop}/${language}/checkout?id=${orderId}`,
    href: `/checkout?id=${orderId}`,
  }

  Router.push(URL.href, URL.as)
}

// This is the work around with state and re-rendering
const setMaskWidthHeight = (imageEl, previewImageElRef) => {
  const maskEl = document.getElementById('proof_mask')

  if (maskEl) {
    maskEl.style.width = `${imageEl.width}px`
    maskEl.style.height = `${imageEl.height}px`
  }

  previewImageElRef.current.scrollIntoView({ behavior: 'smooth' })
}

const showUploadProof = (hasProof, isPast, order) =>
  order?.current_status_code === 10 && !hasProof && !isPast

const submitPaymentProof = (reference, image, updateProofUploadData) => {
  window.scrollTo(0, 0)
  updateProofUploadData(true)

  PaymentApi.uploadPaymentProof(getOrderIDReference(reference), {
    file: image,
  })
    .then(
      (res) => {
        if ((res.status > 199 && res.status < 300) || res.status === 413) {
          updateProofUploadData(false, true)
        }
      },
      // User already submitted the proof or other request error
      () => {
        updateProofUploadData(false, true)
      },
    )
    .catch(
      /* istanbul ignore next */
      (err) => {
        throw new Error(err)
      },
    )
}

const isMobileBankingPendingOrder = (order) =>
  [ORDER_STATES.PENDING, ORDER_STATES.PAYMENT_PENDING].includes(
    order.current_status_code,
  ) && MOBILE_BANKING_TYPE.includes(order.payment.type_name)

export {
  downloadInvoice,
  getBankPaymentInfo,
  getEndDate,
  getExtendablityStatus,
  getFormattedDate,
  getOrderId,
  handleExtendClickAPI,
  isManualBankPayment,
  isMobileBankingPendingOrder,
  isPickUpCompleted,
  isVoucherWithTTBS,
  onDrop,
  proceedToPay,
  setMaskWidthHeight,
  showUploadProof,
  submitPaymentProof,
  ORDER_STATES,
}
