import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import createStrictContext from 'components/custom-hooks/createStrictContext'
import { AUTH_UI_STATE } from './const'

const [AuthStateContextProvider, AuthStateContext] =
  createStrictContext('AuthState')
const [AuthDispatchContextProvider, AuthDispatchContext] =
  createStrictContext('AuthDispatch')

const AUTH_INIT_STATE = {
  dataEmail: undefined,
  dataFirstName: undefined,
  dataLastName: undefined,
  dataPassword: undefined,
  dataPhone: undefined,
  dataUserAuthFlowType: undefined,
  dataDateOfBirth: undefined,
  dataPostcode: undefined,
  destination: undefined,
  isMerging: false,
  isMigrate: false,
  isPhoneSignup: false,
  overlayLoading: false,
  snackBar: undefined,
  source: undefined,
  uiState: AUTH_UI_STATE.logIn,
  isSelectSignUpMethod: false,
  signUpMethod: undefined,
  unblockTime: undefined,
  blockType: undefined,
  loginMethods: [],
}

/**
 * @param {AuthState} state
 * @param {AuthState} newState
 * @returns {AuthState}
 */
function AuthReducer(state, newState) {
  return { ...state, ...newState }
}

/** @returns {[AuthState, UpdateAuthState]} */
function useAuth() {
  return [AuthStateContext(), AuthDispatchContext()]
}

const BaseAuthProvider = ({ children, dataEmail, uiState }) => {
  const [state, dispatch] = React.useReducer(AuthReducer, AUTH_INIT_STATE)

  useEffect(() => {
    dispatch({ dataEmail, uiState })
  }, [dataEmail, uiState])

  return (
    <AuthStateContextProvider value={state}>
      <AuthDispatchContextProvider value={dispatch}>
        {children}
      </AuthDispatchContextProvider>
    </AuthStateContextProvider>
  )
}

/* istanbul ignore next */
// Reason: Testing purpose
// eslint-disable-next-line react/prop-types
const FakeAuthProvider = ({ overridingState = {}, dispatch, children }) => (
  <AuthStateContextProvider value={{ ...AUTH_INIT_STATE, ...overridingState }}>
    <AuthDispatchContextProvider value={dispatch || (() => null)}>
      {children}
    </AuthDispatchContextProvider>
  </AuthStateContextProvider>
)

BaseAuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
  dataEmail: PropTypes.string,
  uiState: PropTypes.string,
}

BaseAuthProvider.defaultProps = {
  dataEmail: undefined,
  uiState: AUTH_UI_STATE.logIn,
}

const AuthProvider = connect((state) => ({
  dataEmail: state.auth.email,
  uiState: state.auth.stage,
}))(BaseAuthProvider)

export { AUTH_INIT_STATE, AuthProvider, useAuth, FakeAuthProvider }

/**
 * @typedef {object} AuthState
 * @property {string} uiState - Auth UI state. Reference with `AUTH_UI_STATE` obj.
 * @property {(string|object)} snackBar - Auth SnackBar message, can be a string or an error obj.
 * @property {boolean} overlayLoading - Control parent overlay loader.
 * @property {string} source - Use for merging process.
 * @property {string} destination - Use for merging process.
 * @property {string} dataEmail - Data email.
 * @property {string} dataFirstName - First name.
 * @property {string} dataLastName - Last name.
 * @property {string} dataPassword - Password.
 * @property {string} dataPhone - Full phone data, prefix + phone number. // dataUserAuthFlowType
 * @property {string} dataDateOfBirth - Use for signing up loyalty. Acceptedformats are [D-M-YYYY, YYYY-M-D, D-MMM-YYYY, YYYY-MMM-D]
 * @property {string} dataPostcode - Use for signing up loyalty.
 * @property {string} dataUserAuthFlowType
 * @property {boolean} isMerging - Is in the merging flow or not.
 * @property {boolean} isMigrate - Is in the migrate flow or not.
 * @property {boolean} isSelectSignUpMethod - true if user prefill email or phone for sign up
 * @property {string} signUpMethod
 * @property {string} unblockTime - unblock time remaining after account locked.
 * @property {string} blockType - a type of blocking
 * @property {Array} loginMethods - a list of login method that aviable for this account
 * @property {Array} isPhoneSignup - v2, determine phonenumber is for signup/login in the OTP steps
 *
 * @callback UpdateAuthState
 * @param {AuthState} newState
 */
