const BUTTON_TYPES = {
  default: '',
  primary: 'primary',
  text: 'text',
  textDark: 'textDark',
  link: 'link',
  outlinedLight: 'outlinedLight',
  outlinedDark: 'outlinedDark',
  containedLight: 'containedLight',
}

const BUTTON_TYPE_CLASSES = {
  [BUTTON_TYPES.primary]: 'pml-btn-pri',
  [BUTTON_TYPES.text]: 'pml-btn-txt',
  [BUTTON_TYPES.textDark]: 'pml-btn-td',
  [BUTTON_TYPES.link]: 'pml-btn-link',
  [BUTTON_TYPES.outlinedLight]: 'pml-btn-ol',
  [BUTTON_TYPES.outlinedDark]: 'pml-btn-od',
  [BUTTON_TYPES.containedLight]: 'pml-btn-cl',
}

const BUTTON_SHAPES = {
  default: '',
  circle: 'circle',
  round: 'round',
}

const BUTTON_SHAPE_CLASSES = {
  [BUTTON_SHAPES.circle]: 'pomelo-btn-circle',
  [BUTTON_SHAPES.round]: 'pomelo-btn-round',
}

/**
 * See more https://developer.mozilla.org/en-US/docs/Web/HTML/Element/button#attr-type
 */
const BUTTON_HTML_TYPE = {
  default: '',
  button: 'button',
  submit: 'submit',
  reset: 'reset',
}

const EXPAND_BUTTON_TYPES = {
  floatingText: 'floatingText',
  floatingIcon: 'floatingIcon',
  floatingAction: 'floatingAction',
}

const EXPAND_BUTTON_TYPE_CLASSES = {
  [EXPAND_BUTTON_TYPES.floatingText]: 'pml-btn-f expanded',
  [EXPAND_BUTTON_TYPES.floatingIcon]: 'pml-btn-f',
  [EXPAND_BUTTON_TYPES.floatingAction]: 'pml-btn-fa',
}

export {
  BUTTON_TYPES,
  BUTTON_TYPE_CLASSES,
  BUTTON_SHAPES,
  BUTTON_SHAPE_CLASSES,
  BUTTON_HTML_TYPE,
  EXPAND_BUTTON_TYPES,
  EXPAND_BUTTON_TYPE_CLASSES,
}
