import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { translate } from 'react-i18next'
import ResponsiveImage from 'components/images/responsive-image'
import { hasLocizeTranslation } from 'lib/utils/locize'
import styles from './styles.scss'
import CON from './const'

const ErrorNotAvailable = ({ isPhone, t }) => {
  const banner = CON.banner[isPhone ? 0 : 1]

  return (
    <React.Fragment>
      <style jsx>{styles}</style>
      <ResponsiveImage
        className="error-na__img"
        imgRatio={banner.ratio}
        src={banner.src}
      >
        <div className="desc-box">
          <p className="content">
            {hasLocizeTranslation(t, CON.label1Key, CON.label1)}
          </p>
          <p className="content">
            {hasLocizeTranslation(t, CON.label2Key, CON.label2)}
          </p>
        </div>
      </ResponsiveImage>
    </React.Fragment>
  )
}

ErrorNotAvailable.propTypes = {
  isPhone: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
}

const Extended = translate('global')(ErrorNotAvailable)

export { ErrorNotAvailable }

export default connect(
  /* istanbul ignore next */
  (state) => ({ isPhone: state.device.isPhone }),
)(Extended)
