import PropTypes from 'prop-types'
import Loader from 'components/loader'
import VoucherPiece from 'components/pdp/carousel-images/voucher/voucher-piece'
import VoucherEmptyBody from 'components/pdp/carousel-images/voucher/voucher-empty'

const VoucherList = ({
  isLoading,
  isCheckout,
  isCoachMarkPresent,
  isFlagForAllUserValid,
  isOpenCoachMark,
  voucherPieceRef,
  voucherList,
  appliedVoucher,
  onVoucherSelected,
  onSaveCoachMarkClosed,
  onCopyVoucherCode,
}) => {
  const handleCopyCode = (code) => {
    onCopyVoucherCode(code)

    // should store coachmark status to localStorage when the all user flag is available
    if (isFlagForAllUserValid) {
      onSaveCoachMarkClosed()
    }
  }

  if (isLoading) {
    return (
      <div className="loader-container">
        <Loader />
      </div>
    )
  }

  if (!voucherList?.length) {
    return <VoucherEmptyBody />
  }

  return voucherList.map((list, idx) => {
    const voucherKey = `${list.code}-${idx + 1}`
    const isShowCoachMark = idx === 0

    return (
      <VoucherPiece
        key={voucherKey}
        voucherPieceRef={voucherPieceRef}
        list={list}
        showCoachMark={isShowCoachMark}
        isCoachMarkPresent={isCoachMarkPresent}
        isCheckout={isCheckout}
        openCoachMark={isOpenCoachMark}
        copyCode={() => handleCopyCode(list.code)}
        saveCoachMarkClosed={onSaveCoachMarkClosed}
        onVoucherSelected={onVoucherSelected}
        appliedVoucher={appliedVoucher}
      />
    )
  })
}

VoucherList.defaultProps = {
  isLoading: false,
  onVoucherSelected: undefined,
}

VoucherList.propTypes = {
  voucherPieceRef: PropTypes.shape({
    current: PropTypes.shape({}),
  }).isRequired,
  isOpenCoachMark: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
  isCoachMarkPresent: PropTypes.bool.isRequired,
  isCheckout: PropTypes.bool.isRequired,
  isFlagForAllUserValid: PropTypes.bool.isRequired,
  appliedVoucher: PropTypes.string.isRequired,
  voucherList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onSaveCoachMarkClosed: PropTypes.func.isRequired,
  onVoucherSelected: PropTypes.func,
  onCopyVoucherCode: PropTypes.func.isRequired,
}

export default VoucherList
