import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { withI18next } from 'lib/i18n/withI18next'
import styles from './styles.scss'

const BlackBar = ({ children, className, onClick, isTTBNotice }) => (
  <button
    type="button"
    className={clsx('black-bar', className, {
      'ttb-notice': isTTBNotice,
    })}
    onClick={onClick}
    disabled={!onClick}
  >
    <style jsx>{styles}</style>
    {children}
  </button>
)

BlackBar.defaultProps = {
  className: '',
  onClick: null,
}

BlackBar.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func,
  isTTBNotice: PropTypes.bool.isRequired,
}

export default withI18next()(BlackBar)
