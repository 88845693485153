import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { AuthProvider } from 'components/auth/context'
import { useState } from 'react'
import Badge from 'components/common/badge'
import Icon from 'components/common/icon'
import ICONS, { ICON_SIZE } from 'components/common/icon/const'
import BackdropModal from 'components/portal/backdrop-modal'
import { parseCyKey } from 'lib/utils/cypress'
import Auth from 'components/auth'
import authDuck from 'components/auth/duck'
import { DynamicCartNoticeModal, DynamicHeaderUser } from '../utils'
import CountrySelectDrawer from '../country-select-drawer/CountrySelectDrawer'

const UserMenu = ({
  cartBadge,
  internationalization: { country },
  isPhone,
  openCart,
  setStage,
  showCartNotice,
  showLogin,
  showSearch,
  toggleSearch,
  updateShowLogin,
}) => {
  // Use istanbul ignore for now
  // because this function cannot test due to recaptcha container on auth components
  /* istanbul ignore next */
  const closeAuthModal = () => {
    // TODO: mock of next/dynamic need to return real component
    updateShowLogin(false, { justSignUp: false })
    setStage(null)
  }
  const [isOpenCountrySelectDrawer, setIsOpenCountrySelectDrawer] =
    useState(false)

  return (
    <div className="header-section header-user">
      <ul className="header-section__ul">
        <AuthProvider>
          <DynamicHeaderUser />
          <BackdropModal
            freezeBody={isPhone}
            isOpen={showLogin}
            onBackdropClick={closeAuthModal}
            zIndex={100003}
          >
            <Auth closeLoginModal={closeAuthModal} />
          </BackdropModal>
        </AuthProvider>

        <li className="desktop">
          <span className="menu__pipe">|</span>
        </li>
        <li
          className="desktop large-menu tooltip-handler header-section__ul-icon-lang"
          data-tip
          data-for="i18n"
          data-cy="nav_icon_lang"
        >
          {!isPhone && (
            <CountrySelectDrawer
              isOpenDrawer={isOpenCountrySelectDrawer}
              countryDrawerTrigger={
                <Icon
                  lazyThreshold={0}
                  alt="country_flag"
                  src={ICONS[country]}
                  size={ICON_SIZE.medium}
                  onClick={() =>
                    setIsOpenCountrySelectDrawer(!isOpenCountrySelectDrawer)
                  }
                />
              }
              onCloseDrawer={() =>
                setIsOpenCountrySelectDrawer(!isOpenCountrySelectDrawer)
              }
            />
          )}
        </li>
        {showSearch && (
          <li
            className="header-section__ul-icon-search"
            data-cy={parseCyKey('nav_user__search')}
          >
            <Icon
              alt="search box icon"
              src={ICONS.search}
              size={ICON_SIZE.medium}
              lazyThreshold={0}
              onClick={toggleSearch}
            />
          </li>
        )}

        <li
          className="header-section__ul-icon-bag"
          data-cy={parseCyKey('nav_user__cart')}
        >
          <Badge
            className="header-icon"
            content={cartBadge?.total_items || 0}
            max={99}
          >
            <Icon
              alt="badge-button"
              src={ICONS.bag}
              size={ICON_SIZE.medium}
              lazyThreshold={0}
              onClick={openCart}
            />
          </Badge>
        </li>
        {showCartNotice && <DynamicCartNoticeModal />}
      </ul>
    </div>
  )
}

UserMenu.defaultProps = {
  cartBadge: undefined,
  isPhone: false,
  showCartNotice: false,
  showLogin: false,
  showSearch: true,
}

UserMenu.propTypes = {
  cartBadge: PropTypes.shape({
    total_items: PropTypes.number,
  }),
  internationalization: PropTypes.shape({
    country: PropTypes.string,
    language: PropTypes.string,
  }).isRequired,
  isPhone: PropTypes.bool,
  openCart: PropTypes.func.isRequired,
  setStage: PropTypes.func.isRequired,
  showCartNotice: PropTypes.bool,
  showLogin: PropTypes.bool,
  showSearch: PropTypes.bool,
  toggleSearch: PropTypes.func.isRequired,
  updateShowLogin: PropTypes.func.isRequired,
}

export default connect(
  (state) => ({
    cartBadge: state.cartBadge.payload,
    internationalization: state.internationalization,
    isPhone: state.device.isPhone,
    showCartNotice: state.modal.showCartNotice,
    showLogin: state.auth.showLogin,
  }),
  (dispatch) =>
    bindActionCreators(
      {
        setStage: authDuck.creators.setStage,
        updateShowLogin: authDuck.creators.updateShowLogin,
      },
      dispatch,
    ),
)(UserMenu)
