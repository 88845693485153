import { useMemo } from 'react'
import clsx from 'clsx'
import Typo from 'constants/typography'
import { BADGE_VERTICAL, BADGE_HORIZONTAL } from './const'

/**
 * Determine what should be display in badge
 * If `max` had been set and `content` is overflow will return `max` with +
 * If `dot` is true will return empty content only
 * @param {number|string} param0.content text to display.
 * @param {number} param0.max max number to show.
 * @param {boolean} param0.dot remove content when display as `dot`
 * @return {?string}
 */
const getDisplay = ({ content, max, dot }) => {
  if (dot) {
    return ''
  }

  if (!content) {
    // When found 0 will convert to string
    return content?.toString()
  }

  if (!Number.isNaN(Number(content))) {
    const displayNumber = Number(content)
    return displayNumber > max ? `${max}+` : displayNumber.toString()
  }

  return content
}

const useBadgeElement = ({
  children,
  className,
  content,
  contentClassName,
  max,
  showZero,
  alignX = BADGE_HORIZONTAL.right,
  alignY = BADGE_VERTICAL.top,
  color,
  dot,
  smallCircle,
  hideAndShowAnimation,
}) => {
  const displayContent = useMemo(
    () => getDisplay({ content, max, dot }),
    [content, max, dot],
  )
  const badgeClass = clsx(
    'pml-badge__content',
    `pml-badge__content-${color}`,
    Typo.caption,
    contentClassName,
    {
      [`pml-badge-${alignY}-${alignX}`]: !!children,
      'pml-badge__circle': !dot && !smallCircle,
      'pml-badge__dot': dot && !smallCircle,
      'pml-badge__small-circle': smallCircle,
      'pml-badge__not-wrapper': !children,
      'pml-badge__content-hidden': !showZero && displayContent === '0',
      'pml-badge__content-hide-and-show-animation': hideAndShowAnimation,
    },
  )
  const classes = clsx('pml-badge', className)

  return {
    displayContent,
    badgeClass,
    classes,
  }
}

export default useBadgeElement
