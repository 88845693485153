import dayjs from 'dayjs'
import { getFlagValue } from 'lib/flagsmith'
import { hasLocizeTranslation } from 'lib/utils/locize'

const FLAG_KEY = 'CountdownVoucherData_20230324'

/**
 * @param config - The config object from the config.json file
 * @param translation - the translation object from the locize API
 * @param user - The user object from the API
 * @returns A text
 */
const getBannerTitle = (config, translation, user) =>
  hasLocizeTranslation(translation, config.skinny_locize_key, '')
    .replace('{COUNTDOWN}', '')
    .replace('{VOUCHER_CODE}', user[0].voucher_code)
    .trim()

/**
 * @param config - The configuration object that you pass to the Banner component.
 * @returns A boolean value
 */
const isBannerActiveNow = (config) => {
  const now = dayjs()
  const active_from = dayjs(config.active_from)
  const active_to = dayjs(config.active_to)

  return active_to > now && active_from < now
}

/**
 * @param config - The configuration object that you pass to the component.
 * @param translation - The language of the banner.
 * @param user - The user object from the context
 * @returns A object of banner configuration value
 */
const getBannerOptions = (config, translation, user) => ({
  id_category: config.id_category,
  id_feed: config.id_feed,
  bg_color: config.bg_color,
  font_color: config.font_color,
  active_to: dayjs(config.active_to),
  active_from: dayjs(config.active_from),
  background_type: 'color',
  details: [
    {
      code: {
        title: getBannerTitle(config, translation, user),
      },
    },
  ],
  type: 'COUNTDOWN',
  radio_type: config.radio_type,
  destination_url: config.destination_url,
})

/**
 * @typedef {Object} FlagConfig
 * @property {string} skinny_locize_key
 * @property {string} bg_color
 * @property {string} text_color
 * @property {string|number} id_category
 * @property {string} radio_type
 * @property {string} destination_deeplink
 * @property {string} destination_url
 * @property {Date|string} active_to
 * @property {Date|string} active_from
 * @property {Array<{customer_group_id:(string|number), voucher_code: string}>} users
 */

/**
 * @typedef {Object} Banner
 * @property {string} id_category
 * @property {string} bg_color
 * @property {string} font_color
 * @property {Date|string} active_to
 * @property {Date|string} active_from
 * @property {'color'|'image'} background_type
 * @property {Array<{code : {title : string}}>} details
 */

/**
 * @param {array} customer_groups
 * @param {function} translation
 * @returns {Promise<Banner|undefined>}
 */
export const getCountdownVoucherSkinnyBannerForUser = (
  customer_groups,
  translation,
) =>
  new Promise((resolve) => {
    const currentUserCustomerGroup = customer_groups
      ? [...customer_groups.map(String)]
      : ['guest']
    getFlagValue(FLAG_KEY, false)
      .then((variant) => {
        try {
          return JSON.parse(variant)
        } catch (e) {
          return false
        }
      })
      .then((config) => {
        if (config && isBannerActiveNow(config)) {
          const userMatchedGroups = config.users?.filter((u) =>
            currentUserCustomerGroup.includes(u.customer_group_id),
          )

          if (userMatchedGroups?.length !== 0) {
            resolve(getBannerOptions(config, translation, userMatchedGroups))
          } else {
            resolve(undefined)
          }
        } else {
          resolve(undefined)
        }
      })
  })
export default {
  getCountdownVoucherSkinnyBannerForUser,
}
