import { request } from 'lib/api'
import { HTTP_METHODS } from './const'

class Address {
  static handleOptions(options) {
    if (!options) return Address.getAddress(options)
    switch (options.type) {
      case 'ADD_ADDRESS':
        return Address.addAddress(options)
      case 'DELETE_ADDRESS':
        return Address.deleteAddress(options)
      case 'UPDATE_ADDRESS':
        return Address.updateAddress(options)
      case 'GET_TH_PICKUP':
        return Address.getPickUpPoints(options)
      default:
        return Address.getAddress(options)
    }
  }

  static getAddress() {
    return request({
      url: 'v6/addresses',
    })
  }

  static addAddress(options) {
    const { type, ...body } = options

    return request({
      body,
      method: HTTP_METHODS.post,
      url: 'v6/addresses',
    })
  }

  static updateAddress(options) {
    const { type, id_address, ...body } = options

    return request({
      body,
      method: HTTP_METHODS.patch,
      url: `v6/addresses/${id_address}`,
    })
  }

  static deleteAddress(options) {
    return request({
      url: `v6/addresses/${options.id_address}`,
      method: HTTP_METHODS.delete,
    })
  }

  static getPickUpPoints(options = {}) {
    const { shop_id, pickup_type } = options
    const filter = {
      ...(shop_id && { shop_id }),
      ...(pickup_type && { type: pickup_type }),
    }

    const query = Object.keys(filter)
      .map((key) => `filter[${key}]=${filter[key]}`)
      .join('&')

    return request({
      url: `v6/pickup-locations?${query}`,
    })
  }

  static getShippingOptions() {
    return request({
      method: HTTP_METHODS.get,
      url: 'v6/shippings/locations',
      params: {
        'filter[type]': 'store|csr|skybox|pin|office',
      },
    })
  }

  static getDistrict(idState) {
    return request({
      url: 'v6/districts/search',
      params: {
        id_state: idState,
      },
    })
  }

  static getPostalCode(idDistrict) {
    return request({
      url: `v6/districts/${idDistrict}`,
    })
  }
}

export default Address
