import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import style from './style.scss'
import useSnackbarElement from './SnackBarHook'

/**
 * @param {object} param0.children content of snack bar can be set to node (eg. <span>foobar</span>)
 * @param {object} param0.action display the actionable area. It renders at the end of snack bar
 * @param {number} param0.duration wait time (ms) before calling `onClose`
 * @param {bool} param0.open set `true` for display
 * @param {string} param0.className override or extend component styles
 * @param {string} param0.cy for Cypress
 * @param {function} param0.onClose callback event when component request to be closed
 * @param {function} param0.onEnter callback event when mouse enter
 * @param {function} param0.onLeave callback event when mouse leave
 */
const SnackBar = forwardRef(
  (
    {
      children,
      action,
      duration,
      open,
      className,
      cy,
      onClose,
      onEnter,
      onLeave,
    },
    ref,
  ) => {
    const { classes, isClose, handleMouseEnter, handleMouseLeave } =
      useSnackbarElement({
        children,
        className,
        duration,
        onClose,
        onEnter,
        onLeave,
        open,
      })

    if (!open && isClose) {
      return null
    }

    return (
      <div
        className={classes}
        data-cy={cy}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        ref={ref}
      >
        <style jsx>{style}</style>
        <div className="pomelo-snack-bar__content">{children}</div>
        {action && <div className="pomelo-snack-bar__action">{action}</div>}
      </div>
    )
  },
)

SnackBar.defaultProps = {
  children: undefined,
  action: undefined,
  duration: undefined,
  open: false,
  className: undefined,
  cy: undefined,
  onClose: undefined,
  onEnter: undefined,
  onLeave: undefined,
}

SnackBar.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  action: PropTypes.shape({}),
  duration: PropTypes.number,
  open: PropTypes.bool,
  className: PropTypes.string,
  cy: PropTypes.string,
  onClose: PropTypes.func,
  onEnter: PropTypes.func,
  onLeave: PropTypes.func,
}

export default SnackBar
