import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withI18next } from 'lib/i18n/withI18next'
import Utils from 'lib/utils'
import addExternalScript from 'lib/utils/external-script'
import env from 'config/env'
import Button from 'components/common/button'
import { BUTTON_TYPES } from 'constants/button'
import Icon from 'components/common/icon'
import ICONS from 'components/common/icon/const'
import { hasLocizeTranslation } from 'lib/utils/locize'

function initializeFreshchatScript(t, i18n, user) {
  window.fcSettings = {
    token: env.FRESHCHAT_TOKEN,
    host: 'https://wchat.freshchat.com',
    tags: i18n.shop === 5 ? ['id'] : ['th'],
    open: false,
    config: {
      headerProperty: {
        backgroundColor: '#979797',
        foregroundColor: 'white',
        backgroundImage: 'none',
        hideChatButton: true,
      },
      content: {
        headers: {
          csat_question: hasLocizeTranslation(
            t,
            'CSAT_QUESTION',
            'Are you satisfied with our service?',
          ),
          push_notification: t(
            'For better customer experience, we recommend enabling web notifications.',
          ),
        },
        actions: {
          csat_yes: hasLocizeTranslation(t, 'CSAT_YES', 'Satisfied :)'),
          csat_no: hasLocizeTranslation(t, 'CSAT_NO', 'Dissatisfied :('),
          push_notify_yes: t('Yes'),
          push_notify_no: t('No'),
        },
      },
    },
  }

  window.fcWidget.init(window.fcSettings)
  Utils.showChat(user)
}

export function onClickFreshchat(t, i18n, user) {
  const scriptTag = document.getElementById('freshchat')

  if (!scriptTag) {
    addExternalScript(
      'https://wchat.freshchat.com/js/widget.js',
      'freshchat',
      () => {
        initializeFreshchatScript(t, i18n, user)
      },
    )
  } else {
    Utils.showChat(user)
  }
}

const FreshChatCTA = ({ user, t, internationalization: i18n }) => (
  <div
    data-cy="pdp__freshchat"
    className="pdp__freshchat"
    role="button"
    onClick={() => onClickFreshchat(t, i18n, user)}
    onKeyDown={() => onClickFreshchat(t, i18n, user)}
    tabIndex="0"
  >
    <Button type={BUTTON_TYPES.link} className="title">
      <Icon src={ICONS.supportChatBlack} />
    </Button>
  </div>
)

FreshChatCTA.defaultProps = {
  user: null,
}

FreshChatCTA.propTypes = {
  user: PropTypes.shape({
    token: PropTypes.string,
  }),
  t: PropTypes.func.isRequired,
  internationalization: PropTypes.shape({}).isRequired,
}

const Extended = withI18next()(FreshChatCTA)

export default connect((state) => ({
  user: state.auth.user,
  internationalization: state.internationalization,
}))(Extended)
