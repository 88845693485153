export const AUTH_UI_STATE = {
  forgotPassword: 'forgotPassword',
  logIn: 'logIn',
  merge: 'merge',
  signUp: 'signUp',
  loyaltySignUp: 'loyaltySignUp',
  thirdPartyVerify: 'thirdPartyVerify',
  userInfoPhone: 'userInfoPhone',
  verifyPhone: 'verifyPhone',
  blockedUser: 'blockedUser',
  welcome: 'welcome',
  v2: {
    emailVerify: 'emailVerify',
    emailSignup: 'emailSignup',
    emailLogin: 'emailLogin',
    phoneSignup: 'phoneSignup',
  },
}

// Firebase Provider List: https://stackoverflow.com/questions/46901153
export const AUTH_PROVIDER = {
  email: 'email',
  facebook: ['Facebook', 'facebook.com'],
  google: ['Google', 'google.com'],
  password: 'password',
  phone: 'phone',
}

export const AUTH_LOGIN_METHOD = {
  facebook: 'FACEBOOK',
  google: 'GOOGLE',
}

export const AUTH_ERROR = {
  codeExpired: 'auth/code-expired',
  dupeAccount: 'auth/account-exists-with-different-credential',
  dupeAccount2: 'auth/account-exists-login-with-different-credential',
  emailExist: 'auth/account-exists-please-login',
  emailNotFound: 400,
  emailNotFoundMessage: 'EMAIL_NOT_FOUND',
  emailUsed: 'auth/email-already-in-use',
  invalid: 401,
  invalidCode: 'auth/invalid-verification-code',
  invalidCodeMessage: 'INVALID_CODE',
  invalidEmail: 'auth/invalid-email',
  invalidEmailApi: 'INVALID_EMAIL',
  invalidPass: 'auth/wrong-password',
  invalidPhone: 'auth/invalid-phone-number',
  invalidSession: 'auth/invalid-session',
  limitExceededException: 'LimitExceededException',
  notAuthorized: 'NotAuthorizedException',
  notFound: 404,
  passwordAttemptsExceeded: 'auth/password-attempts-exceeded',
  popUpCancelled: 'auth/cancelled-popup-request',
  popUpClosed: 'auth/popup-closed-by-user',
  requireReLogin: 'auth/requires-recent-login',
  tooManyRequest: 'auth/too-many-requests',
  userNotFound: 'auth/user-not-found',
  userNotFoundException: 'UserNotFoundException',
  unknown: 'ERROR_UNKNOWN',
  weakPass: 'auth/weak-password',
}

export const AUTH_MESSAGE = {
  alreadyInPool: 'User already in pool',
  codeExpired: 'The verification code has expired. Please try again.',
  dupeAccount: 'Account Exist For Login With Different Credential',
  emailExist: 'Email already exist. Please login instead.',
  emailUsed: 'Sorry email address already used.',
  incorrectUsernameOrPassword: 'Incorrect username or password.',
  invalidCode: 'Incorrect verification code. Please try again.',
  invalidEmail: 'The email address is invalid.',
  invalidUsername: 'Invalid username',
  invalidPass:
    'Your password is incorrect, if you cannot remember your password, you can always recover it.',
  invalidPhone: 'Please enter a valid number.',
  invalidSessionForTheUser: 'Invalid session for the user.',
  notRegis: 'You have not registered yet, please sign up first.',
  passwordAttemptsExceeded: 'Password attempts exceeded',
  resumeMigrationEmailProvidedInvalid: 'The email provided is invalid',
  resumeMigrationUsernameOrPasswordIncorrect:
    'Username or password is incorrect',
  resumeMigrationTheProvidedAccountDoesNotExist:
    'The provided account does not exist',
  userMigrationPreSignUpDuplicateAccountFound:
    'UserMigration failed with error PreSignUp failed with error Duplicate account found..', // 1st migrate
  userMigrationInvalidUsername:
    'UserMigration failed with error rejected, invalid username provided.', // 1st migrate
  userMigrationPasswordResetRequire:
    'UserMigration failed with error Password reset required for the user.', // abandon email migration
  userMigrationInvalidEmailOrPassword:
    'UserMigration failed with error rejected, invalid email or password.', // password doesnot match in firebase
  userMigrationUserDoesnotExist:
    'UserMigration failed with error rejected, legacy user does not exist.', // user not found
  tooManyAttempts: 'Too many attempts',
  tooManyRequests: 'too many requests',
  wait: 'Please wait a few moments before trying again.',
  unknown:
    'Something went wrong. Please wait for a moment before trying again.',
}

export const AUTH_ERROR_INVALID_EMIL_OR_PASSWORD = [
  AUTH_MESSAGE.userMigrationInvalidEmailOrPassword,
  AUTH_MESSAGE.resumeMigrationEmailProvidedInvalid,
  AUTH_MESSAGE.resumeMigrationUsernameOrPasswordIncorrect,
  AUTH_MESSAGE.resumeMigrationTheProvidedAccountDoesNotExist,
]

export const AUTH_ERROR_MIGRATE = [
  AUTH_MESSAGE.userMigrationPreSignUpDuplicateAccountFound,
  AUTH_MESSAGE.userMigrationInvalidUsername,
  AUTH_MESSAGE.userMigrationPasswordResetRequire,
]

export const AUTH_ERROR_MERGE = [
  AUTH_ERROR.dupeAccount,
  AUTH_ERROR.dupeAccount2,
  AUTH_ERROR.emailUsed,
]

export const AUTH_SIGNUP_METHODS = {
  EMAIL: 'email',
  PHONE: 'phone',
}

export const RECAPTCHA_CONTAINER_ID = 'recaptcha_container'

export const ERROR_CODE_EMAIL = [
  AUTH_ERROR.emailExist,
  AUTH_ERROR.emailNotFound,
  AUTH_ERROR.invalidEmailApi,
  AUTH_ERROR.userNotFound,
]

export const AUTH_BLOCK_USER_TYPE = {
  LOGIN: 'LOGIN',
  RESET_PASSWORD: 'FORGOT_PASSWORD',
}

export const AUTH_TYPE = {
  LOGIN: 'CUSTOM_AUTH',
  MIGRATION: 'USER_PASSWORD_AUTH',
}

export const AUTH_DIALOG = {
  AUTH_DIALOG_TITLE: 'Leaving so soon?',
  AUTH_DIALOG_INFO:
    'Closing the screen will take you back to setting up a new password, are you sure you want to leave?',
  AUTH_DIALOG_CTA_1: 'Leave',
  AUTH_DIALOG_CTA_2: 'Stay',
}
