import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { translate } from 'react-i18next'
import useCartRecommendations from 'components/shared/useEffect/useCartRecommendations'
import CartRecommendationSkeleton from './skeleton'
import OneSizeRecommendation from './one-size-recommendation'

const CartRecommendation = ({ cartOpen, cartJustOpened }) => {
  const { data, error } = useCartRecommendations({
    allowFetch: cartOpen,
    forceFetch: cartJustOpened,
  })

  if (!data) {
    return <CartRecommendationSkeleton />
  }

  if (!data?.recommendations?.length || error) {
    return null
  }

  const ONE_SIZE_TYPE = 'recommendation-accessories'

  return data.recommendations.map((recomm) => {
    switch (recomm.type) {
      case ONE_SIZE_TYPE:
        return (
          <OneSizeRecommendation
            key={recomm.type}
            subTypes={recomm.sub_tpyes}
            products={recomm.products}
          />
        )
      default:
        return null
    }
  })
}

CartRecommendation.propTypes = {
  cartOpen: PropTypes.bool.isRequired,
  cartJustOpened: PropTypes.bool.isRequired,
}

const Extended = translate('global')(CartRecommendation)

export { CartRecommendation }

export default connect(
  /* istanbul ignore next */
  (state) => ({
    cartOpen: state.cartData.cartOpen,
  }),
)(Extended)
