import React from 'react'
import PropTypes from 'prop-types'
import Select from 'components/common/select'
import Typo from 'constants/typography'
import styles from './date-of-birth-select.scss'
import { DAYS_OPTIONS, MONTHS_OPTIONS } from './const'

export const generateYearsOptions = (currentYear, startYear = 1923) => {
  if (currentYear === undefined || currentYear || null) {
    const generatedCurrentYear = new Date().getFullYear()
    return Array.from(
      { length: generatedCurrentYear - startYear + 1 },
      (_, index) => startYear + index,
    )
  }

  const yearsArray = Array.from(
    { length: currentYear - startYear + 1 },
    (_, index) => startYear + index,
  )

  return yearsArray
}

/**
 *
 * @param register // pass down the register from useForm hook
 * @description AuthDateOfBirthSelect is an uncontroll form, if the form is needed to use in another place as in controll form, additional props such as value, onChange, disable, error may need to implement.
 * @returns
 */
const AuthDateOfBirthSelect = ({ register }) => {
  const currentYear = new Date().getFullYear()
  const yearsOptions = generateYearsOptions(currentYear, 1923)

  return (
    <div className="date-of-birth-select">
      <style jsx>{styles}</style>
      <div className={Typo.caption}>Date of Birth</div>
      <div className="date-of-birth-select__inputs">
        <Select
          name="date"
          className="date-of-birth-select__date"
          cy="auth__phone_prefix"
          inputRef={register({ required: true })}
          defaultValue={1}
        >
          {DAYS_OPTIONS.map((day) => (
            <option key={day} value={day}>
              {day}
            </option>
          ))}
        </Select>
        <Select
          fullWidth
          name="month"
          className="date-of-birth-select__month"
          cy="auth__phone_prefix"
          inputRef={register({ required: true })}
          defaultValue="Jan"
        >
          {MONTHS_OPTIONS.map((month) => (
            <option key={month} value={month}>
              {month}
            </option>
          ))}
        </Select>
        <Select
          fullWidth
          name="year"
          className="date-of-birth-select__year"
          cy="auth__phone_prefix"
          inputRef={register({ required: true })}
          defaultValue={1990}
        >
          {yearsOptions.map((year) => (
            <option key={year} value={year}>
              {year}
            </option>
          ))}
        </Select>
      </div>
    </div>
  )
}

AuthDateOfBirthSelect.propTypes = {
  register: PropTypes.func.isRequired,
}

export default AuthDateOfBirthSelect
