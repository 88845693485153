import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Link from 'next/link'
import clsx from 'clsx'
import env from 'config/env'
import Icon from 'components/common/icon'
import ICONS from 'components/common/icon/const'
import Typo from 'constants/typography'
import styles from './styles.scss'

const PomeloLogo = ({ internationalization: { country, language } }) => {
  const isProd = env.ENVIRONMENT === 'production'
  let iconType = ''

  if (env.ENVIRONMENT === 'staging') {
    iconType = ICONS.pmlLogoStaging
  } else if (env.ENVIRONMENT === 'pre-production') {
    iconType = ICONS.pmlLogoPreProd
  } else {
    iconType = ICONS.pmlLogo
  }

  return (
    <React.Fragment>
      <style jsx>{styles}</style>
      <Link href="/home" as={`/${country}/${language}/`}>
        <a className="pomelo-logo__wrapper" aria-label="Pomelo Logo">
          <Icon width={96} height={20} src={iconType} />

          <span
            className={clsx(
              Typo.body2,
              'pomelo-logo__version',
              env.ENVIRONMENT,
              {
                hide: isProd,
              },
            )}
          >
            v{env.VERSION}
          </span>
        </a>
      </Link>
    </React.Fragment>
  )
}

export { PomeloLogo }

PomeloLogo.propTypes = {
  internationalization: PropTypes.shape({
    country: PropTypes.string.isRequired,
    language: PropTypes.string.isRequired,
  }).isRequired,
}

export default connect(
  /* istanbul ignore next */
  (state) => ({
    internationalization: state.internationalization,
  }),
)(PomeloLogo)
