import Router from 'next/router'
import { segmentTrackAction } from 'lib/segment'
import { getISOCurrencyCodeWithShopID } from '../common/commonUtils'

export const isLowQuantity = (item) => item.stock?.is_low_quantity

export const isSoldOut = (item) =>
  item?.stock?.is_sold_out || item?.stock?.quantity <= 0

export const isWaitlisted = (item, localWaitlists) =>
  item?.is_on_waitlist || localWaitlists?.[item?.id_product_attribute]

export const getPDPAccordionTrackingData = (product, country) => {
  const { category, id_product, prices } = product
  return {
    id_product,
    id_site_category: category?.id_category,
    price: prices?.original_price,
    currency: getISOCurrencyCodeWithShopID(country).toLowerCase(),
  }
}

export const getRelatedProductTrackingData = (product, data) => {
  const { category } = product
  const { feedPos, relatedProductList, refType } = data
  return {
    feed_position: feedPos,
    id_site_category: category?.id_category,
    product_list_ids: relatedProductList?.map((item) =>
      item.id_product.toString(),
    ),
    ref_type: refType,
  }
}

export const segmentTrackPDPInteract = (
  product,
  country,
  eventName,
  relatedProductData = null,
) => {
  const trackingData = relatedProductData
    ? getRelatedProductTrackingData(product, relatedProductData)
    : getPDPAccordionTrackingData(product, country)
  segmentTrackAction(eventName, trackingData)
}

export const shouldShowVoucherAlert = (
  auth,
  showAllUserVoucher,
  pmloVoucherFlagValue,
) => {
  const showGuestNewUserVoucher =
    pmloVoucherFlagValue === 'B' && !auth.customerData?.is_returning_customer
  return showAllUserVoucher || showGuestNewUserVoucher
}

export const onRelatedProductSectionClick = (
  i18n,
  productId,
  type,
  refType,
) => {
  const { country, language } = i18n
  let href = `/${type}?id_product=${productId}`
  const as = `/${country}/${language}/${type}/${productId}`

  if (refType) {
    href += `&refType=${refType}`
  }

  Router.push(href, as)
}
