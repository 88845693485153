export const AUTH_PDPA_CONTENT = {
  key: 'AUTH_PDPA_CONTENT',
  text: 'I agree to %tnc% and for my personal data to be processed for the purposes stated in, and according to, %pnc%',
}

export const TERMS_AND_CONDITIONS = {
  key: 'TERMS_AND_CONDITIONS',
  text: 'Terms and Conditions',
  link: '/info/terms-and-conditions',
}
