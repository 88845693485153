import React, { useReducer } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { translate } from 'react-i18next'
import Router from 'next/router'
import clsx from 'clsx'
import StateStorage from 'lib/state-storage'
import cartItemsDuck from 'components/cart/cart-items/duck'
import useAddToBag from 'components/shared/useRedux/useAddToBag'
import { SNACKBAR_TYPE } from 'components/cart/cart-snackbar'
import { hasLocizeTranslation } from 'lib/utils/locize'
import Typo from 'constants/typography'
import CartRecommendationArrows from './icon-arrows'
import CartRecommendedProduct from './product-item'
import styles from '../styles.scss'

export function getRecommendationTitle(subTypes, t) {
  const BESTSELLER_SUBTYPE = 'best-seller-accessories'

  if (subTypes.includes(BESTSELLER_SUBTYPE)) {
    return {
      title: hasLocizeTranslation(t, 'ONE_SIZE_REC_BEST_TITLE', 'Best Selling'),
      subtitle: hasLocizeTranslation(
        t,
        'ONE_SIZE_REC_BEST_SUBTITLE',
        'Take a look before it runs out',
      ),
    }
  }

  return {
    title: hasLocizeTranslation(
      t,
      'ONE_SIZE_REC_BROWSING_TITLE',
      'Recommended For You',
    ),
    subtitle: hasLocizeTranslation(
      t,
      'ONE_SIZE_REC_BROWSING_SUBTITLE',
      'Based on your browsing history',
    ),
  }
}

const OneSizeRecommendation = ({
  subTypes,
  products,
  updateCartItem,
  updateSnackbar,
  addToBag,
  t,
  isPhone,
}) => {
  const curImgInitialState = 0
  const curImgReducer = (state, action) =>
    action === 'decrement' ? state - 1 : state + 1
  const [curImg, setCurImg] = useReducer(curImgReducer, curImgInitialState)

  if (!products.length) {
    return null
  }

  const { title, subtitle } = getRecommendationTitle(subTypes, t)

  const onClickProductImg = (product) => {
    updateCartItem({ cartOpen: false })

    const shop = StateStorage.getShop()
    const lang = StateStorage.getLanguage()
    const { id_product, link_rewrite, category_link_rewrite } = product
    const categoryLinkRewrite = category_link_rewrite || 'clothes'
    const linkRewrite = link_rewrite
    const hrefUrl = `/product?id=${id_product}`
    const asUrl = `/${shop}/${lang}/${categoryLinkRewrite}/${id_product}-${linkRewrite}.html`

    Router.push(hrefUrl, asUrl)
  }

  const onClickAddToBag = (product) => {
    const {
      id_product,
      id_category_default,
      link_rewrite,
      attributes = [],
    } = product
    const productAttribute = attributes[0]?.id_product_attribute

    // API accepts id_product_attribute undefined
    // then users cannot remove product from their bags
    if (!productAttribute) {
      return
    }

    addToBag({
      enableInterstitial: false,
      onError: (err) => updateSnackbar(err, SNACKBAR_TYPE.error),
      onSuccess: () =>
        updateSnackbar(
          hasLocizeTranslation(
            t,
            'CART_ITEM_ADD_SUCCESS',
            'Item has successfully added to the bag.',
          ),
          SNACKBAR_TYPE.info,
        ),
      product: {
        id_product,
        category: { id_category: id_category_default, link_rewrite },
      },
      sizeData: {
        id_product_attribute: productAttribute,
        size: 'one size', // For now this feature is only for one size product.
      },
      refData: {
        ref_id: id_product,
        ref_type: 'cart',
      },
      returnParsedError: true,
      t,
    })
  }

  const onClickArrow = (action) => {
    let idx = curImg

    setCurImg(action)

    if (action === 'decrement') {
      idx -= 1
    } else {
      idx += 1
    }

    const elem = document.getElementById(`cart__recomm__product-item-${idx}`)

    if (elem) {
      elem.scrollIntoView({ behavior: 'smooth', inline: 'start' })
    }
  }

  return (
    <div className="cart__recomm__wrapper">
      <style jsx>{styles}</style>
      <div className="cart__recomm__title">
        <span className={Typo.subtitle1}>{title}</span>
        <span className={clsx(Typo.body2, 'cart__recomm__subtitle')}>
          {subtitle}
        </span>
      </div>
      <div className="cart__recomm__products-list">
        {!isPhone && (
          <CartRecommendationArrows
            onClick={onClickArrow}
            curImg={curImg}
            totalProducts={products.length}
          />
        )}
        {products.map((product, idx) => (
          <CartRecommendedProduct
            product={product}
            key={product.id_product}
            onClickProductImg={() => onClickProductImg(product)}
            onClickAddToBag={() => onClickAddToBag(product)}
            idx={idx}
          />
        ))}
      </div>
    </div>
  )
}

OneSizeRecommendation.defaultProps = {
  products: [],
  subTypes: [],
}

OneSizeRecommendation.propTypes = {
  subTypes: PropTypes.arrayOf(PropTypes.string),
  products: PropTypes.arrayOf(
    PropTypes.shape({
      id_product: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      link_rewrite: PropTypes.string,
      category_link_rewrite: PropTypes.string,
      images: PropTypes.shape({
        full_cover_image_path: PropTypes.string.isRequired,
        full_hover_image_path: PropTypes.string,
      }).isRequired,
      prices: PropTypes.shape({
        original_price: PropTypes.number.isRequired,
        original_price_formatted: PropTypes.string.isRequired,
        discounted_price: PropTypes.number,
        discounted_price_formatted: PropTypes.string,
        reduction: PropTypes.shape({
          type: PropTypes.string.isRequired,
          value: PropTypes.number.isRequired,
          value_formatted: PropTypes.string.isRequired,
        }),
      }).isRequired,
      is_back_in_stock: PropTypes.bool.isRequired,
      stock: PropTypes.shape({
        quantity: PropTypes.number,
        is_low_stock: PropTypes.bool.isRequired,
        is_sold_out: PropTypes.bool.isRequired,
      }).isRequired,
    }).isRequired,
  ),
  updateCartItem: PropTypes.func.isRequired,
  addToBag: PropTypes.func.isRequired,
  updateSnackbar: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  isPhone: PropTypes.bool.isRequired,
}

const Extended = translate('global')(OneSizeRecommendation)

export { OneSizeRecommendation }

export default connect(
  (state) => ({
    isPhone: state.device.isPhone,
  }),
  (dispatch) =>
    bindActionCreators(
      {
        updateCartItem: cartItemsDuck.creators.update,
        addToBag: useAddToBag,
        updateSnackbar: cartItemsDuck.creators.updateSnackbar,
      },
      dispatch,
    ),
)(Extended)
