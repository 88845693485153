import clsx from 'clsx'
import PropTypes from 'prop-types'
import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { hasLocizeTranslation } from 'lib/utils/locize'
import { withI18next } from 'lib/i18n/withI18next'
import Typo from 'constants/typography'

const ErrorContainerTitle = ({ isNewUI, isPageError, isPhone, t }) => {
  if (isNewUI) {
    return (
      <span
        className={clsx(
          {
            [Typo.title3]: !isPhone,
            [Typo.subtitle2]: isPhone,
          },
          'popular-title',
        )}
      >
        {hasLocizeTranslation(t, 'NEW_NO_RESULT_TITLE', 'Popular on Pomelo')}
      </span>
    )
  }

  if (isPageError) {
    return <p className={Typo.title3}>{t('Try these instead')}</p>
  }

  return (
    <Fragment>
      <p className={Typo.subtitle1}>{t('Popular Categories')}</p>
      <p className={clsx(Typo.caption, 'empty-results__info')}>
        {t('Check out our bestselling essentials')}
      </p>
    </Fragment>
  )
}

ErrorContainerTitle.defaultProps = {
  isNewUI: false,
  isPageError: false,
}

ErrorContainerTitle.propTypes = {
  isNewUI: PropTypes.bool,
  isPageError: PropTypes.bool,
  isPhone: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
}

export default compose(
  connect((state) => ({
    isPhone: state.device.isPhone,
  })),
  withI18next(),
)(ErrorContainerTitle)
