import cartDuck from 'components/cart/duck'
import modalDuck from 'components/modal/duck'
import StateStorage from 'lib/state-storage'
import Tracking from 'lib/tracking'
import ExchangeRateApiHandler from 'lib/api/exchange'
import { segmentTrackProductAdded } from 'lib/segment'
import { updateLagacyShoppingTrack } from 'lib/auth'

export const ADD_TO_BAG_API_CODE = {
  overLimit: 'QUANTITY_OVER_LIMIT',
  soldOut: 'ITEM_SOLD_OUT',
}

export function getAddedProdSizeQtyInCart(cart, product, sizeId) {
  const listProductMatchId = cart.items.filter(
    ({ id_product }) => id_product === product.id_product,
  )
  const productInCart = listProductMatchId.find(({ attributes }) =>
    attributes.find(
      ({ selected, id_product_attribute }) =>
        selected && id_product_attribute === sizeId,
    ),
  )

  const selectedAttribute = productInCart?.attributes?.find(
    ({ id_product_attribute }) => id_product_attribute === sizeId,
  )

  const stockProductQuantity =
    selectedAttribute?.stock?.quantity || productInCart?.max_quantity
  const checkAllQuantity = stockProductQuantity && productInCart?.quantity

  if (selectedAttribute && checkAllQuantity) {
    const cartQuantity = parseInt(productInCart.quantity, 10)
    const stockQuantity = parseInt(stockProductQuantity, 10)

    return Math.min(stockQuantity, cartQuantity + 1)
  }

  return productInCart?.id_product ? productInCart?.quantity : 1
}

const { toggleCartNotice } = modalDuck.creators
const { fetch: fetchCart } = cartDuck.creators

function useAddToBag({
  enableInterstitial = true,
  enableGATracking = true,
  enableSegment = true,
  onError,
  onFinish = () => null,
  onSuccess = () => null,
  product = null,
  refData = null,
  sizeData = null,
  applyStoreCredit,
}) {
  return async (dispatch, getState) => {
    await dispatch(
      fetchCart({
        type: 'getCart',
        body: {
          is_store_credits_applied: applyStoreCredit,
        },
      }),
    )

    const {
      cart: { payload: cartPayload },
      cartData: { isStoreCreditUsed },
    } = getState()

    if (!cartPayload || !product || !sizeData) {
      return null
    }

    const quantityCart = getAddedProdSizeQtyInCart(
      cartPayload,
      product,
      sizeData.id_product_attribute,
    )

    const res = await dispatch(
      fetchCart({
        productAttributeID: sizeData.id_product_attribute,
        productID: product.id_product,
        quantity: quantityCart,
        segmentAnonymousID: StateStorage.getSegmentAnonymousId(),
        type: 'AddToCart',
        skipCartRender: true, // Temp
        keepPayload: true, // Prevent payload clearing on error
      }),
    )

    if (res) {
      if (enableInterstitial) {
        const interstitialData = res.error
          ? { error: res.error.message }
          : { product }

        dispatch(toggleCartNotice(true, interstitialData))
      }

      if (res?.error && onError) {
        onError(res.error.message)
      } else {
        dispatch(
          fetchCart({
            type: 'GET',
            body: {
              is_store_credits_applied: isStoreCreditUsed,
            },
          }),
        )

        const {
          // Price structure from lookbook content
          discounted_price,
          formatted_discounted_price: discounted_price_formatted,
          formatted_price: original_price_formatted,
          price: original_price,
          prices,
        } = product
        // Price structure on product details (and the rest?)
        let tempPrice = prices

        // TODO: Check one size recommendation, seems prices are undefined.
        if (!tempPrice) {
          tempPrice = {
            discounted_price,
            discounted_price_formatted,
            original_price,
            original_price_formatted,
          }
        }

        if (enableGATracking && tempPrice) {
          ExchangeRateApiHandler.convertTo(
            tempPrice.discounted_price || tempPrice.original_price || 0,
          ).then((priceUsd) => {
            Tracking.trackEvent(Tracking.EVENT_NAME_ADD_TO_CART, {
              'add-to-cart-id': product.id_product || product.id_product_2,
              'add-to-cart-price-usd': priceUsd,
            })
          })
        }

        if (enableSegment) {
          const idCart = res.metadata?.id_cart
          segmentTrackProductAdded(
            { ...product, prices: tempPrice },
            sizeData,
            refData,
            idCart,
          )
        }

        if (onSuccess) {
          onSuccess(res, { product, sizeData })
          await updateLagacyShoppingTrack(res)
        }
      }

      if (onFinish) {
        onFinish(res)
      }
    }

    return res
  }
}

export default useAddToBag
