import { request } from './index'
import { HTTP_METHODS } from './const'

export default function getShoppingBagRecommendation(productIdList) {
  return request({
    url: 'v6/shopping-bag',
    method: HTTP_METHODS.get,
    ...(productIdList && {
      params: {
        product_ids: productIdList,
      },
    }),
  })
}
