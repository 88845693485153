import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import clsx from 'clsx'
import Typo from 'constants/typography'
import TextField from 'components/common/textfield'
import Button from 'components/common/button'
import { BUTTON_HTML_TYPE, BUTTON_TYPES } from 'constants/button'
import { hasLocizeTranslation } from 'lib/utils/locize'
import { sendPasswordResetEmailFireBase } from 'lib/auth'
import { formConfig, INPUT_NAME_EMAIL } from 'components/auth/signup-form/const'
import { withI18next } from 'lib/i18n/withI18next'
import {
  AUTH_ERROR,
  AUTH_UI_STATE,
  AUTH_BLOCK_USER_TYPE,
  AUTH_ERROR_MIGRATE,
  AUTH_MESSAGE,
} from 'components/auth/const'
import { useAuth } from 'components/auth/context'
import AuthHelperSignUp from 'components/auth/shared/helper-sign-up'
import { handlePasswordFlow } from 'components/auth/utils'
import style from './style.scss'

const AuthForgot = ({ t }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [showCustomError, setShowCustomError] = useState(false)
  const [authState, updateAuthState] = useAuth()
  const { errors, formState, handleSubmit, register, setError } = useForm({
    mode: 'onChange',
    defaultValues: {
      [INPUT_NAME_EMAIL]: authState.dataEmail,
    },
  })
  const { isValid } = formState

  const showConfirmationText = async (submittedEmail) => {
    updateAuthState({
      snackBar: `${hasLocizeTranslation(
        t,
        'RESEND_EMAIL_CONFIRM',
        'A confirmation email has been sent to your address',
      )} ${submittedEmail}`,
    })
  }

  const handleFormSubmit = async (values, e) => {
    e?.preventDefault()
    updateAuthState({ overlayLoading: true })
    setIsLoading(true)

    const submittedEmail = values[INPUT_NAME_EMAIL].toLowerCase()

    try {
      await sendPasswordResetEmailFireBase(submittedEmail)
      await showConfirmationText(submittedEmail)
    } catch (err) {
      const isUserNotFound =
        err.code === AUTH_ERROR.userNotFound ||
        [
          AUTH_MESSAGE.userMigrationUserDoesnotExist,
          AUTH_ERROR.emailNotFoundMessage,
        ].includes(err.message)

      const isIncorrectEmail =
        err.code === AUTH_ERROR.userNotFoundException ||
        [AUTH_ERROR.invalid, AUTH_ERROR.notFound].includes(err.statusCode)

      const isBlockLimitExceeded =
        err.code === AUTH_ERROR.limitExceededException ||
        err.name === AUTH_ERROR.limitExceededException

      const isMigrationFlow = AUTH_ERROR_MIGRATE.includes(err.message)

      if (isMigrationFlow) {
        await handlePasswordFlow(submittedEmail)
        return
      }

      if (isUserNotFound) {
        setShowCustomError(true)
      } else if (isIncorrectEmail) {
        setError(
          INPUT_NAME_EMAIL,
          'manual',
          hasLocizeTranslation(
            t,
            'INVALID_EMAIL_PATTERN',
            'Incorrect email, Please try again',
          ),
        )
      } else if (isBlockLimitExceeded) {
        updateAuthState({
          dataEmail: submittedEmail,
          blockType: AUTH_BLOCK_USER_TYPE.RESET_PASSWORD,
          uiState: AUTH_UI_STATE.blockedUser,
        })
      } else {
        updateAuthState({ snackBar: err })
      }
    }

    updateAuthState({ overlayLoading: false })
    setIsLoading(false)
  }

  const onEmailChanged = useCallback(() => {
    if (showCustomError) {
      setShowCustomError(false)
    }
  }, [showCustomError])

  const setLoginStage = useCallback(() => {
    updateAuthState({ uiState: AUTH_UI_STATE.logIn })
  }, [updateAuthState])

  return (
    <React.Fragment>
      <style jsx>{style}</style>
      <span className={clsx(Typo.h4, 'auth__title')}>
        {t('Forgot your Password?')}
      </span>
      <span className={clsx(Typo.body2, 'auth__desc')}>
        {t(
          'Please enter the email address you used to register. We will then send you a new password.',
        )}
      </span>
      <form
        className="auth__forgot-password"
        onSubmit={handleSubmit(handleFormSubmit)}
        noValidate
      >
        <TextField
          error={!!errors[INPUT_NAME_EMAIL]}
          fullWidth
          helperText={
            showCustomError ? (
              <AuthHelperSignUp />
            ) : (
              errors[INPUT_NAME_EMAIL]?.message
            )
          }
          inputRef={register(
            formConfig.email({
              isRequired: true,
              requiredMessage: t('Required'),
              patternErrorMessage: hasLocizeTranslation(
                t,
                'FORM_INVALID_EMAIL_FORMAT',
                'Invalid email format.',
              ),
            }),
          )}
          label={t('Email')}
          htmlFor="email"
          name={INPUT_NAME_EMAIL}
          onChange={onEmailChanged}
          type="email"
          inputProps={{
            id: 'email',
          }}
        />
        <Button
          className="auth__standalone-cta full-width submit"
          disabled={!isValid || isLoading}
          htmlType={BUTTON_HTML_TYPE.submit}
          type={BUTTON_TYPES.primary}
        >
          {hasLocizeTranslation(
            t,
            'RESET_PASSWORD_RESEND_CTA',
            'Send reset link',
          )}
        </Button>
        <Button
          className="auth__standalone-cta full-width back"
          disabled={isLoading}
          htmlType={BUTTON_HTML_TYPE.button}
          onClick={setLoginStage}
          type={BUTTON_TYPES.default}
        >
          {t('Back to Login')}
        </Button>
      </form>
    </React.Fragment>
  )
}

AuthForgot.propTypes = {
  t: PropTypes.func.isRequired,
}

export default withI18next()(AuthForgot)
