import Head from 'next/head'
import PropTypes from 'prop-types'
import React from 'react'
import { getShopsMetaLinks } from 'lib/utils/shop-lang'
import StateStorage from 'lib/state-storage'
import env from 'config/env'
import { DEF_HEAD_META_DATA } from './const'
import generateBreadcrumbs, { getDeeplink } from './utils'

function trimProductPath(productPath) {
  const productId = productPath.replace(/-.+/gi, '')
  return productId
}

/**
 * For handling the sub category path that we have to support
 *
 * Check the file `/server/router.js` at `validCategory2Prefix`
 * @param {string[]} category eg. sub category and category = ['features', 'tops'] or category and category filter = ['tops', 'crop-tops']
 */
export function getCategoryPath(category) {
  if (
    !category?.length ||
    (category[0] !== 'features' && category[0] !== 'lookbooks')
  ) {
    return category
  }

  const parsedCategory = [...category]

  parsedCategory.shift()

  return parsedCategory
}

export function getCategoryCleanedPath(path) {
  if (!path) {
    return ''
  }

  const [, , ...category] = path.split('/')
  const parsedCategory = getCategoryPath(category)

  return `clothes/${parsedCategory.join('/')}`
}

export function getCategoryOriginPath(path) {
  if (!path) {
    return ''
  }

  const [, shop, lang, , ...category] = path.split('/')
  const parsedCategory = getCategoryPath(category)

  return `/${shop}/${lang}/clothes/${parsedCategory.join('/')}`
}

function getPathAfterCheckWithPageName(path, pageName, isCanonical) {
  const otherPath = isCanonical
    ? `${path}`
    : `${trimProductPath(path.slice(1))}`
  const productPath = `${otherPath}.html`
  const categoryPath = isCanonical
    ? getCategoryOriginPath(path)
    : getCategoryCleanedPath(path)
  switch (pageName) {
    case 'category':
      return categoryPath
    case 'product':
      return productPath
    default:
      return otherPath
  }
}

const CustomHead = ({
  cleanedPath,
  deeplink: defaultDeeplink,
  description,
  image,
  originPath,
  pageId,
  pageName,
  pathPrefix,
  title,
  schema,
}) => {
  const deeplink = getDeeplink(pageName, pageId) || defaultDeeplink
  const links = getShopsMetaLinks(
    pathPrefix,
    getPathAfterCheckWithPageName(cleanedPath, pageName, false),
  )
  const schemaList = schema || generateBreadcrumbs()
  const authUserId = String(StateStorage.getAuthUser()?.id_customer ?? '')

  return (
    <Head>
      <title>{title || DEF_HEAD_META_DATA.title}</title>
      <meta
        name="description"
        content={description || DEF_HEAD_META_DATA.description}
      />
      <meta
        property="al:ios:url"
        content={deeplink || DEF_HEAD_META_DATA.deeplink}
      />
      <meta
        property="al:android:url"
        content={deeplink || DEF_HEAD_META_DATA.deeplink}
      />
      <meta
        name="branch:deeplink:page"
        content={pageName || DEF_HEAD_META_DATA.page}
      />
      <meta
        name="branch:deeplink:id"
        content={pageId || DEF_HEAD_META_DATA.id}
      />
      <meta
        name="branch:deeplink:$deeplink_path"
        content={deeplink || DEF_HEAD_META_DATA.deeplink}
      />
      <meta
        name="branch:deeplink:$ios_deeplink_path"
        content={deeplink || DEF_HEAD_META_DATA.deeplink}
      />
      <meta
        name="branch:deeplink:$android_deeplink_path"
        content={deeplink || DEF_HEAD_META_DATA.deeplink}
      />
      <meta property="og:image" content={image || DEF_HEAD_META_DATA.image} />
      <meta property="og:title" content={title || DEF_HEAD_META_DATA.title} />
      <meta
        property="og:description"
        content={description || DEF_HEAD_META_DATA.description}
      />
      <link
        rel="canonical"
        href={`${pathPrefix}${getPathAfterCheckWithPageName(
          originPath,
          pageName,
          true,
        )}`}
      />
      {links.map(({ href, hrefLang }) => (
        <link key={hrefLang} rel="alternate" hrefLang={hrefLang} href={href} />
      ))}
      {schemaList && (
        <script
          type="application/ld+json"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: schemaList }}
        />
      )}
      <script src="//cdnt.netcoresmartech.com/smartechclient.js" />
      <script>
        smartech(`create`, `{env.SMARTECH_CREATE_ID}` ); smartech(`register`, `
        {env.SMARTECH_REGISTER_ID}`); smartech(`identify`, `{authUserId}
        `);
      </script>
    </Head>
  )
}

CustomHead.defaultProps = {
  cleanedPath: '',
  deeplink: '',
  description: '',
  image: '',
  originPath: '',
  pageId: '',
  pageName: '',
  pathPrefix: '',
  title: '',
  schema: '',
}

CustomHead.propTypes = {
  description: PropTypes.string,
  deeplink: PropTypes.string,
  image: PropTypes.string,
  pageId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  cleanedPath: PropTypes.string,
  originPath: PropTypes.string,
  pathPrefix: PropTypes.string,
  pageName: PropTypes.string,
  title: PropTypes.string,
  schema: PropTypes.string,
}

export default CustomHead
