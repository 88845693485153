import clsx from 'clsx'
import Typo from 'constants/typography'

const getClassNameWithOpacity = (checkClass, isSoldOut) =>
  clsx(checkClass, {
    opacity: isSoldOut,
  })

const getPriceClass = (discountValue, isSoldOut) =>
  getClassNameWithOpacity(
    discountValue
      ? clsx(Typo.price2, 'cart-item-info__price-stroke')
      : Typo.price1,
    isSoldOut,
  )

const isLowInStock = (isSoldOut, product, selectedAttribute) =>
  !isSoldOut && product.is_available && selectedAttribute.stock.is_low_quantity

const freeGiftPriceClass = (isShoppingBagNewDesign) =>
  isShoppingBagNewDesign
    ? clsx(Typo.price1, 'free-gift__text-red')
    : clsx(Typo.subtitle2, 'free-gift__text')

export {
  getPriceClass,
  isLowInStock,
  freeGiftPriceClass,
  getClassNameWithOpacity,
}
