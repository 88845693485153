import ICONS from 'components/common/icon/const'
import paymentPendingIcon from 'components/checkout/payment-pending/const'
import { SHOP_COUNTRY_ISO_CODES } from 'constants/shop-language'
import {
  getPaymentGateway,
  isCreditCardExpired,
} from 'lib/utils/payments/paymentMethodUtils'
import { getISOCurrencyCodeWithShopID } from 'lib/utils/common/commonUtils'
import paymentAPI from 'lib/api/payment'
import ExchangeRateApiHandler from 'lib/api/exchange'
import StateStorage from 'lib/state-storage'
import Tracking from 'lib/tracking'
import OrderApi from 'lib/api/order'
import { segmentOrderCompleted } from 'lib/segment'
import { SHIPPING_TYPE_ENUM } from 'constants/order-constants/order'

export const CHECKOUT_STEPS = {
  SHIPPING: 'SHIPPING',
  PAYMENT: 'PAYMENT',
  CONFIRM: 'CONFIRM',
  PAYMENT_RETRY: 'PAYMENT_RETRY',
}

export const GLOBAL_SHIPPING_OPTIONS = [
  {
    id: 'NORMAL',
    name: 'Deliver To My Address',
    hasIcon: false,
    iconLink: '',
  },
]

export const TH_SHIPPING_OPTIONS = [
  {
    id: 'PICKUP',
    name: 'Pomelo Pick Up',
    info: 'Try on and pick up for free in 1-2 days!',
    hasIcon: false,
    iconLink: '',
  },
  {
    id: 'NORMAL',
    name: 'Deliver To My Address',
    info: 'Receive your package in 1-4 business days!',
    hasIcon: false,
    iconLink: '',
  },
]

export const PH_SHIPPING_OPTIONS = [
  {
    id: 'NORMAL',
    name: 'EXPRESS_DELIVERY_SHIPPING_TITLE',
    info: 'EXPRESS_DELIVERY_SHIPPING_INFO',
    hasIcon: false,
    iconLink: '',
  },
]

export const CHECKOUT_SUB_STEPS = {
  SHIPPING: {
    id: 'SHIPPING',
    title: 'Select Your Shipping Method',
    icon: ICONS.webAddress,
    options: [
      {
        country: SHOP_COUNTRY_ISO_CODES.TH,
        items: TH_SHIPPING_OPTIONS,
      },
      {
        country: SHOP_COUNTRY_ISO_CODES.ID,
        items: GLOBAL_SHIPPING_OPTIONS,
      },
      {
        country: SHOP_COUNTRY_ISO_CODES.KH,
        items: GLOBAL_SHIPPING_OPTIONS,
      },
      {
        country: SHOP_COUNTRY_ISO_CODES.SG,
        items: GLOBAL_SHIPPING_OPTIONS,
      },
      {
        country: SHOP_COUNTRY_ISO_CODES.US,
        items: GLOBAL_SHIPPING_OPTIONS,
      },
      {
        country: SHOP_COUNTRY_ISO_CODES.AU,
        items: GLOBAL_SHIPPING_OPTIONS,
      },
      {
        country: SHOP_COUNTRY_ISO_CODES.MY,
        items: GLOBAL_SHIPPING_OPTIONS,
      },
      {
        country: SHOP_COUNTRY_ISO_CODES.HK,
        items: GLOBAL_SHIPPING_OPTIONS,
      },
      {
        country: SHOP_COUNTRY_ISO_CODES.MO,
        items: GLOBAL_SHIPPING_OPTIONS,
      },
      {
        country: SHOP_COUNTRY_ISO_CODES.PH,
        items: PH_SHIPPING_OPTIONS,
      },
      {
        country: SHOP_COUNTRY_ISO_CODES.GLOBAL,
        items: GLOBAL_SHIPPING_OPTIONS,
      },
    ],
  },
  PICKUP: {
    id: 'PICKUP',
    title: 'Pomelo Pick Up',
    icon: ICONS.webShipping,
    parentStep: CHECKOUT_STEPS.SHIPPING,
  },
  SKYBOX: {
    id: 'SKYBOX',
    title: 'SKYBOX BTS Pick Up',
    icon: ICONS.webAddress,
    parentStep: CHECKOUT_STEPS.SHIPPING,
  },
  CHOOSE_YOUR_SHIPPING_METHOD: {
    id: 'CHOOSE_YOUR_SHIPPING_METHOD',
    title: 'Choose Your Shipping Method',
    icon: ICONS.webAddress,
    parentStep: CHECKOUT_STEPS.SHIPPING,
  },
  NORMAL: {
    id: 'NORMAL',
    title: 'Deliver To My Address',
    icon: ICONS.webAddress,
    parentStep: CHECKOUT_STEPS.SHIPPING,
  },
  OFFICE: {
    id: 'OFFICE',
    title: 'Pomelo Head Quarters',
    icon: ICONS.webAddress,
    parentStep: CHECKOUT_STEPS.SHIPPING,
  },
  PAYMENT: {
    id: 'PAYMENT',
    title: 'How Do You Want To Pay',
    icon: ICONS.webPayment,
    parentStep: CHECKOUT_STEPS.PAYMENT,
  },
  CREDIT_CARD: {
    id: 'CREDIT_CARD',
    title: 'Select a Credit/Debit Card',
    icon: ICONS.webCreditCard,
    parentStep: CHECKOUT_STEPS.PAYMENT,
  },
  GOPAY: {
    id: 'GOPAY',
    title: 'Gopay',
    parentStep: CHECKOUT_STEPS.PAYMENT,
  },
  OMISE_MB: {
    id: 'OMISE_MB',
    title: 'Mobile Banking',
    icon: ICONS.webCreditCard,
    parentStep: CHECKOUT_STEPS.PAYMENT,
  },
  OMISE_PROMPTPAY: {
    id: 'OMISE_PROMPTPAY',
    title: 'QR Promptpay',
    icon: ICONS.webCreditCard,
    parentStep: CHECKOUT_STEPS.PAYMENT,
  },
  ADYEN_MB: {
    id: 'ADYEN_MB',
    title: 'Mobile Banking',
    icon: ICONS.webCreditCard,
    parentStep: CHECKOUT_STEPS.PAYMENT,
  },
  NEW_CREDIT_CARD: {
    id: 'NEW_CREDIT_CARD',
    title: 'Add a Credit/Debit Card',
    icon: ICONS.webCreditCard,
    parentStep: CHECKOUT_STEPS.PAYMENT,
  },
  CASH_ON_DELIVERY: {
    id: 'CASH_ON_DELIVERY',
    title: 'Cash on Delivery',
    icon: ICONS.checkoutCOD,
    parentStep: CHECKOUT_STEPS.PAYMENT,
  },
  STORE_CREDITS: {
    id: 'STORE_CREDITS',
    title: 'Store Credits',
    parentStep: CHECKOUT_STEPS.PAYMENT,
  },
  PAY_AT_STORE: {
    id: 'PAY_AT_STORE',
    title: 'Pay At Store',
    icon: ICONS.webPayment,
    parentStep: CHECKOUT_STEPS.PAYMENT,
  },
  ONLINE_BANKING: {
    id: 'Online Banking',
    title: 'Online Banking',
    parentStep: CHECKOUT_STEPS.PAYMENT,
  },
  VT_BCA_BANK_TRANSFER: {
    id: 'VT_BCA_BANK_TRANSFER',
    title: 'BCA Bank Transfer',
    parentStep: CHECKOUT_STEPS.PAYMENT,
  },
  VT_MANDIRI_BILL_PAYMENT: {
    id: 'VT_MANDIRI_BILL_PAYMENT',
    title: 'Mandiri Bank Transfer',
    parentStep: CHECKOUT_STEPS.PAYMENT,
  },
  SUMMARY: {
    id: 'SUMMARY',
    title: 'Review Your Order',
    icon: ICONS.webSummary,
    parentStep: CHECKOUT_STEPS.CONFIRM,
  },
  CONFIRMATION: {
    id: 'CONFIRMATION',
    title: 'Thank You',
    icon: ICONS.webThankYou,
    parentStep: CHECKOUT_STEPS.CONFIRM,
  },
  PAYMENT_PENDING: {
    id: 'PAYMENT_PENDING',
    title: 'Processing',
    icon: paymentPendingIcon,
    parentStep: CHECKOUT_STEPS.CONFIRM,
  },
  PAYMENT_RETRY: {
    id: 'PAYMENT_RETRY',
    title: 'Oops Something went wrong',
    icon: ICONS.webError,
    parentStep: CHECKOUT_STEPS.PAYMENT_RETRY,
  },
  MT_BANK_TRANSFER: {
    id: 'MT_BANK_TRANSFER',
    title: 'Manual Bank Transfer',
    icon: ICONS.payment,
    parentStep: CHECKOUT_STEPS.PAYMENT,
  },
  VT_BANK_TRANSFER: {
    id: 'VT_BANK_TRANSFER',
    title: 'Virtual Bank Transfer',
    icon: ICONS.payment,
    parentStep: CHECKOUT_STEPS.PAYMENT,
  },
  VT_BNI_BANK_TRANSFER: {
    id: 'VT_BNI_BANK_TRANSFER',
    title: 'Virtual BNI Bank Transfer',
    icon: ICONS.payment,
    parentStep: CHECKOUT_STEPS.PAYMENT,
  },
  VT_BRI_BANK_TRANSFER: {
    id: 'VT_BRI_BANK_TRANSFER',
    title: 'Virtual BRI Bank Transfer',
    icon: ICONS.payment,
    parentStep: CHECKOUT_STEPS.PAYMENT,
  },
  MT_BCA_BANK_TRANSFER: {
    id: 'MT_BCA_BANK_TRANSFER',
    title: 'Transfer with BCA',
    parentStep: CHECKOUT_STEPS.PAYMENT,
  },
  MT_MANDIRI_BANK_TRANSFER: {
    id: 'MT_MANDIRI_BANK_TRANSFER',
    title: 'Transfer with Mandiri',
    parentStep: CHECKOUT_STEPS.PAYMENT,
  },
  MT_BNI_BANK_TRANSFER: {
    id: 'MT_BNI_BANK_TRANSFER',
    title: 'Transfer with BNI',
    parentStep: CHECKOUT_STEPS.PAYMENT,
  },
  SITE_TO_STORE_SCHEDULING: {
    id: 'SITE_TO_STORE_SCHEDULING',
    title: 'Select Your Pick Up Date',
    parentStep: CHECKOUT_STEPS.SHIPPING,
  },
  HOOLAH: {
    id: 'HOOLAH',
    title: 'Hoolah Installments',
    parentStep: CHECKOUT_STEPS.PAYMENT,
  },
  AWAIT_PAYMENT: {
    id: 'AWAIT_PAYMENT',
    title: 'Await Payment',
  },
  ABA_KHQR: {
    id: 'ABA_KHQR',
    title: 'ABA_KHQR',
    icon: ICONS.payment,
    parentStep: CHECKOUT_STEPS.PAYMENT,
  },
}

const CHECKOUT_PAYMENT_TOKENS = {
  [CHECKOUT_SUB_STEPS.CREDIT_CARD.id]: {
    type: 'credit-card',
  },
  [CHECKOUT_SUB_STEPS.GOPAY.id]: {
    type: 'gopay',
  },
  [CHECKOUT_SUB_STEPS.OMISE_MB.id]: {
    provider: 'omise',
  },
  [CHECKOUT_SUB_STEPS.OMISE_PROMPTPAY.id]: {
    type: 'omise_promptpay',
  },
  [CHECKOUT_SUB_STEPS.VT_BCA_BANK_TRANSFER.id]: {
    type: 'virtual-bank-transfer',
    provider: 'bca',
  },
  [CHECKOUT_SUB_STEPS.VT_MANDIRI_BILL_PAYMENT.id]: {
    type: 'virtual-bank-transfer',
    provider: 'mandiri',
  },
  [CHECKOUT_SUB_STEPS.VT_BANK_TRANSFER.id]: {
    type: 'virtual-bank-transfer',
    provider: 'other',
  },
  [CHECKOUT_SUB_STEPS.VT_BNI_BANK_TRANSFER.id]: {
    type: 'virtual-bank-transfer',
    provider: 'bni',
  },
  [CHECKOUT_SUB_STEPS.VT_BRI_BANK_TRANSFER.id]: {
    type: 'virtual-bank-transfer',
    provider: 'bri',
  },
  [CHECKOUT_SUB_STEPS.MT_BCA_BANK_TRANSFER.id]: {
    type: 'manual-bank-transfer',
    provider: 'bca',
  },
  [CHECKOUT_SUB_STEPS.MT_BNI_BANK_TRANSFER.id]: {
    type: 'manual-bank-transfer',
    provider: 'bni',
  },
  [CHECKOUT_SUB_STEPS.MT_MANDIRI_BANK_TRANSFER.id]: {
    type: 'manual-bank-transfer',
    provider: 'mandiri',
  },
  [CHECKOUT_SUB_STEPS.PAY_AT_STORE.id]: {
    type: 'pay-at-store',
  },
  [CHECKOUT_SUB_STEPS.CASH_ON_DELIVERY.id]: {
    type: 'cash-on-delivery',
  },
  [CHECKOUT_SUB_STEPS.STORE_CREDITS.id]: {
    type: 'free-order',
  },
  [CHECKOUT_SUB_STEPS.HOOLAH.id]: {
    type: 'hoolah',
  },
  [CHECKOUT_SUB_STEPS.ABA_KHQR.id]: {
    type: 'aba_khqr',
  },
}

export const ORDER_STATUS = {
  ORDER_PAYMENT_SUCCESS: 1,
  ORDER_SUCCESS_PAYMENT_FAILED: 2,
  ORDER_ALREADY_PAID: 3,
  ORDER_SUCCESS_PAYMENT_PENDING: 4,
  ORDER_PRODUCT_SOLD_OUT: 0,
}

export const extractCarrierIDFromCheckoutModel = (checkoutModel) =>
  checkoutModel?.shippingMethod?.id_carrier ?? 0

export const extractShippingIDFromCheckoutModel = ({ shippingAddress }) => {
  const { id_pickup_location, id_address, id_partner_store } = shippingAddress
  return id_pickup_location || id_address || id_partner_store || 0
}

// to meet api requirement
export const extractZoneIDFromCheckoutModel = (checkoutModel) => {
  if (checkoutModel?.shippingAddress) {
    const { country, id_zone, state } = checkoutModel.shippingAddress

    return state?.id_zone || country?.id_zone || id_zone || 0
  }

  return 0
}

export function getPaymentType(paymentMethod, shouldSkip = false) {
  if (shouldSkip) {
    return ''
  }

  switch (paymentMethod.method) {
    case CHECKOUT_SUB_STEPS.CREDIT_CARD.id:
      return getPaymentGateway(StateStorage.getShop())
    case CHECKOUT_SUB_STEPS.ADYEN_MB.id:
    case CHECKOUT_SUB_STEPS.OMISE_MB.id:
      return paymentMethod.type
    case CHECKOUT_SUB_STEPS.STORE_CREDITS.id:
      return 'free_order'
    default:
      return CHECKOUT_SUB_STEPS[paymentMethod.method].id.toLowerCase()
  }
}

export function getPaymentToken({ method, token, type }) {
  const paymentToken = {
    ...CHECKOUT_PAYMENT_TOKENS[method],
    token,
  }

  if (method === CHECKOUT_SUB_STEPS.CREDIT_CARD.id) {
    paymentToken.provider = getPaymentGateway(StateStorage.getShop())
  } else if (method === CHECKOUT_SUB_STEPS.OMISE_MB.id) {
    paymentToken.type = type
  }

  return paymentToken
}

export const getShopTrackingId = (country) => {
  switch (country) {
    case 'th':
      return 1836
    case 'au':
      return 1838
    case 'sg':
      return 1840
    case 'id':
      return 1842
    case 'hk':
    case 'mo':
      return null
    case 'global':
    default:
      return 1844
  }
}

export const isCartTotalZero = (cart) => Boolean(cart?.summary?.sub_total === 0)

export const getSwitchArea = ({ checkoutModel }) => {
  const { subtype, type, method } = checkoutModel
  return (subtype || type || method).toLowerCase()
}

export const logicForPaymentType = async (options) => {
  if (!options) return false

  const apiData = {
    shipping_type: options.shipping_type,
    shipping_id: options.shipping_id,
  }

  const paymentMethods = await paymentAPI.getPaymentMethods(apiData)

  if (!paymentMethods?.error) {
    return paymentMethods?.payment_methods?.find(
      (method) => method.type === options.paymentType,
    )
  }
  return false
}

export const getShippingIdRedux = (shippingAddress) =>
  shippingAddress?.id_pickup_location ||
  shippingAddress?.id_address ||
  shippingAddress?.id_partner_store ||
  0

/**
 * It takes an order object and returns a new order object with the order_details and order_summary
 * properties transformed
 * @param order - The order object that you want to transform.
 * @returns An object with the order details and summary.
 */
const transformedOrderObject = (order) => {
  const { order_details: products, order_summary: summary } = order

  const productObj = products.map((product) => ({
    id_product: product.id_product,
    category: { link_rewrite: product.category_link_rewrite },
    manufacturer: product.manufacturer,
    name: product.product_name,
    prices: {
      original_price: Math.ceil(product.original_product_price_wt),
      discounted_price: Math.ceil(product.unit_price_tax_incl),
    },
    quantity: product.product_quantity,
  }))

  const summaryObj = summary.reduce(
    (name, value) => ({
      ...name,
      [value.name.replace('-', '').toLowerCase()]: value.raw_value,
    }),
    [],
  )

  return {
    ...order,
    order_summary: summaryObj,
    order_details: productObj,
  }
}

export const getOrderDetails = async (orderId) => {
  try {
    const order = await OrderApi.getItem({
      orderId,
    })

    return order ? transformedOrderObject(order) : null
  } catch (error) {
    return null
  }
}

export const trackTransaction = async (
  orderId,
  { checkoutModel, userInfo, internationalization },
) => {
  const total = checkoutModel?.orderSummary?.subTotal || 0
  let customerType = 1

  if (userInfo?.payload?.default_sort === 'new_in') {
    customerType = 2
  }
  const orderDetails = await getOrderDetails(orderId)

  if (orderDetails) {
    const { address, order_details, order_summary } = orderDetails
    segmentOrderCompleted({
      i18n: internationalization,
      customer_type: userInfo?.is_returning_customer,
      is_retry: true,
      products: order_details,
      order_id: orderId,
      summary: {
        ...order_summary,
        shipping_fee: order_summary.shipping || 0,
        applied_voucher: order_summary.discount || 0,
      },
      user: address,
      voucher_name: orderDetails.voucher?.name,
    })
  }

  ExchangeRateApiHandler.convertTo(total).then((res) => {
    Tracking.trackEvent(Tracking.EVENT_NAME_PURCHASE, {
      transactionId: orderId || 0,
      transactionTotal: res,
      'purchase-order-id': orderId || 0,
      'purchase-customer-type': customerType,
      'purchase-sales-amount-usd': res,
      'ia-purchase-offer-id': getShopTrackingId(internationalization.country),
    })
  })
}

export const trackSuccessfulOrder = (cartModel, country, order, userInfo) => {
  const cartItems = cartModel?.items || []
  const total = cartModel?.summary?.total || 0
  const products = cartItems.map((p) => p.id_product || p.id_product_2) || []
  const customerType = userInfo?.payload?.default_sort === 'new_in' || 1
  const productList = cartItems.map((product) => ({
    id: product.id_product,
    price: ExchangeRateApiHandler.syncConvertTo(
      product.prices.discounted_price || product.prices.original_price,
    ),
    quantity: product.quantity ? product.quantity : 1,
    category: product.category.id_category,
    name: product.name,
    sku: product.attributes.find((attr) => attr.selected).id_product_attribute,
  }))
  const currency = getISOCurrencyCodeWithShopID(
    StateStorage.getShop(),
  ).toUpperCase()
  const productSimpleList = productList.map(({ id, price, quantity }) => ({
    id: id.toString(),
    price: price.toString(),
    quantity: quantity.toString(),
  }))
  ExchangeRateApiHandler.convertTo(total).then((res) => {
    Tracking.trackEvent(Tracking.EVENT_NAME_PURCHASE, {
      transactionId: order && order.id_order ? order.id_order : 0,
      transactionTotal: res,
      'purchase-order-id': order ? order.id_order : 0,
      'purchase-customer-type': customerType,
      'purchase-sales-amount-usd': res,
      'ia-purchase-offer-id': getShopTrackingId(country),
      'cart-product-ids': products,
      transactionProducts: productList,
      'criteo-view-cart-or-purchase-usd': productSimpleList.map((product) => ({
        ...product,
        currency,
      })),
    })
  })
}

export const isAwaitPayment = (checkoutModel) =>
  checkoutModel?.paymentMethod?.method === CHECKOUT_SUB_STEPS.AWAIT_PAYMENT.id

export const isTTBShipping = (shippingAddress) => {
  const { method, type, subtype } = shippingAddress
  return (
    method === CHECKOUT_SUB_STEPS.PICKUP.id &&
    type.toLowerCase() === 'pickup' &&
    subtype !== 'office'
  )
}

export const shouldShowTTBVoucherNoticeBanner = (
  activeSubStep,
  shippingAddress,
  cartModel,
) =>
  cartModel?.summary?.applied_voucher?.is_applied &&
  isTTBShipping(shippingAddress) &&
  activeSubStep === CHECKOUT_SUB_STEPS.SUMMARY.id

export const isExceedTTBItemLimit = (
  cartQuantity,
  itemLimit,
  shippingAddress,
  paymentMethod,
) =>
  cartQuantity > itemLimit &&
  isTTBShipping(shippingAddress) &&
  paymentMethod === CHECKOUT_SUB_STEPS.PAY_AT_STORE.id

export const checkedRenderMethod = (order) => {
  const shouldGoToRetryPaymentStep = [
    'failed',
    'failure',
    null,
    undefined,
  ].includes(order?.status)
  return shouldGoToRetryPaymentStep
    ? {
        step: CHECKOUT_STEPS.PAYMENT_RETRY,
        subStep: CHECKOUT_SUB_STEPS.PAYMENT_RETRY.id,
      }
    : {
        step: CHECKOUT_STEPS.CONFIRM,
        subStep: CHECKOUT_SUB_STEPS.PAYMENT_PENDING.id,
      }
}

export const handleRetryOmise3DSError = (
  response,
  setActiveStep,
  setActiveSubStep,
) => {
  const nextStep = checkedRenderMethod(response)

  setActiveStep(nextStep.step)
  setActiveSubStep(nextStep.subStep)
}

export const handleRetryError = (setActiveStep, setActiveSubStep) => {
  setActiveStep(CHECKOUT_STEPS.PAYMENT_RETRY)
  setActiveSubStep(CHECKOUT_SUB_STEPS.PAYMENT_RETRY.id)
}

export const getCartParams = (checkoutModel, isStoreCreditUsed) => {
  const isPaymentDataValid =
    checkoutModel.paymentMethod && !isAwaitPayment(checkoutModel)

  const isTTBPayAtStore = Boolean(
    checkoutModel?.paymentMethod?.isTTBFeePayment &&
      checkoutModel?.shippingAddress?.type === SHIPPING_TYPE_ENUM.PICKUP,
  )

  return {
    type: 'GET',
    body: {
      shipping_token: {
        id_carrier: extractCarrierIDFromCheckoutModel(checkoutModel),
        id_zone: extractZoneIDFromCheckoutModel(checkoutModel),
        shipping_id: extractShippingIDFromCheckoutModel(checkoutModel),
        shipping_type: checkoutModel.shippingAddress.type || 'normal',
      },
      ...(isPaymentDataValid && {
        payment_token: getPaymentToken(checkoutModel.paymentMethod),
      }),
      is_store_credits_applied: isStoreCreditUsed,
      ...(isTTBPayAtStore && {
        is_ttb_pay_at_store: true,
      }),
    },
  }
}

export const getCarrierOptionsFromOrderDetails = (orderDetails) => ({
  zoneId: orderDetails.address?.id_zone,
  productsTotal: orderDetails.total_paid_tax_incl,
  customerId: orderDetails.address?.id_customer,
  stateId: orderDetails.address?.id_state,
  pickupLocId: orderDetails.shipping_id,
  shippingType: orderDetails.shipping_type,
})

/**
 *
 * @param {string} activeSubStep
 * @param {string[]} creditCardList
 * @returns {boolean}
 */
export const hasExpiringCreditCard = (activeSubStep, creditCardList) => {
  const isCreditCardSelectionStep =
    activeSubStep === CHECKOUT_SUB_STEPS.CREDIT_CARD.id

  return (
    isCreditCardSelectionStep &&
    isCreditCardExpired(creditCardList?.credit_cards)
  )
}
