import Duck from 'extensible-duck'

const initialState = [
  {
    id: 'size',
    values: [],
    activeKeys: [],
  },
  {
    id: 'color',
    values: [],
    activeKeys: [],
  },
  {
    id: 'price',
    values: [],
    activeKeys: [],
  },
  {
    id: 'categories',
    values: [],
    activeKeys: [],
  },
  {
    id: 'brand',
    values: [],
    activeKeys: [],
  },
  {
    id: 'sustainability',
    values: [],
    activeKeys: [],
  },
]

const idExists = (state, item) => {
  const stateStorage = state.find(({ id }) => id === item.id)
  return stateStorage?.values?.find((v) => v === item.value)
}

const keyExists = (state, item) => {
  const stateStorage = state.find(({ id }) => id === item.id)
  return stateStorage?.activeKeys?.find((v) => v === item.key)
}

const removeId = (state, item) => {
  const stateStorage = state.find(({ id }) => id === item.id)
  const left = stateStorage?.values?.filter((v) => v !== item.value)
  return left || []
}

const removeKey = (state, item) => {
  const stateStorage = state.find(({ id }) => id === item.id)
  const left = stateStorage?.activeKeys?.filter((v) => v !== item.key)
  return left || []
}

const updateList = (action, state, stateItem) => {
  const newStateItem = stateItem
  if (stateItem.id === action.item.id) {
    newStateItem.values = idExists(state, action.item)
      ? removeId(state, action.item)
      : [action.item.value].concat(stateItem.values)
    newStateItem.activeKeys = keyExists(state, action.item)
      ? removeKey(state, action.item)
      : [action.item.key].concat(stateItem.activeKeys)
  }

  return newStateItem
}

const updateFilter = (action, state) => {
  const newState = state
  action.item.forEach((filter) => {
    const filterList = { ...filter }

    // NOTE: to prevent brands/brand options from API mess up with initialState
    if (filterList.type === 'brands') {
      filterList.type = 'brand'
    }

    const existingFilter = state.find((list) => list.id === filterList.type)
    if (!existingFilter) {
      newState.push({
        id: filterList.type,
        values: [],
        activeKeys: [],
      })
    }
  })

  return state
}

export default new Duck({
  namespace: 'content',
  store: 'filter',
  types: [
    'CLEAN',
    'CLEAN_ID',
    'UPDATE_LIST',
    'UPDATE_RANGE',
    'INITIAL',
    'UPDATE_FILTERS',
  ],
  initialState,
  reducer: (state, action, duck) => {
    switch (action.type) {
      case duck.types.UPDATE_FILTERS:
        return updateFilter(action, state)
      case duck.types.UPDATE_LIST:
        return state.map((stateItem) => updateList(action, state, stateItem))
      case duck.types.UPDATE_RANGE:
        return state.map((stateItem) => {
          if (stateItem.id === action.item.id) {
            return {
              id: stateItem.id,
              values: action.item.value,
              activeKeys: action.item.value,
            }
          }

          return stateItem
        })
      case duck.types.CLEAN_ID:
        return state.map((stateItem) => {
          const newStateItem = stateItem
          if (stateItem.id === action.id) {
            newStateItem.activeKeys = []
            newStateItem.values = []
          }

          return newStateItem
        })
      case duck.types.CLEAN:
        return state.map((stateItem) => {
          const newStateItem = stateItem
          newStateItem.activeKeys = []
          newStateItem.values = []
          return newStateItem
        })
      case duck.types.INITIAL:
        return [...action.state]
      default:
        return state
    }
  },
  selectors: {
    root: (state) => state,
  },
  creators: (duck) => ({
    update: (item) => ({ type: duck.types.UPDATE_LIST, item }),
    updateFilters: (item) => ({ type: duck.types.UPDATE_FILTERS, item }),
    updateRange: (item) => ({ type: duck.types.UPDATE_RANGE, item }),
    clean: () => ({ type: duck.types.CLEAN }),
    cleanId: (id) => ({ type: duck.types.CLEAN_ID, id }),
    initial: (state) => ({ type: duck.types.INITIAL, state }),
  }),
})
