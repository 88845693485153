/* eslint-disable no-use-before-define */
// All functions in this file should only be called from the client side.
import { getPomeloFilteredStatus } from 'components/shared/category/util'
import env from 'config/env'
import ShopLanguageMap from 'lib/i18n/shop-language-map'
import StateStorage from 'lib/state-storage'
import Utils from 'lib/utils'
import {
  getActiveFilters,
  getISOCurrencyCodeWithShopID,
  getSessionStringAsObj,
} from 'lib/utils/common/commonUtils'
import { removeNull } from 'lib/utils/common/object'
import get3rdPartyToUpdatedConsent from 'lib/utils/pdpa-consent'
// eslint-disable-next-line import/no-cycle
import { getFlagValue } from 'lib/flagsmith'
import {
  BRANCH_CPID,
  EVENT,
  SEGMENT_AUTH_EMAIL,
  SEGMENT_AUTH_FACEBOOK,
  SEGMENT_AUTH_FACEBOOK_DOTCOM,
  SEGMENT_AUTH_GOOGLE,
  SEGMENT_AUTH_GOOGLE_DOTCOM,
  SEGMENT_AUTH_PASSWORD,
  SEGMENT_AUTH_PHONE,
  SEGMENT_NO_CLICK_POSITION_REF_TYPES,
  SEGMENT_STORAGE_KEY_PRODUCT,
  SEGMENT_STORAGE_KEY_PROMPT_LOCATION,
  SESSION_STORAGE_SIGN_UP,
  SEGMENT_STORAGE_KEY_CATEGORY,
} from './const'

const shop = StateStorage.getShop()
const lang = StateStorage.getLanguage()
const id_shop = ShopLanguageMap.getIdShopBySlug(shop)
const id_lang = ShopLanguageMap.getIdLangBySlugAndLang(shop, lang)

function logSegment(event, data) {
  if (
    process.env.NODE_ENV !== 'production' &&
    process.env.NODE_ENV !== 'test'
  ) {
    console.log('🦄Segment:', event, data || '') // eslint-disable-line no-console
  }
}

function segmentGetUserTraits() {
  if (window.analytics?.user) {
    return window.analytics.user().traits()
  }

  return null
}

const isOnWhiteListEvents = async (eventName) => {
  const whitelistEventNames = await getFlagValue(
    'WhitelistEventNames_20230704',
    [],
  )
    .then((values) => JSON.parse(values)?.whitelist)
    .catch(() => [])

  return whitelistEventNames?.includes(eventName)
}

export async function segmentTrackAction(eventName, data) {
  const browser_fingerprint_id = await Segment.getBranchFingerPrint()
  const fbpId = StateStorage.getCookie('_fbp')
  const fbcId = StateStorage.getCookie('_fbc')
  const isValidEvent = await Segment.isOnWhiteListEvents(eventName)

  if (window.analytics && isValidEvent) {
    const parsedData = removeNull({
      ...data,
      id_shop: data?.id_shop || id_shop,
      ...(!!browser_fingerprint_id && { browser_fingerprint_id }),
      _fbp: fbpId,
      _fbc: fbcId,
    })

    const userTraits = segmentGetUserTraits()
    const {
      branch_cpid,
      email,
      first_name,
      last_name,
      id_customer,
      registration_date,
    } = userTraits || {}

    const contextData = removeNull({
      branch_cpid,
      email,
      first_name,
      last_name,
      id_customer,
      registration_date,
    })

    // For `parsedData`, the key with value of `undefined` won't be sent to the Segment. (Automatically)
    window.analytics.track(eventName, parsedData, {
      context: { traits: contextData },
    })

    Segment.logSegment(eventName, parsedData)
  }
}

// #region Identify
export async function segmentIdentifyUser(authState, idLang) {
  if (window.analytics) {
    const isoCode =
      ShopLanguageMap.getISOCodeBySlugAndLang(shop, lang) ||
      window.navigator.language
    const {
      user: { id_customer, email, firstname, lastname },
      lastSignInTime,
    } = authState
    const idCustomer = id_customer || 0
    const data = {
      branch_cpid: Segment.getBranchCrossPlatformId() || '',
      current_app_version: env.VERSION,
      email,
      first_name: firstname,
      id_customer: parseRefVal(idCustomer),
      id_lang: idLang || id_lang,
      id_shop,
      last_name: lastname,
      registration_date: lastSignInTime,
      iso_code: isoCode,
    }

    window.analytics.identify(idCustomer, data)
    Segment.logSegment('identify', data)
  }
}

export function segmentIdentifyGuestMode(anonymousID = null) {
  if (window.analytics) {
    if (anonymousID) {
      window.analytics.user().anonymousId(anonymousID)
    }

    window.analytics.identify()
    logSegment('identify')
  }
}
// #endregion

// #region Authentication
export function segmentAuthentication(method, isNewUser, id_customer) {
  const provider = getProviderId(method)
  const prompt_location =
    sessionStorage.getItem(SEGMENT_STORAGE_KEY_PROMPT_LOCATION) || ''
  sessionStorage.removeItem(SEGMENT_STORAGE_KEY_PROMPT_LOCATION)

  if (isNewUser) {
    segmentTrackSignUp(provider, prompt_location, parseRefVal(id_customer))
  } else {
    segmentTrackLogin(provider, prompt_location)
  }
}

export function segmentReset() {
  if (window && window.analytics) {
    window.analytics.reset()
    logSegment('reset')
  }
}

function getProviderId(providerId) {
  switch (providerId) {
    case SEGMENT_AUTH_PASSWORD:
      return SEGMENT_AUTH_EMAIL
    case SEGMENT_AUTH_FACEBOOK_DOTCOM:
      return SEGMENT_AUTH_FACEBOOK
    case SEGMENT_AUTH_GOOGLE_DOTCOM:
      return SEGMENT_AUTH_GOOGLE
    case SEGMENT_AUTH_PHONE:
      return SEGMENT_AUTH_PHONE
    default:
      return ''
  }
}

function segmentTrackSignUp(provider, prompt_location, id_customer) {
  Segment.segmentTrackAction(EVENT.signedUp, {
    id_customer,
    id_lang,
    id_shop,
    prompt_location,
    signup_method: provider,
  })
  sessionStorage.setItem(SESSION_STORAGE_SIGN_UP, '1')
}

function segmentTrackLogin(provider, prompt_location) {
  const iso_code =
    ShopLanguageMap.getISOCodeBySlugAndLang(shop, lang) ||
    window.navigator.language

  segmentTrackAction(EVENT.loggedIn, {
    login_method: provider,
    prompt_location,
    iso_code,
  })
}
// #endregion

// #region Cart
/**
 * @param {Object} options - The data to track
 * @param {integer} options.id_cart
 * @param {integer} options.id_shop
 * @param {number} options.cart_subtotal
 * @param {string} options.currency
 * @param {string} [options.ref_type]
 * @param {string} [options.ref_id]
 * @param {Object[]} options.products - products in cart
 * @param {integer} options.products[].id_product
 * @param {string} options.products[].name
 * @param {number} options.products[].price
 * @param {integer} options.products[].quantity
 * @param {number} [options.products[].item_discount] - in amount of currency not percentage
 */
export function segmentTrackCartViewed(options) {
  const { eventType } = options
  let data = options

  const cart_contents = getProductDetailFromCart(options.products) || []

  if (cart_contents) {
    data = { ...data, cart_contents }
    delete data.products
  }

  delete data.eventType

  segmentTrackAction(eventType, data)
}
// #endregion

// #region Products
/**
 *
 * @param {*} data
 * @param {TrackProductViewedRef} ref
 * @param {boolean} needRefType
 */
function parseReferer(data = {}, ref = {}, needRefType = false) {
  const {
    click_position,
    color_swatch,
    ref_id,
    ref_type,
    refresh,
    sub_position,
    query_id,
    index,
    id_recommendation,
    ref_layout,
    id_lookbook,
    lookbook_name,
  } = ref

  if (!ref_type || ref_type === 'external') {
    if (!ref_type && needRefType) {
      const refType =
        sessionStorage.getItem(SEGMENT_STORAGE_KEY_CATEGORY.type) || 'undefined'

      return { ...data, ref_type: refType }
    }

    return {
      ...data,
      ref_type: 'external',
      ...(!!refresh && { refresh: true }),
    }
  }

  const includeClickPosition =
    click_position && !SEGMENT_NO_CLICK_POSITION_REF_TYPES.includes(ref_type)

  return {
    ...data,
    ...(!!click_position &&
      includeClickPosition && { click_position: parseRefVal(click_position) }),
    ...(!!color_swatch && { color_swatch }),
    ...(!!ref_id && { ref_id: ref_id.toString() }),
    ...(!!ref_type && { ref_type }),
    ...(!!sub_position && { sub_position: parseRefVal(sub_position) }),
    ...(!!refresh && { refresh: true }),
    ...(!!query_id && { query_id }),
    ...(!!index && { index }),
    ...(!!id_recommendation && { id_recommendation }),
    ...(!!ref_layout && { ref_layout }),
    ...(!!id_lookbook && { id_lookbook }),
    ...(!!lookbook_name && { lookbook_name }),
  }
}

function parseRefVal(val) {
  const intVal = parseInt(val, 10)
  return Number.isInteger(intVal) ? intVal : val
}

/**
 * @param {{
 *  id_site_category: number,
 *  site_category_name: string,
 *  page_number: number,
 *  product_list_ids: number[],
 *  sort_by: string,
 *  active_filters?: {},
 * }} options
 * @param {{}} ref
 */
const getProductListIdsString = (product_list_ids) =>
  Utils.isArray(product_list_ids) ? product_list_ids.map(String) : []
export function segmentTrackProductListViewed(options, ref) {
  const {
    id_site_category,
    site_category_name,
    page_number,
    product_list_ids,
    sort_by,
    active_filters,
  } = options
  const filters = getActiveFilters(active_filters)
  const product_list_ids_string = getProductListIdsString(product_list_ids)

  let data = {
    id_shop,
    page_number,
    product_list_ids: product_list_ids_string,
    concat_product_ids: product_list_ids_string.join(','),
    sort_by: sort_by || 'popular',
    ...(!!id_site_category && { id_site_category }),
    ...(!!site_category_name && {
      site_category_name: site_category_name
        ? site_category_name.toLowerCase()
        : '',
    }),
    ...(Object.keys(filters).length > 0 && { active_filters: filters }),
    ref_layout: 'list',
  }
  data = parseReferer(data, ref, true)
  segmentTrackAction(EVENT.productListViewed, data)
}

/**
 * A function to get referer data
 * @param {*} data
 */
function getRefererData(data) {
  const productStack =
    getSessionStringAsObj(SEGMENT_STORAGE_KEY_PRODUCT.stack) || {}
  const categoryRef = productStack[SEGMENT_STORAGE_KEY_PRODUCT.category_uuid]

  if (categoryRef) {
    return {
      ...data,
      ref_type: 'category',
      ref_id: data.id_product,
    }
  }
  if (window.history.state) {
    const urlParams = new URLSearchParams(window.history.state.url)
    const paramKey = urlParams.get('page_uuid')

    return parseReferer(
      data,
      productStack[paramKey] ||
        productStack[SEGMENT_STORAGE_KEY_PRODUCT.ssr_uuid],
    )
  }

  return data
}

/**
 *
 * @param {{
 *  id_product: number,
 *  name: string,
 *  category: { id_category: number, link_rewrite: string }
 *  metadata: { tracking: { color_name: string } },
 *  prices: { discounted_price: number, original_price: number },
 *  manufacturer: { name: string },
 *  }} product
 * @param {{ size: string }} sizeData
 * @param {{ refData: {} }} refData pass this in to override the normal refData
 * @param {number} idCart
 */
export function segmentTrackProductAdded(product, sizeData, refData, idCart) {
  const {
    id_product,
    name,
    category: { id_category, link_rewrite } = {},
    metadata,
    prices,
    manufacturer,
  } = product

  let data = {
    id_shop,
    brand: manufacturer?.name,
    id_product,
    product_id: id_product,
    product_name: name,
    id_cart: idCart || 0,
    size: sizeData?.size?.toLowerCase() || '',
    quantity: 1,
    currency: getISOCurrencyCodeWithShopID(shop).toUpperCase(),
    ...(!!id_category && { id_site_category: id_category }),
    ...(!!link_rewrite && {
      site_category_name: link_rewrite.toLowerCase(),
    }),
    ...(!!refData && refData),
    ...(!!prices && {
      price: prices.discounted_price || prices.original_price,
    }),
  }

  if (metadata?.tracking?.color_name) {
    data = { ...data, color: metadata.tracking.color_name.toLowerCase() }
  }

  if (!refData) {
    data = getRefererData(data)
  }

  Segment.segmentTrackAction(EVENT.productAdded, data)
}

/**
 * @typedef {Object} TrackProductViewedRef
 * @property {string | number | undefined} click_position
 * @property {boolean | undefined} color_swatch
 * @property {string | undefined} ref_id
 * @property {string} ref_type
 * @property {boolean | undefined } refresh
 * @property {string | number | undefined} sub_position
 * @property {number | undefined} query_id
 * @property {number | undefined} index
 *
 */
/**
 *
 * @param {{
 *  id_product: number,
 *  metadata: { tracking: { color_name: string } },
 *  name: string,
 *  category: { link_rewrite: string },
 *  manufacturer: { name: string },
 * }} product
 * @param {TrackProductViewedRef} ref additional tracking data
 */
export function segmentTrackProductViewed(product, ref) {
  const {
    category,
    id_product,
    name,
    metadata,
    category: { link_rewrite } = {},
    manufacturer,
  } = product
  let data = {
    id_shop,
    brand: manufacturer?.name,
    id_product,
    product_id: id_product,
    product_name: name.toLowerCase(),
    id_site_category: category.id_category,
    site_category_name: link_rewrite.toLowerCase(),
  }

  if (metadata?.tracking?.color_name) {
    data = { ...data, color: metadata.tracking.color_name.toLowerCase() }
  }

  data = parseReferer(data, ref)

  Segment.segmentTrackAction(EVENT.productViewed, data)
}

/**
 *
 * @param {{
 * product: {
 *  id_product: number,
 *  name: string,
 *  category: { id_category: number, link_rewrite: string }
 *  metadata: { tracking: { color_name: string } },
 *  prices: { discounted_price: number, original_price: number },
 *  manufacturer: { name: string },
 *  },
 *  sizeData: { size: string },
 *  }} segmentData
 * @param {{ ref_id: number, ref_type: string }} refData
 * @param {bool} isNotifySuccess a boolean to track whether users are successful in notifying sold-out product or not
 */
export function segmentTrackProductSoldOutNotifyMe(
  segmentData,
  refData,
  isNotifySuccess,
) {
  const {
    product: {
      id_product,
      name,
      category: { id_category, link_rewrite } = {},
      metadata,
      prices,
      manufacturer,
    },
    sizeData,
  } = segmentData

  let data = {
    brand: manufacturer?.name,
    ...(!!metadata?.tracking?.color_name && {
      color: metadata.tracking.color_name?.toLowerCase(),
    }),
    currency: getISOCurrencyCodeWithShopID(shop).toUpperCase(),
    fb_content: JSON.stringify({ id: id_product, quantity: 1 }),
    id_product,
    id_shop,
    ...(!!id_category && { id_site_category: id_category }),
    is_successful: isNotifySuccess,
    ...(!!prices && {
      price: prices.discounted_price || prices.original_price,
    }),
    product_name: name,
    quantity: 1,
    ...(!!refData && refData),
    size: sizeData?.size?.toLowerCase() || '',
    ...(!!link_rewrite && {
      site_category_name: link_rewrite.toLowerCase(),
    }),
  }

  if (!refData) {
    data = getRefererData(data)
  }

  Segment.segmentTrackAction(EVENT.productSoldOutNotifyMe, data)
}
// #endregion

/**
 *
 * @param {{
 * product: {
 *  id_product: number,
 *  name: string,
 *  category: { id_category: number, link_rewrite: string }
 *  metadata: { tracking: { color_name: string } },
 *  prices: { discounted_price: number, original_price: number },
 *  manufacturer: { name: string },
 *  },
 *  sizeData: { size: string },
 *  }} segmentData
 * @param {{ ref_id: number, ref_type: string }} refData
 * @param {bool} isNotifySuccess a boolean to track whether users are successful in notifying sold-out product or not
 */
export function segmentTrackProductComingSoonNotifyMe(
  segmentData,
  refData,
  isNotifySuccess,
) {
  const {
    product: {
      id_product,
      name,
      category: { id_category, link_rewrite } = {},
      metadata,
      prices,
      manufacturer,
    },
    sizeData,
  } = segmentData

  let data = {
    brand: manufacturer?.name,
    ...(!!metadata?.tracking?.color_name && {
      color: metadata.tracking.color_name?.toLowerCase(),
    }),
    currency: getISOCurrencyCodeWithShopID(shop).toUpperCase(),
    fb_content: JSON.stringify({ id: id_product, quantity: 1 }),
    id_product,
    id_shop,
    ...(!!id_category && { id_site_category: id_category }),
    is_successful: isNotifySuccess,
    ...(!!prices && {
      price: prices.discounted_price || prices.original_price,
    }),
    product_name: name,
    quantity: 1,
    ...(!!refData && refData),
    size: sizeData?.size?.toLowerCase() || '',
    ...(!!link_rewrite && {
      site_category_name: link_rewrite.toLowerCase(),
    }),
  }

  if (!refData) {
    data = getRefererData(data)
  }

  Segment.segmentTrackAction(EVENT.productComingSoonNotifyMe, data)
}
// #endregion

/**
 * @param {{
 *  id_product: number,
 *  name: string,
 *  metadata: { tracking: { color_name: string } },
 *  category: { id_category: number, link_rewrite: string }
 *  prices: { discounted_price: number, original_price: number },
 * }} product
 */
export function segmentTrackProductAddedToWishlist(product, refType) {
  const {
    id_product,
    name,
    metadata,
    category: { id_category, link_rewrite } = {},
    prices,
    manufacturer,
  } = product

  const data = {
    brand: manufacturer?.name,
    id_shop,
    id_product,
    product_id: id_product,
    product_name: name,
    price: prices.discounted_price || product.prices.original_price,
    currency: getISOCurrencyCodeWithShopID(shop).toUpperCase(),
    site_category_name: link_rewrite,
    ref_type: refType || 'undefined',
    ...(!!metadata?.tracking?.color_name && {
      color: metadata.tracking.color_name?.toLowerCase(),
    }),
    ...(!!id_category && { id_site_category: id_category }),
  }

  segmentTrackAction(EVENT.productAddedToWishlist, data)
}

// #region Search
/**
 * @param {{
 *  query: string,
 *  page_number?: number,
 *  productIds: number[],
 *  sort_by: string,
 *  active_filters?: {},
 *  query_id: string,
 *  query_idx: string,
 * }} options
 */
export function segmentTrackSearchProducts(options) {
  const {
    active_filters,
    page_number = 1,
    productIds,
    query,
    sort_by,
    query_id,
    query_idx,
  } = options
  const filters = getActiveFilters(active_filters)
  const data = {
    id_shop,
    page_number,
    query: query ? query.toLowerCase() : '',
    sort_by: sort_by || 'popular',
    ...(Boolean(productIds) && { search_results: productIds.map(String) }),
    ...(Object.keys(filters).length > 0 && { active_filters: filters }),
    ...(query_id && { query_id }),
    ...(query_idx && { index: query_idx }),
  }

  segmentTrackAction(EVENT.searchedProducts, data)
}
// #endregion

// #region Checkout
/**
 * @param {{
 *  cartData: {
 *    eligibility: {},
 *    items: [],
 *    metadata: {
 *      id_cart: number,
 *    },
 *    summary: {},
 *  }}} cartData
 *  @param { string } currency
 *  @param { number } idCart
 */
export function segmentTrackCheckoutStarted(cartData, currency, idCart) {
  segmentTrackAction(EVENT.checkoutStarted, {
    id_shop,
    cart_subtotal: cartData.summary.subtotal,
    currency,
    cart_contents: getProductDetailFromCart(cartData.items),
    id_cart: idCart,
  })
}

/**
 * @param {{
 *  id_cart: number,
 *  number_of_saved_cards: number,
 *  payment_method_type: string,
 * }} segmentData
 */
export function segmentTrackCheckoutStepCompleted(step_name, segmentData) {
  const { id_cart, payment_method_type, number_of_saved_cards } = segmentData
  segmentTrackAction(EVENT.checkoutStepCompleted, {
    step_name,
    id_shop,
    id_cart,
    ...(!!payment_method_type && { payment_method_type }),
    ...(!!number_of_saved_cards && { number_of_saved_cards }),
  })
}
// #endregion

export function segmentLanguageSelected(iso_code, shopId, prompt_location) {
  segmentTrackAction(EVENT.languageSelected, {
    iso_code,
    id_shop: shopId,
    prompt_location,
  })
}

export function getBranchCrossPlatformId() {
  return localStorage ? localStorage.getItem(BRANCH_CPID) : null
}

/**
 * This function gets called when the app is loaded first time on client side.
 * Have to loop to find out if Branch script is loaded with the required feature yet.
 * @param {*} tries number of the loop
 */
export function fetchBranchCrossPlatformId(tries = 1) {
  if (Segment.getBranchCrossPlatformId() || tries > 30) {
    return
  }

  // Retry
  if (!window.branch?.crossPlatformIds) {
    setTimeout(() => Segment.fetchBranchCrossPlatformId(tries + 1), 500)
    return
  }

  window.branch.crossPlatformIds((err, data) => {
    if (err) {
      // Error logging
    } else if (data?.cross_platform_id) {
      localStorage.setItem(BRANCH_CPID, data.cross_platform_id)
    }
  })
}

export function getBranchFingerPrint() {
  if (Segment.cachedFingerprint) {
    return Segment.cachedFingerprint
  }

  if (!window.branch?.getBrowserFingerprintId) {
    return ''
  }

  const getFingerprint = new Promise((resolve, reject) => {
    window.branch.getBrowserFingerprintId((err, fingerprint) => {
      if (err) {
        reject(err)
        return
      }

      Segment.cachedFingerprint = fingerprint
      resolve(fingerprint)
    })
  })

  return getFingerprint
}

function getProductDetailFromCart(products) {
  if (!products?.length) {
    return null
  }

  return products.map((product) => ({
    id_product: product.id_product,
    product_name: product.name,
    price: product.prices.discounted_price || product.prices.original_price,
    quantity: product.quantity,
    item_discount: getDiscountedAmount(product.prices),
  }))
}

function parseSegmentNumber(val) {
  return parseFloat(val?.toFixed(2))
}

function getDiscountedAmount(cart) {
  if (
    cart?.discounted_price > 0 &&
    cart?.original_price !== cart?.discounted_price
  ) {
    return parseSegmentNumber(cart.original_price - cart.discounted_price)
  }

  return 0
}

export function segmentCouponApplied(voucher_name, id_cart, apply_method) {
  segmentTrackAction(EVENT.couponApplied, {
    voucher_name,
    id_cart,
    id_shop,
    apply_method,
  })
}

/**
 *
 */
export function segmentCouponFailed(
  voucher_name,
  id_cart,
  status_code,
  reason,
  location,
) {
  segmentTrackAction(EVENT.couponFailed, {
    voucher_name,
    id_cart,
    id_shop,
    status_code,
    reason,
    location,
  })
}

/**
 * @param {{
 *  activeKeys: array,
 *  id: string,
 *  value: array,
 * }} filter
 * @param {string} query
 * @param {{
 *  id_site_category: number,
 *  site_category_name: string,
 *  is_quick_filter: boolean,
 * }} ref
 * @param {array} newOption
 */
export function segmentProductListFiltered({ filter, query, ref, newOption }) {
  const { id, label, value, pmlFilter } = newOption || {}
  const isFilteringCategory = id === 'categories'
  const optionId = id?.toLowerCase()
  const activeFilters = getActiveFilters(filter)
  const hasPriceAsActiveFilter = Boolean(activeFilters?.price_filter_max)
  const isPmlBrandFiltered = getPomeloFilteredStatus(activeFilters)
  const isNotSpecificFilters =
    optionId && !['sustainability', 'price', 'categories'].includes(optionId)

  const segmentData = {
    id_shop,
    ...(isFilteringCategory && { category_filter: [value] }),
    ...(isNotSpecificFilters && {
      [`${optionId?.replace(' ', '_')}_filter`]: [value],
    }),
    ...(optionId === 'sustainability' && {
      sustainability: [label],
    }),
    ...(optionId === 'price' && {
      price_filter_min: value[0],
      price_filter_max: value[1],
      price_filter_currency: ShopLanguageMap.getCurrencyISOCodeByShopSlug(shop),
    }),
    ...(!!activeFilters && { active_filters: activeFilters }),
    ...(hasPriceAsActiveFilter && {
      price_filter_currency: ShopLanguageMap.getCurrencyISOCodeByShopSlug(shop),
    }),
    ...(!!query && { query }),
    ...(!!isPmlBrandFiltered && { is_pml_filter_used: !!pmlFilter }),
    ...(ref && ref),
  }

  segmentTrackAction(EVENT.productListFiltered, segmentData)
}

/**
 * @param {number} id_site_category
 * @param {string} site_category_name
 * @param {string} sort_by
 * @param {string} query
 */
export function segmentProductListSorted(
  id_site_category,
  site_category_name,
  sort_by,
  query,
) {
  segmentTrackAction(EVENT.productListSorted, {
    ...(!!id_site_category && { id_site_category }),
    ...(!!site_category_name && { site_category_name }),
    ...(!!query && { query }),
    sort_by: sort_by || 'popular',
    id_shop,
  })
}

/**
 * @param { number } id_product
 * @param { number } stock_available_sku
 * @param { number } id_site_category
 * @param { string } site_category_name
 */
export function segmentProductRemoved(
  id_product,
  stock_available_sku,
  id_site_category,
  site_category_name,
) {
  let data = {
    id_shop,
    id_product,
    product_id: id_product,
    stock_available_sku,
  }

  if (id_site_category) {
    data = { ...data, id_site_category }
  }

  if (site_category_name) {
    data = { ...data, site_category_name }
  }

  segmentTrackAction(EVENT.productRemoved, data)
}

/**
 * @param {{
 *  id_customer: number,
 *  order_id: number,
 *  ref_type: string,
 * }} data
 */
export function segmentOrderDetailviewed(data) {
  segmentTrackAction(EVENT.orderDetailsViewed, { ...data, id_shop })
}

export function segmentOrderCompleted({
  customer_type,
  i18n,
  idCart,
  order_id,
  products,
  summary,
  user,
  voucher_name,
  is_retry,
}) {
  let discount = 0
  let revenue = 0
  let shipping = 0
  let total = 0
  const parsedProducts = []

  if (summary) {
    const {
      shipping_fee,
      subtotal,
      total: cartTotal,
      applied_voucher,
    } = summary

    discount += applied_voucher?.discount || 0
    revenue = Math.max(Segment.parseSegmentNumber(subtotal), 0)
    shipping = Segment.parseSegmentNumber(shipping_fee)
    total = Segment.parseSegmentNumber(cartTotal)
  }

  const product_ids = []
  if (products) {
    products.forEach((product) => {
      const {
        id_product: productId,
        prices: { original_price, discounted_price },
      } = product
      discount += original_price - (discounted_price || original_price)

      parsedProducts.push({
        brand: product.manufacturer,
        category: product.category.link_rewrite,
        id: productId,
        id_product: productId,
        name: product.name || '',
        price: discounted_price || original_price,
        product_id: productId,
        quantity: product.quantity,
        sku: productId.toString(),
      })
      product_ids.push(productId)
    })
  }

  const fb_content = parsedProducts.map((product) => ({
    id: product.id,
    quantity: product.quantity,
  }))

  Segment.segmentTrackAction(EVENT.orderCompleted, {
    currency: getISOCurrencyCodeWithShopID(
      i18n?.country || 'global',
    ).toUpperCase(),
    discount: Segment.parseSegmentNumber(discount),
    id_customer: user?.id_customer,
    id_shop,
    order_id: parseRefVal(order_id),
    products: parsedProducts,
    revenue,
    shipping,
    total,
    customer_type: customer_type ? 'existing' : 'new',
    coupon: voucher_name,
    id_cart: idCart,
    platform: 'web',
    fb_content: JSON.stringify(fb_content),
    is_retry: !!is_retry,
    concat_product_ids: product_ids.join(','),
  })
}

/**
 * @param { string } prompt_location
 */
export function segmentSignInViewed(prompt_location) {
  segmentTrackAction(EVENT.signInViewed, { prompt_location, id_shop })
}

export function segmentSwatchOverlayViewed({
  categoryId,
  categoryName,
  productId,
  query,
}) {
  const data = {
    id_shop,
    id_product: productId,
    ...(!!categoryId && { id_site_category: categoryId }),
    ...(!!categoryName && { site_category_name: categoryName }),
    ...(!!query && { query }),
  }

  segmentTrackAction(EVENT.swatchOverlayViewed, data)
}

/**
 * @param {{
 *  id_customer: number,
 *  id_order: number,
 *  id_order_return: number,
 *  id_shop?: number,
 * }} options
 */
export function segmentReturnPomeloPickupViewed(options) {
  segmentTrackAction(EVENT.returnPomeloPickupViewed, options)
}

/**
 * @param {{
 *  email_new_arrivals: boolean,
 *  email_promotions: boolean,
 *  push_new_arrivals: boolean,
 *  push_promotions: boolean,
 *  push_order_updates: boolean,
 *  web_push_new_arrivals: boolean,
 *  web_push_promotions: boolean,
 *  id_shop: integer
 * }} options
 */
export function segmentUpdateCommunicationPreferences(options) {
  segmentTrackAction(EVENT.updateCommunicationPreferences, {
    ...options,
    id_shop,
  })
}

/**
 * @param {{
 *  shipping_method_type: string,
 *  id_cart: number,
 *  number_of_saved_addresses?: number,
 *  repeat_pick_up?: boolean,
 * }} options
 */
export function segmentSelectShippingMethod(options) {
  segmentTrackAction(EVENT.selectShippingMethod, { ...options, id_shop })
}

/**
 * @param {{
 *  number_of_saved_addresses: number,
 *  id_cart: number,
 * }} options
 */
export function segmentAddNewAddress(options) {
  segmentTrackAction(EVENT.addNewAddress, { ...options, id_shop })
}

export function segmentSelectShippingAddress(options) {
  segmentTrackAction(EVENT.selectShippingAddress, { ...options, id_shop })
}

export function segmentSelectPaymentMethod(options) {
  segmentTrackAction(EVENT.selectPaymentMethod, { ...options, id_shop })
}

/**
 * @param {{
 *   number_of_saved_credit_cards: number
 *   id_cart: number
 * }} options
 */
export function segmentAddCreditCard(options) {
  segmentTrackAction(EVENT.addCrediCard, { ...options, id_shop })
}

/**
 * @param {{
 *  id_cart: number,
 *  number_of_saved_credit_cards: number
 * }} options
 */
export function segmentAddedCreditCardFailed(options) {
  segmentTrackAction(EVENT.addCrediCardFailed, options)
}

export function segmentSelectCreditCard(options) {
  segmentTrackAction(EVENT.selectCreditCard, { ...options, id_shop })
}

/**
 * @param {{
 *  id_cart: number,
 *  number_of_pick_up_locations: number,
 *  repeat_pick_up: bool,
 * }} options
 */
export function segmentViewListOfPickupLocations(options) {
  segmentTrackAction(EVENT.viewListOfPickupLocations, { ...options, id_shop })
}

/**
 * @param {{
 *  interaction_type: string,
 *  query: string,
 *  id_cart: number,
 * }} options
 */
export function segmentPomeloPickupInteracted(options) {
  segmentTrackAction(EVENT.pomeloPickupInteracted, { ...options, id_shop })
}

export function segmentSelectPickupLocations(options) {
  segmentTrackAction(EVENT.selectPickupLocations, { ...options, id_shop })
}

export function segmentExtendPickupDayClicked(options) {
  segmentTrackAction(EVENT.extendPickupDayClicked, { ...options, id_shop })
}

export function segmentConfirmExtendClicked(options) {
  segmentTrackAction(EVENT.confirmExtendClicked, { ...options, id_shop })
}

/**
 * @param {{
 *  ref_type: string,
 *  ref_id: string,
 * }} options
 */
export function segmentbrandDirectoryViewed(options) {
  segmentTrackAction(EVENT.brandDirectoryViewed, options)
}

/**
 * @param {{
 *  clicked_keyword: string,
 *  isRecentSearchValid: bool,
 * }} options
 */
export function segmentSearchItemClicked(options) {
  const { clicked_keyword, isRecentSearchValid } = options
  const segmentEventName = isRecentSearchValid
    ? EVENT.recentSearchClicked
    : EVENT.popularSearchClicked
  const data = {
    id_shop,
    ...(isRecentSearchValid
      ? { is_recent_keyword_clicked: true }
      : { is_popular_keyword_clicked: true }),
    clicked_keyword,
  }

  segmentTrackAction(segmentEventName, data)
}

/**
 * @param {{
 *  productId: number,
 *  voucherList: array,
 *  isVoucherViewed: bool,
 * }} options
 */
export function segmentVoucherSection(options) {
  const { productId, voucherList, isVoucherViewed } = options
  const user = StateStorage.getAuthUser() || StateStorage.getAnonymousUser()
  const voucher_code = voucherList.map((list) => list.code)
  const segmentEventName = isVoucherViewed
    ? EVENT.voucherViewed
    : EVENT.voucherClicked
  const data = {
    id_customer: user.id_customer,
    id_shop,
    id_product: productId,
    voucher_codes: voucher_code,
  }
  segmentTrackAction(segmentEventName, data)
}

/**
 * @param {string} id_product
 * @param {string} voucher_codes
 */
export function segmentVoucherCopied(id_product, voucher_codes) {
  const user = StateStorage.getAuthUser() || StateStorage.getAnonymousUser()

  const data = {
    id_customer: user.id_customer,
    id_shop,
    id_product,
    voucher_codes,
  }

  segmentTrackAction(EVENT.voucherCopied, data)
}

/**
 * @param {string} oldCookieConsent
 * @param {{
 *  is_performance_allowed: bool,
 *  is_marketing_allowed: bool,
 * }} options
 */
export function segmentCookieNoticeResponded(options, oldCookieConsent) {
  const user = StateStorage.getAuthUser()
  const { is_performance_allowed, is_marketing_allowed } = options
  const data = {
    id_customer: user?.id_customer || 0,
    id_shop,
    is_performance_allowed,
    is_marketing_allowed,
  }

  segmentTrackAction(
    oldCookieConsent ? EVENT.cookieSettingUpdated : EVENT.cookieNoticeResponded,
    data,
  )
  get3rdPartyToUpdatedConsent()
}

/**
 * @param {{
 *  id_product: number,
 *  category: { id_category: number, link_rewrite: string }
 *  metadata: { tracking: { color_name: string } },
 *  }} product
 * @param {{ size: string }} sizeData
 */
export function segmentRecommendedSizeAdded(product, sizeData) {
  const {
    id_product,
    category: { id_category, link_rewrite } = {},
    metadata,
  } = product

  const data = {
    id_product,
    id_shop,
    ...(!!id_category && { id_site_category: id_category }),
    ...(!!link_rewrite && {
      site_category_name: link_rewrite.toLowerCase(),
    }),
    ref_type: 'add_to_cart',
    size: sizeData.size.toLowerCase(),
  }

  if (metadata?.tracking?.color_name) {
    data.color = metadata.tracking.color_name.toLowerCase()
  }

  segmentTrackAction(EVENT.recommendedSizeAdded, data)
}

export function segmentPDPAConsentResponded() {
  const user = StateStorage.getAuthUser()
  const data = {
    id_customer: user?.id_customer || 0,
    id_shop,
  }

  segmentTrackAction(EVENT.pdpaConsentResponded, data)
}

export function segmentReferralTrack(eventName) {
  const user = StateStorage.getAuthUser() || StateStorage.getAnonymousUser()
  const data = {
    id_shop,
    id_customer: user?.id_customer || 0,
  }

  segmentTrackAction(eventName, data)
}

export function segmentReferralViewedTrack(eventName, ref_type) {
  const user = StateStorage.getAuthUser()
  const data = {
    id_shop,
    id_customer: user?.id_customer,
    ref_type,
  }

  segmentTrackAction(eventName, data)
}

export function segmentReferralCodeCopied(eventName, code) {
  const user = StateStorage.getAuthUser() || StateStorage.getAnonymousUser()
  const data = {
    id_shop,
    id_customer: user?.id_customer || 0,
    referral_code: code,
  }

  segmentTrackAction(eventName, data)
}

export function segmentRecentlyViewProduct(ref_type) {
  segmentTrackAction(EVENT.recentlyViewProduct, {
    id_shop,
    ref_type,
  })
}

export function segmentVoucherCheckoutClicked() {
  const user = StateStorage.getAuthUser() || StateStorage.getAnonymousUser()
  const data = {
    id_customer: user?.id_customer,
    id_shop,
  }

  segmentTrackAction(EVENT.voucherCheckoutClicked, data)
}

export function segmentHomeFeedImpression(id_feed) {
  const data = {
    id_shop,
    id_feed,
    impression: 'Skinny Banner',
    feed_position: 0,
  }

  segmentTrackAction(EVENT.homeFeedImpression, data)
}

export function segmentSkinnyBannerClicked(id_feed) {
  const user = StateStorage.getAuthUser() || StateStorage.getAnonymousUser()
  const data = {
    id_customer: user?.id_customer,
    id_shop,
    id_feed,
  }

  segmentTrackAction(EVENT.skinnyBannerClicked, data)
}

export function segmentNewLoyaltyPagesViewed(page, data = null) {
  const eventName =
    page === 'My Loyalty'
      ? EVENT.myLoyaltyPageViewed
      : EVENT.loyaltyLandingPageViewed

  segmentTrackAction(eventName, { ...data })
}

// https://medium.com/@DavideRama/cdf2b61af642
const Segment = {
  cachedFingerprint: '',
  isOnWhiteListEvents,
  fetchBranchCrossPlatformId,
  getBranchCrossPlatformId,
  getBranchFingerPrint,
  logSegment,
  parseSegmentNumber,
  segmentbrandDirectoryViewed,
  segmentIdentifyUser,
  segmentOrderCompleted,
  segmentOrderDetailviewed,
  segmentTrackAction,
  segmentTrackProductAdded,
  segmentTrackLogin,
  segmentTrackSignUp,
}

export default Segment
