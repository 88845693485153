import Router from 'next/router'
import { SEGMENT_STORAGE_KEY_CATEGORY } from 'lib/segment/const'
import StateStorage from 'lib/state-storage'
import { segmentSkinnyBannerClicked } from 'lib/segment'
import { RADIO_TYPES } from './const'

const onBannerClicked = (e, link, radioType, idFeed) => {
  if (e?.ctrlKey || e?.metaKey) {
    return
  }

  e?.preventDefault()
  e?.stopPropagation()

  if (radioType === RADIO_TYPES.category) {
    sessionStorage.setItem(SEGMENT_STORAGE_KEY_CATEGORY.id, '')
    sessionStorage.setItem(SEGMENT_STORAGE_KEY_CATEGORY.position, '')
    sessionStorage.setItem(SEGMENT_STORAGE_KEY_CATEGORY.type, 'skinny_banner')
    sessionStorage.setItem(SEGMENT_STORAGE_KEY_CATEGORY.subPos, '')
  }
  segmentSkinnyBannerClicked(idFeed)

  Router.push(link.href, link.as)
}

// in case apollo send us a full destination_url
// we have to remove the front `/`
// otherwise do nothing
const hrefModify = (href) => {
  const validUrlPattern = /:\/\/www/gi
  let newHref = href
  if (validUrlPattern.test(href)) {
    newHref = href.slice(1)
  }

  return newHref
}

const getBannerLink = (destination_url, id_category, radioType) => {
  const shop = StateStorage.getShop()
  const lang = StateStorage.getLanguage()

  const isCategoryType = radioType === RADIO_TYPES.category
  const hrefLinkData = isCategoryType
    ? `/category?id=${id_category}`
    : `/${destination_url}`
  const asLinkData = isCategoryType
    ? `/${shop}/${lang}/clothes/${destination_url}`
    : undefined

  return {
    href: hrefModify(hrefLinkData),
    as: asLinkData,
  }
}

export { getBannerLink, onBannerClicked }
