import { getPathnameWithoutShopAndLang } from 'lib/utils/url'

export function getDeeplink(pageName, pageId) {
  if (pageName) {
    return `deeplink/${pageName}/${pageId || 0}`
  }
  return null
}

export default function generateBreadcrumbs() {
  if (typeof window !== 'undefined') {
    const path = getPathnameWithoutShopAndLang(window.location.pathname).split(
      '/',
    )
    switch (path.length) {
      case 1: {
        const schema = `{"@context": "https://schema.org","@type": "BreadcrumbList","itemListElement": {"@type": "ListItem","position": 1,"name": "${path[0]}","item": {"id": "https://www.pomelofashion.com/${path[0]}", "type": "WebPage"}}}`
        return schema
      }
      case 2: {
        const schema = `{"@context": "https://schema.org","@type": "BreadcrumbList","itemListElement": [{"@type": "ListItem","position": 1,"name": "${path[0]}","item": {"id": "https://www.pomelofashion.com/${path[0]}", "type": "WebPage"}},{"@type": "ListItem","position": 2,"name": "${path[1]}","item": {"id": "https://www.pomelofashion.com/${path[0]}/${path[1]}", "type": "WebPage"}}]}`
        return schema
      }
      case 3: {
        const schema = `{"@context": "https://schema.org","@type": "BreadcrumbList","itemListElement": [{"@type": "ListItem","position": 1,"name": "${path[0]}","item": {"id": "https://www.pomelofashion.com/${path[0]}", "type": "WebPage"}},{"@type": "ListItem","position": 2,"name": "${path[1]}","item": {"id": "https://www.pomelofashion.com/${path[0]}/${path[1]}", "type": "WebPage"}},{"@type": "ListItem","position": 3,"name": "${path[2]}","item": {"id": "https://www.pomelofashion.com/${path[0]}/${path[1]}/${path[2]}", "type": "WebPage"}}]}`
        return schema
      }
      default:
        return null
    }
  }
  return null
}
