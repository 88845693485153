import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withI18next } from 'lib/i18n/withI18next'
import { hasLocizeTranslation } from 'lib/utils/locize'
import Typo from 'constants/typography'
import Icon from 'components/common/icon'
import ICON, { ICON_SIZE } from 'components/common/icon/const'
import ResponsiveImage from 'components/images/responsive-image'
import { ADS_BANNER_BUTTON, getAdsBannerContent, WEB_TO_APP } from '../const'

const AlertAdsButton = ({ isExpanded, customerData, t }) => {
  const bannerContent = useMemo(
    () => getAdsBannerContent(customerData?.is_returning_customer),
    [customerData?.is_returning_customer],
  )

  if (!isExpanded) {
    return (
      <React.Fragment>
        <Icon
          className="phone-icon"
          size={ICON_SIZE.medium}
          src={ICON.phoneScanQRCode}
        />
        <span className={Typo.button}>
          {hasLocizeTranslation(
            t,
            ADS_BANNER_BUTTON.key,
            ADS_BANNER_BUTTON.text,
          )}
        </span>
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      {bannerContent.map((bannerText) => (
        <span
          key={bannerText.key}
          className={bannerText.isTitle ? Typo.title3 : Typo.body2}
        >
          {hasLocizeTranslation(t, bannerText.key, bannerText.text)}
        </span>
      ))}
      <div className="qr-code__wrapper">
        <ResponsiveImage
          className="qr-code"
          imgRatio={WEB_TO_APP.imgRatio}
          src={WEB_TO_APP.src}
        />
      </div>
    </React.Fragment>
  )
}

AlertAdsButton.propTypes = {
  customerData: PropTypes.shape({
    is_returning_customer: PropTypes.bool,
  }).isRequired,
  isExpanded: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
}

const Extended = withI18next()(AlertAdsButton)

export default connect((state) => ({
  customerData: state.auth.customerData,
}))(Extended)
