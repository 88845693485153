import clsx from 'clsx'
import PropTypes from 'prop-types'
import Typo from 'constants/typography'

const WishListTabPostfix = ({ tabCount }) => (
  <span className={clsx('postfix', Typo.body2)}>({tabCount || '0'})</span>
)

WishListTabPostfix.defaultProps = {
  tabCount: 0,
}

WishListTabPostfix.propTypes = {
  tabCount: PropTypes.number,
}

export default WishListTabPostfix
