import Router from 'next/router'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { useTransition, animated } from 'react-spring'
import { toggleBodyFreezing } from 'lib/utils/common/commonUtils'
import Portal from 'components/portal'
import styles from './styles.scss'

/**
 * @param {Number} param0.backdropOpacity default to 0.5, set to 1 will make a black backdrop.
 * @param {Object} param0.children content that will be shown inside the modal.
 * @param {Boolean} param0.freezeBody if set to true, `toggleBodyFreezing` will be called when modal is opened.
 * @param {Boolean} param0.isOpen use this to control whether the modal is opened or closed.
 * @param {Function} param0.onBackdropClick function to call when the backdrop is clicked on.
 * @param {Function} param0.onRouteChange function to call when the route changes (navigation). Useful when the modal resides high up in the DOM and doesn't get unmounted when page changes.
 * @param {String|Number} param0.zIndex use to override the z-index css. Default is 10.
 */
const BackdropModal = ({
  backdropOpacity,
  children,
  freezeBody,
  isOpen,
  onBackdropClick,
  onRouteChange,
  zIndex,
}) => {
  const transitions = useTransition(isOpen, null, {
    config: { mass: 1, tension: 400, friction: 0, clamp: true },
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  })

  useEffect(() => {
    if (onRouteChange && isOpen) {
      Router.events.on('routeChangeStart', onRouteChange)
    }

    return () => {
      Router.events.off('routeChangeStart', onRouteChange)
    }
  }, [onRouteChange, isOpen])

  useEffect(() => {
    if (isOpen && freezeBody) {
      toggleBodyFreezing(true)
    }

    return () => {
      if (freezeBody) {
        toggleBodyFreezing()
      }
    }
  }, [freezeBody, isOpen])

  return (
    <Portal>
      {transitions.map(
        ({ item, key, props }) =>
          item && (
            <animated.section
              style={zIndex || zIndex === 0 ? { ...props, zIndex } : props}
              className="backdrop-modal__wrapper"
              key={key}
            >
              <style jsx>{styles}</style>
              <div
                aria-label="backdrop"
                data-cy="backdrop-modal"
                className="backdrop-modal__self"
                style={{ backgroundColor: `rgba(0, 0, 0, ${backdropOpacity})` }}
                onClick={onBackdropClick}
                role="presentation"
              />
              {children}
            </animated.section>
          ),
      )}
    </Portal>
  )
}

BackdropModal.defaultProps = {
  backdropOpacity: 0.5,
  onBackdropClick: undefined,
  onRouteChange: undefined,
  freezeBody: true,
  isOpen: false,
  zIndex: 10,
}

BackdropModal.propTypes = {
  backdropOpacity: PropTypes.number,
  children: PropTypes.node.isRequired,
  onBackdropClick: PropTypes.func,
  onRouteChange: PropTypes.func,
  freezeBody: PropTypes.bool,
  isOpen: PropTypes.bool,
  zIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

export default BackdropModal
