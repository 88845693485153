export const getCategoryFilterFromRedux = (filters) => {
  if (!filters) {
    return null
  }

  return filters.find((filter) => filter.id === 'categories')
}

export const isThisCategoryContainPomelo = (filters) => {
  if (!filters?.brands) {
    return false
  }

  return filters.brands.some((brand) => brand.name === 'Pomelo')
}

export const isPomeloBrandFilterValid = (categoryId) => {
  // both flags are eligible so is served all id
  const validCategoryId = [
    2, 14, 18, 36, 37, 88, 264, 332, 458, 1654, 1655, 1656, 1658,
  ]

  return validCategoryId.includes(categoryId)
}

export function getPomeloFilteredStatus(activeFilters) {
  let filterStatus
  if (activeFilters?.brand_filter?.length) {
    const { brand_filter } = activeFilters
    filterStatus = brand_filter.includes('Pomelo')
  }
  return filterStatus
}

export const shouldUsePmloFilterLogic = (categoryFilter, categoryId) => {
  const isContainPomeloBrand = isThisCategoryContainPomelo(categoryFilter)
  const isPomeloFilterValid = isPomeloBrandFilterValid(categoryId)

  return isContainPomeloBrand && isPomeloFilterValid
}
