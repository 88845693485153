import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { withI18next } from 'lib/i18n/withI18next'
import { hasLocizeTranslation } from 'lib/utils/locize'
import Typo from 'constants/typography'
import parseCyKey from 'lib/utils/cypress'

const UspText = ({ footerItem, t }) => (
  <React.Fragment>
    <span
      className={clsx(Typo.subtitle1, 'title')}
      data-cy={parseCyKey(footerItem.subtitle.key, 'key')}
    >
      {hasLocizeTranslation(
        t,
        footerItem.subtitle.key,
        footerItem.subtitle.defaultVal,
      )}
    </span>
    {footerItem?.caption && (
      <span className={Typo.caption}>
        {hasLocizeTranslation(
          t,
          footerItem.caption.key,
          footerItem.caption.defaultVal,
        )}
      </span>
    )}
  </React.Fragment>
)

UspText.propTypes = {
  footerItem: PropTypes.shape({
    caption: PropTypes.shape({
      key: PropTypes.string,
      defaultVal: PropTypes.string,
    }),
    subtitle: PropTypes.shape({
      key: PropTypes.string,
      defaultVal: PropTypes.string,
    }),
  }).isRequired,
  t: PropTypes.func.isRequired,
}

export default withI18next()(UspText)
