import { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import VoucherListModal from 'components/pdp/carousel-images/voucher/voucher-modal/VoucherListModal'
import { getVoucherCheckoutLists } from 'components/pdp/carousel-images/voucher/voucher-checkout/utils'
import VoucherApiHandler from 'lib/api/voucher'
import { customFlagValue } from 'lib/utils/handle-flag/const'

const VoucherCheckout = ({ onClose, applyVoucher, cart }) => {
  const {
    summary: { applied_voucher },
  } = cart || {}
  const [isLoading, setIsLoading] = useState(true)
  const [allVouchersList, setAllVouchersList] = useState([])
  const [appliedVoucher, setAppliedVoucher] = useState(applied_voucher?.code)
  const noVoucherCacheFlagValue = true // 'NoVoucherCache-20221212'
  const voucherCheckoutNewErrorFlagValue = customFlagValue({
    flagName: 'voucherCheckout',
  }) // TODO: force value to handle with flagsmith later (VoucherCheckoutNewError-20230101)

  const setVoucher = (vouchersList) => {
    setAllVouchersList(vouchersList)
  }

  const handleClose = () => {
    if (applied_voucher?.code !== appliedVoucher) {
      applyVoucher({
        type: 'USE_VOUCHER',
        voucherCode: appliedVoucher,
        apply_method: 'voucher_checkout',
      })
    }
    onClose()
  }

  useEffect(() => {
    const getVoucherLists = () => {
      VoucherApiHandler.getVoucher(noVoucherCacheFlagValue)
        .then((res) => {
          getVoucherCheckoutLists(
            res.vouchers,
            cart.items,
            cart.summary.subtotal,
            setVoucher,
            voucherCheckoutNewErrorFlagValue,
          )
          setIsLoading(false)
        })
        .catch(() => {
          setVoucher(null)
          setIsLoading(false)
        })
    }
    getVoucherLists()
  }, [cart, noVoucherCacheFlagValue, voucherCheckoutNewErrorFlagValue])

  return (
    <VoucherListModal
      isLoading={isLoading}
      onClose={handleClose}
      voucherList={allVouchersList}
      onVoucherSelected={(code) => setAppliedVoucher(code)}
      appliedVoucher={appliedVoucher}
      isCheckout
    />
  )
}

VoucherCheckout.propTypes = {
  onClose: PropTypes.func.isRequired,
  applyVoucher: PropTypes.func.isRequired,
  cart: PropTypes.shape({
    summary: PropTypes.shape({
      subtotal: PropTypes.number,
      applied_voucher: PropTypes.shape({
        code: PropTypes.string,
      }),
    }).isRequired,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        id_product: PropTypes.number,
        quantity: PropTypes.number,
      }),
    ).isRequired,
  }).isRequired,
}

export default connect((state) => ({
  cart: state.cart.payload,
}))(VoucherCheckout)
