import { Fragment } from 'react'
import PropTypes from 'prop-types'
import Link from 'next/link'
import { connect } from 'react-redux'
import ContentLoader from 'react-content-loader'
import clsx from 'clsx'
import Icon from 'components/common/icon'
import { ICON_SIZE } from 'components/common/icon/const'
import Typo from 'constants/typography'
import { hasLocizeTranslation } from 'lib/utils/locize'
import useUSPLoyalty from 'components/custom-hooks/useUSPLoyalty'
import { withI18next } from 'lib/i18n/withI18next'
import { parseCyKey } from 'lib/utils/cypress'
import {
  dataCash,
  dataDelivery,
  dataPickup,
  dataNew,
  dataReturn,
  getDataLoyalty,
} from './const'
import styles from './style.scss'

const getHeaderData = (key, usp) => {
  if (usp && key === 'loyalty') {
    return getDataLoyalty(usp)
  }
  switch (key) {
    case 'cash':
      return dataCash
    case 'pickup':
      return dataPickup
    case 'return':
      return dataReturn
    case 'delivery':
      return dataDelivery
    case 'new':
      return dataNew
    default:
      return null
  }
}

const renderLoyaltyTooltipSkeleton = (t, icon, title, type) => (
  <Fragment>
    <style jsx>{styles}</style>
    <Icon src={icon} size={ICON_SIZE.medium} />
    <span className={clsx('usp-caption', Typo.caption)}>
      {hasLocizeTranslation(t, title.key, title.default)}
    </span>
    <div className="usp-tooltip" key={`${type}-tooltip`}>
      <ContentLoader
        // Noted: Support SSR
        uniquekey="header-user-item__content-loader"
        speed={2}
        width={100}
        height={200}
        primaryColor="#f3f3f3"
        secondaryColor="#ecebeb"
      >
        <rect x="4" y="1" rx="3" ry="3" width="50" height="6" />
        <rect x="4" y="12" rx="3" ry="3" width="100" height="6" />
        <rect x="4" y="24" rx="3" ry="3" width="100" height="6" />
        <rect x="4" y="36" rx="3" ry="3" width="100" height="6" />
        <rect x="4" y="48" rx="3" ry="3" width="100" height="6" />
        <rect x="4" y="60" rx="3" ry="3" width="100" height="6" />
        <rect x="4" y="72" rx="3" ry="3" width="100" height="6" />
        <rect x="4" y="84" rx="3" ry="3" width="100" height="6" />
        <rect x="4" y="96" rx="3" ry="3" width="100" height="6" />
        <rect x="4" y="108" rx="3" ry="3" width="100" height="6" />
        <rect x="4" y="120" rx="3" ry="3" width="100" height="6" />
        <rect x="4" y="132" rx="3" ry="3" width="100" height="6" />
        <rect x="4" y="144" rx="3" ry="3" width="100" height="6" />
        <rect x="4" y="156" rx="3" ry="3" width="100" height="6" />
        <rect x="4" y="168" rx="3" ry="3" width="100" height="6" />
        <rect x="4" y="192" rx="3" ry="3" width="50" height="6" />
      </ContentLoader>
    </div>
  </Fragment>
)

const HeaderUspItem = ({ activeType, type, t, internationalization, auth }) => {
  const { country, language } = internationalization
  const { usp, isReady } = useUSPLoyalty({ auth, prefix: 'HEADER' })
  const { icon, title, subTitle, link } = getHeaderData(type, usp)
  const isTooltipAvailable = subTitle && activeType === type

  if (!isReady && type === 'loyalty') {
    return renderLoyaltyTooltipSkeleton(t, icon, title, type)
  }

  return (
    <Fragment>
      <style jsx>{styles}</style>
      <Icon src={icon} size={ICON_SIZE.medium} />
      <span
        className={Typo.caption}
        data-cy={parseCyKey(`header__usp_${title.key}`, 'key')}
      >
        {hasLocizeTranslation(t, title.key, title.default)}
      </span>
      {isTooltipAvailable && (
        <div className="usp-tooltip" key={`${type}-tooltip`}>
          <span
            className={clsx(Typo.caption, 'usp-content')}
            data-cy={parseCyKey(`header__usp_${subTitle.key}`, 'key')}
          >
            {hasLocizeTranslation(t, subTitle.key, subTitle.default)}
          </span>
          {link && (
            <Link href={link.href} as={`/${country}/${language}${link.as}`}>
              <a
                className={clsx(Typo.overline, 'usp__learnmore')}
                data-cy={parseCyKey(`header__usp_${link.keyText}`, 'key')}
              >
                {hasLocizeTranslation(t, link.keyText, link.defaultText)}
              </a>
            </Link>
          )}
        </div>
      )}
    </Fragment>
  )
}

HeaderUspItem.propTypes = {
  internationalization: PropTypes.shape({
    country: PropTypes.string,
    language: PropTypes.string,
  }).isRequired,
  t: PropTypes.func.isRequired,
  activeType: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  auth: PropTypes.shape({
    customerData: PropTypes.shape({
      isFetched: PropTypes.bool.isRequired,
      loyalty_signup: PropTypes.number,
    }),
    user: PropTypes.shape({}),
  }).isRequired,
}

const Extended = withI18next()(HeaderUspItem)

export default connect((state) => ({
  auth: state.auth,
  internationalization: state.internationalization,
}))(Extended)
