/**
 * Some info.
 *
 * API doesn't return the key for secondary languages if the value is blank.
 *
 * But the Locize lib that we're using will then fallback to value from the ref language instead.
 *
 * So in the case that we want to prevent fallbacking to ref language, we'll have to set the value
 * for the secondary languages in Locize, to be something that's not blank. eg. an empty space
 *
 * @param {function} t
 * @param {string} key default: ''
 * @param {string} fallback default: ''
 */
export function hasLocizeTranslation(t, key = '', fallback = '') {
  const translated = t(key)

  if (translated === key) {
    return fallback
  }

  return translated
}

export function locizeTranslation(t, object) {
  return Object.keys(object).map((key) => {
    const translated = t(key)

    // return fallback translation
    if (translated === key) {
      return object[key]
    }

    return translated
  })
}
