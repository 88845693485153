import { SEGMENT_STORAGE_KEY_CATEGORY } from 'lib/segment/const'
import { CATEGORORY_DATA } from './const'

const getCategoryData = (isPhone) =>
  isPhone ? CATEGORORY_DATA.slice(0, 3) : CATEGORORY_DATA

const setSegmentCategoryKeyData = (showSearch, refType) => {
  sessionStorage.setItem(
    SEGMENT_STORAGE_KEY_CATEGORY.id,
    showSearch ? '' : 'no_results',
  )
  sessionStorage.setItem(SEGMENT_STORAGE_KEY_CATEGORY.position, '')
  sessionStorage.setItem(
    SEGMENT_STORAGE_KEY_CATEGORY.type,
    refType || (showSearch ? 'error_page' : 'search'),
  )
  sessionStorage.setItem(SEGMENT_STORAGE_KEY_CATEGORY.subPos, '')
}

export { getCategoryData, setSegmentCategoryKeyData }
