import React from 'react'
import clsx from 'clsx'
import Typo from 'constants/typography'
import { withI18next } from 'lib/i18n/withI18next'
import style from './loyalty-sign-up.scss'
import AuthLoyaltySignUpForm from './loyalty-sign-up-form/AuthLoyaltySignUpForm'

const AuthLoyaltySignUp = () => (
  <React.Fragment>
    <style jsx>{style}</style>
    <span
      className={clsx(Typo.h4)}
      style={{
        paddingLeft: '7px',
      }}
    >
      Pomelo.
    </span>
    <span className={clsx(Typo.h1, 'auth-loyalty-sign-up__perks-title')}>
      PERKS
    </span>
    <div
      style={{
        margin: '0px 8px',
      }}
    >
      <div className={clsx(Typo.body2, 'auth-loyalty-sign-up__desc')}>
        {/* {hasLocizeTranslation(
          t,
          'LOYALTY_SIGNUP_DESC',
          ' Become a member to earn from every purchase and get more privileges.',
        )} */}
        Become a member to earn from every purchase and get more privileges.
      </div>
      <AuthLoyaltySignUpForm isRegister />
    </div>
  </React.Fragment>
)

export default withI18next()(AuthLoyaltySignUp)
