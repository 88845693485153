import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import useIntersection from 'components/custom-hooks/useIntersection'

const PageTrackWrapper = ({
  additionalPage,
  children,
  className,
  onProductPageChanged,
}) => {
  const wrapperEl = useRef(null)
  const hasIntersected = useRef(false)
  const isIntersecting = useIntersection(wrapperEl, {
    once: false,
    threshold: 0.8,
  })

  useEffect(() => {
    if (isIntersecting) {
      onProductPageChanged(additionalPage)
      hasIntersected.current = true
    } else if (
      hasIntersected.current &&
      wrapperEl.current?.getBoundingClientRect
    ) {
      const elClientRect = wrapperEl.current.getBoundingClientRect()

      // top value here will be minus, if the element is above the viewport.
      // We only set the page to be previous one when the element is going below the viewport.
      if (elClientRect?.top > 0 && additionalPage > 0) {
        onProductPageChanged(additionalPage - 1)
      }
    }
  }, [isIntersecting, onProductPageChanged, additionalPage])

  return (
    <div className={className} ref={wrapperEl}>
      {children}
    </div>
  )
}

PageTrackWrapper.defaultProps = {
  children: null,
  className: '',
}

PageTrackWrapper.propTypes = {
  additionalPage: PropTypes.number.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
    PropTypes.number,
  ]),
  className: PropTypes.string,
  onProductPageChanged: PropTypes.func.isRequired,
}

export default PageTrackWrapper
