import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { translate } from 'react-i18next'
import { hasLocizeTranslation } from 'lib/utils/locize'
import ExpandButton from 'components/common/button/expand'
import Icon from 'components/common/icon'
import ICONS from 'components/common/icon/const'
import HoverInteractionsAddToBag from './add-to-bag'

const ProductItemHoverInteractions = ({
  goToSimilarProducts,
  options,
  productId,
  refType,
  showAddToBag,
  showSeeSimilar,
  t,
}) => {
  const wrapperRef = useRef()

  // To resolve issue with animation triggers on page load
  useEffect(() => {
    if (wrapperRef?.current) {
      wrapperRef.current.classList?.add('not-ready')

      const requestId = requestAnimationFrame(() =>
        wrapperRef?.current?.classList?.remove('not-ready'),
      )

      return () => cancelAnimationFrame(requestId)
    }
    return undefined
  }, [wrapperRef])

  return (
    <div className="product-hover-interactions" ref={wrapperRef}>
      {showSeeSimilar && (
        <div className="product-hover-interactions__see-similar">
          <ExpandButton className="similar-cta" onClick={goToSimilarProducts}>
            <Icon size={20} src={ICONS.similarProduct} />
            {hasLocizeTranslation(t, 'CATEGORY_SIMILAR_CTA', 'see similar')}
          </ExpandButton>
        </div>
      )}
      {showAddToBag && options?.length > 0 && (
        <HoverInteractionsAddToBag
          options={options}
          productId={productId}
          refData={{ ref_type: refType }}
        />
      )}
    </div>
  )
}

ProductItemHoverInteractions.defaultProps = {
  options: null,
  refType: null,
  showAddToBag: false,
  showSeeSimilar: false,
}

ProductItemHoverInteractions.propTypes = {
  goToSimilarProducts: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({})),
  productId: PropTypes.number.isRequired,
  refType: PropTypes.string,
  showAddToBag: PropTypes.bool,
  showSeeSimilar: PropTypes.bool,
  t: PropTypes.func.isRequired,
}

const Extended = translate('global')(ProductItemHoverInteractions)

export { ProductItemHoverInteractions }

export default Extended
