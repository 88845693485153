import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { withI18next } from 'lib/i18n/withI18next'
import { hasLocizeTranslation } from 'lib/utils/locize'
import Typo from 'constants/typography'
import StateStorage from 'lib/state-storage'
import {
  COOKIE_AND_PRIVACY,
  linkToPnC,
} from 'components/alert/alert-cookie/const'
import { AUTH_PDPA_CONTENT, TERMS_AND_CONDITIONS } from './const'
import styles from './styles.scss'

const AuthPDPAContent = ({ is3rdParty, t }) => {
  const as = `/${StateStorage.getShop()}/${StateStorage.getLanguage()}`
  const termsAndConditions = `${as}${TERMS_AND_CONDITIONS.link}`
  const pdpaContent = hasLocizeTranslation(
    t,
    AUTH_PDPA_CONTENT.key,
    AUTH_PDPA_CONTENT.text,
  )
    .replace(
      '%tnc%',
      `<a class="pdpa__link" href=${termsAndConditions}>${hasLocizeTranslation(
        t,
        TERMS_AND_CONDITIONS.key,
        TERMS_AND_CONDITIONS.text,
      )}</a>`,
    )
    .replace(
      '%pnc%',
      `<a class="pdpa__link" href=${linkToPnC}>${hasLocizeTranslation(
        t,
        COOKIE_AND_PRIVACY.key,
        COOKIE_AND_PRIVACY.text,
      )}</a>`,
    )

  return (
    <React.Fragment>
      <style jsx>{styles}</style>
      <div
        className={clsx('pdpa__content', Typo.caption, {
          'align-text': !is3rdParty,
        })}
      >
        <div
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: pdpaContent,
          }}
        />
      </div>
    </React.Fragment>
  )
}

AuthPDPAContent.defaultProps = {
  is3rdParty: false,
}

AuthPDPAContent.propTypes = {
  is3rdParty: PropTypes.bool,
  t: PropTypes.func.isRequired,
}

export default withI18next()(AuthPDPAContent)
