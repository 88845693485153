import { useState, useEffect } from 'react'
import env from 'config/env'
import StateStorage from 'lib/state-storage'
import ShopLanguageMap from 'lib/i18n/shop-language-map'
// eslint-disable-next-line import/no-cycle
import fetchFlagsFromFlagsmith from 'lib/api/flagsmith'

const getFlagKey = () => {
  const lang = StateStorage.getLanguage()
  const shop = StateStorage.getShop()
  return `Flagsmith-${shop}-${lang}`
}

const getFlagValueWithCache = () => StateStorage.getLocalState(getFlagKey())
const saveFlagValues = (flagValues) => {
  StateStorage.saveLocalState(getFlagKey(), JSON.stringify(flagValues), 900)
}

const hasCacheValue = async () => {
  const cacheFlags = await getFlagValueWithCache()

  if (
    typeof cacheFlags !== 'undefined' ||
    ![null, undefined].includes(cacheFlags)
  ) {
    return cacheFlags
  }

  return null
}

/**
 * @param {string} segmentAnonymousID The anonymous ID for the user
 * @returns {Object} The user traits object with info like:
 *  - id_anonymous: The anonymous ID
 *  - id_shop: The shop ID
 *  - id_lang: The language ID (if available)
 *  - id_customer: The customer ID (if logged in)
 *  - email: The email (if logged in)
 *  - firstname: The first name (if logged in)
 *  - lastname: The last name (if logged in)
 *  - is_guest: Whether the user is logged in or not
 *  - platform: The platform (web)
 */
const getTraitsUser = (segmentAnonymousID) => {
  const user = StateStorage.getAuthUser()
  const shop = StateStorage.getShop()
  const lang = StateStorage.getLanguage()
  const id_shop = ShopLanguageMap.getIdShopBySlug(shop)
  const id_lang = ShopLanguageMap.getIdLangBySlugAndLang(shop, lang)

  return {
    id_anonymous: segmentAnonymousID,
    current_app_version: env.VERSION,
    id_shop,
    ...(id_lang && id_lang),
    ...(!!user && {
      id_customer: user.id_customer,
      email: user.email,
      firstname: user.firstname,
      lastname: user.lastname,
    }),
    is_guest: !user,
    platform: 'web',
  }
}

const getIdentity = () => {
  const segmentAnonymousID = StateStorage.getSegmentAnonymousId()
  const traitUser = getTraitsUser(segmentAnonymousID)

  return {
    identifier: segmentAnonymousID,
    traits: Object.keys(traitUser).map((item) => ({
      trait_key: item,
      trait_value: traitUser[item],
    })),
  }
}

/**
 * @param {Object[]} allFlags The list of all Flagsmith flags
 * @param {string} flagExpected The name of the flag to check for
 * @returns {Object|undefined} The flag object if found, otherwise undefined
 */
const flagFounded = (allFlags, flagExpected) =>
  allFlags?.find((list) => list.feature.name === flagExpected)

/**
 * @param {string} flagName
 * @param {any} defaultValue
 * @returns {any}
 */
export const fetchAndStoredNewFlagValue = async (flagName, defaultValue) => {
  const features = await fetchFlagsFromFlagsmith({
    body: getIdentity(),
    isInitFlagsmith: false,
  })

  if (features?.flags?.length) {
    const feature = flagFounded(features?.flags, flagName)
    saveFlagValues(features.flags)
    return feature ? feature.feature_state_value : defaultValue
  }

  return defaultValue
}

/**
 * @param {string} flagName
 * @param {any} defaultValue
 * @returns {any}
 */
export const getFlagValue = async (flagName, defaultValue) => {
  const cacheFlags = await hasCacheValue()

  if (cacheFlags) {
    const features = JSON.parse(cacheFlags)
    const feature = flagFounded(features, flagName)
    return feature ? feature.feature_state_value : defaultValue
  }

  const flagValue = await fetchAndStoredNewFlagValue(flagName)
  return flagValue
}

/**
 * @param {string} flagName
 * @param {any} defaultValue
 * @returns {any}
 */
export const useFlagsmithGetFlagValue = (flagName, defaultValue) => {
  const [flagValue, setFlagValue] = useState(defaultValue)

  useEffect(() => {
    getFlagValue(flagName, defaultValue)
      .then((res) => {
        setFlagValue(res)
      })
      .catch(() => {
        setFlagValue(defaultValue)
      })
  }, [defaultValue, flagName])

  return flagValue
}

/**
 * @param {string} userAgent
 * @returns {boolean}
 */
export const isBot = (userAgent) => {
  const bots = [
    /geedobot/i,
    /googlebot/i,
    /adsbot/i,
    /facebot/i,
    /twitterbot/i,
    /applebot/i,
    /bingbot/i,
    /scaninfo@paloaltonetworks.com/i,
    /crawler@brightedge.com/i,
  ]

  return bots.some((bot) => bot.test(userAgent))
}

const shouldInitialFlagsmith = async () => {
  const cacheFlags = await hasCacheValue()

  return !isBot(window?.navigator?.userAgent) && !cacheFlags
}

const initFlagSmith = async () => {
  if (await shouldInitialFlagsmith()) {
    const initialState = await fetchFlagsFromFlagsmith({
      body: getIdentity(),
      isInitFlagsmith: true,
    })

    if (initialState?.flags?.length) {
      saveFlagValues(initialState.flags)
    }
  }
}

export default initFlagSmith
