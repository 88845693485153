import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import Typo from 'constants/typography'
import { AUTH_BLOCK_USER_TYPE } from 'components/auth/const'
import { locizeTranslation } from 'lib/utils/locize'
import { withI18next } from 'lib/i18n/withI18next'
import { useAuth } from '../context'
import blockedUserLocizes from './const'

const AuthBlockedUser = ({ t }) => {
  const [info1, info2, info3, infoBlockResetPassword, infoTryAgain] =
    locizeTranslation(t, blockedUserLocizes)
  const [authState] = useAuth()
  const { dataPhone, dataEmail, unblockTime, blockType } = authState

  const getRemainingBlockTime = () => {
    const blockingTime = new Date(unblockTime * 1000)
    const currentTime = new Date()
    const remainingTime = blockingTime - currentTime
    return remainingTime > 0 ? Math.ceil(remainingTime / 1000 / 60) : 10
  }

  const generateAuthBlockMessage = () => {
    switch (blockType) {
      case AUTH_BLOCK_USER_TYPE.RESET_PASSWORD:
        return {
          title: t('Reset Password'),
          info1: infoBlockResetPassword,
          info2: infoTryAgain,
        }
      case AUTH_BLOCK_USER_TYPE.LOGIN:
      default: {
        const titlePhone = dataPhone && t('phone log in')
        const titleEmail = dataEmail && t('email log in')

        return {
          title: titlePhone || titleEmail || t('Log In'),
          info1: `${info1} ${info2}`,
          info2: unblockTime
            ? `
                ${info3.split(/%d/)[0]}
                ${getRemainingBlockTime()}
                ${info3.split(/%d/)[1]}
              `
            : infoTryAgain,
        }
      }
    }
  }

  const authBlockMessage = generateAuthBlockMessage()
  return (
    <React.Fragment>
      <span
        className={clsx(Typo.h4, 'auth__title')}
        data-cy="auth__lock__title"
      >
        {authBlockMessage.title}
      </span>
      <p className={Typo.body2} data-cy="auth__lock__info">
        {authBlockMessage.info1.split(/%account%/)[0]}
        <span className={Typo.subtitle2} data-cy="auth__lock__account__info">
          {dataPhone || dataEmail}
        </span>
        {authBlockMessage.info1.split(/%account%/)[1]}
      </p>
      <p className={Typo.body2}>{authBlockMessage.info2}</p>
    </React.Fragment>
  )
}

AuthBlockedUser.propTypes = {
  t: PropTypes.func.isRequired,
}

export default withI18next()(AuthBlockedUser)
