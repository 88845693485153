import React, { useState } from 'react'
import PropType from 'prop-types'
import { useForm } from 'react-hook-form'
import clsx from 'clsx'
import { withI18next } from 'lib/i18n/withI18next'
import Typo from 'constants/typography'
import { hasLocizeTranslation } from 'lib/utils/locize'
import TextField, { EMAIL_REGEX } from 'components/common/textfield'
import { BUTTON_HTML_TYPE, BUTTON_TYPES } from 'constants/button'
import Button from 'components/common/button'
import { getEmailProviderListFireBase } from 'lib/auth'
import { useAuth } from '../context'
import AuthPDPAContent from '../pdpa'
import style from './style.scss'
import { AUTH_ERROR, AUTH_MESSAGE, AUTH_UI_STATE } from '../const'

const INPUT_NAME_EMAIL = 'email'
const INPUT_NAME_FIRST = 'firstName'
const INPUT_NAME_LAST = 'lastName'

const AuthUserInfo = ({ t }) => {
  const [isLoading, setIsLoading] = useState(false)
  const { errors, formState, handleSubmit, register } = useForm({
    mode: 'onChange',
  })
  const [authState, updateAuthState] = useAuth()
  const { isValid } = formState
  const { dataPhone } = authState

  const handleFormSubmit = async (values, e) => {
    if (e?.preventDefault) {
      e.preventDefault()
    }

    updateAuthState({ overlayLoading: true })
    setIsLoading(true)
    const { email, firstName, lastName } = values

    try {
      const emailProviderList = await getEmailProviderListFireBase(email)

      if (emailProviderList?.length) {
        updateAuthState({
          dataEmail: email,
          dataFirstName: firstName,
          dataLastName: lastName,
          overlayLoading: false,
          uiState: AUTH_UI_STATE.merge,
        })
      }
    } catch (err) {
      if (err.code === AUTH_ERROR.invalidPhone) {
        updateAuthState({
          overlayLoading: false,
          snackBar: t(AUTH_MESSAGE.invalidPhone),
          uiState: AUTH_UI_STATE.logIn,
        })
      } else {
        updateAuthState({ overlayLoading: false, snackBar: err })
        setIsLoading(false)
      }
    }
  }

  return (
    <React.Fragment>
      <style jsx>{style}</style>
      <span className={clsx(Typo.h4, 'auth__title')}>{t('phone log in')}</span>
      <span className={clsx(Typo.body2, 'auth__desc')}>
        <div className={Typo.subtitle2}>{dataPhone}</div>
      </span>
      <form
        className="auth__user-info"
        onSubmit={handleSubmit(handleFormSubmit)}
        noValidate
      >
        <TextField
          error={!!errors[INPUT_NAME_EMAIL]}
          fullWidth
          helperText={errors[INPUT_NAME_EMAIL]?.message}
          inputRef={register({
            required: t('Required'),
            pattern: {
              value: EMAIL_REGEX,
              message: hasLocizeTranslation(
                t,
                'FORM_INVALID_EMAIL_FORMAT',
                'Invalid email format.',
              ),
            },
          })}
          label={t('Email')}
          name={INPUT_NAME_EMAIL}
          type="email"
        />
        <TextField
          error={!!errors[INPUT_NAME_FIRST]}
          fullWidth
          helperText={errors[INPUT_NAME_FIRST]?.message}
          inputRef={register({
            required: t('Required'),
          })}
          label={t('First Name')}
          name={INPUT_NAME_FIRST}
          type="text"
        />
        <TextField
          error={!!errors[INPUT_NAME_LAST]}
          fullWidth
          helperText={errors[INPUT_NAME_LAST]?.message}
          inputRef={register({
            required: t('Required'),
          })}
          label={t('Last Name')}
          name={INPUT_NAME_LAST}
          type="text"
        />
        <AuthPDPAContent />
        <Button
          className="auth__standalone-cta full-width submit"
          disabled={!isValid || isLoading}
          htmlType={BUTTON_HTML_TYPE.submit}
          type={BUTTON_TYPES.primary}
        >
          {hasLocizeTranslation(
            t,
            'AUTH_AGREE_PDPA_BUTTON',
            'Agree & Create Account',
          )}
        </Button>
      </form>
    </React.Fragment>
  )
}

AuthUserInfo.propTypes = {
  t: PropType.func.isRequired,
}

export default withI18next()(AuthUserInfo)
