/* eslint-disable no-param-reassign */
// all source code are from
// https://github.com/willmcpo/body-scroll-lock#readme

let hasPassiveEvents = false

if (typeof window !== 'undefined') {
  const passiveTestOptions = {
    get passive() {
      hasPassiveEvents = true
      return undefined
    },
  }
  window.addEventListener('testPassive', null, passiveTestOptions)
  window.removeEventListener('testPassive', null, passiveTestOptions)
}

const isIosDevice =
  typeof window !== 'undefined' &&
  window.navigator &&
  window.navigator.platform &&
  /iP(ad|hone|od)/.test(window.navigator.platform)

let locks = []
let documentListenerAdded = false
let initialClientY = -1
let previousBodyOverflowSetting
let previousBodyPaddingRight

// returns true if `el` should be allowed to receive touchmove events
/* istanbul ignore next */
const allowTouchMove = (el) =>
  locks.some((lock) => {
    if (lock.options.allowTouchMove && lock.options.allowTouchMove(el)) {
      return true
    }

    return false
  })

/* istanbul ignore next */
const preventDefault = (rawEvent) => {
  const e = rawEvent || window.event

  // For the case whereby consumers adds a touchmove event listener to document.
  // Recall that we do document.addEventListener('touchmove', preventDefault, { passive: false })
  // in disableBodyScroll - so if we provide this opportunity to allowTouchMove, then
  // the touchmove event on document will break.
  if (allowTouchMove(e.target)) {
    return true
  }

  // Do not prevent if the event has more than one touch (usually meaning this is a multi touch gesture like pinch to zoom)
  if (e.touches.length > 1) return true

  if (e.preventDefault) e.preventDefault()

  return false
}

const setOverflowHidden = (options) => {
  // Setting overflow on body/documentElement synchronously in Desktop Safari slows down
  // the responsiveness for some reason. Setting within a setTimeout fixes this.
  setTimeout(() => {
    // If previousBodyPaddingRight is already set, don't set it again.
    if (previousBodyPaddingRight === undefined) {
      const reserveScrollBarGap =
        !!options && options.reserveScrollBarGap === true
      const scrollBarGap =
        window.innerWidth - document.documentElement.clientWidth

      if (reserveScrollBarGap && scrollBarGap > 0) {
        previousBodyPaddingRight = document.body.style.paddingRight
        document.body.style.paddingRight = `${scrollBarGap}px`
      }
    }

    // If previousBodyOverflowSetting is already set, don't set it again.
    if (previousBodyOverflowSetting === undefined) {
      previousBodyOverflowSetting = document.body.style.overflow
      document.body.style.overflow = 'hidden'
    }
  })
}

const restoreOverflowSetting = () => {
  // Setting overflow on body/documentElement synchronously in Desktop Safari slows down
  // the responsiveness for some reason. Setting within a setTimeout fixes this.
  setTimeout(() => {
    if (previousBodyPaddingRight !== undefined) {
      document.body.style.paddingRight = previousBodyPaddingRight

      // Restore previousBodyPaddingRight to undefined so setOverflowHidden knows it
      // can be set again.
      previousBodyPaddingRight = undefined
    }

    if (previousBodyOverflowSetting !== undefined) {
      document.body.style.overflow = previousBodyOverflowSetting

      // Restore previousBodyOverflowSetting to undefined
      // so setOverflowHidden knows it can be set again.
      previousBodyOverflowSetting = undefined
    }
  })
}

// https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollHeight#Problems_and_solutions
/* istanbul ignore next */
const isTargetElementTotallyScrolled = (targetElement) =>
  targetElement
    ? targetElement.scrollHeight - targetElement.scrollTop <=
      targetElement.clientHeight
    : false

/* istanbul ignore next */
const handleScroll = (event, targetElement) => {
  const clientY = event.targetTouches[0].clientY - initialClientY

  if (allowTouchMove(event.target)) {
    return false
  }

  if (targetElement && targetElement.scrollTop === 0 && clientY > 0) {
    // element is at the top of its scroll
    return preventDefault(event)
  }

  if (isTargetElementTotallyScrolled(targetElement) && clientY < 0) {
    // element is at the top of its scroll
    return preventDefault(event)
  }

  event.stopPropagation()
  return true
}

export const disableBodyScroll = (targetElement, options) => {
  /* istanbul ignore next */
  if (isIosDevice) {
    // targetElement must be provided, and disableBodyScroll must not have been
    // called on this targetElement before.
    if (!targetElement) {
      // eslint-disable-next-line no-console
      console.error(
        'disableBodyScroll unsuccessful - targetElement must be provided when calling disableBodyScroll on IOS devices.',
      )
      return
    }

    if (
      targetElement &&
      !locks.some((lock) => lock.targetElement === targetElement)
    ) {
      const lock = {
        targetElement,
        options: options || {},
      }

      locks = [...locks, lock]

      targetElement.ontouchstart = (event) => {
        if (event.targetTouches.length === 1) {
          // detect single touch
          initialClientY = event.targetTouches[0].clientY
        }
      }
      targetElement.ontouchmove = (event) => {
        if (event.targetTouches.length === 1) {
          // detect single touch
          handleScroll(event, targetElement)
        }
      }

      if (!documentListenerAdded) {
        document.addEventListener(
          'touchmove',
          preventDefault,
          hasPassiveEvents ? { passive: false } : undefined,
        )
        documentListenerAdded = true
      }
    }
  } else {
    setOverflowHidden(options)
    const lock = {
      targetElement,
      options: options || {},
    }

    locks = [...locks, lock]
  }
}

export const clearAllBodyScrollLocks = () => {
  /* istanbul ignore next */
  if (isIosDevice) {
    // Clear all locks ontouchstart/ontouchmove handlers, and the references
    locks.forEach((lock) => {
      lock.targetElement.ontouchstart = null
      lock.targetElement.ontouchmove = null
    })

    if (documentListenerAdded) {
      document.removeEventListener(
        'touchmove',
        preventDefault,
        hasPassiveEvents ? { passive: false } : undefined,
      )
      documentListenerAdded = false
    }

    locks = []

    // Reset initial clientY
    initialClientY = -1
  } else {
    restoreOverflowSetting()
    locks = []
  }
}

export const enableBodyScroll = (targetElement) => {
  /* istanbul ignore next */
  if (isIosDevice) {
    if (!targetElement) {
      // eslint-disable-next-line no-console
      console.error(
        'enableBodyScroll unsuccessful - targetElement must be provided when calling enableBodyScroll on IOS devices.',
      )
      return
    }

    targetElement.ontouchstart = null
    targetElement.ontouchmove = null

    locks = locks.filter((lock) => lock.targetElement !== targetElement)

    if (documentListenerAdded && locks.length === 0) {
      document.removeEventListener(
        'touchmove',
        preventDefault,
        hasPassiveEvents ? { passive: false } : undefined,
      )

      documentListenerAdded = false
    }
  } else if (locks.length === 1 && locks[0].targetElement === targetElement) {
    restoreOverflowSetting()

    locks = []
  } else {
    locks = locks.filter((lock) => lock.targetElement !== targetElement)
  }
}

function isBrowserHeightHigher(el, cushionHeight = 0) {
  // Not really sure what does cushionHeight do, it's from the old code.
  return window.innerHeight > el.clientHeight + cushionHeight
}

function calculateHeightCap(el) {
  const bottomPadding = 20

  return (
    Math.abs(el.clientHeight - window.innerHeight) * -1 - bottomPadding || 0
  )
}

function setElementTop(el, value) {
  el.style.top = `${value}px`
}

export function calibrateOnResize(el, defaultTop) {
  if (!el) {
    return
  }

  if (isBrowserHeightHigher(el)) {
    setElementTop(el, defaultTop)
  }
}

export function calibrateOnScroll(el, defaultTop) {
  if (!el) {
    return
  }

  if (!isBrowserHeightHigher(el)) {
    const currentTop = +(el.style?.top?.split('px')[0] || 0)
    const lastOffset = +(el.getAttribute('data-offset') || 0)
    const { pageYOffset } = window

    // scroll up
    if (pageYOffset < lastOffset) {
      const scrollDiff = lastOffset - pageYOffset
      const top = currentTop + scrollDiff

      if (top < 0) {
        setElementTop(el, top)
      } else {
        setElementTop(el, defaultTop)
      }
    } else {
      // scroll down
      const scrollDiff = pageYOffset - lastOffset
      const heightCap = calculateHeightCap(el)

      if (currentTop > heightCap) {
        const top = currentTop - scrollDiff

        setElementTop(el, top > heightCap ? top : heightCap)
      } else {
        const top = currentTop + scrollDiff

        setElementTop(el, top < heightCap ? top : heightCap)
      }
    }

    el.setAttribute('data-offset', pageYOffset)
  } else {
    setElementTop(el, defaultTop)
  }
}
