import clsx from 'clsx'
import PropTypes from 'prop-types'
import React from 'react'
import Button from 'components/common/button'
import Icon from 'components/common/icon'
import ICONS from 'components/common/icon/const'
import Typo from 'constants/typography'
import { withI18next } from 'lib/i18n/withI18next'
import { hasLocizeTranslation } from 'lib/utils/locize'
import { cardCreditsMsg } from '../const'

const CartCreditBody = ({
  t,
  isFromCheckoutSummary,
  isDisableApplyCTA,
  isApplied,
  available_formatted,
  to_apply_formatted,
  remaining_formatted,
  openModal,
  handleStoreCredit,
  resetStoreCredit,
}) => {
  const translatedUsable = isApplied
    ? cardCreditsMsg.creditApplied.useMsg
    : cardCreditsMsg.creditWaitForApply.useMsg
  const translateTotal = isApplied
    ? cardCreditsMsg.creditApplied.creditNumMsg
    : cardCreditsMsg.creditWaitForApply.creditNumMsg
  const totalText = isApplied ? remaining_formatted : available_formatted

  const renderCta = () =>
    isApplied ? (
      <Icon
        alt="cart discount close button"
        className="cart-discount__remove"
        onClick={resetStoreCredit}
        src={ICONS.close}
      />
    ) : (
      <Button
        className={clsx('cart-discount__cta', 'is-credit', {
          'is-checkout-summary': isFromCheckoutSummary,
        })}
        onClick={handleStoreCredit}
        disabled={isDisableApplyCTA}
      >
        {t('Apply')}
      </Button>
    )

  return (
    <div
      className={clsx('cart-discount__card', {
        'not-apply-credit': !isApplied,
      })}
    >
      <div
        className={clsx('cart-discount__credit', {
          [Typo.price1]: isApplied,
        })}
      >
        <div
          className={clsx(Typo.price1, 'cart-discount__credit__label', {
            'cart-discount__credit__label__disable': isDisableApplyCTA,
          })}
        >
          <span>
            {hasLocizeTranslation(
              t,
              translatedUsable.key,
              translatedUsable.value,
            ).replace('%s', to_apply_formatted)}
          </span>
          <Icon
            className="cart-discount__icon"
            src={ICONS.info}
            onClick={openModal}
          />
        </div>
        <div className={clsx(Typo.caption, 'cart-discount__total')}>
          {hasLocizeTranslation(t, translateTotal.key, translateTotal.value)}
          {totalText}
        </div>
      </div>
      {renderCta()}
    </div>
  )
}

CartCreditBody.defaultProps = {
  isDisableApplyCTA: false,
}

CartCreditBody.propTypes = {
  t: PropTypes.func.isRequired,
  isFromCheckoutSummary: PropTypes.bool.isRequired,
  isDisableApplyCTA: PropTypes.bool,
  isApplied: PropTypes.bool.isRequired,
  available_formatted: PropTypes.string.isRequired,
  to_apply_formatted: PropTypes.string.isRequired,
  remaining_formatted: PropTypes.string.isRequired,
  openModal: PropTypes.func.isRequired,
  handleStoreCredit: PropTypes.func.isRequired,
  resetStoreCredit: PropTypes.func.isRequired,
}

export default withI18next()(CartCreditBody)
