import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { withI18next } from 'lib/i18n/withI18next'
import SizeSelection from './size-selection'
import QuantitySelection from './quantity-selection'
import styles from './styles.scss'
import { isFreeGiftProduct } from './utils'

const SizeAndQuantitySelection = ({
  allowProductUpdate,
  handleChangeQuantity,
  handleChangeSize,
  handleClickSize,
  isShoppingBagNewDesign,
  isSoldOut,
  product,
  selectedAttribute,
  shouldShowOption,
}) => (
  <div
    className={clsx('size-quantity', {
      'new-design': isShoppingBagNewDesign,
    })}
  >
    <style jsx>{styles}</style>
    <SizeSelection
      allowProductUpdate={allowProductUpdate}
      handleChangeSize={handleChangeSize}
      handleClickSize={handleClickSize}
      isShoppingBagNewDesign={isShoppingBagNewDesign}
      isSoldOut={isSoldOut}
      product={product}
      selectedAttribute={selectedAttribute}
      shouldShowOption={shouldShowOption}
    />
    {!isFreeGiftProduct(product) && (
      <QuantitySelection
        allowProductUpdate={allowProductUpdate}
        handleChangeQuantity={handleChangeQuantity}
        isSoldOut={isSoldOut}
        product={product}
        selectedAttribute={selectedAttribute}
        shouldShowOption={shouldShowOption}
      />
    )}
  </div>
)

SizeAndQuantitySelection.defaultProps = {
  allowProductUpdate: false,
  handleChangeSize: null,
  handleClickSize: null,
  isShoppingBagNewDesign: false,
  isSoldOut: false,
  product: null,
  selectedAttribute: null,
  shouldShowOption: false,
}

SizeAndQuantitySelection.propTypes = {
  allowProductUpdate: PropTypes.bool,
  handleChangeQuantity: PropTypes.func.isRequired,
  handleChangeSize: PropTypes.func,
  handleClickSize: PropTypes.func,
  isShoppingBagNewDesign: PropTypes.bool,
  isSoldOut: PropTypes.bool,
  product: PropTypes.shape({
    type: PropTypes.string,
  }),
  selectedAttribute: PropTypes.shape({ size: PropTypes.string }),
  shouldShowOption: PropTypes.bool,
}

export default withI18next()(SizeAndQuantitySelection)
