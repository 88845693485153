import { SHOP_LANG_CURRENCY } from 'constants/shop-language'
import StateStorage from '../state-storage'
import { request } from './index'

class Exchange {
  static get() {
    return request({
      url: 'v6/exchange-rates',
    })
  }

  static syncConvertTo(value) {
    if (value === undefined || value === null || typeof value !== 'number') {
      throw new Error('Value to convert is not defined')
    }

    const rate = Exchange.getCountryRate()

    if (rate === undefined || rate === null || typeof rate !== 'number') {
      throw new Error('Rate to convert is not defined')
    }

    return (value * rate).toFixed(2)
  }

  static getCountryRate(exchange) {
    const rates = exchange || StateStorage.getLocalState('pomeloExchangeRate')
    const shop = StateStorage.getShop()
    const currency = SHOP_LANG_CURRENCY[shop].currency_iso_code
    const currencyRate = rates?.find(({ iso_code }) => iso_code === currency)

    if (currencyRate && currencyRate.rate_to_usd) {
      return currencyRate.rate_to_usd
    }

    return 1
  }

  static convertTo(value) {
    if (value === 0) return Promise.resolve(0)

    if (!value) {
      throw new Error('Value to convert is not defined')
    }

    if (this.getCountryRate()) {
      return Promise.resolve(this.getConvertedShopCurrencyValue(value))
    }

    return Exchange.get().then((res) => {
      if (res && res['exchange-rates']) {
        this.saveExchangeRate(res['exchange-rates'])
        return this.getConvertedShopCurrencyValue(value, res['exchange-rates'])
      }
      throw new Error('API Response is invalid')
    })
  }

  static getConvertedShopCurrencyValue(value, exchangeRate) {
    const rate = Exchange.getCountryRate(exchangeRate)
    const resolved = value * rate

    if (!resolved) {
      throw new Error('Resulting value is not defined')
    } else {
      return resolved.toFixed(2)
    }
  }

  static saveExchangeRate(exchangeRate) {
    StateStorage.saveLocalState('pomeloExchangeRate', exchangeRate)
  }

  static convertCurrencyValueTo(value, currency = 'USD', rates = []) {
    const currencyRate = rates?.find(({ iso_code }) => iso_code === currency)

    if (currencyRate && currencyRate.rate) {
      return value * currencyRate.rate
    }
    return value
  }
}

export default Exchange
