import { useState, useEffect, useRef } from 'react'
import { request } from 'lib/api'
import StateStorage from 'lib/state-storage'
import { ENDPOINTS } from 'lib/api/const'

const CART_RECOMM_STORAGE_KEY = 'pomeloCartRecomm'

function useCartRecommendations({ allowFetch, forceFetch = true, limit = 10 }) {
  const [data, setData] = useState(null)
  const [error, setError] = useState(null)
  const isFetching = useRef(false)

  useEffect(() => {
    let ignore = false

    async function fetch() {
      if (!ignore) {
        StateStorage.removeLocalState(CART_RECOMM_STORAGE_KEY)
        setData(null)

        try {
          isFetching.current = true
          const recommendations = await request({
            url: ENDPOINTS.cartRecommendation,
            params: {
              'filter[limit]': limit,
            },
          })

          StateStorage.saveLocalState(
            CART_RECOMM_STORAGE_KEY,
            recommendations,
            600,
          )
          setData(recommendations)
          isFetching.current = false
        } catch (e) {
          setError(e)
          isFetching.current = false
        }
      }
    }

    let cache = null

    if (!forceFetch) {
      cache = StateStorage.getLocalState(CART_RECOMM_STORAGE_KEY)

      if (cache) {
        setData(cache)
      }
    }

    if (!cache && allowFetch && !isFetching.current) {
      fetch()
    }

    return () => {
      ignore = true
    }
  }, [allowFetch, forceFetch, limit])

  return { data, error }
}

export default useCartRecommendations
