import PropTypes from 'prop-types'
import { Fragment, useMemo } from 'react'
import { connect } from 'react-redux'
import { customFlagValue, isFlagEligible } from 'lib/utils/handle-flag/const'
import CartProduct from '../cart-product'

const CartItems = ({
  allowProductUpdate,
  cartProducts,
  fromSummary,
  refType,
  setError,
  isProductUpdated,
  showFallbackModal,
}) => {
  const productItems = useMemo(
    () =>
      cartProducts?.items?.map((product) => ({
        ...product,
        selectedAttributes: product.attributes.find(({ selected }) =>
          Boolean(selected),
        ),
      })),
    [cartProducts],
  )

  const shoppingBagRedesignFlagValue = customFlagValue({
    flagName: 'shoppingBagRedesign',
  }) // TODO: force value to handle with flagsmith later (ShoppingBagRedesign-20230101)
  const isShoppingBagNewDesign = isFlagEligible(shoppingBagRedesignFlagValue)

  if (!cartProducts?.items) {
    return null
  }

  return (
    <Fragment>
      {productItems.map((product, idx) => (
        <CartProduct
          key={product.id_product}
          allowProductUpdate={allowProductUpdate}
          showMoveToBagCoachmark={idx === 0}
          fromSummary={fromSummary}
          product={product}
          refType={refType}
          selectedAttribute={product.selectedAttributes}
          setError={setError}
          isProductUpdated={isProductUpdated}
          showFallbackModal={showFallbackModal}
          isShoppingBagNewDesign={isShoppingBagNewDesign}
        />
      ))}
    </Fragment>
  )
}

CartItems.defaultProps = {
  allowProductUpdate: undefined,
  cartProducts: undefined,
  fromSummary: false,
  refType: 'bag',
  setError: undefined,
  isProductUpdated: undefined,
  showFallbackModal: undefined,
}

CartItems.propTypes = {
  allowProductUpdate: PropTypes.bool,
  cartProducts: PropTypes.shape({
    items: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  fromSummary: PropTypes.bool,
  setError: PropTypes.func,
  refType: PropTypes.string,
  isProductUpdated: PropTypes.func,
  showFallbackModal: PropTypes.func,
}

export default connect((state) => ({ cartProducts: state.cart.payload }))(
  CartItems,
)
