import { useEffect } from 'react'
import { RECAPTCHA_CONTAINER_ID } from 'components/auth/const'

function useRecaptcha() {
  useEffect(() => {
    let recaptchaEl = document.getElementById(RECAPTCHA_CONTAINER_ID)

    if (!recaptchaEl) {
      recaptchaEl = document.createElement('div')
      recaptchaEl.setAttribute('id', RECAPTCHA_CONTAINER_ID)
      document.body.appendChild(recaptchaEl)
    }
  }, [])
}

export default useRecaptcha
