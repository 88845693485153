/* eslint-disable react/no-unused-class-component-methods */
import PropTypes from 'prop-types'
import React from 'react'
import ExchangeRateApiHandler from 'lib/api/exchange'
import HealthCheckApiHandler from 'lib/api/healthcheck'
import ShopLanguageMap from 'lib/i18n/shop-language-map'
import StateStorage from 'lib/state-storage'
import Tracking from 'lib/tracking'
import UserInfo from 'lib/api/user-info'
import deviceDuck from 'lib/device/duck'
import i18nDuck from 'lib/i18n/duck'
import env from 'config/env'
import { parseProductPageNo } from 'pages/category/util'

class BasePage extends React.Component {
  static loadInitialContentFromServer({
    pathname,
    query,
    req,
    res,
    store,
    skipAuthorization = false,
  }) {
    const { zone, lang } = req.params
    const shop = ShopLanguageMap.getShopBySlug(zone)
    const locale = ShopLanguageMap.getIdLangBySlugAndLang(zone, lang)

    // workaround skip auth for ssr refresh token issues, which is this way would not broke ssr if doesn't have auth cookie
    if (skipAuthorization) {
      StateStorage.removeAuthUser()
    }

    const currency = StateStorage.getCookie('pomeloCurrency') || 1
    const shopWithLanguage = {
      ...shop,
      langSlug: ShopLanguageMap.getSlugFromLangId(locale),
      languageId: locale,
      currency,
    }
    const isDev =
      process.env.NODE_ENV !== 'production' &&
      process.env.NODE_ENV !== 'staging'
    const pathPrefix = `${isDev ? req.protocol : 'https'}://${req.get('host')}`

    let cleanedPath = BasePage.getCleanedPath(req.url, { zone, lang })
    let originPath = BasePage.getCleanedPath(req.url)

    if (pathname === '/category' && query?.page) {
      const parsedPageNo = parseProductPageNo(query.page)

      if (parsedPageNo > 1) {
        cleanedPath += `?page=${parsedPageNo}`
        originPath += `?page=${parsedPageNo}`
      }
    }

    StateStorage.setServerLastUrl(pathPrefix + req.url)
    store.dispatch(i18nDuck.creators.setShop(shopWithLanguage))
    store.dispatch(
      deviceDuck.creators.set({
        cleanedPath,
        isDesktop: res.locals.is_desktop,
        isPhone: res.locals.is_phone,
        originPath,
        pathPrefix,
      }),
    )

    return shopWithLanguage
  }

  static loadInitialContentFromClient(req, res, store) {
    const state = store.getState()
    const { country, currency, locale } = state.internationalization
    const shop = ShopLanguageMap.getShopBySlug(country)

    return { ...shop, languageId: locale, currency }
  }

  /**
   * Use case: path = `/th/en/home`, zone = `th` and lang = `en`. Returns `/home`
   */
  static getCleanedPath(path, { zone, lang } = {}) {
    let shopAndLangPath = ''

    if (zone) {
      shopAndLangPath += `/${zone}`

      if (lang) {
        shopAndLangPath += `/${lang}`
      }
    }

    return path.substring(shopAndLangPath.length).split('?')[0]
  }

  static onPageError(error) {
    return {
      error: true,
      msg: JSON.stringify(error),
    }
  }

  static async saveVersion(host) {
    const res = await HealthCheckApiHandler.get(host)

    if (res) {
      StateStorage.setHealthCheck(res)
    }
  }

  constructor(props, trackPageView = true) {
    super(props)
    this.trackPageView = trackPageView
    if (
      props &&
      props.internationalization &&
      props.internationalization.currency
    ) {
      StateStorage.saveCurrency(props.internationalization.currency)
    }
  }

  componentDidMount() {
    const { internationalization: i18n, isPhone } = this.props
    window.scroll(0, 0)

    if (this.trackPageView) {
      Tracking.trackPageView(window.location.href, {
        'criteo-mobile-or-desktop': isPhone ? 'm' : 'd',
        country: i18n ? i18n.country : null,
      })
    }

    if (typeof this.afterPageMount === 'function') {
      this.afterPageMount()
    }

    this.saveExchangeRate()
    BasePage.saveVersion()

    try {
      const { pathname } = window.location
      const [, , lang] = pathname.split('/')
      StateStorage.saveLanguage(lang)
    } catch (error) {
      //
    }
  }

  async saveExchangeRate() {
    const rate = StateStorage.getLocalState('pomeloExchangeRate')

    if (!rate) {
      const res = await ExchangeRateApiHandler.get()

      if (res && res['exchange-rates']) {
        StateStorage.saveLocalState('pomeloExchangeRate', res['exchange-rates'])
      }
    }
  }

  addExternalScript(src, id = '', onloadFunction = undefined) {
    const addScript = document.createElement('script')

    if (onloadFunction) {
      addScript.onload = onloadFunction
    }

    addScript.id = id
    addScript.setAttribute('src', src)
    addScript.setAttribute('async', true)
    document.body.appendChild(addScript)
    return addScript
  }

  componentWillUnmount() {
    if (typeof this.onPageLeave === 'function') {
      this.onPageLeave()
    }
  }

  throwUndefinedMethodError(methodName) {
    throw new Error(
      `Method ${methodName} needs to be defined in derived classes of BasePage`,
    )
  }

  throwUndefinedPropertyError(propertyName) {
    throw new Error(
      `Property ${propertyName} needs to be defined in derived classes of BasePage`,
    )
  }

  // FRESH CHAT SECTION

  loadFreshchatScript(t, i18n) {
    const scriptTag = document.getElementById('freshchat')

    if (!scriptTag) {
      this.addExternalScript(
        'https://wchat.freshchat.com/js/widget.js',
        'freshchat',
        () => {
          window.fcSettings = {
            token: env.FRESHCHAT_TOKEN,
            host: 'https://wchat.freshchat.com',
            tags: i18n.shop === 5 ? ['id'] : ['th'],
            open: false,
            config: {
              headerProperty: {
                backgroundColor: '#979797',
                foregroundColor: 'white',
                backgroundImage: 'none',
                hideChatButton: true,
              },
              content: {
                headers: {
                  push_notification: t(
                    'For better customer experience, we recommend enabling web notifications.',
                  ),
                },
                actions: {
                  push_notify_yes: t('Yes'),
                  push_notify_no: t('No'),
                },
              },
            },
          }

          window.fcWidget.init(window.fcSettings)
        },
      )
    }
  }

  onFreshChatUserCreated(responseCreated) {
    const status = responseCreated && responseCreated.status
    const data = responseCreated && responseCreated.data

    if (status === 200 && data.restoreId) {
      this.sendFreshchatRestoreId(data.restoreId)
    }
  }

  updateFreshchatProperties(user) {
    if (!user.freshchat_restore_id) {
      window.fcWidget.init({
        ...window.fcSettings,
        externalId: user.id_customer || 0,
      })

      window.fcWidget.user.get((res) => {
        const status = res && res.status
        const data = res && res.data

        if (status !== 200) {
          window.fcWidget.user.setProperties({
            firstName: user.firstname,
            lastName: user.lastname,
            email: user.email,
          })

          window.fcWidget.on('user:created', this.onFreshChatUserCreated)
        } else if (data?.restoreId) {
          this.sendFreshchatRestoreId(data.restoreId, user)
        }
      })
    }
  }

  sendFreshchatRestoreId(restoreId, user) {
    return UserInfo.updateCurrentUserInfo({
      firstname: user.firstname,
      lastname: user.lastname,
      email: user.email,
      citizen_id: user.citizen_id || undefined,
      userId: user.id_customer,
      freshchat_restore_id: restoreId,
    })
  }

  // END FRESH CHAT SECTION
}

BasePage.propTypes = {
  internationalization: PropTypes.shape({
    country: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    currency: PropTypes.string,
  }).isRequired,
  isPhone: PropTypes.bool.isRequired,
}

export default BasePage
