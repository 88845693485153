import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { useForm } from 'react-hook-form'
import { parsePhoneNumber } from 'libphonenumber-js'
import Button from 'components/common/button'
import { BUTTON_HTML_TYPE, BUTTON_TYPES } from 'constants/button'
import { getCountryCodeWithShopID } from 'lib/utils/common/commonUtils'
import { hasLocizeTranslation } from 'lib/utils/locize'
import { useAuth } from 'components/auth/context'
import { withI18next } from 'lib/i18n/withI18next'
import TextFieldPhone from 'components/molecules/textfield-phone'
import { formConfig } from 'components/auth/signup-form/const'
import FormItem from 'components/form-item'
import TextField from 'components/common/textfield'
import UserInfo from 'lib/api/user-info'
import compose from 'recompose/compose'
import style from '../../shared/phone/style.scss'
import AuthDateOfBirthSelect from '../../shared/date-of-birth-select/AuthDateOfBirthSelect'
import { goToMyLoyaltyPage } from '../../utils'

const AuthLoyaltySignUpForm = ({
  auth,
  countryState,
  internationalization,
  isRegister,
  t,
}) => {
  // states
  const [authState, updateAuthState] = useAuth()
  const [isLoading, setIsLoading] = useState(false)
  const [isVerified, setIsVerified] = useState(false)
  const [prefix, setPrefix] = useState(
    getCountryCodeWithShopID(internationalization),
  )
  const { errors, formState, handleSubmit, register } = useForm({
    mode: 'onChange',
  })

  const { user } = auth
  const { isValid, dirty } = formState
  const invalidPhoneMsg = hasLocizeTranslation(
    t,
    'FORM_INVALID_PHONE_FORMAT',
    'Invalid phone number format.',
  )
  const isVerifiedAndNotTouched = isVerified && !dirty

  // events
  const phoneRegister = register(
    formConfig.phone({
      isRequired: true,
      requiredMessage: t('Required'),
      lengthErrorMessage: invalidPhoneMsg,
      patternErrorMessage: invalidPhoneMsg,
    }),
  )

  const handleFormSubmit = async (values, e) => {
    e?.preventDefault()

    try {
      const birthDate = values.date
      const birthMonth = values.month
      const birthYear = values.year
      const fullBirthday = `${birthDate}-${birthMonth}-${birthYear}`
      const fullPhone = parsePhoneNumber(`+${prefix}${values.phone}`).number

      updateAuthState({
        overlayLoading: true,
      })
      setIsLoading(true)

      UserInfo.updateCurrentUserInfo({
        userId: user.id_customer,
        phone_signup: fullPhone,
        loyalty_signup: true,
        postcode: values.postcode,
        birthday: fullBirthday,
      })

      goToMyLoyaltyPage()
    } catch (error) {
      updateAuthState({ snackBar: error })
    }

    updateAuthState({ overlayLoading: false })
    setIsLoading(false)
  }

  // effect
  useEffect(() => {
    if (authState.dataPhone) {
      setIsVerified(true)
    }
    // disabled this rule because want to run only when mounted
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <form
      className="auth__phone-login-wrapper"
      onSubmit={handleSubmit(handleFormSubmit)}
      noValidate
    >
      <style jsx>{style}</style>
      <FormItem>
        <TextFieldPhone
          htmlFor="phone"
          inputProps={{
            cy: 'auth__phone__input',
            required: true,
            type: 'tel',
            id: 'phone',
          }}
          label={t('Phone Number')}
          name="phone"
          countryList={countryState?.countries}
          phonePrefixRegister={register({ required: true })}
          phoneNumberRegister={phoneRegister}
          selectedPhonePrefix={String(prefix)}
          userPhoneNumber={null}
          handleChangePhonePrefix={(e) => setPrefix(+e.target.value)}
          error={errors.phone?.message}
        />
      </FormItem>
      <AuthDateOfBirthSelect register={register} />
      <FormItem>
        <TextField
          fullWidth
          name="postcode"
          htmlFor="postcode"
          inputProps={{
            id: 'postcode',
          }}
          label={hasLocizeTranslation(
            t,
            'ADDRESS_FORM_POSTAL_CODE',
            'Postcode',
          )}
          defaultValue={null}
          inputRef={register({ required: true })}
          error={!!errors?.postcode}
          helperText={errors?.postcode?.message}
          type="number"
        />
      </FormItem>
      <FormItem>
        <Button
          className="auth__phone-login-cta"
          cy="auth__login__phone__button"
          disabled={(!isValid && !isVerifiedAndNotTouched) || isLoading}
          htmlType={BUTTON_HTML_TYPE.submit}
          type={BUTTON_TYPES.primary}
        >
          {t(isRegister ? 'Sign Up' : 'Log In')}
        </Button>
      </FormItem>
    </form>
  )
}

AuthLoyaltySignUpForm.propTypes = {
  auth: PropTypes.shape({
    customerData: PropTypes.shape({ isFetched: PropTypes.bool }),
    isGuestMode: PropTypes.bool,
    user: PropTypes.shape({
      id_customer: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      phone_signup: PropTypes.string,
    }),
  }).isRequired,
  countryState: PropTypes.shape({
    countries: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  internationalization: PropTypes.shape({}).isRequired,
  isRegister: PropTypes.bool,
  t: PropTypes.func.isRequired,
}

AuthLoyaltySignUpForm.defaultProps = {
  countryState: undefined,
  isRegister: false,
}

export default compose(
  connect((state) => ({
    auth: state.auth,
    countryState: state.country.payload,
    internationalization: state.internationalization,
  })),
  withI18next(),
)(AuthLoyaltySignUpForm)
