import clsx from 'clsx'
import PropTypes from 'prop-types'
import React from 'react'
import Typo from 'constants/typography'

const ProductCollectionBadge = ({
  isProductBadge,
  isCategoryBadge,
  swatchBadgeValue,
  badge,
  badge_value,
}) => {
  const elementProps = {
    spanClassName: clsx({
      [`product_badge-name ${Typo.overline}`]: isProductBadge,
      [Typo.overline]: isCategoryBadge,
    }),
    wrapperClassName: clsx({
      'product_collection-name__wrapper': isCategoryBadge,
    }),
  }

  return (
    <div className={elementProps.wrapperClassName}>
      <span className={elementProps.spanClassName}>
        {swatchBadgeValue || badge?.value || badge_value}
      </span>
    </div>
  )
}

ProductCollectionBadge.defaultProps = {
  badge_value: null,
}

ProductCollectionBadge.propTypes = {
  badge: PropTypes.shape({
    value: PropTypes.string,
  }).isRequired,
  badge_value: PropTypes.string,
  isProductBadge: PropTypes.oneOfType([PropTypes.bool, PropTypes.string])
    .isRequired,
  isCategoryBadge: PropTypes.bool.isRequired,
  swatchBadgeValue: PropTypes.string.isRequired,
}

export default ProductCollectionBadge
