import queryString from 'query-string'
import { getFilterParams } from 'lib/utils/category'
// eslint-disable-next-line import/no-cycle
import { request } from '.'
import { HTTP_METHODS } from './const'
import getRecentlyProductIdsParam from './utils'

class Products {
  static getList(options) {
    const {
      categoryId,
      device,
      isGuestMode,
      language: lang,
      segmentAnonymousID,
      shop,
      timeout,
      token,
      userUId: userUID,
    } = options
    const queryParams = Products.getQueryParams(options)
    const recentlyIdList = getRecentlyProductIdsParam()

    return request({
      device,
      isGuestMode,
      lang,
      segmentAnonymousID,
      shop,
      timeout,
      token,
      url: `v6/categories/${categoryId}/products?${queryParams}`,
      userUID,
      ...(recentlyIdList && { params: { product_ids: recentlyIdList } }),
    })
  }

  static getQueryParams(options) {
    if (!options) {
      return ''
    }

    const queryParams = { skip: Products.getSkipQueryParams(options.skip) }

    if (options.filter) {
      const filterParam = getFilterParams(options.filter, true)
      queryParams.filter = queryString.stringify(filterParam)
    }

    if (options.sort?.by) {
      queryParams.order_by = `filter[order_by]=${options.sort.by}`
    }

    if (options.limit) {
      queryParams.limit = `filter[limit]=${options.limit}`
    }

    return Object.values(queryParams)
      .filter(Boolean)
      .map((value) => value)
      .join('&')
  }

  static getSkipQueryParams(skip = 0) {
    return `filter[skip]=${skip}`
  }

  /**
   *
   * @param {string} productIDStringList - A string of concatinated `id_product` separated by comma ex. `'123,456,'`
   */
  static getProductListByIDs(productIDStringList) {
    return request({
      method: HTTP_METHODS.get,
      url: `v6/products`,
      params: {
        product_ids: productIDStringList,
      },
    })
  }

  static async getRecommendsByProduct(productId) {
    const recommendsByProductResponse = await request({
      method: HTTP_METHODS.get,
      url: `v6/products/${productId}/recommendations`,
    })

    return recommendsByProductResponse
  }

  static async getUpSellsByProduct(options) {
    const productId = typeof options === 'object' ? options.id_product : options

    const upsellsResponse = await request({
      url: `v6/products/${productId}/upsells`,
      method: HTTP_METHODS.get,
    })

    if (upsellsResponse) {
      return upsellsResponse.products
    }

    return {}
  }

  static getFilterQueryString(filters, param) {
    let query = ''

    if (filters && filters.values.length > 0) {
      const filterQuery = filters.values.reduce(
        (res, value) =>
          res && res.length > 0
            ? `${res},${encodeURIComponent(value)}`
            : `${encodeURIComponent(value)}`,
        '',
      )
      query = `${param}=${filterQuery}`
    }

    return query
  }

  static getPriceFilterQuery(filters) {
    let query = ''

    if (filters && filters.values.length > 1) {
      query = `filter[filter_price]=${filters.values[0]}-${filters.values[1]}`
    }

    return query
  }

  static getProductAlternatives(productId, currentFirst) {
    return request({
      url: `v6/products/${productId}/alternatives`,
      method: HTTP_METHODS.get,
      ...(currentFirst && {
        params: {
          'filter[order_by]': 'current_first',
        },
      }),
    })
  }

  static getProducts(productIds) {
    return request({
      method: HTTP_METHODS.get,
      url: `v6/products/image-search`,
      ...(productIds && {
        params: {
          product_ids: productIds?.join(','),
        },
      }),
    })
  }

  static async getSimilarsByProduct({ productId, shop, lang, skip, limit }) {
    const similarsByProductResponse = await request({
      method: HTTP_METHODS.get,
      url: `v6/products/${productId}/similar`,
      shop,
      lang,
      params: {
        'filter[skip]': skip || 0,
        'filter[limit]': limit || 8,
      },
    })

    if (similarsByProductResponse) {
      return similarsByProductResponse.products
    }

    return {}
  }

  static getPersonalizedRecommendation({
    productId,
    productIdList,
    limit,
    skip,
  }) {
    return request({
      method: HTTP_METHODS.get,
      url: `v6/products/custom-recommendations`,
      params: {
        ...(!!productId && { product_id: productId }),
        ...(!!productIdList && { product_ids: productIdList }),
        'filter[skip]': skip || 0,
        'filter[limit]': limit || 8,
      },
    })
  }
}

export default Products
