import Duck from 'extensible-duck'

const duck = new Duck({
  namespace: 'content',
  store: 'internationalization',
  types: ['UPDATE_LOCALE', 'SET_SHOP'],
  initialState: {
    country: 'th',
    language: 'th',
    locale: 'th', // This supposed to be number?
    currency: 1,
    shop: 1,
    countryId: 206,
  },
  reducer: (state, action, scopedDuck) => {
    switch (action.type) {
      case scopedDuck.types.UPDATE_LOCALE:
        return { ...state, locale: action.locale }
      case scopedDuck.types.SET_SHOP:
        return {
          ...state,
          country: action.shop.shopSlug,
          language: action.shop.langSlug,
          locale: action.shop.languageId,
          currency: action.shop.currencyId,
          shop: action.shop.shopId,
          countryId: action.shop.countryId,
        }
      default:
        return state
    }
  },
  creators: (scopedDuck) => ({
    setShop: (shop) => ({ type: scopedDuck.types.SET_SHOP, shop }),
    setLanguage: (locale) => ({ type: scopedDuck.types.UPDATE_LOCALE, locale }),
  }),
})

export default duck
