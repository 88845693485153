import StateStorage from '../state-storage'
import { request } from './index'
import { HTTP_METHODS } from './const'

class Country {
  static async getCountry() {
    const keyName = 'pomeloCountries'

    // All shops should return the same response.
    const cached = StateStorage.getLocalState(keyName)

    if (cached) {
      return Promise.resolve(cached)
    }

    const res = await request({
      url: 'v6/static/countries',
      method: HTTP_METHODS.get,
    })
    StateStorage.saveLocalState(keyName, res, 600)

    return res
  }
}

export default Country
