import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import env from 'config/env'

const updateSelectedSwatch = ({
  data,
  e,
  isSelected,
  parentId,
  setSelectedSwatch,
}) => {
  if (isSelected) {
    return
  }

  if (e?.preventDefault && e?.stopPropagation) {
    e.preventDefault()
    e.stopPropagation()
  }

  const isDefaultSwatch = parentId === data.id_product

  setSelectedSwatch(isDefaultSwatch ? null : data)
}

const handleSwatchClick = (
  e,
  showFullSwatches,
  callTriggerFullSwatch,
  { data, productId, isSelected, parentId, setSelectedSwatch },
) => {
  if (showFullSwatches) {
    updateSelectedSwatch({
      data,
      e,
      isSelected,
      parentId,
      setSelectedSwatch,
    })
  } else {
    callTriggerFullSwatch(e, productId, false)
  }
}

const ProductItemSwatch = ({
  callTriggerFullSwatch,
  data,
  fullSwatches,
  idx,
  isPhone,
  parentId,
  selectedSwatchId,
  setSelectedSwatch,
  showFullSwatches,
}) => {
  const showFullSwatchesWithData = showFullSwatches && fullSwatches?.length

  // Show up to 4 swatches for non-full swatches and full swatches on mobile
  if (!data || (idx > 3 && (!showFullSwatchesWithData || isPhone))) {
    return null
  }

  const {
    color,
    display_color,
    pattern_image: pattern,
  } = showFullSwatchesWithData ? data.color : data
  const productId = data.id_product || data.id_product_associated
  const isSelected = selectedSwatchId === productId
  const style = pattern
    ? {
        backgroundImage: `url(${env.IMG_HOST}${pattern}?auto=compress,format&q=60&w=50)`,
      }
    : { backgroundColor: display_color || color }

  return (
    <span
      aria-label="product-color-variation"
      className={clsx('product_color-variation__color', {
        selected: isSelected,
      })}
      style={style}
      data-cy="product-item-swatch"
      role="button"
      tabIndex="0"
      onClick={(e) =>
        handleSwatchClick(e, showFullSwatches, callTriggerFullSwatch, {
          data,
          productId,
          isSelected,
          parentId,
          setSelectedSwatch,
        })
      }
      onKeyDown={(e) =>
        handleSwatchClick(e, showFullSwatches, callTriggerFullSwatch, {
          data,
          productId,
          isSelected,
          parentId,
          setSelectedSwatch,
        })
      }
    />
  )
}

ProductItemSwatch.defaultProps = {
  data: null,
  fullSwatches: null,
}

ProductItemSwatch.propTypes = {
  callTriggerFullSwatch: PropTypes.func.isRequired,
  data: PropTypes.shape({
    color: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]),
    id_product: PropTypes.number,
    id_product_associated: PropTypes.number,
  }),
  fullSwatches: PropTypes.arrayOf(PropTypes.shape({})),
  idx: PropTypes.number.isRequired,
  isPhone: PropTypes.bool.isRequired,
  parentId: PropTypes.number.isRequired,
  selectedSwatchId: PropTypes.number.isRequired,
  setSelectedSwatch: PropTypes.func.isRequired,
  showFullSwatches: PropTypes.bool.isRequired,
}

export default ProductItemSwatch
