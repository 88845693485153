// eslint-disable-next-line import/no-cycle
import { request } from 'lib/api'
import env from 'config/env'
import { HTTP_METHODS } from './const'

/**
 * @param {Object} param
 * @param {Object} param.body The request body containing the identity details
 * @param {Boolean} param.isInitFlagsmith Whether to use the Flagsmith API key or the Edge proxy API key
 * @returns {Object|null} The feature flags for the given identity or null if there was an error
 */
export const fetchFlagsFromFlagsmith = async ({ body, isInitFlagsmith }) => {
  try {
    const features = await request({
      baseURL: isInitFlagsmith
        ? env.FLAGSMITH_API_KEY
        : env.FLAGSMITH_EDGE_PROXY_API_KEY,
      url: 'identities/',
      method: HTTP_METHODS.post,
      header: {
        'x-environment-key': env.FLAGSMITH_CLIENT_ID,
      },
      body,
      withAuthorization: false,
    })
    return features
  } catch (e) {
    return null
  }
}

export default fetchFlagsFromFlagsmith
