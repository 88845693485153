import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import Typo from 'constants/typography'
import { withI18next } from 'lib/i18n/withI18next'
import InputLayout from 'components/common/input-layout'
import { getClassNameWithOpacity } from 'components/cart/cart-product/utils'

const OneSizeSelection = ({ isSoldOut, selectedAttribute, t }) => {
  const sizeClass = getClassNameWithOpacity(
    clsx(Typo.subtitle2, 'default-amount'),
    isSoldOut,
  )

  return (
    <InputLayout className="item-info__size" label={t('Size')} fullWidth shrink>
      <div className={sizeClass}>{selectedAttribute?.size}</div>
    </InputLayout>
  )
}

OneSizeSelection.defaultProps = {
  isSoldOut: false,
  selectedAttribute: null,
}

OneSizeSelection.propTypes = {
  isSoldOut: PropTypes.bool,
  selectedAttribute: PropTypes.shape({ size: PropTypes.string }),
  t: PropTypes.func.isRequired,
}

export default withI18next()(OneSizeSelection)
