import React from 'react'
import PropTypes from 'prop-types'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import { withI18next } from 'lib/i18n/withI18next'
import Typo from 'constants/typography'
import { hasLocizeTranslation } from 'lib/utils/locize'
import clsx from 'clsx'
import Link from 'next/link'
import StateStorage from 'lib/state-storage'
import Icon, { ICONS, ICON_SIZE } from 'components/common/icon'
import styles from './my-account-title-navigation.scss'

const MyAccountTitleNavigation = ({
  t,
  title,
  isPhone,
  actionBtn,
  onClickBack,
  isShowBackBtn,
}) => {
  const shop = StateStorage.getShop()
  const language = StateStorage.getLanguage()
  const isControlClickBackBtn = Boolean(onClickBack)
  const backBtnComponent = isControlClickBackBtn ? (
    <a
      role="presentation"
      onClick={(event) => {
        event.stopPropagation()
        event.preventDefault()
        onClickBack()
      }}
      onKeyDown={() => onClickBack()}
      className={clsx('my-account-title-navigation__back-btn', Typo.body2)}
    >
      <Icon src={ICONS.arrowHeadLeft} size={ICON_SIZE.small} />
      {!isPhone && hasLocizeTranslation(t, 'BACK', 'Back')}
    </a>
  ) : (
    <Link
      href="/user?page=my-account"
      as={`/${shop}/${language}/user/my-account`}
    >
      <a className={clsx('my-account-title-navigation__back-btn', Typo.body2)}>
        <Icon src={ICONS.arrowHeadLeft} size={ICON_SIZE.small} />
        {!isPhone && hasLocizeTranslation(t, 'BACK', 'Back')}
      </a>
    </Link>
  )

  return (
    <React.Fragment>
      <style jsx>{styles}</style>
      <div className="my-account-title-navigation">
        {isShowBackBtn && backBtnComponent}
        <span
          className={clsx('my-account-title-navigation__title', Typo.subtitle1)}
        >
          {title}
        </span>
        <div className="my-account-title-navigation__action-btn">
          {actionBtn}
        </div>
      </div>
    </React.Fragment>
  )
}

MyAccountTitleNavigation.defaultProps = {
  title: '',
  actionBtn: null,
  onClickBack: null,
  isShowBackBtn: true,
}

MyAccountTitleNavigation.propTypes = {
  title: PropTypes.string,
  actionBtn: PropTypes.node,
  onClickBack: PropTypes.func,
  t: PropTypes.func.isRequired,
  isShowBackBtn: PropTypes.bool,
  isPhone: PropTypes.bool.isRequired,
}

export default compose(
  connect((state) => ({
    isPhone: state.device.isPhone,
  })),
  withI18next(),
)(MyAccountTitleNavigation)
