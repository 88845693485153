import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import Typo from 'constants/typography'
import Icon from 'components/common/icon'
import ICONS, { ICON_SIZE } from 'components/common/icon/const'
import Button from 'components/common/button'
import { BUTTON_TYPES } from 'constants/button'
import {
  getEmailProviderListFireBase,
  logInOrSignUpWithMerge,
  logInWithPhoneGetSMSFireBase,
  signInWithFacebook,
  signInWithGoogle,
  successfulMerge,
} from 'lib/auth'
import Loader from 'components/loader'
import { withI18next } from 'lib/i18n/withI18next'
import style from './style.scss'
import { useAuth } from '../context'
import AuthEmail from '../shared/email'
import { AUTH_ERROR, AUTH_PROVIDER, AUTH_UI_STATE } from '../const'

const AuthMerge = ({ t }) => {
  const [authState, updateAuthState] = useAuth()
  const [isLoading, setIsLoading] = useState(false)
  const [mergeType, setMergeType] = useState()
  const { dataEmail, dataPassword, dataPhone, source } = authState

  useEffect(() => {
    async function fetchMergeType() {
      const providerList = await getEmailProviderListFireBase(dataEmail)
      setMergeType(providerList?.length ? providerList[0] : AUTH_PROVIDER.email)
    }

    fetchMergeType()
  }, [dataEmail])

  const mergeWithEmail = async (email, password) => {
    updateAuthState({ isMerging: true, overlayLoading: true })

    try {
      if (source === AUTH_PROVIDER.phone) {
        await logInWithPhoneGetSMSFireBase(dataPhone)

        updateAuthState({
          overlayLoading: false,
          uiState: AUTH_UI_STATE.verifyPhone,
        })
      } else {
        const res = await logInOrSignUpWithMerge(source, email, password)

        successfulMerge(res)
      }
    } catch (err) {
      updateAuthState({ overlayLoading: false, snackBar: err })
    }
  }

  // Use istanbul ignore for now
  // reason: can't handle signIn popup callback
  /* istanbul ignore next */
  function stopLoading() {
    updateAuthState({ overlayLoading: false })
    setIsLoading(false)
  }

  const mergeFacebookOrGoogle = async () => {
    updateAuthState({ isMerging: true, overlayLoading: true })
    setIsLoading(true)
    const isFromPhone = source === AUTH_PROVIDER.phone

    try {
      if (
        mergeType === AUTH_PROVIDER.facebook[0] ||
        mergeType === AUTH_PROVIDER.facebook[1]
      ) {
        await signInWithFacebook(
          false,
          isFromPhone ? undefined : stopLoading,
          isFromPhone,
        )
      } else {
        await signInWithGoogle(
          false,
          isFromPhone ? undefined : stopLoading,
          isFromPhone,
        )
      }

      if (isFromPhone) {
        await logInWithPhoneGetSMSFireBase(dataPhone)

        updateAuthState({
          overlayLoading: false,
          uiState: AUTH_UI_STATE.verifyPhone,
        })
      } else {
        const res = await logInOrSignUpWithMerge(
          source,
          dataEmail,
          dataPassword,
        )

        successfulMerge(res)
      }
    } catch (err) {
      if (
        ![AUTH_ERROR.popUpCancelled, AUTH_ERROR.popUpClosed].includes(err.code)
      ) {
        updateAuthState({ overlayLoading: false, snackBar: err })
      }

      setIsLoading(false)
    }
  }

  const getMergeDetails = (provider) => {
    let mergeDetails = {
      desc: 'Your Facebook email address has already been used to create a Pomelo account. Sign in with Facebook to continue.',
      icon: ICONS.facebookAuthMerge,
      button: 'Log in with Facebook',
    }
    if (provider === 'google') {
      mergeDetails = {
        desc: 'Your Google email address has already been used to create a Pomelo account. Sign in with Google to continue.',
        icon: ICONS.googleAuthMerge,
        button: 'Log in with Google',
      }
    }
    return mergeDetails
  }

  const getSocialMediaMerge = (provider) => {
    const { desc, icon, button } = getMergeDetails(provider)
    return (
      <React.Fragment>
        <span className={clsx(Typo.body2, 'auth__desc auth__desc__merge')}>
          {t(desc)}
        </span>
        <Button
          className="auth__standalone-cta full-width"
          disabled={isLoading}
          onClick={mergeFacebookOrGoogle}
          type={BUTTON_TYPES.outlinedLight}
        >
          <Icon src={icon} size={ICON_SIZE.medium} />
          {t(button)}
        </Button>
      </React.Fragment>
    )
  }

  const renderBody = () => {
    if (!mergeType) {
      return (
        <div className="loader-container">
          <Loader />
        </div>
      )
    }

    switch (mergeType) {
      case AUTH_PROVIDER.facebook[0]:
      case AUTH_PROVIDER.facebook[1]:
        return getSocialMediaMerge('facebook')
      case AUTH_PROVIDER.google[0]:
      case AUTH_PROVIDER.google[1]:
        return getSocialMediaMerge('google')
      case AUTH_PROVIDER.email:
      default:
        return (
          <React.Fragment>
            <span className={clsx(Typo.body2, 'auth__desc auth__desc__merge')}>
              {t(
                'This email address has already been used to create a Pomelo account. Enter the password for that account to continue.',
              )}
            </span>
            <AuthEmail mergeHandler={mergeWithEmail} t={t} />
          </React.Fragment>
        )
    }
  }

  return (
    <React.Fragment>
      <style jsx>{style}</style>
      <span className={clsx(Typo.h4, 'auth__title')}>
        {t('Merge accounts')}
      </span>
      {renderBody()}
    </React.Fragment>
  )
}

AuthMerge.propTypes = {
  t: PropTypes.func.isRequired,
}

export default withI18next()(AuthMerge)
