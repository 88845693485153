import imageCompression from 'browser-image-compression'

const compressImageWithMaxSizeMB = async (imgFile, maxSizeMB = 1) => {
  try {
    const compressedFile = await imageCompression(imgFile, maxSizeMB) // maxSizeMB, maxWidthOrHeight are optional
    return compressedFile
  } catch (err) {
    throw new Error(err)
  }
}

const getFileSizeMB = (imgFile) => imgFile.size / 1024 / 1024

export { getFileSizeMB }

export default compressImageWithMaxSizeMB
