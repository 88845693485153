import { request } from './index' // eslint-disable-line import/no-cycle
import { HTTP_METHODS } from './const'

class PDPAConsent {
  static async SavePDPAConsent(options) {
    const { cookie_consent } = options

    return request({
      url: 'v6/consents',
      method: HTTP_METHODS.post,
      body: {
        ...(!!cookie_consent && {
          cookie_consent: {
            is_consent_to_analytics_usage:
              cookie_consent.is_performance_allowed,
            is_consent_to_marketing_usage: cookie_consent.is_marketing_allowed,
            is_strictly_necessory: true,
          },
        }),
        privacy_consent: {
          is_consent_to_analytics_usage: true,
          is_consent_to_marketing_usage: true,
        },
      },
    })
  }
}

export default PDPAConsent
