import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import clsx from 'clsx'
import Typo from 'constants/typography'
import { hasLocizeTranslation } from 'lib/utils/locize'
import TextField, { NUMBER_ONLY_REGEX } from 'components/common/textfield'
import Button from 'components/common/button'
import { BUTTON_HTML_TYPE, BUTTON_TYPES } from 'constants/button'
import {
  logInOrSignUpWithPhoneCodeFireBase,
  logInWithPhoneGetSMSFireBase,
  mergeWithPhoneCodeFireBase,
  successfulLogInFireBase,
  getEmailProviderListFireBase,
  mergeWithEmailFirebase,
} from 'lib/auth'
import { withI18next } from 'lib/i18n/withI18next'
import useTimer from 'components/custom-hooks/useTimer'
import FormItem from 'components/form-item'
import { segmentPDPAConsentResponded } from 'lib/segment'
import { useAuth } from 'components/auth/context'
import {
  AUTH_PROVIDER,
  AUTH_ERROR,
  AUTH_ERROR_MERGE,
  AUTH_UI_STATE,
  AUTH_BLOCK_USER_TYPE,
  AUTH_MESSAGE,
} from 'components/auth/const'
import UserInfo from 'lib/api/user-info'
import { useFlagsmithGetFlagValue } from 'lib/flagsmith'

import style from './style.scss'

const INPUT_NAMES = ['1', '2', '3', '4', '5', '6']

const KEY_CODES = {
  arrowLeft: 'ArrowLeft',
  arrowRight: 'ArrowRight',
  backspace: 'Backspace',
}

const AuthVerify = ({ setIsMigrate, t }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [customError, setCustomError] = useState({
    isError: false,
  })
  const [sendSms, setSendSms] = useState()
  const isAutoLoyaltySignupFlag = useFlagsmithGetFlagValue(
    'EnableLoyaltyAutoSignupFields_20231002',
    false,
  )
  const inputsRef = useRef([])
  const {
    errors,
    formState,
    handleSubmit,
    register,
    reset,
    triggerValidation,
  } = useForm({
    mode: 'onChange',
  })
  const { isSubmitted, isValid } = formState
  const [authState, updateAuthState] = useAuth()
  const { seconds, isRunning, restart } = useTimer({
    expiryTimestamp: Date.now() + 60 * 1000,
    autoStart: true,
  })
  const {
    dataPhone,
    dataEmail,
    dataFirstName,
    dataLastName,
    dataPostcode,
    dataDateOfBirth,
    isMerging,
    isMigrate,
    isPhoneSignup,
  } = authState

  const firstInputEl = inputsRef.current[1]

  useEffect(() => {
    if (firstInputEl) {
      firstInputEl.focus()
    }
  }, [firstInputEl])

  useEffect(() => {
    if (isSubmitted) {
      triggerValidation()
    }
  }, [customError, isSubmitted, triggerValidation])

  const onFocus = (e) => {
    if (e.target.select) {
      e.target.select()
    }
  }

  const onKeyDown = (e) => {
    if (e.target) {
      const { key } = e
      const inputNo = +e.target.getAttribute('name')
      const prevInput = inputsRef.current[inputNo - 1]
      const nextInput = inputsRef.current[inputNo + 1]

      if (prevInput && key === KEY_CODES.arrowLeft) {
        e.preventDefault()
        prevInput.focus()
      } else if (
        prevInput &&
        key === KEY_CODES.backspace &&
        inputsRef.current[inputNo].value.length < 1
      ) {
        prevInput.focus()
      } else if (nextInput && key === KEY_CODES.arrowRight) {
        e.preventDefault()
        nextInput.focus()
      }
    }
  }

  const onInput = (e) => {
    if (e.target) {
      const inputNo = +e.target.getAttribute('name')

      if (!errors[`${inputNo}`]) {
        const nextInput = inputsRef.current[inputNo + 1]

        if (nextInput) {
          nextInput.focus()
        }
      } else {
        e.target.select()
      }
    }

    if (customError) {
      setCustomError({
        isError: false,
      })
    }
  }

  const handleFormSubmit = async (values, e) => {
    e?.preventDefault()
    const code = Object.values(values).join('')

    setIsMigrate(isMigrate)
    updateAuthState({ overlayLoading: true })
    setIsLoading(true)

    try {
      const res = isMerging
        ? await mergeWithPhoneCodeFireBase(code)
        : await logInOrSignUpWithPhoneCodeFireBase(code, {
            email: dataEmail,
            firstName: dataFirstName,
            lastName: dataLastName,
          })

      if (isMerging && !!dataEmail) {
        // In case need more merge email to phone account
        const providerList =
          (await getEmailProviderListFireBase(dataEmail)) || []

        const isMergedEmail =
          providerList.includes(AUTH_PROVIDER.email) ||
          providerList.includes(AUTH_PROVIDER.password)

        if (!isMergedEmail) {
          await mergeWithEmailFirebase(dataEmail)
        }
      }

      successfulLogInFireBase(
        res,
        Boolean(dataEmail && dataFirstName && dataLastName),
      )
      segmentPDPAConsentResponded()

      if (isPhoneSignup && isAutoLoyaltySignupFlag) {
        /**
         * Description: for sign up using phone number, the auto enroll loyalty program will be executed after the phone number is verified
         */
        await UserInfo.updateCurrentUserInfo({
          phone_signup: dataPhone,
          loyalty_signup: true,
          postcode: dataPostcode,
          birthday: dataDateOfBirth,
        })
      }

      if (res) {
        const customer = res?.customer
        // eslint-disable-next-line
        smartech(`contact`, 2, {
          'pk^customerid': `${customer?.id_customer}`,
          email: customer?.email,
          FIRST_NAME: customer?.firstname,
        })
        // eslint-disable-next-line
        smartech(`identify`, `${customer?.id_customer}`)
        // eslint-disable-next-line
        smartech(`dispatch`, `sign_in`, {
          email: customer?.email,
          first_name: customer?.firstname,
        })
      }
    } catch (err) {
      const isInvalidOTP =
        err.message === AUTH_MESSAGE.incorrectUsernameOrPassword ||
        err.message === 'Invalid OTP'

      const isPasswordAttemptsExceeded =
        err.message === AUTH_MESSAGE.passwordAttemptsExceeded ||
        err.message === AUTH_MESSAGE.invalidSessionForTheUser

      const isExpiredOTP =
        [AUTH_ERROR.codeExpired].includes(err.code) ||
        err.message === 'Expired OTP'
      const isMerge = AUTH_ERROR_MERGE.includes(err.code)

      const isTooManyAttempts = err.message?.includes(
        AUTH_MESSAGE.tooManyAttempts,
      )

      if (!window.navigator.onLine) {
        updateAuthState({ uiState: AUTH_UI_STATE.logIn, isMigrate: false })
        setIsMigrate(false)
      } else if (isInvalidOTP) {
        setCustomError({ isError: true, type: 'invalid' })
      } else if (isExpiredOTP) {
        setCustomError({ isError: true, type: 'expired' })
      } else if (isTooManyAttempts) {
        updateAuthState({
          overlayLoading: false,
          uiState: AUTH_UI_STATE.blockedUser,
          unblockTime: err.unblockTime,
          blockType: AUTH_BLOCK_USER_TYPE.LOGIN,
        })
        setIsLoading(false)
        return
      } else if (isPasswordAttemptsExceeded) {
        updateAuthState({
          overlayLoading: false,
          uiState: AUTH_UI_STATE.blockedUser,
          blockType: AUTH_BLOCK_USER_TYPE.LOGIN,
        })
        setIsLoading(false)
        return
      } else if (isMerge) {
        updateAuthState({ uiState: AUTH_UI_STATE.merge })
      } else {
        updateAuthState({ snackBar: err })
      }

      updateAuthState({ overlayLoading: false })
      setIsLoading(false)
    }
  }

  const resendVerificationCode = async () => {
    updateAuthState({ overlayLoading: true })
    setIsLoading(true)
    setCustomError({ isError: false })
    reset()

    try {
      await logInWithPhoneGetSMSFireBase(dataPhone)

      restart(Date.now() + 60 * 1000)

      if (firstInputEl) {
        firstInputEl.focus()
      }
    } catch (err) {
      if (err.message.includes(AUTH_MESSAGE.tooManyAttempts)) {
        updateAuthState({
          overlayLoading: false,
          uiState: AUTH_UI_STATE.blockedUser,
          unblockTime: err.unblockTime,
        })
        setIsLoading(false)
        return
      }
      updateAuthState({ snackBar: err })
    }

    updateAuthState({ overlayLoading: false })
    setIsLoading(false)
    setSendSms('resend')
  }

  return (
    <React.Fragment>
      <style jsx>{style}</style>
      <span className={Typo.body2} data-cy="auth__phone__verify__info">
        {t('We have sent a verification code to')}
        <div
          className={Typo.subtitle2}
          data-cy="auth__phone__verify__account__info"
        >
          {dataPhone}.
        </div>
        {t('This code will expire in 10 minutes.')}
      </span>
      <form
        autoComplete="off"
        className="auth__phone-verify"
        onSubmit={handleSubmit(handleFormSubmit)}
      >
        {INPUT_NAMES.map((name, idx) => (
          <TextField
            className="code-field"
            error={!!errors[name] || customError.isError}
            fieldId={`auth__phone__verify__code__field_${idx}`}
            inputRef={(ref) => {
              inputsRef.current[idx + 1] = ref
              register({
                required: t('Required'),
                pattern: {
                  value: NUMBER_ONLY_REGEX,
                  message: 'error',
                },
              })(ref)
            }}
            key={name}
            maxLength={1}
            name={name}
            inputProps={{
              onFocus,
              onInput,
              onKeyDown,
            }}
            type="text"
          />
        ))}
        <div className={clsx(Typo.caption, 'error-msg')}>
          {customError.type === 'invalid' &&
            hasLocizeTranslation(
              t,
              'VERIFICATION_CODE_INVALID',
              'Incorrect verification code. Please try again',
            )}
          {customError.type === 'expired' &&
            hasLocizeTranslation(
              t,
              'VERIFICATION_CODE_EXPIRED',
              'Your code has expired. Please resend the code',
            )}
        </div>
        <Button
          cy="auth__phone__verify__button"
          className="auth__standalone-cta full-width verify-cta"
          disabled={!isValid || isLoading}
          htmlType={BUTTON_HTML_TYPE.submit}
          type={BUTTON_TYPES.primary}
        >
          {t('Verify')}
        </Button>
        <FormItem className="auth-phone__resend-cta">
          {isRunning ? (
            <p
              className={clsx(
                Typo.subtitle1,
                'auth-phone__resend-cta-cooldown',
              )}
              data-cy="auth__phone__verify__resend_count"
            >
              {`${hasLocizeTranslation(
                t,
                'RESEND_VERIFICATION_CODE_IN',
                'Resend Verification Code In',
              )} 0:${seconds || 59}`}
            </p>
          ) : (
            <Button
              cy="auth__phone__verify__resend_button"
              onClick={resendVerificationCode}
              type={BUTTON_TYPES.link}
              disabled={isLoading}
            >
              {t('Resend Verification Code')}
            </Button>
          )}
        </FormItem>
        <p hidden>{sendSms}</p>
      </form>
    </React.Fragment>
  )
}

AuthVerify.defaultProps = {
  setIsMigrate: undefined,
}
AuthVerify.propTypes = {
  setIsMigrate: PropTypes.func,
  t: PropTypes.func.isRequired,
}

export default withI18next()(AuthVerify)
