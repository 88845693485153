import clsx from 'clsx'
import PropTypes from 'prop-types'
import React from 'react'
import Icon from 'components/common/icon'
import { ICON_SIZE } from 'components/common/icon/const'
import Typo from 'constants/typography'
import { withI18next } from 'lib/i18n/withI18next'
import { hasLocizeTranslation } from 'lib/utils/locize'

const HoolahInfo = ({ getClass, step, t }) => (
  <div className={getClass('info')}>
    <div className={getClass('info-icon')}>
      <Icon src={step.icon} size={ICON_SIZE.large} />
    </div>
    <div className={getClass('info-container')}>
      <span className={clsx('title', Typo.subtitle2)}>
        {hasLocizeTranslation(t, step.title.key, step.title.value)}
      </span>
      <span className={clsx('desc', Typo.body2)}>
        {hasLocizeTranslation(t, step.description.key, step.description.value)}
      </span>
    </div>
  </div>
)

HoolahInfo.propTypes = {
  getClass: PropTypes.func.isRequired,
  step: PropTypes.shape({
    icon: PropTypes.string.isRequired,
    title: PropTypes.shape({
      key: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
    description: PropTypes.shape({
      key: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  }).isRequired,
  t: PropTypes.func.isRequired,
}

const Extended = withI18next()(HoolahInfo)

export default Extended
