import clsx from 'clsx'
import React from 'react'
import PropTypes from 'prop-types'
import Router from 'next/router'
import styles from './style.scss'

const Banner = ({ className, children, link }) => {
  const handleOnClick = (e) => {
    e?.preventDefault()
    if (link) {
      Router.push(link)
    }
  }

  return (
    <div
      className={clsx('banner', className)}
      onClick={handleOnClick}
      role="presentation"
    >
      <style jsx>{styles}</style>
      {children}
    </div>
  )
}

Banner.defaultProps = {
  children: undefined,
  link: undefined,
  className: undefined,
}

Banner.propTypes = {
  children: PropTypes.shape({}),
  link: PropTypes.string,
  className: PropTypes.string,
}

export default Banner
