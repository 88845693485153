import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import Button from 'components/common/button'
import { BUTTON_TYPES } from 'constants/button'
import {
  getVoucherCheckoutButtonText,
  isApplied,
} from 'components/pdp/carousel-images/voucher/voucher-checkout/utils'
import { withI18next } from 'lib/i18n/withI18next'

const VoucherButton = forwardRef(
  (
    {
      appliedVoucher,
      code,
      copyCode,
      isCheckout,
      isNotApplicable,
      onVoucherSelected,
      t,
    },
    ref,
  ) => {
    const applyButtonText = getVoucherCheckoutButtonText(code, appliedVoucher)
    const isDisabled = isNotApplicable || isApplied(code, appliedVoucher)

    return (
      <Button
        className={clsx('pdp__voucher-modal__piece-cta', {
          'non-eligible': isNotApplicable,
        })}
        type={BUTTON_TYPES.link}
        onClick={isCheckout ? () => onVoucherSelected(code) : copyCode}
        ref={ref}
        disabled={isDisabled}
      >
        {t(isCheckout ? applyButtonText : 'COPY')}
      </Button>
    )
  },
)

VoucherButton.defaultProps = {
  onVoucherSelected: undefined,
}

VoucherButton.propTypes = {
  appliedVoucher: PropTypes.string.isRequired,
  code: PropTypes.string.isRequired,
  copyCode: PropTypes.func.isRequired,
  isCheckout: PropTypes.bool.isRequired,
  isNotApplicable: PropTypes.bool.isRequired,
  onVoucherSelected: PropTypes.func,
  t: PropTypes.func.isRequired,
}

export default withI18next()(VoucherButton)
