import { getAssetPath } from 'lib/utils/common/commonUtils'

const ICON_PREFIX = `${getAssetPath()}/static/img/icons/`

const MOBILE_BANKING_LIST = [
  {
    id: 'omise_mb_scb',
    iconSrc: `${ICON_PREFIX}checkout/mobile-bank-list/scb.svg`,
    name: 'SCB Easy App',
  },
  {
    id: 'omise_mb_kbank',
    iconSrc: `${ICON_PREFIX}checkout/mobile-bank-list/kbank.svg`,
    name: 'K PLUS',
  },
  {
    id: 'omise_mb_bbl',
    iconSrc: `${ICON_PREFIX}checkout/mobile-bank-list/bbl.svg`,
    name: 'Bualuang mBanking',
  },
  {
    id: 'omise_mb_bay',
    iconSrc: `${ICON_PREFIX}checkout/mobile-bank-list/kma.svg`,
    name: 'Krungsri Mobile App (KMA)',
  },
  {
    id: 'adyen_mb_fpx_mb2u',
    iconSrc: `${ICON_PREFIX}checkout/mobile-bank-list/maybank.svg`,
    name: 'Maybank',
  },
  {
    id: 'adyen_mb_fpx_cimbclicks',
    iconSrc: `${ICON_PREFIX}checkout/mobile-bank-list/cimb.svg`,
    name: 'CIMB Bank',
  },
  {
    id: 'adyen_mb_fpx_pbb',
    iconSrc: `${ICON_PREFIX}checkout/mobile-bank-list/publicbank.svg`,
    name: 'Public Bank',
  },
  {
    id: 'adyen_mb_fpx_rhb',
    iconSrc: `${ICON_PREFIX}checkout/mobile-bank-list/rhb.svg`,
    name: 'RHB Bank',
  },
  {
    id: 'adyen_mb_fpx_hlb',
    iconSrc: `${ICON_PREFIX}checkout/mobile-bank-list/hong-leong.svg`,
    name: 'Hong Leong Bank',
  },
]

export const MOBILE_BANKING_TYPE = [
  'omise_mb_scb',
  'omise_mb_kbank',
  'omise_mb_bbl',
  'omise_mb_bay',
  'adyen_mb_fpx_mb2u',
  'adyen_mb_fpx_cimbclicks',
  'adyen_mb_fpx_pbb',
  'adyen_mb_fpx_rhb',
  'adyen_mb_fpx_hlb',
]

export default MOBILE_BANKING_LIST
