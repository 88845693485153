import PropTypes from 'prop-types'
import React from 'react'
import Icon from 'components/common/icon'
import { ICON_SIZE } from 'components/common/icon/const'
import { withI18next } from 'lib/i18n/withI18next'
import LoyaltyUSPFooter from '../../loyalty-usp-footer'
import UspText from '../usp-text'

const UspBody = ({ footerItemByOrder }) =>
  Object.keys(footerItemByOrder).map((item, index) => {
    if (footerItemByOrder[item].shouldRender) {
      if (item === 'loyalty') {
        return (
          <li key={index}>
            <LoyaltyUSPFooter />
          </li>
        )
      }

      return (
        <li key={index}>
          <Icon
            src={footerItemByOrder[item].iconSrc}
            size={ICON_SIZE.medium}
            lazyThreshold={1}
          />
          <UspText footerItem={footerItemByOrder[item]} />
        </li>
      )
    }

    return null
  })

UspBody.propTypes = {
  footerItemByOrder: PropTypes.shape({}).isRequired,
}

export default withI18next()(UspBody)
