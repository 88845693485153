import clsx from 'clsx'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { DesktopMenu, MobileHamburgerMenu } from '../utils'

const NavigationMenu = ({ isPhone, isSearchBarActive }) => {
  if (isPhone) {
    return (
      <div className="header-section mobile-menu">
        <MobileHamburgerMenu />
      </div>
    )
  }

  return (
    <div
      className={clsx('header-section desktop-menu', {
        'search-bar-active': isSearchBarActive,
      })}
    >
      <DesktopMenu />
    </div>
  )
}

NavigationMenu.defaultProps = {
  isPhone: false,
  isSearchBarActive: false,
}

NavigationMenu.propTypes = {
  isPhone: PropTypes.bool,
  isSearchBarActive: PropTypes.bool,
}

export default connect((state) => ({
  isPhone: state.device.isPhone,
}))(NavigationMenu)
