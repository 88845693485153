import PropTypes from 'prop-types'
import React, { Fragment } from 'react'
import styles from './style.scss'

const ToggleSwitch = ({ disabled, checked, onChange }) => (
  <Fragment>
    <style jsx>{styles}</style>
    <div className="toggle-switch__wrapper">
      <input
        type="checkbox"
        checked={checked}
        className="toggle-switch"
        disabled={disabled}
        onChange={onChange}
        data-cy="pmlo-toggle-switch"
      />
    </div>
  </Fragment>
)

ToggleSwitch.defaultProps = {
  disabled: false,
  checked: false,
  onChange: null,
}

ToggleSwitch.propTypes = {
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
}

export default ToggleSwitch
