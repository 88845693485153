import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { createPortal } from 'react-dom'

const Portal = ({ children }) => {
  const [isReady, setIsReady] = useState(false)

  // to support SSR, and animation on componentWillUnmount
  useEffect(() => {
    setIsReady(true)
  }, [])

  if (!isReady) {
    return null
  }

  return createPortal(
    children,
    document.getElementById('modal') || document.body,
  )
}

Portal.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Portal
