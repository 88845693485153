import { useMemo } from 'react'
import { getAuthStatus } from 'lib/utils/common/commonUtils'

function useUSPLoyalty({ auth, prefix = 'PDP' }) {
  const authStatus = getAuthStatus(auth)
  const isReady = authStatus.isFetched
  const isLoggedIn = authStatus.isFetched && !authStatus.isGuest
  const isLoyaltySignedUp = authStatus.isFetched && authStatus.isLoyaltyMember

  const usp = useMemo(() => {
    if (!isLoggedIn) {
      return {
        title: {
          key: `${prefix}_USP_LOYALTY_GUEST`,
          val: 'Sign up and get Pomelo Perks benefits',
        },
        description: {
          key: `${prefix}_USP_LOYALTY_GUEST_DESC`,
          val: 'Up to 5% order value back for loyalty members on app',
        },
        showCta: true,
      }
    }
    return isLoyaltySignedUp
      ? {
          title: {
            key: `${prefix}_USP_LOYALTY_MEMBER`,
            val: 'Enjoy getting 5% back to your order',
          },
          description: {
            key: `${prefix}_USP_LOYALTY_MEMBER_DESC`,
            val: 'Earn 5% Cashback on all orders',
          },
        }
      : {
          title: {
            key: `${prefix}_USP_LOYALTY_NON_MEMBER`,
            val: 'Join Pomelo Perks for more benefits',
          },
          description: {
            key: `${prefix}_USP_LOYALTY_NON_MEMBER_DESC`,
            val: 'Earn 5% Cashback on all orders',
          },
          showCta: true,
        }
  }, [isLoyaltySignedUp, isLoggedIn, prefix])

  return {
    usp,
    isLoggedIn,
    isLoyaltySignedUp,
    isReady,
  }
}

export default useUSPLoyalty
