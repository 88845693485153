const { SHOP_LOCALES } = require('../../constants/shop-language')
const env = require('../../config/env')

const dev = process.env.NODE_ENV !== 'production'
const debug = process.env.NODE_ENV === 'debug'

/**
 * Default i18n configs: https://www.i18next.com/overview/configuration-options
 * Extended configs from Node BE: https://github.com/i18next/i18next-node-remote-backend
 */
const i18nOptions = {
  ns: ['global'],
  debug: dev && debug,
  defaultNS: ['global'],
  preload: SHOP_LOCALES,
  load: 'currentOnly',
  lowerCaseLng: true,
  fallbackLng: ['en-th'],
  fallbackNS: false,
  saveMissing: false,
  nsSeparator: ':::',
  keySeparator: '::',
  detection: {
    order: ['cookie'],
    caches: ['cookie'],
    lookupCookie: 'pomeloLang',
  },
  backend: {
    loadPath: `${env.API_HOST}v6/i18n/strings?language={{lng}}`,
  },
  react: {
    wait: false,
    withRef: false,
    bindI18n: 'languageChanged',
    bindStore: 'added removed',
    nsMode: 'default',
  },
}

module.exports = i18nOptions
