import env from 'config/env'

export const ADS_BANNER_BUTTON = {
  key: 'ADS_BANNER_BUTTON',
  text: 'Download APP!',
}

export const ADS_BANNER_TITLE = {
  isTitle: true,
  key: 'ADS_BANNER_TITLE',
  text: 'DOWNLOAD THE APP & GET 15% OFF NO MIN. SPEND!',
}

export const ADS_BANNER_SUB_TITLE = {
  key: 'ADS_BANNER_SUB_TITLE',
  text: 'On your 1st order + Join Pomelo Perks* & earn 10% Cashback.',
}

export const ADS_BANNER_TITLE_PURCHASED_CUSTOMER = {
  isTitle: true,
  key: 'ADS_BANNER_TITLE_PURCHASED_CUSTOMER',
  text: 'DOWNLOAD THE APP TO UNLOCK MORE OFFERS!',
}

export const ADS_BANNER_SUB_TITLE_PURCHASED_CUSTOMER = {
  key: 'ADS_BANNER_SUB_TITLE_PURCHASED_CUSTOMER',
  text: 'Find best styles, brands, and deals at your fingertips + try on for free, pay later (TTB for applicable shops)',
}

export const getAdsBannerContent = (isPurchasedCustomer) =>
  isPurchasedCustomer
    ? [
        ADS_BANNER_TITLE_PURCHASED_CUSTOMER,
        ADS_BANNER_SUB_TITLE_PURCHASED_CUSTOMER,
      ]
    : [ADS_BANNER_TITLE, ADS_BANNER_SUB_TITLE]

export const WEB_TO_APP = {
  imgRatio: 1,
  src: `${env.IMG_HOST}img/logos/web-to-app.jpg`,
}
