import React from 'react'
import PropTypes from 'prop-types'
import Typo from 'constants/typography'
import styles from './styles.scss'

const ProductDiscountBadge = ({ percentage }) => {
  if (!percentage) {
    return null
  }

  return (
    <div className="product__discount-badge">
      <style jsx>{styles}</style>
      <span className={Typo.price2}>{percentage}</span>
    </div>
  )
}

ProductDiscountBadge.defaultProps = {
  percentage: null,
}

ProductDiscountBadge.propTypes = {
  percentage: PropTypes.string,
}

export default ProductDiscountBadge
