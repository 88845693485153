import clsx from 'clsx'
import React from 'react'
import Router from 'next/router'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { v4 as uuid } from 'uuid'
import { withI18next } from 'lib/i18n/withI18next'
// noinspection ES6CheckImport
import cartDuck from 'components/cart/duck'
import Typo from 'constants/typography'
import modalDuck from 'components/modal/duck'
import {
  CHECKOUT_SUB_STEPS,
  getCartParams,
} from 'lib/utils/checkout/checkoutUtils'
import cartItemsDuck from 'components/cart/cart-items/duck'
import checkoutDuck from 'components/checkout/duck'
import { updateLagacyShoppingTrack } from 'lib/auth'
import { segmentProductRemoved } from 'lib/segment'
import { SEGMENT_STORAGE_KEY_PRODUCT } from 'lib/segment/const'
import { appendObjToSessionObj } from 'lib/utils/common/commonUtils'
import { getFreeGiftCondition } from 'components/cart/free-gift/utils'
import { getSoldOutProducts, getCartProductUrl } from '../utils'
import { CART_FREE_GIFT_MODAL_ID } from '../free-gift/const'
import CartProductName from './cart-product-name'
import CartProductInfo from './cart-product-info'
import CartProductSizeQuantity from './cart-product-size-quantity'
import CartProductPrice from './cart-product-price'
import CartProductImageContainer from './cart-product-image-container'
import CartMoveToWishlistButton from './move-to-wishlist'
import CartProductNoted from './noted'
import styles from './styles.scss'

const CartProduct = ({
  addToCart,
  allowProductUpdate,
  applyStoreCredit,
  checkoutModel,
  cartProducts,
  checkSoldOutItem,
  couponUsed,
  fromSummary,
  invalidateCart,
  isProductUpdated,
  product,
  refType,
  removeFromCart,
  setError,
  selectedAttribute,
  showFallbackModal,
  isShoppingBagNewDesign,
  showFreeGift,
  showMoveToBagCoachmark,
  updateCart,
  updateCartItem,
  updatePayment,
  t,
}) => {
  const isTTBOrder = checkoutModel?.shippingAddress?.method === 'PICKUP'
  const isFreeGift = product?.type === 'gift'

  const isSoldOut = () => {
    if (selectedAttribute?.stock.is_sold_out) {
      checkSoldOutItem({
        hasSoldOutItem: true,
      })
    }

    return selectedAttribute?.stock.is_sold_out
  }

  const openFreeGift = () => {
    showFreeGift()
    updateCartItem({
      cartOpen: true,
      freeGiftOpen: true,
      couponUsed,
      isStoreCreditUsed: applyStoreCredit,
    })
  }

  const onLinkClicked = (e) => {
    e.preventDefault()
    const pageUuid = uuid()
    const href = `/product?id=${product.id_product}&page_uuid=${pageUuid}`
    const as = getCartProductUrl(product)
    appendObjToSessionObj(SEGMENT_STORAGE_KEY_PRODUCT.stack, {
      [pageUuid]: {
        click_position: '',
        ref_id: '',
        ref_type: refType,
        sub_position: '',
      },
    })
    sessionStorage.setItem(SEGMENT_STORAGE_KEY_PRODUCT.id, '')
    sessionStorage.setItem(SEGMENT_STORAGE_KEY_PRODUCT.position, '')
    sessionStorage.setItem(SEGMENT_STORAGE_KEY_PRODUCT.type, refType)
    sessionStorage.setItem(SEGMENT_STORAGE_KEY_PRODUCT.subPos, '')
    updateCartItem({
      cartOpen: false,
      couponUsed,
      freeGiftOpen: false,
      isStoreCreditUsed: applyStoreCredit,
    })
    Router.push(href, as)
  }

  const updateCheckSoldOutItem = (products) => {
    const soldOutProduct = getSoldOutProducts(products)

    checkSoldOutItem({
      hasSoldOutItem: soldOutProduct?.length > 0 || false,
    })
  }

  const deleteFromCart = async () => {
    invalidateCart()
    // If we are deleting cart's items from checkout summary, we need to pass additional payload such as payment token and shipping token to recalculate fees correctly from cart view endpoint
    const res = await removeFromCart({
      body: fromSummary
        ? getCartParams(checkoutModel, applyStoreCredit).body
        : {
            is_store_credits_applied: applyStoreCredit,
          },
      productID: product.id_product,
      productAttributeID: selectedAttribute.id_product_attribute,
    })

    if (!res || res?.error) {
      showFallbackModal(res?.error || 'Something went wrong')
    } else {
      segmentProductRemoved(
        product.id_product,
        product.max_quantity,
        product.category.id_category,
        product.category.link_rewrite?.toLowerCase(),
      )
      if (!allowProductUpdate) {
        if (isProductUpdated) {
          isProductUpdated(true)
        }
        // TODO: check why need to update payment method when remove item from cart
        if (
          res.items.length > 0 &&
          res.summary.subtotal === 0 &&
          applyStoreCredit
        ) {
          updatePayment({
            paymentMethod: {
              method: CHECKOUT_SUB_STEPS.STORE_CREDITS.id,
              type: CHECKOUT_SUB_STEPS.STORE_CREDITS.id,
              name: CHECKOUT_SUB_STEPS.STORE_CREDITS.title,
            },
          })
        }
      }
      updateCheckSoldOutItem(res)
    }

    await updateLagacyShoppingTrack(res)
  }

  const commonProps = {
    product,
    selectedAttribute,
    isFreeGift,
    isSoldOut: isSoldOut(),
  }

  const productImageProps = {
    ...commonProps,
    deleteFromCart,
    openFreeGift,
    onLinkClicked,
  }

  const productSizeQuantityProps = {
    ...commonProps,
    addToCart,
    allowProductUpdate,
    applyStoreCredit,
    cartProducts,
    setError,
    updateCart,
    openFreeGift,
  }

  return isShoppingBagNewDesign ? (
    <div
      className={clsx('cart-product', 'new-design', {
        summary: fromSummary,
      })}
    >
      <style jsx>{styles}</style>
      <CartProductName
        {...commonProps}
        openFreeGift={openFreeGift}
        onLinkClicked={onLinkClicked}
        isShoppingBagNewDesign
      />
      <div className="product-info-section">
        <CartProductImageContainer
          {...productImageProps}
          isShoppingBagNewDesign
        />
        <div className="new-product-information">
          <CartProductPrice
            {...commonProps}
            fromSummary={fromSummary}
            isShoppingBagNewDesign
          />
          {isFreeGift && (
            <span className={clsx('free-gift-condition', Typo.caption)}>
              {getFreeGiftCondition(product.condition, t, false)}
            </span>
          )}
          <CartProductSizeQuantity
            {...productSizeQuantityProps}
            isShoppingBagNewDesign
          />
        </div>
      </div>
      <CartProductNoted
        product={product}
        selectedAttribute={selectedAttribute}
        fromSummary={fromSummary}
        isShoppingBagNewDesign
        isShowNoReturnNoted={!isTTBOrder}
      />
    </div>
  ) : (
    <div className="cart-product">
      <style jsx>{styles}</style>
      <CartProductImageContainer {...productImageProps} />
      <div className="product-information">
        <CartProductInfo
          {...commonProps}
          fromSummary={fromSummary}
          openFreeGift={openFreeGift}
          onLinkClicked={onLinkClicked}
        />
        <CartProductSizeQuantity
          {...productSizeQuantityProps}
          openFreeGift={openFreeGift}
        />
      </div>
      <CartMoveToWishlistButton
        product={product}
        showMoveToBagCoachmark={showMoveToBagCoachmark}
        deleteFromCart={deleteFromCart}
      />
      <CartProductNoted
        product={product}
        selectedAttribute={selectedAttribute}
        fromSummary={fromSummary}
        isShowNoReturnNoted={!isTTBOrder}
      />
    </div>
  )
}

CartProduct.defaultProps = {
  fromSummary: false,
  showFallbackModal: undefined,
  allowProductUpdate: undefined,
  applyStoreCredit: undefined,
  cartProducts: undefined,
  checkSoldOutItem: undefined,
  checkoutModel: undefined,
  couponUsed: undefined,
  internationalization: undefined,
  invalidateCart: undefined,
  isProductUpdated: undefined,
  selectedAttribute: undefined,
  showFreeGift: undefined,
  showMoveToBagCoachmark: false,
  updateCart: undefined,
  setError: undefined,
  updatePayment: undefined,
  product: undefined,
}

CartProduct.propTypes = {
  addToCart: PropTypes.func.isRequired,
  allowProductUpdate: PropTypes.bool,
  applyStoreCredit: PropTypes.bool,
  cartProducts: PropTypes.shape({
    eligibility: PropTypes.shape({
      free_gift: PropTypes.shape({ has_multiple: PropTypes.bool }),
    }),
    items: PropTypes.arrayOf(PropTypes.shape({})),
    summary: PropTypes.shape({
      store_credits: PropTypes.shape({}),
      ttb_fee: PropTypes.string,
    }),
  }),
  checkSoldOutItem: PropTypes.func,
  checkoutModel: PropTypes.shape({
    shippingAddress: PropTypes.shape({
      method: PropTypes.string,
    }),
  }),
  couponUsed: PropTypes.bool,
  fromSummary: PropTypes.bool,
  internationalization: PropTypes.shape({}),
  invalidateCart: PropTypes.func,
  isProductUpdated: PropTypes.func,
  product: PropTypes.shape({
    id_product: PropTypes.number,
    attributes: PropTypes.arrayOf(PropTypes.shape({})),
    name: PropTypes.string,
    category: PropTypes.shape({
      id_category: PropTypes.number,
      link_rewrite: PropTypes.string,
    }),
    condition: PropTypes.shape({
      minimum_amount: PropTypes.shape({
        minimum_amount: PropTypes.number,
      }),
      minimum_item: PropTypes.shape({
        minimum_amount: PropTypes.number,
      }),
    }),
    max_quantity: PropTypes.number,
    type: PropTypes.string,
    prices: PropTypes.shape({
      original_price: PropTypes.number,
      original_price_formatted: PropTypes.string,
      discounted_price_formatted: PropTypes.string,
      discounted_price: PropTypes.number,
    }),
    is_available: PropTypes.bool,
    is_sale: PropTypes.bool,
    quantity: PropTypes.number,
    hyphen_name: PropTypes.string,
    image: PropTypes.shape({
      path: PropTypes.string,
      full_path: PropTypes.string,
    }),
  }),
  refType: PropTypes.string.isRequired,
  removeFromCart: PropTypes.func.isRequired,
  selectedAttribute: PropTypes.shape({
    id_product_attribute: PropTypes.number,
    size: PropTypes.string,
    selected: PropTypes.bool,
    stock: PropTypes.shape({
      quantity: PropTypes.number,
      is_low_quantity: PropTypes.bool.isRequired,
      is_sold_out: PropTypes.bool.isRequired,
    }),
  }),
  setError: PropTypes.func,
  showFallbackModal: PropTypes.func,
  isShoppingBagNewDesign: PropTypes.bool.isRequired,
  showFreeGift: PropTypes.func,
  showMoveToBagCoachmark: PropTypes.bool,
  updateCart: PropTypes.func,
  updateCartItem: PropTypes.func.isRequired,
  updatePayment: PropTypes.func,
  t: PropTypes.func.isRequired,
}

export { CartProduct }

export default compose(
  connect(
    (state) => ({
      isPhone: state.device.isPhone,
      cartProducts: state.cart.payload,
      internationalization: state.internationalization,
      checkoutModel: state.checkout,
      applyStoreCredit: state.cartData.isStoreCreditUsed,
      couponUsed: state.cartData.couponUsed,
    }),
    (dispatch) =>
      bindActionCreators(
        {
          addToCart: (options) =>
            cartDuck.creators.get({ ...options, type: 'AddToCart' }),
          updateCart: (options) =>
            cartDuck.creators.get({ ...options, type: 'UpdateCart' }),
          removeFromCart: (options) =>
            cartDuck.creators.get({ ...options, type: 'DeleteFromCart' }),
          invalidateCart: cartDuck.creators.invalidate,
          showFreeGift: () => modalDuck.creators.show(CART_FREE_GIFT_MODAL_ID),
          checkSoldOutItem: cartItemsDuck.creators.checkSoldOutItem,
          updatePayment: checkoutDuck.creators.updatePayment,
          updateCartItem: cartItemsDuck.creators.update,
        },
        dispatch,
      ),
  ),
  withI18next(),
)(CartProduct)
