import { request } from 'lib/api'
import { HTTP_METHODS } from './const'

class UserInfo {
  static handleFetch(options) {
    switch (options.type) {
      case 'UPDATE_INFO':
        return UserInfo.updateCurrentUserInfo(options)
      default:
        return UserInfo.getCurrentUserInfo()
    }
  }

  static getCurrentUserInfo() {
    return request({
      method: HTTP_METHODS.get,
      url: 'v6/customers/current',
    })
  }

  static updateCurrentUserInfo(options) {
    const { type, ...body } = options
    return request({
      method: HTTP_METHODS.patch,
      url: 'v6/customers/current',
      body,
    })
  }

  static unsubscribe({ customer_id, email, ...rest }) {
    const options = {}

    if (customer_id) {
      options.customer_id = parseInt(customer_id, 10)
    } else if (email) {
      options.email = email
    }

    const body = {
      ...options,
      ...rest,
    }

    return request({
      body,
      method: HTTP_METHODS.post,
      url: 'v6/subscriptions/emails/unsubscribe',
      stringify: true,
    })
  }

  static getUserSubscription({ name, value }) {
    return request({
      url: 'v6/subscriptions/emails',
      params: {
        [name]: value,
      },
    })
  }
}

export default UserInfo
