import { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import { BUTTON_TYPES } from 'constants/button'
import Typo from 'constants/typography'
import ModalNoReturn from 'components/modal/no-return'
import Button from 'components/common/button'
import { withI18next } from 'lib/i18n/withI18next'
import { hasLocizeTranslation } from 'lib/utils/locize'
import styles from './styles.scss'

/**
 *
 * @param {boolean} param0.showCta set to display action button
 */
const NoReturnNoted = ({ showCta, t }) => {
  const [showNoReturn, setShowNoReturn] = useState()

  const handleClick = () => setShowNoReturn((prevState) => !prevState)

  return (
    <Fragment>
      <style jsx>{styles}</style>
      <Button
        className="no-return-noted__cta"
        type={BUTTON_TYPES.text}
        onClick={handleClick}
      >
        <span className={Typo.caption}>
          {hasLocizeTranslation(
            t,
            'NOT_ALLOW_RETURN_PRODUCT_LABEL',
            'Item Non-eligible for Return',
          )}
        </span>
      </Button>
      <ModalNoReturn
        open={showNoReturn}
        onBackdropClick={handleClick}
        showCta={showCta}
      />
    </Fragment>
  )
}

NoReturnNoted.defaultProps = {
  showCta: undefined,
  t: undefined,
}

NoReturnNoted.propTypes = {
  showCta: PropTypes.bool,
  t: PropTypes.func,
}

export default withI18next()(NoReturnNoted)
