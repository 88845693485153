import PropTypes from 'prop-types'
import React, { useRef, useState } from 'react'
import { withI18next } from 'lib/i18n/withI18next'
import { hasLocizeTranslation } from 'lib/utils/locize'
import Icon from 'components/common/icon'
import ICONS, { ICON_SIZE } from 'components/common/icon/const'
import Coachmark from 'components/common/coachmark'
import { COACHMARK_POSITION } from 'components/common/coachmark/const'
import { redirectToWishlistPage } from 'components/cart/utils'
import { useFlagsmithGetFlagValue } from 'lib/flagsmith'
import {
  getIsCoachMarkClosed,
  saveClosedCoachMarkToStorage,
} from 'pages/product/utils'

const CartWishlistIcon = ({ closeCart, t, updateShowLogin }) => {
  const cartWishlistIconRef = useRef(null)
  const isCoachMarkClosed = getIsCoachMarkClosed(
    'cart_wishlist_coach_mark',
    true,
  )
  const [isOpenCoachmark, setIsOpenCoachmark] = useState(!isCoachMarkClosed)
  const saveForLaterFlagValue = useFlagsmithGetFlagValue(
    'SaveForLater_20230704',
    false,
  )

  if (!saveForLaterFlagValue) {
    return null
  }

  const saveCoachMarkClosed = () => {
    setIsOpenCoachmark(false)
    saveClosedCoachMarkToStorage('cart_wishlist_coach_mark', true)
  }

  return (
    <div>
      <Icon
        alt="wishlist-icon"
        src={ICONS.wishlist}
        className="svg-close"
        size={ICON_SIZE.medium}
        onClick={() => redirectToWishlistPage(closeCart, updateShowLogin)}
        cy="wishlist_cart"
      />
      <Coachmark
        onClose={saveCoachMarkClosed}
        position={COACHMARK_POSITION.TOP_RIGHT}
        text={hasLocizeTranslation(
          t,
          'CART_WISHLIST_ICON_COACHMARK',
          'Easy access to your wishlist here!',
        )}
        isOpen={isOpenCoachmark}
        customContainerProps={{
          style: {
            right: '-12px',
            top: '12px',
            width: '100%',
          },
        }}
        disableLeaveListener
        disableEnterListener
        customChildrenRef={cartWishlistIconRef}
      />
    </div>
  )
}

CartWishlistIcon.propTypes = {
  closeCart: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  updateShowLogin: PropTypes.func.isRequired,
}

export default withI18next()(CartWishlistIcon)
