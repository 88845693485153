import { createContext, useContext } from 'react'

function createStrictContext(name) {
  const Context = createContext()
  Context.displayName = name

  function useStrictContext() {
    const ctx = useContext(Context)
    if (ctx === undefined) {
      throw new Error(
        `use${name}Context must be used within a ${name}Provider.`,
      )
    }
    return ctx
  }

  return [Context.Provider, useStrictContext]
}

export default createStrictContext
