import Router from 'next/router'
import filterDuck from 'components/filter/filter-options/duck'
import visibilityDuck from 'components/visibility/duck'
import { segmentTrackProductListViewed } from 'lib/segment'
import {
  SEGMENT_STORAGE_KEY_CATEGORY,
  SEGMENT_STORAGE_KEY_IS_REFRESHED,
  SEGMENT_STORAGE_KEY_LAST_URL,
  SEGMENT_STORAGE_KEY_PRODUCT,
  SEGMENT_STORAGE_KEY_SSR,
} from 'lib/segment/const'
import Utils from 'lib/utils'
import StateStorage from 'lib/state-storage'
import { appendObjToSessionObj } from 'lib/utils/common/commonUtils'
import { getProductIDs } from 'lib/utils/handle-response'
import { getPathnameWithoutShopAndLang } from 'lib/utils/url'
import Tracking from 'lib/tracking'
// eslint-disable-next-line import/no-cycle
import { FETCH_PRODUCTS_DELAY, PRODUCTS_PER_PAGE } from './const'

export function getValidSortParam(sort) {
  if (sort) {
    const parsedSort = sort.toLowerCase()
    const validSort = [
      'popular',
      'new_in',
      'price_desc',
      'price_asc',
      'recommended',
      'trending',
      'best_sellers',
    ]

    if (validSort.includes(parsedSort)) {
      return parsedSort
    }
  }

  return ''
}

export function checkAndGetCacheResponse({
  asPath,
  categoryId,
  reduxCategory,
  reduxSort,
  setIsSameCategoryAsRedux,
  setPathsWereSubPath,
  sortQuery,
  store,
}) {
  const isSameCategoryAsRedux =
    reduxCategory.payload.link_rewrite === categoryId ||
    reduxCategory.payload.id_category?.toString() === categoryId?.toString()
  setIsSameCategoryAsRedux(isSameCategoryAsRedux)
  const hasSameSortQuery = !sortQuery || reduxSort.by === sortQuery

  if (isSameCategoryAsRedux && hasSameSortQuery) {
    /**
     * Hacky way to handle the following case.
     *
     * 1. Current url is /tops?category=filter1,filter2&brand=brand1,brand2
     * 2. The user navigate to /tops
     * 3. Old logic would assume no data changes. (In truth the filters has changed)
     *
     * This conditions will prevent incorrect cache logic.
     */
    const lastUrl = sessionStorage.getItem(SEGMENT_STORAGE_KEY_LAST_URL) || ''
    const tmp = lastUrl.substring(lastUrl.indexOf('//') + 2)
    const lastAsPath = getPathnameWithoutShopAndLang(
      tmp.substring(tmp.indexOf('/')),
    )
    const parsedAsPath = getPathnameWithoutShopAndLang(asPath)

    const pathsWereSubPath =
      lastAsPath &&
      parsedAsPath &&
      lastAsPath !== parsedAsPath &&
      (lastAsPath.startsWith(parsedAsPath) ||
        parsedAsPath.startsWith(lastAsPath))

    if (setPathsWereSubPath) {
      setPathsWereSubPath(pathsWereSubPath)
    }

    if (!pathsWereSubPath) {
      appendObjToSessionObj(SEGMENT_STORAGE_KEY_PRODUCT.stack, {
        [SEGMENT_STORAGE_KEY_PRODUCT.category_uuid]: true,
      })

      return true
    }

    // If it's the case of sub path, clear the filters data in the store.
    store.dispatch(filterDuck.creators.clean())
    store.dispatch(visibilityDuck.creators.clean())
  }

  return null
}

export function updateFilterStoreFromKey(
  { filters = [], store, key },
  transformer = (t) => t,
) {
  if (!key || !store) {
    throw new Error('Missing property needed.')
  }

  if (key === 'price') {
    store.dispatch(filterDuck.creators.updateRange({ id: key, value: filters }))
  } else {
    filters.forEach((filter) => {
      store.dispatch(
        filterDuck.creators.update({
          id: key,
          key: transformer(filter),
          value: transformer(filter),
        }),
      )
    })
  }
}

// on search page, that found lost data or incomplete data from categoriesQuery, categoryQuery via getInitialProps
// so, found category' filter from path instead of query via getInitialProps
function findCategoryFromPath(asPath) {
  const preparePath = decodeURI(asPath)
    .replace(/category=|&category=/gi, 'cutone')
    .replace(/&sort=|&price=|&color=/gi, 'cuttwo')
  const firstCutStringFromPath = preparePath?.split('cutone')[1]
  const categoryStringFromPath = firstCutStringFromPath?.split('cuttwo')[0]
  return categoryStringFromPath
}

function findCategoryFilter(
  categoryQuery,
  categoriesQuery,
  asPath,
  isSearch = false,
) {
  if (isSearch) {
    return findCategoryFromPath(asPath)?.split(',')
  }
  return categoryQuery
    ? [categoryQuery]
    : categoriesQuery?.split(' ').join('-').split(',')
}

// reduce complexity from getAndSaveFiltersFromQueryParams()
export function normalizeFilterQuery({
  sizeQuery,
  brandQuery,
  categoryQuery,
  categoriesQuery,
  priceQuery,
  colorQuery,
  asPath,
  isSearch = false,
}) {
  const sizes = sizeQuery?.split(',')
  const brands = brandQuery?.split(',')
  const categories = findCategoryFilter(
    categoryQuery,
    categoriesQuery,
    asPath,
    isSearch,
  )
  const colors = colorQuery?.split(',')
  const prices = priceQuery?.split('-')

  return [
    {
      id: 'size',
      values: sizes || [],
      activeKeys: sizes || [],
      transformer: (size) => (size === 'ONE SIZE' ? 'One Size' : size),
    },
    {
      id: 'brand',
      values: brands || [],
      activeKeys: brands || [],
    },
    {
      id: 'categories',
      values: categories || [],
      activeKeys: categories || [],
    },
    {
      id: 'color',
      values: colors || [],
      activeKeys: colors || [],
    },
    {
      id: 'price',
      values: prices || [],
      activeKeys: prices || [],
    },
  ]
}

export function updateFilterStore(filterConfigs, store) {
  filterConfigs
    .filter(({ values }) => Boolean(values))
    .forEach(({ id, values, transformer }) => {
      updateFilterStoreFromKey({ filters: values, store, key: id }, transformer)
    })
}

export function getAndSaveFiltersFromQueryParams({
  brandQuery,
  categoriesQuery,
  categoryQuery,
  colorQuery,
  priceQuery,
  reduxFilter,
  sizeQuery,
  store,
  asPath,
}) {
  if (
    [
      sizeQuery,
      brandQuery,
      categoryQuery,
      categoriesQuery,
      priceQuery,
      colorQuery,
    ].some(Boolean)
  ) {
    store.dispatch(filterDuck.creators.clean())

    const filterConfigs = normalizeFilterQuery({
      sizeQuery,
      brandQuery,
      categoryQuery,
      categoriesQuery,
      priceQuery,
      colorQuery,
      asPath,
    })

    updateFilterStore(filterConfigs, store)
  }
  const filterData = [...reduxFilter]

  return filterData
}

export const getQueryString = ({ filterData, sortData, viewportPageNo }) => {
  const queryParams = {}
  let subCategoryPath = ''

  const filterKeys = {
    size: 'filter_size',
    categories: 'category',
    brand: 'brand',
    brands: 'brand',
    price: 'price',
    color: 'color',
    sustainability: 'sustainability',
  }

  filterData
    .filter(({ values }) => Boolean(values.length))
    .forEach(({ activeKeys, id, values }) => {
      switch (id) {
        case 'categories':
          subCategoryPath = `/${values[0]}`
          queryParams[filterKeys[id]] = `${values.join(',')}`
          break
        case 'price':
          queryParams[filterKeys[id]] = `${values.join('-')}`
          break
        case 'sustainability':
        case 'size':
        case 'brand':
        case 'brands':
          queryParams[filterKeys[id]] = `${activeKeys.join(',')}`
          break
        default:
          queryParams[`${id}`] = `${values.join(',')}`
          break
      }
    })

  if (viewportPageNo && viewportPageNo > 1) {
    queryParams.page = viewportPageNo
  }

  queryParams.sort = sortData || 'popular'

  const queryString = Object.keys(queryParams)
    .map((key) => `${key}=${queryParams[key]}`)
    .join('&')

  return { queryString, subCategoryPath }
}

export function syncRoutePath({
  categoryId,
  categoryRewrite,
  filterData,
  setShouldRunGetInitialProps,
  sortData,
  viewportPageNo,
} = {}) {
  /**
   * Get the current prefix path of the category page.
   *
   * eg.  /th/en/clothes/tops/crop-tops
   *      /th/en/clothes/tops?category=crop-tops,activewear
   *
   * both links has pathPrefix of /th/en/clothes
   */
  const pathPrefix = Router.asPath.split('/').slice(0, 4).join('/')
  let textQuery = {}
  const { queryString, subCategoryPath } = getQueryString({
    filterData,
    sortData,
    viewportPageNo,
  })

  let hrefParams = `?id=${categoryId}`

  if (subCategoryPath) {
    hrefParams += `&subcategory=${subCategoryPath.substr(1)}`
  }

  if (queryString) {
    hrefParams += `&${queryString}`
    textQuery = `?${queryString}`
  }

  /**
   * We don't want to run getInitialProps at this time (the route is updated).
   * But we need it to run in the future, to support browser's previous and forward navigation.
   */
  setShouldRunGetInitialProps(false)

  Router.replace(
    `/category${hrefParams}`,
    `${pathPrefix}/${categoryRewrite}${textQuery}`,
  )
}

export function trackProductListViewedWithRef({
  categoryId,
  categoryName,
  filter,
  pageNo,
  productIds,
  sort,
}) {
  const click_position = sessionStorage.getItem(
    SEGMENT_STORAGE_KEY_CATEGORY.position,
  )
  const ref_id = sessionStorage.getItem(SEGMENT_STORAGE_KEY_CATEGORY.id)
  const ref_type = sessionStorage.getItem(SEGMENT_STORAGE_KEY_CATEGORY.type)
  const sub_position = sessionStorage.getItem(
    SEGMENT_STORAGE_KEY_CATEGORY.subPos,
  )
  const ref_layout = 'list'

  const isSSRTracked = sessionStorage.getItem(SEGMENT_STORAGE_KEY_SSR)
  let ref = { ref_type: 'external' }

  if (ref_type === 'no_track') {
    return
  }

  // Track for SSR
  if (!ref_type) {
    const urlParams = new URLSearchParams(window.location.search)
    const paramRefId = urlParams.get('ref_id')
    const paramRefType = urlParams.get('ref_type') || 'external'
    const paramClickPosition = urlParams.get('click_position')
    const paramSubPosition = urlParams.get('sub_position')

    if (
      !isSSRTracked &&
      sessionStorage.getItem(SEGMENT_STORAGE_KEY_IS_REFRESHED)
    ) {
      sessionStorage.removeItem(SEGMENT_STORAGE_KEY_IS_REFRESHED)
      ref.refresh = true
    }

    if (paramRefId) {
      ref.ref_id = paramRefId
    }

    if (paramRefType) {
      ref.ref_type = paramRefType
      sessionStorage.setItem(SEGMENT_STORAGE_KEY_CATEGORY.type, paramRefType)
    }

    if (paramClickPosition) {
      ref.click_position = paramClickPosition
    }

    if (paramSubPosition) {
      ref.sub_position = paramSubPosition
    }
  } else {
    ref = {
      click_position,
      ref_id,
      ref_type,
      sub_position,
      ref_layout,
    }
  }

  segmentTrackProductListViewed(
    {
      active_filters: filter,
      id_site_category: categoryId,
      site_category_name: categoryName,
      page_number: pageNo,
      product_list_ids: productIds,
      sort_by: sort ? sort.by : '',
    },
    ref,
  )
  sessionStorage.setItem(SEGMENT_STORAGE_KEY_SSR, '1')
}

function getSubCategoryProductIds(subCategories) {
  if (!subCategories || subCategories.length <= 0) {
    return []
  }

  return subCategories.reduce((sum, subCategory) => {
    if (!subCategory.products) {
      return sum
    }
    const result = [
      ...sum,
      ...subCategory.products.map((prod) => prod.id_product),
    ]
    return result
  }, [])
}

export function trackCategoryPage({
  category,
  categoryProducts,
  filter,
  internationalization,
  isPhone,
  newHref,
  pageNo,
  sort,
  trackPageView = true,
}) {
  if (!categoryProducts) {
    return
  }

  const { products, sub_categories } = categoryProducts

  const initialProducts = products?.length
    ? getProductIDs(products, false)
    : getSubCategoryProductIds(sub_categories)
  const categoryId = category?.id_category || 0

  appendObjToSessionObj(SEGMENT_STORAGE_KEY_PRODUCT.stack, {
    [SEGMENT_STORAGE_KEY_PRODUCT.category_uuid]: true,
  })

  trackProductListViewedWithRef({
    categoryId,
    categoryName: category?.name || '',
    filter,
    pageNo,
    productIds: initialProducts,
    sort,
  })

  /**
   * Prevent tracking same page that can occur from filtering with query params.
   *
   * eg. /clothes/tops/crop-tops?brand=alita,pomelo => /clothes/tops
   */
  if (trackPageView) {
    const shop = StateStorage.getShop()

    Tracking.trackPageView(newHref || window.location.href, {
      'criteo-mobile-or-desktop': isPhone ? 'm' : 'd',
      country: internationalization?.country || shop,
    })
  }

  Tracking.trackEvent(Tracking.EVENT_NAME_VIEW_CONTENT_CATEGORY, {
    'view-content-category': 'category',
    'view-content-category-id': categoryId,
    'criteo-view-content-category': getProductIDs(products, true),
  })
}

export function parseProductPageNo(page) {
  const parsedPage = parseInt(page, 10)

  if (Number.isNaN(parsedPage)) {
    return 1
  }

  return parsedPage
}

export function extractTabsID(tabList) {
  return tabList.reduce((ids, currentTab) => {
    if (currentTab?.showOnCategoryID) {
      return Array.from(new Set([...ids, ...currentTab.showOnCategoryID]))
    }
    return [...ids, currentTab.categoryId]
  }, [])
}

const getRefForTrackProductListViewed = () => {
  const ref_type = sessionStorage.getItem(SEGMENT_STORAGE_KEY_CATEGORY.type)
  const ref_id = sessionStorage.getItem(SEGMENT_STORAGE_KEY_CATEGORY.id)
  const click_position = sessionStorage.getItem(
    SEGMENT_STORAGE_KEY_CATEGORY.position,
  )
  const sub_position = sessionStorage.getItem(
    SEGMENT_STORAGE_KEY_CATEGORY.subPos,
  )
  const ref_layout = 'list'

  return {
    ref_id,
    click_position,
    ref_type,
    sub_position,
    ref_layout,
  }
}

// reduce complexity from loadMoreProducts
const checkForSendSegmentTrackProductListViewed = (res, params) => {
  const { setApiResError, filter, categoryId, category, pageToSkip, sort } =
    params

  if (res?.error) {
    setApiResError(true)
  } else if (res?.payload?.products?.length) {
    segmentTrackProductListViewed(
      {
        active_filters: filter,
        id_site_category: categoryId,
        site_category_name: category.name || '',
        page_number: pageToSkip + 1,
        product_list_ids: getProductIDs(res.payload.products, false),
        sort_by: sort.by,
      },
      { ...getRefForTrackProductListViewed() },
    )
  }
}

export const loadMoreProductsUtil = async ({
  addCategoryProducts,
  category,
  categoryProducts,
  filter,
  internationalization,
  user_uid,
  segment_anonymous_id,
  sort,
  startPageNo,
  setIsFetchingProducts,
  setApiResError,
}) => {
  const { products } = categoryProducts || {}
  const { currency, shop } = internationalization || {}

  if (category && category.id_category) {
    const categoryId = category.id_category
    let pageToSkip = startPageNo - 1

    if (products && products.length) {
      pageToSkip += Math.ceil(products.length / PRODUCTS_PER_PAGE)
    }

    setIsFetchingProducts(true)

    const res = await addCategoryProducts(
      {
        categoryId,
        currencyId: currency,
        filter,
        isGuestMode: StateStorage.isGuestMode(),
        limit: PRODUCTS_PER_PAGE,
        segmentAnonymousID: segment_anonymous_id,
        shopId: shop,
        skip: pageToSkip * PRODUCTS_PER_PAGE,
        sort,
        userUId: user_uid,
      },
      'products',
    )

    setTimeout(() => {
      setIsFetchingProducts(false)
    }, FETCH_PRODUCTS_DELAY)

    checkForSendSegmentTrackProductListViewed(res, {
      setApiResError,
      filter,
      categoryId,
      category,
      pageToSkip,
      sort,
    })
  }
}

export function getBreadcrumbSchema(category) {
  const { name, link_rewrite } = category
  const breadcrumbSchema = `{
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      "itemListElement": {
        "@type": "ListItem",
        "position": 1,
        "name": "${name}",
        "item": {
          "id": "https://www.pomelofashion.com/clothes/${link_rewrite}", 
          "type": "WebPage"
        }
      }
    }`

  return breadcrumbSchema
}

/**
 * @param {object} filters
 * @param {boolean} isSearchPage
 * @returns {boolean}
 */
export const isNewCategoryFiltersAndSort = (filters, isSearchPage = false) =>
  Utils.isArray(filters) && !isSearchPage

/**

 * @param {object} category
 * @param {object} filters 
 * @returns {object} 
 */
export const getSortFilterOptions = (category, filters) =>
  isNewCategoryFiltersAndSort(category?.filters)
    ? { sort: category?.sort }
    : filters
