export const VOUCHER_CHECKOUT_LOCIZE = {
  VOUCHER_CHECKOUT_ADD: {
    key: 'VOUCHER_CHECKOUT_ADD',
    text: 'Add Voucher',
  },
  VOUCHER_CHECKOUT_HEADER: {
    key: 'VOUCHER_CHECKOUT_HEADER',
    text: 'Vouchers',
  },
  VOUCHER_CHECKOUT_FORYOU: {
    key: 'VOUCHER_CHECKOUT_FORYOU',
    text: 'Vouchers for you',
  },
  VOUCHER_CHECKOUT_PARTNER: {
    key: 'VOUCHER_CHECKOUT_PARTNER',
    text: 'Partner offers',
  },
  VOUCHER_CHECKOUT_EMPTY: {
    key: 'VOUCHER_CHECKOUT_EMPTY',
    text: 'No vouchers found',
  },
  VOUCHER_CHECKOUT_VALID_DATE: {
    key: 'VOUCHER_CHECKOUT_VALID_DATE',
    text: 'Valid until %s',
  },
  VOUCHER_BADGE_HOT_DEAL: {
    key: 'VOUCHER_BADGE_HOT_DEAL',
    text: 'Hot deal',
  },
  VOUCHER_CHECKOUT_MORE_ITEM_DESC: {
    key: 'VOUCHER_CHECKOUT_MORE_ITEM_DESC',
    text: 'Add %d more items to get %s off',
  },
  VOUCHER_CHECKOUT_MORE_SPEND_DESC: {
    key: 'VOUCHER_CHECKOUT_MORE_SPEND_DESC',
    text: 'You need %d to reach min. spend',
  },
  VOUCHER_CHECKOUT_COF_MORE_ITEM_DESC: {
    key: 'VOUCHER_CHECKOUT_COF_MORE_ITEM_DESC',
    text: 'Add %d more item(s) to get cheapest one free',
  },
}

export const VOUCHER_TABS = {
  VOUCHER_FORYOU: 'VOUCHER_FORYOU',
  PARTNER: 'PARTNER',
}

export const HOT_DEAL_BADGE = 'Hot deal'

export const LOGO_BADGE = 'Logo'

export const PARTNER_VOUCHER = 'Partner Offer'
