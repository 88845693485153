import env from 'config/env'
import { request } from './index'
import { HTTP_METHODS } from './const'

const getConfiguration = ({ name }) =>
  request({
    url: `v8/configuration`,
    method: HTTP_METHODS.get,
    baseURL: env.NEXT_API_HOST,
    params: { name },
  })

export default getConfiguration
