import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { withI18next } from 'lib/i18n/withI18next'
import Icon from 'components/common/icon'
import ICONS, { ICON_SIZE } from 'components/common/icon/const'
import { hasLocizeTranslation } from 'lib/utils/locize'
import Typo from 'constants/typography'

const LoyaltyUSPFooter = ({ t }) => (
  <React.Fragment>
    <Icon src={ICONS.loyaltyWhite} size={ICON_SIZE.medium} lazyThreshold={1} />
    <span
      className={clsx(Typo.subtitle1, 'title')}
      data-cy="footer_usp_loyalty_title"
    >
      {hasLocizeTranslation(t, 'FOOTER_USP_LOYALTY_TITLE', 'Pomelo Perks')}
    </span>
    <span className={Typo.caption}>
      {hasLocizeTranslation(
        t,
        'FOOTER_USP_LOYALTY_SUBTITLE',
        'Earn 5% Cashback on All Orders',
      )}
    </span>
  </React.Fragment>
)

LoyaltyUSPFooter.propTypes = {
  t: PropTypes.func.isRequired,
}

export default withI18next()(LoyaltyUSPFooter)
