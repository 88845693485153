import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { translate } from 'react-i18next'
import Router from 'next/router'
import clsx from 'clsx'
import useModal from 'components/portal/useModal'
import { hasLocizeTranslation } from 'lib/utils/locize'
import StateStorage from 'lib/state-storage'
import { CSR_NAV_TRACK } from 'constants/cookies'
import Icon from 'components/common/icon'
import ICONS, { ICON_SIZE } from 'components/common/icon/const'
import Typo from 'constants/typography'
import Dialog from 'components/common/dialog'
import styles from './styles.scss'
import BackdropModal from '../backdrop-modal'

export const FALLBACK_TYPE = {
  back: 0,
  close: 1,
  forward: 2,
  homepage: 3,
  reload: 4,
  backOrReload: 5,
}

function getCtaAction(modalType, setIsOpen, canGoBack) {
  const shop = StateStorage.getShop()
  const language = StateStorage.getLanguage()
  const href = '/home'
  const as = `/${shop}/${language}/`

  if (typeof window !== 'undefined') {
    if (modalType === FALLBACK_TYPE.backOrReload && canGoBack) {
      setIsOpen(false)
      return window.history.back()
    }

    switch (modalType) {
      case FALLBACK_TYPE.reload:
        window.location.reload()
        break
      case FALLBACK_TYPE.back:
        window.history.back()
        break
      case FALLBACK_TYPE.forward:
        window.history.forward()
        break
      case FALLBACK_TYPE.close:
        setIsOpen(false)
        break
      case FALLBACK_TYPE.homepage:
        Router.push(href, as)
        break
      default:
        window.location.reload()
    }
  }
  return undefined
}

function getCtaTitle(modalType, t, canGoBack) {
  if (modalType === FALLBACK_TYPE.backOrReload && canGoBack) {
    return hasLocizeTranslation(t, 'FALL_BACK_MODAL_CTA_BACK', 'Go Back')
  }

  switch (modalType) {
    case FALLBACK_TYPE.reload:
      return hasLocizeTranslation(
        t,
        'FALL_BACK_MODAL_CTA_TRY_AGIAN',
        'Try Again',
      )
    case FALLBACK_TYPE.back:
      return hasLocizeTranslation(t, 'FALL_BACK_MODAL_CTA_BACK', 'Go Back')
    case FALLBACK_TYPE.close:
      return hasLocizeTranslation(t, 'FALL_BACK_MODAL_CTA_CLOSE', 'Close')
    default:
      return hasLocizeTranslation(
        t,
        'FALL_BACK_MODAL_CTA_TRY_AGIAN',
        'Try Again',
      )
  }
}

const FallBackModal = ({ children, keyIndex, modalType, t }) => {
  const [isOpen, setIsOpen] = useModal(false)
  const [canGoBack, setCanGoBack] = useState(false)

  useEffect(() => {
    if (modalType === FALLBACK_TYPE.backOrReload) {
      // Use keyIndex to detect client side navigation between the same page.
      // Use sessionStorage to detect navigation between different pages.
      setCanGoBack(sessionStorage.getItem(CSR_NAV_TRACK) > 0 || keyIndex > 0)
    }

    setIsOpen(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyIndex])

  return (
    <BackdropModal isOpen={isOpen}>
      <style jsx>{styles}</style>
      <Dialog
        confirmable
        confirmText={getCtaTitle(modalType, t, canGoBack)}
        icon={<Icon src={ICONS.wrong} size={ICON_SIZE.extraLarge} />}
        onConfirm={() => getCtaAction(modalType, setIsOpen, canGoBack)}
      >
        {children || (
          <React.Fragment>
            <div className={Typo.h6}>
              {hasLocizeTranslation(
                t,
                'FALL_BACK_MODAL_TITLE',
                'Oops! Something went wrong',
              )}
            </div>
            <div className={clsx(Typo.body2, 'fallback-modal__subtitle')}>
              {hasLocizeTranslation(
                t,
                'FALL_BACK_MODAL_CONTENT',
                "We're working on it and we'll get it fixed as soon as we can",
              )}
            </div>
          </React.Fragment>
        )}
      </Dialog>
    </BackdropModal>
  )
}

FallBackModal.defaultProps = {
  children: null,
  keyIndex: 0,
  modalType: 4,
}

FallBackModal.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  keyIndex: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
  modalType: PropTypes.number,
  t: PropTypes.func.isRequired,
}

export default translate('global')(FallBackModal)
