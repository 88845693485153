import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import TextField from 'components/common/textfield'
import Select from 'components/common/select'
import Typo from 'constants/typography'
import styles from './style.scss'

const TextFieldPhone = ({
  selectedPhonePrefix,
  handleChangePhonePrefix,
  phonePrefixRegister,
  phoneNumberRegister,
  countryList,
  description,
  disabled,
  userPhoneNumber,
  error,
  label,
  helperText,
  name,
  inputProps,
  htmlFor,
  onChange,
}) => {
  const helperTextClasses = clsx('pml-txt-phone__helper', Typo.caption, {
    error,
  })

  return (
    <div className="pml-txt-phone">
      <style jsx>{styles}</style>
      <div className="pml-txt-phone__inputs">
        {selectedPhonePrefix && (
          <Select
            cy="auth__phone_prefix"
            value={selectedPhonePrefix}
            onChange={handleChangePhonePrefix}
            name="phonePrefix"
            className="pml-txt-phone__country-code"
            inputRef={phonePrefixRegister}
            disabled={disabled}
            error={!!error}
          >
            <option value={selectedPhonePrefix}>+{selectedPhonePrefix}</option>
            {countryList?.map((country) => (
              <option
                key={country.id_country}
                value={country.call_prefix}
                defaultValue={
                  parseInt(selectedPhonePrefix, 10) === country.call_prefix
                }
              >
                {country.name}
                &nbsp; +{country.call_prefix}
              </option>
            ))}
          </Select>
        )}
        <TextField
          className="pml-txt-phone__phone-number"
          name={name}
          fieldId="auth__phone__field"
          fullWidth
          inputProps={inputProps}
          type="tel"
          onChange={onChange}
          defaultValue={userPhoneNumber}
          label={label}
          inputRef={phoneNumberRegister}
          error={!!error}
          htmlFor={htmlFor}
          disabled={disabled}
        />
      </div>
      <div className={helperTextClasses}>{error || helperText}</div>
      {description && <div className={Typo.caption}>{description}</div>}
    </div>
  )
}

TextFieldPhone.defaultProps = {
  userPhoneNumber: undefined,
  selectedPhonePrefix: '',
  countryList: undefined,
  description: undefined,
  disabled: false,
  inputProps: undefined,
  onChange: undefined,
  phonePrefixRegister: undefined,
  phoneNumberRegister: undefined,
  error: undefined,
  label: undefined,
  helperText: undefined,
  name: undefined,
  htmlFor: undefined,
}

TextFieldPhone.propTypes = {
  countryList: PropTypes.arrayOf(
    PropTypes.shape({
      id_country: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      call_prefix: PropTypes.number.isRequired,
    }),
  ),
  description: PropTypes.string,
  disabled: PropTypes.bool,
  userPhoneNumber: PropTypes.string,
  selectedPhonePrefix: PropTypes.string,
  handleChangePhonePrefix: PropTypes.func.isRequired,
  phonePrefixRegister: PropTypes.func,
  phoneNumberRegister: PropTypes.func,
  inputProps: PropTypes.shape({}),
  error: PropTypes.string,
  label: PropTypes.string,
  helperText: PropTypes.string,
  name: PropTypes.string,
  htmlFor: PropTypes.string,
  onChange: PropTypes.func,
}

export default TextFieldPhone
