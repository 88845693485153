import env from 'config/env'
import { FEATURE_TOGGLER } from 'constants/global'
import { HTTP_METHODS } from './const'
import { request } from './index'

class Return {
  static LIST = 'LIST'

  static DETAIL_NEW = 'DETAIL_NEW'

  static RETURN_LOCATIONS = 'RETURN_LOCATIONS'

  static CREATE = 'CREATE_RETURNS'

  static RETURNS_REASONS = 'RETURNS_REASONS'

  static RETURNS_PAYMENT_METHOD = 'RETURNS_PAYMENT_METHOD'

  static API_HOST = env.API_HOST

  static handleFetch(options = {}) {
    switch (options.source) {
      case Return.LIST:
        return Return.getReturnsList(options)
      case Return.DETAIL_NEW:
        return Return.getReturnDetails(options)
      case Return.RETURN_LOCATIONS:
        return Return.getReturnLocations()
      case Return.CREATE:
        return Return.createReturn(options)
      case Return.RETURNS_REASONS:
        return Return.getReturnReasons()
      case Return.RETURNS_PAYMENT_METHOD:
        return Return.getReturnRefundMethods(options)
      default:
        return Return.getReturnsList(options)
    }
  }

  static getReturnsList(options) {
    return request({
      url: 'v6/returns',
      params: {
        'filter[shop_id]': options.shopID,
      },
      method: HTTP_METHODS.get,
    })
  }

  static getReturnDetails(options) {
    return request({
      url: `v6/returns/${options.returnID}`,
      method: HTTP_METHODS.get,
    })
  }

  static getReturnLocations() {
    return request({
      url: 'v6/returns/locations',
      method: HTTP_METHODS.get,
    })
  }

  static createReturn(options) {
    return request({
      url: 'v6/returns',
      body: options.body,
      method: HTTP_METHODS.post,
    })
  }

  static getReturnReasons() {
    return request({
      url: 'v6/returns/reasons',
      method: HTTP_METHODS.get,
    })
  }

  static getReturnRefundMethods(options) {
    let { body } = options

    // Non loyalty, use v2 endpoint with different body.
    if (!FEATURE_TOGGLER.loyalty && body) {
      body = {
        id_order: body.id_order,
        id_order_details: [
          ...body.details.map((detail) => detail.id_order_detail),
        ],
      }
    }

    return request({
      url: 'v6/returns/refund-methods',
      body,
      method: HTTP_METHODS.post,
    })
  }

  static checkPrePrintedNumber(options) {
    return request({
      url: `v6/returns/slips/${options.slipNumber}/validate`,
      method: HTTP_METHODS.get,
    })
  }

  static async getReturnForms({ returnID, formType }) {
    const { token: pdfToken } =
      (await request({
        url: `v6/returns/${returnID}/form`,
        params: {
          type: formType,
        },
        method: HTTP_METHODS.get,
      })) || {}

    if (pdfToken) {
      return request({
        url: `v6/tokens/${pdfToken}`,
        method: HTTP_METHODS.get,
        responseType: 'blob',
      })
    }
    return undefined
  }

  static bookPopStaion(returnID, locationReturnID) {
    const data = {
      id_order_return: returnID,
    }

    return request({
      url: `v6/returns/locations/${locationReturnID}/book`,
      body: data,
      method: HTTP_METHODS.post,
    })
  }
}

export default Return
