import { getAssetPath } from 'lib/utils/common/commonUtils'

export const HTTP_METHODS = {
  delete: 'DELETE',
  get: 'GET',
  patch: 'PATCH',
  post: 'POST',
  put: 'PUT',
}

export const STATUS_CODES = {
  ok: 200,
  blank: 204,
  badRequest: 400,
  unauthorized: 401,
  forbidden: 403,
  notFound: 404,
  tooManyRequest: 429,
  internalError: 500,
  badGateway: 502,
  unavailable: 503,
  timeout: 504,
}

export const ERROR_CODES = {
  timeout: 'ECONNABORTED',
}

export const ENDPOINTS = {
  storeCredit: {
    cache: 86400000,
    link: 'v6/store-credits',
  },
  creditConditions: {
    cache: 86400000,
    link: 'v6/conditions?type=credit',
  },
  cashbackConditions: {
    cache: 86400000,
    link: 'v6/conditions?type=cashback',
  },
  storeCreditConditions: {
    cache: 86400000,
    link: 'v6/conditions?type=store_credit',
  },
  ttbDepositConditions: {
    cache: 86400000,
    link: 'v6/conditions?type=ttb_deposit',
  },
  allAcceptedDiscountConditions: {
    cache: 86400000,
    link: 'v6/conditions?type=all_accept_discount',
  },
  pomeloCares: {
    cache: 86400000,
    link: 'v6/pomelo-cares',
  },
  lookbooks: {
    cache: 86400000,
    link: 'v6/lookbooks',
  },
  productsSearch: {
    cache: 86400000,
    link: 'v6/products/search',
  },
  bestsellers: {
    cache: 86400000,
    link: (id) => `v6/categories/${id}/bestsellers-products`,
  },
  cartRecommendation: 'v6/recommendations/cart',
  creditsHistory: 'v6/credits/history',
  brandDirectory: {
    cache: 86400000,
    link: 'v7/navigations/brands',
  },
  omise3DsStatus: {
    link: (id_order) => `v6/webhooks/omise?id_order=${id_order}`,
  },
  midtrans3DsStatus: {
    link: (id_order) => `v6/orders/${id_order}/payment-status`,
  },
}

export const ASSETS = {
  PML_LOGO: `${getAssetPath()}/static/apple-touch-icon.png`,
}

export const API_HEADER_ATTRIBUTE_KEY = Object.freeze({
  'cache-control': 'api_header_cache_ctrl',
})

export const API_ALIASES = {
  ADDRESS: 'ADDRESS',
  ADDRESSES: 'ADDRESSES',
  AUTH_IDENTIFY: 'AUTH_IDENTIFY',
  AUTH_TOKEN: 'AUTH_TOKEN',
  CART_APPLY: 'CART_APPLY',
  CART_BADGE: 'CART_BADGE',
  CART_CURRENT: 'CART_CURRENT',
  CART_FREE_GIFTS: 'CART_FREE_GIFTS',
  CART_RECOMMENDATIONS: 'CART_RECOMMENDATIONS',
  CART_VIEW: 'CART_VIEW',
  CATEGORY: 'CATEGORY',
  CATEGORY_BESTSELLERS: 'CATEGORY_BESTSELLERS',
  CATEGORY_PRODUCTS: 'CATEGORY_PRODUCTS',
  CATEGORY_RELATED: 'CATEGORY_RELATED',
  CONDITIONS: 'CONDITIONS',
  CONSENT: 'CONSENT',
  COUNTRIES: 'COUNTRIES',
  COUNTRY_REGIONS: 'COUNTRY_REGIONS',
  COUNTRY_STATES: 'COUNTRY_STATES',
  CREDIT_CARD: 'CREDIT_CARD',
  CREDIT_CARDS: 'CREDIT_CARDS',
  CREDIT_CARD_MARK_DEFAULT: 'CREDIT_CARD_MARK_DEFAULT',
  CREDIT_HISTORY: 'CREDIT_HISTORY',
  CUSTOMERS_LOYALTY: 'CUSTOMERS_LOYALTY',
  CUSTOMER_CURRENT: 'CUSTOMER_CURRENT',
  DISTRICT: 'DISTRICT',
  DISTRICTS_SEARCH: 'DISTRICTS_SEARCH',
  DISTRICT_BARANGAYS: 'DISTRICT_BARANGAYS',
  EMAIL_SUBSCRIPTION: 'EMAIL_SUBSCRIPTION',
  EMAIL_UNSUBSCRIBE: 'EMAIL_UNSUBSCRIBE',
  EXCHANGE_RATES: 'EXCHANGE_RATES',
  FAQ: 'FAQ',
  FAQS: 'FAQS',
  FEEDBACKS_QUESTIONS: 'FEEDBACKS_QUESTIONS',
  FIREBASE_VERIFY_PHONE: 'FIREBASE_VERIFY_PHONE',
  HOMEPAGE_INSTAGRAMS: 'HOMEPAGE_INSTAGRAMS',
  HOMEPAGE_MASONRY: 'HOMEPAGE_MASONRY',
  HOMEPAGE_SLIDERS: 'HOMEPAGE_SLIDERS',
  HOMEPAGE_VIDEOS: 'HOMEPAGE_VIDEOS',
  I18N: 'I18N',
  INVOICE_DOWNLOAD: 'INVOICE_DOWNLOAD',
  LOOKBOOK: 'LOOKBOOK',
  LOOKBOOKS: 'LOOKBOOKS',
  NAVIGATIONS: 'NAVIGATIONS',
  NAVIGATIONS_BRANDS: 'NAVIGATIONS_BRANDS',
  NPS: 'NPS',
  NPSES: 'NPSES',
  NPSES_VALIDATE: 'NPSES_VALIDATE',
  ORDER: 'ORDER',
  ORDERS: 'ORDERS',
  ORDER_CHECKOUT: 'ORDER_CHECKOUT',
  ORDER_EXTEND: 'ORDER_EXTEND',
  ORDER_FEEDBACK: 'ORDER_FEEDBACK',
  ORDER_PAYMENT: 'ORDER_PAYMENT',
  ORDER_PAYMENT_STATUS: 'ORDER_PAYMENT_STATUS',
  ORDER_REFUND_SUMMARY: 'ORDER_REFUND_SUMMARY',
  PAYMENTS_BANK_ACCOUNT: 'PAYMENTS_BANK_ACCOUNT',
  PAYMENTS_METHODS: 'PAYMENTS_METHODS',
  PAYMENTS_PROOFS: 'PAYMENTS_PROOFS',
  PICKUP_LOCATIONS: 'PICKUP_LOCATIONS',
  POMELO_CARES: 'POMELO_CARES',
  PRODUCT: 'PRODUCT',
  PRODUCTS: 'PRODUCTS',
  PRODUCTS_CUSTOM_RECOMMENDATIONS: 'PRODUCTS_CUSTOM_RECOMMENDATIONS',
  PRODUCTS_IMAGE_SEARCH: 'PRODUCTS_IMAGE_SEARCH',
  PRODUCTS_SEARCH: 'PRODUCTS_SEARCH',
  PRODUCT_ADD_TO_CART: 'PRODUCT_ADD_TO_CART',
  PRODUCT_ALTERNATIVES: 'PRODUCT_ALTERNATIVES',
  PRODUCT_NOTIFY_ME: 'PRODUCT_NOTIFY_ME',
  PRODUCT_RECOMMENDATIONS: 'PRODUCT_RECOMMENDATIONS',
  PRODUCT_SIMILAR: 'PRODUCT_SIMILAR',
  PRODUCT_UPSELLS: 'PRODUCT_UPSELLS',
  REFERRALS_CODE: 'REFERRALS_CODE',
  REFERRALS_ORDERS: 'REFERRALS_ORDERS',
  RETURN: 'RETURN',
  RETURNS: 'RETURNS',
  RETURNS_LOCATIONS: 'RETURNS_LOCATIONS',
  RETURNS_LOCATION_BOOK: 'RETURNS_LOCATION_BOOK',
  RETURNS_ORDERS: 'RETURNS_ORDERS',
  RETURNS_REASONS: 'RETURNS_REASONS',
  RETURNS_REFUND_METHODS: 'RETURNS_REFUND_METHODS',
  RETURNS_SLIP_VALIDATE: 'RETURNS_SLIP_VALIDATE',
  RETURN_FORM: 'RETURN_FORM',
  SHIPPINGS_LOCATIONS: 'SHIPPINGS_LOCATIONS',
  SHIPPING_METHODS: 'SHIPPING_METHODS',
  SHOPPING_BAG: 'SHOPPING_BAG',
  SKINNY_BANNERS: 'SKINNY_BANNERS',
  STORE_CREDITS: 'STORE_CREDITS',
  TOKEN: 'TOKEN',
  VIDEO_GALLERY: 'VIDEO_GALLERY',
  VISUAL_SCREENS: 'VISUAL_SCREENS',
  VOUCHERS: 'VOUCHERS',
  WEBHOOK_HOOLA: 'WEBHOOK_HOOLA',
  WEBHOOK_OMISE: 'WEBHOOK_OMISE',
  WHY_POMELO: 'WHY_POMELO',
  WISHLIST: 'WISHLIST',
  WISHLISTS: 'WISHLISTS',
  WISHLIST_ADD_TO_CART: 'WISHLIST_ADD_TO_CART',
}

export const API_ALIAS_CONFIGS = {
  [API_ALIASES.ADDRESS]: {
    original_path: '/v6/addresses/::PARAM::',
    canary_path: '/v8/addresses/::PARAM::',
  },
  [API_ALIASES.ADDRESSES]: {
    original_path: '/v6/addresses',
    canary_path: '/v8/addresses',
  },
  [API_ALIASES.AUTH_IDENTIFY]: {
    original_path: '/v6/oauth/identity',
    canary_path: '/v8/oauth/identity',
  },
  [API_ALIASES.AUTH_TOKEN]: {
    original_path: '/v6/oauth/token',
    canary_path: '/v8/oauth/token',
  },
  [API_ALIASES.CART_APPLY]: {
    original_path: '/v6/carts/current/apply',
    canary_path: '/v8/carts/current/apply',
  },
  [API_ALIASES.CART_BADGE]: {
    original_path: '/v6/carts/current/badge',
    canary_path: '/v8/carts/current/badge',
  },
  [API_ALIASES.CART_CURRENT]: {
    original_path: '/v6/carts/current',
    canary_path: '/v8/carts/current',
  },
  [API_ALIASES.CART_FREE_GIFTS]: {
    original_path: '/v6/carts/current/free-gifts',
    canary_path: '/v8/carts/current/free-gifts',
  },
  [API_ALIASES.CART_RECOMMENDATIONS]: {
    original_path: '/v6/recommendations/cart',
    canary_path: '/v8/recommendations/cart',
  },
  [API_ALIASES.CART_VIEW]: {
    original_path: '/v6/carts/current/view',
    canary_path: '/v8/carts/current/view',
  },
  [API_ALIASES.CATEGORY]: {
    original_path: '/v6/categories/::PARAM::',
    canary_path: '/v8/categories/::PARAM::',
  },
  [API_ALIASES.CATEGORY_BESTSELLERS]: {
    original_path: '/v6/categories/::PARAM::/bestsellers-products',
    canary_path: '/v8/categories/::PARAM::/bestsellers-products',
  },
  [API_ALIASES.CATEGORY_PRODUCTS]: {
    original_path: '/v6/categories/::PARAM::/products',
    canary_path: '/v8/categories/::PARAM::/products',
  },
  [API_ALIASES.CATEGORY_RELATED]: {
    original_path: '/v6/categories/::PARAM::/related',
    canary_path: '/v8/categories/::PARAM::/related',
  },
  [API_ALIASES.CONDITIONS]: {
    original_path: '/v6/conditions',
    canary_path: '/v8/conditions',
  },
  [API_ALIASES.CONSENT]: {
    original_path: '/v6/consents',
    canary_path: '/v8/consents',
  },
  [API_ALIASES.COUNTRIES]: {
    original_path: '/v6/static/countries',
    canary_path: '/v8/static/countries',
  },
  [API_ALIASES.COUNTRY_REGIONS]: {
    original_path: '/v6/static/countries/::PARAM::/regions',
    canary_path: '/v8/static/countries/::PARAM::/regions',
  },
  [API_ALIASES.COUNTRY_STATES]: {
    original_path: '/v6/static/countries/::PARAM::/states',
    canary_path: '/v8/static/countries/::PARAM::/states',
  },
  [API_ALIASES.CREDIT_CARD]: {
    original_path: '/v6/credit-cards/::PARAM::',
    canary_path: '/v8/credit-cards/::PARAM::',
  },
  [API_ALIASES.CREDIT_CARDS]: {
    original_path: '/v6/credit-cards',
    canary_path: '/v8/credit-cards',
  },
  [API_ALIASES.CREDIT_CARD_MARK_DEFAULT]: {
    original_path: '/v6/credit-cards/::PARAM::/mark-default',
    canary_path: '/v8/credit-cards/::PARAM::/mark-default',
  },
  [API_ALIASES.CREDIT_HISTORY]: {
    original_path: '/v6/credits/history',
    canary_path: '/v8/credits/history',
  },
  [API_ALIASES.CUSTOMERS_LOYALTY]: {
    original_path: '/v7/customers/loyalty',
    canary_path: '/v8/customers/loyalty',
  },
  [API_ALIASES.CUSTOMER_CURRENT]: {
    original_path: '/v6/customers/current',
    canary_path: '/v8/customers/current',
  },
  [API_ALIASES.DISTRICT]: {
    original_path: '/v6/districts/::PARAM::',
    canary_path: '/v8/districts/::PARAM::',
  },
  [API_ALIASES.DISTRICTS_SEARCH]: {
    original_path: '/v6/districts/search',
    canary_path: '/v8/districts/search',
  },
  [API_ALIASES.DISTRICT_BARANGAYS]: {
    original_path: '/v6/districts/::PARAM::/barangays',
    canary_path: '/v8/districts/::PARAM::/barangays',
  },
  [API_ALIASES.EMAIL_SUBSCRIPTION]: {
    original_path: '/v6/subscriptions/emails',
    canary_path: '/v8/subscriptions/emails',
  },
  [API_ALIASES.EMAIL_UNSUBSCRIBE]: {
    original_path: '/v6/subscriptions/emails/unsubscribe',
    canary_path: '/v8/subscriptions/emails/unsubscribe',
  },
  [API_ALIASES.EXCHANGE_RATES]: {
    original_path: '/v6/exchange-rates',
    canary_path: '/v8/exchange-rates',
  },
  [API_ALIASES.FAQ]: {
    original_path: '/v6/faqs/::PARAM::',
    canary_path: '/v8/faqs/::PARAM::',
  },
  [API_ALIASES.FAQS]: { original_path: '/v6/faqs', canary_path: '/v8/faqs' },
  [API_ALIASES.FEEDBACKS_QUESTIONS]: {
    original_path: '/v6/feedbacks/questions',
    canary_path: '/v8/feedbacks/questions',
  },
  [API_ALIASES.FIREBASE_VERIFY_PHONE]: {
    original_path: '/v6/firebase/verify-phone',
    canary_path: '/v8/firebase/verify-phone',
  },
  [API_ALIASES.HOMEPAGE_INSTAGRAMS]: {
    original_path: '/v6/homepage-instagrams',
    canary_path: '/v8/homepage-instagrams',
  },
  [API_ALIASES.HOMEPAGE_MASONRY]: {
    original_path: '/v6/homepage-masonry',
    canary_path: '/v8/homepage-masonry',
  },
  [API_ALIASES.HOMEPAGE_SLIDERS]: {
    original_path: '/v6/homepage-sliders',
    canary_path: '/v8/homepage-sliders',
  },
  [API_ALIASES.HOMEPAGE_VIDEOS]: {
    original_path: '/v6/homepage-videos',
    canary_path: '/v8/homepage-videos',
  },
  [API_ALIASES.I18N]: {
    original_path: '/v6/i18n/strings',
    canary_path: '/v8/i18n/strings',
  },
  [API_ALIASES.INVOICE_DOWNLOAD]: {
    original_path: '/v6/invoices/::PARAM::/download',
    canary_path: '/v8/invoices/::PARAM::/download',
  },
  [API_ALIASES.LOOKBOOK]: {
    original_path: '/v6/lookbooks/::PARAM::',
    canary_path: '/v8/lookbooks/::PARAM::',
  },
  [API_ALIASES.LOOKBOOKS]: {
    original_path: '/v6/lookbooks',
    canary_path: '/v8/lookbooks',
  },
  [API_ALIASES.NAVIGATIONS]: {
    original_path: '/v6/navigations',
    canary_path: '/v8/navigations',
  },
  [API_ALIASES.NAVIGATIONS_BRANDS]: {
    original_path: '/v7/navigations/brands',
    canary_path: '/v8/navigations/brands',
  },
  [API_ALIASES.NPS]: {
    original_path: '/v6/nps/::PARAM::',
    canary_path: '/v8/nps/::PARAM::',
  },
  [API_ALIASES.NPSES]: { original_path: '/v6/nps', canary_path: '/v8/nps' },
  [API_ALIASES.NPSES_VALIDATE]: {
    original_path: '/v6/nps/validate',
    canary_path: '/v8/nps/validate',
  },
  [API_ALIASES.ORDER]: {
    original_path: '/v7/orders/::PARAM::',
    canary_path: '/v8/orders/::PARAM::',
  },
  [API_ALIASES.ORDERS]: {
    original_path: '/v7/orders',
    canary_path: '/v8/orders',
  },
  [API_ALIASES.ORDER_CHECKOUT]: {
    original_path: '/orders/checkout',
    canary_path: '/v8/orders/checkout',
  },
  [API_ALIASES.ORDER_EXTEND]: {
    original_path: '/v6/orders/::PARAM::/extend',
    canary_path: '/v8/orders/::PARAM::/extend',
  },
  [API_ALIASES.ORDER_FEEDBACK]: {
    original_path: '/v6/orders/::PARAM::/feedback',
    canary_path: '/v8/orders/::PARAM::/feedback',
  },
  [API_ALIASES.ORDER_PAYMENT]: {
    original_path: '/orders/orderpayment',
    canary_path: '/v8/orders/orderpayment',
  },
  [API_ALIASES.ORDER_PAYMENT_STATUS]: {
    original_path: '/v6/orders/::PARAM::/payment-status',
    canary_path: '/v8/orders/::PARAM::/payment-status',
  },
  [API_ALIASES.ORDER_REFUND_SUMMARY]: {
    original_path: '/v6/orders/::PARAM::/refund-summary',
    canary_path: '/v8/orders/::PARAM::/refund-summary',
  },
  [API_ALIASES.PAYMENTS_BANK_ACCOUNT]: {
    original_path: '/v6/payments/bank-account',
    canary_path: '/v8/payments/bank-account',
  },
  [API_ALIASES.PAYMENTS_METHODS]: {
    original_path: '/v6/payments/methods',
    canary_path: '/v8/payments/methods',
  },
  [API_ALIASES.PAYMENTS_PROOFS]: {
    original_path: '/v6/payments/proofs',
    canary_path: '/v8/payments/proofs',
  },
  [API_ALIASES.PICKUP_LOCATIONS]: {
    original_path: '/v6/pickup-locations',
    canary_path: '/v8/pickup-locations',
  },
  [API_ALIASES.POMELO_CARES]: {
    original_path: '/v6/pomelo-cares',
    canary_path: '/v8/pomelo-cares',
  },
  [API_ALIASES.PRODUCT]: {
    original_path: '/v6/products/::PARAM::',
    canary_path: '/v8/products/::PARAM::',
  },
  [API_ALIASES.PRODUCTS]: {
    original_path: '/v6/products',
    canary_path: '/v8/products',
  },
  [API_ALIASES.PRODUCTS_CUSTOM_RECOMMENDATIONS]: {
    original_path: '/v6/products/custom-recommendations',
    canary_path: '/v8/products/custom-recommendations',
  },
  [API_ALIASES.PRODUCTS_IMAGE_SEARCH]: {
    original_path: '/v6/products/image-search',
    canary_path: '/v8/products/image-search',
  },
  [API_ALIASES.PRODUCTS_SEARCH]: {
    original_path: '/v6/products/search',
    canary_path: '/v8/products/search',
  },
  [API_ALIASES.PRODUCT_ADD_TO_CART]: {
    original_path: '/v6/products/::PARAM::/add-to-cart',
    canary_path: '/v8/products/::PARAM::/add-to-cart',
  },
  [API_ALIASES.PRODUCT_ALTERNATIVES]: {
    original_path: '/v6/products/::PARAM::/alternatives',
    canary_path: '/v8/products/::PARAM::/alternatives',
  },
  [API_ALIASES.PRODUCT_NOTIFY_ME]: {
    original_path: '/v6/products/::PARAM::/notify-me',
    canary_path: '/v8/products/::PARAM::/notify-me',
  },
  [API_ALIASES.PRODUCT_RECOMMENDATIONS]: {
    original_path: '/v6/products/::PARAM::/recommendations',
    canary_path: '/v8/products/::PARAM::/recommendations',
  },
  [API_ALIASES.PRODUCT_SIMILAR]: {
    original_path: '/v6/products/::PARAM::/similar',
    canary_path: '/v8/products/::PARAM::/similar',
  },
  [API_ALIASES.PRODUCT_UPSELLS]: {
    original_path: '/v6/products/::PARAM::/upsells',
    canary_path: '/v8/products/::PARAM::/upsells',
  },
  [API_ALIASES.REFERRALS_CODE]: {
    original_path: '/v6/referrals/referral-code',
    canary_path: '/v8/referrals/referral-code',
  },
  [API_ALIASES.REFERRALS_ORDERS]: {
    original_path: '/v6/referrals/orders',
    canary_path: '/v8/referrals/orders',
  },
  [API_ALIASES.RETURN]: {
    original_path: '/v6/returns/::PARAM::',
    canary_path: '/v8/returns/::PARAM::',
  },
  [API_ALIASES.RETURNS]: {
    original_path: '/v6/returns',
    canary_path: '/v8/returns',
  },
  [API_ALIASES.RETURNS_LOCATIONS]: {
    original_path: '/v6/returns/locations',
    canary_path: '/v8/returns/locations',
  },
  [API_ALIASES.RETURNS_LOCATION_BOOK]: {
    original_path: '/v6/returns/locations/::PARAM::/book',
    canary_path: '/v8/returns/locations/::PARAM::/book',
  },
  [API_ALIASES.RETURNS_ORDERS]: {
    original_path: '/v6/returns/orders',
    canary_path: '/v8/returns/orders',
  },
  [API_ALIASES.RETURNS_REASONS]: {
    original_path: '/v6/returns/reasons',
    canary_path: '/v8/returns/reasons',
  },
  [API_ALIASES.RETURNS_REFUND_METHODS]: {
    original_path: '/v6/returns/refund-methods',
    canary_path: '/v8/returns/refund-methods',
  },
  [API_ALIASES.RETURNS_SLIP_VALIDATE]: {
    original_path: '/v6/returns/slips/::PARAM::/validate',
    canary_path: '/v8/returns/slips/::PARAM::/validate',
  },
  [API_ALIASES.RETURN_FORM]: {
    original_path: '/v6/returns/::PARAM::/form',
    canary_path: '/v8/returns/::PARAM::/form',
  },
  [API_ALIASES.SHIPPINGS_LOCATIONS]: {
    original_path: '/v6/shippings/locations',
    canary_path: '/v8/shippings/locations',
  },
  [API_ALIASES.SHIPPING_METHODS]: {
    original_path: '/v6/carriers/shipping-methods',
    canary_path: '/v8/carriers/shipping-methods',
  },
  [API_ALIASES.SHOPPING_BAG]: {
    original_path: '/v6/shopping-bag',
    canary_path: '/v8/shopping-bag',
  },
  [API_ALIASES.SKINNY_BANNERS]: {
    original_path: '/v6/skinny-banners',
    canary_path: '/v8/skinny-banners',
  },
  [API_ALIASES.STORE_CREDITS]: {
    original_path: '/v6/store-credits',
    canary_path: '/v8/store-credits',
  },
  [API_ALIASES.TOKEN]: {
    original_path: '/v6/tokens/::PARAM::',
    canary_path: '/v8/tokens/::PARAM::',
  },
  [API_ALIASES.VIDEO_GALLERY]: {
    original_path: '/v6/video-gallery/::PARAM::',
    canary_path: '/v8/video-gallery/::PARAM::',
  },
  [API_ALIASES.VISUAL_SCREENS]: {
    original_path: '/v6/visual-screen',
    canary_path: '/v8/visual-screen',
  },
  [API_ALIASES.VOUCHERS]: {
    original_path: '/v6/vouchers',
    canary_path: '/v8/vouchers',
  },
  [API_ALIASES.WEBHOOK_HOOLA]: {
    original_path: '/v6/webhooks/hoolah',
    canary_path: '/v8/webhooks/hoolah',
  },
  [API_ALIASES.WEBHOOK_OMISE]: {
    original_path: '/v6/webhooks/omise',
    canary_path: '/v8/webhooks/omise',
  },
  [API_ALIASES.WHY_POMELO]: {
    original_path: '/v6/info/why-pomelo',
    canary_path: '/v8/info/why-pomelo',
  },
  [API_ALIASES.WISHLIST]: {
    original_path: '/v6/wishlists/::PARAM::',
    canary_path: '/v8/wishlists/::PARAM::',
  },
  [API_ALIASES.WISHLISTS]: {
    original_path: '/v6/wishlists',
    canary_path: '/v8/wishlists',
  },
  [API_ALIASES.WISHLIST_ADD_TO_CART]: {
    original_path: '/v6/wishlists/::PARAM::/add-to-cart',
    canary_path: '/v8/wishlists/::PARAM::/add-to-cart',
  },
}
