import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { withI18next } from 'lib/i18n/withI18next'
import { hasLocizeTranslation } from 'lib/utils/locize'
import ToggleSwitch from 'components/common/toggle-switch'
import Typo from 'constants/typography'
import { arrangeContent } from 'components/alert/alert-cookie/const'

const CookieSettingList = ({ checked, list, t, toggleSetting }) => {
  const contentList = arrangeContent(t, list.content, 'cta-link')

  return (
    <React.Fragment>
      <div className="content-list">
        <span className={Typo.subtitle2}>
          {hasLocizeTranslation(t, list.header.key, list.header.text)}
        </span>
        <ToggleSwitch
          checked={checked}
          disabled={list.disable}
          onChange={toggleSetting}
        />
      </div>
      <div className={clsx('subtitle-list', Typo.caption)}>
        <div
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: contentList,
          }}
        />
      </div>
    </React.Fragment>
  )
}

CookieSettingList.propTypes = {
  checked: PropTypes.bool.isRequired,
  list: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
  toggleSetting: PropTypes.func.isRequired,
}

export default withI18next()(CookieSettingList)
