export const cardCreditsMsg = {
  creditApplied: {
    useMsg: { key: 'CART_CREDITS_USED', value: 'USED %s CREDITS' },
    creditNumMsg: {
      key: 'CART_CREDITS_REMAINING',
      value: 'Remaining Credits : ',
    },
  },
  creditWaitForApply: {
    useMsg: { key: 'CART_CREDITS_USABLE', value: 'USE %s CREDITS' },
    creditNumMsg: {
      key: 'CART_CREDITS_TOTAL',
      value: 'Total Credits : ',
    },
  },
}

export default { cardCreditsMsg }
