import Cookies from 'js-cookie'
import UniversalCookie from 'universal-cookie'
import { DEVICE_COOKIES } from 'constants/cookies'

const KEY_POMELO_PINS_COACH_MARK = 'pomeloPinsCoachMark'
const KEY_POMELO_PICKUP_COACH_MARK = 'pomeloPickUpCoachMark'
const KEY_POMELO_CHECKOUT_PHONE_NO = 'pomeloCheckoutPhoneNo'
const KEY_PDP_USER_SIZE_PREFERENCE = 'pomeloPDPUserSizePreference'
const KEY_POMELO_LEGACY_SHOPPING_TRACK = 'pomeloLegacyShoppingTrack'

let UniversalCookiesInstance = new UniversalCookie()
let userSession

/* istanbul ignore next */
function setCookie(key, value, lifespanDays) {
  if (typeof document !== 'undefined') {
    if (lifespanDays) {
      Cookies.set(key, value, { expires: lifespanDays })
    } else {
      Cookies.set(key, value)
    }

    return true
  }

  return UniversalCookiesInstance.set(key, value) || false
}

/* istanbul ignore next */
function getCookie(key) {
  if (typeof document === 'undefined') {
    return UniversalCookiesInstance.get(key)
  }

  return Cookies.get(key) || UniversalCookiesInstance.get(key) || null
}

/* istanbul ignore next */
function removeCookie(key) {
  if (typeof document === 'undefined') {
    return UniversalCookiesInstance.remove(key)
  }

  return Cookies.remove(key)
}

function removeLocalState(key) {
  if (typeof window !== 'undefined' && typeof localStorage !== 'undefined') {
    localStorage.removeItem(key)
    return true
  }

  return false
}

/**
 * Save to localStorage
 * @param {*} lifespan in seconds, default set to 300, specify as false for no expiration.
 */
function saveLocalState(key, data, lifespan) {
  let computedLifespan = lifespan
  if (typeof window !== 'undefined' && typeof localStorage !== 'undefined') {
    if (lifespan === undefined || lifespan === null) {
      computedLifespan = 300 // Expire in seconds (5 mins default)
    } else if (lifespan !== false) {
      computedLifespan = Math.abs(lifespan)
    }

    const res = {
      payload: data,
      ...(lifespan !== false && {
        expires: Date.now() + computedLifespan * 1000,
      }),
    }

    localStorage.setItem(key, JSON.stringify(res))
  }
}

function getLocalState(key) {
  if (typeof window !== 'undefined' && typeof localStorage !== 'undefined') {
    try {
      const serializedState = localStorage.getItem(key)

      const data =
        typeof serializedState === 'string'
          ? JSON.parse(serializedState)
          : serializedState

      if (data?.expires || data?.expires === 0) {
        const now = Date.now()
        const lifespan = data.expires

        if (lifespan < now) {
          removeLocalState(key)
          return null
        }
      }

      return data?.payload
    } catch (e) {
      // JSON parse fail
    }
  }

  return null
}

function loadAuthState() {
  try {
    const serializedState = getCookie('auth')

    if (!serializedState) {
      return undefined
    }

    const unserializedState =
      typeof serializedState === 'string'
        ? JSON.parse(serializedState)
        : serializedState

    return {
      auth: {
        token: unserializedState.token,
        user: {
          id_customer: unserializedState.user.id_customer,
          firstname: unserializedState.user.firstname,
          lastname: unserializedState.user.lastname,
        },
      },
    }
  } catch (err) {
    return undefined
  }
}

function saveCountry(country) {
  try {
    setCookie('pomeloCountry', country, 365)
  } catch (err) {
    // Ignore write errors
  }
}

function getCountry() {
  return getCookie('pomeloCountry')
}

function saveCurrency(currency) {
  try {
    setCookie('pomeloCurrency', currency)
  } catch (err) {
    // Ignore write errors
  }
}

function getCurrency() {
  return getCookie('pomeloCurrency') || 1
}

function saveShop(shop) {
  const key = 'pomeloShop'
  setCookie(key, shop, 365)
  saveLocalState(key, shop, false)
}

function getShop() {
  const key = 'pomeloShop'
  return getCookie(key) || getLocalState(key) || 'global'
}

function saveLanguage(lang) {
  try {
    setCookie('pomeloLang', lang, 365)
    // Quick fix for QAFE-49 wrong lang show
    sessionStorage.setItem('pomeloLang', lang)
  } catch (err) {
    // Ignore write errors
  }
}

/* istanbul ignore next */
function initializeCookies(req, shop, lang) {
  if (typeof document !== 'undefined') {
    return
  }

  UniversalCookiesInstance = new UniversalCookie(req)

  if (shop) {
    saveShop(shop)
  }

  if (lang) {
    saveLanguage(lang)
  }
}

function getLanguage() {
  const shop = getShop()

  if (shop === 'id') {
    return 'id'
  }

  try {
    // Quick fix for QAFE-49 use sessionStorage along with cookie
    let serializedState = getCookie('pomeloLang')

    if (typeof document !== 'undefined') {
      serializedState = sessionStorage.getItem('pomeloLang')
    }

    if (!serializedState) {
      return undefined
    }

    return serializedState.split('-')[0]
  } catch (err) {
    return undefined
  }
}

function getAuthUser() {
  try {
    const serializedState = getCookie('auth')

    if (!serializedState) {
      return undefined
    }

    const unserializedState =
      typeof serializedState === 'string'
        ? JSON.parse(serializedState)
        : serializedState

    return unserializedState.user
  } catch (err) {
    return undefined
  }
}

function getAnonymousUser() {
  return {
    firstname: 'anonymous',
    lastname: 'user',
    email: 'anonymous@pmlo.co',
  }
}

function getAuthUserAndLastSignInTime() {
  try {
    const serializedState = getCookie('auth')
    const unserializedState =
      typeof serializedState === 'string'
        ? JSON.parse(serializedState)
        : serializedState

    if (!unserializedState?.user) {
      return undefined
    }

    return {
      lastSignInTime: unserializedState.lastSignInTime,
      user: unserializedState.user,
    }
  } catch (err) {
    return undefined
  }
}

function getAuthCookies() {
  try {
    const serializedState = getCookie('auth')

    if (!serializedState) {
      return undefined
    }

    return typeof serializedState === 'string'
      ? JSON.parse(serializedState)
      : serializedState
  } catch (err) {
    return undefined
  }
}

function setIdentityId(identityId) {
  setCookie('identityId', identityId)
}

function getIdentityId() {
  return getCookie('identityId')
}

function removeIdentityId() {
  removeCookie('identityId')
}

function removeAuthUser() {
  const bannerPDPACookieConsent = getCookie('bannerPDPACookieConsent')

  setCookie('auth', { token: null, user: null })
  removeIdentityId()
  removeCookie('token')
  removeCookie('refreshToken')
  if (bannerPDPACookieConsent === 'null') {
    removeCookie('bannerPDPACookieConsent')
  }
  removeLocalState('legacyShopping')
}

/* istanbul ignore next */
function getAuthToken() {
  try {
    // TODO: this could be remove after all users have refresh token in their browser
    // Token in cookies will be valid only when refresh token is exist
    const auth = getAuthCookies()
    const token = auth?.token
    if (typeof window === 'undefined') {
      const refreshToken = getCookie('refreshToken')
      return refreshToken ? token : ''
    }
    return token
  } catch (err) {
    return undefined
  }
}

function getBannerStatus(id) {
  const bannerStatus = getCookie(`banner${id}`)

  if (typeof window === 'undefined') {
    return false
  }

  return !bannerStatus || bannerStatus !== 'false'
}

function isPinCoachMarkShown() {
  try {
    const serializedState = getCookie(KEY_POMELO_PINS_COACH_MARK)

    if (!serializedState) {
      return false
    }

    return typeof serializedState === 'string'
      ? JSON.parse(serializedState)
      : serializedState
  } catch (err) {
    return false
  }
}

function isPickupCoachMarkShown() {
  try {
    const serializedState = getCookie(KEY_POMELO_PICKUP_COACH_MARK)

    if (!serializedState) {
      return false
    }

    return typeof serializedState === 'string'
      ? JSON.parse(serializedState)
      : serializedState
  } catch (err) {
    return false
  }
}

function saveBannerStatus(id, state) {
  try {
    setCookie(`banner${id}`, state, 7)
  } catch (err) {
    // Ignore write errors
  }
}

function saveAuthState(authState) {
  try {
    const { lastSignInTime, token, user, refreshToken } = authState || {}
    const serializedState = JSON.stringify({
      lastSignInTime,
      token,
      user,
    })
    setCookie('auth', serializedState, 365)
    setCookie('refreshToken', refreshToken)
  } catch (err) {
    // Ignore write errors
  }
}

function setAuthToken(token) {
  const auth = getAuthCookies() || {}
  saveAuthState({
    ...auth,
    token,
  })
}

function setPinCoachMarkShown(status) {
  try {
    const serializedState = JSON.stringify(status)
    setCookie(KEY_POMELO_PINS_COACH_MARK, serializedState)
  } catch (err) {
    // Ignore write errors
  }
}

function setPickupCoachMarkShown(status) {
  try {
    const serializedState = JSON.stringify(status)
    setCookie(KEY_POMELO_PICKUP_COACH_MARK, serializedState)
  } catch (err) {
    // Ignore write errors
  }
}

function isGuestMode() {
  try {
    const serializedState = getCookie('auth')

    if (!serializedState) {
      return true
    }

    const unserializedState =
      typeof serializedState === 'string'
        ? JSON.parse(serializedState)
        : serializedState

    return unserializedState?.token && !unserializedState?.user
  } catch (err) {
    throw new Error(err)
  }
}

function isAuthCookiesAvailable() {
  const serializedState = getCookie('auth')
  if (!serializedState) {
    return true
  }

  const unserializedState =
    typeof serializedState === 'string'
      ? JSON.parse(serializedState)
      : serializedState

  return !unserializedState?.token && !unserializedState?.user
}

function getUserUID() {
  return getCookie('user_uid')
}

function getSegmentAnonymousId() {
  const serializedState = getCookie('ajs_anonymous_id')

  if (!serializedState) {
    return null
  }

  if (typeof serializedState === 'string') {
    return serializedState.replace(/"/g, '')
  }

  return serializedState
}

function getCheckoutPhoneNo() {
  return getCookie(KEY_POMELO_CHECKOUT_PHONE_NO)
}

function setCheckoutPhoneNo(phoneNo) {
  setCookie(KEY_POMELO_CHECKOUT_PHONE_NO, phoneNo)
}

function removeCheckoutPhoneNo() {
  removeCookie(KEY_POMELO_CHECKOUT_PHONE_NO)
}

function getDevice() {
  return getCookie(DEVICE_COOKIES)
}

function saveUserSizePreference(size) {
  try {
    setCookie(KEY_PDP_USER_SIZE_PREFERENCE, size)
  } catch (err) {
    // Ignore write errors
  }
}

function getUserSizePreference() {
  const serializedState = getCookie(KEY_PDP_USER_SIZE_PREFERENCE)

  if (!serializedState) {
    return null
  }

  return serializedState
}

function getServerRefreshToken() {
  const refreshToken = getCookie('refreshToken')
  return refreshToken
}

function setServerLastUrl(url) {
  setCookie('ctx_last_url', url)
}

function getServerLastUrl() {
  return getCookie('ctx_last_url')
}

function setHealthCheck(data) {
  setCookie('pomeloHealth', data, 60000)
}

function getHealthCheck() {
  const serializedState = getCookie('pomeloHealth')

  if (!serializedState) {
    return undefined
  }

  return typeof serializedState === 'string'
    ? JSON.parse(serializedState)
    : serializedState
}

function getUserSession() {
  return userSession
}

function getLegacyShoppingTrack() {
  return getLocalState(KEY_POMELO_LEGACY_SHOPPING_TRACK) || {}
}

function saveLegacyShoppingTrack(payload) {
  return saveLocalState(KEY_POMELO_LEGACY_SHOPPING_TRACK, payload, false)
}

function removeLegacyShoppingTrack() {
  return removeLocalState(KEY_POMELO_LEGACY_SHOPPING_TRACK)
}

export default {
  getAuthCookies,
  getAuthToken,
  getAuthUser,
  getAnonymousUser,
  getAuthUserAndLastSignInTime,
  getBannerStatus,
  getCheckoutPhoneNo,
  getCookie,
  getCountry,
  getCurrency,
  getDevice,
  getLanguage,
  getLocalState,
  getSegmentAnonymousId,
  getServerLastUrl,
  getServerRefreshToken,
  getShop,
  getUserSizePreference,
  getUserUID,
  initializeCookies,
  isAuthCookiesAvailable,
  isGuestMode,
  isPickupCoachMarkShown,
  isPinCoachMarkShown,
  loadAuthState,
  removeAuthUser,
  removeCookie,
  removeLocalState,
  saveAuthState,
  saveBannerStatus,
  saveCountry,
  saveCurrency,
  saveLanguage,
  saveLocalState,
  saveShop,
  saveUserSizePreference,
  setAuthToken,
  setCheckoutPhoneNo,
  setCookie,
  setPickupCoachMarkShown,
  setPinCoachMarkShown,
  setServerLastUrl,
  setHealthCheck,
  getHealthCheck,
  getUserSession,
  getIdentityId,
  setIdentityId,
  removeCheckoutPhoneNo,
  removeIdentityId,
  getLegacyShoppingTrack,
  saveLegacyShoppingTrack,
  removeLegacyShoppingTrack,
}
