const passwordLocizes = {
  labels: {
    PASSWORD_POLICY_LENGTH: '8 to 25 Characters',
    PASSWORD_POLICY_UPPER_CASE: '1 Upper Case Alphabet',
    PASSWORD_POLICY_LOWER_CASE: '1 Lower Case Alphabet',
    PASSWORD_POLICY_NUMBER: '1 Number',
  },
}

export default passwordLocizes
