/**
 * A functiont that ensure the label given
 * has no space and return in `snake_case` format
 * @param {String} label
 * @returns {String}
 */
export function parseCyKey(label, type) {
  if (type === 'key') {
    return (
      label &&
      typeof label === 'string' &&
      label.trim().split(' ').join('_').toLowerCase()
    )
  }
  return (
    label &&
    typeof label === 'string' &&
    label
      .replace(/([A-Z])/g, ' $1')
      .trim()
      .split(' ')
      .join('_')
      .toLowerCase()
  )
}

export default parseCyKey
