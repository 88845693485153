import { setElementStyleProperty } from 'lib/utils/common/commonUtils'
import { hasLocizeTranslation } from 'lib/utils/locize'
import {
  VOUCHER_CHECKOUT_LOCIZE,
  PARTNER_VOUCHER,
  HOT_DEAL_BADGE,
} from './voucher-checkout/const'
import {
  VOUCHER_DISCOUNT_TYPE,
  VOUCHER_DISCOUNT_TYPE_LIST,
  VOUCHER_LOCIZE,
  VOUCHER_TYPE_LIST,
} from './const'

/**
 *
 * @param {object} a - Voucher on the left in compare
 * @param {object} b - Voucher on the right in compare
 */
const discountTypeCompare = (a, b) =>
  VOUCHER_DISCOUNT_TYPE_LIST.indexOf(a.condition.discount.type) -
  VOUCHER_DISCOUNT_TYPE_LIST.indexOf(b.condition.discount.type)

const isPercentageType = (type) => type === VOUCHER_DISCOUNT_TYPE.PERCENTAGE

const voucherMinimumTypeCompare = (minimum_items) =>
  !minimum_items || minimum_items === 1 ? 1 : 0

const sortMinimumCondition = (a, b) => {
  const minimumSpendOrder =
    a.condition.minimum_amount - b.condition.minimum_amount
  const minimumItemOrder = a.condition.minimum_items - b.condition.minimum_items

  return voucherMinimumTypeCompare(a.condition.minimum_items)
    ? minimumSpendOrder
    : minimumItemOrder
}

const sortMinimumType = (a, b) => {
  const minimumTypeOrder =
    voucherMinimumTypeCompare(a.condition.minimum_items) -
    voucherMinimumTypeCompare(b.condition.minimum_items)

  return minimumTypeOrder === 0
    ? -minimumTypeOrder || sortMinimumCondition(a, b)
    : -minimumTypeOrder
}

// This function will return order of voucher by discount amount (amount or percentage) in descending order
/**
 *
 * @param {object} a - Voucher on the left in compare
 * @param {object} b - Voucher on the right in compare
 */
const sortDiscountAmount = (a, b) => {
  const discountPercentageOrder =
    a.condition.discount.reduction_percent -
    b.condition.discount.reduction_percent
  const discountAmountOrder =
    a.condition.discount.reduction_amount -
    b.condition.discount.reduction_amount
  if (a.condition.discount.type === VOUCHER_DISCOUNT_TYPE.GET_COF) {
    return sortMinimumCondition(a, b)
  }
  return isPercentageType(a.condition.discount.type)
    ? -discountPercentageOrder
    : -discountAmountOrder
}

// This function will return order of voucher by discount type with percentage above amount type
/**
 *
 * @param {object} a - Voucher on the left in compare
 * @param {object} b - Voucher on the right in compare
 */
const sortDiscountType = (a, b) => {
  const discountTypeOrder = discountTypeCompare(a, b)

  return discountTypeOrder === 0
    ? discountTypeOrder || sortDiscountAmount(a, b)
    : discountTypeOrder
}

// This function will return order of voucher by voucher type with order in voucherType array
/**
 *
 * @param {object} a - Voucher on the left in compare
 * @param {object} b - Voucher on the right in compare
 */
const sortVoucherPDP = (a, b) => {
  const voucherTypeOrder =
    VOUCHER_TYPE_LIST.indexOf(a.voucher_type) -
    VOUCHER_TYPE_LIST.indexOf(b.voucher_type)
  return voucherTypeOrder === 0
    ? voucherTypeOrder || sortDiscountType(a, b, voucherTypeOrder)
    : voucherTypeOrder
}

/**
 *
 * @param {array} list
 * @returns {array} sorted list with hot deal vouchers at the top
 */
const sortHotdealVoucherAtTop = (list) => {
  const hotdealVouchers = list?.filter((voucher) => voucher.hot_deal) || []
  const nonHotdealVouchers = list?.filter((voucher) => !voucher.hot_deal) || []

  return [...hotdealVouchers, ...nonHotdealVouchers]
}

const sortForGetHotdealBadgePDP = (a, b) => {
  const discountOrder = sortDiscountType(a, b)

  return discountOrder === 0
    ? discountOrder || sortMinimumType(a, b)
    : discountOrder
}

// This function will return hot deal voucher code by exclude partner voucher and get only hot deal voucher with highest amount of discount
/**
 *
 * @param {array} list - List of voucher
 */
const getHotdealVoucherCode = (list) =>
  list
    .filter(
      (voucher) =>
        voucher.voucher_type !== PARTNER_VOUCHER &&
        voucher.badge_type === HOT_DEAL_BADGE,
    )
    .sort(sortForGetHotdealBadgePDP)[0]?.code

const getHotdealBadgePDP = (list) =>
  list.map((voucher) => ({
    ...voucher,
    hot_deal: voucher.badge_type === HOT_DEAL_BADGE,
  }))

const filterVoucherPDP = (voucher, productId) => {
  const {
    applicable_products: { only, except },
    voucher_type,
    show_in_pdp,
  } = voucher
  const isAvailable = only?.includes(productId.toString())
  const isExcept = except?.includes(productId.toString())

  if (
    show_in_pdp &&
    voucher_type !== PARTNER_VOUCHER &&
    (isAvailable || isExcept === false || except === null)
  ) {
    return voucher
  }

  return null
}

const getSubtitleWithOneMinSpend = (list, isAmountType, t) => {
  const {
    condition: { minimum_amount_formatted, maximum_discount_amount_formatted },
  } = list
  const voucherInfo = isAmountType
    ? VOUCHER_LOCIZE.VOUCHER_MIN_SPEND
    : VOUCHER_LOCIZE.VOUCHER_MIN_SPEND_UP_TO

  return hasLocizeTranslation(t, voucherInfo.key, voucherInfo.text)
    .replace(/%d/g, minimum_amount_formatted)
    .replace(/%s/g, maximum_discount_amount_formatted)
}

const getSubtitle = (list, t) => {
  const {
    condition: {
      maximum_discount_amount_formatted,
      minimum_items,
      discount: { type },
    },
  } = list
  const voucherInfo = isPercentageType(type)
    ? VOUCHER_LOCIZE.VOUCHER_MIN_PURCHASE_UP_TO
    : VOUCHER_LOCIZE.VOUCHER_MIN_PURCHASE

  return hasLocizeTranslation(t, voucherInfo.key, voucherInfo.text)
    .replace(/%d/g, minimum_items)
    .replace(/%s/g, maximum_discount_amount_formatted)
}

const setElementsWidth = (numericWidth) => {
  const newWidthInPx = `${numericWidth - 32}px` // subtract width with 32px, making its right margin looks like 32px

  setElementStyleProperty(
    'pdp__voucher-modal__snack-bar',
    {
      key: 'width',
      value: `${numericWidth}px`,
    },
    0,
  )
  setElementStyleProperty(
    'pdp__voucher-modal__piece-coachmark',
    {
      key: 'width',
      value: newWidthInPx,
    },
    0,
  )
  setElementStyleProperty(
    'pdp__voucher-modal__piece-coachmark',
    {
      key: 'min-width',
      value: newWidthInPx,
    },
    0,
  )
}

const getSubtitleWithMoreItem = (voucher, t) => {
  const {
    condition: {
      discount: {
        reduction_amount_formatted,
        reduction_percent_formatted,
        type,
      },
      require_minimum_items,
    },
  } = voucher
  const descText =
    type === VOUCHER_DISCOUNT_TYPE.GET_COF
      ? VOUCHER_CHECKOUT_LOCIZE.VOUCHER_CHECKOUT_COF_MORE_ITEM_DESC
      : VOUCHER_CHECKOUT_LOCIZE.VOUCHER_CHECKOUT_MORE_ITEM_DESC
  const reductionQuantity =
    type === VOUCHER_DISCOUNT_TYPE.AMOUNT
      ? reduction_amount_formatted
      : reduction_percent_formatted
  return hasLocizeTranslation(t, descText.key, descText.text)
    .replace(/%d/g, require_minimum_items || 0)
    .replace(/%s/g, reductionQuantity)
}

const getSubtitleWithMoreSpend = (voucher, t) => {
  const {
    condition: { minimum_amount_formatted, require_minimum_spend },
  } = voucher

  const requireSpendFormatted = minimum_amount_formatted
    ?.replace(/,/g, '')
    .replace(/\d+/g, require_minimum_spend.toLocaleString())

  return hasLocizeTranslation(
    t,
    VOUCHER_CHECKOUT_LOCIZE.VOUCHER_CHECKOUT_MORE_SPEND_DESC.key,
    VOUCHER_CHECKOUT_LOCIZE.VOUCHER_CHECKOUT_MORE_SPEND_DESC.text,
  ).replace(/%d/g, requireSpendFormatted)
}

const getInApplicableVoucherDesc = (voucher, t) => {
  const {
    condition: {
      require_minimum_items,
      discount: { type },
    },
  } = voucher

  return type === VOUCHER_DISCOUNT_TYPE.GET_COF || require_minimum_items > 0
    ? getSubtitleWithMoreItem(voucher, t)
    : getSubtitleWithMoreSpend(voucher, t)
}

const getApplicableVoucherDesc = (list, t) => {
  const {
    condition: {
      discount: { type },
      minimum_items,
    },
  } = list
  const isAmountType = type === VOUCHER_DISCOUNT_TYPE.AMOUNT

  let voucherDesc = null

  if (!minimum_items || minimum_items === 1) {
    voucherDesc = getSubtitleWithOneMinSpend(list, isAmountType, t)
  } else {
    voucherDesc = getSubtitle(list, t)
  }

  return voucherDesc
}

const getVoucherDesc = (isNotApplicable, list, t) =>
  isNotApplicable
    ? getInApplicableVoucherDesc(list, t)
    : getApplicableVoucherDesc(list, t)

const getVoucherTitle = (reductionQuantity, code, type, minimum_items, t) => {
  const titleText =
    type === VOUCHER_DISCOUNT_TYPE.GET_COF
      ? VOUCHER_LOCIZE.VOUCHER_COF_TITLE
      : VOUCHER_LOCIZE.VOUCHER_DISCOUNT_TITLE
  const reductionText =
    type === VOUCHER_DISCOUNT_TYPE.GET_COF
      ? minimum_items - 1
      : reductionQuantity

  return hasLocizeTranslation(t, titleText.key, titleText.text)
    .replace(/%d/g, reductionText)
    .replace(/%s/g, code.toUpperCase())
}

export {
  getVoucherTitle,
  getVoucherDesc,
  setElementsWidth,
  voucherMinimumTypeCompare,
  getHotdealBadgePDP,
  getHotdealVoucherCode,
  getInApplicableVoucherDesc,
  sortVoucherPDP,
  discountTypeCompare,
  isPercentageType,
  filterVoucherPDP,
  sortHotdealVoucherAtTop,
}
