import PropTypes from 'prop-types'
import { withI18next } from 'lib/i18n/withI18next'
import styles from './styles.scss'

const RemoveItemNoted = ({ t }) => (
  <div className="cart-product-noted__remove">
    <style jsx>{styles}</style>
    <span data-cy="cart-product-remove-noted">
      {t('Please remove this item from your bag')}
    </span>
  </div>
)

RemoveItemNoted.propTypes = {
  t: PropTypes.func.isRequired,
}

export default withI18next()(RemoveItemNoted)
