const COUNTRIES_MODAL = {
  default: {
    prefix: '/us/en',
    usp: '$49 USD.',
  },
  22: {
    prefix: '/hk/en',
    usp: 'HK $299',
  },
  21: {
    prefix: '/us/en',
    usp: '$49 USD.',
  },
  24: {
    prefix: '/au/en',
    usp: '$49 AUD.',
  },
  25: {
    prefix: '/sg/en',
    usp: 'Above SGD 40',
  },
  111: {
    prefix: '/id/id',
    usp: 'RP 390,000',
  },
  132: {
    prefix: '/mo/en',
    usp: 'HK $299',
  },
  136: {
    prefix: '/my/en',
    usp: 'RM 99',
  },
  206: {
    prefix: '/th/en',
    usp: '790 ฿.',
  },
  172: {
    prefix: '/ph/en',
    usp: 'PHP 1,495',
  },
  63: {
    prefix: '/kh/en',
    usp: '$49 USD.',
  },
}

const FREE_SHIPPING_OVER_100_USD_COUNTRIES = ['203', '63', '11', '5']

const minOrderFreeDelivery = Object.freeze({
  key: 'MINIMUM_ORDER_FREE_DELIVERY',
  text: 'Free shipping over $150 USD.',
})

export {
  COUNTRIES_MODAL,
  minOrderFreeDelivery,
  FREE_SHIPPING_OVER_100_USD_COUNTRIES,
}
