import React, { Suspense } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import dynamic from 'next/dynamic'
import { withI18next } from 'lib/i18n/withI18next'
import { locizeTranslation } from 'lib/utils/locize'
import Typo from 'constants/typography'
import styles from './style.scss'
import lightIcon from './pml-girl-loader-light.json'
import LoaderLocizes from './const'

const Lottie = dynamic(() => import('lottie-react'), {
  suspense: true,
  ssr: false,
})

const Loader = ({ big, micro, small, white, isMigrate, t }) => {
  const [title, desc_1, desc_2] = locizeTranslation(t, LoaderLocizes)
  const loaderClassNames = clsx('lds-ring slider-loader', {
    'lds-ring-small': small,
    'lds-ring-big': big,
    'lds-ring-micro': micro,
    white,
  })

  const rippleLoader = (
    <div className={loaderClassNames} role="alert">
      <style jsx>{styles}</style>
      <div>&nbsp;</div>
      <div>&nbsp;</div>
      <div>&nbsp;</div>
      <div>&nbsp;</div>
    </div>
  )

  const pmlGirlLoader = (
    <div className="pml-girl-loader">
      <div className="pml-girl-loader__icon">
        <Suspense fallback="Loading...">
          <Lottie animationData={lightIcon} />
        </Suspense>
      </div>
      <div className={Typo.h6}>{title}</div>
      <span className={Typo.body2}>{desc_1}</span>
      <span className={Typo.body2}>{desc_2}</span>
    </div>
  )

  const getLoader = () => {
    let loader = rippleLoader
    if (isMigrate) {
      loader = pmlGirlLoader
    }
    return loader
  }

  return getLoader()
}

Loader.defaultProps = {
  small: false,
  micro: false,
  big: false,
  white: false,
  isMigrate: false,
}

Loader.propTypes = {
  small: PropTypes.bool,
  big: PropTypes.bool,
  micro: PropTypes.bool,
  white: PropTypes.bool,
  isMigrate: PropTypes.bool,
  t: PropTypes.func.isRequired,
}

export default withI18next()(Loader)
