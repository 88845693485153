import { useState, useRef } from 'react'
import { locizeTranslation } from 'lib/utils/locize'
import passwordLocizes from './const'

/**
 *
 * Noted: extract to reduce complexity
 * @param {boolean} isShowPassword
 * @returns {string}
 */
function checkInputType(isShowPassword) {
  return isShowPassword ? 'text' : 'password'
}

function handleOnChange({
  passwordInputRef,
  setIsShowPasswordToggler,
  setIsShowPassword,
  onChange,
}) {
  return (e) => {
    // NOTE: To tackle weird UI issue when show/hide auto filled input.
    requestAnimationFrame(() => {
      // CSS logic not include in testing library
      /* istanbul ignore next */
      try {
        const isPasswordAutoFilled =
          passwordInputRef.current.parentElement.querySelector(
            'input:-webkit-autofill',
          )
        setIsShowPasswordToggler(!isPasswordAutoFilled)

        if (isPasswordAutoFilled) {
          setIsShowPassword(false)
        }
      } catch (err) {
        // Silently failed. (Firefox throw selector error)
      }
    })
    if (typeof onChange === 'function') {
      onChange(e)
    }
  }
}

function useTextFieldPassword({
  inputRef,
  onChange,
  error,
  passwordFormat,
  t,
}) {
  const [
    passwordPolicyLength,
    passwordPolicyUpperCase,
    passwordPolicyLowerCase,
    passwordPolicyNumber,
  ] = locizeTranslation(t, passwordLocizes.labels)
  const passwordInputRef = useRef(null)
  const [isShowPassword, setIsShowPassword] = useState(false)
  const [isShowPasswordToggler, setIsShowPasswordToggler] = useState(true)
  const type = checkInputType(isShowPassword)

  const passwordPolicyLabel = {
    validLength: passwordPolicyLength,
    upperCase: passwordPolicyUpperCase,
    lowerCase: passwordPolicyLowerCase,
    number: passwordPolicyNumber,
  }

  const handleClick = () => {
    setIsShowPassword(!isShowPassword)
  }
  const handlePasswordRef = (ref) => {
    passwordInputRef.current = ref
    if (typeof inputRef === 'function') {
      inputRef(ref)
    }
  }
  const handleChange = handleOnChange({
    passwordInputRef,
    setIsShowPasswordToggler,
    setIsShowPassword,
    onChange,
  })

  const getProgress = () => {
    let progress = 100
    const successCount =
      passwordFormat && Object.values(passwordFormat).reduce((a, b) => a + b)
    progress -= 25 * successCount
    return error ? 0 : progress
  }

  return {
    isShowPassword,
    isShowPasswordToggler,
    type,
    handleClick,
    handlePasswordRef,
    handleChange,
    getProgress,
    passwordPolicyLabel,
  }
}

// eslint-disable-next-line import/prefer-default-export
export { useTextFieldPassword }
