export const SMALL_PHONE = 'smallPhone'
export const PHONE = 'phone'
export const TABLET = 'tablet'
export const DESKTOP = 'desktop'
export const TV = 'tv'
export const HEADER_LARGER = 'headerLarger'

export const smallPhoneWidth = '325px'
export const phoneWidth = '520px'
export const tabletWidth = '768px'
export const desktopWidth = '992px'
export const tvWidth = '1200px'
export const headerLargerWidth = '1455px'
