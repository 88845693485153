const PASSWORD_SALT = '4YWtrSLgpGtMV7Rh8rwTYm0K2r3HYArkiAsXE3qYdksEaXX3T42xsygX'

const LOGIN_TYPE = Object.freeze({
  EMAIL: 'EMAIL',
  PHONE: 'PHONE',
})

const AUTH_ACTION = Object.freeze({
  LOGIN: 'login', // use to make sure that user successfully login (can rename)
  RESET: 'reset',
  SET_PASSWORD: 'set password',
  SIGN_IN: 'signin', // use when signup if user duplicate will return signin
  SIGN_UP: 'signup',
  ABANDONED: 'abandoned migration, password reset required', // when user not success reset password from migration
  PASSWORD_RESET_REQUIRE: 'merge case, password reset required',
  VERIFY_EMAIL: 'verify email',
})

export { PASSWORD_SALT, LOGIN_TYPE, AUTH_ACTION }
