import env from 'config/env'

const ERROR_BEET_UNAVAILABLE = {
  banner: [
    {
      ratio: 1.121875,
      src: `${env.IMG_HOST}img/error/beet_wm.jpg`,
    },
    {
      ratio: 0.333333333,
      src: `${env.IMG_HOST}img/error/beet_w.jpg`,
    },
  ],
  label1: 'beet is currently unavailable in your region',
  label2: 'check back soon!',
  label1Key: 'ERROR_BEET_UNAVAILABLE_LABEL_1',
  label2Key: 'ERROR_BEET_UNAVAILABLE_LABEL_2',
}

export default ERROR_BEET_UNAVAILABLE
