import WishlistApiHandler from 'lib/api/wishlist'
import WishListTabPostfix from 'components/wishlist/wishlist-tabs/tab-postfix'
import { sortButtonLabel, sortButtonValue } from 'pages/wishlist/const'
import { hasLocizeTranslation } from 'lib/utils/locize'
import { getSizeProductSelected } from './wishlist-product/utils'

export const getSort = (sortBy) => {
  switch (sortBy) {
    case sortButtonLabel.HIGH_TO_LOW:
      return sortButtonValue.HIGH_TO_LOW_TEXT
    case sortButtonLabel.LOW_TO_HIGH:
      return sortButtonValue.LOW_TO_HIGH_TEXT
    case sortButtonLabel.RECENTLY_ADDED:
    default:
      return sortButtonValue.RECENTLY_ADDED_TEXT
  }
}

export const getWishListTabs = (
  comingSoon,
  soldOut,
  allProducts,
  inStock,
  t,
) => [
  {
    label: t('All'),
    labelPostfix: <WishListTabPostfix tabCount={allProducts} />,
    content: allProducts > 0,
    name: 'ALL_PRODUCTS',
  },
  {
    label: t('In Stock'),
    labelPostfix: <WishListTabPostfix tabCount={inStock} />,
    content: inStock > 0,
    name: 'IN_STOCK',
  },
  {
    label: hasLocizeTranslation(t, 'COMING_SOON', 'Coming Soon'),
    labelPostfix: <WishListTabPostfix tabCount={comingSoon} />,
    content: comingSoon > 0,
    name: 'COMING_SOON',
  },
  {
    label: t('Sold Out'),
    labelPostfix: <WishListTabPostfix tabCount={soldOut} />,
    content: soldOut > 0,
    name: 'SOLD_OUT',
  },
]

/**
 * @param {Object} user
 * @returns {Promise<Array|null>} Promise resolving to array of wishlist products or null on error
 */
const getAllWishlist = (user) =>
  WishlistApiHandler.getAllWishlist({ userId: user.id_customer })
    .then((wishlist) => wishlist?.products)
    .catch(() => null)

/**
 * @param {number} productId
 * @param {Object} user
 * @returns {Promise<boolean>} Promise resolving to true if product is in wishlist in multiple sizes
 */
export const getWishlistProductMultipleSizes = async (productId, user) => {
  const wishlistProduct = await getAllWishlist(user)

  const wishlistProductMultipleSizes = wishlistProduct.filter(
    (item) => item.id_product === productId,
  )?.length

  return wishlistProductMultipleSizes > 1
}

/**
 * @param {Object} param
 * @param {Object[]} param.products
 * @param {boolean} param.shouldFilterSoldOutProduct
 * @returns {Object[]}
 */
export const filterWishlistProductsToShow = ({
  products,
  shouldFilterSoldOutProduct,
}) =>
  products?.filter((product) => {
    if (shouldFilterSoldOutProduct) {
      return (
        (product.is_sold_out ||
          getSizeProductSelected(product)?.quantity === 0) &&
        !product?.is_coming_soon
      )
    }
    return (
      !product.is_sold_out &&
      (!product.selected_attribute ||
        getSizeProductSelected(product)?.quantity > 0)
    )
  })

export const filterComingSoonWishlistProducts = (products) =>
  products?.filter((product) => product?.is_coming_soon)
