import Router from 'next/router'
import { v4 as uuid } from 'uuid'
import { SEGMENT_STORAGE_KEY_PRODUCT } from 'lib/segment/const'
import { appendObjToSessionObj } from 'lib/utils/common/commonUtils'
import { hasLocizeTranslation } from 'lib/utils/locize'
import ProductApiHandler from 'lib/api/product'
import StateStorage from 'lib/state-storage'
import {
  segmentTrackProductSoldOutNotifyMe,
  segmentTrackProductComingSoonNotifyMe,
} from 'lib/segment'

export const removeFromWishlist = (
  productId,
  invalidateWishlist,
  updateWishlist,
  updateCount,
  wishlistId,
) => {
  invalidateWishlist()

  const options = {
    productId,
    wishlistId,
    type: 'DELETE',
  }

  updateWishlist(options).then(() => {
    updateCount()
  })
}

export const showSizes = (e, setIsShowSelectSize) => {
  e?.preventDefault()

  setIsShowSelectSize(true)
}

export const hasReduction = (product, shop) =>
  shop !== 5 &&
  product?.reduction &&
  product.reduction.type.toLowerCase() === 'percentage'

export const getTagText = (isSoldOut, product, t) => {
  if (!isSoldOut && product.is_low_stock) {
    return hasLocizeTranslation(t, 'ONLY_ITEM_LEFT', 'Only %s left').replace(
      /%s/g,
      product.quantity,
    )
  }
  if (!isSoldOut && product.is_back_in_stock) {
    return t('Back In Stock')
  }

  return hasLocizeTranslation(
    t,
    'SOLD_OUT_ITEM',
    'Sold Out - Notify Me or Find Item at Store',
  )
}

export const onLinkClicked = (e, product, as) => {
  /* istanbul ignore next */
  if (e.ctrlKey || e.metaKey) {
    return
  }

  e?.preventDefault()
  const pageUuid = uuid()
  const href = `/product?id=${product.id_product}&page_uuid=${pageUuid}`

  appendObjToSessionObj(SEGMENT_STORAGE_KEY_PRODUCT.stack, {
    [pageUuid]: {
      click_position: '',
      ref_id: '',
      ref_type: 'wishlist',
      sub_position: '',
    },
  })
  sessionStorage.setItem(SEGMENT_STORAGE_KEY_PRODUCT.id, '')
  sessionStorage.setItem(SEGMENT_STORAGE_KEY_PRODUCT.position, '')
  sessionStorage.setItem(SEGMENT_STORAGE_KEY_PRODUCT.type, 'wishlist')
  sessionStorage.setItem(SEGMENT_STORAGE_KEY_PRODUCT.subPos, '')
  Router.push(href, as)
}

/**
 * @param {boolean} isSoldOut
 * @param {boolean} saveForLaterFlagValue
 * @param {Object} selectedProductAttribute
 * @param {boolean} selectedProductAttribute.is_on_waitlist
 * @param {number} selectedProductAttribute.quantity
 * @param {function} t - a translation function that takes a key and returns the translated string.
 * @returns {string}
 */
export const getButtonLabel = (
  isSoldOut,
  saveForLaterFlagValue,
  selectedProductAttribute,
  t,
  isAllAttributeWaitlisted,
  is_coming_soon,
) => {
  if (isAllAttributeWaitlisted) return t('Waitlisted')

  if (is_coming_soon) return t('Coming Soon')

  if (isSoldOut || selectedProductAttribute?.quantity === 0) {
    return selectedProductAttribute?.is_on_waitlist
      ? t('Waitlisted')
      : t('Notify Me')
  }

  return saveForLaterFlagValue
    ? hasLocizeTranslation(t, 'MOVE_TO_BAG_CTA', 'Move To Bag')
    : t('Add To Bag')
}

/**
 * @param {object} product
 * @param {array[]} product.attributes
 * @returns {boolean}
 */
export const isOneSize = (product) =>
  product?.attributes?.length === 1 &&
  product?.attributes[0].name === 'One Size'

/**
 * @param {object} product
 * @param {array[]} product.attributes
 * @param {object} product.selected_attributes
 * @param {number} product.selected_attributes.id_product_attribute
 * @returns {string}
 */
export const getSizeProductSelected = (product) => {
  if (isOneSize(product)) {
    return product?.attributes[0]
  }

  return product?.attributes?.find(
    (item) =>
      item.id_attribute === product?.selected_attribute?.id_product_attribute,
  )
}

/**
 * @param {number} product_id
 * @returns {Promise<Object>} A promise that resolves with the product data
 */
const getProductData = async (product_id) => {
  const product = await ProductApiHandler.getProduct({
    token: null,
    product_id,
    isGuestMode: StateStorage.isGuestMode(),
  })

  return product
}

/**
 * @param {boolean} isSoldOut
 * @param {Object} selectedProductAttribute
 * @returns {boolean}
 */
const sizeIsNotAvailable = (isSoldOut, selectedProductAttribute) =>
  !selectedProductAttribute || isSoldOut

/**
 * @param {Object} selectedProductAttribute
 * @param {number} selectedProductAttribute.quantity
 * @param {boolean} selectedProductAttribute.is_on_waitlist
 * @returns {boolean}
 */
export const selectedSizeIsSoldOutAndInWaitlisted = (
  selectedProductAttribute,
) =>
  selectedProductAttribute?.quantity === 0 &&
  selectedProductAttribute?.is_on_waitlist

/**
 * @param {Object} selectedProductAttribute
 * @param {number} selectedProductAttribute.quantity
 * @param {boolean} selectedProductAttribute.is_on_waitlist
 * @returns {boolean}
 */
const selectedSizeIsSoldOutAndNotInWaitlisted = (selectedProductAttribute) =>
  selectedProductAttribute.quantity === 0 &&
  !selectedProductAttribute.is_on_waitlist

/**
 * @param {Object} params
 * @param {Function} params.addToWaitlist - Function to add to waitlist
 * @param {Function} params.handleUpdateWishlist - Function to update wishlist
 * @param {Object} params.wishlistProduct - Wishlist product object
 * @param {Object} params.selectedProductAttribute - Selected product attribute
 */
const addWishlistProductToWaitlist = ({
  addToWaitlist,
  handleUpdateWishlist,
  wishlistProduct,
  selectedProductAttribute,
}) => {
  const segmentData = {
    product: {
      ...wishlistProduct,
      price: wishlistProduct.discounted_price || wishlistProduct.price,
    },
    sizeData: {
      id_product_attribute: selectedProductAttribute.id_attribute,
      size: selectedProductAttribute.name,
    },
  }
  const isComingSoon = segmentData?.product?.is_coming_soon

  addToWaitlist({
    product: wishlistProduct,
    sizeData: {
      id_product_attribute: selectedProductAttribute.id_attribute,
    },
    onError: () => handleUpdateWishlist(),
    onSuccess: () => {
      if (isComingSoon) {
        segmentTrackProductComingSoonNotifyMe(segmentData, null, true)
      } else {
        segmentTrackProductSoldOutNotifyMe(segmentData, null, true)
      }
      handleUpdateWishlist()
    },
  })
}

/**
 * @param {Event} e - Click event
 * @param {Object} params
 * @param {Object} params.wishlistProduct - Wishlist product
 * @param {Object} params.selectedProductAttribute - Selected attribute
 * @param {Function} params.setIsShowSelectSize - Set size selector state
 * @param {Function} params.addToWaitlist - Add product to waitlist
 * @param {Function} params.addWishlistToBag - Add wishlist product to bag
 * @param {Function} params.closeAddToBagModal - Close add to bag modal
 * @param {Function} params.handleAddToBagSuccess - Success callback
 * @param {Function} params.handleDeleteWishlist - Delete from wishlist callback
 * @param {Function} params.handleUpdateWishlist - Update wishlist callback
 * @param {boolean} params.isSoldOut - Whether product is sold out
 */
export const onButtonClick = async (
  e,
  {
    wishlistProduct,
    selectedProductAttribute,
    setIsShowSelectSize,
    setShouldMoveToBagAfterSelectedSize,
    addToWaitlist,
    addWishlistToBag,
    closeAddToBagModal,
    handleAddToBagSuccess,
    handleDeleteWishlist,
    handleUpdateWishlist,
    isSoldOut,
  },
) => {
  if (sizeIsNotAvailable(isSoldOut, selectedProductAttribute)) {
    showSizes(e, setIsShowSelectSize)
    setShouldMoveToBagAfterSelectedSize(true)
  } else if (
    selectedSizeIsSoldOutAndNotInWaitlisted(selectedProductAttribute)
  ) {
    addWishlistProductToWaitlist({
      addToWaitlist,
      handleUpdateWishlist,
      selectedProductAttribute,
      wishlistProduct,
    })
    setShouldMoveToBagAfterSelectedSize(false)
  } else {
    const product = await getProductData(wishlistProduct.id_product)
    addWishlistToBag({
      product,
      sizeData: {
        id_product_attribute: selectedProductAttribute.id_attribute,
        size: selectedProductAttribute.name,
      },
      onError: closeAddToBagModal,
      onSuccess: handleAddToBagSuccess,
      onFinish: handleDeleteWishlist,
      refData: {
        ref_id: wishlistProduct?.id_product,
        ref_type: 'wishlist',
      },
    })
    setShouldMoveToBagAfterSelectedSize(false)
  }
}
