import ICONS from 'components/common/icon/const'
import { getAssetPath } from 'lib/utils/common/commonUtils'

export const hoolahModalText = {
  descriptionTitle: {
    key: 'PDP_HOOLAH_INFO_MODAL_DESC_TITLE',
    value: 'Get what you love now. Pay only part upfront today.',
  },
  descriptionSubTitle: {
    key: 'PDP_HOOLAH_INFO_MODAL_DESC_SUB_TITLE',
    value: '3 installments. No interest. No hidden fees.',
  },
  headerTitle: {
    key: 'PDP_HOOLAH_INFO_MODAL_TITLE',
    value: 'What’s Hoolah?',
  },
  learnMoreCta: {
    key: 'PDP_HOOLAH_INFO_MODAL_CTA',
    value: 'Learn More',
  },
}

export const hoolahInfoList = [
  {
    icon: ICONS.pickupBag,
    title: {
      key: 'PDP_HOOLAH_INFO_MODAL_INFO_1_TITLE',
      value: 'No-hassle installation.',
    },
    description: {
      key: 'PDP_HOOLAH_INFO_MODAL_INFO_1_SUB_TITLE',
      value:
        'Get what you need right now and split the cost of your purchase into 3 interest-free payments.',
    },
  },
  {
    icon: ICONS.creditCard,
    title: {
      key: 'PDP_HOOLAH_INFO_MODAL_INFO_2_TITLE',
      value: 'No credit card. No worries.',
    },
    description: {
      key: 'PDP_HOOLAH_INFO_MODAL_INFO_2_SUB_TITLE',
      value:
        'Choose Hoolah in checkout. We accept both credit and debit cards.',
    },
  },
]

export const hoolahUrl = Object.freeze({
  sg: 'https://merchant.cdn.hoolah.co/0dfb3cfa-7454-51ad-bdad-cb3ee3765299/hoolah-explainer.html',
  my: 'https://merchant.cdn.hoolah.co/2baf0ccd-b328-5b15-9ca8-443d4a86e13a/hoolah-explainer.html',
  th: '',
})

const ASSET_PREFIX = `${getAssetPath()}/static/img/hoolah`

const ASSETS = {
  HOOLAH_LOGO: `${ASSET_PREFIX}/shopback-logo.svg`,
}

export default ASSETS
