import {
  AVAILABLE_SHOP_LANG_ISO_CODE,
  ENABLE_SHOP,
} from 'constants/shop-language'

const VALID_SHOP = ENABLE_SHOP.map((shop) => shop.slug)

export function getPathnameWithoutShopAndLang(pathname = '') {
  if (!pathname) {
    return ''
  }

  const shopRemoved = pathname.substring(pathname.indexOf('/') + 1)
  const langRemoved = shopRemoved.substring(shopRemoved.indexOf('/') + 1)
  const result = langRemoved.substring(langRemoved.indexOf('/') + 1)
  return result.endsWith('/') ? result.slice(0, -1) : result
}

export default class UrlUtils {
  static getLocation = (href) => {
    const match = href.match(
      /^(https?:)\/\/(([^:/?#]*)(?::([0-9]+))?)([/]{0,1}[^?#]*)(\?[^#]*|)(#.*|)$/,
    )

    return (
      match && {
        href,
        protocol: match[1],
        host: match[2],
        hostname: match[3],
        port: match[4],
        pathname: match[5],
        search: match[6],
        hash: match[7],
      }
    )
  }

  static getPathWithoutLocale = (href) => {
    if (!href || typeof href !== 'string') return null

    const urlTokens = href.split('/')
    return urlTokens.pop() || urlTokens[urlTokens.length - 2]
  }

  // eslint-disable-next-line consistent-return
  static getPathWithoutShopAndLang(path) {
    if (path && typeof path === 'string') {
      const urlParts = path.split('/')
      const firstPath = urlParts[0]
      let start = 0

      if (firstPath && ['http:', 'https:'].includes(firstPath.toLowerCase())) {
        start = 2
        urlParts[0] = ''
        urlParts[1] = ''
        urlParts[2] = ''
      }

      const shopSlug = urlParts[start + 1]
      const langSlug = urlParts[start + 2]

      if (shopSlug && VALID_SHOP.includes(shopSlug.toLowerCase())) {
        urlParts[start + 1] = ''
      }

      if (
        langSlug &&
        AVAILABLE_SHOP_LANG_ISO_CODE.includes(langSlug.toLowerCase())
      ) {
        urlParts[start + 2] = ''
      }

      return urlParts.reduce(
        // eslint-disable-next-line no-return-assign, no-param-reassign
        (fullUrl, part) => (fullUrl += part ? `/${part}` : ''),
        '',
      )
    }
  }

  static shallowClearHashAndQuery(router) {
    const sanitizedCurAsPath =
      !!router.asPath && router.asPath.split('?')[0].split('#')[0]

    router.replace(router.pathname, `${sanitizedCurAsPath}`, { shallow: true })
  }

  static shallowPushHash(router, postfix = 'hash') {
    const sanitizedCurAsPath =
      !!router.asPath && router.asPath.split('?')[0].split('#')[0]

    router.push(router.pathname, `${sanitizedCurAsPath}#${postfix}`, {
      shallow: true,
    })
  }
}

export const getDecodedUrlWithQueryString = () => {
  const windowLocation = window.location.href
  const myLocation = windowLocation

  const decodedFinal = myLocation.replace(/\//g, '%2F')

  return decodedFinal.replace(/:/g, '%3A')
}

export const getDecodedUrlWithoutQueryString = () => {
  const windowLocation = window.location.href
  const hasQS = windowLocation.indexOf('?')
  let myLocation = windowLocation

  if (hasQS > 0) {
    myLocation = windowLocation.substring(0, windowLocation.lastIndexOf('?'))
  }

  const decodedFinal = myLocation.replace(/\//g, '%2F')

  return decodedFinal.replace(/:/g, '%3A')
}
