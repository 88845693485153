import { useState, useEffect } from 'react'
import { fetchPersonalizedRecommendedProducts } from './utils'

const usePersonalizedRecommendation = (
  auth,
  productId,
  isShoppingBag,
  limit,
  skip,
) => {
  const [list, setList] = useState({})
  const [isFetched, setIsFetched] = useState(false)

  useEffect(() => {
    let ignore = false
    if (auth?.isGuestMode !== null && auth?.isGuestMode !== undefined) {
      fetchPersonalizedRecommendedProducts(
        auth,
        productId,
        isShoppingBag || false,
        setList,
        setIsFetched,
        ignore,
        { limit, skip },
      )
    }

    return () => {
      ignore = true
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.isGuestMode, productId, isShoppingBag])

  return [list, isFetched]
}

export default usePersonalizedRecommendation
