import clsx from 'clsx'
import PropTypes from 'prop-types'
import style from './style.scss'

const FormItem = ({ children, className }) => {
  const classes = clsx('pml-form-row', className)

  return (
    <div className={classes}>
      <style jsx>{style}</style>
      {children}
    </div>
  )
}

FormItem.defaultProps = {
  children: undefined,
  className: undefined,
}

FormItem.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
}

export default FormItem
