import React, { useRef, useState } from 'react'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import authDuck from 'components/auth/duck'
import cartItemDuck from 'components/cart/cart-items/duck'
import wishlistDuck from 'components/wishlist/duck'
import StateStorage from 'lib/state-storage'
import { withI18next } from 'lib/i18n/withI18next'
import { hasLocizeTranslation } from 'lib/utils/locize'
import { useFlagsmithGetFlagValue } from 'lib/flagsmith'
import {
  saveClosedCoachMarkToStorage,
  getIsCoachMarkClosed,
} from 'pages/product/utils'
import Button from 'components/common/button'
import { BUTTON_TYPES } from 'constants/button'
import Coachmark from 'components/common/coachmark'
import { COACHMARK_POSITION } from 'components/common/coachmark/const'
import { moveProductToWishlist } from 'components/cart/utils'
import styles from './styles.scss'

const CartMoveToWishlistButton = ({
  addWishlist,
  deleteFromCart,
  updateWishlist,
  invalidateWishlist,
  product,
  showMoveToBagCoachmark,
  t,
  updateShowLogin,
  updateSnackbar,
}) => {
  const cartMoveToWishlistRef = useRef(null)
  const isCoachMarkClosed = getIsCoachMarkClosed(
    'cart_move_to_wishlist_coach_mark',
    true,
  )
  const [isOpenCoachmark, setIsOpenCoachmark] = useState(
    showMoveToBagCoachmark && !isCoachMarkClosed,
  )
  const saveForLaterFlagValue = useFlagsmithGetFlagValue(
    'SaveForLater_20230704',
    false,
  )

  if (!saveForLaterFlagValue || product?.type === 'gift') {
    return null
  }

  const saveCoachMarkClosed = () => {
    setIsOpenCoachmark(false)
    saveClosedCoachMarkToStorage('cart_move_to_wishlist_coach_mark', true)
  }

  const clickMoveToWishlist = async () => {
    if (StateStorage.isGuestMode()) {
      updateShowLogin(true)
    } else {
      deleteFromCart().then(() =>
        moveProductToWishlist({
          addWishlist,
          updateWishlist,
          invalidateWishlist,
          product,
          updateSnackbar,
          t,
        }),
      )
    }
  }

  return (
    <div className="cart-product-move-to-wishlist">
      <style jsx>{styles}</style>
      <Button type={BUTTON_TYPES.text} onClick={clickMoveToWishlist}>
        {hasLocizeTranslation(
          t,
          'CART_MOVE_TO_WISHLIST_CTA',
          'Move to Wishlist',
        )}
      </Button>
      <Coachmark
        onClose={saveCoachMarkClosed}
        position={COACHMARK_POSITION.TOP_LEFT}
        text={hasLocizeTranslation(
          t,
          'CART_MOVE_TO_WISHLIST_COACHMARK',
          'Now you can move your products to wishlist',
        )}
        isOpen={isOpenCoachmark}
        customContainerProps={{
          style: {
            top: '12px',
            width: 'fit-content',
          },
        }}
        disableLeaveListener
        disableEnterListener
        customChildrenRef={cartMoveToWishlistRef}
      />
    </div>
  )
}

CartMoveToWishlistButton.defaultProps = {
  showMoveToBagCoachmark: false,
}

CartMoveToWishlistButton.propTypes = {
  addWishlist: PropTypes.func.isRequired,
  deleteFromCart: PropTypes.func.isRequired,
  updateWishlist: PropTypes.func.isRequired,
  invalidateWishlist: PropTypes.func.isRequired,
  product: PropTypes.shape({
    id_product: PropTypes.number,
    selectedAttributes: PropTypes.shape({
      id_product_attribute: PropTypes.number,
    }),
    type: PropTypes.string,
  }).isRequired,
  showMoveToBagCoachmark: PropTypes.bool,
  t: PropTypes.func.isRequired,
  updateShowLogin: PropTypes.func.isRequired,
  updateSnackbar: PropTypes.func.isRequired,
}

export default compose(
  connect(
    () => ({}),
    (dispatch) =>
      bindActionCreators(
        {
          addWishlist: (option) =>
            wishlistDuck.creators.get({ ...option, type: 'ADD' }),
          updateWishlist: wishlistDuck.creators.get,
          invalidateWishlist: wishlistDuck.creators.invalidate,
          updateShowLogin: authDuck.creators.updateShowLogin,
          updateSnackbar: cartItemDuck.creators.updateSnackbar,
        },
        dispatch,
      ),
  ),
  withI18next(),
)(CartMoveToWishlistButton)
