import clsx from 'clsx'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import ICONS from 'components/common/icon/const'
import { withI18next } from 'lib/i18n/withI18next'
import { covertPropertiesToCamelCase } from 'lib/utils/common/object'
import { getShopInfo } from 'lib/utils/shop-lang'
import usePomeloCashbackEnable from 'pages/user/pomelo-credit/credit-cashback/usePomeloCashbackEnable'
import styles from './style.scss'
import UspBody from './usp-body'

const FooterUsp = ({
  internationalization: { shop },
  isCategoryPage,
  isHoolahActive,
  noFooterTopMargin,
}) => {
  const isCashbackEnable = usePomeloCashbackEnable(shop)
  const currentShopInfo = getShopInfo({
    key: 'id_shop',
    value: shop,
  })

  const { isEnableCashOnDelivery, isFreeReturnAble } =
    covertPropertiesToCamelCase(currentShopInfo)

  const footerItemByOrder = {
    loyalty: {
      shouldRender: isCashbackEnable,
    },
    weeklyArrival: {
      shouldRender: true,
      iconSrc: ICONS.uspNewArrivalsGhost,
      subtitle: {
        key: 'FOOTER_USP_WEEKLY_TITLE',
        defaultVal: 'Weekly New',
      },
      caption: {
        key: 'FOOTER_USP_WEEKLY_SUBTITLE',
        defaultVal: 'Arrivals',
      },
    },
    freeDelivery: {
      shouldRender: true,
      iconSrc: ICONS.deliveryGhost,
      subtitle: {
        key: 'FOOTER_USP_FREE_DELIVERY_TITLE',
        defaultVal: 'Free Delivery',
      },
      caption: {
        key: 'FOOTER_USP_FREE_DELIVERY_SUBTITLE',
        defaultVal: 'ON ORDERS UP TO $49 USD',
      },
    },
    freeReturn: {
      shouldRender: isFreeReturnAble,
      iconSrc: ICONS.deliveryGhost,
      subtitle: {
        key: 'FOOTER_USP_365_DAYS_TITLE',
        defaultVal: '365 Day Returns',
      },
      caption: {
        key: 'FOOTER_USP_365_DAYS_FOR_FREE',
        defaultVal: 'For Free',
      },
    },
    cashOnDelivery: {
      shouldRender: isEnableCashOnDelivery,
      iconSrc: ICONS.codGhost,
      subtitle: {
        key: 'FOOTER_USP_CASH_ON_TITLE',
        defaultVal: 'Cash On Delivery',
      },
    },
    hoolahInstallment: {
      shouldRender: isHoolahActive,
      iconSrc: ICONS.creditCardGhost,
      subtitle: {
        key: 'FOOTER_USP_HOOLAH_INSTALLMENT_TITLE',
        defaultVal: 'Monthly Installments',
      },
      caption: {
        key: 'FOOTER_USP_HOOLAH_INSTALLMENT_SUB_TITLE',
        defaultVal: '0% interest for 3 months',
      },
    },
  }

  return (
    <div
      className={clsx('footer-usp-container', {
        'on-category-page': isCategoryPage,
        'no-top-margin': noFooterTopMargin,
      })}
    >
      <style jsx>{styles}</style>
      <ul>
        <UspBody footerItemByOrder={footerItemByOrder} />
      </ul>
    </div>
  )
}

FooterUsp.defaultProps = {
  noFooterTopMargin: false,
}

FooterUsp.propTypes = {
  internationalization: PropTypes.shape({
    shop: PropTypes.number,
  }).isRequired,
  isCategoryPage: PropTypes.bool.isRequired,
  isHoolahActive: PropTypes.bool.isRequired,
  noFooterTopMargin: PropTypes.bool,
}

const Extended = withI18next()(FooterUsp)

export default connect((state) => ({
  internationalization: state.internationalization,
}))(Extended)
