import PropTypes from 'prop-types'
import clsx from 'clsx'
import React, { useCallback, useEffect, useState } from 'react'
import Typo from 'constants/typography'
import { BUTTON_HTML_TYPE } from 'constants/button'
import { parseCyKey } from 'lib/utils/cypress'
import styles from './style.scss'

/**
 *
 * @param {string} param0.className default undefined. additional classname to the tab wrapper.
 * @param {bool} param0.disabled disable all tab. the component can't be change selected item.
 * @param {function} param0.onChange callback event when selected tab was changed
 * @param {number} param0.selectedIndex default tab that be selected at start
 * @param {array} param0.tabs list of tab's item
 */
const Tabs = ({ className, disabled, onChange, selectedIndex, tabs }) => {
  const [selected, setSelected] = useState(selectedIndex)

  const handleChangeTab = useCallback(
    (tabIndex, callback) => () => {
      setSelected(tabIndex)
      onChange(tabIndex)
      if (callback) {
        callback(tabIndex)
      }
    },
    [onChange],
  )

  useEffect(() => {
    setSelected(selectedIndex)
  }, [selectedIndex])

  if (
    selectedIndex === null ||
    selectedIndex >= tabs.length ||
    selectedIndex < 0
  ) {
    return <span className={Typo.caption}>Index is out of range</span>
  }

  return (
    <div className={clsx('tabs__container', className)}>
      <style jsx>{styles}</style>
      {tabs?.map((tab, index) => (
        <button
          key={tab.label}
          type={BUTTON_HTML_TYPE.button}
          onClick={handleChangeTab(index, tab.onClick)}
          className={clsx(
            'tabs__item',
            { disabled: tab.disabled },
            { selected: index === selected },
          )}
          disabled={disabled || tab.disabled}
          data-cy={parseCyKey(`tabs__item_${tab.label}`)}
        >
          <span
            style={{
              color: tab.labelColor,
            }}
            className={Typo.subtitle2}
          >
            {tab.label}
          </span>
          {tab.labelPostfix}
          <div
            style={{
              backgroundColor: tab.indicatorColor,
            }}
            data-cy={`tabs-indicator-${tab.label}`}
            className={clsx('tabs__indicator', {
              selected: index === selected,
            })}
          />
        </button>
      ))}
    </div>
  )
}

Tabs.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  selectedIndex: PropTypes.number,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      onClick: PropTypes.func,
      disabled: PropTypes.bool,
      labelPostfix: PropTypes.node,
      indicatorColor: PropTypes.string,
    }),
  ),
}

Tabs.defaultProps = {
  className: undefined,
  disabled: false,
  onChange: undefined,
  selectedIndex: undefined,
  tabs: [],
}

export default Tabs
