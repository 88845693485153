import React, { useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import Tabs from 'components/common/tabs'
import Typo from 'constants/typography'
import { hasLocizeTranslation } from 'lib/utils/locize'
import { withI18next } from 'lib/i18n/withI18next'
import AuthFooter from '../footer'
import style from './style.scss'
import SignupForm from '../signup-form/SignupForm'
import AuthPhone from '../shared/phone/AuthPhoneBase'
import { useAuth } from '../context'
import { AUTH_SIGNUP_METHODS } from '../const'

const AuthSignUp = ({ t }) => {
  const [authState, updateAuthState] = useAuth()
  const [activeTab, setActiveTab] = useState(
    authState.signUpMethod === AUTH_SIGNUP_METHODS.PHONE ? 1 : 0,
  )
  const TABS_DATA = useMemo(
    () => [
      {
        label: 'Email',
        component: <SignupForm activeTab={activeTab} />,
      },
      { label: 'Phone', component: <AuthPhone isRegister /> },
    ],
    [activeTab],
  )

  const changeTab = (val) => {
    setActiveTab(val)
    updateAuthState({ isSelectSignUpMethod: false })
  }

  return (
    <React.Fragment>
      <style jsx>{style}</style>
      <span className={clsx(Typo.h4, 'auth__title')}>
        {hasLocizeTranslation(
          t,
          'SIGNUP_DISCOUNT_TITLE',
          'Sign up now to receive 20% off your first order',
        )}
      </span>
      <div className={clsx(Typo.body2, 'auth__desc')}>
        {t(
          'Be the first to learn about new items, secret sales, upcoming trends, and more!',
        )}
      </div>
      <Tabs
        className="auth__tabs"
        onChange={changeTab}
        selectedIndex={activeTab}
        tabs={TABS_DATA}
      />
      {authState.isSelectSignUpMethod ? (
        <SignupForm activeTab={activeTab} />
      ) : (
        TABS_DATA[activeTab].component
      )}
      <AuthFooter />
    </React.Fragment>
  )
}

AuthSignUp.propTypes = {
  t: PropTypes.func.isRequired,
}

export default withI18next()(AuthSignUp)
