import React, { useCallback, useEffect, useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import Tabs from 'components/common/tabs'
import Typo from 'constants/typography'
import { withI18next } from 'lib/i18n/withI18next'
import AuthFooter from '../footer'
import AuthPhone from '../shared/phone/AuthPhoneBase'
import AuthEmail from '../shared/email'
import { AUTH_INIT_STATE, useAuth } from '../context'

const AuthLogIn = ({ t, closeModal }) => {
  const [authState, updateAuthState] = useAuth()
  const { dataPhone } = authState
  const [activeTab, setActiveTab] = useState(!dataPhone ? 0 : 1)
  const TABS_DATA = useMemo(
    () => [
      { label: 'Email', component: <AuthEmail closeModal={closeModal} /> },
      { label: 'Phone', component: <AuthPhone /> },
    ],
    [closeModal],
  )

  const changeTab = useCallback((val) => {
    setActiveTab(val)
  }, [])

  useEffect(() => {
    updateAuthState(AUTH_INIT_STATE)
  }, [updateAuthState])

  return (
    <React.Fragment>
      <span
        className={clsx(Typo.h4, 'auth__title')}
        data-cy="auth__login__title"
      >
        {t('Log In')}
      </span>
      <Tabs
        className="auth__tabs"
        onChange={changeTab}
        selectedIndex={activeTab}
        tabs={TABS_DATA}
      />
      {TABS_DATA[activeTab].component}
      <AuthFooter />
    </React.Fragment>
  )
}

AuthLogIn.propTypes = {
  t: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
}

export default withI18next()(AuthLogIn)
