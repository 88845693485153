import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { withI18next } from 'lib/i18n/withI18next'
import { hasLocizeTranslation } from 'lib/utils/locize'
import Typo from 'constants/typography'
import { FOOTER_KCG_ADDRESS } from 'components/footer/const'

const FooterBandSection = ({
  footerContent,
  internationalization: { country },
  t,
}) => {
  const shouldShowKCGAddress = country === 'id'
  const KCG_ADDRESS_LIST = hasLocizeTranslation(
    t,
    FOOTER_KCG_ADDRESS.key,
    FOOTER_KCG_ADDRESS.text,
  ).split('\n')

  return (
    <React.Fragment>
      {footerContent.map(({ key, brand_content, locizeKey }) => (
        <span key={key} className={Typo.body2}>
          {hasLocizeTranslation(t, locizeKey, brand_content)}
        </span>
      ))}
      {shouldShowKCGAddress && (
        <div className="footer-section-address">
          {KCG_ADDRESS_LIST.map((text, idx) => (
            <span key={idx} className={Typo.body2}>
              {text}
            </span>
          ))}
        </div>
      )}
    </React.Fragment>
  )
}

FooterBandSection.propTypes = {
  footerContent: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  internationalization: PropTypes.shape({
    country: PropTypes.string,
  }).isRequired,
  t: PropTypes.func.isRequired,
}

export default compose(
  connect((state) => ({
    internationalization: state.internationalization,
  })),
  withI18next(),
)(FooterBandSection)
