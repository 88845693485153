import React from 'react'
import PropType from 'prop-types'
import { connect } from 'react-redux'
import clsx from 'clsx'
import { withI18next } from 'lib/i18n/withI18next'
import Typo from 'constants/typography'
import { hasLocizeTranslation } from 'lib/utils/locize'
import { BUTTON_TYPES } from 'constants/button'
import Button from 'components/common/button'
import {
  signInWithFacebook,
  signInWithGoogle,
  successfulLogInFireBase,
} from 'lib/auth'
import { segmentPDPAConsentResponded } from 'lib/segment'
import { useAuth } from '../context'
import AuthPDPAContent from '../pdpa'
import { AUTH_ERROR, AUTH_ERROR_MERGE, AUTH_UI_STATE } from '../const'

const AuthThirdPartyVerify = ({ t }) => {
  const [authState, updateAuthState] = useAuth()
  const { source } = authState
  const isFaceBookClicked =
    source[0] === 'Facebook' || source[1] === 'facebook.com'
  const buttonTitle = isFaceBookClicked
    ? hasLocizeTranslation(
        t,
        'THIRD_PARTY_PDPA_BUTTON_FACEBOOK',
        'Agree & Log In with Facebook',
      )
    : hasLocizeTranslation(
        t,
        'THIRD_PARTY_PDPA_BUTTON_GOOGLE',
        'Agree & Log In with Google',
      )

  const handleError = (err) => {
    if (
      [AUTH_ERROR.popUpCancelled, AUTH_ERROR.popUpClosed].includes(err.code)
    ) {
      updateAuthState({ overlayLoading: false })
    } else if (AUTH_ERROR_MERGE.includes(err.code)) {
      updateAuthState({ dataEmail: err.email, uiState: AUTH_UI_STATE.merge })
    } else {
      updateAuthState({ overlayLoading: false, snackBar: err })
    }
  }

  const legacySignIn = async () => {
    const res = isFaceBookClicked
      ? await signInWithFacebook()
      : await signInWithGoogle()

    if (res) {
      successfulLogInFireBase(res)
      segmentPDPAConsentResponded()

      const customer = res?.customer
      // eslint-disable-next-line
      smartech(`contact`, 2, {
        'pk^customerid': `${customer?.id_customer}`,
        email: customer?.email,
        FIRST_NAME: customer?.firstname,
      })
      // eslint-disable-next-line
      smartech(`identify`, `${customer?.id_customer}`)
      // eslint-disable-next-line
      smartech(`dispatch`, `sign_in`, {
        email: customer?.email,
        first_name: customer?.firstname,
      })
    }
  }

  const handleLoginWith3Party = async () => {
    try {
      await legacySignIn()
    } catch (err) {
      handleError(err)
    }
  }

  return (
    <React.Fragment>
      <span className={clsx(Typo.h4, 'auth__title')}>
        {hasLocizeTranslation(
          t,
          'THIRD_PARTY_PDPA_TITLE',
          'Review the Terms to Sign Up',
        )}
      </span>
      <AuthPDPAContent is3rdParty />
      <Button
        className="auth__standalone-cta full-width submit"
        type={BUTTON_TYPES.primary}
        onClick={handleLoginWith3Party}
      >
        {buttonTitle}
      </Button>
    </React.Fragment>
  )
}

AuthThirdPartyVerify.propTypes = {
  t: PropType.func.isRequired,
}

const Extended = withI18next()(AuthThirdPartyVerify)

export default connect((state) => ({
  isPhone: state.device.isPhone,
}))(Extended)
