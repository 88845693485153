import { useState } from 'react'
import Time from 'lib/utils/time'
import useInterval from '../useInterval'

const DEFAULT_DELAY = 1000

function getDelayFromExpiryTimestamp(expiryTimestamp) {
  if (!Time.isValidExpiryTimeStamp(expiryTimestamp)) {
    return null
  }

  const seconds = Time.getSecondsFromExpiry(expiryTimestamp)
  const milliSeconds = Math.trunc(seconds - Math.trunc(seconds) * 1000)
  return milliSeconds > 0 ? milliSeconds : DEFAULT_DELAY
}

/**
 * Noted: extract to reduce complexity
 */
function handleInterval({
  delay,
  expiryTimestamp,
  handleExpire,
  setDelay,
  setSeconds,
}) {
  if (delay !== DEFAULT_DELAY) {
    setDelay(DEFAULT_DELAY)
  }
  const secondsValue = Time.getSecondsFromExpiry(expiryTimestamp)
  setSeconds(secondsValue)
  if (secondsValue <= 0) {
    handleExpire()
  }
}

export default function useTimer({
  expiryTimestamp: expiry,
  onExpire,
  autoStart,
}) {
  const [expiryTimestamp, setExpiryTimestamp] = useState(expiry)
  const [seconds, setSeconds] = useState(
    Time.getSecondsFromExpiry(expiryTimestamp),
  )
  const [isRunning, setIsRunning] = useState(autoStart)
  const [didStart, setDidStart] = useState(autoStart)
  const [delay, setDelay] = useState(
    getDelayFromExpiryTimestamp(expiryTimestamp),
  )

  function handleExpire() {
    if (typeof onExpire === 'function') {
      onExpire()
    } else if (onExpire) {
      // eslint-disable-next-line no-console
      console.warn('useTimer: Expect onExpire as function')
    }
    setIsRunning(false)
    setDelay(null)
  }

  function pause() {
    setIsRunning(false)
  }

  function restart(newExpiryTimestamp, newAutoStart = true) {
    setDelay(getDelayFromExpiryTimestamp(newExpiryTimestamp))
    setDidStart(newAutoStart)
    setIsRunning(newAutoStart)
    setExpiryTimestamp(newExpiryTimestamp)
    setSeconds(Time.getSecondsFromExpiry(newExpiryTimestamp))
  }

  function resume() {
    const time = new Date()
    time.setMilliseconds(time.getMilliseconds() + seconds * 1000)
    restart(time)
  }

  function start() {
    if (didStart) {
      setSeconds(Time.getSecondsFromExpiry(expiryTimestamp))
      setIsRunning(true)
    } else {
      resume()
    }
  }

  useInterval(
    () => {
      handleInterval({
        delay,
        expiryTimestamp,
        handleExpire,
        setDelay,
        setSeconds,
      })
    },
    isRunning ? delay : null,
  )

  return {
    ...Time.getTimeFromSeconds(seconds),
    isRunning,
    start,
    pause,
    restart,
  }
}
