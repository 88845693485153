import {
  discountTypeCompare,
  isPercentageType,
  voucherMinimumTypeCompare,
} from 'components/pdp/carousel-images/voucher/utils'
import { HOT_DEAL_BADGE, PARTNER_VOUCHER } from './const'
import { VOUCHER_DISCOUNT_TYPE } from '../const'

const requireMinimumTypeCompare = (require_minimum_items) =>
  require_minimum_items ? 1 : 0

const sortMinimumConditionVoucher = (a, b, applicableOrder, discountOrder) => {
  const minimumSpendOrder =
    a.condition.minimum_amount - b.condition.minimum_amount
  const minimumItemOrder = a.condition.minimum_items - b.condition.minimum_items

  return voucherMinimumTypeCompare(a.condition.minimum_items)
    ? -applicableOrder || -discountOrder || minimumItemOrder
    : -applicableOrder || -discountOrder || minimumSpendOrder
}

const sortDiscountTypeVoucher = (a, b, applicableOrder, minimumTypeOrder) => {
  const discountPercentageOrder =
    a.condition.discount.reduction_percent -
    b.condition.discount.reduction_percent
  const discountAmountOrder =
    a.condition.discount.reduction_amount -
    b.condition.discount.reduction_amount

  if (isPercentageType(a.condition.discount.type)) {
    return minimumTypeOrder
      ? sortMinimumConditionVoucher(
          a,
          b,
          applicableOrder,
          discountPercentageOrder,
        )
      : -applicableOrder || -discountPercentageOrder || minimumTypeOrder
  }

  if (a.condition.discount.type === VOUCHER_DISCOUNT_TYPE.GET_COF) {
    return (
      -applicableOrder || a.condition.minimum_items - b.condition.minimum_items
    )
  }

  return minimumTypeOrder
    ? sortMinimumConditionVoucher(a, b, applicableOrder, discountAmountOrder)
    : -applicableOrder || -discountAmountOrder || minimumTypeOrder
}

const sortApplicableVoucher = (a, b, applicableOrder) => {
  const typeOrder = discountTypeCompare(a, b)

  const minimumTypeOrder =
    voucherMinimumTypeCompare(a.condition.minimum_items) -
    voucherMinimumTypeCompare(b.condition.minimum_items)

  return typeOrder === 0
    ? sortDiscountTypeVoucher(a, b, applicableOrder, minimumTypeOrder)
    : -applicableOrder || typeOrder
}

const sortInApplicableVoucher = (a, b) => {
  const minimumTypeOrder =
    requireMinimumTypeCompare(a.condition.require_minimum_items) -
    requireMinimumTypeCompare(b.condition.require_minimum_items)
  const minimumAmountOrder =
    a.condition.require_minimum_spend - b.condition.require_minimum_spend
  const minimumItemOrder =
    a.condition.require_minimum_items - b.condition.require_minimum_items

  if (minimumTypeOrder === 0) {
    return a.condition.require_minimum_items
      ? minimumItemOrder
      : minimumAmountOrder
  }

  return minimumTypeOrder
}

const sortVoucherCheckout = (a, b) => {
  const applicableOrder = a.is_applicable - b.is_applicable
  return applicableOrder === 0 && !a.is_applicable
    ? sortInApplicableVoucher(a, b)
    : sortApplicableVoucher(a, b, applicableOrder)
}

const getAvailableItemsQuantity = (voucher, cartItems) => {
  const {
    applicable_products: { only, except },
  } = voucher

  const availableProductList = cartItems.filter((item) => {
    if (only) {
      return only.includes(item.id_product.toString())
    }
    if (except) {
      return !except.includes(item.id_product.toString())
    }
    return item
  })

  return availableProductList
    .map((product) => product.quantity)
    .reduce((sum, num) => sum + num, 0)
}

const addVoucherRequireCondition = (
  voucher,
  isQuantityNeeded,
  isAmountNeeded,
  quantity,
  requireAmount,
  requireItems,
) => {
  if (isAmountNeeded || isQuantityNeeded) {
    return {
      ...voucher,
      condition: {
        ...voucher.condition,
        ...(isAmountNeeded && {
          require_minimum_spend:
            requireAmount < 0 && quantity === 0 ? 0 : requireAmount, // if bag reach min spend but no product in requirement
        }),
        ...(isQuantityNeeded && { require_minimum_items: requireItems }),
      },
      is_applicable: 0,
    }
  }

  return voucher
}

const checkApplicableVoucher = (
  voucher,
  cartItems,
  amount,
  voucherCheckoutNewErrorFlagValue,
) => {
  const cartQuantity = cartItems.reduce(
    (sum, { quantity }) => sum + quantity,
    0,
  )
  const quantity = getAvailableItemsQuantity(voucher, cartItems)
  const { minimum_items, minimum_amount } = voucher.condition
  const isAmountNeeded =
    voucherCheckoutNewErrorFlagValue && voucherMinimumTypeCompare(minimum_items)
      ? amount < minimum_amount
      : amount < minimum_amount || quantity === 0
  const bagQuantity = voucherCheckoutNewErrorFlagValue ? quantity : cartQuantity
  const isQuantityNeeded =
    minimum_items && minimum_items > 1 && bagQuantity < minimum_items
  const requireAmount = minimum_amount - amount
  const requireItems = minimum_items - bagQuantity
  return addVoucherRequireCondition(
    voucher,
    isQuantityNeeded,
    isAmountNeeded,
    quantity,
    requireAmount,
    requireItems,
  )
}

const isPartnerVoucher = (type) => type === PARTNER_VOUCHER

const getHotdealBadgeCheckout = (list) =>
  list.map((voucher) => ({
    ...voucher,
    hot_deal: voucher.badge_type === HOT_DEAL_BADGE,
  }))

const sortHotdealVoucherCheckout = (list) => {
  const applicableHotdealVouchers =
    list?.filter(
      (voucher) => voucher.hot_deal && voucher.is_applicable === 1,
    ) || []

  const applicableNonHotdealVouchers =
    list?.filter(
      (voucher) => !voucher.hot_deal && voucher.is_applicable === 1,
    ) || []

  const applicablePartnerVouchers =
    applicableNonHotdealVouchers?.filter(
      (voucher) =>
        voucher.voucher_type === PARTNER_VOUCHER && voucher.is_applicable === 1,
    ) || []

  const applicableNonPartnerVouchers =
    applicableNonHotdealVouchers?.filter(
      (voucher) =>
        voucher.voucher_type !== PARTNER_VOUCHER && voucher.is_applicable === 1,
    ) || []

  const notApplicableVouchers =
    list?.filter((voucher) => voucher.is_applicable === 0) || []

  return [
    ...applicableHotdealVouchers,
    ...applicablePartnerVouchers,
    ...applicableNonPartnerVouchers,
    ...notApplicableVouchers,
  ]
}

const getVoucherCheckoutLists = (
  vouchers,
  cartItems,
  cartSubtotal,
  setVoucher,
  voucherCheckoutNewErrorFlagValue,
) => {
  const voucherCheckoutList = vouchers
    .filter((voucher) => voucher.show_in_checkout)
    .map((voucher) => ({ ...voucher, is_applicable: 1 }))

  const sortedVoucherList = voucherCheckoutList
    .map((voucher) =>
      checkApplicableVoucher(
        voucher,
        cartItems,
        cartSubtotal,
        voucherCheckoutNewErrorFlagValue,
      ),
    )
    .sort(sortVoucherCheckout)

  const voucherListWithHotdeal = getHotdealBadgeCheckout(sortedVoucherList)

  setVoucher(sortHotdealVoucherCheckout(voucherListWithHotdeal))
}

const isApplied = (code, appliedVoucher) => code === appliedVoucher

const getVoucherCheckoutButtonText = (code, appliedVoucher) =>
  code === appliedVoucher ? 'APPLIED' : 'Apply'

const isVoucherWithBadge = (hot_deal, badge_type, custom_badge_text) =>
  hot_deal || (badge_type !== HOT_DEAL_BADGE && custom_badge_text)

export {
  getVoucherCheckoutButtonText,
  isApplied,
  getVoucherCheckoutLists,
  isPartnerVoucher,
  isVoucherWithBadge,
}
