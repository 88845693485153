import dynamic from 'next/dynamic'
import Loading from './loading'

const RADIO_TYPES = {
  category: 'category',
}

const NoCodeSkinnyBanner = dynamic(() => import('./layout/no-code'), {
  ssr: false,
  loading: Loading,
})
const CodeSkinnyBanner = dynamic(() => import('./layout/code'), {
  ssr: false,
  loading: Loading,
})
const CountdownSkinnyBanner = dynamic(() => import('./layout/countdown'), {
  ssr: false,
  loading: Loading,
})

const Components = {
  NO_CODE: NoCodeSkinnyBanner,
  CODE: CodeSkinnyBanner,
  COUNTDOWN: CountdownSkinnyBanner,
}

export default Components

export { RADIO_TYPES }
