import ICONS from 'components/common/icon/const'
import ASSETS from 'components/pdp/interactions/product-usp/hoolah-info-modal/const'
import env from 'config/env'
import { TERMS_AND_CONDITIONS } from 'components/auth/pdpa/const'

const FOOTER_LOCIZE = {
  info_key: 'FOOTER_INFO',
  info_desc: 'INFO',
  help_key: 'FOOTER_HELP',
  help_desc: 'HELP',
  brand_key: 'FOOTER_BRAND',
  brand_desc: 'POMELO',
  hashtag_key: 'FOOTER_HASHTAG',
  hashtag_desc: '#POMELOGIRLS',
  contact_key: 'FOOTER_CONTACT',
  contact_desc: 'Contact',
  payment_key: 'FOOTER_PAYMENT',
  payment_desc: 'Accepted Payment Methods',
}

const FOOTER_CONTENT = [
  {
    key: 'content_line_1',
    brand_content: 'Fashion born in Asia',
    locizeKey: 'FOOTER_BRAND_SLOGAN_LINE_1',
  },
  {
    key: 'content_line_2',
    brand_content: 'On trend. Online. On the go.',
    locizeKey: 'FOOTER_BRAND_SLOGAN_LINE_2',
  },
]

const FOOTER_INFO = [
  {
    key: 'info_about_us',
    href: '/about',
    as: '/about',
    label: 'About us',
  },
  {
    key: 'info_contact',
    href: '/faq?type=contact',
    as: '/info/contact',
    label: 'Contact',
  },
  {
    key: 'info_career',
    href: 'https://careers.pomelofashion.com',
    label: 'Careers',
    rel: 'external',
  },
  {
    key: 'info_press',
    href: '/press',
    as: '/press',
    label: 'Press',
  },
  {
    key: 'info_purpose',
    href: `${env.PURPOSE_BY_POMELO_HREF}`,
    as: `${env.PURPOSE_BY_POMELO_SHOW_URL}`,
    label: 'Purpose',
  },
  {
    key: 'info_offline_store',
    href: '/offline',
    as: '/offline',
    label: 'Pomelo Stores',
  },
  {
    key: 'info_sell_on_pomelo',
    href: '/lookbooks/brands-acquisition',
    as: '/lookbooks/brands-acquisition',
    label: 'Sell on Pomelo',
  },
  {
    key: 'info_invite_friends',
    href: '/referral?ref_type=homefooter',
    as: '/referral?ref_type=homefooter',
    label: 'Invite friends',
  },
]

const FOOTER_HELP = [
  {
    key: 'help_shipping',
    href: '/faq?type=shipping',
    as: '/info/shipping',
    label: 'Shipping',
  },
  {
    key: 'help_returns',
    href: '/faq?type=returns',
    as: '/info/returns',
    label: 'Returns',
  },
  {
    key: 'help_faq',
    href: '/faq?type=faq',
    as: '/info/faq',
    label: 'FAQ',
  },
]

const FOOTER_ICONS = [
  {
    key: 'facebookSVG',
    src: ICONS.facebook,
    alt: 'pomelo official facebook',
  },
  {
    key: 'instagramSVG',
    src: ICONS.instagram,
    alt: 'pomelo official instagram',
  },
  {
    key: 'lineSVG',
    src: ICONS.line,
    alt: 'pomelo official line',
  },
  {
    key: 'whatsappSVG',
    src: ICONS.whatsapp,
    alt: 'pomelo official whatsapp',
  },
  {
    key: 'messengerSVG',
    src: ICONS.messenger,
    alt: 'pomelo facebook messenger',
  },
  {
    key: 'youtubeSVG',
    src: ICONS.youtube,
    alt: 'pomelo official youtube',
  },
]

const FOOTER_PAYMENT_ICONS = [
  {
    key: 'visa',
    src: ICONS.visa,
  },
  {
    key: 'mastercard',
    src: ICONS.mastercard,
  },
  {
    key: 'jcb',
    src: ICONS.jcb,
  },
  {
    key: 'amex',
    src: ICONS.amex,
  },
  {
    key: 'cup',
    src: ICONS.cup,
  },
  {
    key: 'hoolah',
    src: ASSETS.HOOLAH_LOGO,
    customSize: {
      width: 44,
      height: 14,
    },
  },
]

const FOOTER_SECTION = [
  {
    name: {
      key: 'FOOTER_BRAND',
      desc: 'POMELO',
    },
    content: FOOTER_CONTENT,
  },
  {
    name: {
      key: 'FOOTER_INFO',
      desc: 'INFO',
    },
    content: FOOTER_INFO,
  },
  {
    name: {
      key: 'FOOTER_HELP',
      desc: 'HELP',
    },
    content: FOOTER_HELP,
  },
  {
    name: {
      key: 'FOOTER_CONTACT',
      desc: 'Contact',
    },
    content: FOOTER_ICONS,
  },
]

const FOOTER_SUPPORTED_CREDIT_CARDS = [
  { key: 'abaKhqr', icon: ICONS.abaKhqrFooter },
  { key: 'abaVisaCard', icon: ICONS.abaVisaCard },
  { key: 'abaMasterCard', icon: ICONS.abaMasterCard },
  { key: 'abaUnionPayCard', icon: ICONS.abaUnionPayCard },
  { key: 'abaJcbCard', icon: ICONS.abaJcbCard },
]

const FOOTER_LINKS = [
  {
    key: 'privacy-policy',
    href: '/privacy-policy',
    as: '/info/privacy-policy',
    label: {
      key: 'FOOTER_LINKS_PRIVACY_AND_COOKIES',
      text: 'Privacy & Cookies',
    },
  },
  {
    key: 'cookies-setting',
    href: '/cookies-setting',
    as: '/info/cookies-setting',
    label: {
      key: 'FOOTER_LINKS_COOKIES_SETTINGS',
      text: 'Cookies Settings',
    },
  },
  {
    key: 'terms-and-conditions',
    href: '/terms-and-conditions',
    as: TERMS_AND_CONDITIONS.link,
    label: {
      key: TERMS_AND_CONDITIONS.key,
      text: TERMS_AND_CONDITIONS.text,
    },
  },
]

const FOOTER_KCG_ADDRESS = {
  key: 'FOOTER_KCG_ADDRESS',
  text: `Layanan Pengaduan Konsumen Pomelo:\nEmail: customercare_pomelo@ptkcg.co.id\nNo. Tlp: (+62) 21 50858804\nDirektorat Jenderal Perlindungan Konsumen dan Tertib Niaga Kementerian Perdagangan RI\nWhatsApp: +62 853 1111 1010`,
}

export {
  FOOTER_LOCIZE,
  FOOTER_CONTENT,
  FOOTER_INFO,
  FOOTER_HELP,
  FOOTER_ICONS,
  FOOTER_PAYMENT_ICONS,
  FOOTER_SECTION,
  FOOTER_LINKS,
  FOOTER_KCG_ADDRESS,
  FOOTER_SUPPORTED_CREDIT_CARDS,
}
