import StateStorage from 'lib/state-storage'
import { hasLocizeTranslation } from 'lib/utils/locize'
import { TERMS_AND_CONDITIONS } from 'components/auth/pdpa/const'

export const linkToPnC = `/${StateStorage.getShop()}/${StateStorage.getLanguage()}/info/privacy-policy`
export const NEW_COOKIE_ALERT_TITLE = {
  key: 'NEW_COOKIE_ALERT_TITLE',
  text: 'We care about your privacy',
}
export const NEW_COOKIE_ALERT_CONTENT = {
  key: 'NEW_COOKIE_ALERT_CONTENT',
  text: 'Your privacy matters to us. We use cookies and other similar technologies to ensure your best experience and improve our services. Please review the %linkTnC% We may use your personal data for the purposes stated in, and according to, the %linkPnC%',
}
export const COOKIE_LINK_TnC = {
  key: 'COOKIE_LINK_TnC',
  text: 'Terms and Conditions.',
}
export const COOKIE_CUSTOMIZE_BUTTON = {
  key: 'COOKIE_CUSTOMIZE_BUTTON',
  text: 'Customize',
}
export const COOKIE_ACCEPT_ALL_BUTTON = {
  key: 'COOKIE_ACCEPT_ALL_BUTTON',
  text: 'Accept All',
}
export const COOKIE_SETTING = {
  key: 'COOKIE_SETTING',
  text: 'Cookies setting',
}
export const PRIVACY_SETTING = {
  key: 'PRIVACY_SETTING',
  text: 'Privacy Settings',
}
export const PRIVACY_SETTING_CONTENT = {
  key: 'PRIVACY_SETTING_CONTENT',
  text: 'Along with our partners we use trackers for the reasons below to store and retrieve info from your device. Please review the %linkPnC% and %linkTnC%',
}
export const COOKIE_AND_PRIVACY = {
  key: 'COOKIE_AND_PRIVACY',
  text: 'Privacy and Cookies Notice.',
}
export const PRIVACY_SETTING_LIST = [
  {
    header: {
      key: 'PRIVACY_STRICTLY_SETTING_HEADER',
      text: 'Necessary Services',
    },
    content: {
      key: 'PRIVACY_STRICTLY_SETTING_CONTENT',
      text: 'Strictly used to provide necessary services e.g. update your order status and delivery your valued items. Please review the %linkTnC% before agreeing.',
    },
    disable: true,
  },
  {
    header: {
      key: 'PRIVACY_PERFORMANCE_SETTING_HEADER',
      text: 'Improved Services',
    },
    content: {
      key: 'PRIVACY_PERFORMANCE_SETTING_CONTENT',
      text: 'Used to provide your better experience. e.g. customer support, personalized contents. You data will not be shared!',
    },
    disable: false,
  },
  {
    header: {
      key: 'PRIVACY_MARKETING_SETTING_HEADER',
      text: 'Marketing Services',
    },
    content: {
      key: 'PRIVACY_MARKETING_SETTING_CONTENT',
      text: 'Your anonymized data is shared to our partners so we can keep you up to the latest trend!.',
    },
    disable: false,
  },
]

export const arrangeContent = (t, content, className) =>
  hasLocizeTranslation(t, content.key, content.text)
    .replace(
      '%linkTnC%',
      `<a class=${className} href=${
        TERMS_AND_CONDITIONS.link
      }>${hasLocizeTranslation(
        t,
        COOKIE_LINK_TnC.key,
        COOKIE_LINK_TnC.text,
      )}</a>`,
    )
    .replace(
      '%linkPnC%',
      `<a class=${className} href=${linkToPnC}>${hasLocizeTranslation(
        t,
        COOKIE_AND_PRIVACY.key,
        COOKIE_AND_PRIVACY.text,
      )}</a>`,
    )
