import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import TextField from 'components/common/textfield'
import { hasLocizeTranslation } from 'lib/utils/locize'
import Button from 'components/common/button'
import { BUTTON_HTML_TYPE, BUTTON_TYPES } from 'constants/button'
import { useAuth } from 'components/auth/context'
import {
  AUTH_BLOCK_USER_TYPE,
  AUTH_ERROR_MERGE,
  AUTH_ERROR_MIGRATE,
  AUTH_ERROR_INVALID_EMIL_OR_PASSWORD,
  AUTH_ERROR,
  AUTH_MESSAGE,
  AUTH_PROVIDER,
  AUTH_UI_STATE,
  ERROR_CODE_EMAIL,
} from 'components/auth/const'
import { logInWithEmailFireBase, successfulLogInFireBase } from 'lib/auth'
import {
  formConfig,
  INPUT_NAME_EMAIL,
  INPUT_NAME_PASSWORD,
} from 'components/auth/signup-form/const'
import { withI18next } from 'lib/i18n/withI18next'
import FormItem from 'components/form-item'
import TextFieldPassword from 'components/common/textfield-password'
import { ontextFieldFocus, handlePasswordFlow } from 'components/auth/utils'
import AuthHelperSignUp from 'components/auth/shared/helper-sign-up'
import style from './style.scss'

const AuthEmail = ({ mergeHandler, closeModal, t, userGroup }) => {
  const [authState, updateAuthState] = useAuth()
  const { errors, formState, handleSubmit, register, setError, getValues } =
    useForm({
      mode: 'onChange',
      defaultValues: {
        [INPUT_NAME_EMAIL]: authState.dataEmail,
      },
    })

  const [isLoading, setIsLoading] = useState(false)
  const [customEmailError, setCustomEmailError] = useState(false)

  const { isValid } = formState

  const onEmailChanged = () => {
    if (customEmailError) {
      setCustomEmailError(false)
    }
  }

  const showBlockScreen = (email) => {
    updateAuthState({
      dataEmail: email,
      blockType: AUTH_BLOCK_USER_TYPE.LOGIN,
      uiState: AUTH_UI_STATE.blockedUser,
      overlayLoading: false,
    })
    setIsLoading(false)
  }

  const handleFormSubmit = async (values, e) => {
    e?.preventDefault()

    const { email, password, firstName, lastName } = values

    updateAuthState({
      dataEmail: email,
      dataFirstName: firstName,
      dataLastName: lastName,
      dataPassword: password,
      overlayLoading: true,
      source: mergeHandler ? authState.source : AUTH_PROVIDER.email,
    })

    setIsLoading(true)

    try {
      const res = await logInWithEmailFireBase(email, password)

      if (mergeHandler) {
        mergeHandler(email, password)
      } else {
        successfulLogInFireBase(res)
      }

      if (res) {
        const customer = res?.customer
        // eslint-disable-next-line
        smartech(`contact`, 2, {
          'pk^customerid': `${customer?.id_customer}`,
          email: customer?.email,
          FIRST_NAME: customer?.firstname,
        })
        // eslint-disable-next-line
        smartech(`identify`, `${customer?.id_customer}`)
        // eslint-disable-next-line
        smartech(`dispatch`, `sign_in`, {
          email: customer?.email,
          first_name: customer?.firstname,
        })
      }
    } catch (err) {
      const { code, statusCode, message } = err

      const isInvalidPassword = code === AUTH_ERROR.invalidPass

      const isInCorrectPassword =
        statusCode === AUTH_ERROR.invalid || code === AUTH_ERROR.notAuthorized

      const isInvalidEmail = ERROR_CODE_EMAIL.includes(code)

      const isMerge = AUTH_ERROR_MERGE.includes(code)

      const isEmailNotFound = statusCode === AUTH_ERROR.emailNotFound
      const isTooManyAttemptsFirebase =
        err.message?.includes(AUTH_MESSAGE.tooManyAttempts) ||
        err.message?.includes(AUTH_MESSAGE.tooManyRequests)

      const isInvalidEmailOrPassword =
        AUTH_ERROR_INVALID_EMIL_OR_PASSWORD.includes(message)

      const isMigrationFlow = AUTH_ERROR_MIGRATE.includes(message)

      const isPasswordAttemptsExceeded =
        code === AUTH_ERROR.notAuthorized &&
        message === AUTH_MESSAGE.passwordAttemptsExceeded

      if (isMigrationFlow) {
        await handlePasswordFlow(email, null, closeModal)
        return
      }

      if (isPasswordAttemptsExceeded) {
        showBlockScreen(email)
        return
      }

      if (isEmailNotFound) {
        setError(
          INPUT_NAME_EMAIL,
          'manual',
          hasLocizeTranslation(
            t,
            'INVALID_EMAIL_PATTERN',
            'Incorrect email, Please try again',
          ),
        )
      } else if (isInvalidPassword || isInvalidEmailOrPassword) {
        setError(
          INPUT_NAME_PASSWORD,
          'manual',
          hasLocizeTranslation(t, 'INVALID_PASSWORD', 'Invalid Password'),
        )
      } else if (isInCorrectPassword) {
        setError(
          INPUT_NAME_PASSWORD,
          'manual',
          hasLocizeTranslation(
            t,
            'INVALID_PASSWORD_COGNITO',
            'Incorrect Password',
          ),
        )
      } else if (isInvalidEmail) {
        setCustomEmailError(true)
        setError(INPUT_NAME_EMAIL, 'duplicate', '')
      } else if (isMerge) {
        updateAuthState({
          dataEmail: email,
          uiState: AUTH_UI_STATE.merge,
        })
      } else if (isTooManyAttemptsFirebase) {
        showBlockScreen(email)
        return
      } else {
        updateAuthState({ snackBar: err })
      }

      updateAuthState({ overlayLoading: false })
      setIsLoading(false)
    }
  }

  const onForgotPasswordClicked = useCallback(() => {
    updateAuthState({
      uiState: AUTH_UI_STATE.forgotPassword,
      dataEmail: getValues(INPUT_NAME_EMAIL),
    })
  }, [updateAuthState, getValues])

  return (
    <form
      className="auth__email-form"
      onSubmit={handleSubmit(handleFormSubmit)}
    >
      <style jsx>{style}</style>
      <FormItem>
        <TextField
          fieldId="auth__email__field"
          error={!!errors[INPUT_NAME_EMAIL]}
          fullWidth
          helperText={
            customEmailError ? (
              <AuthHelperSignUp />
            ) : (
              errors[INPUT_NAME_EMAIL]?.message
            )
          }
          inputRef={register(
            formConfig.email({
              isRequired: true,
              requiredMessage: t('Required'),
              patternErrorMessage: hasLocizeTranslation(
                t,
                'FORM_INVALID_EMAIL_FORMAT',
                'Invalid email format.',
              ),
            }),
          )}
          label={t('Email')}
          name={INPUT_NAME_EMAIL}
          onChange={onEmailChanged}
          type="email"
          htmlFor="email"
          inputProps={{
            id: 'email',
          }}
        />
      </FormItem>
      <FormItem className="password-textfield">
        <TextFieldPassword
          fieldId="auth__password__field"
          inputRef={register({
            required: hasLocizeTranslation(
              t,
              'PASSWORD_REQUIRED',
              'Please Fill in Password',
            ),
          })}
          name={INPUT_NAME_PASSWORD}
          error={!!errors[INPUT_NAME_PASSWORD]}
          helperText={errors[INPUT_NAME_PASSWORD]?.message}
          onFocus={() => ontextFieldFocus(INPUT_NAME_PASSWORD, userGroup)}
        />
      </FormItem>
      <Button
        cy="auth__forgot__password_btn"
        className="auth__standalone-cta no-top-margin"
        onClick={onForgotPasswordClicked}
        type={BUTTON_TYPES.link}
      >
        {hasLocizeTranslation(t, 'FORGOT_PASSWORD', 'Forgot Password')}
      </Button>
      <FormItem>
        <Button
          className="auth__email-form-cta"
          cy="auth__login__email__button"
          disabled={!isValid || isLoading}
          htmlType={BUTTON_HTML_TYPE.submit}
          type={BUTTON_TYPES.primary}
        >
          {t('Log In')}
        </Button>
      </FormItem>
    </form>
  )
}

AuthEmail.propTypes = {
  mergeHandler: PropTypes.func,
  closeModal: PropTypes.func,
  t: PropTypes.func.isRequired,
  userGroup: PropTypes.string,
}

AuthEmail.defaultProps = {
  mergeHandler: undefined,
  closeModal: undefined,
  userGroup: null,
}

export default withI18next()(AuthEmail)
