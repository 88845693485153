import clsx from 'clsx'
import PropTypes from 'prop-types'
import Typo from 'constants/typography'
import { withI18next } from 'lib/i18n/withI18next'
import { getCartProductUrl } from 'components/cart/utils'
import styles from './styles.scss'
import CartChangeFreeGiftButton from '../cart-change-free-gift-button/index'
import { getClassNameWithOpacity } from '../utils'

const CartProductName = ({
  product,
  isSoldOut,
  isFreeGift,
  openFreeGift,
  onLinkClicked,
  isShoppingBagNewDesign,
  t,
}) => {
  const productNameClass = getClassNameWithOpacity(
    'cart-item-info__product-name',
    isSoldOut,
  )

  if (isSoldOut) {
    return (
      <div className={productNameClass}>
        <style jsx>{styles}</style>
        <span className={clsx(Typo.body2, 'product-name')}>{product.name}</span>
      </div>
    )
  }

  const renderFreeGift = () => {
    if (isShoppingBagNewDesign) {
      return (
        <div className="free-gift-info">
          <div className="free-gift-button">
            <button onClick={openFreeGift} className="product-name">
              <span className={Typo.subtitle2}>{t('Free gift added')}</span>
            </button>
            <CartChangeFreeGiftButton onClick={openFreeGift} />
          </div>

          <span className={Typo.body2}>{product.name}</span>
        </div>
      )
    }

    return (
      <button onClick={openFreeGift} className="product-name">
        <span className={Typo.subtitle2}>{t('Free gift added')}</span>
        <span className={Typo.caption}>{product.name}</span>
      </button>
    )
  }

  return (
    <div
      className={clsx(productNameClass, {
        'new-design': isShoppingBagNewDesign,
      })}
    >
      <style jsx>{styles}</style>
      {isFreeGift ? (
        renderFreeGift()
      ) : (
        <a
          href={getCartProductUrl(product)}
          onClick={onLinkClicked}
          className={clsx(Typo.body2, 'product-name')}
        >
          {product.name}
        </a>
      )}
    </div>
  )
}

CartProductName.defaultProps = {
  isShoppingBagNewDesign: false,
}

CartProductName.propTypes = {
  product: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
  isSoldOut: PropTypes.bool.isRequired,
  isFreeGift: PropTypes.bool.isRequired,
  openFreeGift: PropTypes.func.isRequired,
  onLinkClicked: PropTypes.func.isRequired,
  isShoppingBagNewDesign: PropTypes.bool,
  t: PropTypes.func.isRequired,
}

export default withI18next()(CartProductName)
