import PropTypes from 'prop-types'
import React from 'react'
import Router from 'next/router'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import ShopLanguageMap from 'lib/i18n/shop-language-map'
import StateStorage from 'lib/state-storage'
import UrlUtil from 'lib/utils/url'
import userDuck from 'components/user/account-details/duck'
import { segmentLanguageSelected } from 'lib/segment'
import { clearRecentlyViewProducts } from 'components/recently-view/utils'
import { SHOP_MENUS } from 'constants/shop-language'
import { useFlagsmithGetFlagValue } from 'lib/flagsmith'
import Drawer from 'components/common/drawer/Drawer'
import { hasLocizeTranslation } from 'lib/utils/locize'
import { compose } from 'recompose'
import { withI18next } from 'lib/i18n/withI18next'
import I18nItem from '../i18n-item'
import styles from './country-select-drawer.scss'

const updateI18n = (shopSlug, langSlug) => {
  let lang = langSlug

  if (shopSlug === 'th' && langSlug === 'en') {
    lang = `${langSlug}-${shopSlug}`
  }

  StateStorage.saveShop(shopSlug)
  StateStorage.saveLanguage(lang)
  const asPath = UrlUtil.getPathWithoutShopAndLang(Router.asPath) || '/'
  window.location.href = `/${shopSlug}/${langSlug}${asPath}`
}

const updateTheShop = ({
  user,
  idShop,
  shop,
  lang,
  invalidateUser,
  handleUserInfo,
}) => {
  if (user) {
    // Update user's country id on database
    const idLang = ShopLanguageMap.getIdLangBySlugAndLang(shop, lang)
    invalidateUser()
    handleUserInfo({
      type: 'UPDATE_INFO',
      userId: user.id_customer,
      id_shop: idShop,
      id_lang: idLang,
    })
  }
}

const sendSelectedLangToSegment = (idShop, lang, shop, user) => {
  const isoCode = ShopLanguageMap.getISOCodeBySlugAndLang(shop, lang)
  const promptLocation = user ? 'account' : 'nav_sign_up'
  segmentLanguageSelected(isoCode, idShop, promptLocation)
}

const saveEmptyCountry = (shop) => {
  if (shop === 'global') {
    StateStorage.saveCountry('')
  }
}

const CountrySelectDrawer = ({
  t,
  user,
  onClickBack,
  isOpenDrawer,
  onCloseDrawer,
  drawerPosition,
  invalidateUser,
  handleUserInfo,
  isShowCloseDrawer,
  countryDrawerTrigger,
  internationalization: { country, language },
}) => {
  const changeShopLang = (e, { shop, lang }) => {
    e?.preventDefault()
    e?.stopPropagation()
    clearRecentlyViewProducts(shop)

    const idShop = ShopLanguageMap.getIdShopBySlug(shop)
    sendSelectedLangToSegment(idShop, lang, shop, user)
    saveEmptyCountry(shop)

    updateTheShop({
      user,
      idShop,
      shop,
      lang,
      invalidateUser,
      handleUserInfo,
    })
    updateI18n(shop, lang)
  }

  const isSelected = ({ shop, lang }) => shop === country && lang === language

  const isEnableCambodianShop = useFlagsmithGetFlagValue(
    'EnableCambodianShop_20240618',
    true,
  )
  const shopMenus = isEnableCambodianShop
    ? SHOP_MENUS
    : SHOP_MENUS.filter((menu) => menu.shop !== 'kh')

  return (
    <Drawer
      onClickBack={onClickBack}
      isOpenDrawer={isOpenDrawer}
      onCloseDrawer={onCloseDrawer}
      drawerPosition={drawerPosition}
      drawerTrigger={countryDrawerTrigger}
      title={hasLocizeTranslation(
        t,
        'Country and Language',
        'Country and Language',
      )}
      isShowCloseDrawer={isShowCloseDrawer}
    >
      <div className="country-select-drawer">
        <style jsx>{styles}</style>
        <ul className="i18n__tooltip">
          {shopMenus.map((data) => (
            <I18nItem
              data={data}
              key={data.order}
              isSelected={isSelected}
              changeShopLang={changeShopLang}
            />
          ))}
        </ul>
      </div>
    </Drawer>
  )
}

CountrySelectDrawer.defaultProps = {
  user: null,
  onClickBack: null,
  onCloseDrawer: null,
  drawerPosition: 'right',
  isShowCloseDrawer: true,
}

CountrySelectDrawer.propTypes = {
  t: PropTypes.func.isRequired,
  internationalization: PropTypes.shape({
    country: PropTypes.string,
    language: PropTypes.string,
  }).isRequired,
  onClickBack: PropTypes.func,
  onCloseDrawer: PropTypes.func,
  drawerPosition: PropTypes.string,
  isShowCloseDrawer: PropTypes.bool,
  isOpenDrawer: PropTypes.bool.isRequired,
  invalidateUser: PropTypes.func.isRequired,
  handleUserInfo: PropTypes.func.isRequired,
  countryDrawerTrigger: PropTypes.node.isRequired,
  user: PropTypes.shape({
    id_customer: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
}

export default compose(
  connect(
    (state) => ({
      user: state.auth.user,
      internationalization: state.internationalization,
    }),
    (dispatch) =>
      bindActionCreators(
        {
          handleUserInfo: userDuck.creators.get,
          invalidateUser: userDuck.creators.invalidate,
        },
        dispatch,
      ),
  ),
  withI18next(),
)(CountrySelectDrawer)
