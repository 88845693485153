import PRODUCT_PREMIUM_TYPE_ENUM from 'constants/product/product'
import { hasLocizeTranslation } from 'lib/utils/locize'

const getProductPremiumType = ({
  isComingSoon = false,
  isSoldOut = false,
  isFlashSale = false,
  isLowStock = false,
  isBackInStock = false,
}) => {
  if (isComingSoon) return PRODUCT_PREMIUM_TYPE_ENUM.COMING_SOON
  if (isSoldOut) return PRODUCT_PREMIUM_TYPE_ENUM.SOLD_OUT
  if (isFlashSale) return PRODUCT_PREMIUM_TYPE_ENUM.FLASH_SALE
  if (isLowStock) return PRODUCT_PREMIUM_TYPE_ENUM.LOW_STOCK
  if (isBackInStock) return PRODUCT_PREMIUM_TYPE_ENUM.BACK_IN_STOCK
  return null
}

export const getPremiumTags = ({
  t,
  productBannerType,
  comingSoonText,
  stockQuantity,
}) => {
  switch (productBannerType) {
    case PRODUCT_PREMIUM_TYPE_ENUM.COMING_SOON:
      return comingSoonText
    case PRODUCT_PREMIUM_TYPE_ENUM.SOLD_OUT:
      return hasLocizeTranslation(
        t,
        'SOLD_OUT_ITEM',
        'Sold Out - Notify Me or Find Item at Store',
      )
    case PRODUCT_PREMIUM_TYPE_ENUM.FLASH_SALE:
      return hasLocizeTranslation(t, 'FLASH_SALE', 'Flash Sale')
    case PRODUCT_PREMIUM_TYPE_ENUM.LOW_STOCK:
      return hasLocizeTranslation(t, 'ONLY_ITEM_LEFT', 'Only %s left').replace(
        /%s/g,
        stockQuantity,
      )
    case PRODUCT_PREMIUM_TYPE_ENUM.BACK_IN_STOCK:
      return hasLocizeTranslation(t, 'Back In Stock', 'Back In Stock')
    default:
      return null
  }
}

export default getProductPremiumType
