import styles from './style.scss'

const NoopLoader = () => (
  <span className="noop-loader">
    <style jsx>{styles}</style>
    &nbsp;
  </span>
)

export default NoopLoader
