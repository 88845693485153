function isObject(value) {
  return value && typeof value === 'object' && value.constructor === Object
}

function removeNull(value) {
  if (!isObject(value)) {
    throw Error('Value is not an object.')
  }

  return Object.keys(value)
    .filter((key) => value[key] !== null)
    .reduce((result, key) => ({ ...result, [key]: value[key] }), {})
}

/**
 * covert top level properties to camelCase
 * @param {object} object
 * @returns {object}
 */
function covertPropertiesToCamelCase(object) {
  const convertedObj = Object.entries(object).reduce((covertedObj, current) => {
    const [key, value] = current
    const snakeSplitPattern = /_([a-zA-Z0-9])/g
    const camelcaseKey = key.replace(snakeSplitPattern, (matchString) => {
      const [, char] = matchString
      return char.toUpperCase()
    })
    return {
      ...covertedObj,
      [camelcaseKey]: value,
    }
  }, {})
  return convertedObj
}

/**
 * Get key from object by using its value
 * @param {object} object
 * @param {*} value
 * @returns {string}
 */
function getKeyByValue(object, value) {
  return Object.keys(object).find((key) => object[key] === value)
}

export { isObject, getKeyByValue, removeNull, covertPropertiesToCamelCase }
