import clsx from 'clsx'
import PropTypes from 'prop-types'
import { BUTTON_TYPES } from 'constants/button'
import Typo from 'constants/typography'
import Button from 'components/common/button'
import Icon, { ICONS, ICON_SIZE } from 'components/common/icon'
import { hasLocizeTranslation } from 'lib/utils/locize/index'
import { withI18next } from 'lib/i18n/withI18next'
import { isLowInStock, getClassNameWithOpacity } from '../utils'
import CartProductImage from './cart-product-image'
import styles from './styles.scss'

const CartProductImageContainer = ({
  product,
  selectedAttribute,
  deleteFromCart,
  isSoldOut,
  isFreeGift,
  openFreeGift,
  onLinkClicked,
  isShoppingBagNewDesign,
  t,
}) => {
  const isLowInStockProudctTagDisplay =
    isLowInStock(isSoldOut, product, selectedAttribute) &&
    isShoppingBagNewDesign

  const imageLinkClass = getClassNameWithOpacity('cart-image-link', isSoldOut)

  return (
    <div
      className={clsx('product-image', {
        'new-design': isShoppingBagNewDesign,
      })}
    >
      <style jsx>{styles}</style>
      <CartProductImage
        product={product}
        isFreeGift={isFreeGift}
        isSoldOut={isSoldOut}
        openFreeGift={openFreeGift}
        onLinkClicked={onLinkClicked}
        imageLinkClass={imageLinkClass}
      />
      <Button
        type={BUTTON_TYPES.link}
        className="cart-remove"
        onClick={deleteFromCart}
      >
        <Icon
          className="cart-remove-icon"
          alt="cart-remove"
          src={ICONS.close}
          size={ICON_SIZE.small}
        />
      </Button>
      {isLowInStockProudctTagDisplay && (
        <span className={clsx('product-image__tag', Typo.overline)}>
          {hasLocizeTranslation(t, 'ONLY_ITEM_LEFT', 'Only %s left').replace(
            /%s/g,
            selectedAttribute.stock.quantity,
          )}
        </span>
      )}
    </div>
  )
}

CartProductImageContainer.propTypes = {
  product: PropTypes.shape({
    name: PropTypes.string,
    is_available: PropTypes.bool,
    image: PropTypes.shape({
      path: PropTypes.string,
      full_path: PropTypes.string,
    }),
  }).isRequired,
  selectedAttribute: PropTypes.shape({
    stock: PropTypes.shape({
      quantity: PropTypes.number,
      is_low_quantity: PropTypes.bool.isRequired,
      is_sold_out: PropTypes.bool.isRequired,
    }),
  }).isRequired,
  deleteFromCart: PropTypes.func.isRequired,
  isSoldOut: PropTypes.bool.isRequired,
  isFreeGift: PropTypes.bool.isRequired,
  openFreeGift: PropTypes.func.isRequired,
  onLinkClicked: PropTypes.func.isRequired,
  isShoppingBagNewDesign: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
}

export default withI18next()(CartProductImageContainer)
