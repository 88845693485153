import React from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import env from 'config/env'
import PropTypes from 'prop-types'
import styles from './google-recaptcha.scss'

const GoogleRecaptcha = ({ handleVerifyRecaptcha }) => (
  <React.Fragment>
    <style jsx>{styles}</style>
    <ReCAPTCHA
      className="google-recaptcha"
      onChange={handleVerifyRecaptcha}
      sitekey={env.GOOGLE_RECAPTCHA_SITE_KEY}
    />
  </React.Fragment>
)

GoogleRecaptcha.propTypes = {
  handleVerifyRecaptcha: PropTypes.func.isRequired,
}

export default GoogleRecaptcha
