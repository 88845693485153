import PropTypes from 'prop-types'
import React from 'react'
import ContentLoader from 'react-content-loader'
import styles from './styles.scss'

const ProductListSkeleton = ({ customStyles, number, withCTA, withTitle }) => (
  <div className="product-list-skeleton" style={customStyles}>
    <style jsx>{styles}</style>
    {withTitle && (
      <div className="product-list-title-skeleton">
        <ContentLoader
          uniquekey="product-item-skeleton__content-loader-0"
          width={100}
          height={5}
          speed={2}
          primaryColor="#f3f3f3"
          secondaryColor="#ecebeb"
        >
          <rect x="40" y="2" width={20} height={2} />
        </ContentLoader>
      </div>
    )}
    {Array.from(Array(number)).map((_, i) => (
      <div className="product-item-skeleton" key={i}>
        <div className="product-item-img-skeleton">
          <ContentLoader
            uniquekey="product-item-skeleton__content-loader-1"
            width={100}
            height={133.33}
            speed={2}
            primaryColor="#f3f3f3"
            secondaryColor="#ecebeb"
          >
            <rect x="0" y="0" width={100} height={133.33} />
          </ContentLoader>
        </div>
        <ContentLoader
          uniquekey="product-item-skeleton__content-loader-2"
          width={100}
          height={30}
          speed={2}
          primaryColor="#f3f3f3"
          secondaryColor="#ecebeb"
        >
          <rect x="0" y="5" width={40} height={10} />
          <rect x="0" y="20" width={100} height={10} />
        </ContentLoader>
      </div>
    ))}
    {withCTA && (
      <div className="product-list-button-skeleton">
        <ContentLoader
          uniquekey="product-item-skeleton__content-loader-3"
          width={100}
          height={10}
          speed={2}
          primaryColor="#f3f3f3"
          secondaryColor="#ecebeb"
        >
          <rect x="42" y="5" width={16} height={5} />
        </ContentLoader>
      </div>
    )}
  </div>
)

ProductListSkeleton.defaultProps = {
  customStyles: null,
  number: 3,
  withCTA: false,
  withTitle: false,
}

ProductListSkeleton.propTypes = {
  customStyles: PropTypes.shape({}),
  number: PropTypes.number,
  withCTA: PropTypes.bool,
  withTitle: PropTypes.bool,
}

export default ProductListSkeleton
