const i18next = require('i18next')
const i18nOptions = require('./i18n-options')

const i18nInstance = i18next

if (!i18nInstance.isInitialized) {
  i18nInstance.init(i18nOptions)
}

const getInitialProps = (req, namespaces) => {
  let resultNamespaces = namespaces
  if (!namespaces) {
    resultNamespaces = i18nInstance.options.defaultNS
  }
  if (typeof namespaces === 'string') {
    resultNamespaces = [namespaces]
  }

  req.i18n.toJSON = () => null

  const initialI18nStore = {}

  req.i18n.languages.forEach((l) => {
    initialI18nStore[l] = {}

    resultNamespaces.forEach((ns) => {
      initialI18nStore[l][ns] = req.i18n.services.resourceStore.data[l]
        ? req.i18n.services.resourceStore.data[l][ns] || {}
        : {}
    })
  })

  return {
    i18n: req.i18n,
    initialI18nStore,
    initialLanguage: req.i18n.language,
  }
}

module.exports = {
  getInitialProps,
  i18nInstance,
  I18n: i18next.default,
}
