/**
 * **Utils class**
 *
 * Consists of a set of *PURE* javascript functions which can be re-used many times across the project.
 *
 * Feel free to add new cool and mind-blowing functions here! :)
 */
class Utils {
  /**
   * Check if the input is an object and return the value of the key if it exists.
   * @param input the object variable
   * @param key the key of the input object
   */
  static getObjectVal = (input, key) => {
    if (input && typeof input === 'object' && key in input) {
      return input[key]
    }

    return null
  }

  /**
   * Return true if the input is an array.
   * @param input variable to be checked
   */
  static isArray = (input) => !!(input && input.constructor === Array)

  /**
   * Return true if the input is an array with value inside.
   * @param input variable to be checked
   */
  static isArrayNotEmpty = (input) => !!(this.isArray(input) && input.length)

  /**
   * Show the Freshchat dialog
   * @param user user data, you can get this from redux `state.auth.user`
   */
  static showChat(user) {
    const isFSUpdateNeeded =
      user && window?.fcSettings?.restoreId !== user.freshchat_restore_id

    window.fcSettings = {
      ...window.fcSettings,
      externalId: (user && user.id_customer) || 0,
      restoreId: (user && user.freshchat_restore_id) || null,
    }

    if (isFSUpdateNeeded) {
      window.fcWidget?.init(window.fcSettings)
    }

    if (window && window.fcWidget) {
      window.fcWidget?.open()
    }
  }

  static omitBy(obj, fn) {
    return Object.keys(obj)
      .filter((k) => !fn(obj[k], k))
      .reduce((acc, key) => {
        acc[key] = obj[key]
        return acc
      }, {})
  }

  static sortPomeloPins(pins) {
    const parsedPins = [...pins]
    parsedPins?.sort((a, b) => a.distance_from_user - b.distance_from_user)

    // Show the temporary disabled pins at the bottom.
    parsedPins?.sort((a, b) => {
      const statusA = a.status
      const statusB = b.status

      if (statusA > statusB) {
        return 1
      }
      if (statusA < statusB) {
        return -1
      }
      return 0
    })

    return parsedPins
  }
}

export default Utils
