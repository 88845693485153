import clsx from 'clsx'
import PropTypes from 'prop-types'
import Router from 'next/router'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Badge from 'components/common/badge'
import Typo from 'constants/typography'
import { getProductListByUserId } from 'components/recently-view/utils'
import searchModalDuck from 'components/search/search-section/duck'
import { withI18next } from 'lib/i18n/withI18next'
import { segmentRecentlyViewProduct } from 'lib/segment'
import { getUserId } from 'lib/utils/common/commonUtils'
import { hasLocizeTranslation } from 'lib/utils/locize'
import styles from './styles.scss'

const SeeAllCard = ({
  auth,
  closeSearchModal,
  internationalization: { country, language },
  refType,
  seeAllCardHeight,
  seeAllCardWidth,
  t,
}) => {
  const userId = getUserId(auth).toString()
  const productList = getProductListByUserId(userId)
  const seeAllText = hasLocizeTranslation(
    t,
    'SEARCH_SECTION_RECENTLY_VIEW_SEE_ALL_CARD',
    'See All',
  )

  const goToRecentlyViewPage = () => {
    segmentRecentlyViewProduct(refType)
    Router.push(
      {
        pathname: '/recently-view',
        query: {
          refType,
        },
      },
      `/${country}/${language}/recently-view`,
    ).then(closeSearchModal)
  }

  return (
    <div
      className="see-all-card__container swiper-slide" // include 'swiper-slide' class to be included in Swpier component
      style={{ height: seeAllCardHeight, width: seeAllCardWidth }}
    >
      <style jsx>{styles}</style>
      <button className="see-all-card__button" onClick={goToRecentlyViewPage}>
        <div className={clsx('see-all-card__title', Typo.button)}>
          {seeAllText}
        </div>
        <Badge className="see-all-card__badge" content={productList.length} />
      </button>
    </div>
  )
}

SeeAllCard.propTypes = {
  auth: PropTypes.shape({
    isGuestMode: PropTypes.bool,
    user: PropTypes.shape({
      id_customer: PropTypes.number,
    }),
  }).isRequired,
  closeSearchModal: PropTypes.func.isRequired,
  internationalization: PropTypes.shape({
    country: PropTypes.string,
    language: PropTypes.string,
  }).isRequired,
  refType: PropTypes.string.isRequired,
  seeAllCardHeight: PropTypes.number.isRequired,
  seeAllCardWidth: PropTypes.number.isRequired,
  t: PropTypes.func.isRequired,
}

const Extended = withI18next()(SeeAllCard)

export default connect(
  (state) => ({
    auth: state.auth,
    internationalization: state.internationalization,
  }),
  (dispatch) =>
    bindActionCreators(
      {
        closeSearchModal: searchModalDuck.creators.closeSearchModal,
      },
      dispatch,
    ),
)(Extended)
