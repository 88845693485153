export const isFlagEligible = (flagValue) => !['OFF', 'A'].includes(flagValue)

export const customFlagValue = ({ flagName, defaultValue }) => {
  switch (flagName) {
    case 'voucherCheckout':
      return 'B'
    case 'shoppingBagRedesign':
      return 'OFF'
    default:
      return defaultValue
  }
}
